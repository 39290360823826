
import { computed, defineComponent, PropType, Ref, ref, toRefs } from 'vue'

import ApexChartActions, {
  EVENTS as CHART_EVENTS
} from '@/components/analysis/common/chart-actions/ApexChartActions.vue'
import useChartRange from '@/components/analysis/composable/chart-range'
import useMaximizer from '@/components/composables/maximizer-apex'
import { Dict, rounder } from '@/libs/common'

export default defineComponent({
  name: 'RadarChart',
  components: {
    ApexChartActions
  },
  props: {
    objectiveNodeKeys: {
      type: Array as PropType<string[]>,
      default: () => ['1', '2']
    },
    data: {
      type: Array,
      default: () => [
        [65, 59, 90],
        [35, 89, 10]
      ]
    },
    chartId: {
      type: String,
      default: 'radar-chart'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => undefined
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const chartEl: Ref<HTMLElement | null> = ref(null)
    const { min, max } = toRefs(props)
    const { minBound, maxBound } = useChartRange(min, max)

    const series = computed(() =>
      props.data.map((r: any, index: number): any => {
        const dataset: Dict = {}
        dataset.data = r.map((x: any) => rounder(x, 4))
        dataset.name = 'option ' + (index + 1)
        return dataset
      })
    )

    const chartOptions = computed(() => ({
      colors: props.colors,
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: 2,
        curve: 'straight'
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 2
      },
      grid: {
        row: {
          colors: ['#f3f3f3'],
          opacity: 0.5
        }
      },
      yaxis: {
        min: minBound.value,
        max: maxBound.value,
        decimalsInFloat: 2,
        forceNiceScale: false
      },
      xaxis: {
        categories: props.objectiveNodeKeys
      },
      tooltip: {
        y: {
          formatter: (val: number) => val.toFixed(4)
        }
      },
      legend: {
        position: 'bottom'
      }
    }))

    return {
      CHART_EVENTS,
      chartEl,
      ...useMaximizer(),
      series,
      chartOptions
    }
  }
})
