export enum AuthStateEnum {
  ERROR = 'error',
  IS_AUTHORIZED = 'isAuthorized',
  IS_GETTING_CURRENT_USER = 'isGettingCurrentUser',
  IS_LOGGING_OUT = 'isLoggingOut',
  IS_UPDATING_CURRENT_USER = 'isUpdatingCurrentUser',
  IS_VERIFYING = 'isVerifying',
  TOKEN = 'token',
  USER = 'user',
  USER_PREFERENCE = 'user_preference'
}
