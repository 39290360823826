import { computed, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'

import { DB_ENUM_VALUES } from '@/constants/database'
import { AHP_SURVEY_STATUS, APISETS } from '@/services/api/ahp/set'
import { createSurvey, getSurveys } from '@/services/api/survey'
import { SurveySchema, UserNext, Workspace } from '@/types'
import { AhpSurveyStatus } from '@/types/database/ahp'

const surveyDefault = {
  name: 'Hybrid',
  state: 'IN_PROGRESS',
  ext: {
    email_share_body:
      'Dear {{ userName }}, <br />The survey {{ surveyName }} is currently available. Please click the link {{ surveyUrl }} here.',
    email_share_subject: '[SAUCE] AHP Survey (name: {{ surveyName }})'
  },
  workspaceId: '',
  collectionMethod: 'HYBRID',
  scale: {},
  surrogate: false
}

export default function useHybridSurveyStatus(
  workspaceId: string,
  workspace: Ref<Workspace>,
  users: Ref<UserNext[]>
): any {
  const isLoadingSurveyStatus: Ref<boolean> = ref(false)
  const { gets, create, erase, distribute } = APISETS[AHP_SURVEY_STATUS].method

  const { runAsync: runGetSurveyStatus, data: dataSurveyStatus }: any = useRequest(gets, {
    manual: true
  })

  const dataSource = computed(() => {
    if (!dataSurveyStatus.value) {
      return []
    }
    return (dataSurveyStatus.value as AhpSurveyStatus[])?.map((each: AhpSurveyStatus) => ({
      ...each,
      key: each?.id,
      url: each?.ext?.surveyUrlWithSurveyKey || undefined,
      username: users.value.find((user: UserNext) => user.id === each.userId)?.username
    }))
  })

  const { runAsync: runDeleteSurveyStatus } = useRequest(erase, {
    manual: true
  })

  const { runAsync: runDistributeSurveyStatus } = useRequest(distribute, {
    manual: true
  })

  const { runAsync: runCreateSurveyStatus, loading: isCreatingSurveyStatus } = useRequest(create, {
    manual: true
  })

  const selectedSurvey: Ref<SurveySchema | undefined> = ref()

  const { run: runGetSurveys, data: surveyData } = useRequest(getSurveys, {
    manual: true
  })

  const { run: runCreateSurvey, loading: isCreatingSurvey } = useRequest(createSurvey, {
    manual: true
  })

  watch(surveyData, () => {
    const existingSurvey = surveyData.value?.content?.[0]
    if (existingSurvey) {
      selectedSurvey.value = surveyData.value?.content?.[0]
    } else {
      if (workspace.value) {
        const payload = {
          ...surveyDefault,
          workspaceId: workspace.value.id,
          name: workspace.value.name
        }
        runCreateSurvey(workspace.value.id, payload)
      }
    }
  })

  const loadSurvey = () => {
    if (workspaceId) {
      runGetSurveys(workspaceId)
    }
  }

  watch(isCreatingSurvey, () => {
    if (!isCreatingSurvey.value && workspaceId) {
      runGetSurveys(workspaceId)
    }
  })

  const distributeSurvey = async (id: string, surveyUrl: string) => {
    const payload = {
      surveyUrl
    }
    if (id) {
      await runDistributeSurveyStatus(workspaceId, id, payload)
      return
    }
    dataSurveyStatus.value?.forEach(async (each: AhpSurveyStatus) => {
      await runDistributeSurveyStatus(workspaceId, each.id, payload)
      // ...each,
      // key: each?.id,
      // url: each?.ext?.surveyUrlWithSurveyKey || undefined
    })
    loadSurveyStatus()
  }

  const initializeSurveyStatus = async () => {
    const promises: Promise<any>[] = []
    dataSurveyStatus.value?.forEach(async (each: AhpSurveyStatus) => {
      promises.push(runDeleteSurveyStatus(workspaceId, each.id))
      // ...each,
      // key: each?.id,
      // url: each?.ext?.surveyUrlWithSurveyKey || undefined
    })
    await Promise.all(promises)
    users.value?.forEach(async (each: UserNext) => {
      const surveyStatus = {
        userId: each.id,
        status: DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING,
        completionRate: 0
      } as AhpSurveyStatus
      await runCreateSurveyStatus(workspaceId, surveyStatus)
    })
    await runGetSurveyStatus(workspaceId)
  }

  const isPersistingSurveyStatus = computed(() => isCreatingSurveyStatus.value)

  const loadSurveyStatus = () => {
    runGetSurveyStatus(workspaceId)
  }

  return {
    loadSurvey,
    dataSource,
    distributeSurvey,
    initializeSurveyStatus,
    isPersistingSurveyStatus,
    isLoadingSurveyStatus,
    loadSurveyStatus
  }
}
