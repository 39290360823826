
import { css, cx } from '@emotion/css'
import { Pin, Pinned } from '@vicons/tabler'
import { computed, defineComponent, onMounted, PropType, reactive, ref, watch } from 'vue'

import { DISTRIBUTION_FORM_DATA, DISTRIBUTIONS } from '@/constants/probs'
import { Variable } from '@/libs/bayes'

import { EVENTS, useProbSelector } from './common'
import { criticalStyle, dotStyle } from './styles'

export const probStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .ant-input {
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;

    .ant-input-input-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 20px;
      bottom: 0;

      .ant-input-input {
        text-align: center;
        margin-right: 21px;
        font-size: 18px;
        height: 100%;
      }
    }
  }
`

export default defineComponent({
  components: {
    Pin,
    Pinned
  },
  props: {
    allowCritical: { type: Boolean, default: false },
    colIndex: { type: Number, default: undefined },
    tabIndex: { type: Number, default: undefined },
    combination: { type: Object, required: true },
    combKey: { type: String, required: true },
    disabled: { type: Boolean, required: true },
    discrete: { type: Boolean, default: false },
    response: { type: Object, default: undefined },
    rowId: { type: String, default: undefined },
    rowIndex: { type: Number, default: undefined },
    variable: { type: Object as PropType<Variable>, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const probType = ref<any>(undefined)
    const visible = ref<boolean>(false)

    const formData = reactive({ ...DISTRIBUTION_FORM_DATA })

    const prob = ref<number | undefined>(undefined)
    const probT = ref<number | undefined>(undefined)
    const probSummary = ref<string>('')
    const critical = ref<boolean>(false)
    const isDeterministic = computed(() => props.variable?.isDeterministic())

    const { emitProbSelectorChange, onToggleCritical } = useProbSelector(
      props,
      emit,
      formData,
      probType,
      critical
    )

    /**
     * Sync response
     */
    const updateValuesFromProps = () => {
      if (props.response?.value !== prob.value || props.response?.value !== probT.value) {
        prob.value = props.response?.value
        probT.value = props.response?.value
      }
      if (props.response?.ext?.critical !== critical.value) {
        critical.value = props.response?.ext?.critical
      }
      if (props.response?.ext?.probabilityConfig) {
        const p = props.response?.ext?.probabilityConfig
        probType.value = p.id
        probSummary.value = p.id + ' [' + Object.values(p.params).join(',') + ']'
        Object.keys(p.params).forEach((k) => {
          formData[p.id][k] = p.params[k]
        })
      }
    }

    // When first load component, update init value
    onMounted(() => {
      updateValuesFromProps()
    })

    watch(
      () => props.response,
      () => {
        updateValuesFromProps()
      }
    )

    const handleOk = () => {
      visible.value = false
      const probConfig = emitProbSelectorChange()
      if (probConfig) {
        probSummary.value = probConfig.id + ' [' + Object.values(probConfig.params).join(',') + ']'
      } else {
        probSummary.value = ''
      }
    }

    const showDialog = () => {
      if (!props.disabled) {
        visible.value = true
      }
    }

    const closeDialog = () => {
      visible.value = false
    }

    const bodyStyle = {
      height: 'calc(100vh - 300px)',
      overflow: 'auto',
      backgroundColor: '#f5f5f5'
    }

    return {
      probSummary,
      showDialog,
      closeDialog,
      onToggleCritical,
      critical,
      criticalStyle,
      cx,
      dotStyle,
      prob,
      probStyle,
      probType,
      isDeterministic,
      probT,
      DISTRIBUTIONS,
      formData,
      visible,
      handleOk,
      bodyStyle
    }
  }
})
