
import { defineComponent, PropType, reactive, toRaw, UnwrapRef } from 'vue'

import {
  DEFAULT_OPTION_DESIGN_CONFIG,
  MoeaAlgoType,
  OptionDesignConfigData
} from '../libs/option-design'

export default defineComponent({
  props: {
    networks: { type: Object, default: undefined },
    variableNodes: { type: Object, default: undefined },
    data: { type: Object as PropType<OptionDesignConfigData>, default: undefined }
  },
  setup(props) {
    const algoOptions = Object.keys(MoeaAlgoType).map((key) => ({
      label: MoeaAlgoType[key],
      value: key
    }))

    const form: UnwrapRef<OptionDesignConfigData> = reactive(
      Object.assign({ ...DEFAULT_OPTION_DESIGN_CONFIG }, props.data)
    )

    const getData = () => {
      return toRaw(form)
    }

    return {
      form,
      algoOptions,
      getData
    }
  }
})
