
// import { CaretDown, CaretUp, Copy, Trash } from '@vicons/tabler'
// import { Icon } from '@vicons/utils'
import { range, repeat, sort } from 'ramda'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'

import Sorter from '@/components/analysis/common/sorter/Sorter.vue'
import { AnalysisTask, InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import { percentFormatter } from '@/libs/common'
import { StatePolarity } from '@/libs/enums'
import { genCellBgStyleRange } from '@/libs/theme'
import { getStateStyle } from '@/libs/utils'
import { VueOptProp } from '@/libs/vue'

export const EVENTS = {
  ON_MOVE_UP_ROW: 'onMoveUpRow',
  ON_MOVE_DOWN_ROW: 'onMoveDownRow',
  ON_UPDATE_NETWORK: 'onUpdateNetwork',
  ON_CALCULATE: 'onCalculate',
  ON_UPDATE_OBSERVED_NODE: 'onUpdateObservedNode'
}

export default defineComponent({
  components: {
    Sorter
    // Icon,
    // Copy,
    // CaretDown,
    // CaretUp,
    // Trash,
  },
  props: {
    selections: { type: Object, required: true },
    networks: { type: Object, required: true },
    optValByObjectiveNodeRows: { type: Array, required: true },
    solutionByVariableNodeRows: { type: Array, required: true },
    optionCount: { type: Number, default: 0 },
    task: { type: Object as VueOptProp<AnalysisTask>, default: undefined },
    variableNodes: { type: Object, required: true },
    changeCounts: { type: Object, required: true },
    baselines: { type: Object, required: true },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const sorterObjectiveName: Ref<number> = ref(0)
    const sorterSolutionName: Ref<number> = ref(0)
    const sorterObjective: Ref<number[]> = ref([])
    const sorterSolution: Ref<number[]> = ref([])
    const options = computed(() => range(props.optionCount))
    const moveUp = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_UP_ROW, record, rowIndex)
    }

    const moveDown = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_DOWN_ROW, record, rowIndex)
    }

    const cellBgStyle = (val: number) => {
      return genCellBgStyleRange(props.min, props.max, val)
    }

    const objectiveRows: Ref<any[]> = ref([])
    const solutionRows: Ref<any[]> = ref([])

    watch(
      () => props.optionCount,
      () => {
        sorterObjective.value = repeat(0, props.optionCount)
        sorterSolution.value = repeat(0, props.optionCount)
      }
    )

    const solutionStyle = (variable: any, solution: any, baseline: any) => {
      if (variable.isDeterministic) {
        return solution?.name !== baseline?.name
          ? getStateStyle(solution?.polarity || StatePolarity.POSITIVE)
          : ''
      } else {
        if (solution == baseline) {
          return ''
        } else if (solution >= baseline) {
          return {
            'background-color': '#ffcccc'
          }
        } else {
          return {
            'background-color': '#ccccff'
          }
        }
      }
    }

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    onMounted(() => {
      // updateRows()
      solutionRows.value = props.solutionByVariableNodeRows
      objectiveRows.value = props.optValByObjectiveNodeRows
      sorterObjective.value = repeat(0, props.optionCount)
      sorterSolution.value = repeat(0, props.optionCount)
    })

    watch(
      () => props.solutionByVariableNodeRows,
      () => {
        solutionRows.value = props.solutionByVariableNodeRows
      }
    )

    watch(
      () => props.optValByObjectiveNodeRows,
      () => {
        objectiveRows.value = props.optValByObjectiveNodeRows
      }
    )

    const sortObjectiveName = () => {
      if (sorterObjectiveName.value === 0) {
        objectiveRows.value = props.optValByObjectiveNodeRows
      } else {
        objectiveRows.value = sort((row1: any, row2: any) => {
          return row2.name?.localeCompare(row1.name) * -sorterObjectiveName.value
        }, props.optValByObjectiveNodeRows)
      }
    }

    const sortSolutionName = () => {
      if (sorterSolutionName.value === 0) {
        solutionRows.value = props.solutionByVariableNodeRows
      } else {
        solutionRows.value = sort((row1: any, row2: any) => {
          return (
            row2['variableNode']?.key.localeCompare(row1['variableNode']?.key) *
            -sorterSolutionName.value
          )
        }, props.solutionByVariableNodeRows)
      }
    }

    const sortObjective = (optIndex: number) => {
      for (let i = 0; i < props.optionCount; i++) {
        if (i !== optIndex) {
          sorterObjective.value[i] = 0
        }
      }
      if (sorterObjective.value[optIndex] === 0) {
        objectiveRows.value = props.optValByObjectiveNodeRows
      } else {
        objectiveRows.value = sort((row1: any, row2: any) => {
          return (
            (row2['opt' + optIndex] - row1['opt' + optIndex]) * -sorterObjective.value[optIndex]
          )
        }, props.optValByObjectiveNodeRows)
      }
    }

    const sortSolution = (optIndex: number) => {
      for (let i = 0; i < props.optionCount; i++) {
        if (i !== optIndex) {
          sorterSolution.value[i] = 0
        }
      }
      if (sorterSolution.value[optIndex] === 0) {
        solutionRows.value = props.solutionByVariableNodeRows
      } else {
        solutionRows.value = sort((row1: any, row2: any) => {
          return (
            (row2['order' + optIndex] - row1['order' + optIndex]) * -sorterSolution.value[optIndex]
          )
        }, props.solutionByVariableNodeRows)
      }
    }

    return {
      sorterObjectiveName,
      sorterSolutionName,
      sortObjectiveName,
      sortSolutionName,
      objectiveRows,
      solutionRows,
      sortObjective,
      sortSolution,
      sorterObjective,
      sorterSolution,
      solutionStyle,
      percentFormatter,
      getStateStyle,
      StatePolarity,
      options,
      update,
      cellBgStyle,
      ROW_TYPE,
      EVENTS,
      InputType,
      moveUp,
      moveDown
    }
  }
})
