import { render } from "./AnalysisWorkspaceList.vue?vue&type=template&id=5116471a"
import script from "./AnalysisWorkspaceList.vue?vue&type=script&lang=ts"
export * from "./AnalysisWorkspaceList.vue?vue&type=script&lang=ts"

import "./AnalysisWorkspaceList.vue?vue&type=style&index=0&id=5116471a&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5116471a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5116471a', script)) {
    api.reload('5116471a', script)
  }
  
  module.hot.accept("./AnalysisWorkspaceList.vue?vue&type=template&id=5116471a", () => {
    api.rerender('5116471a', render)
  })

}

script.__file = "src/components/analysis/workspace/AnalysisWorkspaceList.vue"

export default script