import { SurveySummaryStateEnum } from '@/store/enums/states/surveySummary'
import { SurveySummaryState } from '@/store/types/states/surveySummary'

/**
 * Default value
 */
const state: SurveySummaryState = {
  [SurveySummaryStateEnum.SURVEY_SUMMARIES]: [],
  [SurveySummaryStateEnum.IS_GETTING]: false,
  [SurveySummaryStateEnum.ERROR]: null
}

export { state }
