import { computed, ComputedRef, ref, watch } from 'vue'

import { DB_ENUM_VALUES } from '@/constants/database'
import { ModuleNames } from '@/constants/vuex'
import { CollectionMethodMap, CPTSet, Network, Variable } from '@/libs/bayes'
import { CPTMethod } from '@/libs/bayes/enums'
import { QuestionnaireActionEnum } from '@/store/enums/actions'
import { QuestionnaireGetterEnum } from '@/store/enums/getters'
import { AuthStateEnum, NetworkStateEnum, SurveyStateEnum } from '@/store/enums/states'
import { Store } from '@/store/types'
import { vuexActions, vuexGetters } from '@/store/util'
import { CPTSchema, NetworkSchema, ResponseSchema, SurveySchema } from '@/types'

const { AUTH, NETWORK, QUESTIONNAIRE, SURVEY } = ModuleNames

export default function useSurvey(store: Store, loadPersisted = false): any {
  const persistedCpts = computed(() =>
    loadPersisted ? store.getters[vuexGetters(QUESTIONNAIRE, QuestionnaireGetterEnum.CPTS)] : []
  )

  const persistedResponses = computed(() =>
    loadPersisted
      ? store.getters[vuexGetters(ModuleNames.QUESTIONNAIRE, QuestionnaireGetterEnum.RESPONSES)]
      : []
  )

  const currentUser = computed(() => store.state[AUTH][AuthStateEnum.USER])
  const currentNetwork = computed(() => store.state[NETWORK][NetworkStateEnum.CURRENT_NETWORK])
  const currentSurvey = computed(() => store.state[SURVEY][SurveyStateEnum.CURRENT_SURVEY])

  const hasDiscreteScale = computed(() => {
    const survey = currentSurvey.value as SurveySchema
    return survey?.scale?.method === DB_ENUM_VALUES.SURVEY.SCALE_METHOD.PHRASE
  })

  const network: ComputedRef<Network> = computed(
    () => new Network(currentNetwork.value as NetworkSchema)
  )
  const selectedVariable = ref()

  const cptSet = computed(() => {
    const cptSet = new CPTSet(
      network.value,
      currentSurvey?.value?.collectionMethod as CPTMethod,
      currentSurvey?.value?.ext?.collectionMethodMap as CollectionMethodMap
    )
    if (cptSet) {
      cptSet.generateCombinations()
    }
    if (loadPersisted) {
      if (persistedCpts.value) {
        cptSet.load(persistedCpts.value as CPTSchema[])
      }
    }
    return cptSet
  })

  const cpt = ref()

  watch(cptSet, () => {
    if (!selectedVariable.value) {
      selectedVariable.value = network?.value?.variables[0]
    }
    if (cptSet.value) {
      cptSet.value.generateCombinations()
      updateCPT()
    }
  })

  watch(selectedVariable, () => {
    updateCPT()
  })

  const updateCPT = () => {
    if (cptSet.value && selectedVariable.value) {
      cpt.value = cptSet.value.getCPT(selectedVariable.value)
    }
  }

  const selectVariable = (variable: Variable) => {
    selectedVariable.value = variable
  }

  // const cpt = computed(() => {
  //   if (cptSet.value && selectedVariable.value) {
  //     return cptSet.value.getCPT(selectedVariable.value)
  //   }
  //   return undefined
  // })

  const persistResponses = async (responses: Array<ResponseSchema>, isAnalytics: boolean) => {
    const userId = currentUser.value?.id
    const surveyId = currentSurvey.value?.id
    const workspaceId = currentSurvey.value?.workspaceId
    const networkId = currentNetwork.value?.id
    const cptId = cpt.value?.id
    if (userId && surveyId && workspaceId && cptId && networkId) {
      await store.dispatch(
        vuexActions(ModuleNames.QUESTIONNAIRE, QuestionnaireActionEnum.PATCH_RESPONSES),
        responses.map((response) =>
          Object.assign({}, response, { userId, surveyId, workspaceId, cptId, networkId })
        )
      )
      // If isAnalytics (SurveyAnalytics), return all responses. Otherwise (Questionnaire), return from specific user.
      await store.dispatch(
        vuexActions(ModuleNames.QUESTIONNAIRE, QuestionnaireActionEnum.GET_SURVEY_RESPONSES),
        isAnalytics ? { surveyId, networkId } : { surveyId, userId, networkId }
      )
    }
  }

  return {
    cpt,
    cptSet,
    currentNetwork,
    currentSurvey,
    currentUser,
    network,
    hasDiscreteScale,
    persistedCpts,
    persistedResponses,
    persistResponses,
    selectedVariable,
    selectVariable,
    updateCPT
  }
}
