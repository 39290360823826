/**
 * Define mutation enum values
 */
export enum AuthMutationEnum {
  AUTH = 'AUTH',
  AUTH_CURRENT = 'AUTH_CURRENT',
  AUTH_CURRENT_FULFILLED = 'AUTH_CURRENT_FULFILLED',
  AUTH_CURRENT_REJECTED = 'AUTH_CURRENT_REJECTED',
  AUTH_FULFILLED = 'AUTH_FULFILLED',
  AUTH_REFRESH = 'AUTH_REFRESH',
  AUTH_REJECTED = 'AUTH_REJECTED',
  LOG_OUT = 'LOG_OUT',
  LOG_OUT_FULFILLED = 'LOG_OUT_FULFILLED',
  LOG_OUT_REJECTED = 'LOG_OUT_REJECTED',
  SET_USER_PREFERENCE = 'SET_USER_PREFERENCE',
  UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER',
  UPDATE_CURRENT_USER_FULFILLED = 'UPDATE_CURRENT_USER_FULFILLED',
  UPDATE_CURRENT_USER_REJECTED = 'UPDATE_CURRENT_USER_REJECTED'
}
