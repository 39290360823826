import { render } from "./MatrixDisplay.vue?vue&type=template&id=3dbe9d89"
import script from "./MatrixDisplay.vue?vue&type=script&lang=ts"
export * from "./MatrixDisplay.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3dbe9d89"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3dbe9d89', script)) {
    api.reload('3dbe9d89', script)
  }
  
  module.hot.accept("./MatrixDisplay.vue?vue&type=template&id=3dbe9d89", () => {
    api.rerender('3dbe9d89', render)
  })

}

script.__file = "src/components/analysis/common/matrix-display/MatrixDisplay.vue"

export default script