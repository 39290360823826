<template>
  <div class="sza-od-config">
    <a-form :model="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 10 }">
      <a-form-item label="Algorithm">
        <a-select
          v-model:value="form.moeaAlgoType"
          style="width: 100%; max-width: 200px"
          :options="algoOptions"
        />
      </a-form-item>
      <a-form-item label="Population size">
        <a-input-number v-model:value="form.populationSize" :min="1" :max="1000000" />
      </a-form-item>
      <a-divider />
      <a-form-item label="Max. evalutation">
        <a-input-number v-model:value="form.evaluationMax" :min="1" :max="1000000" />
      </a-form-item>
      <a-form-item label="Time limit (ms)">
        <a-input-number v-model:value="form.timeMax" :min="1" :max="1 * 60 * 60 * 1000" />
      </a-form-item>
      <a-form-item label="Max. unchanged solution">
        <a-input-number v-model:value="form.solUnchangedMax" :min="1" :max="100" />
      </a-form-item>
      <a-divider />
      <a-form-item label="Search Direction">
        <a-input-number v-model:value="form.searchDirection" :min="-1" :max="1" />
      </a-form-item>
      <a-form-item label="Max. input nodes">
        <a-input-number v-model:value="form.maxInputNodes" :min="1" :max="10000" />
      </a-form-item>
    </a-form>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, reactive, toRaw, UnwrapRef } from 'vue'

import {
  DEFAULT_OPTION_DESIGN_CONFIG,
  MoeaAlgoType,
  OptionDesignConfigData
} from '../libs/option-design'

export default defineComponent({
  props: {
    networks: { type: Object, default: undefined },
    variableNodes: { type: Object, default: undefined },
    data: { type: Object as PropType<OptionDesignConfigData>, default: undefined }
  },
  setup(props) {
    const algoOptions = Object.keys(MoeaAlgoType).map((key) => ({
      label: MoeaAlgoType[key],
      value: key
    }))

    const form: UnwrapRef<OptionDesignConfigData> = reactive(
      Object.assign({ ...DEFAULT_OPTION_DESIGN_CONFIG }, props.data)
    )

    const getData = () => {
      return toRaw(form)
    }

    return {
      form,
      algoOptions,
      getData
    }
  }
})
</script>
