import { findIndex } from 'lodash-es'
import { MutationTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { UserMutationEnum } from '@/store/enums/mutations/user'
import { UserStateEnum } from '@/store/enums/states/user'
import { UserMutation } from '@/store/types/mutations/user'
import { UserState } from '@/store/types/states/user'
import { UserList } from '@/types/api/user'
import { User } from '@/types/database/user'

const mutations: MutationTree<UserState> & UserMutation = {
  // Create user
  [UserMutationEnum.CREATE_USER](state: UserState) {
    state[UserStateEnum.IS_CREATING] = true
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.CREATING_USER
  },
  [UserMutationEnum.CREATE_USER_FULFILLED](state: UserState, user: User) {
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
    state[UserStateEnum.IS_CREATING] = false
    state[UserStateEnum.CURRENT_USER] = user
    const newContent = [...state[UserStateEnum.USER_LIST]?.content, user]
    state[UserStateEnum.USER_LIST] = {
      ...state[UserStateEnum.USER_LIST],
      content: newContent
    }
  },
  [UserMutationEnum.CREATE_USER_REJECTED](state: UserState, error: Error) {
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
    state[UserStateEnum.IS_CREATING] = false
    state[UserStateEnum.ERROR] = error
  },
  // Get users
  [UserMutationEnum.GET_USERS](state: UserState) {
    state[UserStateEnum.IS_GETTING] = true
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_USER
  },
  [UserMutationEnum.GET_USERS_FULFILLED](state: UserState, users: UserList) {
    state[UserStateEnum.IS_GETTING] = false
    state[UserStateEnum.USER_LIST] = users
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [UserMutationEnum.GET_USERS_REJECTED](state: UserState) {
    state[UserStateEnum.IS_GETTING] = false
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Get user
  [UserMutationEnum.GET_USER](state: UserState) {
    state[UserStateEnum.IS_GETTING] = true
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_USER
  },
  [UserMutationEnum.GET_USER_FULFILLED](state: UserState, user: User) {
    state[UserStateEnum.IS_GETTING] = false
    state[UserStateEnum.CURRENT_USER] = user
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [UserMutationEnum.GET_USER_REJECTED](state: UserState) {
    state[UserStateEnum.IS_GETTING] = false
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Update user
  [UserMutationEnum.UPDATE_USER](state: UserState) {
    state[UserStateEnum.IS_UPDATING] = true
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.UPDATING_USER
  },
  [UserMutationEnum.UPDATE_USER_FULFILLED](state: UserState, user: User) {
    const updatedUserId = user?.id
    const index = findIndex(state[UserStateEnum.USER_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: updatedUserId
    })
    state[UserStateEnum.IS_UPDATING] = false
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
    state[UserStateEnum.CURRENT_USER] = user
    if (index !== -1) {
      const newContent = [
        ...state[UserStateEnum.USER_LIST]?.content?.slice(0, index),
        user,
        ...state[UserStateEnum.USER_LIST]?.content?.slice(index + 1)
      ]
      state[UserStateEnum.USER_LIST] = {
        ...state[UserStateEnum.USER_LIST],
        content: newContent
      }
    }
  },
  [UserMutationEnum.UPDATE_USER_REJECTED](state: UserState) {
    state[UserStateEnum.IS_UPDATING] = false
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Delete user
  [UserMutationEnum.DELETE_USER](state: UserState) {
    state[UserStateEnum.IS_DELETING] = true
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.DELETING_USER
  },
  [UserMutationEnum.DELETE_USER_FULFILLED](state: UserState, id: User['id']) {
    const index = findIndex(state[UserStateEnum.USER_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: id
    })
    state[UserStateEnum.IS_DELETING] = false
    state[UserStateEnum.CURRENT_USER] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
    if (index !== -1) {
      const newContent = [
        ...state[UserStateEnum.USER_LIST]?.content?.slice(0, index),
        ...state[UserStateEnum.USER_LIST]?.content?.slice(index + 1)
      ]
      state[UserStateEnum.USER_LIST] = {
        ...state[UserStateEnum.USER_LIST],
        content: newContent
      }
    }
  },
  [UserMutationEnum.DELETE_USER_REJECTED](state: UserState) {
    state[UserStateEnum.IS_DELETING] = false
    state[UserStateEnum.ERROR] = null
    state[UserStateEnum.LOADING_PROMPT_TEXT] = null
  }
}

export { mutations }
