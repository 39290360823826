
import { message } from 'ant-design-vue'
import { is, reduce } from 'ramda'
import { defineComponent, ref, watch } from 'vue'

import { Variable } from '@/libs/bayes/Variable'

export const EVENTS = {
  CHANGE: 'update:modelValue',
  USER_CHANGE: 'onUserChange'
}

export default defineComponent({
  components: {},
  props: {
    modelValue: { type: Number, default: undefined },
    variable: Variable,
    config: { type: Object, default: undefined },
    isDeterministic: { type: Boolean, default: false },
    disabled: { type: Boolean }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const min = ref(props.config?.min)
    const max = ref(props.config?.max)
    const step = ref(props.isDeterministic ? 100 : 1)
    const val = ref<number | undefined>(props.modelValue)
    const marks = ref(
      reduce(
        (acc: Record<string, number>, val) => {
          acc[`${val}`] = val
          return acc
        },
        {},
        props.isDeterministic ? [0, 100] : [0, 25, 50, 75, 100]
      )
    )
    watch(
      () => props.modelValue,
      (v) => {
        val.value = is(Number, v) ? v : undefined
      }
    )
    watch(val, (v) => {
      if (props.isDeterministic) {
        if (v !== 0 && v !== 100) {
          message.info('This is a deterministic node that requires either 0 or 100 value')
          if (typeof v != 'undefined') {
            if (v < 50) {
              v = 0
            } else {
              v = 100
            }
          }
        }
      }
      emit(EVENTS.CHANGE, is(Number, v) ? v : undefined)
    })

    const change = () => {
      emit(EVENTS.USER_CHANGE, val.value)
    }

    return {
      change,
      step,
      marks,
      max,
      min,
      val
    }
  }
})
