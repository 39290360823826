<template>
  <div class="sz-analysis">
    <workspace-info :workspace="workspace" />
    <div class="sz-command-bar">
      <a-button type="link" @click="saveJob">
        <template #icon>
          <Icon :class="cssIcon">
            <DeviceFloppy />
          </Icon>
        </template>
        <span>Save current analysis job</span>
      </a-button>
    </div>
    <a-tabs v-model:activeKey="activeTab" tab-position="left">
      <a-tab-pane :key="TABS.PARAMS" tab="Parameters">
        <splitpanes class="default-theme" horizontal>
          <pane size="50">
            <div class="sza-networks-wrapper">
              <div class="heading">
                <div class="title">Networks</div>
              </div>
              <NetworkList
                :workspace-id="workspaceId"
                :networks="networks"
                @[NET_EVENTS.NETWORK_IMPORTED]="loadNetworks"
                @[NET_EVENTS.NETWORK_REMOVE]="removeNetwork"
              />
            </div>
          </pane>
          <pane>
            <div class="sza-node-selections-wrapper">
              <div class="title">All Variables</div>
              <div v-if="commonNodeKeys.length == 0 && networks.length > 1" class="warning">
                No variables common in all networks
              </div>
              <what-if-node-selection
                :node-selections="nodeSelections"
                :config="selectionConfig"
                @[VAR_EVENTS.TOGGLE_INPUT]="toggleInput"
                @[VAR_EVENTS.TOGGLE_OUTPUT]="toggleOutput"
                @[VAR_EVENTS.UTILITY_VECTOR_CHANGE]="onUtilityUpdated"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
      <a-tab-pane :key="TABS.TASKS" tab="Tasks (Batch)">
        <splitpanes class="default-theme" horizontal>
          <pane min-size="100" size="100">
            <div class="heading">
              <div class="title">Tasks</div>
            </div>
            <div style="min-height: 100px; overflow: auto">
              <what-if-task-list
                :tasks="tasks"
                :networks="networks"
                :network-map="networkMap"
                :config="{
                  updateTasks: false,
                  showResults: false,
                  exportResults: true,
                  importTasks: true
                }"
                @[TASK_EVENTS.RUN]="run"
                @[TASK_EVENTS.IMPORT]="importBatch"
                @[TASK_EVENTS.EXPORT]="exportResults"
                @[TASK_EVENTS.ABORT]="abort"
                @[TASK_EVENTS.REFRESH]="refresh"
                @[TASK_EVENTS.REMOVE]="remove"
                @[TASK_EVENTS.RUN_ALL]="runAll"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
      <a-tab-pane :key="TABS.RESULTS" tab="Results (Interactive)">
        <splitpanes class="default-theme" horizontal>
          <pane min-size="10" size="50">
            <div class="heading">
              <div class="title">What-if Table</div>
            </div>
            <div class="sz-command-bar">
              <a-button type="link" @click="refresh">
                <template #icon>
                  <Icon :class="cssIcon">
                    <Refresh />
                  </Icon>
                </template>
                <span>Refresh</span>
              </a-button>
              <a-button type="link" @click="init">
                <template #icon>
                  <Icon size="14">
                    <LayoutGridAdd />
                  </Icon>
                </template>
                <span>Init</span>
              </a-button>
              <a-button type="link" @click="preCalculate">
                <template #icon>
                  <Icon size="14">
                    <Run />
                  </Icon>
                </template>
                <span>Update defaults (Sync)</span>
              </a-button>
              <a-button type="link" @click="calculate">
                <template #icon>
                  <Icon size="14">
                    <Run />
                  </Icon>
                </template>
                <span>Run Interactive</span>
              </a-button>
              <a-button type="link" @click="plotResults">
                <template #icon>
                  <Icon size="14">
                    <ChartBar />
                  </Icon>
                </template>
                <span>Plot Chart</span>
              </a-button>
              <!-- <a-button type="primary" @click="showPalette">Change Color Scheme</a-button> -->
            </div>
            <div style="min-height: 100px; overflow: auto">
              <what-if-table
                v-if="tasks.length"
                :ref="
                  (el) => {
                    if (el) {
                      table = el
                    }
                  }
                "
                :key="tableKey"
                :tasks="tasks"
                :selections="nodeSelections"
                :rows="whatIfRows"
                @[TABLE_EVENTS.ON_DUPLICATE_TASK]="onDuplicateTask"
                @[TABLE_EVENTS.ON_REMOVE_TASK]="onRemoveTask"
                @[TABLE_EVENTS.ON_RUN_TASK]="run"
                @[TABLE_EVENTS.ON_TOGGLE_TASK]="toggleTask"
                @[TABLE_EVENTS.ON_MOVE_UP_ROW]="onMoveUpRow"
                @[TABLE_EVENTS.ON_MOVE_DOWN_ROW]="onMoveDownRow"
              />
            </div>
          </pane>
          <pane>
            <div class="sz-command-bar">
              <a-button type="link" @click="showPalettePicker">
                <template #icon>
                  <Icon size="14">
                    <Palette />
                  </Icon>
                </template>
                <span>Change Chart Colors</span>
              </a-button>
            </div>
            <div style="overflow: auto">
              <what-if-apex-chart
                v-if="chartNodeIds.length && chartData.length"
                :node-ids="chartNodeIds"
                :data="chartData"
                :colors="paletteColors"
                :max="maxOutputValue"
                :min="minOutputValue"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
    </a-tabs>
  </div>
  <common-analysis
    v-model:palette-visible="paletteVisible"
    :is-loading="isCreatingJob || isUpdatingJob || isExecuting || isParsing || isPersisting"
    :palette-name="paletteName"
    @[COMMON_EVENTS.COLOR_CHANGED]="selectPalette"
  />
  <WhatIfBatchImport
    v-if="nodeSelections"
    :is-visible="isBatchImportVisible"
    :network-map="networkMap"
    @[IMPORT_EVENTS.OK]="importBatchOk"
    @[IMPORT_EVENTS.CANCEL]="isBatchImportVisible = false"
  />
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

import { ChartBar, DeviceFloppy, LayoutGridAdd, Palette, Refresh, Run } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { indexBy, prop } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import writeXlsxFile from 'write-excel-file'

import CommonAnalysis, {
  EVENTS as COMMON_EVENTS
} from '@/components/analysis/common/CommonAnalysis.vue'
import NetworkList, {
  EVENTS as NET_EVENTS
} from '@/components/analysis/common/network-list/NetworkList.vue'
import WhatIfNodeSelection, {
  EVENTS as VAR_EVENTS
} from '@/components/analysis/common/node-selection/NodeSelectionTable.vue'
import WhatIfTaskList, {
  EVENTS as TASK_EVENTS
} from '@/components/analysis/common/task-list/TaskList.vue'
import WorkspaceInfo from '@/components/analysis/common/workspace-info/WorkspaceInfo.vue'
import useAnalysis from '@/components/analysis/composable/analysis'
import useBase, { TABS } from '@/components/analysis/composable/base'
import useJob from '@/components/analysis/composable/job'
import usePalette from '@/components/analysis/composable/palette'
import useWhatIf, {
  inputNodeMapper,
  outputNodeMapper
} from '@/components/analysis/composable/whatif'
import { AnalysisTask } from '@/components/analysis/libs/common'
import WhatIfApexChart from '@/components/analysis/whatif/WhatIfApexChart.vue'
// import WhatIfChart from '@/components/analysis/whatif/WhatIfChart.vue'
import WhatIfTable, { EVENTS as TABLE_EVENTS } from '@/components/analysis/whatif/WhatIfTable.vue'
import { CATEGORICAL_PALETTES, ColorSchemeItem } from '@/components/common/color-scheme'
import { EMIT_EVENTS } from '@/constants/emits'
import { ROUTE_NAME } from '@/constants/router'
dayjs.extend(relativeTime)
import { logger } from '@/libs/logger'
import { gradientMaker } from '@/libs/utils'
import { cssIcon } from '@/styles/common'
import { JobType } from '@/types/database/job'

import WhatIfBatchImport, { EVENTS as IMPORT_EVENTS } from './WhatIfBatchImport.vue'

const CATEGORICAL_PALETTE_MAP = indexBy(prop('name'), CATEGORICAL_PALETTES)

export default defineComponent({
  components: {
    ChartBar,
    CommonAnalysis,
    DeviceFloppy,
    LayoutGridAdd,
    Run,
    NetworkList,
    Icon,
    Pane,
    Refresh,
    Splitpanes,
    WhatIfBatchImport,
    WhatIfNodeSelection,
    // WhatIfChart,
    WhatIfApexChart,
    WhatIfTable,
    Palette,
    WorkspaceInfo,
    WhatIfTaskList
  },
  setup() {
    const activeTab = ref(TABS.PARAMS)
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const selectedTasks = ref(new Set<AnalysisTask>())
    const isBatchImportVisible: Ref<boolean> = ref(false)
    const isParsing: Ref<boolean> = ref(false)

    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const selectionConfig = {
      inputVisible: true,
      utilityVectorVisible: true,
      stateVisible: false,
      variationVisible: false,
      variationsVisible: false,
      constraintVisible: false,
      headers: {
        input: {
          title: 'Input',
          desc: 'Input Node'
        },
        output: {
          title: 'Output',
          desc: 'Output Node'
        }
      }
    }
    const {
      networks,
      loadWorkspace,
      loadNetworks,
      removeNetwork,
      workspace,
      workspaceCreated,
      workspaceModified
    } = useBase(workspaceId)

    const {
      paletteVisible,
      paletteName,
      paletteColors,
      selectPalette,
      showPalettePicker
    } = usePalette()

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }

    const toggleTask = (task: AnalysisTask) => {
      // task.isSelected = !task.isSelected
      if (task.isSelected) {
        selectedTasks.value.add(task)
      } else {
        selectedTasks.value.delete(task)
      }
    }

    const {
      networkMap,
      currentUser,
      nodeSelections,
      nodeSelectionMap,
      updateNodeSelections,
      inputNodeKeys,
      outputNodeKeys,
      updateInputOutputNodes,
      commonNodeKeys
    } = useAnalysis(workspaceId, JobType.WHATIF, networks)

    const {
      isPersisting,
      abortTask,
      tasks,
      taskMap,
      clearTasks,
      loadJob,
      loadTasks,
      persistJob,
      persistTasks,
      addTask,
      initTasks,
      executeTask,
      removeTask,
      isUpdatingJob,
      isCreatingJob
    } = useJob(
      currentUser,
      workspaceId,
      JobType.WHATIF,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      onSuccessSaveJob
    )

    const {
      clearCharts,
      plotCharts,
      chartData,
      chartNodeIds,
      exportTabular,
      whatIfRows,
      onMoveDownRow,
      onMoveUpRow,
      updateWhatIfRows,
      executeAnalysis,
      preExecuteAnalysis,
      updateRowsWithUtilityVector,
      updateTasksFromWhatIfRows,
      isExecuting,
      maxOutputValue,
      minOutputValue,
      parseWhatIfTasksRaw,
      updateDeterministicValues
    } = useWhatIf(
      workspaceId,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      taskMap,
      updateTable,
      onSuccessSaveJob
    )

    const networkColumns = computed(() => {
      return [
        {
          title: 'Network name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Nodes count',
          dataIndex: 'nodes.length',
          key: 'nodes'
        }
      ]
    })

    const networkRows = computed(
      () =>
        networks.value?.map((network: any) => ({
          key: network.id,
          nodes: network.nodes,
          name: network.name
        })) || []
    )

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(workspace.value?.name || 'UNTITLED')
    }))

    const handleLoadNetwork = () => {
      loadNetworks()
    }

    const calculate = () => {
      executeAnalysis()
      updateTable()
    }

    const preCalculate = () => {
      preExecuteAnalysis()
      updateTable()
    }

    const toggleInput = () => {
      updateInputOutputNodes()
      // updateWhatIfRows()
    }
    const toggleOutput = () => {
      updateInputOutputNodes()
      // updateWhatIfRows()
    }

    const init = async () => {
      message.info('Initializing ...')
      initTasks()
      updateInputOutputNodes()
      updateWhatIfRows()
      await preExecuteAnalysis()
      message.success('Initialization completed')
    }

    const onUtilityUpdated = (key: string) => {
      updateRowsWithUtilityVector(key)
    }

    watch(networks, async () => {
      logger.info('watch - networks')
      updateNodeSelections()
      await loadJob()
      await loadTasks()
      updateInputOutputNodes()
      updateWhatIfRows()
    })

    const refresh = async () => {
      await loadTasks()
      updateInputOutputNodes()
      updateWhatIfRows()
      clearCharts()
    }

    watch(nodeSelections, () => {
      updateWhatIfRows()
    })

    onMounted(() => {
      loadWorkspace()
      loadNetworks()
    })

    const saveJob = async () => {
      updateTasksFromWhatIfRows()
      updateDeterministicValues()
      await persistJob()
      await persistTasks()
    }

    const selectTaskPalette = () => {
      paletteColors.value = CATEGORICAL_PALETTE_MAP[paletteName.value]?.items?.map(
        ({ color }: ColorSchemeItem) => color
      )
      paletteVisible.value = false
    }

    const onDuplicateTask = (taskId: string, taskIndex: number) => {
      updateTasksFromWhatIfRows()
      addTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    const onRemoveTask = (taskId: string, taskIndex: number) => {
      updateTasksFromWhatIfRows()
      removeTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    const importBatch = () => {
      isBatchImportVisible.value = true
    }

    const parsingComplete = () => {
      setTimeout(() => {
        isParsing.value = false
      })
    }

    const importBatchOk = async (rawText: string) => {
      isBatchImportVisible.value = false
      isParsing.value = true
      const {
        newTasks,
        error,
        outputNodeKeys: outKeys,
        inputNodeKeys: inKeys
      } = parseWhatIfTasksRaw(rawText)
      if (error) {
        message.error(error)
        parsingComplete()
        return
      }
      clearTasks()
      tasks.value = newTasks
      outputNodeKeys.value = outKeys
      inputNodeKeys.value = inKeys
      updateWhatIfRows()
      parsingComplete()
    }

    const exportResults = async () => {
      const { datas, names } = exportTabular()
      await writeXlsxFile(datas, {
        sheets: names,
        fileName: workspace.value?.name + '-results.xlsx'
      })
    }

    const run = (task: AnalysisTask) => {
      executeTask(task)
    }

    const runAll = async () => {
      message.info('All tasks are submitted to the batch execution')
      await executeTask()
      loadTasks()
    }

    const plotResults = () => {
      const tasks_ = selectedTasks.value?.size ? selectedTasks.value : new Set(tasks.value)
      plotCharts(tasks_)
    }

    const abort = (task: AnalysisTask) => {
      if (!abortTask(task)) {
        message.error('You need to save the job first')
      }
    }

    const remove = async (taskId: string, taskIndex: number) => {
      removeTask(taskId, taskIndex)
      await saveJob()
      updateWhatIfRows()
    }

    return {
      abort,
      remove,
      toggleTask,
      run,
      runAll,
      exportResults,
      importBatchOk,
      activeTab,
      importBatch,
      isPersisting,
      isBatchImportVisible,
      calculate,
      cardStyle,
      CATEGORICAL_PALETTE_MAP,
      CATEGORICAL_PALETTES,
      chartData,
      chartNodeIds,
      COMMON_EVENTS,
      commonNodeKeys,
      cssIcon,
      EMIT_EVENTS,
      handleLoadNetwork,
      init,
      refresh,
      isParsing,
      inputNodeKeys,
      isCreatingJob,
      isExecuting,
      isUpdatingJob,
      loadNetworks,
      maxOutputValue,
      minOutputValue,
      NET_EVENTS,
      TASK_EVENTS,
      networkColumns,
      networkRows,
      networkMap,
      networks,
      nodeSelections,
      onDuplicateTask,
      onMoveDownRow,
      plotResults,
      onMoveUpRow,
      onRemoveTask,
      onUtilityUpdated,
      outputNodeKeys,
      paletteColors,
      paletteName,
      paletteVisible,
      preCalculate,
      removeNetwork,
      ROUTE_NAME,
      saveJob,
      selectionConfig,
      selectPalette,
      selectTaskPalette,
      showPalettePicker,
      IMPORT_EVENTS,
      TABLE_EVENTS,
      table,
      tableKey,
      TABS,
      tasks,
      toggleInput,
      toggleOutput,
      VAR_EVENTS,
      whatIfRows,
      workspace,
      workspaceCreated,
      workspaceId,
      workspaceModified
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/base.styl"
@import "../../../styles/commons.styl"
@import "../styles/common.styl"
.sz-page-spinner
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  padding: 0
  margin: 0
  background-color: rgba(255, 255, 255, 0.5)
  > div
    @extend .centered
    margin auto
</style>
