import { ttl } from '@/constants/api'

const MESSAGE = {
  ALLOCATION_NOT_SET: 'No allocation found for current user',
  SESSION_EXPIRE: 'Session expired',
  INVALID_CREDENTIAL: 'Invalid user name or password',
  UNEXPECTED_WORKSPACE_ID: (workspaceId: any): string =>
    `Unexpected workspaceId ${workspaceId}. You will be redirect to home page in 3 seconds.`,
  UNEXPECTED_SURVEY_ID: ttl`Unexpected surveyId \`${'surveyId'}\`. You will be redirect to home page in 3 seconds.`,
  UNEXPECTED_USER_ID: (userId: any): string =>
    `Unexpected userId ${userId}. You will be redirect to home page in 3 seconds.`,
  NETWORK_NOT_SET: 'No network has been set up for this workspace',
  CPT_NOT_SAVED: 'No CPT existed for current survey, please save CPTs first',
  EXPORT_DATA_NOT_FOUND:
    'No export data found, please make sure at list one user has submit his/her responses',
  NETWORK_VARIABLE_DESCRIPTION_PLACEHOLDER: 'Please input description here.',
  NETWORK_VARIABLE_DESCRIPTION_UPDATE_SUCCESS: 'Descriptions updated successfully.',
  NETWORK_VARIABLE_DESCRIPTION_UPDATE_FAIL: 'Failed to update descriptions.',
  CPT_RESPONSES_SAVE_SUCCESS: 'CPT responses saved successfully.',
  CPT_RESPONSES_SAVE_FAIL: 'Failed to save CPT responses.',
  PERSIST_CPT_SUCCESS: 'CPTs persisted successfully',
  PERSIST_CPT_FAIL: 'Failed to persist CPTs',
  SEND_SURVEY_SUCCESS: 'Survey email is queued for sending',
  SEND_SURVEY_FAIL: 'Fail to send survey.',
  PUBLISH_SURVEY_SUCCESS: 'Survey published successfully.',
  PUBLISH_SURVEY_FAIL: 'Failed to publish survey.',
  QUESTIONNAIRE_SURVEY_STATUS_PENDING:
    'You are in view-only mode, since the survey has not published yet.',
  QUESTIONNAIRE_SURVEY_STATUS_PENDING_REDIRECT:
    'The survey has not published yet. You will be redirected to the user account page in 3 seconds.',
  SURVEY_CONFIG_CREATE_SUCCESS: 'Survey created successfully.',
  SURVEY_CONFIG_UPDATE_SUCCESS: 'Survey config updated successfully.',
  ROUTE_ACCESS_NOT_ALLOWED: 'You do not have access rights to this page.',
  USER_CREATED_SUCCESS: 'User has been created successfully.',
  USER_ENABLED_SUCCESS: ttl`User ${'username'} has been enabled successfully.`,
  USER_DISABLED_SUCCESS: ttl`User ${'username'} has been disabled successfully.`,
  USER_DELETED_SUCCESS: 'User has been deleted successfully.',
  USER_UPDATED_SUCCESS: ttl`User ${'username'} has been updated successfully.`,
  PROFILE_UPDATED_SUCCESS: 'Profile has been updated successfully.',
  PASSWORD_CHANGED_SUCCESS: 'Password has been updated successfully.',
  WORKSPACE_CREATE_SUCCESS: 'Workspace created successfully.',
  WORKSPACE_CREATE_FAIL: 'Unable to create a workspace. Possibly due to insufficient rights.',
  WORKSPACE_UPDATE_SUCCESS: 'Workspace updated successfully.',
  WORKSPACE_DELETE_SUCCESS: 'Workspace deleted successfully.',

  // Loading
  CREATING_USER: 'Creating user',
  GETTING_USER: 'Getting user',
  UPDATING_USER: 'Updating user',
  DELETING_USER: 'Deleting user',

  GETTING_WORKSPACE: 'Getting workspace',
  CREATING_WORKSPACE: 'Creating workspace',
  UPDATING_WORKSPACE: 'Updating workspace',
  DELETING_WORKSPACE: 'Deleting workspace',

  IMPORTING_NETWORK: 'Importing network',
  GETTING_NETWORK: 'Getting network',
  UPDATING_NETWORK: 'Updating network',
  DELETING_NETWORK: 'Deleting network',

  GETTING_SURVEY: 'Getting survey',
  CREATING_SURVEY: 'Creating survey',
  UPDATING_SURVEY: 'Updating survey',
  DELETING_SURVEY: 'Deleting survey',
  EXPORTING_SURVEY: 'Exporting survey',

  GETTING_ALLOCATION: 'Getting allocation',
  CREATING_ALLOCATION: 'Creating allocation',
  UPDATING_ALLOCATION: 'Updating allocation',
  DELETING_ALLOCATION: 'Deleting allocation'
}

export { MESSAGE }
