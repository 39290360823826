<template>
  <div class="sza-hybrid-stats">
    <div class="sza-matrix">
      <div class="heading">Comparison Matrix</div>
      <matrix-display v-if="matrix" :matrix="matrix" />
    </div>
    <div class="sza-stats">
      <div class="heading">Indicators</div>
      <div class="stats-display">
        <div class="row">
          <span>Maximum Eigen Value</span>
          <span>{{ probFormatter(stats.maxEigenValue) }}</span>
        </div>
        <div class="row">
          <span>Consistency Ratio</span>
          <span>{{ probFormatter(stats.consistencyRatio) }}</span>
        </div>
        <div class="row">
          <span>Consistency Ratio (Alonso Lamata)</span>
          <span>{{ probFormatter(stats.consistencyRatioAlonsoLamata) }}</span>
        </div>
        <div v-if="isAggregate" class="row">
          <span>Consensus indicator</span>
          <span>{{ probFormatter(stats.consensusIndicator) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import MatrixDisplay from '@/components/analysis/common/matrix-display/MatrixDisplay.vue'
import { StatType } from '@/components/analysis/composable/hybrid-types'
import { probFormatter } from '@/libs/common'

export default defineComponent({
  components: {
    MatrixDisplay
  },
  props: {
    matrix: { type: Array as PropType<Array<number[]> | undefined>, default: undefined },
    stats: { type: Object as PropType<StatType>, default: () => ({}) },
    isAggregate: { type: Boolean, default: false }
  },
  setup() {
    return {
      probFormatter
    }
  }
})
</script>
