import localForage from 'localforage'

import LOCAL_STORAGE from '@/constants/localStorage'

const store = localForage.createInstance({
  driver: localForage.LOCALSTORAGE,
  name: LOCAL_STORAGE.NAME,
  version: 1.0,
  storeName: LOCAL_STORAGE.STORE_NAME
})

export default store
