/**
 * Enum value for user state
 */
export enum UserStateEnum {
  IS_CREATING = 'isCreating',
  IS_UPDATING = 'isUpdating',
  IS_DELETING = 'isDeleting',
  IS_GETTING = 'isGetting',
  ERROR = 'error',
  USER_LIST = 'userList',
  CURRENT_USER = 'currentUser',
  LOADING_PROMPT_TEXT = 'loadingPromptText'
}
