import { size } from 'lodash-es'
import { keys, reduce } from 'ramda'

import { objectId } from '@/libs/utils'
import {
  CPTEntrySchema,
  CPTRowSchema,
  CPTSchema,
  CPTSummaryRowSchema,
  CPTSummarySchema
} from '@/types'

import { Combination, CombinationRow } from './Combination'
import { CPTSummary, CPTSummaryRow } from './CPTSummary'
import { CPTMethod } from './enums/CPTMethod'
import { CPTType } from './enums/CPTType'
import { AceDstFullCPT } from './methods/AceDstFullCPT'
import { AceDstSimpleCPT } from './methods/AceDstSimpleCPT'
import { CainCPT } from './methods/CainCPT'
import { CPT } from './methods/CPT'
import { Marginal } from './methods/Marginal'
import { Network } from './Network'
import { State } from './State'
import { Variable } from './Variable'

export type CollectionMethodMap = Record<Variable['id'], CPTMethod>
export type CPTMap = Record<Variable['id'], Record<State['id'], CPT>>

export class CPTSet {
  network: Network
  variables: Array<Variable>
  cptMap: CPTMap
  method: CPTMethod
  collectionMethodMap: CollectionMethodMap

  constructor(
    network: Network,
    method: CPTMethod = CPTMethod.ACE_DST,
    collectionMethodMap?: CollectionMethodMap
  ) {
    this.network = network
    this.variables = network.variables
    this.method = method
    this.collectionMethodMap = { ...collectionMethodMap } || {}
    this.cptMap = {}
    this.initCptMap()
  }

  initCptMap(): void {
    this.cptMap = reduce(
      (acc, variable) => {
        acc[variable.id] = {}
        return acc
      },
      {} as CPTMap,
      this.variables
    )
  }

  assign(variable: Variable, state: State, cpt: CPT): void {
    this.cptMap[variable.id][state.id] = cpt
  }

  updateEffectiveMethod(variable: Variable): CPTMethod {
    const isTerminal = this.network.isTerminal(variable)
    let method = this.collectionMethodMap[variable.id] || CPTMethod.DEFAULT
    if (isTerminal) {
      method = CPTMethod.MARGINAL
    } else if (method === CPTMethod.DEFAULT) {
      method = this.method === CPTMethod.MIXED ? CPTMethod.ACE_DST : this.method
    }
    this.collectionMethodMap[variable.id] = method
    return method
  }

  getEffectiveMethod(variable: Variable): CPTMethod {
    return this.collectionMethodMap[variable.id]
  }

  updateMethodForVariable(variable: Variable, method: CPTMethod): void {
    this.collectionMethodMap[variable.id] = method
    const state = variable.getPositiveState().state
    const cpt = this.generateCptForVariable(variable)
    if (cpt) {
      cpt.generateElicitedRows()
      cpt.generateCalculatedRows()
      this.cptMap[variable.id][state.id] = cpt
    }
  }

  generateCptForVariable(variable: Variable, id?: string): CPT | undefined {
    let cpt
    const state = variable.getPositiveState().state
    const method = this.updateEffectiveMethod(variable)
    if (method === CPTMethod.MARGINAL) {
      cpt = new Marginal(this.network, variable, state, id)
    } else if (method === CPTMethod.ACE_DST_SIMPLE || method === CPTMethod.WEIGHTED) {
      cpt = new AceDstSimpleCPT(this.network, variable, state, id)
    } else if (method === CPTMethod.ACE_DST) {
      cpt = new AceDstFullCPT(this.network, variable, state, id)
    } else if (method === CPTMethod.CAIN) {
      cpt = new CainCPT(this.network, variable, state, id)
    } else {
      return undefined
    }
    return cpt
  }

  generateCombinations(): void {
    this.initCptMap()
    for (let i = 0; i < this.variables.length; i++) {
      const variable = this.variables[i]
      const cpt = this.generateCptForVariable(variable)
      const state = variable.getPositiveState().state
      if (cpt) {
        cpt.generateElicitedRows()
        cpt.generateCalculatedRows()
        this.cptMap[variable.id][state.id] = cpt
      }
    }
  }

  getCPT(variable: Variable): CPT {
    const state = variable.getPositiveState().state
    return this.cptMap[variable.id][state.id]
  }

  processRows(
    combinationRows: Array<CombinationRow>,
    type: CPTType = CPTType.ELICITED
  ): Array<CPTRowSchema> {
    const rows = []
    for (let k = 0; k < combinationRows.length; k++) {
      const { combination } = combinationRows[k]
      const parents = []
      for (let l = 0; l < combination.length; l++) {
        const { variable, state } = combination[l]
        parents.push({
          variableId: variable.id,
          stateId: state.id
        })
      }
      const id = objectId()
      const row: CPTRowSchema = {
        id,
        type,
        parents
      }
      rows.push(row)
    }
    return rows
  }

  serialize(type: CPTType | undefined, surveyId: string): Array<CPTEntrySchema> {
    // let variables = this.network.variables;
    const cpts: Array<CPTEntrySchema> = []
    for (let i = 0; i < this.variables.length; i++) {
      const variable = this.variables[i]
      const variableId = variable.id
      let method
      const effMethod = this.getEffectiveMethod(variable)
      if (effMethod == CPTMethod.MARGINAL) {
        method = 'MARGINAL'
      } else if (effMethod == CPTMethod.CAIN) {
        method = 'CAIN'
      } else {
        method = 'ACE'
      }
      const stateIDs = keys(this.cptMap[variableId])
      for (let j = 0; j < stateIDs.length; j++) {
        const stateId = stateIDs[j]
        let rows: Array<CPTRowSchema> = []
        const { elicitedRows = [], calculatedRows = [] } = this.cptMap[variableId][stateId]
        if (!type || type === CPTType.ELICITED) {
          rows = rows.concat(this.processRows(elicitedRows, CPTType.ELICITED))
        }
        if (!type || type === CPTType.CALCULATED) {
          rows = rows.concat(this.processRows(calculatedRows, CPTType.CALCULATED))
        }
        cpts.push({
          variableId,
          stateId,
          rows,
          method,
          networkId: this.network.id,
          surveyId: surveyId
        })
      }
    }
    return cpts
  }

  load(cpts: Array<CPTSchema>): void {
    const net = this.network
    const variableMap = net.variableMap
    const cptMap: CPTMap = {}
    let cpt
    for (let i = 0; i < cpts.length; i++) {
      const { variableId, stateId, rows, id } = cpts[i]
      const variable = variableMap[variableId]
      if (!variable) {
        continue
      }
      // const state = variable.stateMap[stateId]
      const combinations = []
      const elicitedRows = []
      const calculatedRows = []
      for (let j = 0; j < rows.length; j++) {
        const row = rows[j]
        const parents = row.parents
        const rowId = row.id
        const type = row.type
        const combination: Combination = []
        for (let k = 0; k < parents.length; k++) {
          const { variableId, stateId } = parents[k]
          const parentVariable = variableMap[variableId]
          if (!parentVariable) {
            continue
          }
          const parentState = parentVariable.stateMap[stateId]
          combination.push({
            variable: parentVariable,
            state: parentState,
            rowId
          })
        }
        const newRow: CombinationRow = new CombinationRow(combination, type, rowId)
        newRow.combination = combination
        if (type === CPTType.ELICITED) {
          combinations.push(combination)
          elicitedRows.push(newRow)
        } else if (type === CPTType.CALCULATED) {
          calculatedRows.push(newRow)
        }
      }
      if (!cptMap[variableId]) {
        cptMap[variableId] = {}
      }
      cpt = this.generateCptForVariable(variable, id)
      if (cpt) {
        // Set some values
        cpt.generateElicitedRows()
        cpt.setElicitedRows(combinations, elicitedRows)
        cpt.setCalculatedRows(calculatedRows)
        cptMap[variableId][stateId] = cpt
      }
    }
    if (size(cptMap) === 0) {
      this.initCptMap()
    } else {
      this.cptMap = cptMap
    }
  }

  serializeRow(row: CPTSummaryRow): any {
    const { parents = [], outcomes = [] } = row
    return {
      id: objectId(),
      parents: parents.map(({ variable, state }) => ({
        variableId: variable.id,
        stateId: state.id
      })),
      outcomes: outcomes.map(({ state, value, rationale }) => ({
        id: objectId(),
        stateId: state.id,
        value: isNaN(value) ? 1 / outcomes.length : value,
        rationale: rationale || ''
      }))
    }
  }

  serializeSummaries(cptSummaries: Array<CPTSummary>, surveyId: string): Array<CPTSummarySchema> {
    const summaries: Array<CPTSummarySchema> = []
    for (let i = 0; i < cptSummaries.length; i++) {
      const cptSummary: CPTSummary = cptSummaries[i]
      const variableId = cptSummary.variable.id
      const rows = cptSummary.rows?.map((row: CPTSummaryRow) => {
        return this.serializeRow(row)
      })
      const summary = {
        variableId,
        networkId: this.network.id,
        surveyId,
        rows: rows || []
      }
      summaries.push(summary)
    }
    return summaries
  }

  serializeSummariesWithOrder(
    network: Network,
    cptSummaries: Array<CPTSummary>,
    surveyId: string
  ): Array<CPTSummarySchema> {
    const summaries: Array<CPTSummarySchema> = []
    for (let i = 0; i < cptSummaries.length; i++) {
      const cptSummary: CPTSummary = cptSummaries[i]
      const variable = cptSummary.variable
      const variableId = variable.id
      const parentOrder: Record<string, any> = network.parentsOriginalOrder[variableId]
      let rows: CPTSummaryRowSchema[] = []
      if (parentOrder) {
        const parentIds = parentOrder.map((v: Variable) => v.id)
        const cpt = this.getCPT(variable)
        const ordereredRows: CombinationRow[] = cpt.generateOrderedRows(parentIds) || []
        const cptSummaryRowMap: Record<string, any> = {}
        cptSummary.rows?.forEach((row: CPTSummaryRow) => {
          const { parents = [] } = row
          const parentMap = parents.reduce((acc: any, { variable, state }) => {
            acc[variable.id] = { variable, state }
            return acc
          }, {})
          const newParents: Combination = parentIds.map((id: string) => parentMap[id])
          cptSummaryRowMap[cpt.makeCombKey(newParents)] = row
        })
        rows = ordereredRows.map((combRow) => {
          const row: CPTSummaryRow = cptSummaryRowMap[combRow.key] || {}
          return this.serializeRow(row)
        })
      } else {
        rows = cptSummary.rows?.map((row: CPTSummaryRow) => this.serializeRow(row)) || []
      }
      const summary = {
        variableId,
        networkId: this.network.id,
        surveyId,
        rows: rows || []
      }
      summaries.push(summary)
    }
    return summaries
  }

  summarize(analyticsMap: Record<string, any>, isAnonymous: boolean): Array<CPTSummary> {
    // let variables = this.network.variables;
    const cptSummaries: Array<CPTSummary> = []
    for (let i = 0; i < this.variables.length; i++) {
      const variable = this.variables[i]
      const state = variable.getPositiveState().state
      const cpt = this.cptMap[variable.id][state.id]
      const cptSummary = cpt.summarize(analyticsMap, isAnonymous)
      if (cptSummary) {
        cptSummaries.push(cptSummary)
      }
    }
    return cptSummaries
  }

  mock(): Array<CPTSummary> {
    // let variables = this.network.variables;
    const cptSummaries: Array<CPTSummary> = []
    for (let i = 0; i < this.variables.length; i++) {
      const variable = this.variables[i]
      const state = variable.getPositiveState().state
      const cpt = this.cptMap[variable.id][state.id]
      const cptSummary = cpt.mock()
      if (cptSummary) {
        cptSummaries.push(cptSummary)
      }
    }
    return cptSummaries
  }
}
