import { render } from "./XdslImport.vue?vue&type=template&id=68de9d7b"
import script from "./XdslImport.vue?vue&type=script&lang=ts"
export * from "./XdslImport.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "68de9d7b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('68de9d7b', script)) {
    api.reload('68de9d7b', script)
  }
  
  module.hot.accept("./XdslImport.vue?vue&type=template&id=68de9d7b", () => {
    api.rerender('68de9d7b', render)
  })

}

script.__file = "src/components/network/XdslImport.vue"

export default script