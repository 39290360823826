import API from '@/axios'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import { NetworkSchema, ResponseSchema, SurveySchema, User } from '@/types'
import { API_PAGEABLE_PARAMS } from '@/types'

/**
 * Get response by id
 * @param id
 */
const getResponse = async (id: ResponseSchema['id']): Promise<any> => {
  try {
    return extractResponse(await API.get(ENDPOINTS.RESPONSE({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get survey responses
 * @param surveyId
 * @param userId
 * @param params
 * @param getAllParams
 */
const getSurveyResponses = async (
  {
    surveyId,
    userId,
    networkId
  }: { surveyId: SurveySchema['id']; userId?: User['id']; networkId?: NetworkSchema['id'] },
  params?: API_PAGEABLE_PARAMS,
  getAllParams: API_PAGEABLE_PARAMS = API_DEFAULT_PAGEABLE_PARAMS
): Promise<any> => {
  try {
    if (params) {
      const newParams = Object.assign({}, params) as API_PAGEABLE_PARAMS & {
        userId?: User['id']
        networkId?: NetworkSchema['id']
      }
      if (userId) {
        newParams.userId = userId
      }
      if (networkId) {
        newParams.networkId = networkId
      }
      const config = {
        params: { ...newParams }
      }
      return extractResponse(await API.get(ENDPOINTS.SURVEY_RESPONSES({ id: surveyId }), config))
    } else {
      const requestParams = Object.assign({}, getAllParams)
      const config = { ...requestParams } as API_PAGEABLE_PARAMS & {
        userId?: User['id']
        networkId?: NetworkSchema['id']
      }
      if (userId) {
        config.userId = userId
      }
      if (networkId) {
        config.networkId = networkId
      }

      return recursivePaginationAPIRequestFn(
        API,
        ENDPOINTS.SURVEY_RESPONSES({ id: surveyId })
      )(config)
    }
  } catch (e) {
    errorHandler(e)
  }
}

const getSurveyAnalysisResponses = async ({
  surveyId,
  userId,
  networkId
}: {
  surveyId: SurveySchema['id']
  userId?: User['id']
  networkId?: NetworkSchema['id']
}): Promise<any> => {
  try {
    const requestParams = API_DEFAULT_PAGEABLE_PARAMS
    const config = { ...requestParams } as API_PAGEABLE_PARAMS & {
      userId?: User['id']
      networkId?: NetworkSchema['id']
    }
    if (userId) {
      config.userId = userId
    }
    if (networkId) {
      config.networkId = networkId
    }
    return recursivePaginationAPIRequestFn(
      API,
      ENDPOINTS.SURVEY_RESPONSES({ id: surveyId })
    )(config)
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Create survey response
 * @param id - survey id
 * @param response
 */
const createResponse = async ({
  id,
  response
}: {
  id: SurveySchema['id']
  response: ResponseSchema
}): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.SURVEY_RESPONSES({ id }), response))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Update response
 * @param response
 */
const updateResponse = async (response: ResponseSchema): Promise<any> => {
  try {
    const { id } = response
    return extractResponse(await API.put(ENDPOINTS.RESPONSE({ id }), response))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Delete response
 * @param id - response id
 */
const deleteResponse = async (id: ResponseSchema['id']): Promise<any> => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.RESPONSE({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

export {
  getSurveyAnalysisResponses,
  createResponse,
  getResponse,
  getSurveyResponses,
  updateResponse,
  deleteResponse
}
