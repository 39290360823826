
import { SearchOutlined } from '@ant-design/icons-vue'
import { Apps } from '@vicons/tabler'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { usePagination } from 'vue-request'

import WorkspaceDialog from '@/components/analysis/workspace/AnalysisWorkspaceDialog.vue'
import WorkspaceCard, {
  EVENTS as AW_EVENTS
} from '@/components/analysis/workspace/AnalysisWorkspaceItem.vue'
import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { getWorkspaces } from '@/services/api/workspace'
import { Workspace } from '@/types'

function workspaceProxy(args: any) {
  const params = {
    [DB_FIELDS.WORKSPACE.TYPE]: DB_ENUM_VALUES.WORKSPACE.TYPE.ANALYSIS,
    ...args
  }
  params.page = params.page - 1
  return getWorkspaces({
    params
  })
}

export default defineComponent({
  components: {
    Apps,
    WorkspaceCard,
    WorkspaceDialog,
    SearchOutlined
  },
  setup() {
    const { data, run, current, totalPage, loading, pageSize } = usePagination(workspaceProxy, {
      defaultParams: [
        {
          size: API_DEFAULT_PAGEABLE_PARAMS.size
        }
      ],
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'size',
        totalKey: 'totalElements'
      }
    })
    const workspaces = computed(() => data.value?.content || [])
    const totalElements = computed(() => data.value?.totalElements || 0)

    const isWorkspaceDialogVisible = ref<boolean>(false)
    const clickedWorkspace = ref<Workspace | undefined>(undefined)
    const workspaceSearchInput = ref<string>('')

    /**
     * Handle display workspace dialog or not
     **/
    const toggleWorkspaceDialog = () => {
      isWorkspaceDialogVisible.value = !isWorkspaceDialogVisible.value
      run({
        page: 1,
        size: API_DEFAULT_PAGEABLE_PARAMS.size
      })
    }

    /**
     * Handle click workspace card to decide whether create a new one or update existed one
     * @param currentWorkspace
     */
    const handleClickWorkspaceCard = (currentWorkspace: Workspace | undefined = undefined) => {
      clickedWorkspace.value = currentWorkspace
      isWorkspaceDialogVisible.value = true
    }

    /**
     * Handle search workspace
     * @param input
     */
    const handleWorkspaceSearch = async (input: string) => {
      run({
        page: 1,
        size: API_DEFAULT_PAGEABLE_PARAMS.size,
        [DB_FIELDS.WORKSPACE.NAME]: input.trim()
      })
    }

    const deleteWorkspace = () => {
      run({
        page: 1,
        size: API_DEFAULT_PAGEABLE_PARAMS.size
      })
    }

    onMounted(() => {
      // current.value = 0
    })

    return {
      deleteWorkspace,
      AW_EVENTS,
      EMIT_EVENTS,
      API_DEFAULT_PAGEABLE_PARAMS,
      workspaces,
      loading,
      pageSize,
      isWorkspaceDialogVisible,
      clickedWorkspace,
      current,
      totalPage,
      totalElements,
      workspaceSearchInput,
      toggleWorkspaceDialog,
      handleClickWorkspaceCard,
      handleWorkspaceSearch
    }
  }
})
