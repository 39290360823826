import { BuilderStateEnum } from '@/store/enums/states/builder'
import { BuilderState } from '@/store/types/states/builder'

/**
 * Default value
 */
const state: BuilderState = {
  [BuilderStateEnum.IS_CREATING]: false,
  [BuilderStateEnum.IS_UPDATING]: false,
  [BuilderStateEnum.IS_DELETING]: false,
  [BuilderStateEnum.IS_GETTING]: false,
  [BuilderStateEnum.NODE_LIST]: [],
  [BuilderStateEnum.EDGE_LIST]: [],
  [BuilderStateEnum.ERROR]: null
}

export { state }
