import { CPTMethod } from '@/libs/bayes/enums/CPTMethod'

const DB_FIELDS = {
  COMMON: {
    CREATED_DATE: 'createdDate',
    CONTENT: 'content', // Used in all GET list
    ID: 'id',
    SCHEMA_VERSION: 'schemaVersion',
    LAST_MODIFIED_DATE: 'lastModifiedDate'
  },
  ALLOCATION: {
    ASSIGNMENTS: 'assignments',
    ASSIGNMENTS_ASSIGNED: 'assigned',
    ASSIGNMENTS_EXT: 'ext',
    ASSIGNMENTS_VARIABLE_ID: 'variableId',
    NETWORK_ID: 'networkId',
    USER_ID: 'userId',
    WORKSPACE_ID: 'workspaceId'
  },
  USER: {
    COMMENTS: 'comments',
    CREATED_DATE: 'createdDate',
    EMAIL: 'email',
    ENABLED: 'enabled',
    EXPERTISE: 'expertise',
    EXT: 'ext',
    FIRST_NAME: 'firstName',
    ID: 'id',
    LAST_MODIFIED_DATE: 'lastModifiedDate',
    LAST_NAME: 'lastName',
    PASSWORD: 'password',
    PRIMARY_PHONE: 'primaryPhone',
    PROFILE_PHOTO: 'profilePhoto',
    ROLES: 'roles',
    SCHEMA_VERSION: 'schemaVersion',
    TITLE: 'title',
    USERNAME: 'username',
    KEY: 'key'
  },
  NETWORK: {
    ID: 'id'
  },
  SURVEY: {
    COLLECTION_METHOD: 'collectionMethod',
    SURROGATE: 'surrogate',
    CPT_ID: 'cptId',
    EXT: 'ext',
    NAME: 'name',
    SCALE: 'scale',
    SCALE_MAX: 'max',
    SCALE_MIN: 'min',
    SCALE_METHOD: 'method',
    SCALE_PRECISION: 'precision',
    SCALE_STEPS: 'steps',
    SCALE_STEPS_DESCRIPTION: 'description',
    SCALE_STEPS_NAME: 'name',
    SCALE_STEPS_VALUE: 'value',
    STATE: 'state',
    WORKSPACE_ID: 'workspaceId'
  },
  SURVEY_STATUS: {
    SENT_ON: 'sentOn',
    STATUS: 'status',
    COMPLETION_RATE: 'completionRate',
    USER_ID: 'userId',
    SURVEY_ID: 'surveyId',
    EXT: 'ext'
  },
  WORKSPACE: {
    NAME: 'name',
    DESCRIPTION: 'description',
    TYPE: 'type',
    CREATOR_ID: 'creatorId',
    ACCESS_CONTROL_LIST: 'accessControlList'
  }
} as const

const DB_ENUM_VALUES = {
  USER: {
    ROLES: {
      ADMIN: 'ADMIN',
      USER: 'USER',
      DESIGNER: 'DESIGNER'
    }
  },
  WORKSPACE: {
    TYPE: {
      ELICITATION: 'ELICITATION',
      ANALYSIS: 'ANALYSIS'
    }
  },
  SURVEY: {
    STATE: {
      CLOSED: 'CLOSED',
      IN_PROGRESS: 'IN_PROGRESS',
      PENDING: 'PENDING'
    },
    COLLECTION_METHOD: CPTMethod,
    SCALE_METHOD: {
      CONTINUOUS: 'CONTINUOUS',
      PHRASE: 'PHRASE'
    }
  },
  SURVEY_USER_STATUS: {
    STATUS: {
      PENDING: 'PENDING',
      SENT: 'SENT',
      IN_PROGRESS: 'IN_PROGRESS',
      COMPLETED: 'COMPLETED'
    }
  }
} as const

export { DB_FIELDS, DB_ENUM_VALUES }
