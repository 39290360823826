import { render } from "./SurveyDialog.vue?vue&type=template&id=c1aa1ed2"
import script from "./SurveyDialog.vue?vue&type=script&lang=ts"
export * from "./SurveyDialog.vue?vue&type=script&lang=ts"

import "./SurveyDialog.vue?vue&type=style&index=0&id=c1aa1ed2&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c1aa1ed2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c1aa1ed2', script)) {
    api.reload('c1aa1ed2', script)
  }
  
  module.hot.accept("./SurveyDialog.vue?vue&type=template&id=c1aa1ed2", () => {
    api.rerender('c1aa1ed2', render)
  })

}

script.__file = "src/components/survey/SurveyDialog.vue"

export default script