import API from '@/axios'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import { API_PAGEABLE_PARAMS, CPTSummarySchema, SurveySchema } from '@/types'

/**
 * Get survey CPTs
 * - Get all survey CPTs if 'params' is not provided
 * @param id
 * @param params
 * @param getAllParams
 */
const getSurveyCPTSummaries = async (options: {
  surveyId: CPTSummarySchema['surveyId']
  params?: API_PAGEABLE_PARAMS
  getAll?: boolean
}): Promise<any> => {
  try {
    const getAll = options ? options.getAll : false
    const params = options ? options.params : {}
    const { surveyId } = options
    if (getAll) {
      const requestParams = Object.assign({}, API_DEFAULT_PAGEABLE_PARAMS, params)
      return recursivePaginationAPIRequestFn(
        API,
        ENDPOINTS.SURVEY_CPT_SUMMARIES({ id: surveyId })
      )(requestParams)
    } else {
      return extractResponse(
        await API.get(ENDPOINTS.SURVEY_CPT_SUMMARIES({ id: surveyId }), { params })
      )
    }
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Create CPT
 * @param cpt
 */
const createCPTSummary = async (
  cptSummary: Pick<CPTSummarySchema, 'networkId' | 'surveyId' | 'rows' | 'variableId'>
): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.CPT_SUMMARIES, cptSummary))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Update CPT
 * @param response
 */
const updateCPTSummary = async (cptSummary: CPTSummarySchema): Promise<any> => {
  try {
    const { id } = cptSummary
    return extractResponse(await API.put(ENDPOINTS.CPT_SUMMARY({ id }), cptSummary))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Delete CPT
 * @param id - response id
 */
const deleteCPTSummary = async (id: CPTSummarySchema['id']): Promise<any> => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.CPT_SUMMARY({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Export genie
 * @param networkId
 * @param surveyId
 */
const exportXDSL = async ({
  networkId,
  surveyId
}: {
  networkId: string
  surveyId: SurveySchema['id']
}): Promise<any> => {
  try {
    return extractResponse(
      await API.get(ENDPOINTS.XDSL_EXPORT({ id: networkId }), {
        responseType: 'blob',
        params: { surveyId }
      })
    )
  } catch (e) {
    errorHandler(e)
  }
}

const traceNetwork = async ({
  networkId,
  surveyId,
  nodeIds
}: {
  networkId: string
  surveyId: SurveySchema['id']
  nodeIds: string[]
}): Promise<any> => {
  try {
    return extractResponse(
      await API.post(ENDPOINTS.TRACE({ id: networkId }), nodeIds, {
        params: { surveyId }
      })
    )
  } catch (e) {
    errorHandler(e)
  }
}

export {
  createCPTSummary,
  getSurveyCPTSummaries,
  updateCPTSummary,
  deleteCPTSummary,
  exportXDSL,
  traceNetwork
}
