import { Ref, ref } from 'vue'

import { AnalysisTask, ChartData } from '@/components/analysis/libs/common'
import { rounder } from '@/libs/common'

export default function useHybridCharts(
  tasks: Ref<AnalysisTask[]>,
  criteriaMap: Ref<Record<string, any>>,
  selectedOutputKeys: Ref<string[]>
): any {
  const chartNodeIdList: Ref<any[][]> = ref([])
  const chartDataList: Ref<ChartData[]> = ref([])
  const chartTitleList: Ref<string[]> = ref([])
  const legends: Ref<string[]> = ref([])

  const aggChartNodeIds: Ref<any[]> = ref([])
  const aggChartData: Ref<ChartData> = ref([])
  const aggChartTitle: Ref<string> = ref('')

  let maxVal = Number.MIN_VALUE
  let minVal = Number.MAX_VALUE

  const updateAhpChartFromTasks = () => {
    const chartDataList_: any[] = []
    const chartNodeIdList_: any[] = []
    const chartTitleList_: any[] = []
    const aggChartNodeIds_: any[] = []
    const aggChartData_: any[] = []

    selectedOutputKeys.value?.forEach((pKey) => {
      const { aggVals, chartData_, chartNodeIds_, chartTitle_ } = updateAhpChartFromTasksOne(pKey)
      aggChartNodeIds_.push(pKey)
      aggVals.forEach((aggVal: number, index: number) => {
        if (!aggChartData_[index]) {
          aggChartData_[index] = []
        }
        aggChartData_[index].push(aggVal)
      })
      const parentCrit = criteriaMap.value[pKey]
      if (!parentCrit?.childrenKeys || parentCrit?.childrenKeys?.length <= 1) {
        return
      }
      chartDataList_.push(chartData_)
      chartNodeIdList_.push(chartNodeIds_)
      chartTitleList_.push(chartTitle_)
    })
    aggChartNodeIds.value = aggChartNodeIds_
    aggChartData.value = aggChartData_
    aggChartTitle.value = ''
    chartDataList.value = chartDataList_
    chartNodeIdList.value = chartNodeIdList_
    chartTitleList.value = chartTitleList_
  }

  const updateAhpChartFromTasksOne = (pKey: string): any => {
    const chartNodeIds_: string[] = []
    const chartData_: number[][] = []
    let chartTitle_: string = pKey

    if (!tasks.value?.length) {
      return
    }
    if (!pKey) {
      return
    }

    const parentCrit = criteriaMap.value[pKey]

    parentCrit?.childrenKeys?.forEach((childrenKey: string) => {
      chartNodeIds_.push(childrenKey)
    })

    const aggVals: number[] = []
    const legends_: string[] = []
    tasks.value.forEach((task: AnalysisTask) => {
      const { result, name } = task
      legends_.push(name)
      const scoreMap = result?.hybridResults?.results?.scoreMap || {}
      const outputNodes = result?.hybridResults?.results?.outputNodes || []
      const outputNodeMap: Record<string, number> = outputNodes.reduce(
        (acc: Record<string, number>, o: any) => {
          acc[(o.networkId ? `${o.networkId}:` : '') + o.key] = o.value
          return acc
        },
        {}
      )
      const series: number[] = []
      let value = 0
      if (pKey in scoreMap) {
        value = rounder(scoreMap[pKey])
      } else if (pKey in outputNodeMap) {
        value = rounder(outputNodeMap[pKey])
      }
      aggVals.push(value)
      parentCrit?.childrenKeys?.forEach((childrenKey: string) => {
        const val = scoreMap[childrenKey] || 0
        if (minVal > val) {
          minVal = val
        }
        if (maxVal < val) {
          maxVal = val
        }
        series.push(val)
      })
      chartData_.push(series)
    })
    legends.value = legends_
    chartTitle_ += '(' + aggVals.join(',') + ')'
    return {
      aggVals,
      chartTitle_,
      chartData_,
      chartNodeIds_
    }
  }

  return {
    chartNodeIdList,
    chartDataList,
    chartTitleList,
    legends,
    aggChartNodeIds,
    aggChartData,
    aggChartTitle,
    updateAhpChartFromTasks
  }
}
