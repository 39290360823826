import { ActionTree } from 'vuex'

import {
  createWorkspace,
  deleteWorkspace,
  getWorkspace,
  getWorkspaces,
  updateWorkspace
} from '@/services/api/workspace'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { WorkspaceMutationEnum } from '@/store/enums/mutations/workspace'
import { RootState } from '@/store/types'
import { WorkspaceActions } from '@/store/types/actions/workspace'
import { WorkspaceState } from '@/store/types/states/workspace'
import { Workspace, WorkspaceList } from '@/types'

const actions: ActionTree<WorkspaceState, RootState> & WorkspaceActions = {
  // Create workspace
  async [WorkspaceActionEnum.CREATE_WORKSPACE]({ commit }, workspace) {
    commit(WorkspaceMutationEnum.CREATE_WORKSPACE, undefined)
    return createWorkspace(workspace)
      .then((res: Workspace) => {
        commit(WorkspaceMutationEnum.CREATE_WORKSPACE_FULFILLED, res)
      })
      .catch((e) => {
        commit(WorkspaceMutationEnum.CREATE_WORKSPACE_REJECTED, e)
      })
  },
  // Get workspaces
  [WorkspaceActionEnum.GET_WORKSPACES]({ commit }, { params, getAll }) {
    commit(WorkspaceMutationEnum.GET_WORKSPACES, undefined)
    getWorkspaces({ params, getAll })
      .then((res: WorkspaceList) => {
        commit(WorkspaceMutationEnum.GET_WORKSPACES_FULFILLED, res)
      })
      .catch((e) => {
        commit(WorkspaceMutationEnum.GET_WORKSPACES_REJECTED, e)
      })
  },
  // Get workspace
  [WorkspaceActionEnum.GET_WORKSPACE]({ commit }, id) {
    commit(WorkspaceMutationEnum.GET_WORKSPACE, undefined)
    getWorkspace(id)
      .then((res) => {
        commit(WorkspaceMutationEnum.GET_WORKSPACE_FULFILLED, res)
      })
      .catch((e) => {
        commit(WorkspaceMutationEnum.GET_WORKSPACE_REJECTED, e)
      })
  },
  // Update workspace
  [WorkspaceActionEnum.UPDATE_WORKSPACE]({ commit }, { id, workspace }) {
    commit(WorkspaceMutationEnum.UPDATE_WORKSPACE, undefined)
    updateWorkspace(id, workspace)
      .then((res: Workspace) => {
        commit(WorkspaceMutationEnum.UPDATE_WORKSPACE_FULFILLED, res)
      })
      .catch((e) => {
        commit(WorkspaceMutationEnum.GET_WORKSPACE_REJECTED, e)
      })
  },
  // Delete workspace
  [WorkspaceActionEnum.DELETE_WORKSPACE]({ commit }, id) {
    commit(WorkspaceMutationEnum.DELETE_WORKSPACE, undefined)
    deleteWorkspace(id)
      .then(() => {
        commit(WorkspaceMutationEnum.DELETE_WORKSPACE_FULFILLED, id)
      })
      .catch((e) => {
        commit(WorkspaceMutationEnum.DELETE_WORKSPACE_REJECTED, e)
      })
  }
}

export { actions }
