
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue'

import { Network, Variable } from '@/libs/bayes'
import {
  combIndexToStateIndex,
  completeProbs,
  getProbsOfNodeDef,
  parentsCombinationDivisions,
  probFormatter,
  probRounder,
  probStep,
  setProbsOfNodeDef
} from '@/libs/common'
import { StatePolarity } from '@/libs/enums'
import { getStateStyle } from '@/libs/utils'

export const EVENTS = {
  CHANGE: 'change'
}

export default defineComponent({
  props: {
    network: { type: Object as PropType<Network>, required: true },
    variable: { type: Object as PropType<Variable>, required: true },
    editable: { type: Boolean, required: true }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const states = computed(() => props.variable.getAllStates() || [])
    const parents = computed(() => props.network.parentsOriginalOrder[props.variable.id] || [])
    const hasParents = computed(() => parents.value.length > 0)
    const parentStates = computed(() => {
      return parents.value.map((parent: Variable) => parent.getAllStates())
    })
    const divisions = computed(() => parentsCombinationDivisions(parentStates.value))
    const combinationTotal = computed(() => divisions.value?.[0])
    const definition = computed(() => {
      return props.variable?.nodeDefinition || []
    })

    const getNodeDefinition = () => props.variable?.nodeDefinition?.map(probRounder) || []
    const definitionModel: Ref<number[]> = ref(getNodeDefinition())

    watch(
      () => props.variable,
      () => {
        definitionModel.value = getNodeDefinition()
      }
    )

    const probChangeWithParents = (stateIndex: number, combIndex: number) => {
      if (stateIndex) {
        const probs = getProbsOfNodeDef(definitionModel.value, combIndex, states.value?.length)
        const newProbs = completeProbs(probs, 1.0, 0)
        setProbsOfNodeDef(definitionModel.value, newProbs, combIndex, states.value?.length)
      }
      emit(EVENTS.CHANGE, definitionModel.value)
    }
    const probChange = (stateIndex: number) => {
      if (stateIndex) {
        definitionModel.value = completeProbs(definitionModel.value, 1.0, 0)
      }
      emit(EVENTS.CHANGE, definitionModel.value)
    }
    const cellWidth = 90
    const tableStyle = computed(() => {
      return {
        width: `${
          hasParents.value
            ? (parents.value.length + states.value.length) * cellWidth
            : states.value?.length * cellWidth
        }px`
      }
    })
    const cellStyle = computed(() => ({
      width: `${cellWidth}px`
    }))

    const rows = computed(() => {
      const rows_ = []
      for (let combIndex = 0; combIndex < combinationTotal.value; combIndex++) {
        const stateIndexes = []
        const pStates = []
        for (let parentIndex = 0; parentIndex < parents.value.length; parentIndex++) {
          const stateIndex = combIndexToStateIndex(
            parentStates.value,
            divisions.value,
            combIndex,
            parentIndex
          )
          stateIndexes.push(stateIndex)
          pStates.push(parentStates.value[parentIndex][stateIndex]?.state)
        }
        rows_.push({
          id: combIndex,
          combIndex,
          stateIndexes,
          pStates
        })
      }
      return rows_
    })

    const pageSize = 16
    const pageIndex = ref(0)
    const pageCount = computed(() => Math.ceil(combinationTotal.value / pageSize))

    const pageRows = computed(() => {
      const start = pageIndex.value * pageSize
      return rows.value.slice(start, start + pageSize)
    })

    const goPage = (delta: number) => {
      let index = pageIndex.value + delta
      if (index < 0) {
        pageIndex.value = 0
      } else if (index >= pageCount.value) {
        pageIndex.value = pageCount.value - 1
      } else {
        pageIndex.value = index
      }
    }

    return {
      pageIndex,
      goPage,
      pageCount,
      pageRows,
      rows,
      cellStyle,
      tableStyle,
      probChangeWithParents,
      probFormatter,
      probRounder,
      probStep,
      definitionModel,
      probChange,
      combinationTotal,
      definition,
      states,
      divisions,
      combIndexToStateIndex,
      hasParents,
      parents,
      parentStates,
      getStateStyle,
      StatePolarity
    }
  }
})
