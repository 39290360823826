import { UserStateEnum } from '@/store/enums/states/user'
import { UserState } from '@/store/types/states/user'

/**
 * Init value for user state
 */
const state: UserState = {
  [UserStateEnum.IS_GETTING]: false,
  [UserStateEnum.IS_CREATING]: false,
  [UserStateEnum.IS_UPDATING]: false,
  [UserStateEnum.IS_DELETING]: false,
  [UserStateEnum.ERROR]: null,
  [UserStateEnum.USER_LIST]: {},
  [UserStateEnum.CURRENT_USER]: null,
  [UserStateEnum.LOADING_PROMPT_TEXT]: null
}

export { state }
