import { AxiosError } from 'axios'
import { isArray } from 'lodash-es'

import axios from '@/axios'
import { ttl } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'

export const AHP_CRITERIA = 'AHP_CRITERIA'
export const AHP_RESPONSES = 'AHP_RESPONSES'
export const AHP_RESULTS = 'AHP_RESULTS'
export const AHP_SURVEY_STATUS = 'AHP_SURVEY_STATUS'

const APISETS: Record<string, any> = {
  [AHP_CRITERIA]: {
    endpoint: ttl`/workspaces/${'workspaceId'}/ahp-criteria/${'entityId'}`
  },
  [AHP_RESPONSES]: {
    endpoint: ttl`/workspaces/${'workspaceId'}/ahp-responses/${'entityId'}`,
    extras: {
      calculate: true,
      consolidate: true,
      export: true
    }
  },
  [AHP_RESULTS]: {
    endpoint: ttl`/workspaces/${'workspaceId'}/ahp-results/${'entityId'}`
  },
  [AHP_SURVEY_STATUS]: {
    endpoint: ttl`/workspaces/${'workspaceId'}/ahp-survey-status/${'entityId'}`,
    extras: {
      distribute: true
    }
  }
}

Object.keys(APISETS).forEach((key) => {
  const apiSet = APISETS[key]
  apiSet.method = {
    create: async (workspaceId: string, payload: any): Promise<any> => {
      try {
        return extractResponse(await axios.post(apiSet.endpoint({ workspaceId }), payload))
      } catch (err) {
        errorHandler(err as AxiosError)
      }
    },
    gets: async (workspaceId: string, options: any): Promise<any> => {
      try {
        const items: any = extractResponse(
          await axios.get(apiSet.endpoint({ workspaceId }), options)
        )
        if (isArray(items)) {
          return items
        }
        return items?.content || []
      } catch (err) {
        errorHandler(err as AxiosError)
      }
    },
    get: async (workspaceId: string, entityId: string): Promise<any> => {
      try {
        return extractResponse(await axios.get(apiSet.endpoint({ workspaceId, entityId })))
      } catch (err) {
        errorHandler(err as AxiosError)
      }
    },
    update: async (workspaceId: string, entityId: string, payload: any): Promise<any> => {
      try {
        return extractResponse(await axios.put(apiSet.endpoint({ workspaceId, entityId }), payload))
      } catch (err) {
        errorHandler(err as AxiosError)
      }
    },
    erase: async (workspaceId: string, entityId: string): Promise<any> => {
      try {
        return extractResponse(await axios.delete(apiSet.endpoint({ workspaceId, entityId })))
      } catch (err) {
        errorHandler(err as AxiosError)
      }
    }
  }
  if (apiSet.extras) {
    Object.keys(apiSet.extras).forEach((submethod: string) => {
      switch (submethod) {
        case 'distribute':
        case 'calculate':
          apiSet.method[submethod] = async (
            workspaceId: string,
            entityId: string,
            payload: any
          ): Promise<any> => {
            try {
              return extractResponse(
                await axios.post(
                  apiSet.endpoint({ workspaceId, entityId }) + '/' + submethod,
                  payload
                )
              )
            } catch (err) {
              errorHandler(err as AxiosError)
            }
          }
          break
        case 'consolidate':
          apiSet.method[submethod] = async (
            workspaceId: string,
            parentKey: string
          ): Promise<any> => {
            try {
              return extractResponse(
                await axios.post(apiSet.endpoint({ workspaceId }) + parentKey + '/' + submethod)
              )
            } catch (err) {
              errorHandler(err as AxiosError)
            }
          }
          break
        case 'export':
          apiSet.method[submethod] = async (workspaceId: string, userId: string): Promise<any> => {
            try {
              return extractResponse(
                await axios.post(apiSet.endpoint({ workspaceId }) + submethod, { userId })
              )
            } catch (err) {
              errorHandler(err as AxiosError)
            }
          }
          break
      }
    })
  }
})

export { APISETS }
