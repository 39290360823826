
import { css } from '@emotion/css'
import { defineComponent, PropType, ref, watch } from 'vue'

const wrapper = css`
  > * {
    margin-right: 4px;
  }

  .ant-input-number {
    width: 55px;
  }
`

export default defineComponent({
  props: {
    constrained: { type: Boolean, default: false },
    modelValue: { type: Array as PropType<number[]>, required: true }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const items = ref([...props.modelValue])
    const minValue = ref(props.constrained ? 0 : -1000)
    const maxValue = ref(props.constrained ? 100 : 1000)
    const stepValue = ref(props.constrained ? 1 : 0.01)
    const onChange = () => {
      emit('update:modelValue', items.value)
      emit('change')
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        items.value = newVal
      }
    )

    return {
      minValue,
      maxValue,
      stepValue,
      items,
      onChange,
      wrapper
    }
  }
})
