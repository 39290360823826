import { keyBy, size } from 'lodash-es'
import { ascend, descend, isEmpty, prop, sortWith } from 'ramda'
import { computed, Ref } from 'vue'
import writeXlsxFile from 'write-excel-file'

import { CPT, CPTSet, Network, Variable } from '@/libs/bayes'
import { Combination, CombinationRow } from '@/libs/bayes/Combination'
import { CPTMethod } from '@/libs/bayes/enums'
import { normalizeXlsTabName } from '@/libs/common'
import { DefaultDict } from '@/libs/utils'
import { Allocation, Dict, ResponseSchema, SurveySchema } from '@/types'

export const COMBKEY_PREFIX = 'v+s'

// Temporary fix for old bugs, remove later once stable
const FIX_INCORRECT_DETERMINISTIC = false

// Analytics information for a particular variable
export type AnalyticsRecord = {
  stats: Dict
  userMap: DefaultDict
  rowMap: DefaultDict
  aggMap: Dict
  xaggMap: Dict
  responses: ResponseSchema[]
  aggResponses: ResponseSchema[]
  responseCount: number
  allocatedUsers: string[]
  allocatedCount: number
}

export const exportAnalytics = async (
  surveyId: string,
  network: Network,
  allocations: Array<Allocation>,
  userMap: Dict,
  cptSet: CPTSet,
  analyticsMap: Dict,
  isAnonymous: boolean,
  currentSurvey: SurveySchema
): Promise<void> => {
  if (surveyId && cptSet && !isEmpty(network.variables)) {
    const names: string[] = []
    const datas: any[] = []
    const validUsers = allocations
      .map(({ userId }: any) => userMap[userId] || { id: userId, username: userId })
      .concat({
        id: 'final',
        username: 'final'
      })
    network.variables.forEach((variable: Variable) => {
      const cpt = cptSet.getCPT(variable)
      const data = cpt.generateAnalyticsTabularData(analyticsMap, validUsers, userMap, isAnonymous)
      datas.push(data)
      names.push(normalizeXlsTabName(variable.name).substring(0, 31))
    })
    await writeXlsxFile(datas, {
      sheets: names,
      fileName: currentSurvey?.name || 'untitled'
    })
  }
}

export const genCombKey = (combination: Combination, prefix: string = COMBKEY_PREFIX): string => {
  let key = prefix
  combination.forEach(({ variable, state }) => {
    key += `-${variable.id}+${state.id}`
  })
  return key
}

const combi2Key = (depMap: Map<string, any>, combination: Combination): string => {
  const depKey: Array<Array<string>> = []
  const indepKey: Array<Array<string>> = []

  combination.forEach(({ variable, state }) => {
    if (depMap.has(variable.id)) {
      depKey.push([variable.id, state.id])
    } else {
      indepKey.push([variable.id, state.id])
    }
  })
  return (
    COMBKEY_PREFIX +
    (depKey.length + indepKey.length > 0 ? '-' : '') +
    depKey
      .concat(indepKey)
      .map((k) => k.join('+'))
      .join('-')
  )
}
export const getResponseMapForVar = (
  analyticsMap: Record<string, any>,
  variable: Variable,
  rows: Array<CombinationRow>,
  depMap: Map<string, any>
): Map<string, any> => {
  const agg = new Map()
  if (!analyticsMap) {
    return agg
  }
  let valid = false
  rows.forEach(({ rowId, combination: combination = [] }) => {
    const combKey = combi2Key(depMap, combination)
    const analytics = analyticsMap[variable.id]
    const rowAgg = analytics?.aggMap[rowId || '']
    const rowItem = analytics?.rowMap?.get(rowId) || {}
    // if overwritten or there are some responses
    if (rowAgg || rowItem.count) {
      valid = true
    }
    const response = rowAgg
      ? {
          rowId,
          rationale: rowAgg.rationale + rowItem.rationale || '',
          value: rowAgg.value,
          ext: {
            critical: rowAgg.ext?.critical
          }
        }
      : {
          rowId,
          rationale: rowItem.rationale || '',
          value: rowItem.mean,
          ext: {
            critical: rowItem.critical
          }
        }
    agg.set(combKey, { valid, rowId, combKey, combination, analytics, response })
  })
  return agg
}

interface TempRow {
  rowId: string
  index: number
  value: number
}

const adjustForDeterministic = (
  variable: Variable,
  cptSet: CPTSet,
  map: Record<Variable['id'], AnalyticsRecord>
) => {
  if (!variable.isDeterministic()) {
    return
  }
  console.log('adjustForDeterministic')

  const cpt: CPT = cptSet.getCPT(variable)
  if (cpt.method !== CPTMethod.MARGINAL || !cpt.elicitedRows?.length) {
    return
  }

  const record: AnalyticsRecord = map[variable.id]
  const { aggMap, xaggMap, aggResponses } = record
  const aggResponseMap = keyBy(aggResponses, 'rowId')
  let sortedRows: TempRow[] = []
  for (let i = 0; i < cpt.elicitedRows.length; i++) {
    const row: CombinationRow = cpt.elicitedRows[i]
    const { rowId } = row
    if (!rowId) {
      return // no valid rowId, cancel adjustment
    }
    if (aggMap[rowId]) {
      return // aggregation has been overwritten, cancel adjustment
    }
    if (!aggResponseMap[rowId]) {
      return
    }
    const { aggSum } = aggResponseMap[rowId]
    sortedRows.push({
      rowId,
      index: i,
      value: aggSum || 0
    })
  }
  const valueDescend = descend(prop('value'))
  const indexAscend = ascend(prop('index'))
  const valueIndexSorter = sortWith<TempRow>([valueDescend, indexAscend])
  sortedRows = valueIndexSorter(sortedRows)
  // adjust xaggMap, aggResponses
  sortedRows.forEach(({ rowId }: TempRow, j: number) => {
    if (j) {
      xaggMap[rowId].value = 0
      aggResponseMap[rowId].value = 0
    } else {
      xaggMap[rowId].value = 100
      aggResponseMap[rowId].value = 100
    }
  })
}

export default function useAnalytics(
  network: Ref<Network>,
  allocations: Ref<Array<Allocation>>,
  allResponses: Ref<Array<ResponseSchema>>,
  userCollection: Ref<Record<string, any>>,
  cptSet: Ref<CPTSet> | undefined = undefined
): any {
  const allVariables = computed(() => network?.value?.variables)
  const validUserIds = keyBy(allocations.value, 'userId')

  const analyticsMap = computed(() => {
    const map: Record<Variable['id'], AnalyticsRecord> = {}
    const variables = allVariables.value || []
    const aggMap: Record<string, any> = {}
    const responses = allResponses.value || []
    // if (isEmpty(variables) || isEmpty(responses)) {
    //   return map
    // }
    const responsesForVarMap = new DefaultDict({ responses: [] })
    // separate aggregate reponses (by survey admin)
    // and responses from allocated users
    responses.forEach((response: ResponseSchema) => {
      const { variableId, ext, rowId } = response
      // aggMap contain aggregated responses
      if (ext?.isAgg) {
        aggMap[rowId] = response
        return
      }
      // The rests are normal individual responses
      const responsesForVar = responsesForVarMap.get(variableId)
      responsesForVar.responses.push(response)
    })
    const initial = {
      sum: 0,
      count: 0,
      mean: 0,
      criticals: 0,
      rationale: '',
      responses: [],
      responseMap: {},
      overwritten: false
    }
    // Build analytics map from individual responses
    variables.forEach((variable: Variable) => {
      const variableId = variable.id
      const responsesForVar = responsesForVarMap.get(variableId)?.responses || []
      // non-admin user map
      const userMap = new DefaultDict(initial)
      const rowMap = new DefaultDict(initial)
      const xaggMap: Dict = {}
      // responses contain all participants responses, but not the aggregated one
      const responses: Array<ResponseSchema> = []
      responsesForVar.forEach((response: ResponseSchema) => {
        const { rowId, userId, value } = response
        if (userId && userId in validUserIds) {
          const userItem = userMap.get(userId)
          userItem.count += 1
          userItem.responses.push(response)
          userItem.responseMap[response.rowId] = response
        } else {
          // at this point, response.ext.isAgg should be true
          // and response is handled in aggMap
          return
        }
        const critical = response.ext?.critical ? 1 : 0
        const rationale = response.rationale || ''
        const rowItem = rowMap.get(rowId)
        rowItem.count += 1
        rowItem.sum += value
        rowItem.criticals += critical
        if (!isEmpty(rationale)) {
          if (rowItem.count > 1) {
            rowItem.rationale += ', '
          }
          rowItem.rationale += `${rationale} (user${rowItem.count}:${userCollection.value[userId]?.username} [${value}])`
        }
        // rowItem.rationale = rowItem.rationale.replace(/^\s+|\s+$/g, '')
        rowItem.responses.push(response)
        responses.push(response)
      })
      // Ensure there is a corresponding rowMap for aggregate overwrite
      // and set overwritten flag true
      Object.keys(aggMap).forEach((rowId: string) => {
        const rowItem = rowMap.get(rowId)
        rowItem.count += 0
        if (rowId in rowMap.items) {
          rowItem.overwritten = true
        } else {
          rowItem.overwritten = false
        }
      })
      const aggResponses: Array<ResponseSchema> = []
      // Consolidate all rows
      let overwritten = false
      // console.log(variable.name, rowMap.items.length)
      Object.keys(rowMap.items).forEach((rowId: string) => {
        const rowItem = rowMap.items[rowId]
        if (rowItem.count == 0) {
          rowItem.sum = 0
          rowItem.mean = 0
          rowItem.critical = false
        } else {
          rowItem.mean = rowItem.count ? rowItem.sum / rowItem.count : 0
          if (rowItem.criticals > 0) {
            rowItem.critical = rowItem.count / rowItem.criticals >= 0.5
          } else {
            rowItem.critical = false
          }
        }
        let value
        let critical
        let rationale
        let userId
        let id
        if (aggMap[rowId]) {
          // aggMap[rowId] is a persisted agg response
          id = aggMap[rowId].id
          value = aggMap[rowId].value
          critical = aggMap[rowId].ext?.critical
          rationale = aggMap[rowId].rationale
          overwritten = true
          userId = aggMap[rowId].userId
        } else {
          value = rowItem.mean
          critical = rowItem.critical
          rationale = rowItem.rationale
          overwritten = false
          userId = 'final'
        }
        xaggMap[rowId] = {
          id,
          userId,
          value,
          rationale,
          ext: {
            critical
          }
        }
        // This is dummy responses (that contains either from admin that has overwritten or automatically calculated)
        aggResponses.push({
          id,
          variableId,
          userId,
          rowId,
          value,
          rationale,
          ext: {
            critical,
            isAgg: true
          },
          aggSum: rowItem.sum,
          aggValue: rowItem.mean,
          aggRationale: rowItem.rationale || '',
          aggExt: {
            critical: rowItem.critical
          }
        })
      })
      const stats = {
        userCount: size(userMap.items),
        overwritten
      }
      map[variableId] = {
        stats,
        userMap,
        rowMap,
        aggMap,
        xaggMap,
        responses,
        aggResponses,
        responseCount: responses.length,
        allocatedUsers: [],
        allocatedCount: 0
      }
      if (cptSet && FIX_INCORRECT_DETERMINISTIC) {
        adjustForDeterministic(variable, cptSet.value, map)
      }
    })

    allocations.value.forEach(({ userId, assignments }: Allocation) => {
      assignments.forEach(({ variableId, assigned }) => {
        if (assigned) {
          map[variableId].allocatedCount += 1
          map[variableId].allocatedUsers.push(userId)
        }
      })
    })
    return map
  })

  return {
    COMBKEY_PREFIX,
    analyticsMap,
    allVariables
  }
}
