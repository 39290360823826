import { render } from "./VariableSymbol.vue?vue&type=template&id=11b83c18"
import script from "./VariableSymbol.vue?vue&type=script&lang=ts"
export * from "./VariableSymbol.vue?vue&type=script&lang=ts"

import "./VariableSymbol.vue?vue&type=style&index=0&id=11b83c18&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "11b83c18"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('11b83c18', script)) {
    api.reload('11b83c18', script)
  }
  
  module.hot.accept("./VariableSymbol.vue?vue&type=template&id=11b83c18", () => {
    api.rerender('11b83c18', render)
  })

}

script.__file = "src/components/symbols/VariableSymbol.vue"

export default script