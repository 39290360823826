
import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretUpFilled,
  CaretUpOutlined
} from '@ant-design/icons-vue'
import { css } from '@emotion/css'
import { defineComponent, ref, watch } from 'vue'

const wrapper = css`
  display: inline-block;
  width: 12px;
  > div {
    display: flex;
    flex-direction: column;
  }
`
export const SORTER = {
  DESCENDING: -1,
  NONE: 0,
  ASCENDING: 1
}

export default defineComponent({
  components: {
    CaretDownFilled,
    CaretDownOutlined,
    CaretUpFilled,
    CaretUpOutlined
  },
  props: {
    modelValue: { type: Number, required: true }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const direction = ref(props.modelValue)
    const onChange = () => {
      emit('update:modelValue', direction.value)
      emit('change')
    }

    const sort = (dir: number) => {
      direction.value = dir
      onChange()
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        direction.value = newVal
      }
    )

    return {
      sort,
      SORTER,
      direction,
      onChange,
      wrapper
    }
  }
})
