/**
 * Mutation types
 */
export enum QuestionnaireMutationEnum {
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  GET_NETWORK_CPTS_FULFILLED = 'GET_NETWORK_CPTS_FULFILLED',
  GET_NETWORK_CPTS_REJECTED = 'GET_NETWORK_CPTS_REJECTED',
  GET_WORKSPACE_NETWORKS_REJECT = 'GET_WORKSPACE_NETWORKS_REJECT',
  GET_SURVEY_CPTS = 'GET_SURVEY_CPTS',
  GET_SURVEY_RESPONSES = 'GET_SURVEY_RESPONSE',
  GET_WORKSPACE_USER_ALLOCATIONS = 'GET_WORKSPACE_USER_ALLOCATIONS',
  GET_WORKSPACE_NETWORKS = 'GET_WORKSPACE_NETWORKS'
}
