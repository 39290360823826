/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum SurveyStatusActionEnum {
  CREATE_SURVEY_STATUS = 'CREATE_SURVEY_STATUS',
  UPDATE_SURVEY_STATUS = 'UPDATE_SURVEY_STATUS',
  DELETE_SURVEY_STATUS = 'DELETE_SURVEY_STATUS',
  GET_SURVEY_STATUS = 'GET_SURVEY_STATUS',
  GET_SURVEY_STATUSES = 'GET_SURVEY_STATUSES',
  DISTRIBUTE_SURVEY = 'DISTRIBUTE_SURVEY'
}
