import { isNil } from 'ramda'

import { getResponseMapForVar } from '@/components/composables/analytics'
import {
  anonymizeRationale,
  Combination as Comb,
  combinationOf,
  contribsOf,
  Contributions,
  normalizeWeights
} from '@/libs/common'
import { ResponseSchema } from '@/types'

import { Combination, CombinationRow } from '../Combination'
import { ResponseMap } from '../CPTResponse'
import { CPTSummary, CPTSummaryOutcome, CPTSummaryRow } from '../CPTSummary'
import { CPTMethod } from '../enums/CPTMethod'
import { Network } from '../Network'
import { State } from '../State'
import { Variable } from '../Variable'
import { CPT } from './CPT'

export class AceDstSimpleCPT extends CPT {
  indepComb?: Comb
  contributions?: Contributions

  constructor(
    network: Network,
    variable: Variable,
    state: State,
    id?: string,
    method: CPTMethod = CPTMethod.ACE_DST_SIMPLE
  ) {
    super(network, variable, state, id, method)
    this.prepCalc()
  }

  generateElicitedRows(): void {
    const { dependents, independents } = this
    const dependentCombs = this.generateDependentCombinations()
    let combinations: Array<Combination> = []

    if (dependents.length === 0) {
      combinations.push([])
    }

    for (let i = 0; i < dependentCombs.length; i++) {
      const dependentComb = dependentCombs[i]
      if (dependentComb.length > 0) {
        combinations.push(dependentComb)
      }
      const posCombs = this.multiplexPositive(dependentComb, independents)
      combinations = combinations.concat(posCombs)
    }
    this.elicitedCombinations = combinations
    this.elicitedRows = combinations.map((combination) => new CombinationRow(combination))
  }

  prepCalc(): void {
    this.indepComb = combinationOf(this.independents) || []
    this.contributions = contribsOf(this.independents) || []
  }

  padCombKey(depCombKey: string, indepVariable: Variable, indepState: State): string {
    return `${depCombKey}${CPT.COMB_DELIM}${indepVariable.id}${CPT.COND_DELIM}${indepState.id}`
  }

  generateCPT(
    depCombKeys: Array<string>,
    depKeyMapper: Record<string, any>,
    responseMap: ResponseMap
  ): Array<ResponseSchema> {
    const indepComb = this.indepComb // must be after preCalc

    if (isNil(indepComb)) {
      return []
    }
    depCombKeys.forEach((depCombKey) => {
      const cpt: Array<Array<number>> | null = this.calculateCPT(depCombKey, responseMap)
      if (!cpt) {
        return
      }
      for (let i = 0; i < cpt.length; i++) {
        const row = cpt[i]
        const combRow = indepComb[i]
        for (let j = 0; j < row.length; j++) {
          const variable = this.independents[j]
          const stateIndex = combRow[j]
          const state = variable.getState(stateIndex)
          const combKey = this.padCombKey(depCombKey, variable, state)
          const response = responseMap.get(combKey)?.response
          if (response) {
            response.value = row[j]
          }
        }
      }
    })
    const responses: Array<ResponseSchema> = []
    responseMap.forEach(({ response }) => {
      if (response) {
        responses.push(response)
      }
    })
    return responses
  }
  /*
  CPTSummmary {
    id: string
    type: string
    networkId: string
    surveyId: string
    variableId: string    
    outcomes: [
        {    stateId: string
            rows: [
                {
                    parents: [CptRowParentDTO, ...]
                    value: number
                    rationale: string
                    ext: {}            
                },
                {
                    parents: [CptRowParentDTO, ...]
                    value: number
                    rationale: string
                    ext: {}            
                }
            ]
        },
        ...
    ]
  }
  */

  summarize(analyticsMap: Record<string, any>, isAnonymous = true): CPTSummary | null {
    const dependentCombinations = this.generateDependentCombinations()
    const indepComb = this.indepComb // must be after preCalc

    if (isNil(indepComb)) {
      return null
    }
    const responseMap = getResponseMapForVar(
      analyticsMap,
      this.variable,
      this.elicitedRows || [],
      this.dependentMap
    )
    if (!this.isCompleteData(responseMap)) {
      return null
    }
    const rows: Array<CPTSummaryRow> = []
    for (let i = 0; i < dependentCombinations.length; i++) {
      const dependentCombination: Combination = dependentCombinations[i]
      const depCombKey = this.makeCombKey(dependentCombination)
      const rawCPT: Array<Array<number>> | null = this.calculateCPT(depCombKey, responseMap)
      if (!rawCPT) {
        return null
      }
      for (let i = 0; i < rawCPT.length; i++) {
        const row = rawCPT[i]
        const combRow = indepComb[i]
        const indepCombination = []
        for (let k = 0; k < combRow.length; k++) {
          const variable = this.independents[k]
          const stateIndex = combRow[k]
          const state = variable.getState(stateIndex)
          indepCombination.push({
            variable,
            state
          })
        }
        const parents = dependentCombination.concat(indepCombination)
        const outcomes: Array<CPTSummaryOutcome> = []
        for (let j = 0; j < row.length; j++) {
          const state = this.variable.getState(j)
          let rationale = ''
          if (j == row.length - 1) {
            const indepVariable = this.independents[i % combRow.length]
            const { state: posState } = indepVariable.getPositiveState()
            const indepPair = `-${indepVariable.id}+${posState.id}`
            const key = `${depCombKey}${indepPair}`
            rationale = anonymizeRationale(
              responseMap.get(key)?.response?.rationale || '',
              isAnonymous
            )
          }
          outcomes.push({
            state,
            value: isNil(row[j]) ? -1 : row[j],
            rationale
          })
        }
        rows.push({
          parents,
          outcomes
        })
      }
    }
    return {
      variable: this.variable,
      rows
    } as CPTSummary
  }

  interpolate(responseMap: ResponseMap): CPTSummary | null {
    const dependentCombinations = this.generateDependentCombinations()
    const indepComb = this.indepComb // must be after preCalc

    if (isNil(indepComb)) {
      return null
    }
    const rows: Array<CPTSummaryRow> = []
    dependentCombinations.forEach((dependentCombination: Combination) => {
      const depCombKey = this.makeCombKey(dependentCombination)
      const rawCPT: Array<Array<number>> | null = this.calculateCPT(depCombKey, responseMap)
      if (!rawCPT) {
        return
      }
      for (let i = 0; i < rawCPT.length; i++) {
        const row = rawCPT[i]
        const combRow = indepComb[i]
        const indepCombination = []
        for (let k = 0; k < combRow.length; k++) {
          const variable = this.independents[k]
          const stateIndex = combRow[k]
          const state = variable.getState(stateIndex)
          indepCombination.push({
            variable,
            state
          })
        }
        const parents = dependentCombination.concat(indepCombination)
        const outcomes: Array<CPTSummaryOutcome> = []
        for (let j = 0; j < row.length; j++) {
          const state = this.variable.getState(j)
          outcomes.push({
            state,
            value: isNil(row[j]) ? -1 : row[j]
          })
        }
        rows.push({
          parents,
          outcomes
        })
      }
    })
    return {
      variable: this.variable,
      rows
    } as CPTSummary
  }

  mock(): CPTSummary | null {
    const dependentCombinations = this.generateDependentCombinations()
    const indepComb = this.indepComb // must be after preCalc

    if (isNil(indepComb)) {
      return null
    }
    const rows: Array<CPTSummaryRow> = []
    dependentCombinations.forEach((dependentCombination: Combination) => {
      for (let i = 0; i < indepComb.length; i++) {
        const combRow = indepComb[i]
        const indepCombination = []
        for (let k = 0; k < combRow.length; k++) {
          const variable = this.independents[k]
          const stateIndex = combRow[k]
          const state = variable.getState(stateIndex)
          indepCombination.push({
            variable,
            state
          })
        }
        const parents = dependentCombination.concat(indepCombination)
        const outcomes: Array<CPTSummaryOutcome> = []
        const childStateNum = this.variable.length
        for (let j = 0; j < childStateNum; j++) {
          const state = this.variable.getState(j)
          outcomes.push({
            state,
            value: 1 / childStateNum
          })
        }
        rows.push({
          parents,
          outcomes
        })
      }
    })
    return {
      variable: this.variable,
      rows
    } as CPTSummary
  }

  calculateCPT(depCombKey: string, responseMap: ResponseMap): Array<Array<number>> | null {
    const bestP = (responseMap.get(depCombKey)?.response?.value || 0) / 100.0
    if (isNil(bestP) || isNil(this.indepComb) || isNil(this.contributions)) {
      return null
    }
    const worstP = 0 / 100.0
    const likelihoodRange = bestP - worstP
    const criticals: Array<boolean> = []
    const ratings: Array<number> = []
    this.independents.forEach((variable: Variable) => {
      const { state } = variable.getPositiveState()
      const indepPair = variable && state ? `-${variable.id}+${state.id}` : ''
      const key = `${depCombKey}${indepPair}`
      criticals.push(responseMap.get(key)?.response?.ext?.critical || false)
      ratings.push((responseMap.get(key)?.response?.value || 0) / 100.0)
    })
    const indepComb = this.indepComb
    const contributions = this.contributions
    const weights = normalizeWeights(ratings, likelihoodRange)
    const parentNum = this.independents.length
    const cptRowNum = indepComb.length
    const childStateNum = this.variable.length
    const cpt: Array<Array<number>> = new Array(cptRowNum)
    let temp
    for (let l = 0; l < cptRowNum; l++) {
      cpt[l] = new Array(childStateNum)
      const combRow = indepComb[l]
      temp = 0
      for (let j = 0; j < parentNum; j++) {
        const contrib = contributions[j][combRow[j]]
        temp = temp + weights[j] * contrib
      }
      temp = worstP + temp
      if (childStateNum === 2) {
        cpt[l][0] = 1 - temp
        cpt[l][1] = temp
      } else {
        let delta = Math.abs(temp - (1 - temp)) / childStateNum
        const points = new Array(childStateNum + 1) // 0 To childStateNum)
        points[0] = 1 - temp
        if (points[0] > temp) {
          delta = -delta
        }
        for (let k = 1; k <= childStateNum; k++) {
          points[k] = points[k - 1] + delta
          cpt[l][k - 1] = (points[k] + points[k - 1]) / childStateNum
        }
      }
    }
    // modify critical nodes
    for (let l = 0; l < cptRowNum; l++) {
      const combRow = indepComb[l]
      for (let j = 0; j < parentNum; j++) {
        if (criticals[j] && combRow[j] === 0) {
          for (let k = 0; k < childStateNum; k++) {
            cpt[l][k] = cpt[0][k]
          }
        }
      }
    }
    return cpt
  }
}
