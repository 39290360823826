import { indexBy, last, prop } from 'ramda'
import { computed, Ref, ref } from 'vue'

import {
  DEFAULT_VARIATIONS,
  makeDefaultUtilityVector,
  makeStateOptions,
  NodeSelection
} from '@/components/analysis/libs/common'
import { ModuleNames } from '@/constants/vuex'
import { Network } from '@/libs/bayes'
import { useStore } from '@/store'
import { AuthStateEnum } from '@/store/enums/states'
import { Store } from '@/store/types'
import { JobType } from '@/types/database/job'

const { AUTH } = ModuleNames

export default function useAnalysis(
  workspaceId: string,
  jobType: JobType,
  networks: Ref<Network[]>
): any {
  const store: Store = useStore()
  const currentUser = computed(() => store.state[AUTH][AuthStateEnum.USER])
  const networkMap = computed(() => indexBy(prop('id'), networks.value))
  const nodeSelections: Ref<NodeSelection[]> = ref([])
  const nodeSelectionMap = computed(() => indexBy(prop('key'), nodeSelections.value))
  const inputNodeKeys: Ref<string[]> = ref([])
  const outputNodeKeys: Ref<string[]> = ref([])
  const commonNodeKeys: Ref<string[]> = ref([])

  const updateNodeSelections = () => {
    const _nodeSelections: NodeSelection[] = []
    const keyMap: Record<string, any> = {}
    networks.value?.forEach((network: Network) => {
      network.nodes?.forEach((node: any) => {
        const key: string = node.shortName
        if (!(key in keyMap)) {
          const variable = network.variableMapByKey[key]
          if (!variable) {
            return
          }
          const isDeterministic = variable.isDeterministic()
          const allStates = variable.getAllStates()
          const numStates = allStates.length
          const stateOptions = makeStateOptions(variable)
          const variations = DEFAULT_VARIATIONS
          const _nodeSelection: NodeSelection = {
            key,
            name: key,
            isInput: false,
            isOutput: false,
            isDeterministic,
            stateOptions,
            states: [],
            state: last(stateOptions)?.value,
            variationsText: variations.join(','),
            variations,
            variation: 20,
            utilityVector: makeDefaultUtilityVector(numStates)
          }
          _nodeSelections.push(_nodeSelection)
          keyMap[key] = _nodeSelection
        } else {
          commonNodeKeys.value.push(key)
        }
      })
    })
    nodeSelections.value = _nodeSelections
  }

  const updateInputOutputNodes = () => {
    inputNodeKeys.value = []
    outputNodeKeys.value = []
    nodeSelections.value?.forEach((node: NodeSelection) => {
      if (node.isInput) {
        inputNodeKeys.value.push(node.key)
      } else if (node.isOutput) {
        outputNodeKeys.value.push(node.key)
      }
    })
  }

  return {
    networkMap,
    currentUser,
    nodeSelections,
    nodeSelectionMap,
    updateNodeSelections,
    inputNodeKeys,
    outputNodeKeys,
    updateInputOutputNodes,
    commonNodeKeys
  }
}
