import { findIndex } from 'lodash-es'
import { MutationTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { WorkspaceMutationEnum } from '@/store/enums/mutations/workspace'
import { WorkspaceStateEnum } from '@/store/enums/states/workspace'
import { WorkspaceMutation } from '@/store/types/mutations/workspace'
import { WorkspaceState } from '@/store/types/states/workspace'
import { WorkspaceList } from '@/types/api/workspace'
import { Workspace } from '@/types/database/workspace'

const mutations: MutationTree<WorkspaceState> & WorkspaceMutation = {
  // Create workspace
  [WorkspaceMutationEnum.CREATE_WORKSPACE](state: WorkspaceState) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.CREATING_WORKSPACE
    state[WorkspaceStateEnum.IS_CREATING] = true
  },
  [WorkspaceMutationEnum.CREATE_WORKSPACE_FULFILLED](state: WorkspaceState, workspace: Workspace) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.ERROR] = null
    state[WorkspaceStateEnum.IS_CREATING] = false
    state[WorkspaceStateEnum.CURRENT_WORKSPACE] = workspace
    const newContent = [...state[WorkspaceStateEnum.WORKSPACES]?.content, workspace]
    const content = newContent?.sort(
      (a: Workspace, b: Workspace) =>
        Date.parse(b?.lastModifiedDate) - Date.parse(a?.lastModifiedDate)
    )
    state[WorkspaceStateEnum.WORKSPACES] = {
      ...state[WorkspaceStateEnum.WORKSPACES],
      totalElements: state[WorkspaceStateEnum.WORKSPACES]?.totalElements + 1,
      content
    }
  },
  [WorkspaceMutationEnum.CREATE_WORKSPACE_REJECTED](state: WorkspaceState, error: Error) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_CREATING] = false
    state[WorkspaceStateEnum.ERROR] = error
  },
  // Get workspaces
  // TODO: may need to pass sort params
  [WorkspaceMutationEnum.GET_WORKSPACES](state: WorkspaceState) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_WORKSPACE
    state[WorkspaceStateEnum.IS_GETTING] = true
  },
  [WorkspaceMutationEnum.GET_WORKSPACES_FULFILLED](
    state: WorkspaceState,
    workspaces: WorkspaceList
  ) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_GETTING] = false
    state[WorkspaceStateEnum.WORKSPACES] = workspaces
  },
  [WorkspaceMutationEnum.GET_WORKSPACES_REJECTED](state: WorkspaceState, error: Error) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_GETTING] = false
    state[WorkspaceStateEnum.ERROR] = error
  },
  // Get workspace
  [WorkspaceMutationEnum.GET_WORKSPACE](state: WorkspaceState) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_WORKSPACE
    state[WorkspaceStateEnum.IS_GETTING] = true
  },
  [WorkspaceMutationEnum.GET_WORKSPACE_FULFILLED](state: WorkspaceState, workspace: Workspace) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_GETTING] = false
    state[WorkspaceStateEnum.CURRENT_WORKSPACE] = workspace
  },
  [WorkspaceMutationEnum.GET_WORKSPACE_REJECTED](state: WorkspaceState, error: Error) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_GETTING] = false
    state[WorkspaceStateEnum.ERROR] = error
  },
  // Update workspace
  [WorkspaceMutationEnum.UPDATE_WORKSPACE](state: WorkspaceState) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.UPDATING_WORKSPACE
    state[WorkspaceStateEnum.IS_UPDATING] = true
  },
  [WorkspaceMutationEnum.UPDATE_WORKSPACE_FULFILLED](state: WorkspaceState, workspace: Workspace) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_UPDATING] = false
    state[WorkspaceStateEnum.CURRENT_WORKSPACE] = workspace
    const updatedId = workspace?.id
    const index = findIndex(state[WorkspaceStateEnum.WORKSPACES]?.content, {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    if (index !== -1) {
      const newContent = [
        ...state[WorkspaceStateEnum.WORKSPACES]?.content?.slice(0, index),
        workspace,
        ...state[WorkspaceStateEnum.WORKSPACES]?.content?.slice(index + 1)
      ]
      state[WorkspaceStateEnum.WORKSPACES] = {
        ...state[WorkspaceStateEnum.WORKSPACES],
        content: newContent
      }
    }
  },
  [WorkspaceMutationEnum.UPDATE_WORKSPACE_REJECTED](state: WorkspaceState, error: Error) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_UPDATING] = false
    state[WorkspaceStateEnum.ERROR] = error
  },
  // Delete workspace
  [WorkspaceMutationEnum.DELETE_WORKSPACE](state: WorkspaceState) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.DELETING_WORKSPACE
    state[WorkspaceStateEnum.IS_DELETING] = true
  },
  [WorkspaceMutationEnum.DELETE_WORKSPACE_FULFILLED](state: WorkspaceState, id: Workspace['id']) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_DELETING] = false
    const index = findIndex(state[WorkspaceStateEnum.WORKSPACES]?.content, {
      [DB_FIELDS.COMMON.ID]: id
    })
    if (index !== -1) {
      const newContent = [
        ...state[WorkspaceStateEnum.WORKSPACES]?.content?.slice(0, index),
        ...state[WorkspaceStateEnum.WORKSPACES]?.content?.slice(index + 1)
      ]
      state[WorkspaceStateEnum.WORKSPACES] = {
        ...state[WorkspaceStateEnum.WORKSPACES],
        totalElements: state[WorkspaceStateEnum.WORKSPACES]?.totalElements - 1,
        content: newContent
      }
    }
  },
  [WorkspaceMutationEnum.DELETE_WORKSPACE_REJECTED](state: WorkspaceState, error: Error) {
    state[WorkspaceStateEnum.LOADING_PROMPT_TEXT] = null
    state[WorkspaceStateEnum.IS_DELETING] = false
    state[WorkspaceStateEnum.ERROR] = error
  }
}

export { mutations }
