enum ModuleNames {
  ALLOCATION = 'allocation',
  AUTH = 'auth',
  BUILDER = 'builder',
  NETWORK = 'network',
  QUESTIONNAIRE = 'questionnaire',
  SURVEY = 'survey',
  SURVEY_STATUS = 'surveyStatus',
  SURVEY_SUMMARY = 'surveySummary',
  USER = 'user',
  WORKSPACE = 'workspace'
}

export { ModuleNames }
