import { render } from "./MarginalTable.vue?vue&type=template&id=4b8c68ab"
import script from "./MarginalTable.vue?vue&type=script&lang=ts"
export * from "./MarginalTable.vue?vue&type=script&lang=ts"

import "./MarginalTable.vue?vue&type=style&index=0&id=4b8c68ab&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4b8c68ab"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4b8c68ab', script)) {
    api.reload('4b8c68ab', script)
  }
  
  module.hot.accept("./MarginalTable.vue?vue&type=template&id=4b8c68ab", () => {
    api.rerender('4b8c68ab', render)
  })

}

script.__file = "src/components/method-marginal/MarginalTable.vue"

export default script