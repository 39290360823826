import { GetterTree } from 'vuex'

import { SurveyGetterEnum } from '@/store/enums/getters/survey'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { RootState } from '@/store/types'
import { SurveyState } from '@/store/types/states/survey'

export const getters: GetterTree<SurveyState, RootState> = {
  [SurveyGetterEnum.SURVEYS]: (state: SurveyState) => {
    return state[SurveyStateEnum.SURVEY_LIST]?.content
  }
}
