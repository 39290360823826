import { render } from "./VariableCollectionMethodCol.vue?vue&type=template&id=ea8ebcbc"
import script from "./VariableCollectionMethodCol.vue?vue&type=script&lang=ts"
export * from "./VariableCollectionMethodCol.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "ea8ebcbc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ea8ebcbc', script)) {
    api.reload('ea8ebcbc', script)
  }
  
  module.hot.accept("./VariableCollectionMethodCol.vue?vue&type=template&id=ea8ebcbc", () => {
    api.rerender('ea8ebcbc', render)
  })

}

script.__file = "src/components/variable/VariableCollectionMethodCol.vue"

export default script