
import {
  AppstoreOutlined,
  DeploymentUnitOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  TeamOutlined
} from '@ant-design/icons-vue'
import { Flask } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import moment from 'moment'
import { computed, defineComponent, onBeforeMount, VNodeChild } from 'vue'
import { useRequest } from 'vue-request'
import { useRoute, useRouter } from 'vue-router'

import { DEFAULT_PROFILE_PHOTO_URL } from '@/constants/components'
import { DB_FIELDS } from '@/constants/database'
import { PATH, ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { logger } from '@/libs/logger'
import { getInfo } from '@/services/api/system'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { AuthGetterEnum } from '@/store/enums/getters/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { WorkspaceStateEnum } from '@/store/enums/states/workspace'
import { vuexActions, vuexGetters } from '@/store/util'
import { ValueOf } from '@/types'

import { default as helpMap } from './help.json'

interface MenuInfo {
  key: string
  keyPath: string[]
  item: VNodeChild
  domEvent: MouseEvent
}

const MENUS = {
  ACCOUNT: 'account',
  BUILDER: 'builder',
  USERS: 'users',
  WORKSPACES: 'workspaces',
  ANALYSIS: 'analysis',
  LOGOUT: 'logout',
  HELP: 'help',
  MANUAL: 'manual'
} as const

const DEFAULT_VISIBLE_MENUS = {
  [MENUS.ACCOUNT]: true,
  [MENUS.BUILDER]: true,
  [MENUS.USERS]: true,
  [MENUS.WORKSPACES]: true,
  [MENUS.ANALYSIS]: true,
  [MENUS.LOGOUT]: true,
  [MENUS.HELP]: true,
  [MENUS.MANUAL]: true
} as const

const UNPROTECTED_ROUTE_LAYOUT = {
  WITH_SCAFFOLD: false,
  RENDER: true
}

const PROTECTED_ROUTE_LAYOUT = {
  WITH_SCAFFOLD: {
    LOGO: true,
    NORTH_STRIPE: false,
    BREADCRUMB: true,
    MENUS: { ...DEFAULT_VISIBLE_MENUS }
  },
  RENDER: true
} as const

type RouteNameValue = ValueOf<typeof ROUTE_NAME>
const getRouteLayout = ({
  isAdmin,
  hasPrivilege,
  isAuthorized
}: {
  isAdmin: boolean
  hasPrivilege: boolean
  isAuthorized: boolean
}): {
  [key in RouteNameValue]: any
} => ({
  [ROUTE_NAME.ALLOCATION]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ALLOCATION_DEPRECATED]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.LOGIN]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: !isAuthorized
  },
  [ROUTE_NAME.ACCOUNT]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false,
      BREADCRUMB: false,
      LOGO: false,
      MENUS: {
        ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD.MENUS,
        [MENUS.USERS]: hasPrivilege,
        [MENUS.WORKSPACES]: hasPrivilege
      }
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.HOME]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.MANUAL]: {
    RENDER: isAuthorized
  },
  [ROUTE_NAME.NETWORK]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.NETWORK_VARIABLES]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.PAGE]: {
    ...PROTECTED_ROUTE_LAYOUT,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.GO_AHP]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: !isAuthorized
  },
  [ROUTE_NAME.AHP]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false,
      BREADCRUMB: false,
      LOGO: false,
      MENUS: {
        ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD.MENUS,
        [MENUS.USERS]: hasPrivilege,
        [MENUS.WORKSPACES]: hasPrivilege
      }
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.GO_QUESTIONNAIRE]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: !isAuthorized
  },
  [ROUTE_NAME.QUESTIONNAIRE]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false,
      BREADCRUMB: false,
      LOGO: false,
      MENUS: {
        ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD.MENUS,
        [MENUS.USERS]: hasPrivilege,
        [MENUS.WORKSPACES]: hasPrivilege
      }
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEYS]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEYS_NEW]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_STOCHASTICS]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_STATUSES]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_SUMMARY]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_ANALYTICS]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.USERS]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized && isAdmin
  },
  [ROUTE_NAME.BUILDER]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.WORKSPACES]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_WORKSPACES]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_WORKSPACE]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_CAUSEEFFECT]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_HYBRID]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_WHATIF]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_SENSITIVITY]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_OPTION_DESIGN]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.WORKSPACE]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  }
})

const frontendVersion = process.env.VUE_APP_UI_VERSION

export default defineComponent({
  name: 'App',
  components: {
    AppstoreOutlined,
    DeploymentUnitOutlined,
    InfoCircleOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
    Flask,
    Icon
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const router = useRouter()
    const { data: dataInfo } = useRequest(getInfo)

    const backendVersion = computed(() =>
      dataInfo.value
        ? dataInfo.value.build?.version +
          ' ' +
          dataInfo.value.git?.commit?.id +
          ' ' +
          moment(dataInfo.value.git?.commit?.time).format('YY-MM-DD|HH:MM')
        : ''
    )

    const isAuthorized = computed(() => store.state.auth[AuthStateEnum.IS_AUTHORIZED])
    const currentWorkspace = computed(
      () => store.state.workspace[WorkspaceStateEnum.CURRENT_WORKSPACE]
    )
    const currentSurvey = computed(() => store.state.survey[SurveyStateEnum.CURRENT_SURVEY])
    const currentPathName = computed(() => route?.name)
    const currentUser = computed(() => store.state[ModuleNames.AUTH][AuthStateEnum.USER])
    const currentUserProfilePhoto = computed(
      () => currentUser.value?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
    )
    const routeLayouts = computed(() => {
      const hasPrivilege = store.getters[
        vuexGetters(ModuleNames.AUTH, AuthGetterEnum.HAS_PRIVILEGE)
      ] as boolean
      const isAdmin = store.getters[
        vuexGetters(ModuleNames.AUTH, AuthGetterEnum.IS_ADMIN)
      ] as boolean
      return getRouteLayout({ isAdmin, hasPrivilege, isAuthorized: isAuthorized.value })
    })
    const currentLayout = computed(() => {
      const routeName = route?.name
      let layout = UNPROTECTED_ROUTE_LAYOUT
      if (routeName && Object.prototype.hasOwnProperty.call(routeLayouts.value, routeName)) {
        layout = routeLayouts.value[routeName as RouteNameValue]
      }
      return layout
    })
    const currentManualPage = computed(() => {
      const routePath = route.path.split('/').filter((each) => each !== '/' && each !== '')
      const HELP = helpMap as any
      let pdfPage

      if (routePath[0] === 'analysis' && routePath[1] === 'workspaces') {
        const helpAnalysisWorkspace = HELP[routePath[0]][routePath[1]][routePath[3]]
        if (helpAnalysisWorkspace) {
          pdfPage = helpAnalysisWorkspace
        }
      } else if (routePath[0] === 'users') {
        pdfPage = HELP[routePath[0]]
      } else if (routePath[0] === 'workspaces') {
        const helpWorkspace = HELP[routePath[0]][routePath[2]]
        if (routePath[2] === 'surveys') {
          const helpSurvey = helpWorkspace[routePath[4]]
          if (helpSurvey) {
            pdfPage = helpSurvey
          } else {
            pdfPage = helpWorkspace['*']
          }
        } else if (helpWorkspace) {
          pdfPage = helpWorkspace
        } else {
          pdfPage = HELP[routePath[0]]['*']
        }
      }
      return pdfPage
    })
    const currentUrlPath = computed(() => {
      let parts = route.path.split('/').filter((each) => each !== '/' && each !== '')
      logger.info('currentUrlPath', currentWorkspace.value, parts)
      let crumbs = []
      for (let i = 0; i < parts.length; i++) {
        let part = parts[i]
        let path = ''
        let name = part
        if (part === 'workspaces') {
          if (parts[i - 1] === 'analysis') {
            path = '/analysis'
          }
          path += '/workspaces'
          crumbs.push({
            name,
            path
          })
          if (i === parts.length - 1) {
            continue
          }
        } else if (parts[i - 1] === 'workspaces') {
          let workspaceId = part
          if (parts[i - 2] === 'analysis') {
            path = '/analysis'
          }
          path += `/workspaces/${workspaceId}`
          if (currentWorkspace.value && workspaceId === currentWorkspace.value.id) {
            name = currentWorkspace.value.name
          } else {
            name = workspaceId
          }
          crumbs.push({
            name,
            path
          })
        } else if (part === 'surveys') {
          crumbs.push({
            name: part,
            path: `/workspaces/${currentWorkspace.value && currentWorkspace.value.id}/surveys`
          })
          if (i === parts.length - 1) {
            continue
          }
          let surveyId = parts[i + 1]
          if (surveyId === currentSurvey.value?.id && currentWorkspace.value?.id) {
            crumbs.push({
              name: currentSurvey.value.name,
              path: `/workspaces/${currentWorkspace.value?.id}/surveys/${currentSurvey.value?.id}`
            })
          } else {
            crumbs.push({
              name: surveyId,
              path: null // `/workspaces/${workspaceId}`
            })
          }
          i++
        } else if (part === 'builder') {
          crumbs.push({
            name: 'builder (WIP)',
            path: null
          })
        } else {
          crumbs.push({
            name: part,
            path: null
          })
        }
      }
      return crumbs
    })

    /**
     * Handle click menu items
     **/
    const handleClickMenu = (e: MenuInfo) => {
      const { key } = e
      switch (key) {
        case MENUS.ACCOUNT:
          router.push({
            name: ROUTE_NAME.ACCOUNT
          })
          break
        case MENUS.BUILDER:
          router.push({
            name: ROUTE_NAME.BUILDER
          })
          break
        case MENUS.USERS:
          router.push({
            name: ROUTE_NAME.USERS
          })
          break
        case MENUS.WORKSPACES:
          router.push({
            name: ROUTE_NAME.WORKSPACES
          })
          break
        case MENUS.ANALYSIS:
          router.push({
            name: ROUTE_NAME.ANALYSIS_WORKSPACES
          })
          break
        case MENUS.LOGOUT:
          store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.LOG_OUT))
          break
        case MENUS.MANUAL:
          // eslint-disable-next-line no-case-declarations
          let routeData = router.resolve({
            name: ROUTE_NAME.MANUAL
          })
          if (currentManualPage.value) {
            routeData = router.resolve({
              name: ROUTE_NAME.MANUAL,
              query: {
                pageNumber: currentManualPage.value.pageNumber,
                totalPages: currentManualPage.value.totalPages
              }
            })
          } else {
            routeData = router.resolve({
              name: ROUTE_NAME.MANUAL
            })
          }

          window.open(routeData.href, '_blank')
          break
        case MENUS.HELP:
          break
        default:
          break
      }
    }

    onBeforeMount(() => {
      store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.SYNC_USER_PREFERENCE))
    })

    return {
      dataInfo,
      frontendVersion,
      backendVersion,
      MENUS,
      PATH,
      ROUTE_NAME,
      currentLayout,
      currentPathName,
      currentUrlPath,
      currentUser,
      currentUserProfilePhoto,
      handleClickMenu,
      isAuthorized
    }
  }
})
