/*
SMILE Node Types

#define DSL_DECISION 1 // set if decision node
#define DSL_CHANCE 2 // set if chance node
#define DSL_DETERMINISTIC 4 // set if deterministic node
#define DSL_UTILITY 8 // set if utility node
// these other flags represent some properties of the nodes
#define DSL_DISCRETE 16 // set if discrete, cleared if continous
#define DSL_CASTLOGIC 32 // set if node is CAST logic
#define DSL_DEMORGANLOGIC 64 // set if node is DeMorgan
#define DSL_NOISYMAXLOGIC 128 // set if Noisy MAX representation
#define DSL_NOISYADDERLOGIC 256 // set if NoisyAdder representation
// temporary flag
#define DSL_PARENTSCONTIN 512 // set if any parent is continuous
// and more stuff
#define DSL_SCC 1024 // set if strongly-coupled component of equation
// types of definitions
// node type features
#define DSL_TRUTHTABLE (DSL_DETERMINISTIC | DSL_DISCRETE)
#define DSL_CPT (DSL_CHANCE | DSL_DISCRETE)
#define DSL_NOISY_MAX (DSL_CHANCE | DSL_DISCRETE | DSL_NOISYMAXLOGIC)
#define DSL_NOISY_ADDER (DSL_CHANCE | DSL_DISCRETE | DSL_NOISYADDERLOGIC)
#define DSL_CAST (DSL_CHANCE | DSL_DISCRETE | DSL_CASTLOGIC)
#define DSL_DEMORGAN (DSL_CHANCE | DSL_DISCRETE | DSL_DEMORGANLOGIC)
#define DSL_LIST (DSL_DECISION | DSL_DISCRETE)
#define DSL_TABLE (DSL_UTILITY)
#define DSL_MAU (DSL_UTILITY | DSL_PARENTSCONTIN)
#define DSL_EQUATION (DSL_DETERMINISTIC)
#define DSL_EQUATION_SCC (DSL_DETERMINISTIC | DSL_SCC)
#define DSL_NO_DEFINITION 0 // used when definition not created
*/

export enum NodeType {
  DECISION = 'DECISION',
  CHANCE = 'CHANCE',
  DETERMINISTIC = 'DETERMINISTIC',
  BELIEF = 'BELIEF',
  DISCRETE = 'DISCRETE',
  CASTLOGIC = 'CASTLOGIC',
  DEMORGANLOGIC = 'DEMORGANLOGIC',
  UTILITY = 'UTILITY',
  UNKNOWN = 'UNKNOWN',
  DUMMY = 'DUMMY',
  NOISYMAXLOGIC = 'NOISYMAXLOGIC',
  NOISYADDERLOGIC = 'NOISYADDERLOGIC',
  PARENTSCONTIN = 'PARENTSCONTIN',
  SCC = 'SCC',
  TRUTH_TABLE = 'TRUTH_TABLE',
  CPT = 'CPT',
  NOISY_MAX = 'NOISY_MAX',
  NOISY_ADDER = 'NOISY_ADDER',
  CAST = 'CAST',
  DEMORGAN = 'DEMORGAN',
  LIST = 'LIST',
  TABLE = 'TABLE',
  MAU = 'MAU',
  EQUATION = 'EQUATION',
  EQUATION_SCC = 'EQUATION',
  NO_DEFINITION = 'NO_DEFINITION'
}

export const checkNodeType = (type: string): NodeType => {
  const ntype = type.toUpperCase()
  if (ntype in NodeType) {
    return ntype as NodeType
  }
  return NodeType.UNKNOWN
}
