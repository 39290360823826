import { includes, isEmpty, keys } from 'ramda'
import { indexBy, pick, prop } from 'ramda'

import { objectId } from '@/libs/utils'
import { Ext, NetworkForm, NetworkSchema, Workspace } from '@/types'

import { NodeType } from './enums/NodeType'
import { StatePolarity } from './enums/StatePolarity'
import { LIKELIHOOD, StateArray, Variable } from './Variable'

type Generation = Record<Variable['id'], Array<Variable>>

export const SUPPORTED_NODE_TYPES = [
  NodeType.CPT,
  NodeType.DECISION,
  NodeType.DETERMINISTIC,
  NodeType.TRUTH_TABLE
]

export class Network {
  id: string
  name: string
  variables: Array<Variable>
  variableNum: number
  variableMap: Record<Variable['id'], Variable>
  variableMapByKey: Record<string, Variable>
  variableOrder: Record<Variable['id'], number>
  parents: Generation
  parentsOriginalOrder: Generation
  children: Generation
  cyElements: any
  subModels: any
  nodes: any
  edges: any
  nodeMap: Record<string, any>
  edgeMap: Record<string, any>
  fsNetworkId: string | undefined | null

  constructor(network: NetworkSchema) {
    const { id, name, edges, nodes, subModels, fsNetworkId } = network
    this.name = name
    this.id = id
    this.variableNum = 0
    this.variables = []
    this.variableMap = {
      [LIKELIHOOD.id]: LIKELIHOOD
    }
    this.variableMapByKey = {}
    this.variableOrder = {}
    this.cyElements = { nodes: [], edges: [] }
    this.nodes = nodes
    this.edges = edges
    this.fsNetworkId = fsNetworkId
    this.nodeMap = indexBy(prop('id'), nodes)
    this.edgeMap = indexBy((edge) => edge.source + '-' + edge.target, edges)
    this.subModels = subModels

    // Add nodes
    for (let i = 0; i < nodes.length; i++) {
      const {
        id,
        name,
        type,
        description = '',
        states = [],
        isReversed = false,
        ext = { dependent: false },
        cssStyle = '',
        nodeDefinition = []
      } = nodes[i]
      let shortName = nodes[i].shortName
      if (shortName === 'shortName') {
        shortName = name
      }
      this.cyElements.nodes.push({
        data: {
          id,
          name
        }
      })
      // Support believe node only for now
      const tipe = isEmpty(type) || type === NodeType.BELIEF ? NodeType.CPT : type
      if (!includes(tipe, SUPPORTED_NODE_TYPES)) {
        continue
      }
      const stateArray = states.map((state) => pick(['id', 'name', 'description'], state))
      const key = shortName || name
      const variable = new Variable(
        id,
        name,
        description,
        stateArray as StateArray,
        false,
        tipe,
        isReversed,
        ext as Ext,
        cssStyle,
        nodeDefinition,
        key
      )
      this.variables.push(variable)
      this.variableMap[id] = variable
      this.variableMapByKey[key] = variable
      this.variableOrder[id] = this.variableNum
      this.variableNum++
    }
    this.parents = {}
    this.parentsOriginalOrder = {}
    this.children = {}
    for (let i = 0; i < edges.length; i++) {
      const { source, target } = edges[i]
      this.cyElements.edges.push({
        data: { id: `e-${i}`, source, target }
      })

      if (!this.variableMap[source] || !this.variableMap[target]) {
        // ignore missing nodes
        continue
      }
      // Add edge-related map
      if (!this.children[source]) {
        this.children[source] = []
      }
      if (!this.parents[target]) {
        this.parents[target] = []
      }
      if (!this.parentsOriginalOrder[target]) {
        this.parentsOriginalOrder[target] = []
      }
      this.children[source].push(this.variableMap[target])
      this.parents[target].push(this.variableMap[source])
      this.parentsOriginalOrder[target].push(this.variableMap[source])
    }
    this.sortParents()
  }

  sortParents(): void {
    for (let i = 0; i < this.variables.length; i++) {
      const variable = this.variables[i]
      let parents = this.getParents(variable)
      parents = parents.sort((parent1: Variable, parent2: Variable): number => {
        if (parent1.isDependent() !== parent2.isDependent()) {
          return parent1.isDependent() ? -1 : 1
        }
        return 0
      })
      this.setParents(variable, parents)
    }
  }

  setParents(variable: Variable, parents: Array<Variable>): void {
    this.parents[variable.id] = parents
  }

  getParents(variable: Variable): Array<Variable> {
    return this.parents[variable.id] || []
  }

  isTerminal(variable: Variable): boolean {
    return this.getParents(variable).length === 0
  }

  getChildren(variable: Variable): Array<Variable> {
    return this.children[variable.id] || []
  }

  getDependencies(variable: Variable): any {
    const parents = this.getParents(variable)
    const dependents = []
    const independents = []
    const dependentMap = new Map()
    const independentMap = new Map()
    for (let i = 0; i < parents.length; i++) {
      const parent = parents[i]
      if (parent.isDependent()) {
        dependents.push(parent)
        dependentMap.set(parent.id, parent)
      } else {
        independents.push(parent)
        independentMap.set(parent.id, parent)
      }
    }
    return {
      dependents,
      independents,
      dependentMap,
      independentMap
    }
  }

  serialize(workspaceId: Workspace['id'], isGenerateNewObjectId = true): NetworkForm {
    const { variables, parents, nodeMap, edgeMap } = this
    const tempMap: Record<string, any> = {}
    for (let i = 0; i < variables.length; i++) {
      const variable = variables[i]
      const _states = variable.states
      const states = []
      for (let j = 0; j < _states.length; j++) {
        const { name, polarity, id } = _states[j]
        const state = {
          id: isGenerateNewObjectId ? objectId() : id,
          name,
          desirable: polarity === StatePolarity.POSITIVE,
          description: 'description'
        }
        states.push(state)
      }
      const { id, name, key, ext, type, cssStyle, nodeDefinition } = variable
      const mongoId = isGenerateNewObjectId ? objectId() : id
      tempMap[id] = mongoId
      const node = {
        id: mongoId,
        name,
        states,
        type: type || NodeType.CPT,
        description: 'description',
        ext,
        cssStyle,
        shortName: key,
        nodeDefinition
      }
      if (id in nodeMap) {
        Object.assign(nodeMap[id], node)
      } else {
        this.nodes.push(node)
      }
    }
    const parentIDs = keys(parents)
    for (let i = 0; i < parentIDs.length; i++) {
      const parentID = parentIDs[i]
      const sources = parents[parentID]
      for (let j = 0; j < sources.length; j++) {
        const { id } = sources[j]
        const target = tempMap[parentID]
        const source = tempMap[id]
        const edge = {
          target,
          source
        }
        const edgeKey = source + '-' + target
        if (edgeKey in edgeMap) {
          // not required for now
          // Object.assign(edgeMap[edgeKey], edge)
        } else {
          this.edges.push(edge)
        }
      }
    }
    return {
      workspaceId,
      subModels: this.subModels,
      nodes: this.nodes,
      edges: this.edges
    }
  }
}
