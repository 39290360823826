/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { message } from 'ant-design-vue'
import { max, min } from 'lodash-es'
import { all, isNil } from 'ramda'
import { Ref, ref } from 'vue'

import {
  AnalysisTask,
  ChartData,
  findMaxProbIndex,
  findStateIndex,
  InputNode,
  NodeSelection,
  OutputNode,
  ROW_TYPE
} from '@/components/analysis/libs/common'
import { Network, State } from '@/libs/bayes'
import { complexRow, indexOfMax, numberCell, simpleRow } from '@/libs/common'
import { syncExecuteSensitivity } from '@/services/api/analysis'
import { getJobTask } from '@/services/api/jobTask'
import { Job, JobInputNode, JobOutputNode } from '@/types/database/job'

export const OMITTED = 'OMIT'
export const ANALYSIS_API: 'v1' | 'v2' | 'v3' = 'v2' // v0, v1 and v2

export const inputNodeMapper = ({
  key,
  state,
  value,
  variation,
  variations
}: InputNode): JobInputNode => {
  return {
    key,
    state,
    value,
    variation,
    variations
  }
}

export const outputNodeMapper = ({ key, utilityVector }: OutputNode): JobOutputNode => {
  return {
    key,
    utilityVector
  }
}

export default function useSensitivity(
  workspaceId: string,
  networks: Ref<Network[]>,
  networkMap: Ref<Record<string, Network>>,
  nodeSelections: Ref<NodeSelection[]>,
  nodeSelectionMap: Ref<Record<string, NodeSelection>>,
  inputNodeKeys: Ref<string[]>,
  outputNodeKeys: Ref<string[]>,
  tasks: Ref<AnalysisTask[]>,
  refreshCallback: () => void,
  successCallback: () => void = () => undefined,
  currentJob: Ref<Job>
): any {
  const chartNodeIds: Ref<any[]> = ref([]) // unused
  const isExecuting: Ref<boolean> = ref(false)
  const sensResultMap: Ref<Record<string, Record<string, Record<string, any>>>> = ref({})
  const commonNodeKeys: Ref<string[]> = ref([])

  const selectedNetwork: Ref<any> = ref()
  const selectedObservedNodeKey: Ref<any> = ref()
  const selectedVariationIndex: Ref<number> = ref(0)

  const chanceRows: Ref<any[]> = ref([])
  const detRows: Ref<any[]> = ref([])
  const commonVariations: Ref<any[]> = ref([])
  const allVariations: Ref<any[]> = ref([])
  const maxDetStates: Ref<number[]> = ref([])
  const baselineIndex: Ref<number> = ref(0)
  const minOutputValue: Ref<number> = ref(0)
  const maxOutputValue: Ref<number> = ref(100)

  const chanceChartData: Ref<ChartData> = ref([])
  const chanceChartByInputData: Ref<ChartData> = ref([])
  const detChartData: Ref<ChartData> = ref([])
  const detChartByInputData: Ref<ChartData> = ref([])

  const updateSensResultMap = (
    inputNodes: any,
    outputNodes: any,
    results: any,
    nodeDefinitions: any,
    commonVariations: any
  ) => {
    for (let i = 0; i < results.length; i++) {
      const outKey: string = outputNodes[i].key
      const deltaPerOutputRow = results[i]
      const deltaPerOutput: any = {}
      for (let j = 0; j < deltaPerOutputRow.length; j++) {
        const inKey = inputNodes[j].key
        const states = inputNodes[j].states
        const state = inputNodes[j].state
        const cpt = nodeDefinitions[j]
        const maxIndex = findMaxProbIndex(cpt)
        const deltaPerVarNodeRow = deltaPerOutputRow[j]
        const deltaPerVarNode: any = {}
        let baseline
        if (inputNodes[j].isDeterministic) {
          baseline = states[maxIndex]?.state
        } else {
          const baselineIndex = state
            ? findStateIndex(
                states.map(({ state }: { state: State }) => state),
                state
              )
            : maxIndex
          baseline = cpt[baselineIndex]
        }
        for (let k = 0; k < deltaPerVarNodeRow.length; k++) {
          const value = deltaPerVarNodeRow[k]
          if (inputNodes[j].isDeterministic) {
            if (inputNodes[j].states[k]) {
              const key = inputNodes[j].states[k].state.name
              deltaPerVarNode[key] = value
            }
          } else {
            const key = commonVariations[k]
            deltaPerVarNode[key] = value
          }
        }
        deltaPerOutput[inKey] = {
          deltaMap: deltaPerVarNode,
          values: deltaPerVarNodeRow,
          baseline
        }
      }
      sensResultMap.value[outKey] = deltaPerOutput
    }
  }

  const extendVariations = (_commonVariations: number[]) => {
    const variations = [..._commonVariations]
    let position = variations.findIndex((v) => v > 0)
    if (position < 0) {
      position = _commonVariations.length
    }
    variations.splice(position, 0, 0)
    return {
      variations,
      position
    }
  }

  const updateRowsWithResult = (result: any) => {
    const _chanceRows: any[] = []
    const _detRows: any[] = []
    const { results, baselines, nodeDefinitions } = result
    let _commonVariations: any[] = []
    if (isNil(selectedNetwork.value) || isNil(selectedObservedNodeKey.value)) {
      return
    }
    const inputNodes: any[] = []
    const outputNodes: any[] = []
    nodeSelections.value?.forEach((node: NodeSelection) => {
      const { isInput, isOutput, key, isDeterministic, variations = [], state } = node
      const variable = selectedNetwork.value.variableMapByKey[key]
      if (!variable) {
        return
      }
      if (isInput) {
        inputNodes.push({
          key,
          state,
          states: variable.getAllStates(),
          variations,
          isDeterministic
        })
        if (variations) {
          _commonVariations = variations
        }
      } else if (isOutput) {
        outputNodes.push({
          key,
          utilityVector: node.utilityVector,
          outcomes: [],
          value: 0
        })
      }
    })
    if (!inputNodes.length) {
      return
    }
    const baseline = baselines[inputNodes[0].key][selectedObservedNodeKey.value]

    updateSensResultMap(inputNodes, outputNodes, results, nodeDefinitions, _commonVariations)
    let _maxDetStates = 0
    const resultForObservedNode = sensResultMap.value[selectedObservedNodeKey.value]
    if (!resultForObservedNode) {
      return
    }

    const { variations: _allVariations, position } = extendVariations(_commonVariations)
    allVariations.value = _allVariations
    const _chanceChartData: ChartData = []
    const _chanceChartByInputData: any = []
    const _detChartData: ChartData = []
    const _detChartByInputData: any = []
    let minValue = 100
    let maxValue = 0
    inputNodes.forEach(({ key, isDeterministic, state, states }) => {
      const row: any = {}
      row.name = key
      const resultForVariationNode = resultForObservedNode[key].values
      row.baseline = resultForObservedNode[key].baseline
      const results = resultForVariationNode || _commonVariations.map(() => 0)
      if (all((x) => x === 0, results)) {
        return
      }
      if (isDeterministic) {
        row.states = states
        const variable = selectedNetwork.value.variableMapByKey[key]
        const nodeDefinition = variable.nodeDefinition
        const baseStateIndex = nodeDefinition?.length
          ? indexOfMax(nodeDefinition)
          : states.length - 1
        row.baseStateIndex = baseStateIndex >= states.length ? states.length - 1 : baseStateIndex
        row.results = resultForVariationNode || states.map(() => 0)
        if (_maxDetStates < states?.length) {
          _maxDetStates = states.length
        }
        _detRows.push(row)
        let chartResults = [...results]
        // results.splice(position, 0, baseline)
        // chartResults.splice(position, 0, 0)
        const minPerInput = min(chartResults)
        const maxPerInput = max(chartResults)
        minValue = min([minValue, minPerInput])
        maxValue = max([maxValue, maxPerInput])
        chartResults = chartResults.map((r) => Number(r).toFixed(4))
        _detChartData.push(chartResults)
        _detChartByInputData.push({
          key,
          stats: [[minPerInput, maxPerInput]]
        })
      } else {
        row.states = state
        let chartResults = [...results]
        results.splice(position, 0, baseline)
        chartResults.splice(position, 0, 0)
        const minPerInput = min(chartResults)
        const maxPerInput = max(chartResults)
        minValue = min([minValue, minPerInput])
        maxValue = max([maxValue, maxPerInput])
        chartResults = chartResults.map((r) => Number(r).toFixed(4))
        row.results = results
        _chanceRows.push(row)
        _chanceChartData.push(chartResults)
        _chanceChartByInputData.push({
          key,
          stats: [[minPerInput, maxPerInput]]
        })
      }
    })
    minOutputValue.value = minValue
    maxOutputValue.value = maxValue
    baselineIndex.value = position
    chanceRows.value = _chanceRows
    detRows.value = _detRows
    chanceChartByInputData.value = _chanceChartByInputData
    chanceChartData.value = _chanceChartData
    detChartByInputData.value = _detChartByInputData
    detChartData.value = _detChartData
    commonVariations.value = _commonVariations
    maxDetStates.value = [...Array(_maxDetStates).keys()]
    return {
      inputNodes,
      outputNodes,
      maxDetStates
    }
  }

  const executeAnalysisSync = async () => {
    if (!selectedNetwork.value) {
      return
    }
    isExecuting.value = true
    const networkId = selectedNetwork.value.id
    const variationNodes: any[] = []
    const observedNodes: any[] = []

    nodeSelections.value?.forEach((node: NodeSelection) => {
      const { isInput, isOutput, key, isDeterministic, variations = [] } = node
      let state = node.state
      const variable = selectedNetwork.value.variableMapByKey[key]
      if (!variable) {
        return
      }
      if (!state || state === 'auto') {
        state = undefined
      }
      if (isInput) {
        const variationNode: any = {
          key,
          state
        }
        if (!isDeterministic) {
          // variationNode.variation = (variationStep || 40) / 100
          variationNode.variations = variations.map((x) => x / 100.0)
          variationNode.values = variations.map((x) => x / 100.0)
        }
        variationNodes.push(variationNode)
      } else if (isOutput) {
        observedNodes.push({
          key,
          utilityVector: node.utilityVector
        })
      }
    })

    const analysisParams = { networkId, variationNodes, observedNodes }
    const result = await syncExecuteSensitivity(workspaceId, analysisParams)
    updateRowsWithResult(result)
    if (refreshCallback) {
      refreshCallback()
    }
    isExecuting.value = false
  }

  const pullResult = async (selectedTask: AnalysisTask) => {
    const jobId = currentJob.value?.id
    if (!jobId) {
      message.error('You need to save the job first')
      return
    }

    const jobTask = await getJobTask(workspaceId, jobId, selectedTask.id)
    if (!jobTask) {
      return
    }
    if (jobTask.status !== 'SUCCESS') {
      message.info('The task might still be running')
      return
    }
    if (!jobTask?.result) {
      message.error('No result is found')
      return
    }
    updateRowsWithResult(jobTask?.result?.sensitivityResults)
  }

  const exportTabular = (): any => {
    const datas: any[] = []
    const names = ['Chance', 'Deterministic']
    let data: any[] = []

    const chanceRows2 = chanceRows.value.map((row: any) => {
      const row2: any = {
        name: row.name,
        key: row.name,
        baseline: (row.baseline * 100).toFixed(4)
      }
      for (let i = 0; i < allVariations.value.length; i++) {
        row2['r' + i] = {
          value: row.results[i],
          isBaseline: i === baselineIndex.value
        }
      }
      return row2
    })

    let header: any[] = ['Input node', 'Baseline %']
    for (let i = 0; i < allVariations.value.length; i++) {
      header.push(allVariations.value[i] + '%')
    }
    data.push(simpleRow(header))
    chanceRows2.forEach((row: any) => {
      const { name, baseline } = row
      const dataRow: any[] = [name, baseline]
      for (let i = 0; i < allVariations.value.length; i++) {
        dataRow.push(numberCell(row[`r${i}`].value * 100, 4))
      }
      data.push(complexRow(dataRow))
    })
    datas.push(data)
    data = []
    header = ['Input node', 'Baseline']
    for (let i = 0; i < maxDetStates.value.length; i++) {
      header.push('State' + (i + 1))
    }
    data.push(simpleRow(header))
    detRows.value.forEach((row: any) => {
      const { name, baseline } = row
      const dataRow: any[] = [name, baseline.name]
      for (let i = 0; i < maxDetStates.value.length; i++) {
        if (row.states?.[i]?.state) {
          dataRow.push(numberCell(row.results[i] ? Number(row.results[i]).toFixed(4) : '0'))
        } else {
          dataRow.push('')
        }
      }
      data.push(complexRow(dataRow))
    })
    datas.push(data)
    return {
      names,
      datas
    }
  }

  const clearResult = () => {
    chanceRows.value = []
    detRows.value = []
    chanceChartData.value = []
    chanceChartByInputData.value = []
    detChartData.value = []
    detChartByInputData.value = []
  }

  return {
    clearResult,
    pullResult,
    exportTabular,
    minOutputValue,
    maxOutputValue,
    chanceRows,
    detRows,
    allVariations,
    commonVariations,
    maxDetStates,
    chanceChartData,
    chanceChartByInputData,
    detChartData,
    detChartByInputData,
    chartNodeIds,
    executeAnalysisSync,
    inputNodeKeys,
    isExecuting,
    outputNodeKeys,
    ROW_TYPE,
    selectedNetwork,
    selectedObservedNodeKey,
    selectedVariationIndex,
    commonNodeKeys,
    baselineIndex
  }
}
