// Separate emit event by component
const EMIT_EVENTS = {
  WORKSPACE: {
    TOGGLE_DIALOG_VISIBILITY: 'TOGGLE_DIALOG_VISIBILITY',
    CLICK_WORKSPACE_CARD: 'CLICK_WORKSPACE_CARD'
  },
  NETWORK: {
    LOAD: 'LOAD',
    TOGGLE_DEFAULT_NETWORK_SAVE_MODAL: 'toggleDefaultNetworkSaveModal',
    TOGGLE_VARIABLE_CHANGES_SAVE_MODAL: 'toggleVariableChangesSaveModal',
    TOGGLE_IMPORT_NETWORK_MODAL: 'toggleImportNetworkModal',
    SAVE_DEFAULT_NETWORK: 'saveDefaultNetwork',
    SAVE_VARIABLE_CHANGES: 'saveVariableChanges',
    CONFIRM_DELETE_NETWORK_RECORDS: 'confirmDeleteNetworkRecords'
  },
  VARIABLE: {
    SELECT: 'select',
    CHANGE: 'change',
    SELECT_ALLOCATION: 'selectionAllocation'
  },
  SURVEY: {
    VARIABLE_TOGGLE_OUTPUT: 'toggleOutput',
    VARIABLE_TOGGLE_CAIN: 'toggleCain'
  }
}

export { EMIT_EVENTS }
export { EMIT_EVENTS as EVENTS }
