import { AxiosError } from 'axios'

import axios from '@/axios'
import { ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'
import { NetworkForm, NetworkSchema, Workspace } from '@/types'

/**
 * Get a network
 * @param id - network uuid
 */
const getNetwork = async (id: NetworkSchema['id']): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.NETWORK({ id })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Get a network source
 * @param id - network uuid
 */
const getNetworkSource = async (id: NetworkSchema['id']): Promise<any> => {
  try {
    const result = await axios.get(ENDPOINTS.NETWORK_SOURCE({ id }), {
      responseType: 'blob'
    })
    const data = extractResponse(result)
    return data
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Get all networks for a specific workspace
 * @param workspaceId - Workspace id
 */
const getNetworks = async (workspaceId: Workspace['id']): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.NETWORKS({ workspaceId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Submit network data to server
 * FIXME: return type should be Promise<Survey> and Promise<Error>?
 * @param workspaceId - workspace id
 * @param payload - network definition
 */
const createNetwork = async ({
  workspaceId,
  payload
}: {
  workspaceId: Workspace['id']
  payload: NetworkForm
}): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.NETWORKS({ workspaceId }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Update a network
 */
const updateNetwork = async ({
  id,
  payload
}: {
  id: NetworkSchema['id']
  payload: NetworkForm
}): Promise<any> => {
  try {
    return extractResponse(await axios.put(ENDPOINTS.NETWORK({ id }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Delete a network from server
 * @param id - network uuid
 */
const deleteNetwork = async (id: NetworkSchema['id']): Promise<any> => {
  try {
    return extractResponse(await axios.delete(ENDPOINTS.NETWORK({ id })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Download a network
 */
const exportNetworkAsXdsl = async ({ id }: { id: NetworkSchema['id'] }): Promise<any> => {
  try {
    return extractResponse(
      await axios.get(ENDPOINTS.NETWORK_XDSL({ id }), { params: { layout: true } })
    )
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Upload a network in XDSL to server
 * @param workspaceId - workspace id
 * @param payload - network form value
 */
const uploadNetwork = async ({
  workspaceId,
  file
}: {
  workspaceId: Workspace['id']
  file: File
}): Promise<any> => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    return extractResponse(await axios.post(ENDPOINTS.NETWORKS_IMPORT({ workspaceId }), formData))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

export {
  createNetwork,
  exportNetworkAsXdsl,
  updateNetwork,
  uploadNetwork,
  deleteNetwork,
  getNetwork,
  getNetworks,
  getNetworkSource
}
