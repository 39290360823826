import { render } from "./SurveyPage.vue?vue&type=template&id=b059c7c4"
import script from "./SurveyPage.vue?vue&type=script&lang=ts"
export * from "./SurveyPage.vue?vue&type=script&lang=ts"

import "./SurveyPage.vue?vue&type=style&index=0&id=b059c7c4&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "b059c7c4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b059c7c4', script)) {
    api.reload('b059c7c4', script)
  }
  
  module.hot.accept("./SurveyPage.vue?vue&type=template&id=b059c7c4", () => {
    api.rerender('b059c7c4', render)
  })

}

script.__file = "src/components/survey/SurveyPage.vue"

export default script