import { render } from "./CainTable.vue?vue&type=template&id=1f35cf6b"
import script from "./CainTable.vue?vue&type=script&lang=ts"
export * from "./CainTable.vue?vue&type=script&lang=ts"

import "./CainTable.vue?vue&type=style&index=0&id=1f35cf6b&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1f35cf6b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1f35cf6b', script)) {
    api.reload('1f35cf6b', script)
  }
  
  module.hot.accept("./CainTable.vue?vue&type=template&id=1f35cf6b", () => {
    api.rerender('1f35cf6b', render)
  })

}

script.__file = "src/components/method-cain/CainTable.vue"

export default script