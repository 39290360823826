
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import { computed, defineComponent } from 'vue'
import { Tippy } from 'vue-tippy'

import StateSymbol from '@/components/symbols/StateSymbol.vue'
import { tipBodyCls, tipHeaderCls } from '@/components/symbols/styles'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { Variable } from '@/libs/bayes/Variable'
import { CSS, Theme, VARIABLE_THEME } from '@/libs/theme'

export default defineComponent({
  components: {
    StateSymbol,
    Tippy
  },
  props: {
    variable: { type: Variable, required: true },
    relation: { type: String, default: undefined },
    index: { type: Number, default: undefined }
  },
  setup(props) {
    const theme: Theme = VARIABLE_THEME[props.relation || VariableRelation.SELECTED]
    const isDependent = computed(() => {
      return props.variable.isDependent()
    })
    return {
      isDependent,
      tipHeaderCls,
      tipBodyCls,
      theme,
      CSS
    }
  }
})
