<template>
  <div class="sza-workspace sz-wrapper">
    <splitpanes class="default-theme">
      <pane min-size="10" size="40">
        <div class="sza-workspace-meta">
          <div class="body-container" :style="cardStyle">
            <div class="title" :title="workspace.name">{{ workspace.name }}</div>
            <div class="updated">Last updated {{ modified }}</div>
            <div class="description">{{ workspace.description }}</div>
          </div>
          <Import
            class="sza-import"
            :workspace-id="workspaceId"
            v-on="{
              [EMIT_EVENTS.NETWORK.LOAD]: handleLoadNetwork
            }"
          />
          <div class="sza-networks">
            <a-table
              size="small"
              :data-source="networkRows"
              :columns="networkColumns"
              :pagination="false"
            />
          </div>
          <div class="sza-selections">
            <div class="title">Common Variables</div>
          </div>
        </div>
      </pane>
      <pane>
        <div>
          <div>What-if Table</div>
          <div class="sz-command-bar">
            <a-button type="link" @click="init">
              <template #icon>
                <Icon size="14">
                  <LayoutGridAdd />
                </Icon>
              </template>
              <span>Init</span>
            </a-button>
            <a-button type="link" @click="calculate">
              <template #icon>
                <Icon size="14">
                  <Run />
                </Icon>
              </template>
              <span>Calculate</span>
            </a-button>
            <!-- <a-button type="primary" @click="showPalette">Change Color Scheme</a-button> -->
          </div>
          <div style="min-height: 100px" />
          <div class="sz-command-bar">
            <a-button type="link" @click="showPalette">
              <template #icon>
                <Icon size="14">
                  <Palette />
                </Icon>
              </template>
              <span>Change Chart Colors</span>
            </a-button>
          </div>
        </div>
      </pane>
    </splitpanes>
    <a-modal
      v-model:visible="paletteVisible"
      class="sza-palette-picker"
      width="60vw"
      @ok="selectTaskPalette"
    >
      <PalettePicker v-model="taskPaletteName" />
    </a-modal>
  </div>
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

import { LayoutGridAdd, Palette, Run } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'
import { useRoute } from 'vue-router'

import useAnalysis from '@/components/analysis/composable/base-deprecated'
import { CATEGORICAL_PALETTE_MAP, CATEGORICAL_PALETTES } from '@/components/common/color-scheme'
import PalettePicker from '@/components/common/PalettePicker.vue'
import Import from '@/components/network/XdslImport.vue'
import { EMIT_EVENTS } from '@/constants/emits'
import { ROUTE_NAME } from '@/constants/router'
dayjs.extend(relativeTime)
import { ColorSchemeItem } from '@/components/common/color-scheme'
import { Network } from '@/libs/bayes'
import { gradientMaker } from '@/libs/utils'
import { getNetworks } from '@/services/api/network'
import { getWorkspace } from '@/services/api/workspace'
import { useStore } from '@/store'
import { NetworkSchema } from '@/types'

export default defineComponent({
  components: {
    LayoutGridAdd,
    Run,
    Icon,
    Import,
    Pane,
    Splitpanes,
    Palette,
    PalettePicker
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const paletteVisible: Ref<boolean> = ref(false)
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const inputs: Ref<number[][]> = ref([])
    const outputs: Ref<number[][]> = ref([])
    const { run, data } = useRequest(getWorkspace, {
      manual: true
    })
    const taskPaletteName: Ref<string> = ref('')
    const taskPaletteColors: Ref<string[]> = ref([])

    const { run: runNetworks, data: dataNetworks } = useRequest(getNetworks, {
      manual: true
    })

    const rawNetworks = computed(() => dataNetworks.value?.content || [])
    const networks = computed(() =>
      rawNetworks.value?.map((rawNetwork: NetworkSchema) => new Network(rawNetwork))
    )
    const workspace = computed(() => data.value || {})
    const created = computed(() => dayjs(workspace.value?.createdDate).fromNow())
    const modified = computed(() => dayjs(workspace.value?.lastModifiedDate).fromNow())

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const {
      chartData,
      chartNodeIds,
      whatIfRows,
      selections,
      updateSelections,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      initTasks,
      addTask,
      removeTask,
      updateInputOutputNodes,
      updateWhatIfRows,
      executeAnalysis,
      updateRowsWithUtilityVector
    } = useAnalysis(workspaceId, networks, store, updateTable)

    const networkColumns = computed(() => {
      return [
        {
          title: 'Network name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Nodes count',
          dataIndex: 'nodes.length',
          key: 'nodes'
        }
      ]
    })

    const networkRows = computed(
      () =>
        networks.value?.map((network: any) => ({
          key: network.id,
          nodes: network.nodes,
          name: network.name
        })) || []
    )

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(workspace.value?.name || 'UNTITLED')
    }))

    const handleLoadNetwork = () => {
      runNetworks(workspaceId as string)
    }

    const calculate = () => {
      executeAnalysis()
      updateTable()
    }

    const toggleInput = () => {
      updateInputOutputNodes()
      // updateWhatIfRows()
    }
    const toggleOutput = () => {
      updateInputOutputNodes()
      // updateWhatIfRows()
    }

    const init = () => {
      initTasks()
      updateWhatIfRows()
    }

    const onUtilityUpdated = (key: string) => {
      updateRowsWithUtilityVector(key)
    }

    watch(networks, () => {
      updateSelections()
    })

    watch(selections, () => {
      updateWhatIfRows()
    })

    onMounted(() => {
      run(workspaceId as string)
      runNetworks(workspaceId as string)
    })

    const showPalette = () => {
      paletteVisible.value = !paletteVisible.value
    }

    const selectTaskPalette = () => {
      taskPaletteColors.value = CATEGORICAL_PALETTE_MAP[taskPaletteName.value]?.items?.map(
        ({ color }: ColorSchemeItem) => color
      )
      paletteVisible.value = false
    }

    const onDuplicateTask = (taskId: string, taskIndex: number) => {
      addTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    const onRemoveTask = (taskId: string, taskIndex: number) => {
      removeTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    return {
      onDuplicateTask,
      onRemoveTask,
      showPalette,
      tableKey,
      initTasks,
      toggleInput,
      toggleOutput,
      inputs,
      outputs,
      networkColumns,
      networkRows,
      selections,
      EMIT_EVENTS,
      ROUTE_NAME,
      created,
      modified,
      workspace,
      workspaceId,
      networks,
      cardStyle,
      whatIfRows,
      tasks,
      handleLoadNetwork,
      calculate,
      onUtilityUpdated,
      table,
      init,
      inputNodeKeys,
      outputNodeKeys,
      chartNodeIds,
      chartData,
      paletteVisible,
      taskPaletteName,
      taskPaletteColors,
      CATEGORICAL_PALETTES,
      CATEGORICAL_PALETTE_MAP,
      selectTaskPalette
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/base.styl"
@import "../../../styles/commons.styl"

.sza-workspace
  .sz-command-bar
    span
      margin-left: 8px

.sza-workspace-meta
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  flex-direction: column
  > div
    flex: 1 1 auto

  .sza-import
    flex: 0 0 auto
    height: 50px

  .sza-import
    flex: 0 0 auto
    height: 30px
    padding: 3px 5px

  .sza-selections
    .title
      font-size 16px
      font-weight bold
      padding 4px 5px

  .body-container
    width 100%
    overflow hidden
    min-height 1px
    flex 1 1 auto
    display flex
    flex-direction column
    > *
      min-height 1px
      padding-left: 5px

    .title
      @extend .truncate
      font-size 18px
      font-weight bold
      flex 0 0 auto

    .description
      overflow auto
      padding 3px 0 4px 5px
      font-size 12px
      line-height 15px
      flex 1 1 auto
      color grey

    .updated
      flex 0 0 auto
      margin 4px 0 0 0
      font-size 12px
      color #a0a0a0

  .action-container
    flex 0 0 auto
    display flex
    flex-direction row
    justify-content space-between
    align-items flex-end
    height 40px

    .anticon
      font-size 24px
</style>
