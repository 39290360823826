import { render } from "./PlainComment.vue?vue&type=template&id=1ce4af36"
import script from "./PlainComment.vue?vue&type=script&lang=ts"
export * from "./PlainComment.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1ce4af36"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1ce4af36', script)) {
    api.reload('1ce4af36', script)
  }
  
  module.hot.accept("./PlainComment.vue?vue&type=template&id=1ce4af36", () => {
    api.rerender('1ce4af36', render)
  })

}

script.__file = "src/components/analysis/hybrid/PlainComment.vue"

export default script