import { WorkspaceStateEnum } from '@/store/enums/states/workspace'
import { WorkspaceState } from '@/store/types/states/workspace'

const state: WorkspaceState = {
  [WorkspaceStateEnum.IS_CREATING]: false,
  [WorkspaceStateEnum.IS_UPDATING]: false,
  [WorkspaceStateEnum.IS_DELETING]: false,
  [WorkspaceStateEnum.IS_GETTING]: false,
  [WorkspaceStateEnum.WORKSPACES]: {},
  [WorkspaceStateEnum.CURRENT_WORKSPACE]: null,
  [WorkspaceStateEnum.ERROR]: null,
  [WorkspaceStateEnum.LOADING_PROMPT_TEXT]: null
}

export { state }
