<template>
  <div class="sz-prob-discrete">
    <div class="sz-prob-step-slider">
      <a-slider
        v-model:value="val"
        :min="min"
        :max="max"
        :marks="marks"
        :step="step"
        :disabled="disabled"
        @change="change"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { is, range, reduce } from 'ramda'
import { defineComponent, PropType, ref, watch } from 'vue'

import { Variable } from '@/libs/bayes/Variable'

export const EVENTS = {
  CHANGE: 'update:modelValue',
  USER_CHANGE: 'onUserChange'
}

const LABELS: Record<number, string> = {
  0: 'Impossible',
  5: 'Very Unlikely',
  15: 'Unlikely',
  30: 'Fairly Unlikely',
  50: 'As likely as Not',
  70: 'Fairly Likely',
  85: 'Likely',
  95: 'Very Likely',
  100: 'Certain'
}

export default defineComponent({
  components: {},
  props: {
    modelValue: { type: Number, default: undefined },
    variable: { type: Object as PropType<Variable>, default: undefined },
    config: { type: Object, default: undefined },
    disabled: { type: Boolean, default: undefined },
    isDeterministic: { type: Boolean, default: false }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const min = ref(0)
    const max = ref(100)
    const step = ref(5)
    const val = ref<number | undefined>(props.modelValue)
    const marks = ref(
      reduce(
        (acc: Record<number, any>, v) => {
          let value = v * 5
          acc[value] = {
            style: {
              fontSize: 10
            },
            label: LABELS[value] || ''
          }
          return acc
        },
        {},
        range(0, 21)
      )
    )
    watch(
      () => props.modelValue,
      (v) => {
        val.value = is(Number, v) ? v : undefined
      }
    )

    watch(val, (v) => {
      emit(EVENTS.CHANGE, is(Number, v) ? v : undefined)
    })

    const change = () => {
      emit(EVENTS.USER_CHANGE, val.value)
    }

    return {
      change,
      marks,
      max,
      min,
      step,
      val
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/vars.styl"
@import "../../styles/commons.styl"

.sz-prob-discrete
  display block

  .sz-prob-step-slider
    @extend .centered
    flex: 1 1 auto
    margin: 0 7px 0 10px
    padding: 2px 0
    width: 400px
    height: 100px

    .ant-slider
      position: relative
      top: 45px

  .ant-slider-mark-text
    transform: translateX(-50%) rotate(270deg) !important
    bottom: 60px
    width 100px
    text-align left

  .ant-slider-rail
    background-color: #f0ffff

  .ant-slider-with-marks
    margin: 8px 6px 24px 6px

  .ant-slider-mark
    font-size: 12px

  .ant-slider-mark-text
    color: rgba(0, 0, 0, 0.30)

  .ant-slider-mark-text-active
      color: rgba(0, 0, 0, 0.30)

  .ant-input-number-input
    font-size 18px

  .ant-input-number
    width: 72px
</style>
