import { invert } from 'lodash-es'

import { ModuleNames } from '@/constants/vuex'
import { AllocationActionEnum } from '@/store/enums/actions/allocation'
// Action types
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { BuilderActionEnum } from '@/store/enums/actions/builder'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { QuestionnaireActionEnum } from '@/store/enums/actions/questionnaire'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { SurveyStatusActionEnum } from '@/store/enums/actions/surveyStatus'
import { SurveySummaryActionEnum } from '@/store/enums/actions/surveySummary'
import { UserActionEnum } from '@/store/enums/actions/user'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { AllocationGetterEnum } from '@/store/enums/getters/allocation'
//Getter types
import { QuestionnaireGetterEnum } from '@/store/enums/getters/questionnaire'
import { SurveyGetterEnum } from '@/store/enums/getters/survey'
import { AllocationMutationEnum } from '@/store/enums/mutations/allocation'
// Mutation types
import { AuthMutationEnum } from '@/store/enums/mutations/auth'
import { BuilderMutationEnum } from '@/store/enums/mutations/builder'
import { NetworkMutationEnum } from '@/store/enums/mutations/network'
import { QuestionnaireMutationEnum } from '@/store/enums/mutations/questionnaire'
import { SurveyMutationEnum } from '@/store/enums/mutations/survey'
import { SurveyStatusMutationEnum } from '@/store/enums/mutations/surveyStatus'
import { SurveySummaryMutationEnum } from '@/store/enums/mutations/surveySummary'
import { UserMutationEnum } from '@/store/enums/mutations/user'
import { WorkspaceMutationEnum } from '@/store/enums/mutations/workspace'
import { NamespacedActions, NamespacedGetters, NamespacedMutations } from '@/store/types'

import { AuthGetterEnum } from './enums/getters/auth'

type ActionName =
  | `${AuthActionEnum}`
  | `${BuilderActionEnum}`
  | `${UserActionEnum}`
  | `${SurveyActionEnum}`
  | `${SurveySummaryActionEnum}`
  | `${SurveyStatusActionEnum}`
  | `${WorkspaceActionEnum}`
  | `${AllocationActionEnum}`
  | `${NetworkActionEnum}`
  | `${QuestionnaireActionEnum}`

const moduleMap = {
  [ModuleNames.ALLOCATION]: {
    actions: invert(AllocationActionEnum),
    mutations: invert(AllocationMutationEnum)
  },
  [ModuleNames.AUTH]: {
    actions: invert(AuthActionEnum),
    mutations: invert(AuthMutationEnum)
  },
  [ModuleNames.BUILDER]: {
    actions: invert(BuilderActionEnum),
    mutations: invert(BuilderMutationEnum)
  },
  [ModuleNames.NETWORK]: {
    actions: invert(NetworkActionEnum),
    mutations: invert(NetworkMutationEnum)
  },
  [ModuleNames.QUESTIONNAIRE]: {
    actions: invert(QuestionnaireActionEnum),
    mutations: invert(QuestionnaireMutationEnum)
  },
  [ModuleNames.SURVEY]: {
    actions: invert(SurveyActionEnum),
    mutations: invert(SurveyMutationEnum)
  },
  [ModuleNames.SURVEY_SUMMARY]: {
    actions: invert(SurveySummaryActionEnum),
    mutations: invert(SurveySummaryMutationEnum)
  },
  [ModuleNames.SURVEY_STATUS]: {
    actions: invert(SurveyStatusActionEnum),
    mutations: invert(SurveyStatusMutationEnum)
  },
  [ModuleNames.USER]: {
    actions: invert(UserActionEnum),
    mutations: invert(UserMutationEnum)
  },
  [ModuleNames.WORKSPACE]: {
    actions: invert(WorkspaceActionEnum),
    mutations: invert(WorkspaceMutationEnum)
  }
}

type vuexActionsResponse = keyof NamespacedActions

type MutationName =
  | `${AuthMutationEnum}`
  | `${BuilderMutationEnum}`
  | `${UserMutationEnum}`
  | `${SurveyMutationEnum}`
  | `${SurveySummaryMutationEnum}`
  | `${SurveyStatusMutationEnum}`
  | `${WorkspaceMutationEnum}`
  | `${AllocationMutationEnum}`
  | `${NetworkMutationEnum}`
  | `${QuestionnaireMutationEnum}`

type vuexMutationsResponse = keyof NamespacedMutations

type GETTER_NAMES =
  | `${QuestionnaireGetterEnum}`
  | `${AllocationGetterEnum}`
  | `${SurveyGetterEnum}`
  | `${AuthGetterEnum}`

type GETTER_KEYS = keyof NamespacedGetters

const QUESTIONNAIRE_GETTER_VALUE: string[] = Object.values(QuestionnaireGetterEnum)
const ALLOCATION_GETTER_VALUE: string[] = Object.values(AllocationGetterEnum)
const SURVEY_GETTER_VALUE: string[] = Object.values(SurveyGetterEnum)
const AUTH_GETTER_VALUE: string[] = Object.values(AuthGetterEnum)

/**
 * Generate namespaced action based on module name and action name
 * @param moduleName
 * @param actionName
 */
const vuexActions = <K extends vuexActionsResponse>(
  moduleName: `${ModuleNames}`,
  actionName: ActionName
): K => {
  if (moduleName in moduleMap && actionName in moduleMap[moduleName]?.actions) {
    return `${moduleName}/${actionName}` as K
  }
  throw new Error(`Unexpected moduleName ${moduleName}, actionName ${actionName}`)
}

/**
 * Generate namespaced mutation based on module name and mutation name
 * @param moduleName
 * @param mutationName
 */
const vuexMutations = <K extends vuexMutationsResponse>(
  moduleName: `${ModuleNames}`,
  mutationName: MutationName
): K => {
  if (moduleName in moduleMap && mutationName in moduleMap[moduleName]?.mutations) {
    return `${moduleName}/${mutationName}` as K
  }
  throw new Error(`Unexpected moduleName ${moduleName}, mutationName ${mutationName}`)
}

/**
 * Generate namespaced getter based on module name and mutation name
 * @param moduleName
 * @param getterName
 */
const vuexGetters = <K extends GETTER_KEYS>(
  moduleName: `${ModuleNames}`,
  getterName: GETTER_NAMES
): K => {
  switch (moduleName) {
    case ModuleNames.QUESTIONNAIRE:
      if (!QUESTIONNAIRE_GETTER_VALUE.includes(getterName)) {
        throw new Error(`Unexpected moduleName ${moduleName}, getterName ${getterName}`)
      }
      break
    case ModuleNames.ALLOCATION:
      if (!ALLOCATION_GETTER_VALUE.includes(getterName)) {
        throw new Error(`Unexpected moduleName ${moduleName}, getterName ${getterName}`)
      }
      break
    case ModuleNames.SURVEY:
      if (!SURVEY_GETTER_VALUE.includes(getterName)) {
        throw new Error(`Unexpected moduleName ${moduleName}, getterName ${getterName}`)
      }
      break
    case ModuleNames.AUTH:
      if (!AUTH_GETTER_VALUE.includes(getterName)) {
        throw new Error(`Unexpected moduleName ${moduleName}, getterName ${getterName}`)
      }
      break
    default:
      throw new Error(`Unexpected moduleName ${moduleName}, getterName ${getterName}`)
  }
  return `${moduleName}/${getterName}` as K
}

export { vuexActions, vuexMutations, vuexGetters }
