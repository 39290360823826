import { render } from "./WorkspaceList.vue?vue&type=template&id=71a02c59"
import script from "./WorkspaceList.vue?vue&type=script&lang=ts"
export * from "./WorkspaceList.vue?vue&type=script&lang=ts"

import "./WorkspaceList.vue?vue&type=style&index=0&id=71a02c59&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "71a02c59"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('71a02c59', script)) {
    api.reload('71a02c59', script)
  }
  
  module.hot.accept("./WorkspaceList.vue?vue&type=template&id=71a02c59", () => {
    api.rerender('71a02c59', render)
  })

}

script.__file = "src/components/workspace/WorkspaceList.vue"

export default script