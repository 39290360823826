/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum BuilderActionEnum {
  CREATE_NODE = 'CREATE_NODE',
  UPDATE_NODE = 'UPDATE_NODE',
  DELETE_NODE = 'DELETE_NODE',
  GET_NODE = 'GET_NODE',
  GET_NODES = 'GET_NODES',
  CREATE_EDGE = 'CREATE_EDGE',
  UPDATE_EDGE = 'UPDATE_EDGE',
  DELETE_EDGE = 'DELETE_EDGE',
  GET_EDGE = 'GET_EDGE',
  GET_EDGES = 'GET_EDGES'
}
