/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum WorkspaceActionEnum {
  'GET_WORKSPACES' = 'GET_WORKSPACES',
  'CREATE_WORKSPACE' = 'CREATE_WORKSPACE',
  'GET_WORKSPACE' = 'GET_WORKSPACE',
  'UPDATE_WORKSPACE' = 'UPDATE_WORKSPACE',
  'DELETE_WORKSPACE' = 'DELETE_WORKSPACE'
}
