import { name } from '../../package.json'

const LOCAL_STORAGE = {
  STORE_NAME: name,
  NAME: name,
  KEYS: {
    TOKEN: {
      KEY_NAME: 'token',
      KEYS: {
        ACCESS_TOKEN: 'token',
        REFRESH_TOKEN: 'refreshToken',
        TYPE: 'tokenType'
      }
    },
    USER_PREFERENCE: {
      KEY_NAME: 'user_preference',
      KEYS: {
        LOGIN_REMEMBER_ME: 'rememberMe'
      }
    }
  }
}

export default LOCAL_STORAGE
