/* eslint-disable @typescript-eslint/no-unused-vars */
import { findIndex } from 'lodash-es'
import { MutationTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
// import { MESSAGE } from '@/constants/message'
import { BuilderMutationEnum } from '@/store/enums/mutations/builder'
import { BuilderStateEnum } from '@/store/enums/states/builder'
import type { BuilderMutation } from '@/store/types/mutations/builder'
import type { BuilderState } from '@/store/types/states/builder'
import type { EdgeListSchema, EdgeSchema, NodeListSchema, NodeSchema } from '@/types'

/**
 * Mutation function
 */
const mutations: MutationTree<BuilderState> & BuilderMutation = {
  // Get nodes
  [BuilderMutationEnum.GET_NODES](state: BuilderState) {
    state[BuilderStateEnum.IS_GETTING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_NODE
  },
  [BuilderMutationEnum.GET_NODES_FULFILLED](state: BuilderState, payload: NodeListSchema) {
    state[BuilderStateEnum.IS_GETTING] = false
    state[BuilderStateEnum.NODE_LIST] = payload
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [BuilderMutationEnum.GET_NODES_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_GETTING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Get node
  [BuilderMutationEnum.GET_NODE](state: BuilderState) {
    state[BuilderStateEnum.IS_GETTING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_NODE
  },
  [BuilderMutationEnum.GET_NODE_FULFILLED](state: BuilderState, payload: NodeSchema) {
    state[BuilderStateEnum.IS_GETTING] = false
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [BuilderMutationEnum.GET_NODE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_GETTING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Create node
  [BuilderMutationEnum.CREATE_NODE](state: BuilderState) {
    state[BuilderStateEnum.IS_CREATING] = true
  },
  [BuilderMutationEnum.CREATE_NODE_FULFILLED](state: BuilderState, payload: NodeSchema) {
    state[BuilderStateEnum.IS_CREATING] = false
  },
  [BuilderMutationEnum.CREATE_NODE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_CREATING] = false
    state[BuilderStateEnum.ERROR] = error
  },
  // Update node
  [BuilderMutationEnum.UPDATE_NODE](state: BuilderState) {
    state[BuilderStateEnum.IS_UPDATING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.UPDATING_NODE
  },
  [BuilderMutationEnum.UPDATE_NODE_FULFILLED](state: BuilderState, payload: NodeSchema) {
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
    const updatedId = payload?.id
    const index = findIndex(state[BuilderStateEnum.NODE_LIST], {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    state[BuilderStateEnum.IS_UPDATING] = false
    if (index !== -1) {
      const newContent = [
        ...state[BuilderStateEnum.NODE_LIST].slice(0, index),
        payload,
        ...state[BuilderStateEnum.NODE_LIST].slice(index + 1)
      ]
      state[BuilderStateEnum.NODE_LIST] = newContent
    }
  },
  [BuilderMutationEnum.UPDATE_NODE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_UPDATING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Delete node
  [BuilderMutationEnum.DELETE_NODE](state: BuilderState) {
    state[BuilderStateEnum.IS_DELETING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.DELETING_NODE
  },
  [BuilderMutationEnum.DELETE_NODE_FULFILLED](state: BuilderState, id: NodeSchema['id']) {
    const index = findIndex(state[BuilderStateEnum.NODE_LIST], {
      [DB_FIELDS.COMMON.ID]: id
    })
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
    state[BuilderStateEnum.IS_DELETING] = false
    if (index !== -1) {
      const newContent = [
        ...state[BuilderStateEnum.NODE_LIST].slice(0, index),
        ...state[BuilderStateEnum.NODE_LIST].slice(index + 1)
      ]
      state[BuilderStateEnum.NODE_LIST] = newContent
    }
  },
  [BuilderMutationEnum.DELETE_NODE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_DELETING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Get edges
  [BuilderMutationEnum.GET_EDGES](state: BuilderState) {
    state[BuilderStateEnum.IS_GETTING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_EDGE
  },
  [BuilderMutationEnum.GET_EDGES_FULFILLED](state: BuilderState, payload: EdgeListSchema) {
    state[BuilderStateEnum.IS_GETTING] = false
    state[BuilderStateEnum.EDGE_LIST] = payload
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [BuilderMutationEnum.GET_EDGES_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_GETTING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Get edge
  [BuilderMutationEnum.GET_EDGE](state: BuilderState) {
    state[BuilderStateEnum.IS_GETTING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_EDGE
  },
  [BuilderMutationEnum.GET_EDGE_FULFILLED](state: BuilderState, payload: EdgeSchema) {
    state[BuilderStateEnum.IS_GETTING] = false
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [BuilderMutationEnum.GET_EDGE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_GETTING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Create edge
  [BuilderMutationEnum.CREATE_EDGE](state: BuilderState) {
    state[BuilderStateEnum.IS_CREATING] = true
  },
  [BuilderMutationEnum.CREATE_EDGE_FULFILLED](state: BuilderState, payload: EdgeSchema) {
    state[BuilderStateEnum.IS_CREATING] = false
  },
  [BuilderMutationEnum.CREATE_EDGE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_CREATING] = false
    state[BuilderStateEnum.ERROR] = error
  },
  // Update edge
  [BuilderMutationEnum.UPDATE_EDGE](state: BuilderState) {
    state[BuilderStateEnum.IS_UPDATING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.UPDATING_EDGE
  },
  [BuilderMutationEnum.UPDATE_EDGE_FULFILLED](state: BuilderState, payload: EdgeSchema) {
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
    const updatedId = payload?.id
    const index = findIndex(state[BuilderStateEnum.EDGE_LIST], {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    state[BuilderStateEnum.IS_UPDATING] = false
    if (index !== -1) {
      const newContent = [
        ...state[BuilderStateEnum.EDGE_LIST].slice(0, index),
        payload,
        ...state[BuilderStateEnum.EDGE_LIST].slice(index + 1)
      ]
      state[BuilderStateEnum.EDGE_LIST] = newContent
    }
  },
  [BuilderMutationEnum.UPDATE_EDGE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_UPDATING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Delete edge
  [BuilderMutationEnum.DELETE_EDGE](state: BuilderState) {
    state[BuilderStateEnum.IS_DELETING] = true
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.DELETING_EDGE
  },
  [BuilderMutationEnum.DELETE_EDGE_FULFILLED](state: BuilderState, id: EdgeSchema['id']) {
    const index = findIndex(state[BuilderStateEnum.EDGE_LIST], {
      [DB_FIELDS.COMMON.ID]: id
    })
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
    state[BuilderStateEnum.IS_DELETING] = false
    if (index !== -1) {
      const newContent = [
        ...state[BuilderStateEnum.EDGE_LIST].slice(0, index),
        ...state[BuilderStateEnum.EDGE_LIST].slice(index + 1)
      ]
      state[BuilderStateEnum.EDGE_LIST] = newContent
    }
  },
  [BuilderMutationEnum.DELETE_EDGE_REJECTED](state: BuilderState, error: Error) {
    state[BuilderStateEnum.IS_DELETING] = false
    state[BuilderStateEnum.ERROR] = error
    // state[BuilderStateEnum.LOADING_PROMPT_TEXT] = null
  }
}

export { mutations }
