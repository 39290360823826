
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IconDeviceFloppy, IconFileImport } from '@tabler/icons-vue'
import { computed, defineComponent, Ref, ref } from 'vue'

import UtilityVector from '@/components/analysis/common/UtilityVector.vue'
import {
  eigenCalculationMethodOptions,
  scalingMethodOptions
} from '@/components/analysis/composable/hybrid-criteria'
import { cssIcon } from '@/styles/common'

import HybridHierarchyImport, { EVENTS as IMPORT_EVENTS } from './HybridHierarchyImport.vue'

export type CriteriaRowType = {
  parentKey?: string
  type?: string
  level?: number
  childrenKeys: string[]
  utilityVector?: number[]
  userIdToWeightMap?: Record<string, number>
}

export const EVENTS = {
  IMPORT: 'IMPORT',
  SAVE: 'SAVE'
}

export default defineComponent({
  components: {
    HybridHierarchyImport,
    IconFileImport,
    IconDeviceFloppy,
    UtilityVector
  },
  props: {
    criteriaRows: { type: Object, default: undefined },
    networkMap: { type: Object, default: undefined }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const isImportVisible: Ref<boolean> = ref(false)
    const selectedScalingMethod: Ref<string> = ref('LINEAR')
    const eigenCalculationMethod: Ref<string> = ref('EVM')

    const importCriteria = () => {
      isImportVisible.value = true
    }

    const criteriaColumns = computed(() => {
      return [
        {
          title: 'Parent',
          dataIndex: 'parentKey',
          key: 'parentKey'
        },
        {
          title: 'Children',
          dataIndex: 'childrenKeys',
          key: 'childrenKeys',
          slots: {
            customRender: 'children'
          }
        },
        {
          title: 'Util vector',
          dataIndex: 'utilityVector',
          key: 'utilityVector',
          slots: {
            customRender: 'utilityVector'
          }
        },
        {
          title: 'Usernames',
          dataIndex: 'usernames',
          key: 'usernames',
          slots: {
            customRender: 'users'
          }
        },
        {
          title: '',
          key: 'actions',
          slots: {
            customRender: 'actions'
          }
        }
      ]
    })

    // const criteriaRows: Ref<CriteriaRowType[]> = computed(() => [])
    //props.criteria?.map(({ key, parentKey, childrenKey, userIds }) => ({ key, parentKey, childrenKey, userIds }))

    const importOk = async (rawText: string) => {
      isImportVisible.value = false
      emit(EVENTS.IMPORT, rawText)
    }

    const saveCriteria = () => {
      emit(EVENTS.SAVE, {
        eigenCalculationMethod: eigenCalculationMethod.value,
        selectedScalingMethod: selectedScalingMethod.value
      })
    }

    return {
      scalingMethodOptions,
      selectedScalingMethod,
      eigenCalculationMethod,
      eigenCalculationMethodOptions,
      saveCriteria,
      criteriaColumns,
      isImportVisible,
      importCriteria,
      importOk,
      IMPORT_EVENTS,
      cssIcon
    }
  }
})
