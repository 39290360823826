/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum UserActionEnum {
  'GET_USERS' = 'getUsers',
  'CREATE_USER' = 'createUser',
  'GET_USER' = 'getUser',
  'UPDATE_USER' = 'updateUser',
  'DELETE_USER' = 'deleteUser'
}
