<template>
  <a-button type="link" @click="clickHandler">
    <template #icon>
      <Icon :class="cssIcon">
        <slot></slot>
      </Icon>
    </template>
    <span>{{ label }}</span>
  </a-button>
</template>

<script lang="ts">
import { Icon } from '@vicons/utils'
import { defineComponent } from 'vue'

import { cssIcon } from '@/styles/common'

export const EVENTS = {
  CLICK: 'click'
}

export default defineComponent({
  components: {
    Icon
  },
  props: {
    label: { type: String, default: '' }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const clickHandler = () => {
      emit(EVENTS.CLICK)
    }

    return {
      clickHandler,
      cssIcon
    }
  }
})
</script>
