<template>
  <tippy :key="variable.id" theme="light">
    <template #content>
      <div :class="tipHeaderCls">
        {{ variable.name }}
      </div>
      <div v-if="variable.description" :class="tipBodyCls">
        {{ variable.description }}
      </div>
      <div class="sz-state-symbols">
        <StateSymbol
          v-for="(state, i) in variable.states"
          :key="state.id"
          :state="state"
          :index="i"
        />
      </div>
    </template>
    <div class="sz-var-symbol" :style="CSS(theme, 1)">
      <span class="name">{{ variable.name }}</span>
      <span v-if="isDependent" class="sz-var-dep-flag">D</span>
    </div>
  </tippy>
</template>

<script lang="ts">
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import { computed, defineComponent } from 'vue'
import { Tippy } from 'vue-tippy'

import StateSymbol from '@/components/symbols/StateSymbol.vue'
import { tipBodyCls, tipHeaderCls } from '@/components/symbols/styles'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { Variable } from '@/libs/bayes/Variable'
import { CSS, Theme, VARIABLE_THEME } from '@/libs/theme'

export default defineComponent({
  components: {
    StateSymbol,
    Tippy
  },
  props: {
    variable: { type: Variable, required: true },
    relation: { type: String, default: undefined },
    index: { type: Number, default: undefined }
  },
  setup(props) {
    const theme: Theme = VARIABLE_THEME[props.relation || VariableRelation.SELECTED]
    const isDependent = computed(() => {
      return props.variable.isDependent()
    })
    return {
      isDependent,
      tipHeaderCls,
      tipBodyCls,
      theme,
      CSS
    }
  }
})
</script>

<style lang="stylus">
@import '../styles/entities.styl';
</style>
