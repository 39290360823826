
import { css } from '@emotion/css'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'

import { InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import useAntTableScroll from '@/components/composables/antTableScroll'
import { genCellBgStyleRange } from '@/libs/theme'

import SensitivityResultsDeterministicTable from './SensitivityResultsDeterministicTable.vue'

export const TABS = {
  CHANCE: 'CHANCE',
  DETERMINISTIC: 'DETERMINISTIC'
}

export const EVENTS = {
  ON_MOVE_UP_ROW: 'onMoveUpRow',
  ON_MOVE_DOWN_ROW: 'onMoveDownRow',
  ON_UPDATE_NETWORK: 'onUpdateNetwork',
  ON_CALCULATE: 'onCalculate',
  ON_UPDATE_OBSERVED_NODE: 'onUpdateObservedNode',
  ON_SELECT_COLUMN: 'onSelectColumn',
  ON_SELECT_NODE_TYPE: 'onSelectNodeType'
}

export default defineComponent({
  components: {
    SensitivityResultsDeterministicTable
  },
  props: {
    selections: { type: Object, required: true },
    networks: { type: Object, required: true },
    chanceRows: { type: Object, required: true },
    detRows: { type: Object, required: true },
    allVariations: { type: Object, required: true },
    maxDetStates: { type: Object, required: true },
    baselineIndex: { type: Number, required: true },
    selectedColIndex: { type: Number, default: -1 }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const activeTab = ref(TABS.CHANCE)
    const moveUp = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_UP_ROW, record, rowIndex)
    }

    const moveDown = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_DOWN_ROW, record, rowIndex)
    }
    const maxRange: Ref<number> = ref(1)
    const minRange: Ref<number> = ref(-1)

    const { tableWrapper, scrollY, scrollX } = useAntTableScroll(null, null, 0, 0, false, 190)

    const cellBgStyle = (val: number) => {
      return genCellBgStyleRange(minRange.value, maxRange.value, val)
    }

    const detStateClass = (isBaseState: boolean) => {
      return css`
        display: flex;
        flex-direction: column;
        background-color: ${isBaseState ? 'white' : 'transparent'};
      `
    }

    const updateRange = () => {
      let _max = 0
      let _min = 0
      props.chanceRows.forEach((row: any) => {
        for (let i = 0; i < props.allVariations.length; i++) {
          if (i !== props.baselineIndex) {
            const val = row.results[i]
            if (val > _max) {
              _max = val
            }
            if (val < _min) {
              _min = val
            }
          }
        }
      })
      maxRange.value = _max
      minRange.value = _min
    }

    watch(() => props.chanceRows, updateRange)

    watch(activeTab, (tab: string) => {
      emit(EVENTS.ON_SELECT_NODE_TYPE, tab)
    })

    const chanceRows2 = computed(() => {
      return props.chanceRows.map((row: any) => {
        const row2: any = {
          name: row.name,
          key: row.name,
          baseline: Number(row.baseline * 100).toFixed(4)
        }
        for (let i = 0; i < props.allVariations.length; i++) {
          row2['r' + i] = {
            value: row.results[i],
            isBaseline: i === props.baselineIndex
          }
        }
        return row2
      })
    })

    const columns = computed(() => {
      const cols: ColumnProps[] = [
        {
          title: 'Input node',
          width: 120,
          dataIndex: 'name',
          fixed: 'left',
          key: 'name', // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
          align: 'left',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: 'Baseline %',
          width: 70,
          fixed: 'left',
          dataIndex: 'baseline',
          key: 'baseline',
          align: 'right',
          slots: {
            customRender: 'baseline'
          },
          customHeaderCell: () => {
            return {
              style: {
                'text-align': 'right',
                padding: '1px 3px'
              }
            }
          }
        }
      ]
      for (let i = 0; i < props.allVariations.length; i++) {
        cols.push({
          title: props.allVariations[i] + '%',
          width: 70,
          dataIndex: 'r' + i,
          key: 'r' + i,
          align: 'right',
          sorter: ((i_) => (a: any, b: any) => a['r' + i_].value - b['r' + i_].value)(i),
          customHeaderCell: () => {
            return {
              style: {
                'text-align': 'right',
                padding: '1px 3px'
              }
            }
          },
          slots: {
            customRender: 'value'
          }
        })
      }
      return cols
    })

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    const selectCol = (index: number) => {
      emit(EVENTS.ON_SELECT_COLUMN, index)
    }

    onMounted(() => {
      updateRange()
    })

    return {
      tableWrapper,
      scrollX,
      scrollY,
      activeTab,
      TABS,
      columns,
      chanceRows2,
      detStateClass,
      selectCol,
      update,
      cellBgStyle,
      ROW_TYPE,
      EVENTS,
      InputType,
      moveUp,
      moveDown
    }
  }
})
