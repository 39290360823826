import { AxiosError } from 'axios'

import axios from '@/axios'
import { ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'
import { Job } from '@/types/database/job'

const upgradeJob = (job: any) => {
  if (job.config) {
    job.params = job.config
  }
  return job
}

const createJob = async (workspaceId: string, payload: Job): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.JOBS({ workspaceId }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const getJobs = async (workspaceId: string): Promise<any> => {
  try {
    const jobs: any[] =
      extractResponse(await axios.get(ENDPOINTS.JOBS({ workspaceId })))?.content || []
    return jobs.map((job) => upgradeJob(job))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const getJob = async (workspaceId: string, jobId: string): Promise<any> => {
  try {
    return upgradeJob(extractResponse(await axios.get(ENDPOINTS.JOB({ workspaceId, jobId }))))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const updateJob = async (workspaceId: string, jobId: string, payload: Job): Promise<any> => {
  try {
    return upgradeJob(
      extractResponse(await axios.put(ENDPOINTS.JOB({ workspaceId, jobId }), payload))
    )
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const deleteJob = async (workspaceId: string, jobId: string): Promise<any> => {
  try {
    return extractResponse(await axios.delete(ENDPOINTS.JOB({ workspaceId, jobId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

export { createJob, getJobs, getJob, updateJob, deleteJob }
