
import { defineComponent, ref, watch } from 'vue'

import { EMIT_EVENTS } from '@/constants/emits'

export default defineComponent({
  props: {
    variable: { type: Object, required: true },
    showAux: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false }
  },
  emits: [EMIT_EVENTS.VARIABLE.CHANGE],
  setup(props, { emit }) {
    const checked = ref(props.variable.isDependent())
    /**
     * Toggle dependent
     */
    const toggleDependent = () => {
      props.variable.setDependent(!props.variable.isDependent())
      emit(EMIT_EVENTS.VARIABLE.CHANGE, props.variable)
    }

    watch(
      () => props.variable,
      () => {
        checked.value = props.variable.isDependent()
      }
    )

    return {
      checked,
      toggleDependent
    }
  }
})
