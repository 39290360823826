
import { SearchOutlined } from '@ant-design/icons-vue'
import { Apps } from '@vicons/tabler'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import WorkspaceDialog from '@/components/workspace/WorkspaceDialog.vue'
import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { WorkspaceStateEnum } from '@/store/enums/states/workspace'
import { vuexActions } from '@/store/util'
import { Workspace } from '@/types'

import WorkspaceCard from './WorkspaceCard.vue'

const SHOW_ALL_TYPES = false

export default defineComponent({
  components: {
    Apps,
    WorkspaceCard,
    WorkspaceDialog,
    SearchOutlined
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const workspaceId = computed(() => route?.params?.workspaceId)
    const workspaces = computed(() => store.state.workspace[WorkspaceStateEnum.WORKSPACES].content)
    const totalPage = computed(() => {
      if (workspaces.value) {
        return store.state.workspace[WorkspaceStateEnum.WORKSPACES]?.totalPages
      }
      return 0
    })
    const totalElements = computed(() => {
      if (workspaces.value) {
        return store.state.workspace[WorkspaceStateEnum.WORKSPACES]?.totalElements
      }
      return 0
    })

    const isWorkspaceDialogVisible = ref<boolean>(false)
    const clickedWorkspace = ref<Workspace | undefined>(undefined)
    const currentPage = ref<number>(API_DEFAULT_PAGEABLE_PARAMS.page + 1)
    const workspaceSearchInput = ref<string>('')

    /**
     * Get all workspaces
     */
    const getWorkspaces = () => {
      store.dispatch(vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACES), {
        params: Object.assign(
          SHOW_ALL_TYPES
            ? {}
            : {
                [DB_FIELDS.WORKSPACE.TYPE]: DB_ENUM_VALUES.WORKSPACE.TYPE.ELICITATION
              },
          API_DEFAULT_PAGEABLE_PARAMS,
          {
            page: currentPage.value - 1,
            size: API_DEFAULT_PAGEABLE_PARAMS.size
          }
        )
      })
    }

    /**
     * Handle display workspace dialog or not
     **/
    const toggleWorkspaceDialog = () => {
      isWorkspaceDialogVisible.value = !isWorkspaceDialogVisible.value
    }

    /**
     * Handle click workspace card to decide whether create a new one or update existed one
     * @param currentWorkspace
     */
    const handleClickWorkspaceCard = (currentWorkspace: Workspace | undefined = undefined) => {
      clickedWorkspace.value = currentWorkspace
      isWorkspaceDialogVisible.value = true
    }

    /**
     * Handle change page size
     **/
    const onPageChange = (page: number) => {
      currentPage.value = page
      getWorkspaces()
    }

    /**
     * Handle click next page
     */
    const handleClickNextPage = () => {
      if (currentPage.value < totalPage.value - 1) currentPage.value += 1
    }

    /**
     * Handle search workspace
     * @param input
     */
    const handleWorkspaceSearch = async (input: string) => {
      await store.dispatch(vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACES), {
        params: {
          // [DB_FIELDS.WORKSPACE.TYPE]: DB_ENUM_VALUES.WORKSPACE.TYPE.ELICITATION,
          [DB_FIELDS.WORKSPACE.NAME]: input.trim()
        }
      })
    }

    onMounted(() => {
      getWorkspaces()
    })

    return {
      EMIT_EVENTS,
      API_DEFAULT_PAGEABLE_PARAMS,
      workspaces,
      workspaceId,
      isWorkspaceDialogVisible,
      clickedWorkspace,
      currentPage,
      totalPage,
      totalElements,
      workspaceSearchInput,
      onPageChange,
      toggleWorkspaceDialog,
      handleClickWorkspaceCard,
      handleClickNextPage,
      handleWorkspaceSearch
    }
  }
})
