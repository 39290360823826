import axios from '@/axios/index'
import API from '@/axios/index'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import { API_PAGEABLE_PARAMS, Workspace, WorkspaceFormJSON } from '@/types'

/**
 * Create workspace
 */
const createWorkspace = async (workspace: WorkspaceFormJSON): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.WORKSPACES, workspace))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get workspaces from server
 */
const getWorkspaces = async ({
  params,
  getAll
}: {
  params?: API_PAGEABLE_PARAMS | Partial<Workspace>
  getAll?: boolean
}): Promise<any> => {
  try {
    if (getAll) {
      const requestParams = Object.assign({}, API_DEFAULT_PAGEABLE_PARAMS, params)
      return recursivePaginationAPIRequestFn(API, ENDPOINTS.WORKSPACES)(requestParams)
    } else {
      return extractResponse(await axios.get(ENDPOINTS.WORKSPACES, { params }))
    }
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get workspace from server
 */
const getWorkspace = async (id: Workspace['id']): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.WORKSPACE({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Update workspace
 */
const updateWorkspace = async (id: Workspace['id'], workspace: WorkspaceFormJSON): Promise<any> => {
  try {
    return extractResponse(await axios.put(ENDPOINTS.WORKSPACE({ id }), workspace))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Delete workspace
 */
const deleteWorkspace = async (id: Workspace['id']): Promise<any> => {
  try {
    return extractResponse(await axios.delete(ENDPOINTS.WORKSPACE({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

export { createWorkspace, getWorkspaces, getWorkspace, updateWorkspace, deleteWorkspace }
