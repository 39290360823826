enum AllocationMutationEnum {
  CREATE_ALLOCATION = 'CREATE_ALLOCATION',
  CREATE_ALLOCATION_FULFILLED = 'CREATE_ALLOCATION_FULFILLED',
  CREATE_ALLOCATION_REJECTED = 'CREATE_ALLOCATION_REJECTED',

  GET_ALLOCATIONS = 'GET_ALLOCATIONS',
  GET_ALLOCATIONS_FULFILLED = 'GET_ALLOCATIONS_FULFILLED',
  GET_ALLOCATIONS_REJECTED = 'GET_ALLOCATIONS_REJECTED',

  GET_ALLOCATION = 'GET_ALLOCATION',
  GET_ALLOCATION_FULFILLED = 'GET_ALLOCATION_FULFILLED',
  GET_ALLOCATION_REJECTED = 'GET_ALLOCATION_REJECTED',

  UPDATE_ALLOCATION = 'UPDATE_ALLOCATION',
  UPDATE_ALLOCATION_FULFILLED = 'UPDATE_ALLOCATION_FULFILLED',
  UPDATE_ALLOCATION_REJECTED = 'UPDATE_ALLOCATION_REJECTED',

  DELETE_ALLOCATION = 'DELETE_ALLOCATION',
  DELETE_ALLOCATION_FULFILLED = 'DELETE_ALLOCATION_FULFILLED',
  DELETE_ALLOCATION_REJECTED = 'DELETE_ALLOCATION_REJECTED',

  CLEAR_ALLOCATION_LIST = 'CLEAR_ALLOCATION_LIST'
}

export { AllocationMutationEnum }
