<template>
  <div class="sz-wrapper sza-workspaces sz-workspaces">
    <div class="sz-command-bar">
      <a-button type="link" @click="handleClickWorkspaceCard">
        <template #icon><Apps /></template>
        New Analysis Workspace
      </a-button>
      <div v-show="false" class="sz-command-bar-right">
        <div class="sz-search-wrapper">
          Search workspace:
          <a-input
            v-model:value="workspaceSearchInput"
            placeholder="Workspace name"
            @pressEnter="handleWorkspaceSearch(workspaceSearchInput)"
          />
          <a-tooltip placement="top">
            <template #title>
              <span>Click here or press "Enter" to search</span>
            </template>
            <SearchOutlined
              class="search-icon"
              @click="handleWorkspaceSearch(workspaceSearchInput)"
            />
          </a-tooltip>
        </div>
      </div>
    </div>
    <div class="sz-workspaces-body">
      <WorkspaceCard
        v-for="workspace in workspaces"
        :key="workspace.id"
        :workspace="workspace"
        v-on="{
          [AW_EVENTS.CLICK_CARD]: handleClickWorkspaceCard,
          [AW_EVENTS.DELETE_WORKSPACE]: deleteWorkspace
        }"
      >
      </WorkspaceCard>
      <div v-if="totalElements > API_DEFAULT_PAGEABLE_PARAMS.size" class="sz-pagination-container">
        <a-pagination
          v-model:current="current"
          :total="totalElements"
          :default-page-size="API_DEFAULT_PAGEABLE_PARAMS.size"
        />
      </div>
    </div>
    <WorkspaceDialog
      :is-visible="isWorkspaceDialogVisible"
      :workspace="clickedWorkspace"
      v-on="{ [EMIT_EVENTS.WORKSPACE.TOGGLE_DIALOG_VISIBILITY]: toggleWorkspaceDialog }"
    />
  </div>
</template>

<script lang="ts">
import { SearchOutlined } from '@ant-design/icons-vue'
import { Apps } from '@vicons/tabler'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { usePagination } from 'vue-request'

import WorkspaceDialog from '@/components/analysis/workspace/AnalysisWorkspaceDialog.vue'
import WorkspaceCard, {
  EVENTS as AW_EVENTS
} from '@/components/analysis/workspace/AnalysisWorkspaceItem.vue'
import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { getWorkspaces } from '@/services/api/workspace'
import { Workspace } from '@/types'

function workspaceProxy(args: any) {
  const params = {
    [DB_FIELDS.WORKSPACE.TYPE]: DB_ENUM_VALUES.WORKSPACE.TYPE.ANALYSIS,
    ...args
  }
  params.page = params.page - 1
  return getWorkspaces({
    params
  })
}

export default defineComponent({
  components: {
    Apps,
    WorkspaceCard,
    WorkspaceDialog,
    SearchOutlined
  },
  setup() {
    const { data, run, current, totalPage, loading, pageSize } = usePagination(workspaceProxy, {
      defaultParams: [
        {
          size: API_DEFAULT_PAGEABLE_PARAMS.size
        }
      ],
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'size',
        totalKey: 'totalElements'
      }
    })
    const workspaces = computed(() => data.value?.content || [])
    const totalElements = computed(() => data.value?.totalElements || 0)

    const isWorkspaceDialogVisible = ref<boolean>(false)
    const clickedWorkspace = ref<Workspace | undefined>(undefined)
    const workspaceSearchInput = ref<string>('')

    /**
     * Handle display workspace dialog or not
     **/
    const toggleWorkspaceDialog = () => {
      isWorkspaceDialogVisible.value = !isWorkspaceDialogVisible.value
      run({
        page: 1,
        size: API_DEFAULT_PAGEABLE_PARAMS.size
      })
    }

    /**
     * Handle click workspace card to decide whether create a new one or update existed one
     * @param currentWorkspace
     */
    const handleClickWorkspaceCard = (currentWorkspace: Workspace | undefined = undefined) => {
      clickedWorkspace.value = currentWorkspace
      isWorkspaceDialogVisible.value = true
    }

    /**
     * Handle search workspace
     * @param input
     */
    const handleWorkspaceSearch = async (input: string) => {
      run({
        page: 1,
        size: API_DEFAULT_PAGEABLE_PARAMS.size,
        [DB_FIELDS.WORKSPACE.NAME]: input.trim()
      })
    }

    const deleteWorkspace = () => {
      run({
        page: 1,
        size: API_DEFAULT_PAGEABLE_PARAMS.size
      })
    }

    onMounted(() => {
      // current.value = 0
    })

    return {
      deleteWorkspace,
      AW_EVENTS,
      EMIT_EVENTS,
      API_DEFAULT_PAGEABLE_PARAMS,
      workspaces,
      loading,
      pageSize,
      isWorkspaceDialogVisible,
      clickedWorkspace,
      current,
      totalPage,
      totalElements,
      workspaceSearchInput,
      toggleWorkspaceDialog,
      handleClickWorkspaceCard,
      handleWorkspaceSearch
    }
  }
})
</script>

<style lang="stylus">
.sza-workspaces
  .sz-search-wrapper
    flex: 1 1 auto
    font-size 12px
    display flex
    justify-content: flex-end
    align-items: center
    padding: 0 10px
    input
      width 200px
      padding 2px 10px
      margin 0 8px
    .search-icon
      cursor pointer
      color rgb(16, 142, 233)

  .sz-workspaces-body
    padding 10px
    background-color: #f2f2f2
    display flex
    flex-direction row
    flex-wrap wrap
    > div.card-container
      flex 0 0 auto
      width 300px
      height 200px
    .sz-pagination-container
      width 100%
      display flex
      justify-content center
      margin-bottom 20px
</style>
