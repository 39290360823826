import { render } from "./AceLikehood.vue?vue&type=template&id=56221564"
import script from "./AceLikehood.vue?vue&type=script&lang=ts"
export * from "./AceLikehood.vue?vue&type=script&lang=ts"

import "./AceLikehood.vue?vue&type=style&index=0&id=56221564&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "56221564"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('56221564', script)) {
    api.reload('56221564', script)
  }
  
  module.hot.accept("./AceLikehood.vue?vue&type=template&id=56221564", () => {
    api.rerender('56221564', render)
  })

}

script.__file = "src/components/method-ace/AceLikehood.vue"

export default script