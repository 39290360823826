
import 'splitpanes/dist/splitpanes.css'

import { Adjustments, DeviceFloppy, Palette, TableExport } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import { Pane, Splitpanes } from 'splitpanes'
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  Ref,
  ref,
  UnwrapRef,
  watch
} from 'vue'
import { useRoute } from 'vue-router'
import writeXlsxFile from 'write-excel-file'

import CommonAnalysis, {
  EVENTS as COMMON_EVENTS
} from '@/components/analysis/common/CommonAnalysis.vue'
import NetworkList, {
  EVENTS as NET_EVENTS
} from '@/components/analysis/common/network-list/NetworkList.vue'
import OptionDesignNodeSelection, {
  EVENTS as VAR_EVENTS
} from '@/components/analysis/common/node-selection/NodeSelectionTable.vue'
import OptionDesignTaskList, {
  EVENTS as TASK_EVENTS
} from '@/components/analysis/common/task-list/TaskList.vue'
import WorkspaceInfo from '@/components/analysis/common/workspace-info/WorkspaceInfo.vue'
import useAnalysis from '@/components/analysis/composable/analysis'
import useBase, { TABS } from '@/components/analysis/composable/base'
import useJob from '@/components/analysis/composable/job'
import useOptionDesign, {
  inputNodeMapper,
  outputNodeMapper
} from '@/components/analysis/composable/option-design'
import usePalette from '@/components/analysis/composable/palette'
import { AnalysisTask, NodeSelection } from '@/components/analysis/libs/common'
import {
  DEFAULT_OPTION_DESIGN_CONFIG,
  OptionDesignConfigData
} from '@/components/analysis/libs/option-design'
import OptionDesignApexChart from '@/components/analysis/option-design/OptionDesignApexChart.vue'
import OptionDesignConfig from '@/components/analysis/option-design/OptionDesignConfig.vue'
import OptionDesignResultsTable, {
  EVENTS as TABLE_EVENTS
} from '@/components/analysis/option-design/OptionDesignResultsTable.vue'
import { ROUTE_NAME } from '@/constants/router'
import { cssIcon } from '@/styles/common'
import { JobType } from '@/types/database/job'

const REFRESH_ENABLE = false

export default defineComponent({
  components: {
    Adjustments,
    CommonAnalysis,
    DeviceFloppy,
    Icon,
    NetworkList,
    OptionDesignApexChart,
    OptionDesignConfig,
    OptionDesignNodeSelection,
    OptionDesignResultsTable,
    OptionDesignTaskList,
    Pane,
    Palette,
    Splitpanes,
    WorkspaceInfo,
    TableExport
  },
  setup() {
    const activeTab = ref(TABS.PARAMS)
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const selectionConfig = {
      inputVisible: true,
      utilityVectorVisible: true,
      stateVisible: true,
      variationVisible: true,
      variationsVisible: false,
      constraintVisible: true,
      headers: {
        input: {
          title: 'Variable',
          desc: 'Variable Node'
        },
        output: {
          title: 'Objective',
          desc: 'Objective Node'
        }
      }
    }
    const {
      networks,
      removeNetwork,
      loadWorkspace,
      loadNetworks,
      workspace,
      workspaceModified,
      networkOptions,
      selectedNetwork,
      selectedNetworkIndex,
      workspaceHeaderStyle
    } = useBase(workspaceId)

    const {
      paletteVisible,
      paletteName,
      paletteColors,
      selectPalette,
      showPalettePicker
    } = usePalette()

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }

    const {
      currentUser,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      updateNodeSelections,
      inputNodeKeys,
      outputNodeKeys,
      updateInputOutputNodes,
      commonNodeKeys
    } = useAnalysis(workspaceId, JobType.SENSITIVITY, networks)

    const {
      currentJob,
      tasks,
      taskMap,
      loadJob,
      loadTasks,
      persistJob,
      persistTasks,
      isUpdatingJob,
      isCreatingJob,
      isLoading,
      isPersisting,
      syncTasks,
      removeTask
    } = useJob(
      currentUser,
      workspaceId,
      JobType.OPTIONDESIGN,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      onSuccessSaveJob
    )

    const {
      changeCounts,
      optionCount,
      chartData,
      chartNodeIds,
      isExecuting,
      variableNodes,
      maxOutputValue,
      minOutputValue,
      executeAnalysis,
      updateOptionDesignNodes,
      abortAnalysis,
      clearResult,
      pullResult,
      prepareTasks,
      optValByObjectiveNodeRows,
      solutionByVariableNodeRows,
      baselines,
      exportTabular
    } = useOptionDesign(
      workspaceId,
      networks,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      taskMap,
      updateTable,
      onSuccessSaveJob,
      selectedNetwork,
      currentJob
    )

    const isConfigVisible: Ref<boolean> = ref(false)
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const inputs: Ref<number[][]> = ref([])
    const outputs: Ref<number[][]> = ref([])
    const configForm = ref()
    const configFormData: UnwrapRef<OptionDesignConfigData> = reactive(
      Object.assign(
        { ...DEFAULT_OPTION_DESIGN_CONFIG },
        currentJob.value?.params?.config?.optionDesignConfig
      )
    )
    const selectedTask: Ref<AnalysisTask | undefined> = ref()

    const handleLoadNetwork = () => {
      loadNetworks()
    }

    const calculate = () => {
      if (selectedNetwork.value) {
        updateOptionDesignNodes()
        updateInputOutputNodes()
        executeAnalysis()
        updateTable()
      }
    }

    const toggleInput = () => {
      updateOptionDesignNodes()
      updateInputOutputNodes()
      // updateWhatIfRows()
    }
    const toggleOutput = () => {
      updateOptionDesignNodes()
      updateInputOutputNodes()
      // updateWhatIfRows()
    }

    const configure = () => {
      isConfigVisible.value = true
    }

    const onConfigOk = () => {
      Object.assign(configFormData, configForm.value.getData())
      isConfigVisible.value = false
    }

    const onConfigCancel = () => {
      isConfigVisible.value = false
    }

    const onVariationUpdated = ({ variation, key }: any) => {
      nodeSelections.value?.forEach((node: NodeSelection) => {
        if (node.isInput && !node.isDeterministic && node.key !== key) {
          node.variation = variation
        }
      })
    }

    watch(networks, async () => {
      updateNodeSelections()
      await loadJob()
      await loadTasks()
      if (!tasks.value?.length) {
        prepareTasks()
      }
      updateOptionDesignNodes()
      selectedNetwork.value = networks.value?.[0]
    })

    watch(nodeSelections, () => {
      // updateSensitivityRows()
    })

    watch(currentJob, () => {
      Object.assign(configFormData, currentJob.value?.params?.config?.optionDesignConfig)
    })

    let refreshTimer: any

    onMounted(async () => {
      await loadWorkspace()
      await loadNetworks()
      if (REFRESH_ENABLE) {
        refreshTimer = setInterval(() => {
          if (activeTab.value === TABS.RESULTS) {
            loadTasks()
          }
        }, 30000)
      }
    })

    onBeforeUnmount(() => {
      if (refreshTimer) {
        clearInterval(refreshTimer)
      }
    })

    const exportResults = async () => {
      const { datas, names } = exportTabular()
      await writeXlsxFile(datas, {
        sheets: names,
        fileName: workspace.value?.name + '-results.xlsx'
      })
    }

    const saveJob = async () => {
      prepareTasks()
      const optionDesignConfig = configFormData
      const variableNodesCount = variableNodes.value.length
      if (
        optionDesignConfig.maxInputNodes &&
        optionDesignConfig.maxInputNodes > variableNodesCount
      ) {
        optionDesignConfig.maxInputNodes = variableNodesCount
      }
      await persistJob({
        optionDesignConfig
      })
      await persistTasks()
    }

    const run = (task: AnalysisTask) => {
      const _network = networkMap.value?.[task.networkId]
      if (_network) {
        selectedNetwork.value = _network
        updateOptionDesignNodes()
        updateInputOutputNodes()
        executeAnalysis(task)
        updateTable()
      }
    }

    const runAll = async () => {
      updateOptionDesignNodes()
      updateInputOutputNodes()
      await executeAnalysis()
      loadTasks()
      updateTable()
    }

    const abort = (task: AnalysisTask) => {
      abortAnalysis(task)
    }

    const refresh = () => {
      loadTasks()
    }

    const remove = async (taskId: string, taskIndex: number) => {
      removeTask(taskId, taskIndex)
      await saveJob()
      if (selectedTask.value?.id === taskId) {
        clearResult()
      }
    }

    const sync = async () => {
      syncTasks()
      await saveJob()
    }

    const viewResult = (task: AnalysisTask) => {
      selectedTask.value = task
      updateOptionDesignNodes()
      updateInputOutputNodes()
      pullResult(task)
    }

    watch(selectedNetworkIndex, () => {
      const _network = networks.value?.[selectedNetworkIndex.value]
      if (_network) {
        // updateRows(_network)
        selectedNetwork.value = _network
      }
    })

    return {
      exportResults,
      optValByObjectiveNodeRows,
      solutionByVariableNodeRows,
      sync,
      abort,
      activeTab,
      calculate,
      chartData,
      chartNodeIds,
      COMMON_EVENTS,
      commonNodeKeys,
      configForm,
      configFormData,
      maxOutputValue,
      minOutputValue,
      configure,
      cssIcon,
      handleLoadNetwork,
      inputNodeKeys,
      inputs,
      isConfigVisible,
      isCreatingJob,
      isExecuting,
      isLoading,
      isPersisting,
      isUpdatingJob,
      loadNetworks,
      NET_EVENTS,
      networkMap,
      networkOptions,
      networks,
      nodeSelections,
      onConfigCancel,
      onConfigOk,
      onVariationUpdated,
      optionCount,
      outputNodeKeys,
      outputs,
      paletteColors,
      paletteName,
      paletteVisible,
      refresh,
      changeCounts,
      baselines,
      remove,
      removeNetwork,
      ROUTE_NAME,
      run,
      runAll,
      saveJob,
      selectedTask,
      selectedNetwork,
      selectedNetworkIndex,
      selectionConfig,
      selectPalette,
      showPalettePicker,
      TABLE_EVENTS,
      table,
      tableKey,
      TABS,
      TASK_EVENTS,
      tasks,
      toggleInput,
      toggleOutput,
      VAR_EVENTS,
      variableNodes,
      viewResult,
      workspace,
      workspaceHeaderStyle,
      workspaceId,
      workspaceModified
    }
  }
})
