import '@fontsource/inria-sans/300.css'
import '@fontsource/inria-sans/400.css'
import '@fontsource/inria-sans/700.css'
import '@fontsource/petrona/400.css'
import './registerServiceWorker'

// import 'vue3-easy-data-table/dist/style.css'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
// import Vue3EasyDataTable from 'vue3-easy-data-table'
dayjs.extend(relativeTime)
import {
  Avatar,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Slider,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Upload
} from 'ant-design-vue'
import { ComponentPublicInstance, createApp, h } from 'vue'
import VueApexCharts from 'vue3-apexcharts'

import App from './App.vue'
import router from './router'
import { store } from './store'

const app = createApp(App)

/**
 * Error handler if needed
 * @param err error trace
 * @param vm component in which error occur
 * @param {string} info Vue specific error information such as lifecycle hooks, events etc.
 */
app.config.errorHandler = (err: unknown, vm: ComponentPublicInstance | null, info: string) => {
  console.error(err)
  if (typeof err === 'object') {
    const { message } = err as { message: string }
    notification.error({
      message: 'Error',
      description: h('div', [h('p', message.toString()), h('p', `Info:  ${info}`)])
    })
  }
}

/**
 * Warning handler if needed
 * @param {string} msg error trace
 * @param vm component in which warning occur
 * @param {string} trace Vue specific error information such as lifecycle hooks, events etc.
 */
// app.config.warnHandler = (
//   msg: string,
//   vm: ComponentPublicInstance | null,
//   trace: string
// ) => {
//   notification.warn({
//     message: "Warn",
//     description: `${msg}\nat: ${trace}`,
//   });
// };

app.use(Avatar)
app.use(Breadcrumb)
app.use(Button)
app.use(Checkbox)
app.use(Col)
app.use(Collapse)
app.use(Divider)
app.use(Dropdown)
app.use(Form)
app.use(Input)
app.use(InputNumber)
app.use(Layout)
app.use(Menu)
app.use(Modal)
app.use(Popconfirm)
app.use(Popover)
app.use(Progress)
app.use(Radio)
app.use(Row)
app.use(Select)
app.use(Slider)
app.use(Space)
app.use(Switch)
app.use(Table)
app.use(Tabs)
app.use(Tag)
app.use(Tooltip)
app.use(Upload)
app.use(Spin)
app.use(Pagination)
// app.component('EasyDataTable', Vue3EasyDataTable)
message.config({
  maxCount: 1
})
app.use(VueApexCharts)

app.use(store)
app.use(router)
app.mount('#app')
