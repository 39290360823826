<template>
  <div v-if="combination" :class="cx('sz-input-prob-agg', probStyle)">
    <a-input-number
      v-model:value="probT"
      :disabled="disabled"
      :style="cellBgStyle"
      :tabindex="tabIndex"
      class="sz-input-prob-agg-number"
      :min="0"
      :max="100"
      @change="change"
    />
    <a-popover trigger="hover">
      <template #content>
        <Discrete
          v-if="discrete"
          v-model="probT"
          :config="discreteSteps"
          :variable="variable"
          :disabled="disabled"
          :is-deterministic="isDeterministic"
          @[DISC_EVENTS.USER_CHANGE]="changeSecondary"
        />
        <Continuous
          v-else
          v-model="probT"
          :config="continuousRange"
          :variable="variable"
          :disabled="disabled"
          :is-deterministic="isDeterministic"
          @[CONT_EVENTS.USER_CHANGE]="changeSecondary"
        />
        <a-textarea
          v-model:value="rationale"
          placeholder="Rationale"
          :rows="2"
          :disabled="disabled"
          @change="onRationaleChange"
        ></a-textarea>
      </template>
      <div :class="dotStyle" />
    </a-popover>
    <a-popover v-if="rowItem?.count" trigger="hover" class="sz-summary">
      <template #content>
        <LessPlot v-if="showLessPlot" :data="data" @[OVERRIDE]="onOverride" />
        <BoxPlot v-if="showBoxPlot" :data="data" @[OVERRIDE]="onOverride" />
        <div class="sz-breakdown">
          <div v-for="(res, index) in responses" :key="index">
            <div class="sz-breakdown-item">
              <span class="name">{{ anonymous ? 'user ' + (index + 1) : res.name }}: </span>
              <span class="value">{{ res.value }}</span>
              <span class="critical">
                <Pinned v-if="res.critical" class="critical-on" />
              </span>
              <span v-if="res.rationale" class="rationale">
                [{{ anonymous ? anonymizeRationale(res.rationale) : res.rationale }}]
              </span>
            </div>
          </div>
        </div>
      </template>
      <div :class="cx(dotAggStyle)">
        {{ Number(aggProb.toFixed(2)) }} / {{ rowItem?.count || 0 }}
      </div>
    </a-popover>
    <div
      v-if="allowCritical"
      :class="criticalStyle"
      :title="critical ? 'Critical' : 'Non-critical'"
      @click="onToggleCritical"
    >
      <Pinned v-if="critical" class="critical-on" />
      <Pin v-else class="critical-off" />
    </div>
  </div>
</template>

<script lang="ts">
import { cx } from '@emotion/css'
import { Pin, Pinned } from '@vicons/tabler'
import { message } from 'ant-design-vue'
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'

import Continuous, { EVENTS as CONT_EVENTS } from '@/components/input/Continuous.vue'
import Discrete, { EVENTS as DISC_EVENTS } from '@/components/input/Discrete.vue'
import BoxPlot, { OVERRIDE } from '@/components/viz/BoxPlot.vue'
import LessPlot from '@/components/viz/LessPlot.vue'
import { ModuleNames } from '@/constants/vuex'
import { Variable } from '@/libs/bayes'
import { anonymizeRationale } from '@/libs/common'
import { useStore } from '@/store'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { SurveySchema } from '@/types'

import { EVENTS, useProb } from './common'
import { criticalStyle, dotAggStyle, dotStyle, probStyle } from './styles'

export default defineComponent({
  components: {
    Continuous,
    Discrete,
    Pin,
    Pinned,
    BoxPlot,
    LessPlot
  },
  props: {
    allowCritical: { type: Boolean, default: false },
    analytics: { type: Object, default: undefined },
    colIndex: { type: Number, default: undefined },
    tabIndex: { type: Number, default: undefined },
    combination: { type: Object, required: true },
    combKey: { type: String, required: true },
    disabled: { type: Boolean, required: true },
    discrete: { type: Boolean, default: false },
    response: { type: Object, default: undefined },
    rowId: { type: String, default: undefined },
    rowIndex: { type: Number, default: undefined },
    userMap: { type: Object, default: undefined },
    variable: { type: Object as PropType<Variable>, required: true },
    anonymous: { type: Boolean, default: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const store = useStore()

    const currentSurvey = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.CURRENT_SURVEY] || {}
    )
    const continuousRange = ref({ min: 0, max: 100 })
    const discreteSteps = computed(() => {
      const survey = currentSurvey.value as SurveySchema
      return survey.scale.steps
    })

    // const rationale = ref<string>(props.response?.rationale)
    const rationale = ref<string>(props.response?.rationale)
    const aggRationale = computed(() => props.response?.aggRationale)
    const prob = ref<number | undefined>(props.response?.value)
    const probT = ref<number | undefined>(undefined)
    const aggProb = computed(() => props.response?.aggValue)
    const critical = ref<boolean>(props.response?.ext?.critical)
    const aggCritical = computed(() => props.response?.aggExt?.critical)
    const isDeterministic = computed(() => props.variable?.isDeterministic())

    const {
      cellBgStyle,
      emitProbChange,
      onToggleCritical,
      onRationaleChange,
      responses,
      rowItem
    } = useProb(props, emit, prob, critical, rationale, true)

    const data = computed(() => responses.value.map((response: any) => response.value))
    const showBoxPlot = computed(() => responses.value.length >= 5)
    const showLessPlot = computed(() => responses.value.length < 5 && responses.value.length > 0)

    /**
     * Sync response
     */
    const updateValuesFromProps = () => {
      if (props.response?.value !== prob.value || props.response?.value !== probT.value) {
        prob.value = props.response?.value
        probT.value = props.response?.value
      }
      if (props.response?.rationale !== rationale.value) {
        const r = props.response?.rationale || ''
        rationale.value = props.anonymous ? anonymizeRationale(r) : r
      }
      if (props.response?.ext?.critical !== critical.value) {
        critical.value = props.response?.ext?.critical
      }
    }

    // When first load component, update init value
    onMounted(() => {
      updateValuesFromProps()
    })

    watch(
      () => props.response,
      () => {
        updateValuesFromProps()
      }
    )

    const fixValues = (v: any) => {
      if (isDeterministic.value) {
        if (v !== 0 && v !== 100) {
          message.info('This is a deterministic node that requires either 0 or 100 value')
        }
        if (typeof v != 'undefined') {
          if (v < 50) {
            prob.value = 0
          } else {
            prob.value = 100
          }
        }
      } else {
        if (typeof v != 'undefined') {
          if (v < 0) {
            prob.value = 0
          } else if (v > 100) {
            prob.value = 100
          }
        }
      }
    }

    const change = () => {
      prob.value = probT.value
      fixValues(prob.value)
      probT.value = prob.value
      emitProbChange()
    }

    const changeSecondary = (value: number) => {
      prob.value = value
      fixValues(prob.value)
      probT.value = prob.value
      emitProbChange()
    }

    watch(
      () => props.anonymous,
      () => {
        const r = props.response?.rationale || ''
        rationale.value = props.anonymous ? anonymizeRationale(r) : r
      }
    )

    // watch(prob, (v) => {
    //   fixValues(v)
    //   emitProbChange()
    // })

    /**
     * Prob manual setter and getter
     */
    const getProb = () => {
      return prob.value
    }

    const setProb = (value: number) => {
      prob.value = value
      probT.value = value
      emitProbChange(true)
    }

    const onOverride = (value: number) => {
      probT.value = value
      change()
    }

    return {
      changeSecondary,
      probT,
      DISC_EVENTS,
      CONT_EVENTS,
      continuousRange,
      discreteSteps,
      currentSurvey,
      showBoxPlot,
      data,
      getProb,
      setProb,
      aggRationale,
      anonymizeRationale,
      onRationaleChange,
      isDeterministic,
      aggCritical,
      dotStyle,
      dotAggStyle,
      rationale,
      aggProb,
      cellBgStyle,
      critical,
      criticalStyle,
      cx,
      onToggleCritical,
      prob,
      probStyle,
      responses,
      onOverride,
      rowItem,
      OVERRIDE,
      showLessPlot,
      change
    }
  }
})
</script>

<style lang="stylus">

.sz-breakdown
  border-top: 1px solid #d0d0d0
  margin-top: 4px;
  padding-top: 3px;
  max-height: 400px
  overflow-y: auto
  .sz-breakdown-item
    padding: 4px 0
    font-size: 10px
    border-bottom: 1px solid #f0f0f0
    span
      vertical-align: middle
    .value
      font-weight: bold
    .critical
      color: #ff3300
      margin: 0px 4px
      svg
        margin-top: 5px
        width: 10px
        height: 10px
    .rationale
      color: #666666
</style>
