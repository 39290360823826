<template>
  <div v-if="combination" :class="cx('sz-input-prob', probStyle)">
    <a-input-number
      v-model:value="probT"
      :disabled="disabled"
      :tabindex="tabIndex"
      :style="cellBgStyle"
      class="sz-input-prob-number"
      :min="0"
      :max="100"
      @change="change"
    />
    <a-popover trigger="hover">
      <template #content>
        <Discrete
          v-if="discrete"
          v-model="probT"
          :config="discreteSteps"
          :variable="variable"
          :disabled="disabled"
          :is-deterministic="isDeterministic"
          @[DISC_EVENTS.USER_CHANGE]="changeSecondary"
        />
        <Continuous
          v-else
          v-model="probT"
          :config="continuousRange"
          :variable="variable"
          :disabled="disabled"
          :is-deterministic="isDeterministic"
          @[CONT_EVENTS.USER_CHANGE]="changeSecondary"
        />
        <a-textarea
          v-model:value="rationale"
          placeholder="Rationale"
          :rows="2"
          :disabled="disabled"
          @change="onRationaleChange"
        ></a-textarea>
      </template>
      <div :class="dotStyle" />
    </a-popover>
    <div
      v-if="allowCritical"
      :class="criticalStyle"
      :title="critical ? 'Critical' : 'Non-critical'"
      @click="onToggleCritical"
    >
      <Pinned v-if="critical" class="critical-on" />
      <Pin v-else class="critical-off" />
    </div>
  </div>
</template>

<script lang="ts">
import { cx } from '@emotion/css'
import { Pin, Pinned } from '@vicons/tabler'
import { message } from 'ant-design-vue'
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'

import Continuous, { EVENTS as CONT_EVENTS } from '@/components/input/Continuous.vue'
import Discrete, { EVENTS as DISC_EVENTS } from '@/components/input/Discrete.vue'
import { ModuleNames } from '@/constants/vuex'
import { Variable } from '@/libs/bayes'
import { useStore } from '@/store'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { SurveySchema } from '@/types'

import { EVENTS, useProb } from './common'
import { criticalStyle, dotStyle, probStyle } from './styles'

export default defineComponent({
  components: {
    Continuous,
    Discrete,
    Pin,
    Pinned
  },
  props: {
    allowCritical: { type: Boolean, default: false },
    colIndex: { type: Number, default: undefined },
    tabIndex: { type: Number, default: undefined },
    combination: { type: Object, required: true },
    combKey: { type: String, required: true },
    disabled: { type: Boolean, required: true },
    discrete: { type: Boolean, default: false },
    response: { type: Object, default: undefined },
    rowId: { type: String, default: undefined },
    rowIndex: { type: Number, default: undefined },
    variable: { type: Object as PropType<Variable>, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const store = useStore()

    const currentSurvey = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.CURRENT_SURVEY] || {}
    )
    const continuousRange = ref({ min: 0, max: 100 })
    const discreteSteps = computed(() => {
      const survey = currentSurvey.value as SurveySchema
      return survey.scale.steps
    })

    const prob = ref<number | undefined>(undefined)
    const probT = ref<number | undefined>(undefined)
    const rationale = ref<string>('')
    const critical = ref<boolean>(false)
    const isDeterministic = computed(() => props.variable?.isDeterministic())

    const { cellBgStyle, emitProbChange, onRationaleChange, onToggleCritical } = useProb(
      props,
      emit,
      prob,
      critical,
      rationale,
      false
    )

    /**
     * Sync response
     */
    const updateValuesFromProps = () => {
      if (props.response?.value !== prob.value || props.response?.value !== probT.value) {
        prob.value = props.response?.value
        probT.value = props.response?.value
      }
      if (props.response?.rationale !== rationale.value) {
        rationale.value = props.response?.rationale || ''
      }
      if (props.response?.ext?.critical !== critical.value) {
        critical.value = props.response?.ext?.critical
      }
    }

    // When first load component, update init value
    onMounted(() => {
      updateValuesFromProps()
    })

    watch(
      () => props.response,
      () => {
        updateValuesFromProps()
      }
    )

    const fixValues = (v: any) => {
      if (isDeterministic.value) {
        if (v !== 0 && v !== 100) {
          message.info('This is a deterministic node that requires either 0 or 100 value')
        }
        if (typeof v != 'undefined') {
          if (v < 50) {
            prob.value = 0
          } else {
            prob.value = 100
          }
        }
      } else {
        if (typeof v != 'undefined') {
          if (v < 0) {
            prob.value = 0
          } else if (v > 100) {
            prob.value = 100
          }
        }
      }
    }

    const change = () => {
      prob.value = probT.value
      fixValues(prob.value)
      probT.value = prob.value
      emitProbChange()
    }

    const changeSecondary = (value: number) => {
      prob.value = value
      fixValues(prob.value)
      probT.value = prob.value
      emitProbChange()
    }

    /**
     * Prob manual setter and getter
     */
    const getProb = () => {
      return prob.value
    }

    const setProb = (value: number) => {
      prob.value = value
      probT.value = value
      emitProbChange(true)
    }

    return {
      change,
      changeSecondary,
      cellBgStyle,
      continuousRange,
      critical,
      criticalStyle,
      cx,
      discreteSteps,
      dotStyle,
      getProb,
      onRationaleChange,
      onToggleCritical,
      prob,
      probStyle,
      rationale,
      isDeterministic,
      setProb,
      DISC_EVENTS,
      CONT_EVENTS,
      probT
    }
  }
})
</script>
