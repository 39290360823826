import { computed, Ref } from 'vue'

import { snapper } from '@/libs/common'

export const CHART_DATA_MARGIN = 0.2

export default function useChartRange(min: Ref<number>, max: Ref<number>): any {
  const range = computed(() => max.value - min.value)
  const step = computed(() => Math.pow(10, Math.round(Math.log10(range.value)) - 1))
  const gap = computed(() => CHART_DATA_MARGIN * range.value)
  const minBound = computed(() => snapper(min.value - gap.value, step.value))
  const maxBound = computed(() => snapper(max.value + gap.value, step.value))
  return {
    range,
    gap,
    step,
    minBound,
    maxBound
  }
}
