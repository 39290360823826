
import { defineComponent, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import StateSymbol from '@/components/symbols/StateSymbol.vue'
import { EMIT_EVENTS } from '@/constants/emits'

export default defineComponent({
  components: { draggable, StateSymbol },
  props: {
    variable: { type: Object, required: true },
    showAux: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false }
  },
  emits: [EMIT_EVENTS.VARIABLE.CHANGE],
  setup(props, { emit }) {
    const drag = ref(false)
    const states = ref(props.variable.states || [])

    /**
     * Handle state change
     */
    const statesChange = () => {
      if (props.readOnly) {
        return
      }
      props.variable.setStates(states.value)
      states.value = props.variable.states
      emit(EMIT_EVENTS.VARIABLE.CHANGE, props.variable)
    }

    watch(
      () => props.variable,
      () => {
        if (props.variable) {
          states.value = props.variable.states
        }
      }
    )
    return { drag, states, statesChange }
  }
})
