import { ActionTree } from 'vuex'

import {
  createAllocation,
  deleteAllocation,
  getAllocation,
  getAllocations,
  updateAllocation
} from '@/services/api/allocation'
import { AllocationActionEnum } from '@/store/enums/actions/allocation'
import { AllocationMutationEnum } from '@/store/enums/mutations/allocation'
import type { RootState } from '@/store/types'
import { AllocationAction } from '@/store/types/actions/allocation'
import { AllocationState } from '@/store/types/states/allocation'
import { Workspace } from '@/types'
import { AllocationList } from '@/types/api/allocation'
import { Allocation } from '@/types/database/allocation'

const actions: ActionTree<AllocationState, RootState> & AllocationAction = {
  // Create allocation
  async [AllocationActionEnum.CREATE_ALLOCATION]({ commit }, { allocation, workspaceId }) {
    commit(AllocationMutationEnum.CREATE_ALLOCATION, undefined)
    createAllocation({ workspaceId, allocation })
      .then((res: Allocation) => {
        commit(AllocationMutationEnum.CREATE_ALLOCATION_FULFILLED, res)
      })
      .catch((error: Error) => {
        commit(AllocationMutationEnum.CREATE_ALLOCATION_REJECTED, error)
      })
  },
  // Get allocations
  async [AllocationActionEnum.GET_ALLOCATIONS]({ commit }, workspaceId: Workspace['id']) {
    return new Promise((resolve, reject) => {
      commit(AllocationMutationEnum.GET_ALLOCATIONS, undefined)
      getAllocations(workspaceId)
        .then((res: AllocationList) => {
          commit(AllocationMutationEnum.GET_ALLOCATIONS_FULFILLED, res)
          resolve(res)
        })
        .catch((error: Error) => {
          commit(AllocationMutationEnum.GET_ALLOCATIONS_REJECTED, error)
          reject(error)
        })
    })
  },
  // Get allocation
  async [AllocationActionEnum.GET_ALLOCATION]({ commit }, id: Allocation['id']) {
    commit(AllocationMutationEnum.GET_ALLOCATION, undefined)
    getAllocation(id)
      .then((res: Allocation) => {
        commit(AllocationMutationEnum.GET_ALLOCATION_FULFILLED, res)
      })
      .catch((error: Error) => {
        commit(AllocationMutationEnum.GET_ALLOCATION_REJECTED, error)
      })
  },
  // Update allocation
  async [AllocationActionEnum.UPDATE_ALLOCATION]({ commit }, { id, allocation }) {
    commit(AllocationMutationEnum.UPDATE_ALLOCATION, undefined)
    updateAllocation({ id, allocation })
      .then((res: Allocation) => {
        commit(AllocationMutationEnum.UPDATE_ALLOCATION_FULFILLED, res)
      })
      .catch((error: Error) => {
        commit(AllocationMutationEnum.UPDATE_ALLOCATION_REJECTED, error)
      })
  },
  // Delete allocation
  async [AllocationActionEnum.DELETE_ALLOCATION]({ commit }, id) {
    commit(AllocationMutationEnum.DELETE_ALLOCATION, undefined)
    deleteAllocation(id)
      .then(() => {
        commit(AllocationMutationEnum.DELETE_ALLOCATION_FULFILLED, id)
      })
      .catch((error: Error) => {
        commit(AllocationMutationEnum.DELETE_ALLOCATION_REJECTED, error)
      })
  }
}

export { actions }
