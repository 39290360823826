import { render } from "./WorkspaceInfo.vue?vue&type=template&id=1130e1db"
import script from "./WorkspaceInfo.vue?vue&type=script&lang=ts"
export * from "./WorkspaceInfo.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1130e1db"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1130e1db', script)) {
    api.reload('1130e1db', script)
  }
  
  module.hot.accept("./WorkspaceInfo.vue?vue&type=template&id=1130e1db", () => {
    api.rerender('1130e1db', render)
  })

}

script.__file = "src/components/analysis/common/workspace-info/WorkspaceInfo.vue"

export default script