import { MutationTree } from 'vuex'

import { SurveySummaryMutationEnum } from '@/store/enums/mutations/surveySummary'
import { SurveySummaryStateEnum } from '@/store/enums/states/surveySummary'
import { SurveySummaryMutation } from '@/store/types/mutations/surveySummary'
import { SurveySummaryState } from '@/store/types/states/surveySummary'
import { CPTSummarySchema } from '@/types'

/**
 * Mutation function
 */
export const mutations: MutationTree<SurveySummaryState> & SurveySummaryMutation = {
  // Get SurveySummary
  [SurveySummaryMutationEnum.GET_SURVEY_SUMMARIES](state: SurveySummaryState) {
    state[SurveySummaryStateEnum.IS_GETTING] = true
  },
  [SurveySummaryMutationEnum.GET_SURVEY_SUMMARIES_FULFILLED](
    state: SurveySummaryState,
    payload: Array<CPTSummarySchema>
  ) {
    state[SurveySummaryStateEnum.IS_GETTING] = false
    state[SurveySummaryStateEnum.SURVEY_SUMMARIES] = payload
  },
  [SurveySummaryMutationEnum.GET_SURVEY_SUMMARIES_REJECTED](
    state: SurveySummaryState,
    error: Error
  ) {
    state[SurveySummaryStateEnum.IS_GETTING] = false
    state[SurveySummaryStateEnum.ERROR] = error
  }
}
