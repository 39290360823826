<template>
  <div class="sz-cain">
    <div class="sz-title">
      <h1>Cain Table</h1>
    </div>
    <VariableContext
      v-if="network"
      :network="network"
      :is-cain="true"
      :variable="selectedVariable"
      :state="selectedState"
      :show-parents="true"
      :show-children="true"
      :current-survey="currentSurvey"
    />
    <div ref="tableWrapper" class="sz-cain-table-wrapper">
      <a-table
        v-if="data.length !== 0"
        :data-source="data"
        :columns="columns"
        :pagination="false"
        class="sz-cain-table"
        :scroll="{ y: scrollY }"
        size="small"
        :style="{ maxWidth: '1500px' }"
      >
        <template #response-index="{ text }">
          <div class="cpt-index">{{ text }}</div>
        </template>
        <template #non-verbose-variable-state="{ text: state }">
          <div class="cpt-state-name__non-verbose" :style="getStateStyle(state.polarity)">
            <span>{{ state.name }}</span>
          </div>
        </template>
        <template #verbose-variable="{ record: { combination } }">
          <div v-for="{ variable } in combination" :key="variable.id" class="cpt-variable-name">
            {{ variable.name }}
          </div>
        </template>
        <template #verbose-state="{ record: { combination } }">
          <div v-for="{ state } in combination" :key="state.id" class="cpt-state-name">
            <a-tag :color="state.isPositive() ? 'blue' : 'red'">{{ state.name }}</a-tag>
          </div>
        </template>
        <template #response="{ text, index }">
          <ProbSelector
            v-if="text?.combination && !isAnalytics && isStochastics"
            :ref="
              (el) => {
                if (el) {
                  probs[text?.combKey] = el
                }
              }
            "
            :key="index"
            :comb-key="text?.combKey"
            :combination="text?.combination"
            :variable="selectedVariable"
            :row-index="index"
            :row-id="text?.rowId"
            :discrete="false"
            :allow-critical="false"
            :response="text?.response"
            :disabled="text?.disabled || !editable"
            @[PROB_EVENTS.ON_PROB_CHANGE]="onResponseChange"
          />
          <Prob
            v-if="text?.combination && !isAnalytics && !isStochastics"
            :ref="
              (el) => {
                if (el) {
                  probs[text?.combKey] = el
                }
              }
            "
            :key="index"
            :comb-key="text?.combKey"
            :combination="text?.combination"
            :variable="selectedVariable"
            :row-index="index"
            :row-id="text?.rowId"
            :discrete="false"
            :allow-critical="false"
            :response="text?.response"
            :disabled="text?.disabled || !editable"
            @[PROB_EVENTS.ON_PROB_CHANGE]="onResponseChange"
          />
          <ProbAgg
            v-if="text?.combination && isAnalytics && !isStochastics"
            :ref="
              (el) => {
                if (el) {
                  probs[text?.combKey] = el
                }
              }
            "
            :key="index"
            :comb-key="text?.combKey"
            :combination="text?.combination"
            :variable="selectedVariable"
            :row-index="index"
            :row-id="text?.rowId"
            :discrete="false"
            :allow-critical="false"
            :response="text?.response"
            :analytics="analytics"
            :anonymous="isAnonymous"
            :user-map="userMap"
            :disabled="text?.disabled || !editable"
            @[PROB_EVENTS.ON_PROB_CHANGE]="onResponseChange"
          />
          <!-- <CainRow
            :key="index"
            :combination="record.combination"
            :variable="selectedVariable"
            :index="index"
            :row-id="record.rowId"
            :comb-key="record.combKey"
            :discrete="discrete"
            :response="record.response"
            :editable="editable && !isSaveButtonSpinning"
            v-on="{
              [CAIN_EVENTS.ON_ROW_RESPONSE_CHANGE]: onResponseChange
              // [CAIN_EVENTS.ON_ROW_HEIGHT_CHANGE]: onRowHeightChange
            }"
          /> -->
        </template>
      </a-table>
    </div>
    <div v-if="!noSave && combinations.length > 0" class="sz-ace-footer">
      <a-button
        type="primary"
        size="small"
        :disabled="!isStochastics && (noSave || !isValid)"
        :loading="isSaveButtonSpinning"
        @click="onResponsesChange"
      >
        Save
      </a-button>
    </div>
  </div>
  <a-modal
    title="CPT Preview"
    :visible="isPreviewVisible"
    class="sz-cpt-preview-modal"
    width="80vw"
    :centered="true"
    :body-style="{ height: '80vh', position: 'relative' }"
    @cancel="isPreviewVisible = false"
  >
    <template #footer>
      <a-button key="cancel" @click="isPreviewVisible = false">OK</a-button>
    </template>
    <CPTPreview
      v-if="isPreviewVisible"
      :cpt="cpt"
      :cpt-values="cptValues"
      :selected-variable="selectedVariable"
    />
  </a-modal>
</template>

<script lang="ts">
import { isEmpty } from 'lodash-es'
import { computed, defineComponent, onBeforeUpdate, PropType, Ref, ref, toRefs, unref } from 'vue'

import useAntTableScroll from '@/components/composables/antTableScroll'
import useResponses from '@/components/composables/responses'
import useTable from '@/components/composables/table'
import CPTPreview from '@/components/cpt/CPTPreview.vue'
import { EVENTS as PROB_EVENTS, redistributeProbs } from '@/components/input/common'
import Prob from '@/components/input/Prob.vue'
import ProbAgg from '@/components/input/ProbAgg.vue'
import ProbSelector from '@/components/input/ProbSelector.vue'
import { RowData, TABLE_EVENTS } from '@/components/method-common/Table'
import VariableContext from '@/components/survey/VariableContext.vue'
import { CombinationRow, Condition, CPT, Network, Variable } from '@/libs/bayes'
import { ResponseUnit } from '@/libs/bayes/CPTResponse'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { getStateStyle } from '@/libs/utils'
import { ResponseSchema, SurveySchema } from '@/types'

// import CainRow, { EVENTS as CAIN_EVENTS } from './CainRow.vue'

const COLUMNS = {
  RESPONSE_INDEX: {
    key: 'responseIndex',
    dataIndex: 'responseIndex',
    title: 'No',
    fixed: 'left',
    align: 'center',
    width: 40,
    slots: {
      customRender: 'response-index'
    }
  },
  RESPONSE: {
    key: 'response',
    dataIndex: 'responseObject',
    width: 120,
    slots: {
      customRender: 'response'
    }
  },
  VERBOSE: {
    VARIABLE: {
      key: 'variable',
      title: 'Variable',
      align: 'center',
      fixed: 'left',
      // width: getColumnWidth('Varaible'),
      slots: {
        customRender: 'verbose-variable'
      }
    },
    STATE: {
      key: 'state',
      title: 'State',
      align: 'center',
      fixed: 'left',
      // width: getColumnWidth('State'),
      slots: {
        customRender: 'verbose-state'
      }
    }
  }
}

export default defineComponent({
  components: {
    VariableContext,
    // CainRow,
    CPTPreview,
    Prob,
    ProbAgg,
    ProbSelector
  },
  props: {
    network: { type: Network, required: true },
    currentSurvey: { type: Object as PropType<SurveySchema>, required: true },
    cpt: { type: Object as PropType<CPT>, required: true },
    selectedVariable: { type: Object as PropType<Variable>, required: true },
    responses: { type: Array as PropType<Array<ResponseSchema>>, default: () => [] },
    editable: { type: Boolean, default: false },
    noSave: { type: Boolean, default: false },
    isSaveButtonSpinning: { type: Boolean },
    allowPartialSave: { type: Boolean, default: false },
    discrete: { type: Boolean, required: true },
    verboseState: { type: Boolean, default: false },
    analytics: { type: Object, default: undefined },
    userMap: { type: Object, default: undefined },
    isAnonymous: { type: Boolean },
    isStochastics: { type: Boolean, default: false }
  },
  emits: [...Object.values(TABLE_EVENTS)],
  setup(props, { emit }) {
    const isAnalytics = computed(() => !isEmpty(props.analytics))
    const isPreviewVisible = ref(false)
    const probs = ref<Record<string, any>>({})
    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      probs.value = {}
    })

    const { responses, cpt, network, selectedVariable } = toRefs(props)

    const {
      combinations,
      elicitedRows,
      dependents,
      independents,
      dependentMap,
      selectedState
    } = useTable(network, selectedVariable, cpt)

    const cptableWrapper = ref(null)
    const cptValues: Ref<Array<Array<number>> | null> = ref([[]])

    const {
      tableRows,
      isValid,
      responseMap,
      // onResponseChange,
      validMap,
      genResponsesPayload
    } = useResponses(responses, combinations, elicitedRows, cpt, dependentMap)
    const { tableWrapper, scrollY } = useAntTableScroll(dependents, independents, 45, 72, false)

    const columns = computed(() => {
      // const variableNameWidth = Math.max(...data.value.map((row: RowData) => row.variableNameWidth))
      // if (props.verboseState) {
      //   // const stateNameWidth = Math.max(...data.value.map((row: RowData) => row.stateNameWidth))
      //   return [
      //     COLUMNS.RESPONSE_INDEX,
      //     {
      //       ...COLUMNS.VERBOSE.VARIABLE
      //       // width:
      //       //   COLUMNS.VERBOSE.VARIABLE.width < variableNameWidth
      //       //     ? variableNameWidth
      //       //     : COLUMNS.VERBOSE.VARIABLE.width
      //     },
      //     {
      //       ...COLUMNS.VERBOSE.STATE
      //       // width:
      //       //   COLUMNS.VERBOSE.STATE.width < stateNameWidth
      //       //     ? stateNameWidth
      //       //     : COLUMNS.VERBOSE.STATE.width
      //     },
      //     COLUMNS.RESPONSE
      //   ]
      // }

      // Columns for individual states of the selected variables
      const colResponses = selectedVariable.value.getAllStates().map(({ state }: Condition) => {
        return {
          title: state.name,
          key: state.id,
          dataIndex: state.id,
          polarity: state.polarity,
          width: 80,
          slots: {
            customRender: 'response'
          },
          customHeaderCell: (col: any) => {
            return {
              style: {
                ...getStateStyle(col.polarity)
              }
            }
          }
        }
      })
      return [
        COLUMNS.RESPONSE_INDEX,
        ...[...dependents.value, ...independents.value].map((variable: any) => {
          return {
            title: variable.name,
            dataIndex: variable.id,
            key: variable.id,
            // width: Math.max(variableNameWidth, getColumnWidth(variable.name)),
            width: 120,
            align: 'center',
            fixed: 'left',
            slots: {
              customRender: 'non-verbose-variable-state'
            }
          }
        }),
        ...colResponses
      ]
    })

    const data = computed(() => {
      if (!tableRows?.value || !tableRows?.value.length) {
        return []
      }
      const records = tableRows.value.map((row: CombinationRow, idx: number) => {
        const data: RowData = {}
        const dataRowKey = row.key
        const combination = row.combination
        data.combination = unref(combination)
        combination.forEach(({ variable, state }) => {
          data[variable.id] = state
        })
        data[COLUMNS.RESPONSE_INDEX.dataIndex] = idx
        data.key = idx
        data.isLikelihood = false
        const allVarStates = selectedVariable.value.getAllStates()
        allVarStates.map(({ state, variable }: Condition, index) => {
          const pair = variable && state ? `-${variable.id}+${state.id}` : ''
          const key = `${dataRowKey}${pair}`
          const val = responseMap.value?.get(key)
          if (val) {
            val.disabled = index === 0 // disabled the negative state for auto calculate
            data[state.id] = val
          }
        })

        // let variableNameWidth = 0
        // let stateNameWidth = 0
        // data.combination.map(({ variable, state }: Condition) => {
        //   const variableWidth = getColumnWidth(variable.name)
        //   const stateWidth = getColumnWidth(state.name)
        //   if (variableWidth > variableNameWidth) {
        //     variableNameWidth = variableWidth
        //   }
        //   if (stateWidth > stateNameWidth) {
        //     stateNameWidth = stateWidth
        //   }
        // })
        // data.variableNameWidth = variableNameWidth
        // data.stateNameWidth = stateNameWidth
        // data.rowHeight = rowsHeight[idx.toString()] || 60
        return data
      })
      return records
    })

    const onResponsesChange = () => {
      const payload = genResponsesPayload(props.allowPartialSave)
      if (payload) {
        emit(TABLE_EVENTS.ON_RESPONSES_CHANGE, payload)
      }
    }

    const onResponseChange = (
      {
        response,
        combKey,
        valid,
        rowIndex
      }: {
        response: ResponseSchema
        combKey: string
        valid: boolean
        rowIndex: number
      },
      noDist = false
    ) => {
      validMap.set(combKey, valid)
      const responseUnit = responseMap.value.get(combKey)
      if (!responseUnit) {
        return
      }
      const rowData = data.value[rowIndex]
      try {
        const stateLength = selectedVariable.value?.getAllStates().length
        const colLength = columns.value?.length
        const isDeterministic = selectedVariable.value?.isDeterministic()
        if (!noDist) {
          redistributeProbs(
            rowData,
            columns.value.slice(colLength - stateLength),
            probs.value,
            combKey,
            isDeterministic
          )
        }
      } catch (e) {
        console.log(e)
        // pass
      }
      const comboRow: ResponseUnit = { ...responseUnit }
      comboRow.response = Object.assign({ ...responseUnit.response }, response)
      responseMap.value.set(combKey, comboRow)
    }

    /*
    const preview = (depCombKey: string) => {
      const ace = props.cpt as CainCPT
      const values = ace.calculateRawCPT(depCombKey, responseMap.value)
      if (values) {
        cptValues.value = values
        isPreviewVisible.value = true
      }
    }
    */

    return {
      cptValues,
      VariableRelation,
      columns,
      combinations,
      elicitedRows,
      cptableWrapper,
      data,
      dependents,
      independents,
      isValid,
      onResponsesChange,
      onResponseChange,
      selectedState,
      responseMap,
      validMap,
      scrollY,
      probs,
      tableWrapper,
      tableRows,
      isAnalytics,
      PROB_EVENTS,
      isPreviewVisible,
      getStateStyle
    }
  }
})
</script>

<style lang="stylus">
@import './styles/index.styl';
</style>
