/* eslint-disable @typescript-eslint/no-unused-vars */
import { first } from 'lodash-es'
import { indexBy, last, prop, repeat } from 'ramda'
import { computed, Ref, ref } from 'vue'
import { useRequest } from 'vue-request'

import {
  AnalysisTask,
  ChartData,
  DEFAULT_UTILITY_VECTOR,
  InputNode,
  InputType,
  OutputNode,
  Selection
} from '@/components/analysis/libs/common'
import { ModuleNames } from '@/constants/vuex'
import { Network } from '@/libs/bayes'
import { logger } from '@/libs/logger'
import { objectId } from '@/libs/utils'
import {
  createSession,
  getSessionResults,
  performAnalysis,
  performAnalysis2,
  runSession,
  setSessionParams
} from '@/services/api/analysis'
import { createJob, getJobs, updateJob } from '@/services/api/job'
import { AuthStateEnum } from '@/store/enums/states'
import { Store } from '@/store/types'
import {
  Job,
  JobConfig,
  JobInput,
  JobInputNode,
  JobOutput,
  JobOutputNode,
  JobParams,
  JobTask,
  JobType
} from '@/types/database/job'

const { AUTH } = ModuleNames

export const OMITTED = 'OMIT'
export const ANALYSIS_API: 'v1' | 'v2' | 'v3' = 'v2' // v0, v1 and v2

export const ROW_TYPE = {
  LABEL: 'LABEL',
  ACTIONS: 'ACTIONS',
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT'
}

const makeDefaultUtilityVector = (stateLength: number) => {
  const vector = repeat(0, stateLength)
  vector[stateLength - 1] = 100
  return vector
}

const normalizeOutcomes = (outcomes: number[], utilityVector: number[]) => {
  return outcomes.reduce((acc, outcome, index) => {
    return acc + outcome * utilityVector[index]
  }, 0)
}

export default function useWhatIfAnalysis(
  workspaceId: string,
  networks: Ref<Network[]>,
  store: Store,
  refreshCallback: () => void,
  successCallback: () => void = () => undefined
): any {
  const tasks: Ref<AnalysisTask[]> = ref([])
  const selections: Ref<Selection[]> = ref([])
  const inputNodeKeys: Ref<string[]> = ref([])
  const outputNodeKeys: Ref<string[]> = ref([])
  const whatIfRows: Ref<any[]> = ref([])
  const chartNodeIds: Ref<any[]> = ref([])
  const chartData: Ref<ChartData> = ref([])
  const resultMap: Ref<Record<string, any>> = ref({})
  const taskMap = computed(() => indexBy(prop('id'), tasks.value))
  const networkMap = computed(() => indexBy(prop('id'), networks.value))
  const selectionMap = computed(() => indexBy(prop('key'), selections.value))
  const whatIfJob: Ref<Job | undefined> = ref()
  const currentUser = computed(() => store.state[AUTH][AuthStateEnum.USER])
  const isExecuting: Ref<boolean> = ref(false)
  const commonNodeKeys: Ref<string[]> = ref([])

  const { run: runUpdatingJob, loading: isUpdatingJob } = useRequest(updateJob, {
    manual: true,
    onSuccess: successCallback
  })

  const { run: runCreatingJob, loading: isCreatingJob } = useRequest(createJob, {
    manual: true,
    onSuccess: successCallback
  })

  const loadWhatIfJob = async () => {
    logger.info('currentUser', currentUser.value?.username)
    if (!currentUser.value) {
      return
    }
    let job: Job | undefined
    const jobs = await getJobs(workspaceId)
    if (jobs.length) {
      const jobs_ = jobs.filter(
        (job: Job) => job.type === JobType.WHATIF && job.userId === currentUser.value?.id
      )
      if (jobs_) {
        job = first(jobs_)
        whatIfJob.value = job
      }
    }
    if (!job) {
      const params: JobParams = {
        networkIds: [],
        inputs: [],
        outputs: []
      }
      whatIfJob.value = {
        type: JobType.WHATIF,
        workspaceId: workspaceId,
        userId: currentUser.value?.id,
        params,
        results: []
      }
    }
  }

  const prefillSelectionsFromJob = () => {
    if (!whatIfJob.value) {
      return
    }
    const job: Job = whatIfJob.value
    job.params?.inputs?.forEach((input: JobInput) => {
      selectionMap.value[input.key].isInput = true
    })
    job.params?.outputs?.forEach((output: JobOutput) => {
      selectionMap.value[output.key].isOutput = true
      selectionMap.value[output.key].utilityVector = output.utilityVector
    })
  }

  const prefillTasksFromJob = () => {
    if (!whatIfJob.value) {
      return
    }
    const job: Job = whatIfJob.value
    const _tasks: AnalysisTask[] = []
    job.params?.tasks?.forEach((task: JobTask, index) => {
      const networkId = task.networkId
      const network = networkMap.value[networkId]
      const inputNodes: InputNode[] = []
      const outputNodes: OutputNode[] = []
      const inputNodeKeys_: string[] = []
      const outputNodeKeys_: string[] = []
      task.inputNodes?.forEach(({ key, state, values }: JobInputNode) => {
        inputNodes.push({
          key,
          state,
          values,
          type: state ? InputType.STATE : InputType.PROBABILITY
        })
        inputNodeKeys_.push(key)
      })
      task.outputNodes?.forEach(({ key, utilityVector, outcomes = [], value }: JobOutputNode) => {
        outputNodes.push({
          key,
          utilityVector,
          outcomes,
          value: value || 0
        })
        outputNodeKeys_.push(key)
      })
      inputNodeKeys.value = inputNodeKeys_
      outputNodeKeys.value = outputNodeKeys_
      const _task: AnalysisTask = {
        id: task.id,
        name: network.name,
        networkId,
        networkIndex: index,
        status: task.status,
        outputNodes,
        inputNodes
      }
      _tasks.push(_task)
    })
    tasks.value = _tasks
  }

  const saveTasksToJob = async () => {
    if (!whatIfJob.value) {
      return
    }
    const networkIds: string[] = []
    const job: Job = { ...whatIfJob.value }
    updateTasksFromWhatIfRows()
    const _tasks: JobTask[] = []
    tasks.value?.forEach(({ id, networkId, inputNodes, outputNodes }) => {
      _tasks.push({
        id,
        jobId: 'x',
        type: JobType.WHATIF,
        status: 'PENDING',
        networkId,
        inputNodes,
        outputNodes
      })
      if (!networkIds.includes(networkId)) {
        networkIds.push(networkId)
      }
    })
    const inputs: JobInput[] = []
    const outputs: JobOutput[] = []
    selections.value?.forEach((node: Selection) => {
      const { isInput, isOutput, key } = node
      if (isInput) {
        inputs.push({
          key
        })
      } else if (isOutput) {
        outputs.push({
          key,
          utilityVector: node.utilityVector
        })
      }
    })
    if (job.config) {
      job.config.networkIds = networkIds
      job.config.inputs = inputs
      job.config.outputs = outputs
      job.config.tasks = _tasks
    }
    if (job.id) {
      runUpdatingJob(workspaceId, job.id, job)
    } else {
      job.id = objectId()
      runCreatingJob(workspaceId, job)
    }
  }

  const updateTasksFromWhatIfRows = () => {
    tasks.value?.forEach((task) => {
      const { id: taskId } = task
      task.status = 'PENDING'
      const outputNodes: OutputNode[] = []
      const inputNodes: InputNode[] = []
      whatIfRows.value?.forEach((row: any) => {
        const { type, key } = row
        if (!row[taskId]) {
          return
        }
        if (type === ROW_TYPE.OUTPUT) {
          const { value, outcomes, utilityVector } = row[taskId]
          outputNodes.push({
            key,
            utilityVector: utilityVector || selectionMap.value?.[key].utilityVector,
            outcomes,
            value
          })
        } else if (type === ROW_TYPE.INPUT) {
          const { value, values, inputType } = row[taskId]
          inputNodes.push({
            key,
            state: value,
            values: values,
            type: inputType
          })
        }
      })
      task.inputNodes = inputNodes
      task.outputNodes = outputNodes
    })
  }

  const updateSelections = () => {
    const _selections: Selection[] = []
    const keyMap: Record<string, any> = {}
    networks.value?.forEach((network: Network) => {
      network.nodes?.forEach((node: any) => {
        const key: string = node.shortName
        if (!(key in keyMap)) {
          const variable = network.variableMapByKey[key]
          if (!variable) {
            return
          }
          const isDeterministic = variable.isDeterministic()
          const numStates = variable.getAllStates().length
          const selection = {
            key,
            name: key,
            isDeterministic,
            isInput: false,
            isOutput: false,
            utilityVector: DEFAULT_UTILITY_VECTOR[numStates] || makeDefaultUtilityVector(numStates)
          }
          _selections.push(selection)
          keyMap[key] = selection
        } else {
          commonNodeKeys.value.push(key)
        }
      })
    })
    selections.value = _selections
  }

  const updateInputOutputNodes = () => {
    inputNodeKeys.value = []
    outputNodeKeys.value = []
    selections.value?.forEach((node: Selection) => {
      if (node.isInput) {
        inputNodeKeys.value.push(node.key)
      } else if (node.isOutput) {
        outputNodeKeys.value.push(node.key)
      }
    })
  }

  const initTasks = () => {
    updateInputOutputNodes()
    const _tasks: AnalysisTask[] = []
    networks.value?.map((network: Network, index: number) => {
      const _task: AnalysisTask = Object.assign(
        {
          id: objectId(),
          name: network.name,
          networkId: network.id,
          networkIndex: index,
          status: 'PENDING'
        },
        getInputOuputNodes(network)
      )
      _tasks.push(_task)
    })
    tasks.value = _tasks
  }

  const addTask = (taskId: string, taskIndex: number) => {
    const task = taskMap.value?.[taskId]
    const network = networkMap.value?.[task.networkId]
    const _task: AnalysisTask = Object.assign(
      {
        name: network.name,
        id: objectId(),
        networkId: network.id,
        status: 'PENDING'
      },
      getInitialInputOuputNodes(network)
    )
    tasks.value?.splice(taskIndex, 0, _task)
  }

  const removeTask = (_taskId: string, taskIndex: number) => {
    tasks.value?.splice(taskIndex, 1)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getInitialInputOuputNodes = (_network: Network) => {
    return {
      inputNodes: [],
      outputNodes: []
    }
  }

  const getInputOuputNodes = (network: Network) => {
    const inputNodes: InputNode[] = []
    const outputNodes: OutputNode[] = []
    selections.value?.forEach((node: Selection) => {
      const { isInput, isOutput, key } = node
      const variable = network.variableMapByKey[key]
      if (!variable) {
        return
      }
      const state = last(variable.getAllStates())?.state.name || ''
      if (isInput) {
        inputNodes.push({
          key,
          state,
          values: [],
          type: variable.isDeterministic() ? InputType.STATE : InputType.PROBABILITY
        })
      } else if (isOutput) {
        outputNodes.push({
          key,
          utilityVector: node.utilityVector,
          outcomes: [],
          value: 0
        })
      }
    })
    return {
      inputNodes,
      outputNodes
    }
  }

  const updateWhatIfRows = () => {
    const _whatIfRows: any[] = []
    const headerValues = tasks.value?.reduce((acc: any, task: AnalysisTask, index: number) => {
      acc[task.id] = {
        taskIndex: index,
        taskId: task.id
      }
      return acc
    }, {})
    _whatIfRows.push({
      name: '',
      key: 'subheader',
      type: ROW_TYPE.ACTIONS,
      ...headerValues
    })
    _whatIfRows.push({
      name: 'input:',
      key: 'header-input',
      type: ROW_TYPE.LABEL
    })
    inputNodeKeys.value?.forEach((key: string) => {
      const inputValues = tasks.value?.reduce((acc: any, task: AnalysisTask, index: number) => {
        const { id, networkId, inputNodes } = task
        const network = networkMap.value?.[networkId]
        const variable = network.variableMapByKey[key]
        if (!variable || !inputNodes) {
          return acc
        }
        const inputNode = inputNodes.filter(({ key: k }) => k === key)?.[0] || undefined
        const states =
          variable?.getAllStates().map(({ state: { name } }) => ({
            value: name,
            label: name
          })) || []
        const values = repeat(0, states.length)
        values[states.length - 1] = 1
        acc[id] = {
          networkName: network.name,
          taskIndex: index,
          taskId: id,
          value: inputNode && inputNode?.state ? inputNode.state : states?.[0].value,
          values:
            inputNode && inputNode.values && inputNode.values.length ? inputNode.values : values,
          variable,
          states,
          options: [
            {
              value: OMITTED,
              label: OMITTED
            },
            ...states
          ],
          inputType: variable.isDeterministic() ? InputType.STATE : InputType.PROBABILITY
        }
        return acc
      }, {})
      _whatIfRows.push({
        name: key,
        key,
        type: ROW_TYPE.INPUT,
        ...inputValues
      })
    })
    _whatIfRows.push({
      name: 'output:',
      key: 'header-output',
      type: ROW_TYPE.LABEL
    })
    outputNodeKeys.value?.forEach((key: string) => {
      const outputValues = tasks.value?.reduce((acc: any, task: AnalysisTask, index: number) => {
        const { id, networkId, outputNodes } = task
        const network = networkMap.value?.[networkId]
        const variable = network.variableMapByKey[key]
        const outputNode = outputNodes.filter(({ key: k }) => k === key)?.[0] || undefined
        acc[id] = {
          outcomes: outputNode?.outcomes || [],
          utilityVector: outputNode?.utilityVector || [],
          networkName: network.name,
          taskId: task.id,
          taskIndex: index,
          value: 0,
          variable
        }
        return acc
      }, {})
      _whatIfRows.push({
        name: key,
        nodeKey: key,
        key,
        disabled: false,
        type: ROW_TYPE.OUTPUT,
        ...outputValues
      })
    })
    whatIfRows.value = _whatIfRows
  }

  const onMoveUpRow = (record: any, rowIndex: number) => {
    const targetRowIndex = rowIndex - 1
    if (targetRowIndex < 0 || whatIfRows.value[targetRowIndex].type !== record.type) {
      return
    }
    let newWhatIfRows: any[] = []
    newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(0, targetRowIndex))
    newWhatIfRows.push(whatIfRows.value[rowIndex])
    newWhatIfRows.push(whatIfRows.value[targetRowIndex])
    if (rowIndex + 1 < whatIfRows.value.length) {
      newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(rowIndex + 1))
    }
    whatIfRows.value = newWhatIfRows
    updateTasksFromWhatIfRows()
  }

  const onMoveDownRow = (record: any, rowIndex: number) => {
    const targetRowIndex = rowIndex + 1
    if (
      targetRowIndex > whatIfRows.value.length - 1 ||
      whatIfRows.value[targetRowIndex].type !== record.type
    ) {
      return
    }
    let newWhatIfRows: any[] = []
    if (rowIndex > 1) {
      newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(0, rowIndex))
    }
    newWhatIfRows.push(whatIfRows.value[targetRowIndex])
    newWhatIfRows.push(whatIfRows.value[rowIndex])
    if (targetRowIndex + 1 < whatIfRows.value.length) {
      newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(targetRowIndex + 1))
    }
    whatIfRows.value = newWhatIfRows
    updateTasksFromWhatIfRows()
  }

  const updateRowsWithResult = (taskId: string, result: any) => {
    whatIfRows.value?.forEach((row: any) => {
      const { key, type } = row
      if (type === ROW_TYPE.OUTPUT) {
        const data = row[taskId]
        if (result[key]) {
          const outcomes = result[key]
          const utilityVector = selectionMap?.value?.[key]?.utilityVector
          data.outcomes = outcomes
          data.value = Number(normalizeOutcomes(outcomes, utilityVector)).toFixed(2)
        }
      }
    })
  }

  const updateRowsDefaultValues = (taskId: string, result: any) => {
    whatIfRows.value?.forEach((row: any) => {
      const { key, type } = row
      if (type === ROW_TYPE.INPUT) {
        const data = row[taskId]
        if (result[key] && data) {
          const outcomes: number[] = result[key]
          if (outcomes) {
            if (data?.inputType === InputType.PROBABILITY) {
              data.values = outcomes?.map((outcome) => Number(outcome).toFixed(2))
            } else {
              const max = Math.max(...outcomes)
              const index = outcomes.indexOf(max) || 0
              data.value = data.states?.[index].value
            }
          }
        }
      }
    })
  }

  const updateRowsWithUtilityVector = (updatedNodeKey: string) => {
    tasks.value?.forEach((task) => {
      const { id: taskId } = task
      const result = resultMap.value?.[taskId]
      if (!result) {
        return
      }
      whatIfRows.value?.forEach((row: any) => {
        const { key } = row
        if (key === updatedNodeKey) {
          const data = row[taskId]
          if (result[key]) {
            const outcomes = result[key]
            const utilityVector = selectionMap?.value?.[key]?.utilityVector
            data.value = Number(normalizeOutcomes(outcomes, utilityVector) * 100).toFixed(2)
          }
        }
      })
    })
    updateChart()
  }

  const updateChart = () => {
    const _chartData: ChartData = []
    const _chartNodeIds: string[] = []

    tasks.value?.forEach((task, index) => {
      const { id: taskId, status } = task
      const series: number[] = []
      if (status === 'PENDING') {
        return
      }
      whatIfRows.value?.forEach((row: any) => {
        const { key, type } = row
        if (type === ROW_TYPE.OUTPUT && !row.disabled) {
          const data = row[taskId]
          series.push(data.value)
          if (!index) {
            _chartNodeIds.push(key)
          }
        }
      })
      _chartData.push(series)
    })
    chartData.value = _chartData
    chartNodeIds.value = _chartNodeIds
  }

  const executeAnalysis = async () => {
    updateTasksFromWhatIfRows()
    isExecuting.value = true
    // this is temporary
    tasks.value?.forEach(async (task) => {
      const { inputNodes, outputNodes, networkId, id } = task
      const evidences = inputNodes.reduce((acc: any, inputNode) => {
        const network = networkMap.value?.[networkId]
        const variable = network.variableMapByKey[inputNode.key]
        if (!variable) {
          return acc
        }
        const stateLength = variable.getAllStates()?.length
        if (variable.isDeterministic()) {
          if (inputNode.state?.length && inputNode.state !== OMITTED) {
            const values: number[] = []
            variable.getAllStates().forEach(({ state }) => {
              if (state.name === inputNode.state) {
                values.push(1)
              } else {
                values.push(0)
              }
            })
            acc[inputNode.key] = values
          } else if (inputNode.state !== OMITTED) {
            const values = repeat(0, stateLength)
            values[stateLength - 1] = 1
            acc[inputNode.key] = values
          }
        } else {
          acc[inputNode.key] = inputNode.values
        }
        return acc
      }, {})
      const nodeIds = outputNodes.map((outputNode) => outputNode.key)
      const analysisInput = { networkId, evidences, nodeIds }
      let result
      if (ANALYSIS_API === 'v2') {
        result = await performAnalysis2(workspaceId, analysisInput)
      } else if (ANALYSIS_API === 'v1') {
        result = await performAnalysis(workspaceId, analysisInput)
      } else {
        // @todo: test after session fix on the backend
        const session = await createSession(workspaceId)
        const sessionId = session.id as string
        await setSessionParams(workspaceId, sessionId, analysisInput)
        await runSession(workspaceId, sessionId)
        result = await getSessionResults(workspaceId, sessionId, { nodeIds })
      }
      task.status = 'SUCCESS'
      resultMap.value[id] = result
      updateRowsWithResult(id, result)
      if (refreshCallback) {
        refreshCallback()
        updateChart()
      }
    })
    isExecuting.value = false
  }

  const preExecuteAnalysis = async () => {
    updateTasksFromWhatIfRows()
    isExecuting.value = true
    // this is temporary
    tasks.value?.forEach(async (task) => {
      const { inputNodes, outputNodes, networkId, id } = task
      const evidences = {}
      const nodeIds: string[] = inputNodes
        .map((inputNode) => inputNode.key)
        .concat(outputNodes.map((outputNode) => outputNode.key))
      const analysisInput = { networkId, evidences, nodeIds }
      let result
      if (ANALYSIS_API === 'v2') {
        result = await performAnalysis2(workspaceId, analysisInput)
      } else if (ANALYSIS_API === 'v1') {
        result = await performAnalysis(workspaceId, analysisInput)
      } else {
        // @todo: test after session fix on the backend
        const session = await createSession(workspaceId)
        const sessionId = session.id as string
        await setSessionParams(workspaceId, sessionId, analysisInput)
        await runSession(workspaceId, sessionId)
        result = await getSessionResults(workspaceId, sessionId, { nodeIds })
      }
      task.status = 'SUCCESS'
      updateRowsDefaultValues(id, result)
      updateRowsWithResult(id, result)
      if (refreshCallback) {
        refreshCallback()
      }
    })
    isExecuting.value = false
  }

  return {
    loadWhatIfJob,
    prefillSelectionsFromJob,
    prefillTasksFromJob,
    saveTasksToJob,
    chartData,
    chartNodeIds,
    whatIfRows,
    selectionMap,
    ROW_TYPE,
    selections,
    onMoveDownRow,
    onMoveUpRow,
    updateSelections,
    tasks,
    addTask,
    isUpdatingJob,
    isCreatingJob,
    inputNodeKeys,
    outputNodeKeys,
    initTasks,
    updateWhatIfRows,
    updateTasksFromWhatIfRows,
    updateInputOutputNodes,
    getInputOuputNodes,
    executeAnalysis,
    preExecuteAnalysis,
    updateRowsWithUtilityVector,
    updateChart,
    removeTask,
    commonNodeKeys,
    isExecuting
  }
}
