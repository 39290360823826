import { AxiosError } from 'axios'

import axios from '@/axios'
import { ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'
import { EdgeInputSchema, EdgeSchema, NetworkSchema, NodeInputSchema, NodeSchema } from '@/types'

/**
 * Get a node
 * @param id - network uuid
 */
const getNode = async (id: NodeSchema['id']): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.CQRS_NODE({ id })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Get all nodes for a specific workspace
 * @param networkId - Network id
 */
const getNodes = async (networkId: string): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.CQRS_NETWORK({ networkId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Submit node data to server
 * FIXME: return type should be Promise<Survey> and Promise<Error>?
 * @param networkId - workspace id
 * @param payload - node definition
 */
const createNode = async (
  networkId: NetworkSchema['id'],
  payload: NodeInputSchema
): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.CQRS_NODE({ networkId }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Update a node
 */
const updateNode = async (
  networkId: NetworkSchema['id'],
  id: NodeSchema['id'],
  payload: NodeInputSchema
): Promise<any> => {
  try {
    return extractResponse(await axios.put(ENDPOINTS.CQRS_NODE({ networkId, id }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Delete a node
 */
const deleteNode = async ({
  networkId,
  id
}: {
  networkId: NetworkSchema['id']
  id: NodeSchema['id']
}): Promise<any> => {
  try {
    return extractResponse(await axios.delete(ENDPOINTS.CQRS_NODE({ networkId, id })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Get a edge
 * @param id - network uuid
 */
const getEdge = async (id: EdgeSchema['id']): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.CQRS_EDGE({ id })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Get all edges for a specific workspace
 * @param networkId - Network id
 */
const getEdges = async (networkId: string): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.CQRS_NETWORK({ networkId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Submit edge data to server
 * FIXME: return type should be Promise<Survey> and Promise<Error>?
 * @param networkId - workspace id
 * @param payload - edge definition
 */
const createEdge = async ({
  networkId,
  payload
}: {
  networkId: NetworkSchema['id']
  payload: EdgeInputSchema
}): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.CQRS_EDGE({ networkId }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Update a edge
 */
const updateEdge = async ({
  networkId,
  id,
  payload
}: {
  networkId: NetworkSchema['id']
  id: EdgeSchema['id']
  payload: EdgeInputSchema
}): Promise<any> => {
  try {
    return extractResponse(await axios.put(ENDPOINTS.CQRS_EDGE({ networkId, id }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

/**
 * Delete a edge
 */
const deleteEdge = async ({
  networkId,
  id
}: {
  networkId: NetworkSchema['id']
  id: EdgeSchema['id']
}): Promise<any> => {
  try {
    return extractResponse(await axios.delete(ENDPOINTS.CQRS_EDGE({ networkId, id })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

export {
  createEdge,
  updateEdge,
  deleteEdge,
  getEdge,
  getEdges,
  createNode,
  updateNode,
  deleteNode,
  getNode,
  getNodes
}
