<template>
  <div class="sza-node-editor">
    <div v-if="hasParents">
      <table
        class="sza-prob-table"
        :style="tableStyle"
        :body-style="{ width: '100%', height: '100%' }"
      >
        <caption></caption>
        <thead>
          <tr>
            <th v-for="(parent, i) in parents" :key="i" class="header" scope="col" rowspan="2">
              <span class="entity">{{ parent?.name }}</span>
            </th>
            <th :colspan="states?.length" scope="col">
              <span class="entity">{{ variable?.name }}</span>
            </th>
          </tr>
          <tr>
            <th
              v-for="({ state }, stateIndex) in states"
              :key="stateIndex"
              :style="getStateStyle(state?.polarity || StatePolarity.POSITIVE)"
              scope="col"
            >
              <span class="entity">{{ state?.name }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="{ pStates, combIndex } in pageRows" :key="combIndex">
            <td
              v-for="(_, parentIndex) in parents"
              :key="parentIndex"
              class="header"
              :style="getStateStyle(pStates[parentIndex]?.polarity || StatePolarity.POSITIVE)"
            >
              <span class="entity">{{ pStates[parentIndex]?.name }}</span>
            </td>
            <td v-for="(_, stateIndex) in states" :key="stateIndex" class="cell" :style="cellStyle">
              <a-input-number
                v-model:value="definitionModel[states.length * combIndex + stateIndex]"
                :disabled="!editable || !stateIndex"
                :step="probStep"
                :formatter="probFormatter"
                @change="probChangeWithParents(stateIndex, combIndex)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="pageCount > 1" class="pager">
        <a-button :disabled="pageIndex === 0" size="small" @click="goPage(-1)">prev</a-button>
        {{ pageIndex + 1 }}/ {{ pageCount }}
        <a-button :disabled="pageIndex === pageCount - 1" size="small" @click="goPage(1)">
          next
        </a-button>
      </div>
    </div>
    <div v-else>
      <table class="sza-prob-table" :style="tableStyle">
        <caption></caption>
        <thead>
          <tr class="header">
            <th
              v-for="({ state }, i) in states"
              :key="i"
              class="cell"
              :style="getStateStyle(state?.polarity || StatePolarity.POSITIVE)"
            >
              <span class="entity">{{ state?.name }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(_, stateIndex) in states" :key="stateIndex" class="cell" :style="cellStyle">
              <a-input-number
                v-model:value="definitionModel[stateIndex]"
                :disabled="!editable || !stateIndex"
                :step="probStep"
                :formatter="probFormatter"
                @change="probChange(stateIndex)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue'

import { Network, Variable } from '@/libs/bayes'
import {
  combIndexToStateIndex,
  completeProbs,
  getProbsOfNodeDef,
  parentsCombinationDivisions,
  probFormatter,
  probRounder,
  probStep,
  setProbsOfNodeDef
} from '@/libs/common'
import { StatePolarity } from '@/libs/enums'
import { getStateStyle } from '@/libs/utils'

export const EVENTS = {
  CHANGE: 'change'
}

export default defineComponent({
  props: {
    network: { type: Object as PropType<Network>, required: true },
    variable: { type: Object as PropType<Variable>, required: true },
    editable: { type: Boolean, required: true }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const states = computed(() => props.variable.getAllStates() || [])
    const parents = computed(() => props.network.parentsOriginalOrder[props.variable.id] || [])
    const hasParents = computed(() => parents.value.length > 0)
    const parentStates = computed(() => {
      return parents.value.map((parent: Variable) => parent.getAllStates())
    })
    const divisions = computed(() => parentsCombinationDivisions(parentStates.value))
    const combinationTotal = computed(() => divisions.value?.[0])
    const definition = computed(() => {
      return props.variable?.nodeDefinition || []
    })

    const getNodeDefinition = () => props.variable?.nodeDefinition?.map(probRounder) || []
    const definitionModel: Ref<number[]> = ref(getNodeDefinition())

    watch(
      () => props.variable,
      () => {
        definitionModel.value = getNodeDefinition()
      }
    )

    const probChangeWithParents = (stateIndex: number, combIndex: number) => {
      if (stateIndex) {
        const probs = getProbsOfNodeDef(definitionModel.value, combIndex, states.value?.length)
        const newProbs = completeProbs(probs, 1.0, 0)
        setProbsOfNodeDef(definitionModel.value, newProbs, combIndex, states.value?.length)
      }
      emit(EVENTS.CHANGE, definitionModel.value)
    }
    const probChange = (stateIndex: number) => {
      if (stateIndex) {
        definitionModel.value = completeProbs(definitionModel.value, 1.0, 0)
      }
      emit(EVENTS.CHANGE, definitionModel.value)
    }
    const cellWidth = 90
    const tableStyle = computed(() => {
      return {
        width: `${
          hasParents.value
            ? (parents.value.length + states.value.length) * cellWidth
            : states.value?.length * cellWidth
        }px`
      }
    })
    const cellStyle = computed(() => ({
      width: `${cellWidth}px`
    }))

    const rows = computed(() => {
      const rows_ = []
      for (let combIndex = 0; combIndex < combinationTotal.value; combIndex++) {
        const stateIndexes = []
        const pStates = []
        for (let parentIndex = 0; parentIndex < parents.value.length; parentIndex++) {
          const stateIndex = combIndexToStateIndex(
            parentStates.value,
            divisions.value,
            combIndex,
            parentIndex
          )
          stateIndexes.push(stateIndex)
          pStates.push(parentStates.value[parentIndex][stateIndex]?.state)
        }
        rows_.push({
          id: combIndex,
          combIndex,
          stateIndexes,
          pStates
        })
      }
      return rows_
    })

    const pageSize = 16
    const pageIndex = ref(0)
    const pageCount = computed(() => Math.ceil(combinationTotal.value / pageSize))

    const pageRows = computed(() => {
      const start = pageIndex.value * pageSize
      return rows.value.slice(start, start + pageSize)
    })

    const goPage = (delta: number) => {
      let index = pageIndex.value + delta
      if (index < 0) {
        pageIndex.value = 0
      } else if (index >= pageCount.value) {
        pageIndex.value = pageCount.value - 1
      } else {
        pageIndex.value = index
      }
    }

    return {
      pageIndex,
      goPage,
      pageCount,
      pageRows,
      rows,
      cellStyle,
      tableStyle,
      probChangeWithParents,
      probFormatter,
      probRounder,
      probStep,
      definitionModel,
      probChange,
      combinationTotal,
      definition,
      states,
      divisions,
      combIndexToStateIndex,
      hasParents,
      parents,
      parentStates,
      getStateStyle,
      StatePolarity
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/commons.styl"

.sza-node-editor
  width: 100%
  height: 100%
  min-height: 500px
  overflow: auto
  position: relative
.pager
  position sticky
  left: 0
  width: 300px
  margin-bottom: 10px
.sza-prob-table
  margin: 5px 0;
  .entity
    @extend .truncate
    @extend .centered
  td, th
    border: 1px solid;
    text-align: center;
    height: 32px;
    width: 100px

  td.cell
    position relative
    padding: 0
    >.ant-input-number
      width: 100%
      height: 100%
      border: 0
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      .ant-input-number-input
        width: 100%
        height: 31px;
        padding: 0 35px 0 11px;
        text-align: right;
        &:focus
          outline: none
          background-color: #fffff0
</style>
