
import { css } from '@emotion/css'
import { defineComponent, PropType } from 'vue'

import { probFormatter } from '@/libs/common'

export const cssTable = css`
  font-size: 12px;
  td,
  th {
    width: 32px;
    height: 32px;
    padding-right: 4px;
    text-align: right;
    border: 1px solid #f0f0f0;
  }
`
export default defineComponent({
  props: {
    matrix: { type: Array as PropType<Array<number[]> | undefined>, default: () => [[]] },
    precision: { type: Number, default: 2 }
  },
  setup() {
    const cellStyle = (r: number, c: number) => {
      const backgroundColor = r === c ? '#f0f0f0' : 'white'
      return {
        backgroundColor
      }
    }

    return {
      cellStyle,
      cssTable,
      probFormatter
    }
  }
})
