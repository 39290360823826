import { findIndex } from 'lodash-es'
import { MutationTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { AllocationMutationEnum } from '@/store/enums/mutations/allocation'
import { AllocationStateEnum } from '@/store/enums/states/allocation'
import { AllocationMutation } from '@/store/types/mutations/allocation'
import { AllocationState } from '@/store/types/states/allocation'
import { AllocationList } from '@/types/api/allocation'
import { Allocation } from '@/types/database/allocation'

const mutations: MutationTree<AllocationState> & AllocationMutation = {
  // Create allocation
  [AllocationMutationEnum.CREATE_ALLOCATION](state: AllocationState) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.CREATING_ALLOCATION
    state[AllocationStateEnum.IS_CREATING] = true
  },
  [AllocationMutationEnum.CREATE_ALLOCATION_FULFILLED](
    state: AllocationState,
    allocation: Allocation
  ) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_CREATING] = false
    state[AllocationStateEnum.CURRENT_ALLOCATION] = allocation
    state[AllocationStateEnum.ALLOCATION_LIST] = {
      ...state[AllocationStateEnum.ALLOCATION_LIST],
      content: [...state[AllocationStateEnum.ALLOCATION_LIST]?.content, allocation]
    }
  },
  [AllocationMutationEnum.CREATE_ALLOCATION_REJECTED](state: AllocationState, error: Error) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_CREATING] = false
    state[AllocationStateEnum.ERROR] = error
  },
  // Get allocations
  [AllocationMutationEnum.GET_ALLOCATIONS](state: AllocationState) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_ALLOCATION
    state[AllocationStateEnum.IS_GETTING] = true
  },
  [AllocationMutationEnum.GET_ALLOCATIONS_FULFILLED](
    state: AllocationState,
    allocations: AllocationList
  ) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_GETTING] = false
    state[AllocationStateEnum.ALLOCATION_LIST] = allocations
  },
  [AllocationMutationEnum.GET_ALLOCATIONS_REJECTED](state: AllocationState, error: Error) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_GETTING] = false
    state[AllocationStateEnum.ERROR] = error
  },
  // Get allocation
  [AllocationMutationEnum.GET_ALLOCATION](state: AllocationState) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_ALLOCATION
    state[AllocationStateEnum.IS_GETTING] = true
  },
  [AllocationMutationEnum.GET_ALLOCATION_FULFILLED](
    state: AllocationState,
    allocation: Allocation
  ) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_GETTING] = false
    state[AllocationStateEnum.CURRENT_ALLOCATION] = allocation
  },
  [AllocationMutationEnum.GET_ALLOCATION_REJECTED](state: AllocationState, error: Error) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_GETTING] = false
    state[AllocationStateEnum.ERROR] = error
  },
  // Update allocation
  [AllocationMutationEnum.UPDATE_ALLOCATION](state: AllocationState) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.UPDATING_ALLOCATION
    state[AllocationStateEnum.IS_UPDATING] = true
  },
  [AllocationMutationEnum.UPDATE_ALLOCATION_FULFILLED](
    state: AllocationState,
    allocation: Allocation
  ) {
    const updatedId = allocation?.id
    state[AllocationStateEnum.IS_UPDATING] = false
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    const index = findIndex(state[AllocationStateEnum.ALLOCATION_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    if (index !== -1) {
      const newContent = [
        ...state[AllocationStateEnum.ALLOCATION_LIST]?.content?.slice(0, index),
        allocation,
        ...state[AllocationStateEnum.ALLOCATION_LIST]?.content?.slice(index + 1)
      ]
      state[AllocationStateEnum.ALLOCATION_LIST] = {
        ...state[AllocationStateEnum.ALLOCATION_LIST],
        content: newContent
      }
    }
  },
  [AllocationMutationEnum.UPDATE_ALLOCATION_REJECTED](state: AllocationState, error: Error) {
    state[AllocationStateEnum.IS_UPDATING] = false
    state[AllocationStateEnum.ERROR] = error
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Delete allocation
  [AllocationMutationEnum.DELETE_ALLOCATION](state: AllocationState) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.DELETING_ALLOCATION
    state[AllocationStateEnum.IS_DELETING] = true
  },
  [AllocationMutationEnum.DELETE_ALLOCATION_FULFILLED](
    state: AllocationState,
    id: Allocation['id']
  ) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_DELETING] = false
    const index = findIndex(state[AllocationStateEnum.ALLOCATION_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: id
    })
    if (index !== -1) {
      const newContent = [
        ...state[AllocationStateEnum.ALLOCATION_LIST]?.content?.slice(0, index),
        ...state[AllocationStateEnum.ALLOCATION_LIST]?.content?.slice(index + 1)
      ]
      state[AllocationStateEnum.ALLOCATION_LIST] = {
        ...state[AllocationStateEnum.ALLOCATION_LIST],
        content: newContent
      }
    }
  },
  [AllocationMutationEnum.DELETE_ALLOCATION_REJECTED](state: AllocationState, error: Error) {
    state[AllocationStateEnum.LOADING_PROMPT_TEXT] = null
    state[AllocationStateEnum.IS_DELETING] = false
    state[AllocationStateEnum.ERROR] = error
  },

  // Clear all content in current allocation list
  [AllocationMutationEnum.CLEAR_ALLOCATION_LIST](state: AllocationState) {
    state[AllocationStateEnum.ALLOCATION_LIST] = {
      ...state[AllocationStateEnum.ALLOCATION_LIST],
      content: []
    }
  }
}

export { mutations }
