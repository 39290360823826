import { SurveyStatusStateEnum } from '@/store/enums/states/surveyStatus'
import { SurveyStatusState } from '@/store/types/states/surveyStatus'

/**
 * Default value
 */
const state: SurveyStatusState = {
  [SurveyStatusStateEnum.IS_CREATING]: false,
  [SurveyStatusStateEnum.IS_UPDATING]: false,
  [SurveyStatusStateEnum.IS_DELETING]: false,
  [SurveyStatusStateEnum.IS_GETTING]: false,
  [SurveyStatusStateEnum.IS_DISTRIBUTING]: false,
  [SurveyStatusStateEnum.SURVEY_STATUS_LIST]: {},
  [SurveyStatusStateEnum.ERROR]: null
}

export { state }
