<template>
  <div :class="wrapper">
    <div class="sz-command-bar">
      <Import
        class="sza-import"
        :networks="networks"
        :workspace-id="workspaceId"
        v-on="{
          [EMIT_EVENTS.NETWORK.LOAD]: onNetworkImported
        }"
      />
    </div>
    <div class="sza-networks">
      <a-table
        size="small"
        :data-source="networkRows"
        :columns="networkColumns"
        :pagination="false"
      >
        <template #actions="{ record }">
          <ActionButton @click="removeNetwork(record)">
            <Trash />
          </ActionButton>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script lang="ts">
import { css } from '@emotion/css'
import { Trash } from '@vicons/tabler'
import { computed, defineComponent, PropType } from 'vue'

import ActionButton from '@/components/common/ActionButton.vue'
import Import from '@/components/network/XdslImport.vue'
import { EMIT_EVENTS } from '@/constants/emits'
import { Network } from '@/libs/bayes'
import { cssIcon } from '@/styles/common'

export const EVENTS = {
  NETWORK_IMPORTED: 'NETWORK_IMPORTED',
  NETWORK_REMOVE: 'NETWORK_REMOVE'
}

const wrapper = css`
  display: flex;
  flex-direction: column;

  .sza-import {
    flex: 0 0 auto;
  }

  .sza-networks {
    position: relative;
    flex: 1 1 auto;
  }
`

type NetworkRow = {
  key: string
  nodes: any[]
  name: string
}

export default defineComponent({
  components: {
    ActionButton,
    Import,
    Trash
  },
  props: {
    workspaceId: { type: String, required: true },
    networks: { type: Object as PropType<Network[]>, required: true }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const networkColumns = computed(() => {
      return [
        {
          title: 'Network name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Nodes count',
          dataIndex: 'nodes.length',
          key: 'nodes'
        },
        {
          title: '',
          key: 'actions',
          slots: {
            customRender: 'actions'
          }
        }
      ]
    })

    const networkRows = computed(
      () =>
        props.networks?.map((network: any) => ({
          key: network.id,
          nodes: network.nodes,
          name: network.name
        })) || []
    )

    const onNetworkImported = () => {
      emit(EVENTS.NETWORK_IMPORTED)
    }

    const removeNetwork = (row: NetworkRow) => {
      emit(EVENTS.NETWORK_REMOVE, row.key)
    }

    return {
      removeNetwork,
      networkColumns,
      wrapper,
      networkRows,
      EMIT_EVENTS,
      cssIcon,
      onNetworkImported
    }
  }
})
</script>

<style lang="stylus">
@import "../../../../styles/base.styl"
@import "../../../../styles/commons.styl"
</style>
