import { MutationTree } from 'vuex'

import { AuthMutationEnum } from '@/store/enums/mutations/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { AuthMutation } from '@/store/types/mutations/auth'
import { AuthState } from '@/store/types/states/auth'
import { UserPreference, UserToken } from '@/types/api/auth'
import { User } from '@/types/database/user'

/**
 * Mutations
 */
export const mutations: MutationTree<AuthState> & AuthMutation = {
  // Get user token
  [AuthMutationEnum.AUTH](state: AuthState) {
    state[AuthStateEnum.IS_VERIFYING] = true
    state[AuthStateEnum.IS_AUTHORIZED] = false
    state[AuthStateEnum.ERROR] = null
  },
  [AuthMutationEnum.AUTH_REFRESH](state: AuthState) {
    state[AuthStateEnum.IS_VERIFYING] = true
    state[AuthStateEnum.ERROR] = null
  },
  [AuthMutationEnum.AUTH_FULFILLED](state: AuthState, token: UserToken) {
    state[AuthStateEnum.IS_VERIFYING] = false
    state[AuthStateEnum.IS_AUTHORIZED] = true
    state[AuthStateEnum.TOKEN] = token
  },
  [AuthMutationEnum.AUTH_REJECTED](state: AuthState, error: Error) {
    state[AuthStateEnum.IS_VERIFYING] = false
    state[AuthStateEnum.IS_AUTHORIZED] = false
    state[AuthStateEnum.TOKEN] = null
    state[AuthStateEnum.ERROR] = error
  },
  // Set current logged in user
  [AuthMutationEnum.AUTH_CURRENT](state: AuthState) {
    state[AuthStateEnum.IS_GETTING_CURRENT_USER] = true
    state[AuthStateEnum.ERROR] = null
  },
  [AuthMutationEnum.AUTH_CURRENT_FULFILLED](state: AuthState, user: User) {
    state[AuthStateEnum.IS_GETTING_CURRENT_USER] = false
    state[AuthStateEnum.USER] = user
  },
  [AuthMutationEnum.AUTH_CURRENT_REJECTED](state: AuthState, error: Error) {
    state[AuthStateEnum.IS_GETTING_CURRENT_USER] = false
    state[AuthStateEnum.IS_AUTHORIZED] = false
    state[AuthStateEnum.ERROR] = error
  },
  // Logout
  [AuthMutationEnum.LOG_OUT](state: AuthState) {
    state[AuthStateEnum.IS_LOGGING_OUT] = true
    state[AuthStateEnum.ERROR] = null
  },
  [AuthMutationEnum.LOG_OUT_FULFILLED](state: AuthState) {
    state[AuthStateEnum.IS_LOGGING_OUT] = false
    state[AuthStateEnum.IS_AUTHORIZED] = false
    state[AuthStateEnum.USER] = null
  },
  [AuthMutationEnum.LOG_OUT_REJECTED](state: AuthState, error: Error) {
    state[AuthStateEnum.IS_LOGGING_OUT] = false
    state[AuthStateEnum.IS_AUTHORIZED] = false
    state[AuthStateEnum.USER] = null
    state[AuthStateEnum.ERROR] = error
  },
  [AuthMutationEnum.SET_USER_PREFERENCE](state: AuthState, payload: UserPreference) {
    state[AuthStateEnum.USER_PREFERENCE] = payload
  },
  [AuthMutationEnum.UPDATE_CURRENT_USER](state: AuthState) {
    state[AuthStateEnum.ERROR] = null
    state[AuthStateEnum.IS_UPDATING_CURRENT_USER] = true
  },
  [AuthMutationEnum.UPDATE_CURRENT_USER_FULFILLED](state: AuthState, payload: User) {
    state[AuthStateEnum.IS_UPDATING_CURRENT_USER] = false
    state[AuthStateEnum.USER] = payload
  },
  [AuthMutationEnum.UPDATE_CURRENT_USER_REJECTED](state: AuthState, payload: Error) {
    state[AuthStateEnum.IS_UPDATING_CURRENT_USER] = false
    state[AuthStateEnum.ERROR] = payload
  }
}
