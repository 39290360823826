<template>
  <div class="sz-workspace card-container" :style="cardStyle">
    <div class="header">
      <a-space size="small">
        <a-tooltip placement="top">
          <template #title>
            <span>Edit</span>
          </template>
          <EditTwoTone @click="handleClickEditButton" />
        </a-tooltip>
        <a-popconfirm
          title="Are you sure delete this workspace?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteWorkspace"
        >
          <a-tooltip placement="top">
            <template #title>
              <span>Delete</span>
            </template>
            <DeleteTwoTone two-tone-color="#eb2f96" />
          </a-tooltip>
        </a-popconfirm>
      </a-space>
    </div>
    <div class="body-container">
      <div class="title" :title="workspace.name">{{ workspace.name }}</div>
      <div class="updated">Last updated {{ modified }}</div>
      <div class="description">{{ workspace.description }}</div>
      <!-- <div>created {{ created }}</div> -->
    </div>
    <div class="action-container">
      <a-tooltip placement="top">
        <template #title>
          <span>Cause Effect</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ANALYSIS_CAUSEEFFECT, params: { workspaceId } }">
          <Icon size="24">
            <ArrowRightTail />
          </Icon>
        </router-link>
      </a-tooltip>
    </div>
    <div class="action-container">
      <a-tooltip placement="top">
        <template #title>
          <span>Build</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.BUILDER, params: { workspaceId } }">
          <BuildOutlined />
        </router-link>
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>
          <span>Network</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.NETWORK, params: { workspaceId } }">
          <DeploymentUnitOutlined />
        </router-link>
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>
          <span>Network Variables</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.NETWORK_VARIABLES, params: { workspaceId } }">
          <BarsOutlined />
        </router-link>
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>
          <span>Allocation</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ALLOCATION, params: { workspaceId } }">
          <UserSwitchOutlined />
        </router-link>
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>
          <span>Survey list</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.SURVEYS, params: { workspaceId } }">
          <SnippetsOutlined />
        </router-link>
      </a-tooltip>
      <!--<TableOutlined />
      <ExperimentTwoTone @click="handleClickNetworkIcon" />-->
    </div>
  </div>
</template>

<script lang="ts">
import {
  BarsOutlined,
  BuildOutlined,
  DeleteTwoTone,
  DeploymentUnitOutlined,
  EditTwoTone,
  SnippetsOutlined,
  UserSwitchOutlined
} from '@ant-design/icons-vue'
import { ArrowRightTail } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed, defineComponent, ref, toRefs } from 'vue'

import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import router from '@/router'
import { useStore } from '@/store'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { vuexActions } from '@/store/util'
dayjs.extend(relativeTime)
import { message } from 'ant-design-vue'

import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { gradientMaker } from '@/libs/utils'

export default defineComponent({
  components: {
    Icon,
    ArrowRightTail,
    BarsOutlined,
    BuildOutlined,
    DeleteTwoTone,
    DeploymentUnitOutlined,
    EditTwoTone,
    SnippetsOutlined,
    UserSwitchOutlined
  },
  props: {
    workspace: {
      type: Object,
      required: true
    },
    focus: {
      type: Boolean,
      required: false
    }
  },
  emits: [EMIT_EVENTS.WORKSPACE.CLICK_WORKSPACE_CARD],
  setup(props, { emit }) {
    const store = useStore()
    const isShowEditForm = ref(false)
    const { workspace } = toRefs(props)
    const workspaceId = workspace.value?.id
    const created = computed(() => dayjs(workspace.value.createdDate).fromNow())
    // FIXME: time incorrect
    const modified = computed(() => dayjs(workspace.value.lastModifiedDate).fromNow())

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(props.workspace?.name || 'UNTITLED'),
      boxShadow: props.focus
        ? '0 0 8px rgb(0 0 0 / 20%), 0 0 20px rgb(0 0 0 / 20%)'
        : '0 0 2px rgb(0 0 0 / 10%), 0 0 8px rgb(0 0 0 / 10%)'
    }))
    /**
     * Handle set whether display edit form
     **/
    const handleShowEditForm = (value: boolean) => {
      isShowEditForm.value = value
    }

    /**
     * Delete workspace
     */
    const deleteWorkspace = () => {
      store.dispatch(
        vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.DELETE_WORKSPACE),
        workspaceId
      )
      message.success(MESSAGE.WORKSPACE_DELETE_SUCCESS)
    }

    /**
     * Handle click each workspace, to enter specific workspace
     */
    const handleClickWorkspace = () => {
      router.push({ name: ROUTE_NAME.SURVEYS, params: { workspaceId } })
    }

    /**
     * Click card
     **/
    const handleClickEditButton = () => {
      emit(EMIT_EVENTS.WORKSPACE.CLICK_WORKSPACE_CARD, props.workspace)
    }

    return {
      ROUTE_NAME,
      created,
      modified,
      isShowEditForm,
      deleteWorkspace,
      handleShowEditForm,
      handleClickWorkspace,
      handleClickEditButton,
      workspaceId,
      cardStyle
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/base.styl"
@import "../../styles/commons.styl"

.sz-workspace
  &.card-container
    @extend .sz-window
    position relative
    background-color white
    padding 5px 15px 15px 15px
    margin 0 15px 15px 0
    display flex
    flex-direction column
    align-items stretch

  .header
    flex 0 0 auto
    height 32px
    align-items flex-start
    display flex
    flex-direction row
    > .ant-space
      flex: 0 0 auto
      display flex
      flex-direction row
      justify-content flex-end
      width 100%

  .body-container
    width 100%
    overflow hidden
    min-height 1px
    flex 1 1 auto
    display flex
    flex-direction column
    > *
      min-height 1px

  .title
    @extend .truncate
    font-size 18px
    font-weight bold
    flex 0 0 auto

  .description
    overflow auto
    padding 3px 0 4px 0
    font-size 12px
    line-height 15px
    flex 1 1 auto
    color grey

  .updated
    flex 0 0 auto
    margin 4px 0 0 0
    font-size 12px
    color #a0a0a0

  .action-container
    flex 0 0 auto
    display flex
    flex-direction row
    justify-content space-between
    align-items flex-end
    height 40px

    .anticon
      font-size 24px
</style>
