export const MoeaAlgoType: Record<string, string> = {
  NSGAII: 'NSGAII',
  NSGAIII: 'NSGAIII',
  eNSGAII: 'eNSGAII',
  eMOEA: 'eMOEA',
  GDE3: 'GDE3',
  PAES: 'PAES',
  PESA2: 'PESA2',
  SPEA2: 'SPEA2',
  IBEA: 'IBEA',
  SMSEMOA: 'SMSEMOA',
  SMPSO: 'SMPSO',
  OMOPSO: 'OMOPSO',
  CMAES: 'CMAES',
  MOEAD: 'MOEAD'
}

export interface OptionDesignConfigData {
  moeaAlgoType: string
  populationSize: number
  evaluationMax: number
  timeMax: number
  solUnchangedMax: number
  searchDirection: number
  randomSeed?: number
  maxInputNodes?: number
}

export const DEFAULT_OPTION_DESIGN_CONFIG: OptionDesignConfigData = {
  moeaAlgoType: MoeaAlgoType.NSGAII,
  populationSize: 50,
  evaluationMax: 50000,
  timeMax: 600000,
  solUnchangedMax: 5,
  searchDirection: 1,
  maxInputNodes: undefined
}
