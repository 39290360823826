/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum QuestionnaireActionEnum {
  GET_DATA = 'GET_DATA',
  GET_NETWORK_CPTS = 'GET_NETWORK_CPTS',
  GET_SURVEY_CPTS = 'GET_SURVEY_CPTS',
  GET_SURVEY_RESPONSES = 'GET_SURVEY_RESPONSE',
  GET_WORKSPACE_NETWORKS = 'GET_WORKSPACE_NETWORKS',
  GET_WORKSPACE_USER_ALLOCATIONS = 'GET_WORKSPACE_USER_ALLOCATIONS',
  UPDATE_RESPONSES = 'UPDATE_RESPONSES',
  CREATE_RESPONSES = 'CREATE_RESPONSES',
  PATCH_RESPONSES = 'PATCH_RESPONSES'
}
