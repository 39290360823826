<template>
  <div
    v-if="
      currentLayout.RENDER &&
      currentLayout.WITH_SCAFFOLD &&
      currentLayout.WITH_SCAFFOLD.NORTH_STRIPE
    "
    class="north-stripe"
  />
  <router-link
    v-if="currentLayout.RENDER && currentLayout.WITH_SCAFFOLD && currentLayout.WITH_SCAFFOLD.LOGO"
    class="nav-text"
    :to="PATH.HOME"
  >
    <img src="/static/logo.svg" class="sz-logo" alt="sauce" />
  </router-link>
  <div
    v-if="
      currentLayout.RENDER && currentLayout.WITH_SCAFFOLD && currentLayout.WITH_SCAFFOLD.BREADCRUMB
    "
    class="sz-breadcrumb"
  >
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="PATH.HOME"> home </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-for="eachPath in currentUrlPath" :key="eachPath">
        <router-link v-if="eachPath.path" :to="eachPath.path">
          {{ eachPath.name }}
        </router-link>
        <span v-else>
          {{ eachPath.name }}
        </span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
  <a-layout v-if="currentLayout.WITH_SCAFFOLD" class="sz-main-layout">
    <a-layout-sider
      :collapsible="false"
      width="48px"
      :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
    >
      <!--      Protected content-->
      <div :style="{ overflow: 'hidden', height: '100vh' }">
        <a-menu mode="inline" class="sz-main-menu" :selectable="false" @click="handleClickMenu">
          <a-menu-item
            v-if="currentLayout.WITH_SCAFFOLD.MENUS[MENUS.ACCOUNT] && currentUser"
            :key="MENUS.ACCOUNT"
            :style="{ marginBottom: '40px' }"
          >
            <a-avatar :size="36" :src="currentUserProfilePhoto" :title="currentUser.username" />
          </a-menu-item>
          <a-menu-item
            v-if="currentLayout.WITH_SCAFFOLD.MENUS[MENUS.BUILDER] && false"
            :key="MENUS.BUILDER"
          >
            <DeploymentUnitOutlined />
          </a-menu-item>
          <a-menu-item
            v-if="currentLayout.WITH_SCAFFOLD.MENUS[MENUS.USERS]"
            :key="MENUS.WORKSPACES"
          >
            <AppstoreOutlined />
          </a-menu-item>
          <a-menu-item
            v-if="currentLayout.WITH_SCAFFOLD.MENUS[MENUS.ANALYSIS]"
            :key="MENUS.ANALYSIS"
          >
            <Icon color="white" size="20">
              <Flask />
            </Icon>
          </a-menu-item>
          <a-menu-item
            v-if="currentLayout.WITH_SCAFFOLD.MENUS[MENUS.MANUAL]"
            :key="MENUS.MANUAL"
            :style="{ position: 'absolute', bottom: '160px' }"
          >
            <a-tooltip placement="top" style="width: 600px">
              <template #title>
                <div>View user instructions</div>
              </template>
              <InfoCircleOutlined />
            </a-tooltip>
          </a-menu-item>
          <a-menu-item
            v-if="currentLayout.WITH_SCAFFOLD.MENUS[MENUS.USERS]"
            :key="MENUS.USERS"
            :style="{ position: 'absolute', bottom: '110px' }"
          >
            <TeamOutlined />
          </a-menu-item>
          <a-menu-item :key="MENUS.LOGOUT" :style="{ position: 'absolute', bottom: '60px' }">
            <LogoutOutlined />
          </a-menu-item>
          <a-menu-item :key="MENUS.HELP" :style="{ position: 'absolute', bottom: '10px' }">
            <a-tooltip placement="top" style="width: 600px">
              <template #title>
                <div>frontend: {{ frontendVersion }}</div>
                <div>backend: {{ backendVersion }}</div>
              </template>
              <QuestionCircleOutlined />
            </a-tooltip>
          </a-menu-item>
        </a-menu>
      </div>
    </a-layout-sider>
    <a-layout :style="{ margin: '50px 0 0 48px' }">
      <a-layout-content style="overflow: visible">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
  <div v-if="currentLayout.RENDER && !currentLayout.WITH_SCAFFOLD" class="fullcover">
    <router-view />
  </div>
</template>

<script lang="ts">
import {
  AppstoreOutlined,
  DeploymentUnitOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  TeamOutlined
} from '@ant-design/icons-vue'
import { Flask } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import moment from 'moment'
import { computed, defineComponent, onBeforeMount, VNodeChild } from 'vue'
import { useRequest } from 'vue-request'
import { useRoute, useRouter } from 'vue-router'

import { DEFAULT_PROFILE_PHOTO_URL } from '@/constants/components'
import { DB_FIELDS } from '@/constants/database'
import { PATH, ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { logger } from '@/libs/logger'
import { getInfo } from '@/services/api/system'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { AuthGetterEnum } from '@/store/enums/getters/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { WorkspaceStateEnum } from '@/store/enums/states/workspace'
import { vuexActions, vuexGetters } from '@/store/util'
import { ValueOf } from '@/types'

import { default as helpMap } from './help.json'

interface MenuInfo {
  key: string
  keyPath: string[]
  item: VNodeChild
  domEvent: MouseEvent
}

const MENUS = {
  ACCOUNT: 'account',
  BUILDER: 'builder',
  USERS: 'users',
  WORKSPACES: 'workspaces',
  ANALYSIS: 'analysis',
  LOGOUT: 'logout',
  HELP: 'help',
  MANUAL: 'manual'
} as const

const DEFAULT_VISIBLE_MENUS = {
  [MENUS.ACCOUNT]: true,
  [MENUS.BUILDER]: true,
  [MENUS.USERS]: true,
  [MENUS.WORKSPACES]: true,
  [MENUS.ANALYSIS]: true,
  [MENUS.LOGOUT]: true,
  [MENUS.HELP]: true,
  [MENUS.MANUAL]: true
} as const

const UNPROTECTED_ROUTE_LAYOUT = {
  WITH_SCAFFOLD: false,
  RENDER: true
}

const PROTECTED_ROUTE_LAYOUT = {
  WITH_SCAFFOLD: {
    LOGO: true,
    NORTH_STRIPE: false,
    BREADCRUMB: true,
    MENUS: { ...DEFAULT_VISIBLE_MENUS }
  },
  RENDER: true
} as const

type RouteNameValue = ValueOf<typeof ROUTE_NAME>
const getRouteLayout = ({
  isAdmin,
  hasPrivilege,
  isAuthorized
}: {
  isAdmin: boolean
  hasPrivilege: boolean
  isAuthorized: boolean
}): {
  [key in RouteNameValue]: any
} => ({
  [ROUTE_NAME.ALLOCATION]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ALLOCATION_DEPRECATED]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.LOGIN]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: !isAuthorized
  },
  [ROUTE_NAME.ACCOUNT]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false,
      BREADCRUMB: false,
      LOGO: false,
      MENUS: {
        ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD.MENUS,
        [MENUS.USERS]: hasPrivilege,
        [MENUS.WORKSPACES]: hasPrivilege
      }
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.HOME]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.MANUAL]: {
    RENDER: isAuthorized
  },
  [ROUTE_NAME.NETWORK]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.NETWORK_VARIABLES]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.PAGE]: {
    ...PROTECTED_ROUTE_LAYOUT,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.GO_AHP]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: !isAuthorized
  },
  [ROUTE_NAME.AHP]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false,
      BREADCRUMB: false,
      LOGO: false,
      MENUS: {
        ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD.MENUS,
        [MENUS.USERS]: hasPrivilege,
        [MENUS.WORKSPACES]: hasPrivilege
      }
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.GO_QUESTIONNAIRE]: {
    ...UNPROTECTED_ROUTE_LAYOUT,
    RENDER: !isAuthorized
  },
  [ROUTE_NAME.QUESTIONNAIRE]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false,
      BREADCRUMB: false,
      LOGO: false,
      MENUS: {
        ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD.MENUS,
        [MENUS.USERS]: hasPrivilege,
        [MENUS.WORKSPACES]: hasPrivilege
      }
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEYS]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEYS_NEW]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_STOCHASTICS]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_STATUSES]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_SUMMARY]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.SURVEY_ANALYTICS]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.USERS]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized && isAdmin
  },
  [ROUTE_NAME.BUILDER]: {
    WITH_SCAFFOLD: isAuthorized && PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
    RENDER: isAuthorized
  },
  [ROUTE_NAME.WORKSPACES]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_WORKSPACES]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_WORKSPACE]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_CAUSEEFFECT]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_HYBRID]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_WHATIF]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_SENSITIVITY]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.ANALYSIS_OPTION_DESIGN]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  },
  [ROUTE_NAME.WORKSPACE]: {
    WITH_SCAFFOLD: isAuthorized && {
      ...PROTECTED_ROUTE_LAYOUT.WITH_SCAFFOLD,
      NORTH_STRIPE: false
    },
    RENDER: isAuthorized
  }
})

const frontendVersion = process.env.VUE_APP_UI_VERSION

export default defineComponent({
  name: 'App',
  components: {
    AppstoreOutlined,
    DeploymentUnitOutlined,
    InfoCircleOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
    Flask,
    Icon
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const router = useRouter()
    const { data: dataInfo } = useRequest(getInfo)

    const backendVersion = computed(() =>
      dataInfo.value
        ? dataInfo.value.build?.version +
          ' ' +
          dataInfo.value.git?.commit?.id +
          ' ' +
          moment(dataInfo.value.git?.commit?.time).format('YY-MM-DD|HH:MM')
        : ''
    )

    const isAuthorized = computed(() => store.state.auth[AuthStateEnum.IS_AUTHORIZED])
    const currentWorkspace = computed(
      () => store.state.workspace[WorkspaceStateEnum.CURRENT_WORKSPACE]
    )
    const currentSurvey = computed(() => store.state.survey[SurveyStateEnum.CURRENT_SURVEY])
    const currentPathName = computed(() => route?.name)
    const currentUser = computed(() => store.state[ModuleNames.AUTH][AuthStateEnum.USER])
    const currentUserProfilePhoto = computed(
      () => currentUser.value?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
    )
    const routeLayouts = computed(() => {
      const hasPrivilege = store.getters[
        vuexGetters(ModuleNames.AUTH, AuthGetterEnum.HAS_PRIVILEGE)
      ] as boolean
      const isAdmin = store.getters[
        vuexGetters(ModuleNames.AUTH, AuthGetterEnum.IS_ADMIN)
      ] as boolean
      return getRouteLayout({ isAdmin, hasPrivilege, isAuthorized: isAuthorized.value })
    })
    const currentLayout = computed(() => {
      const routeName = route?.name
      let layout = UNPROTECTED_ROUTE_LAYOUT
      if (routeName && Object.prototype.hasOwnProperty.call(routeLayouts.value, routeName)) {
        layout = routeLayouts.value[routeName as RouteNameValue]
      }
      return layout
    })
    const currentManualPage = computed(() => {
      const routePath = route.path.split('/').filter((each) => each !== '/' && each !== '')
      const HELP = helpMap as any
      let pdfPage

      if (routePath[0] === 'analysis' && routePath[1] === 'workspaces') {
        const helpAnalysisWorkspace = HELP[routePath[0]][routePath[1]][routePath[3]]
        if (helpAnalysisWorkspace) {
          pdfPage = helpAnalysisWorkspace
        }
      } else if (routePath[0] === 'users') {
        pdfPage = HELP[routePath[0]]
      } else if (routePath[0] === 'workspaces') {
        const helpWorkspace = HELP[routePath[0]][routePath[2]]
        if (routePath[2] === 'surveys') {
          const helpSurvey = helpWorkspace[routePath[4]]
          if (helpSurvey) {
            pdfPage = helpSurvey
          } else {
            pdfPage = helpWorkspace['*']
          }
        } else if (helpWorkspace) {
          pdfPage = helpWorkspace
        } else {
          pdfPage = HELP[routePath[0]]['*']
        }
      }
      return pdfPage
    })
    const currentUrlPath = computed(() => {
      let parts = route.path.split('/').filter((each) => each !== '/' && each !== '')
      logger.info('currentUrlPath', currentWorkspace.value, parts)
      let crumbs = []
      for (let i = 0; i < parts.length; i++) {
        let part = parts[i]
        let path = ''
        let name = part
        if (part === 'workspaces') {
          if (parts[i - 1] === 'analysis') {
            path = '/analysis'
          }
          path += '/workspaces'
          crumbs.push({
            name,
            path
          })
          if (i === parts.length - 1) {
            continue
          }
        } else if (parts[i - 1] === 'workspaces') {
          let workspaceId = part
          if (parts[i - 2] === 'analysis') {
            path = '/analysis'
          }
          path += `/workspaces/${workspaceId}`
          if (currentWorkspace.value && workspaceId === currentWorkspace.value.id) {
            name = currentWorkspace.value.name
          } else {
            name = workspaceId
          }
          crumbs.push({
            name,
            path
          })
        } else if (part === 'surveys') {
          crumbs.push({
            name: part,
            path: `/workspaces/${currentWorkspace.value && currentWorkspace.value.id}/surveys`
          })
          if (i === parts.length - 1) {
            continue
          }
          let surveyId = parts[i + 1]
          if (surveyId === currentSurvey.value?.id && currentWorkspace.value?.id) {
            crumbs.push({
              name: currentSurvey.value.name,
              path: `/workspaces/${currentWorkspace.value?.id}/surveys/${currentSurvey.value?.id}`
            })
          } else {
            crumbs.push({
              name: surveyId,
              path: null // `/workspaces/${workspaceId}`
            })
          }
          i++
        } else if (part === 'builder') {
          crumbs.push({
            name: 'builder (WIP)',
            path: null
          })
        } else {
          crumbs.push({
            name: part,
            path: null
          })
        }
      }
      return crumbs
    })

    /**
     * Handle click menu items
     **/
    const handleClickMenu = (e: MenuInfo) => {
      const { key } = e
      switch (key) {
        case MENUS.ACCOUNT:
          router.push({
            name: ROUTE_NAME.ACCOUNT
          })
          break
        case MENUS.BUILDER:
          router.push({
            name: ROUTE_NAME.BUILDER
          })
          break
        case MENUS.USERS:
          router.push({
            name: ROUTE_NAME.USERS
          })
          break
        case MENUS.WORKSPACES:
          router.push({
            name: ROUTE_NAME.WORKSPACES
          })
          break
        case MENUS.ANALYSIS:
          router.push({
            name: ROUTE_NAME.ANALYSIS_WORKSPACES
          })
          break
        case MENUS.LOGOUT:
          store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.LOG_OUT))
          break
        case MENUS.MANUAL:
          // eslint-disable-next-line no-case-declarations
          let routeData = router.resolve({
            name: ROUTE_NAME.MANUAL
          })
          if (currentManualPage.value) {
            routeData = router.resolve({
              name: ROUTE_NAME.MANUAL,
              query: {
                pageNumber: currentManualPage.value.pageNumber,
                totalPages: currentManualPage.value.totalPages
              }
            })
          } else {
            routeData = router.resolve({
              name: ROUTE_NAME.MANUAL
            })
          }

          window.open(routeData.href, '_blank')
          break
        case MENUS.HELP:
          break
        default:
          break
      }
    }

    onBeforeMount(() => {
      store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.SYNC_USER_PREFERENCE))
    })

    return {
      dataInfo,
      frontendVersion,
      backendVersion,
      MENUS,
      PATH,
      ROUTE_NAME,
      currentLayout,
      currentPathName,
      currentUrlPath,
      currentUser,
      currentUserProfilePhoto,
      handleClickMenu,
      isAuthorized
    }
  }
})
</script>

<style lang="stylus">
@import './styles/base.styl';
@import './styles/commons.styl';
@import './styles/vars.styl';

.north-stripe
  background-color rgba(255, 255, 255, 0.5)
  box-shadow 0 0 4px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.1)
  height 90px
  position absolute
  left 0
  top 0
  right 0
  z-index 0

#app
  font-family 'Inria Sans', Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
  font-size: 14px
  position absolute
  top 0
  left 0
  right 0
  bottom 0

.ant-layout-sider
  background linear-gradient(#5a9da9, #1B6D89) // #74dfdf #1B6D89
  background linear-gradient(#1B6D89, #273d46)
  background linear-gradient(#273d46, #5a9da9)
  box-shadow 0 4px 10px rgba(0, 0, 0, 0.4)
  z-index 1000

.sz-main-menu
  padding: 6px
  margin-top 8px
  background-color transparent
  border none !important
  height 100%
  .ant-menu-item::after
    border-right none !important
  .ant-menu-item
    @extend .centered
    border-radius 50%
    padding 0 !important
    margin-bottom 10px
    display flex
    color white
    width $menu-item-size !important
    height $menu-item-size !important
    &.ant-menu-item-selected
      background-color: rgb(0, 21, 41)
    .anticon
      margin 0
      svg
        width 20px
        height 20px

.sz-breadcrumb
  position absolute
  left 63px
  top 12px
  .ant-breadcrumb
    font-size 16px

.site-layout .site-layout-background
  background #fff

.sz-user-badge
  position absolute
  top 10px
  right 10px

.sz-logo
  width: 60px
  margin: 6px
  position: absolute
  right: 5px
  top: 2px

[data-theme='dark'] .site-layout .site-layout-background
  background #141414

.logo-text
  color white
  font-size 20px
  font-weight bold
  margin 10px auto
  text-align center

.ant-layout-content
  max-height: calc(100vh - 50px);
  padding: 0 16px 0;
  overflow hidden
  display flex
  flex-direction column

.ant-layout-footer
  color grey !important

.splitpanes.default-theme .splitpanes__pane
  background-color: white
</style>
