import { findIndex } from 'lodash-es'
import { MutationTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
import { SurveyStatusMutationEnum } from '@/store/enums/mutations/surveyStatus'
import { SurveyStatusStateEnum } from '@/store/enums/states/surveyStatus'
import { SurveyStatusMutation } from '@/store/types/mutations/surveyStatus'
import { SurveyStatusState } from '@/store/types/states/surveyStatus'
import { SurveyStatusList } from '@/types'
import { SurveyStatus } from '@/types/database/surveyStatus'

/**
 * Mutation function
 */
export const mutations: MutationTree<SurveyStatusState> & SurveyStatusMutation = {
  // Get surveyStatus
  [SurveyStatusMutationEnum.GET_SURVEY_STATUSES](state: SurveyStatusState) {
    state[SurveyStatusStateEnum.IS_GETTING] = true
  },
  [SurveyStatusMutationEnum.GET_SURVEY_STATUSES_FULFILLED](
    state: SurveyStatusState,
    payload: SurveyStatusList
  ) {
    state[SurveyStatusStateEnum.IS_GETTING] = false
    state[SurveyStatusStateEnum.SURVEY_STATUS_LIST] = payload
  },
  [SurveyStatusMutationEnum.GET_SURVEY_STATUSES_REJECTED](state: SurveyStatusState, error: Error) {
    state[SurveyStatusStateEnum.IS_GETTING] = false
    state[SurveyStatusStateEnum.ERROR] = error
  },
  // Get surveyStatus
  [SurveyStatusMutationEnum.GET_SURVEY_STATUS](state: SurveyStatusState) {
    state[SurveyStatusStateEnum.IS_GETTING] = true
  },
  [SurveyStatusMutationEnum.GET_SURVEY_STATUS_FULFILLED](
    state: SurveyStatusState,
    payload: SurveyStatus
  ) {
    state[SurveyStatusStateEnum.IS_GETTING] = false
    state[SurveyStatusStateEnum.SURVEY_STATUS_LIST] = payload
  },
  [SurveyStatusMutationEnum.GET_SURVEY_STATUS_REJECTED](state: SurveyStatusState, error: Error) {
    state[SurveyStatusStateEnum.IS_GETTING] = false
    state[SurveyStatusStateEnum.ERROR] = error
  },
  // Create surveyStatus
  [SurveyStatusMutationEnum.CREATE_SURVEY_STATUS](state: SurveyStatusState) {
    state[SurveyStatusStateEnum.IS_CREATING] = true
  },
  [SurveyStatusMutationEnum.CREATE_SURVEY_STATUS_FULFILLED](
    state: SurveyStatusState,
    payload: SurveyStatus
  ) {
    state[SurveyStatusStateEnum.IS_CREATING] = false
    const newContent = [...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content, payload]
    state[SurveyStatusStateEnum.SURVEY_STATUS_LIST] = {
      ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST],
      content: newContent
    }
  },
  [SurveyStatusMutationEnum.CREATE_SURVEY_STATUS_REJECTED](state: SurveyStatusState, error: Error) {
    state[SurveyStatusStateEnum.IS_CREATING] = false
    state[SurveyStatusStateEnum.ERROR] = error
  },
  // Update surveyStatus
  [SurveyStatusMutationEnum.UPDATE_SURVEY_STATUS](state: SurveyStatusState) {
    state[SurveyStatusStateEnum.IS_UPDATING] = true
  },
  [SurveyStatusMutationEnum.UPDATE_SURVEY_STATUS_FULFILLED](
    state: SurveyStatusState,
    surveyStatus: SurveyStatus
  ) {
    const updatedId = surveyStatus?.id
    const index = findIndex(state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    state[SurveyStatusStateEnum.IS_UPDATING] = false
    if (index !== -1) {
      const newContent = [
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content.slice(0, index),
        surveyStatus,
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content.slice(index + 1)
      ]
      state[SurveyStatusStateEnum.SURVEY_STATUS_LIST] = {
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST],
        content: newContent
      }
    }
  },
  [SurveyStatusMutationEnum.UPDATE_SURVEY_STATUS_REJECTED](state: SurveyStatusState, error: Error) {
    state[SurveyStatusStateEnum.IS_UPDATING] = false
    state[SurveyStatusStateEnum.ERROR] = error
  },
  // Distribute survey
  [SurveyStatusMutationEnum.DISTRIBUTE_SURVEY](state: SurveyStatusState) {
    state[SurveyStatusStateEnum.IS_DISTRIBUTING] = true
  },
  [SurveyStatusMutationEnum.DISTRIBUTE_SURVEY_FULFILLED](
    state: SurveyStatusState,
    surveyStatus: SurveyStatus
  ) {
    state[SurveyStatusStateEnum.IS_DISTRIBUTING] = false
    const updatedId = surveyStatus?.id
    const index = findIndex(state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    if (index !== -1) {
      const newContent = [
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content.slice(0, index),
        surveyStatus,
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content.slice(index + 1)
      ]
      state[SurveyStatusStateEnum.SURVEY_STATUS_LIST] = {
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST],
        content: newContent
      }
    }
  },
  [SurveyStatusMutationEnum.DISTRIBUTE_SURVEY_REJECTED](state: SurveyStatusState, error: Error) {
    state[SurveyStatusStateEnum.IS_DISTRIBUTING] = false
    state[SurveyStatusStateEnum.ERROR] = error
  },
  // Delete surveyStatus
  [SurveyStatusMutationEnum.DELETE_SURVEY_STATUS](state: SurveyStatusState) {
    state[SurveyStatusStateEnum.IS_DELETING] = true
  },
  [SurveyStatusMutationEnum.DELETE_SURVEY_STATUS_FULFILLED](
    state: SurveyStatusState,
    id: SurveyStatus['id']
  ) {
    const index = findIndex(state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: id
    })

    state[SurveyStatusStateEnum.IS_DELETING] = false
    if (index !== -1) {
      const newContent = [
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content.slice(0, index),
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content.slice(index + 1)
      ]
      state[SurveyStatusStateEnum.SURVEY_STATUS_LIST] = {
        ...state[SurveyStatusStateEnum.SURVEY_STATUS_LIST],
        content: newContent
      }
    }
  },
  [SurveyStatusMutationEnum.DELETE_SURVEY_STATUS_REJECTED](state: SurveyStatusState, error: Error) {
    state[SurveyStatusStateEnum.IS_DELETING] = false
    state[SurveyStatusStateEnum.ERROR] = error
  }
}
