import { render } from "./AnalysisWorkspace.vue?vue&type=template&id=4964b35c"
import script from "./AnalysisWorkspace.vue?vue&type=script&lang=ts"
export * from "./AnalysisWorkspace.vue?vue&type=script&lang=ts"

import "./AnalysisWorkspace.vue?vue&type=style&index=0&id=4964b35c&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4964b35c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4964b35c', script)) {
    api.reload('4964b35c', script)
  }
  
  module.hot.accept("./AnalysisWorkspace.vue?vue&type=template&id=4964b35c", () => {
    api.rerender('4964b35c', render)
  })

}

script.__file = "src/components/analysis/workspace/AnalysisWorkspace.vue"

export default script