import { render } from "./AnalysisWorkspaceItem.vue?vue&type=template&id=4710cf0f"
import script from "./AnalysisWorkspaceItem.vue?vue&type=script&lang=ts"
export * from "./AnalysisWorkspaceItem.vue?vue&type=script&lang=ts"

import "./AnalysisWorkspaceItem.vue?vue&type=style&index=0&id=4710cf0f&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4710cf0f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4710cf0f', script)) {
    api.reload('4710cf0f', script)
  }
  
  module.hot.accept("./AnalysisWorkspaceItem.vue?vue&type=template&id=4710cf0f", () => {
    api.rerender('4710cf0f', render)
  })

}

script.__file = "src/components/analysis/workspace/AnalysisWorkspaceItem.vue"

export default script