import { ActionTree } from 'vuex'

import { createUser, deleteUser, getUsers, updateUser } from '@/services/api/user'
import { UserActionEnum } from '@/store/enums/actions/user'
import { UserMutationEnum } from '@/store/enums/mutations/user'
import type { RootState } from '@/store/types'
import { UserActions } from '@/store/types/actions/user'
import { UserState } from '@/store/types/states/user'

/**
 * Actions
 */
const actions: ActionTree<UserState, RootState> & UserActions = {
  // Create user
  async [UserActionEnum.CREATE_USER]({ commit }, user) {
    commit(UserMutationEnum.CREATE_USER, undefined)
    try {
      const res = await createUser(user)
      commit(UserMutationEnum.CREATE_USER_FULFILLED, res)
      return
    } catch (err) {
      commit(UserMutationEnum.CREATE_USER_REJECTED, err)
      throw err
    }
  },
  // Get users
  async [UserActionEnum.GET_USERS]({ commit }, payload) {
    commit(UserMutationEnum.GET_USERS, undefined)
    try {
      const res = await getUsers(payload)
      commit(UserMutationEnum.GET_USERS_FULFILLED, res)
    } catch (error) {
      commit(UserMutationEnum.GET_USERS_REJECTED, error)
    }
  },
  // Update users
  async [UserActionEnum.UPDATE_USER]({ commit }, { id, user }) {
    commit(UserMutationEnum.UPDATE_USER, undefined)
    try {
      const res = await updateUser(id, user)
      commit(UserMutationEnum.UPDATE_USER_FULFILLED, res)
    } catch (err) {
      commit(UserMutationEnum.UPDATE_USER_REJECTED, err)
    }
  },
  // Delete user
  async [UserActionEnum.DELETE_USER]({ commit }, id) {
    commit(UserMutationEnum.DELETE_USER)
    deleteUser(id)
      .then(() => {
        commit(UserMutationEnum.DELETE_USER_FULFILLED, id)
      })
      .catch((error) => {
        commit(UserMutationEnum.DELETE_USER_REJECTED, error)
      })
  }
}

export { actions }
