import { repeat } from 'lodash-es'

import { StatePolarity } from './enums/StatePolarity'

export class State {
  id: string
  name: string
  description?: string
  factor?: number
  polarity?: StatePolarity

  constructor(
    id: string,
    name: string,
    description: string,
    polarity: StatePolarity,
    factor: number
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.polarity = polarity
    this.factor = factor
  }

  isNegative(): boolean {
    return this.polarity === StatePolarity.NEGATIVE
  }

  isPositive(): boolean {
    return this.polarity === StatePolarity.POSITIVE
  }

  isNonPositive(): boolean {
    return this.isNegative() || this.isNeutral()
  }

  isNeutral(): boolean {
    return this.polarity === StatePolarity.NEUTRAL
  }
}

/**
 * These are dummy states used to capture user input
 * They are not part of the real states in the network
 */

export const WORST_LIKELIHOOD = new State(
  repeat('0', 24),
  'WORST_LIKELIHOOD',
  'Dummy state for the worst likelihood',
  StatePolarity.NEGATIVE,
  0
)

export const BEST_LIKELIHOOD = new State(
  repeat('1', 24),
  'BEST_LIKELIHOOD',
  'Dummy state for the best likelihood',
  StatePolarity.POSITIVE,
  1
)
