import { AxiosError } from 'axios'

import API from '@/axios'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import { API_PAGEABLE_PARAMS, CPTSchema } from '@/types'

/**
 * Get CPT
 * @param id
 */
const getCPT = async (id: CPTSchema['id']): Promise<any> => {
  try {
    return extractResponse(await API.get(ENDPOINTS.CPT({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get survey CPTs
 * - Get all survey CPTs if 'params' is not provided
 * @param id
 * @param params
 * @param getAllParams
 */
const getSurveyCPTs = async (options: {
  surveyId: CPTSchema['surveyId']
  params?: API_PAGEABLE_PARAMS
  getAll?: boolean
}): Promise<any> => {
  try {
    const getAll = options ? options.getAll : false
    const params = options ? options.params : {}
    const { surveyId } = options
    if (getAll) {
      const requestParams = Object.assign({}, API_DEFAULT_PAGEABLE_PARAMS, params)
      return recursivePaginationAPIRequestFn(
        API,
        ENDPOINTS.SURVEY_CPTS({ id: surveyId })
      )(requestParams)
    } else {
      return extractResponse(await API.get(ENDPOINTS.SURVEY_CPTS({ id: surveyId }), { params }))
    }
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get network CPTs
 * - Get all network CPTs if 'params' is not provided
 * @param id
 */
const getNetworkCPTs = async (
  id: CPTSchema['networkId'],
  params?: API_PAGEABLE_PARAMS,
  getAllParams: API_PAGEABLE_PARAMS = API_DEFAULT_PAGEABLE_PARAMS
): Promise<any> => {
  try {
    if (params) {
      return extractResponse(await API.get(ENDPOINTS.NETWORK_CPTS({ id }), { params }))
    } else {
      const requestParams = Object.assign({}, getAllParams)
      return recursivePaginationAPIRequestFn(API, ENDPOINTS.NETWORK_CPTS({ id }))(requestParams)
    }
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Create CPT
 * @param cpt
 */
const createCPT = async (
  cpt: Pick<CPTSchema, 'networkId' | 'surveyId' | 'rows' | 'variableId'>
): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.CPTS, cpt))
  } catch (e) {
    errorHandler(e)
  }
}

const createCPTs = async (
  cpts: Pick<CPTSchema, 'networkId' | 'surveyId' | 'rows' | 'variableId'>[]
): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.CPTS_BULK, cpts))
  } catch (e) {
    errorHandler(e as AxiosError<any>)
  }
}

const deleteCPTs = async (surveyId: CPTSchema['surveyId']): Promise<any> => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.SURVEY_CPTS({ id: surveyId })))
  } catch (e) {
    errorHandler(e as AxiosError<any>)
  }
}

/**
 * Update CPT
 * @param response
 */
const updateCPT = async (cpt: CPTSchema): Promise<any> => {
  try {
    const { id } = cpt
    return extractResponse(await API.put(ENDPOINTS.CPT({ id }), cpt))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Delete CPT
 * @param id - response id
 */
const deleteCPT = async (id: CPTSchema['id']): Promise<any> => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.CPT({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

export {
  createCPT,
  createCPTs,
  getCPT,
  getSurveyCPTs,
  getNetworkCPTs,
  updateCPT,
  deleteCPT,
  deleteCPTs
}
