import ResizeObserver from 'resize-observer-polyfill'
import { onBeforeMount, onBeforeUnmount, onMounted, Ref, ref } from 'vue'

import { Variable } from '@/libs/bayes/Variable'
// import { logger } from '@/libs/logger'
import { tableScrollX, tableScrollY } from '@/libs/utils'

export default function useAntTableScroll(
  dependents: Ref<Array<Variable>> | null,
  independents: Ref<Array<Variable>> | null,
  fixedHeader = 0,
  rowHeight = 45,
  useDependents = true,
  xOffset = 0
): any {
  const resizeOb = new ResizeObserver(() => {
    onResize()
  })

  const calcScrollY = () => {
    // logger.info('calcScrollY: table height', tableWrapper?.value?.clientHeight)
    const headerOffset = fixedHeader
      ? fixedHeader
      : useDependents
      ? ((dependents ? dependents.value.length : 0) + 1) * rowHeight
      : ((independents ? independents.value.length : 0) + 1) * rowHeight
    return tableScrollY(tableWrapper.value, headerOffset)
  }

  const calcScrollX = () => {
    return tableScrollX(tableWrapper.value, xOffset)
  }

  const tableWrapper: Ref<HTMLElement | null> = ref(null)
  const scrollY: Ref<null | number> = ref(calcScrollY())
  const scrollX: Ref<null | number> = ref(calcScrollX())

  const onResize = () => {
    const y = calcScrollY()
    if (scrollY.value !== y) {
      scrollY.value = y
    }
    const x = calcScrollX()
    if (scrollX.value !== x) {
      scrollX.value = x
    }
  }

  /*
  // Disable for now, listen to DOM resize directly instead
  if (dependents) {
    watch(dependents, () => {
      logger.info('onResize from dependents')
      // onResize()
    })
  }

  if (independents) {
    watch(independents, () => {
      logger.info('onResize from independents')
      // onResize()
    })
  }
  */

  /**
   * Vue life cycle
   */
  onBeforeMount(() => {
    // window.addEventListener('resize', onResize)
  })

  onMounted(() => {
    if (tableWrapper.value) {
      resizeOb.observe(tableWrapper.value)
    }
    // onResize()
  })

  onBeforeUnmount(() => {
    resizeOb.disconnect()
    // window.removeEventListener('resize', onResize)
  })

  return {
    tableWrapper,
    scrollX,
    scrollY,
    onResize
  }
}
