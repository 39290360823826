
import { Maximize, Minimize } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { defineComponent } from 'vue'

export const EVENTS = {
  ON_TOGGLE_MAXIMIZE: 'onToggleMaximize'
}

export default defineComponent({
  name: 'ChartActions',
  components: {
    Icon,
    Maximize,
    Minimize
  },
  props: {
    chartEl: { type: Object, required: true },
    isMaximize: { type: Boolean, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const toggleMaximize = () => {
      emit(EVENTS.ON_TOGGLE_MAXIMIZE)
    }

    return {
      toggleMaximize
    }
  }
})
