<template>
  <div class="var-name-wrapper" :style="variableWidth && { width: variableWidth }">
    <div class="sz-var-name" :style="style">
      <div class="content var-symbol" @click="selectVariable()">
        <span>{{ variableTitle }}</span>
        <span v-if="!parents?.length" class="sz-terminal-flag">R</span>
        <!-- <span v-if="relation === 'parent'" class="sz-parent-flag">P</span> -->
        <span v-if="variable.isDeterministic()" class="sz-deterministic-flag">d</span>
        <span v-if="variable.isDependent()" class="sz-dep-flag">D</span>
      </div>
      <div class="edit" style="position: absolute; right: 7px; top: 2px">
        <Icon :size="14" color="#777777" @click.stop="editVariable">
          <Edit />
        </Icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Edit } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { computed, defineComponent, PropType, ref, watch } from 'vue'

import { Network, Variable } from '@/libs/bayes'
import { CSS, VARIABLE_THEME } from '@/libs/theme'

export const EVENTS = {
  SELECT: 'select',
  CHANGE: 'change',
  EDIT: 'edit',
  SELECT_ALLOCATION: 'selectionAllocation'
}

export default defineComponent({
  components: { Edit, Icon },
  props: {
    network: { type: Object as PropType<Network>, required: true },
    variable: { type: Object as PropType<Variable>, required: true },
    parents: { type: Object, required: true },
    selected: { type: Boolean, required: true },
    relation: { type: String, required: true },
    showKey: { type: Boolean, default: true },
    maxVariableWidth: { type: String, default: undefined }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const restyle = () => CSS(VARIABLE_THEME[props.relation], 0)
    const style = ref(restyle())
    const definitionVisible = ref(false)
    const definitionTitle = ref('Node definition')

    watch(
      () => props.relation,
      (): void => {
        style.value = restyle()
      }
    )

    const variableTitle = computed(() => (props.showKey ? props.variable.key : props.variable.name))

    const editVariable = () => {
      emit(EVENTS.EDIT, props.variable)
    }

    return {
      editVariable,
      variableTitle,
      definitionVisible,
      definitionTitle,
      style,
      variableWidth: '100%' || props.maxVariableWidth,
      selectVariable: () => {
        style.value = restyle()
        emit(EVENTS.SELECT, props.variable)
      }
    }
  }
})
</script>

<style scoped lang="stylus">
@import "../../components/styles/entities.styl"
.var-name-wrapper
  cursor pointer
  width 100%
  max-height 32px
  height 32px
  display block
  white-space: nowrap
  .sz-var-name
    position relative
    height 32px
    line-height 32px
    padding-left 5px
    .edit
      visibility: hidden
  .sz-var-name:hover
    .edit
        visibility: visible
</style>
