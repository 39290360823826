import { computed, ComputedRef, CSSProperties, ref } from 'vue'

export default function useMaximizer(): any {
  const isMaximize = ref(false)
  const maxStyle: ComputedRef<CSSProperties> = computed(() =>
    isMaximize.value
      ? {
          position: 'fixed',
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          'z-index': 2000,
          height: '100%',
          width: '100%',
          margin: '0',
          'background-color': 'white'
        }
      : {
          position: 'relative',
          margin: '5px 0 0 5px',
          left: 'auto',
          top: 'auto',
          right: 'auto',
          bottom: 'auto',
          'z-index': 'auto'
        }
  )

  const chartStyle: ComputedRef<CSSProperties> = computed(() =>
    isMaximize.value
      ? {
          // height: '100%',
          position: 'relative'
          // width: '100%'
        }
      : {
          // height: '100%',
          position: 'relative'
          // width: '100%'
        }
  )

  const toggle = () => {
    isMaximize.value = !isMaximize.value
  }

  return {
    isMaximize,
    toggle,
    chartStyle,
    maxStyle
  }
}
