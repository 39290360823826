<template>
  <a-modal
    class="sz-whatif-batch-import"
    title="Batch Tasks"
    :visible="isVisible"
    width="1000px"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="container">
      <div>
        <textarea
          ref="tasksTextAreaWrapper"
          style="width: 100%; height: 400px"
          :value="tasksRawValue"
          placeholder="Paste your tasks here"
          name="sz-whatif-task-raw-textarea"
          spellcheck="false"
        />
      </div>
      <div style="width: 400px; flex: 0 0 auto; padding-left: 5px">
        <textarea
          ref="tasksTextAreaWrapper2"
          style="width: 100%; height: 200px; vertical-align: top"
          :value="tasksRawValue2"
          placeholder="Multiplex network and input nodes"
          spellcheck="false"
        />
        <a-button style="text-align: right" @click="generate">Generate</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'

import { multiplexWhatIfRaw } from '@/components/analysis/composable/whatif'

// import { NodeSelection } from '../libs/common'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    networkMap: { type: Object, default: undefined }
  },
  setup(props, { emit }) {
    const tasksRawValue = ref('')
    const tasksTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const tasksRawValue2 = ref('')
    const tasksTextAreaWrapper2: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (tasksTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      tasksRawValue.value = getSelectionRaw()
      emit(EVENTS.OK, tasksRawValue.value)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    const generate = () => {
      if (!props.networkMap) {
        return
      }
      const result = multiplexWhatIfRaw(
        props.networkMap,
        (tasksTextAreaWrapper2.value as HTMLTextAreaElement)?.value || ''
      )
      if (result?.length) {
        tasksRawValue.value = result
      }
    }

    return {
      generate,
      tasksRawValue2,
      tasksTextAreaWrapper2,
      ok,
      cancel,
      tasksRawValue,
      tasksTextAreaWrapper
    }
  }
})
</script>

<style lang="stylus">
.sz-whatif-batch-import
  .container
    width: 100%
    display: flex
    flex-direction: row
    > div
      flex: 1 1 auto;
</style>
