
import { computed, defineComponent, PropType, Ref, ref, toRefs } from 'vue'

import ChartActions, {
  EVENTS as CHART_EVENTS
} from '@/components/analysis/common/chart-actions/ApexChartActions.vue'
import useChartRange from '@/components/analysis/composable/chart-range'
import useMaximizer from '@/components/composables/maximizer-apex'
import { Dict, rounder } from '@/libs/common'
import { PROB_COLOR_HIGH, PROB_COLOR_LOW } from '@/libs/theme'

export default defineComponent({
  name: 'SensitivityByInputChart',
  components: {
    ChartActions
  },
  props: {
    variationNodeKeys: {
      type: Array as PropType<string[]>,
      default: () => ['1', '2']
    },
    data: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      default: 'radar-chart'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => undefined
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const chartEl: Ref<HTMLElement | null> = ref(null)
    const { min, max } = toRefs(props)
    const { minBound, maxBound } = useChartRange(min, max)
    const ZERO_FIX = 0.0001

    const series = computed(() => {
      let datasetMin: Dict = {}
      let datasetMax: Dict = {}
      let minDatas: any = []
      let maxDatas: any = []
      props.data.forEach((r: any): any => {
        const minVal = rounder(r.stats[0][0], 4)
        const maxVal = rounder(r.stats[0][1], 4)
        let minData: any
        let maxData: any
        if (minVal < 0) {
          if (maxVal < 0) {
            minData = [minVal, maxVal]
            maxData = [ZERO_FIX, ZERO_FIX]
          } else {
            minData = [minVal, ZERO_FIX]
            maxData = [ZERO_FIX, maxVal]
          }
        } else {
          if (maxVal > 0) {
            maxData = [minVal, maxVal]
            minData = [ZERO_FIX, ZERO_FIX]
          } else {
            // minVal >= 0 and maxVal <= 0
            minData = [ZERO_FIX, ZERO_FIX]
            maxData = [ZERO_FIX, ZERO_FIX]
          }
        }
        minDatas.push({
          x: r.key,
          y: minData.map((x: any) => rounder(parseFloat(x), 4))
        })
        maxDatas.push({
          x: r.key,
          y: maxData.map((x: any) => rounder(parseFloat(x), 4))
        })
      })
      datasetMin.data = minDatas
      datasetMin.name = 'min'
      datasetMax.data = maxDatas
      datasetMax.name = 'max'
      return [datasetMin, datasetMax]
    })

    const chartOptions = computed(() => ({
      colors: [PROB_COLOR_LOW, PROB_COLOR_HIGH],
      chart: {
        height: 350,
        type: 'rangeBar',
        horizontal: false,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 2
      },
      grid: {
        row: {
          colors: ['#f3f3f3'],
          opacity: 0.5
        }
      },
      yaxis: {
        min: minBound.value,
        max: maxBound.value,
        decimalsInFloat: 4,
        forceNiceScale: false
      },
      // xaxis: {
      //   categories: props.data.map((r: any) => r.key)
      // },
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex }: any) => {
          const datapoint: any = series.value[seriesIndex].data[dataPointIndex]
          const bound: number[] = datapoint.y
          return (
            '<div>' +
            datapoint.x +
            ': ' +
            (bound[0] === ZERO_FIX ? '0' : bound[0].toFixed(4)) +
            ' - ' +
            (bound[1] === ZERO_FIX ? '0' : bound[1].toFixed(4)) +
            '</div>'
          )
        },
        y: {
          formatter: (val: number, val2: any) => {
            console.log(val, val2, typeof val)
            return val + 's'
          }
        },
        z: {
          formatter: (val: number) => {
            console.log(val, val + 's', typeof val)
            return val + 's'
          }
        }
      },
      legend: {
        show: false
      }
    }))

    return {
      CHART_EVENTS,
      chartEl,
      series,
      ...useMaximizer(),
      chartOptions
    }
  }
})
