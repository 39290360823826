import { RuleObject } from 'ant-design-vue/es/form/interface'

/**
 * Validate number value in form
 * @param {RuleObject} rule
 * @param {number} value
 */
const checkNumberInput = async (rule: RuleObject, value: number): Promise<any> => {
  if (!value && value !== 0) {
    return Promise.reject('Please enter this field')
  }
  if (!Number.isInteger(value)) {
    return Promise.reject('Please enter a number')
  } else {
    return Promise.resolve()
  }
}

/**
 * Validate file size in form field
 * @param {number} max - max file size bytes
 * @param {string} errorMessage
 */
const maxFileSize = ({
  maxSize,
  errorMessage,
  multiple
}: {
  maxSize: number
  errorMessage: string
  multiple: boolean
}) => async (rule: RuleObject, value: File[]): Promise<any> => {
  if (value && value.length > 0) {
    if (multiple) {
      for (const file of value) {
        const { size } = file
        if (size >= maxSize) {
          return Promise.reject(errorMessage)
        }
      }
    } else {
      const file = value[value.length - 1]
      const { size } = file
      if (size >= maxSize) {
        return Promise.reject(errorMessage)
      }
    }
  }
  return Promise.resolve()
}
export { checkNumberInput, maxFileSize }
