/**
 * Mutation types
 */
export enum BuilderMutationEnum {
  GET_NODE = 'GET_NODE',
  GET_NODE_FULFILLED = 'GET_NODE_FULFILLED',
  GET_NODE_REJECTED = 'GET_NODE_REJECTED',

  GET_NODES = 'GET_NODES',
  GET_NODES_FULFILLED = 'GET_NODES_FULFILLED',
  GET_NODES_REJECTED = 'GET_NODES_REJECTED',

  CREATE_NODE = 'CREATE_NODE',
  CREATE_NODE_FULFILLED = 'CREATE_NODE_FULFILLED',
  CREATE_NODE_REJECTED = 'CREATE_NODE_REJECTED',

  UPDATE_NODE = 'UPDATE_NODE',
  UPDATE_NODE_FULFILLED = 'UPDATE_NODE_FULFILLED',
  UPDATE_NODE_REJECTED = 'UPDATE_NODE_REJECTED',

  DELETE_NODE = 'DELETE_NODE',
  DELETE_NODE_FULFILLED = 'DELETE_NODE_FULFILLED',
  DELETE_NODE_REJECTED = 'DELETE_NODE_REJECTED',

  GET_EDGE = 'GET_EDGE',
  GET_EDGE_FULFILLED = 'GET_EDGE_FULFILLED',
  GET_EDGE_REJECTED = 'GET_EDGE_REJECTED',

  GET_EDGES = 'GET_EDGES',
  GET_EDGES_FULFILLED = 'GET_EDGES_FULFILLED',
  GET_EDGES_REJECTED = 'GET_EDGES_REJECTED',

  CREATE_EDGE = 'CREATE_EDGE',
  CREATE_EDGE_FULFILLED = 'CREATE_EDGE_FULFILLED',
  CREATE_EDGE_REJECTED = 'CREATE_EDGE_REJECTED',

  UPDATE_EDGE = 'UPDATE_EDGE',
  UPDATE_EDGE_FULFILLED = 'UPDATE_EDGE_FULFILLED',
  UPDATE_EDGE_REJECTED = 'UPDATE_EDGE_REJECTED',

  DELETE_EDGE = 'DELETE_EDGE',
  DELETE_EDGE_FULFILLED = 'DELETE_EDGE_FULFILLED',
  DELETE_EDGE_REJECTED = 'DELETE_EDGE_REJECTED'
}
