export const LABELS = {
  SURVEY: {
    STATE: {
      CLOSED: 'Closed',
      IN_PROGRESS: 'In Progress',
      PENDING: 'Pending'
    },
    COLLECTION_METHOD: {
      ACE_DST_SIMPLE: 'ACE - DTS w/ Best Likelihood',
      ACE_DST: 'ACE - DST w/ Full Likelihood',
      ACE: 'ACE',
      CAIN: 'Cain',
      DEFAULT: 'Default',
      FULL: 'Full (NA)',
      MARGINAL: 'Marginal',
      MIXED: 'Mixed',
      WEIGHTED: 'Weighted (NA)'
    },
    SCALE_METHOD: {
      CONTINUOUS: 'Continuous',
      PHRASE: 'Phrase'
    }
  },
  SURVEY_USER_STATUS: {
    STATUS: {
      PENDING: 'Pending',
      SENT: 'Sent',
      IN_PROGRESS: 'In Progress',
      COMPLETED: 'Completed'
    }
  }
}
