import { AnalyticsRecord, getResponseMapForVar } from '@/components/composables/analytics'
import {
  Combination,
  CombinationRow,
  Condition,
  CPT,
  CPTSummary,
  CPTSummaryOutcome,
  CPTSummaryRow,
  Network,
  State,
  Variable
} from '@/libs/bayes'
import {
  anonymizeRationale,
  complexRow,
  Dict,
  normalizeName,
  numberCell,
  simpleRow
} from '@/libs/common'
import { DefaultDict } from '@/libs/utils'
import { User } from '@/types'

import { ResponseMap } from '../CPTResponse'
import { CPTMethod } from '../enums/CPTMethod'

/**
 * A special 'CPT', which is not actually CPT
 * This is used just to capture marginal probability for a variable of interest
 * when there is no parent for that variable
 */
export class Marginal extends CPT {
  constructor(network: Network, variable: Variable, state: State, id?: string) {
    super(network, variable, state, id, CPTMethod.MARGINAL)
  }

  generateMarginalCombinations(): Array<Combination> {
    const combinations: Array<Combination> = []

    const conditions: Array<Condition> = this.variable.getAllStates()
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i]
      combinations.push([condition])
    }
    return combinations
  }

  generateElicitedRows(): void {
    if (this.parents.length) {
      console.log(
        'Marginal probabilities can only be used on terminal nodes/variable with no parents'
      )
      return
    }
    this.elicitedCombinations = this.generateMarginalCombinations()
    this.elicitedRows = this.elicitedCombinations.map(
      (combination) => new CombinationRow(combination)
    )
  }

  summarize(analyticsMap: Record<string, any>, isAnonymous = true): CPTSummary | null {
    const responseMap = getResponseMapForVar(
      analyticsMap,
      this.variable,
      this.elicitedRows || [],
      this.dependentMap
    )
    if (!this.isCompleteData(responseMap)) {
      return null
    }
    const rows: Array<CPTSummaryRow> = []
    const conditions: Array<Condition> = this.variable.getAllStates()
    const parents: Condition[] = []
    const outcomes: Array<CPTSummaryOutcome> = []
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i]
      const state = condition.state
      const key = this.makeCombKey([condition])
      let value = responseMap.get(key)?.response?.value
      const rationale = anonymizeRationale(
        responseMap.get(key)?.response?.rationale || '',
        isAnonymous
      )
      if (isNaN(value)) {
        return null
      }
      value = value / 100
      outcomes.push({
        state,
        value,
        rationale
      })
    }
    rows.push({
      parents,
      outcomes
    })
    return {
      variable: this.variable,
      rows
    } as CPTSummary
  }

  generateTabularData(responseMap: Record<string, any>): any[] {
    const data: any[] = []
    const allVarStates = this.variable.getAllStates()

    data.push(simpleRow(['Marginal']))
    data.push(
      simpleRow(['Variable', normalizeName(this.variable.name), `(${allVarStates.length} states)`])
    )
    data.push(simpleRow(['']))
    data.push(simpleRow(['State', 'Value', 'Rationale']))

    allVarStates.forEach((condition: Condition) => {
      const row: string[] = []
      const { state } = condition
      row.push(state.name)
      const key = this.makeCombKey([condition])
      const value = responseMap.get(key)?.response?.value || 0
      const rationale = responseMap.get(key)?.response?.rationale || ''
      row.push(value)
      row.push(rationale)
      data.push(simpleRow(row))
    })
    return data
  }

  generateAnalyticsTabularData(
    analyticsMap: Record<string, AnalyticsRecord>,
    users: User[],
    userMap: Dict,
    isAnonymous = false
  ): any[] {
    if (!this.elicitedRows) {
      return []
    }
    const record: AnalyticsRecord = analyticsMap[this.variable.id]
    if (!record) {
      return []
    }
    const analyticsByUserMap: DefaultDict = record.userMap
    const data: any[] = []
    const allVarStates = this.variable.getAllStates()

    data.push(simpleRow(['Marginal']))
    data.push(
      simpleRow(['Variable', normalizeName(this.variable.name), `(${allVarStates.length} states)`])
    )
    data.push(simpleRow(['']))
    data.push(simpleRow(['User Id', 'State', 'Value', 'Rationale']))

    users.forEach(({ id: userId }, userIndex: number) => {
      const userItem = analyticsByUserMap.get(userId)
      if (!userItem) {
        return
      }
      let responseMap: ResponseMap
      if (userId === 'final') {
        responseMap = this.generateResponseMapFromXAggByCombKey(record.xaggMap)
      } else {
        const userItem = analyticsByUserMap.get(userId)
        if (!userItem) {
          return
        }
        responseMap = this.generateResponseMapByCombKey(userItem.responseMap)
      }
      this.elicitedRows?.forEach(({ rowId, combination = [] }) => {
        if (!rowId) {
          return
        }
        const state = combination?.[0].state?.name
        const key = this.makeCombKey(combination)
        const response = responseMap.get(key)?.response
        if (!response) {
          return
        }
        const userId = response.userId || 'final'
        const userName = this.generateUserName(userId, userIndex, userMap, isAnonymous)
        const value = response?.value
        const rationale = anonymizeRationale(response?.rationale, isAnonymous)
        const dataRow = complexRow([userName, state, numberCell(value), rationale])
        data.push(dataRow)
      })
    })
    return data
  }
}
