
import { Edit, FileImport, Filter, Search } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

import UtilityVector from '@/components/analysis/common/UtilityVector.vue'
import VariationGenerator, {
  EVENTS as VARGEN_EVENTS
} from '@/components/analysis/common/VariationGenerator.vue'
import { NodeSelection } from '@/components/analysis/libs/common'
import { cssIcon } from '@/styles/common'

import BatchImport, { EVENTS as IMPORT_EVENTS } from './BatchImport.vue'
import { wrapper } from './styles'

interface TableConfig {
  inputVisible?: boolean
  utilityVectorVisible?: boolean
  variationVisible?: boolean
  variationsVisible?: boolean
  networkVisible?: boolean
  stateVisible?: boolean
  constraintVisible?: boolean
  allowBothInputOutput?: boolean
  headers?: Record<string, any>
}

export const EVENTS = {
  TOGGLE_INPUT: 'TOGGLE_INPUT',
  TOGGLE_OUTPUT: 'TOGGLE_OUTPUT',
  STATE_CHANGE: 'STATE_CHANGE',
  VARIATION_CHANGE: 'VARIATION_CHANGE',
  CONSTRAINT_CHANGE: 'CONSTRAINT_CHANGE',
  UTILITY_VECTOR_CHANGE: 'UTILITY_VECTOR_CHANGE',
  VARIATIONS_CHANGE: 'VARIATIONS_CHANGE'
}

const COLUMN = {
  NAME: 'name',
  NETWORK: 'network',
  DETERMINISTIC: 'isDeterministic',
  INPUT: 'input',
  OUTPUT: 'output',
  STATE: 'state',
  VARIATION: 'variation',
  CONSTRAINT: 'constraint',
  VARIATIONS_OR_UTILITY_VECTOR: 'variationsOrUtilityVector'
}

const COLUMN_DEFS: Record<string, ColumnProps> = {
  [COLUMN.NETWORK]: {
    title: 'Network',
    dataIndex: 'networkId',
    key: 'networkId',
    width: 150
  },
  [COLUMN.NAME]: {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    slots: {
      customRender: 'name',
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterSearchIcon'
    }
  },
  [COLUMN.DETERMINISTIC]: {
    dataIndex: 'isDeterministic',
    align: 'center',
    key: 'isDeterministic',
    width: 40,
    slots: {
      title: 'deterministicTitle',
      customRender: 'deterministic',
      filterIcon: 'filterIcon'
    }
  },
  [COLUMN.INPUT]: {
    dataIndex: 'input',
    align: 'center',
    key: 'input',
    width: 100,
    slots: {
      title: 'inputTitle',
      customRender: 'input',
      filterIcon: 'filterIcon'
    }
  },
  [COLUMN.OUTPUT]: {
    dataIndex: 'output',
    key: 'output',
    align: 'center',
    width: 100,
    slots: {
      title: 'outputTitle',
      customRender: 'output',
      filterIcon: 'filterIcon'
    }
  },
  [COLUMN.STATE]: {
    width: 100,
    dataIndex: 'state',
    key: 'state',
    slots: {
      title: 'stateTitle',
      customRender: 'state'
    }
  },
  [COLUMN.VARIATION]: {
    width: 100,
    dataIndex: '',
    key: 'variation',
    slots: {
      title: 'variationTitle',
      customRender: 'variation'
    }
  },
  [COLUMN.CONSTRAINT]: {
    width: 100,
    dataIndex: '',
    key: 'constraint',
    slots: {
      title: 'constraintTitle',
      customRender: 'constraint'
    }
  },
  [COLUMN.VARIATIONS_OR_UTILITY_VECTOR]: {
    dataIndex: '',
    width: 300,
    key: 'variationsOrUtilityVector',
    slots: {
      title: 'variationsOrUtilityVectorTitle',
      customRender: 'variationsOrUtilityVector'
    }
  }
}

const BOOL_FILTER = {
  TRUE: 'true',
  FALSE: 'false'
}

export default defineComponent({
  components: {
    BatchImport,
    Edit,
    FileImport,
    Filter,
    Icon,
    Search,
    UtilityVector,
    VariationGenerator
  },
  props: {
    networkMap: { type: Object, default: undefined },
    nodeSelections: { type: Object as PropType<NodeSelection[]>, required: true },
    constrained: { type: Boolean, default: true },
    config: {
      type: Object as PropType<TableConfig>,
      required: false,
      default: () => ({
        inputVisible: true,
        utilityVectorVisible: true,
        stateVisible: true,
        variationVisible: true,
        variationsVisible: false,
        constraintVisible: true,
        networkVisible: false,
        allowBothInputOutput: false,
        headers: {}
      })
    }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const searchText = ref('')
    const searchedColumn = ref('')
    const tableSearchInput = ref()
    const isVariationGeneratorVisible: Ref<boolean> = ref(false)
    const isBatchImportVisible: Ref<boolean> = ref(false)
    const selectedRecord: Ref<NodeSelection | undefined> = ref()
    // eslint-disable-next-line vue/no-setup-props-destructure
    const headers = props.config?.headers
    const inputNodeDesc = headers?.input?.desc || 'Input node'
    const outputNodeDesc = headers?.output?.desc || 'Output node'
    const inputNodeTitle = headers?.input?.title || 'Input'
    const outputNodeTitle = headers?.output?.title || 'Output'

    const columns = computed(() => {
      let cols: ColumnProps[] = []

      if (props.config.networkVisible) {
        cols.push({
          ...COLUMN_DEFS[COLUMN.NETWORK],
          sorter: (a: NodeSelection, b: NodeSelection) => {
            if (props.networkMap && a.networkId && b.networkId) {
              return props.networkMap[a.networkId].localeCompare(props.networkMap[b.networkId])
            }
            return 0
          }
        })
      }

      cols = cols.concat([
        {
          ...COLUMN_DEFS[COLUMN.NAME],
          sorter: (a: NodeSelection, b: NodeSelection) => a.name.localeCompare(b.name),
          onFilter: (value: any, record: NodeSelection) =>
            record.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
              setTimeout(() => {
                tableSearchInput.value.focus()
              }, 0)
            }
          }
        },
        {
          ...COLUMN_DEFS[COLUMN.DETERMINISTIC],
          filters: [
            { text: 'Deterministic', value: BOOL_FILTER.TRUE, children: [] },
            { text: 'Chance', value: BOOL_FILTER.FALSE, children: [] }
          ],
          defaultFilteredValue: [BOOL_FILTER.TRUE, BOOL_FILTER.FALSE],
          onFilter: (value: string, record: NodeSelection) => {
            const isDeterministic = record.isDeterministic
            if (!isDeterministic) {
              return value === BOOL_FILTER.FALSE
            } else {
              return isDeterministic.toString() === value
            }
          }
        }
      ])

      if (props.config.inputVisible) {
        cols.push({
          ...COLUMN_DEFS[COLUMN.INPUT],
          filters: [{ text: 'Input nodes only', value: BOOL_FILTER.TRUE, children: [] }],
          defaultFilteredValue: [],
          onFilter: (value: string, record: NodeSelection) => {
            if (value !== BOOL_FILTER.TRUE) {
              return true
            }
            return record.isInput
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sorter: (a: NodeSelection, _b: NodeSelection) => a.isInput,
          sortDirections: ['descend']
        })
      }
      cols.push({
        ...COLUMN_DEFS[COLUMN.OUTPUT],
        filters: [{ text: 'Output nodes only', value: BOOL_FILTER.TRUE, children: [] }],
        defaultFilteredValue: [],
        onFilter: (value: string, record: NodeSelection) => {
          if (value !== BOOL_FILTER.TRUE) {
            return true
          }
          return record.isOutput
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sorter: (a: NodeSelection, _b: NodeSelection) => a.isOutput,
        sortDirections: ['descend']
      })
      if (props.config.stateVisible) {
        cols.push(COLUMN_DEFS[COLUMN.STATE])
      }
      if (props.config.variationVisible) {
        cols.push(COLUMN_DEFS[COLUMN.VARIATION])
      }
      if (props.config.constraintVisible) {
        cols.push(COLUMN_DEFS[COLUMN.CONSTRAINT])
      }
      if (props.config.utilityVectorVisible) {
        cols.push(COLUMN_DEFS[COLUMN.VARIATIONS_OR_UTILITY_VECTOR])
      }
      return cols
    })

    const data = computed(() => {
      return props.nodeSelections
    })

    const toggleInput = (_key: string, state: boolean, record: any) => {
      if (!props.config.allowBothInputOutput) {
        if (state) {
          record.isOutput = false
        }
      }
      emit(EVENTS.TOGGLE_INPUT, record)
    }
    const toggleOutput = (_key: string, state: boolean, record: any) => {
      if (!props.config.allowBothInputOutput) {
        if (state) {
          record.isInput = false
        }
      }
      emit(EVENTS.TOGGLE_OUTPUT, record)
    }
    const onStateChange = (key: string) => {
      emit(EVENTS.STATE_CHANGE, key)
    }

    const onVariationChange = (record: any) => {
      emit(EVENTS.VARIATION_CHANGE, record)
    }

    const onConstraintChange = (record: any) => {
      emit(EVENTS.CONSTRAINT_CHANGE, record)
    }

    const onUtilityVectorChange = (key: string) => {
      emit(EVENTS.UTILITY_VECTOR_CHANGE, key)
    }

    const editVariations = (record: any) => {
      selectedRecord.value = record
      isVariationGeneratorVisible.value = !isVariationGeneratorVisible.value
    }

    const onVariationsChange = (record: NodeSelection) => {
      emit(EVENTS.VARIATIONS_CHANGE, record)
    }

    const onVarGenOK = (record: NodeSelection, { variations }: any) => {
      isVariationGeneratorVisible.value = false
      record.variations = variations
      record.variationsText = variations?.join(',')
      emit(EVENTS.VARIATIONS_CHANGE, record)
    }

    const onVarGenCancel = () => {
      isVariationGeneratorVisible.value = false
    }

    /**
     * Handle table search
     **/
    const handleSearch = (selectedKeys: Array<any>, confirm: any, dataIndex: string) => {
      confirm()
      searchText.value = selectedKeys[0]
      searchedColumn.value = dataIndex
    }

    /**
     * Handle reset table search
     **/
    const handleReset = (clearFilters: any) => {
      clearFilters()
      searchText.value = ''
    }

    const importBatch = () => {
      isBatchImportVisible.value = true
    }

    return {
      importBatch,
      cssIcon,
      inputNodeTitle,
      outputNodeTitle,
      inputNodeDesc,
      outputNodeDesc,
      selectedRecord,
      VARGEN_EVENTS,
      columns,
      isBatchImportVisible,
      data,
      editVariations,
      handleReset,
      handleSearch,
      isVariationGeneratorVisible,
      onConstraintChange,
      onStateChange,
      onUtilityVectorChange,
      onVariationChange,
      onVariationsChange,
      onVarGenOK,
      onVarGenCancel,
      tableSearchInput,
      toggleInput,
      toggleOutput,
      wrapper,
      IMPORT_EVENTS
    }
  }
})
