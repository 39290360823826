
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons-vue'
import { IconCirclesRelation } from '@tabler/icons-vue'
import { ChartDots, RelationManyToMany, ZoomQuestion } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed, defineComponent, toRefs } from 'vue'

import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { vuexActions } from '@/store/util'
dayjs.extend(relativeTime)
import { message } from 'ant-design-vue'

import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { gradientMaker } from '@/libs/utils'

export const EVENTS = {
  CLICK_CARD: EMIT_EVENTS.WORKSPACE.CLICK_WORKSPACE_CARD,
  DELETE_WORKSPACE: 'DELETE_WORKSPACE'
}

export default defineComponent({
  components: {
    RelationManyToMany,
    ChartDots,
    DeleteTwoTone,
    EditTwoTone,
    Icon,
    ZoomQuestion,
    IconCirclesRelation
  },
  props: {
    workspace: {
      type: Object,
      required: true
    }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const store = useStore()
    const { workspace } = toRefs(props)
    const workspaceId = workspace.value?.id
    const created = computed(() => dayjs(workspace.value.createdDate).fromNow())
    const modified = computed(() => dayjs(workspace.value.lastModifiedDate).fromNow())

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(props.workspace?.name || 'UNTITLED')
    }))

    /**
     * Delete workspace
     */
    const deleteWorkspace = async () => {
      await store.dispatch(
        vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.DELETE_WORKSPACE),
        workspaceId
      )
      emit(EVENTS.DELETE_WORKSPACE)
      message.success(MESSAGE.WORKSPACE_DELETE_SUCCESS)
    }

    /**
     * Click card
     **/
    const handleClickEditButton = () => {
      emit(EVENTS.CLICK_CARD, props.workspace)
    }

    return {
      ROUTE_NAME,
      created,
      modified,
      deleteWorkspace,
      handleClickEditButton,
      workspaceId,
      cardStyle
    }
  }
})
