import { any, isNil } from 'ramda'
import { computed, reactive, Ref } from 'vue'

// import { Combination, CombinationRow } from '@/libs/bayes/Combination'
import { Combination, CombinationRow, CPT, CPTSet, Variable } from '@/libs/bayes'
import { ResponseMap, ResponseUnit } from '@/libs/bayes/CPTResponse'
import { ResponseSchema } from '@/types'

export const COMBKEY_PREFIX = 'v+s'

export const generateResponseMap = (
  cpt: CPT,
  responses: Array<ResponseSchema>,
  rows: Array<CombinationRow>
): ResponseMap => {
  const map: ResponseMap = new Map()
  rows.forEach(({ rowId, combination: combination = [] }) => {
    const combKey = cpt.makeCombKey(combination)
    const response = responses.find(
      (response: ResponseSchema) => response && response?.rowId === rowId
    )
    map.set(combKey, { rowId, combKey, combination, response })
  })
  return map
}

export const generateTabularData = (
  variables: Variable[],
  cptSet: CPTSet,
  responses: ResponseSchema[]
): { datas: any; sections: any } => {
  const sections: string[] = []
  const datas: any[] = []
  variables.forEach((variable: Variable) => {
    const cpt = cptSet.getCPT(variable)
    if (!cpt || !cpt.elicitedRows) {
      return
    }
    const rMap: ResponseMap = generateResponseMap(cpt, responses, cpt.elicitedRows)
    datas.push(cpt.generateTabularData(rMap))
    sections.push(variable.name)
  })
  return {
    datas,
    sections
  }
}

export const genCombKey = (combination: Combination, prefix: string = COMBKEY_PREFIX): string => {
  let key = prefix
  combination.forEach(({ variable, state }) => {
    key += `-${variable.id}+${state.id}`
  })
  return key
}

export default function useResponses(
  responses: Ref<Array<ResponseSchema>>,
  combinations: Ref<Array<Combination>>,
  rows: Ref<Array<CombinationRow>>,
  cpt: Ref<CPT>,
  depMap: Ref<Map<any, any>>,
  type = 0
): any {
  const validMap: Map<string, boolean> = reactive(new Map())
  const isValid = computed(() => {
    const valids = Array.from(validMap.values())
    return !any((valid) => valid === false)(valids)
  })

  const tableRows = computed(() => {
    return cpt.value.tableRows
  })

  const combi2Key = (combination: Combination) => {
    const depKey: Array<Array<string>> = []
    const indepKey: Array<Array<string>> = []

    combination.forEach(({ variable, state }) => {
      if (depMap.value.has(variable.id)) {
        depKey.push([variable.id, state.id])
      } else {
        indepKey.push([variable.id, state.id])
      }
    })
    return (
      COMBKEY_PREFIX +
      (depKey.length + indepKey.length > 0 ? '-' : '') +
      depKey
        .concat(indepKey)
        .map((k) => k.join('+'))
        .join('-')
    )
  }

  const responseMap = computed(() => {
    const map: ResponseMap = new Map()
    validMap.clear()
    rows.value.forEach(({ rowId, combination: combination = [] }) => {
      const combKey = combi2Key(combination)
      const response = responses.value.find(
        (response: ResponseSchema) => response && response?.rowId === rowId
      )

      let valid = false
      if (response) {
        valid = !isNil(response.value) || !isNil(response?.ext?.probabilityConfig)
      }
      validMap.set(combKey, valid)
      map.set(combKey, { rowId, combKey, combination, response })
    })
    return map as ResponseMap
  })

  const onResponseChange = ({
    response,
    combKey,
    valid
  }: {
    response: ResponseSchema
    combKey: string
    valid: boolean
  }) => {
    validMap.set(combKey, valid)
    const responseUnit = responseMap.value.get(combKey)
    if (!responseUnit) {
      return
    }
    const comboRow: ResponseUnit = { ...responseUnit }
    comboRow.response = Object.assign({ ...responseUnit.response }, response)
    responseMap.value.set(combKey, comboRow)
  }

  const getResponses = () => {
    return Array.from(responseMap.value.values())
      .filter((value) => value.response?.rowId && value.response?.variableId)
      .map(({ response }) => {
        if (response) {
          response.type = type // no longer use, initially intended for CE
        }
        return response
      })
  }

  const genResponsesPayload = (allowPartialSave: boolean | undefined): any => {
    if (allowPartialSave) {
      return { responses: getResponses() }
    }
    return isValid.value ? { responses: getResponses() } : undefined
  }

  return {
    COMBKEY_PREFIX,
    isValid,
    validMap,
    responseMap,
    tableRows,
    onResponseChange,
    getResponses,
    genResponsesPayload
  }
}
