import { render } from "./HybridWorkspaceNext.vue?vue&type=template&id=4f7181e8"
import script from "./HybridWorkspaceNext.vue?vue&type=script&lang=ts"
export * from "./HybridWorkspaceNext.vue?vue&type=script&lang=ts"

import "./HybridWorkspaceNext.vue?vue&type=style&index=0&id=4f7181e8&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f7181e8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f7181e8', script)) {
    api.reload('4f7181e8', script)
  }
  
  module.hot.accept("./HybridWorkspaceNext.vue?vue&type=template&id=4f7181e8", () => {
    api.rerender('4f7181e8', render)
  })

}

script.__file = "src/components/analysis/hybrid/HybridWorkspaceNext.vue"

export default script