export const DISTRIBUTION_TYPE = {
  UNIFORM: 'uniform',
  RANGE: 'range',
  NORMAL: 'normal',
  FIXED: 'fixed',
  TRIANGULAR: 'triangular',
  NONE: 'none'
}

export const DISTRIBUTIONS = [
  {
    id: DISTRIBUTION_TYPE.UNIFORM,
    label: 'Uniform',
    parameters: [
      {
        id: 'a',
        label: 'A',
        default: 25
      },
      {
        id: 'b',
        label: 'B',
        default: 75
      }
    ]
  },
  {
    id: DISTRIBUTION_TYPE.RANGE,
    label: 'Range',
    parameters: [
      {
        id: 'c',
        label: 'C',
        default: 50
      },
      {
        id: 'delta',
        label: 'Delta',
        default: 10
      }
    ]
  },
  {
    id: DISTRIBUTION_TYPE.NORMAL,
    label: 'Normal',
    parameters: [
      {
        id: 'mean',
        label: 'Mean',
        default: 50
      },
      {
        id: 'variance',
        label: 'Variance',
        default: 0.5
      }
    ]
  },
  {
    id: DISTRIBUTION_TYPE.FIXED,
    label: 'Fixed',
    parameters: [
      {
        id: 'c',
        label: 'C',
        default: 50
      }
    ]
  },
  {
    id: DISTRIBUTION_TYPE.TRIANGULAR,
    label: 'Triangular',
    parameters: [
      {
        id: 'a',
        label: 'A',
        default: 0
      },
      {
        id: 'b',
        label: 'B',
        default: 100
      },
      {
        id: 'c',
        label: 'C',
        default: 50
      }
    ]
  },
  {
    id: DISTRIBUTION_TYPE.NONE,
    label: 'None',
    parameters: []
  }
]

export const DISTRIBUTION_MAP = DISTRIBUTIONS.reduce((acc, d) => ({ ...acc, [d.id]: d }), {})

export const DISTRIBUTION_FORM_DATA: Record<string, Record<string, number>> = DISTRIBUTIONS.reduce(
  (acc, d) => ({
    ...acc,
    [d.id]: d.parameters.reduce((fields, f) => ({ ...fields, [f.id]: f.default }), {})
  }),
  {}
)
