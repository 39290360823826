import { Condition } from './Condition'
import { CPTType } from './enums/CPTType'

export type Combination = Array<Condition>
export type DEP_COMB_KEY = string

export class CombinationRow {
  combination: Combination
  type: CPTType
  rowId?: string
  prefix: string

  constructor(
    combination: Combination,
    type: CPTType = CPTType.ELICITED,
    rowId?: string,
    prefix = 'v+s'
  ) {
    this.combination = combination
    this.type = type
    this.rowId = rowId
    this.prefix = prefix
  }

  get key(): string {
    const comb = this.combination

    return (
      this.prefix +
      (comb.length > 0 ? '-' : '') +
      comb.map(({ variable, state }: Condition) => `${variable.id}+${state.id}`).join('-')
    )
  }
}
