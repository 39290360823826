import { Cursor, SurveySchema } from '@/types'

/**
 * Check survey form submitted value type, used for POST and PUT
 */
type SurveyForm = Pick<
  SurveySchema,
  'name' | 'state' | 'collectionMethod' | 'scale' | 'ext' | 'workspaceId' | 'surrogate'
>

type SurveyList = Cursor<SurveySchema>

enum SurveyExportFormatEnum {
  JSON = 'JSON',
  CSV = 'CSV'
}

type SurveyExportFormatJSON = `${SurveyExportFormatEnum}`

interface SurveyExportReqBodyJSON {
  format: SurveyExportFormatEnum
  userIds: Array<string>
  variableIds: Array<string>
}

export {
  SurveyList,
  SurveyForm,
  SurveyExportFormatEnum,
  SurveyExportReqBodyJSON,
  SurveyExportFormatJSON
}
