import { render } from "./ActionButton.vue?vue&type=template&id=5fd84a28"
import script from "./ActionButton.vue?vue&type=script&lang=ts"
export * from "./ActionButton.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5fd84a28"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5fd84a28', script)) {
    api.reload('5fd84a28', script)
  }
  
  module.hot.accept("./ActionButton.vue?vue&type=template&id=5fd84a28", () => {
    api.rerender('5fd84a28', render)
  })

}

script.__file = "src/components/common/ActionButton.vue"

export default script