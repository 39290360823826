import { render } from "./VariableNameCol.vue?vue&type=template&id=52ad6836&scoped=true"
import script from "./VariableNameCol.vue?vue&type=script&lang=ts"
export * from "./VariableNameCol.vue?vue&type=script&lang=ts"

import "./VariableNameCol.vue?vue&type=style&index=0&id=52ad6836&scoped=true&lang=stylus"
script.render = render
script.__scopeId = "data-v-52ad6836"
/* hot reload */
if (module.hot) {
  script.__hmrId = "52ad6836"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('52ad6836', script)) {
    api.reload('52ad6836', script)
  }
  
  module.hot.accept("./VariableNameCol.vue?vue&type=template&id=52ad6836&scoped=true", () => {
    api.rerender('52ad6836', render)
  })

}

script.__file = "src/components/variable/VariableNameCol.vue"

export default script