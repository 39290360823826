import { render } from "./RichTextEditor.vue?vue&type=template&id=7ae0350b&scoped=true"
import script from "./RichTextEditor.vue?vue&type=script&lang=ts"
export * from "./RichTextEditor.vue?vue&type=script&lang=ts"

import "./RichTextEditor.vue?vue&type=style&index=0&id=7ae0350b&lang=stylus&scoped=true"
script.render = render
script.__scopeId = "data-v-7ae0350b"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7ae0350b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7ae0350b', script)) {
    api.reload('7ae0350b', script)
  }
  
  module.hot.accept("./RichTextEditor.vue?vue&type=template&id=7ae0350b&scoped=true", () => {
    api.rerender('7ae0350b', render)
  })

}

script.__file = "src/components/survey/RichTextEditor.vue"

export default script