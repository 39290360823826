import axios, { AxiosRequestConfig } from 'axios'

import API from '@/axios'
import { API_PREFIX, ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'
import { LoginFormValue } from '@/types/api/auth'

/**
 * Submit login data to server
 * @param payload
 */
const login = async (payload: Pick<LoginFormValue, 'username' | 'password'>): Promise<any> => {
  return extractResponse(await API.post(ENDPOINTS.AUTH_LOGIN, payload))
}

/**
 * Get token and survey user status by using survey key
 * @param surveyKey
 */
const surveyKeyLogin = async (surveyKey: string): Promise<any> => {
  try {
    return extractResponse(
      await API.get(ENDPOINTS.AUTH_SURVEY_KEY_LOGIN, {
        params: { surveyKey }
      })
    )
  } catch (e) {
    errorHandler(e)
  }
}

export const ahpSurveyKeyLogin = async (surveyKey: string): Promise<any> => {
  try {
    return extractResponse(
      await API.get(ENDPOINTS.AUTH_AHP_SURVEY_KEY_LOGIN, {
        params: { surveyKey }
      })
    )
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Use refresh token to get a new token
 * @param {Object} options
 */
const authRefresh = async (options: AxiosRequestConfig): Promise<any> => {
  // Create an instance to avoid intercepting 401 error.
  // Otherwise, this will be in infinite loops if an invalid (or non-existing) refresh token is provided.
  const axiosNoInterceptors = axios.create({ baseURL: API_PREFIX })
  return extractResponse(await axiosNoInterceptors.get(ENDPOINTS.AUTH_REFRESH, options))
}

/**
 * Get current logged in user
 */
const authCurrent = async (): Promise<any> => {
  try {
    return extractResponse(await API.get(ENDPOINTS.AUTH_CURRENT))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Logout
 */
const logout = async (): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.AUTH_LOGOUT))
  } catch (e) {
    errorHandler(e)
  }
}

export { login, surveyKeyLogin, authRefresh, authCurrent, logout }
