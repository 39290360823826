import { render } from "./BoxPlot.vue?vue&type=template&id=a9a92938"
import script from "./BoxPlot.vue?vue&type=script&lang=ts"
export * from "./BoxPlot.vue?vue&type=script&lang=ts"

import "./BoxPlot.vue?vue&type=style&index=0&id=a9a92938&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "a9a92938"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a9a92938', script)) {
    api.reload('a9a92938', script)
  }
  
  module.hot.accept("./BoxPlot.vue?vue&type=template&id=a9a92938", () => {
    api.rerender('a9a92938', render)
  })

}

script.__file = "src/components/viz/BoxPlot.vue"

export default script