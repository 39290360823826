
import { computed, defineComponent, PropType } from 'vue'

import useAntTableScroll from '@/components/composables/antTableScroll'
import { Condition } from '@/libs/bayes/Condition'
import { CPT } from '@/libs/bayes/methods/CPT'
import { Variable } from '@/libs/bayes/Variable'
import { combinationOf } from '@/libs/common'
import { getStateStyle } from '@/libs/utils'

export default defineComponent({
  props: {
    cpt: { type: Object as PropType<CPT>, required: true },
    selectedVariable: { type: Object, required: true },
    cptValues: { type: Array as PropType<Array<Array<number>>>, required: true }
  },
  setup(props) {
    const independents = computed(() => props.cpt.independents)
    const permutation = computed(() => combinationOf(independents.value))
    const childStates = computed(() => props.selectedVariable.getAllStates())
    const { tableWrapper, scrollY } = useAntTableScroll(null, null, 177)

    const columns = computed(() => {
      const cols: Array<any> = []
      if (independents.value.length) {
        independents.value.forEach((variable: Variable) => {
          cols.push({
            title: variable.name,
            key: variable.id,
            dataIndex: variable.id,
            align: 'center',
            slots: {
              customRender: 'indep-col'
            },
            width: 80,
            customCell: () => {
              return {
                class: 'sz-indep-cell'
              }
            },
            customHeaderCell: () => {
              return {
                class: 'sz-indep-col'
              }
            }
          })
        })
      }

      const leaves = childStates.value.map(({ state }: Condition) => {
        return {
          title: state.name,
          key: state.id,
          dataIndex: state.id,
          polarity: state.polarity,
          slots: {
            customRender: 'value'
          },
          width: 100,
          customHeaderCell: (col: any) => {
            return {
              style: {
                'text-align': 'center',
                ...getStateStyle(col.polarity)
              }
            }
          }
        }
      })

      return cols.concat([
        {
          title: '',
          key: 'filler',
          customHeaderCell: () => {
            return {
              style: {
                height: '100px'
              }
            }
          },
          children: [
            {
              title: props.selectedVariable.name + ' (child)',
              key: 'selectedVar',
              children: leaves
            }
          ]
        }
      ])
    })

    const data = computed(() => {
      const rows: Array<any> = []
      if (!props.cptValues) {
        return rows
      }
      permutation.value.forEach((stateIndices: Array<number>, permIndex: number) => {
        const row: Record<string, any> = {}
        stateIndices.forEach((stateIndex: number, varIndex: number) => {
          const variable = independents.value[varIndex]
          const state = variable.getState(stateIndex)
          row[variable.id] = state
        })
        childStates.value.forEach(({ state }: Condition, childStateIndex: number) => {
          row[state.id] = props.cptValues[permIndex][childStateIndex]
        })
        rows.push(row)
      })

      return rows
    })

    return {
      getStateStyle,
      columns,
      data,
      tableWrapper,
      scrollY
    }
  }
})
