// FIXME: maybe we need to remove this because we can use ROUTE_NAME to push url or pass params
const PATH = {
  HOME: '/',
  LOGIN: '/login',
  ACCOUNT: '/account',
  // Workspace
  ALLOCATION_DEPRECATED: '/workspaces/:workspaceId/allocations-depracated',
  ALLOCATION: '/workspaces/:workspaceId/allocations',
  PAGE: '/workspaces/:workspaceId/page',
  ANALYSIS: '/analysis',
  ANALYSIS_WORKSPACES: '/analysis/workspaces',
  ANALYSIS_WORKSPACE: '/analysis/workspaces/:workspaceId',
  ANALYSIS_CAUSEEFFECT: '/analysis/workspaces/:workspaceId/cause-effect',
  ANALYSIS_HYBRID: '/analysis/workspaces/:workspaceId/hybrid',
  ANALYSIS_WHATIF: '/analysis/workspaces/:workspaceId/whatif',
  ANALYSIS_SENSITIVITY: '/analysis/workspaces/:workspaceId/sensitivity',
  ANALYSIS_OPTION_DESIGN: '/analysis/workspaces/:workspaceId/option-design',
  WORKSPACES: '/workspaces',
  WORKSPACE: '/workspaces/:workspaceId',
  // Workspace - network
  NETWORK: '/workspaces/:workspaceId/network',
  NETWORK_VARIABLES: '/workspaces/:workspaceId/network-variables',
  // Workspace - survey
  SURVEYS_NEW: '/workspaces/:workspaceId/surveys/new',
  SURVEYS: '/workspaces/:workspaceId/surveys',
  SURVEY: '/workspaces/:workspaceId/surveys/:surveyId',
  SURVEY_STATUSES: '/workspaces/:workspaceId/surveys/:surveyId/status',
  SURVEY_SUMMARY: '/workspaces/:workspaceId/surveys/:surveyId/summary',
  SURVEY_ANALYTICS: '/workspaces/:workspaceId/surveys/:surveyId/analytics',
  SURVEY_STOCHASTICS: '/workspaces/:workspaceId/surveys/:surveyId/stochastics',
  // Workspace - survey - questionnaire
  GO_QUESTIONNAIRE: '/go/workspaces/:workspaceId/surveys/:surveyId',
  QUESTIONNAIRE: '/workspaces/:workspaceId/surveys/:surveyId/questionnaire',
  USERS: '/users',
  BUILDER: '/workspaces/:workspaceId/builder',
  MANUAL: '/manual',
  GO_AHP: '/go/workspaces/:workspaceId/ahp',
  AHP: '/workspaces/:workspaceId/ahp'
}

/**
 * Name of the path, when you want to pass params, you must use name instead of path url
 */
const ROUTE_NAME = {
  ACCOUNT: 'ACCOUNT',
  ALLOCATION: 'ALLOCATION',
  ALLOCATION_DEPRECATED: 'ALLOCATION_DEPRECATED',
  BUILDER: 'BUILDER',
  ANALYSIS: 'ANALYSIS',
  ANALYSIS_WORKSPACES: 'ANALYSIS_WORKSPACES',
  ANALYSIS_WORKSPACE: 'ANALYSIS_WORKSPACE',
  ANALYSIS_CAUSEEFFECT: 'ANALYSIS_CAUSEEFFECT',
  ANALYSIS_WHATIF: 'ANALYSIS_WHATIF',
  ANALYSIS_HYBRID: 'ANALYSIS_HYBRID',
  ANALYSIS_SENSITIVITY: 'ANALYSIS_SENSITIVITY',
  ANALYSIS_OPTION_DESIGN: 'ANALYSIS_OPTION_DESIGN',
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  MANUAL: 'MANUAL',
  NETWORK: 'NETWORK',
  NETWORK_VARIABLES: 'NETWORK_VARIABLES',
  PAGE: 'PAGE',
  GO_QUESTIONNAIRE: 'GO_QUESTIONNAIRE',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  GO_AHP: 'GO_AHP',
  AHP: 'AHP',
  SURVEY: 'SURVEY',
  SURVEY_STATUSES: 'SURVEY_STATUSES',
  SURVEY_SUMMARY: 'SURVEY_SUMMARY',
  SURVEY_ANALYTICS: 'SURVEY_ANALYTICS',
  SURVEY_STOCHASTICS: 'SURVEY_STOCHASTICS',
  SURVEYS: 'SURVEYS',
  SURVEYS_NEW: 'SURVEYS_NEW',
  USERS: 'USERS',
  WORKSPACE: 'WORKSPACE',
  WORKSPACES: 'WORKSPACES'
} as const

export { PATH, ROUTE_NAME }
