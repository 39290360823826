/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { cloneDeep } from 'lodash-es'
import { addIndex, append, last, map, range, reduce, reverse, takeLast, toLower } from 'ramda'

import { CSS, STATE_THEME } from '@/libs/theme'
import { Option, User } from '@/types'

/**
 * Mongo ObjectID generator
 *
 * @param HEX number Base for the ID generation
 * @param f function Injected hex conversion
 * @returns string Mongo ObjectID
 */
const objectId = (HEX = 16, f = (n: number) => Math.floor(n).toString(HEX)): string =>
  f(Date.now() / 1000) + '.'.repeat(HEX).replace(/./g, () => f(Math.random() * HEX))

const cut = 1 // how many characters/digits need to be mapped into gradient
const ratios = reduce((r: number[]) => append((last(r) || 0) / 1.618, r), [1], range(0, cut))
const cummul = reduce((c: number[], x) => append((last(c) || 0) + x, c), [0], ratios)

const gradientMaker = (label: string): string => {
  const weight = 0.8 // character gets more weight
  let letters = []
  let numbers = []
  label = toLower(label.trim())
  let k = label.length
  while (k--) {
    const ch = label.charCodeAt(k)
    if (ch === 32) {
      break
    } else if (ch >= 97 && ch <= 122) {
      letters.push(ch - 97)
    } else if (ch >= 48 && ch <= 57) {
      numbers.push(ch - 48)
    }
  }
  letters = map((x) => (360 * x) / 26, reverse(takeLast(cut, letters)))
  numbers = map((x) => 36 * x, reverse(takeLast(cut, numbers)))
  let base: any
  let percentages: any
  const reduceIndex = addIndex(reduce)
  const spectrum = letters.length && numbers.length ? weight : 1
  const reducer = (group: any) => (p: any, h: any, i: any) =>
    append(base + (cummul[i] * spectrum) / cummul[group.length], p)
  base = 0
  percentages = reduceIndex(reducer(letters), [], letters)
  base = spectrum === weight ? weight : 0
  percentages = reduceIndex(reducer(numbers), percentages, numbers)
  const stops = reduceIndex(
    (rects, hue, i) =>
      rects +
      `<stop offset="${100 * percentages[i].toFixed(2)}%"  stop-color="hsl(${hue}, 60%, 82%)" />`,
    '',
    letters.concat(numbers)
  )
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" width="100%" height="100%">
  <defs>
    <linearGradient id="grads">
      ${stops}
    </linearGradient>
  </defs>
  <rect x="0" y="0" width="100" height="100" fill="url('#grads')"/></svg>`
  return `url('data:image/svg+xml;base64,${window.btoa(svg)}')`
}

const tableScrollY = (containerEl: HTMLElement | null, offset: number): number | null => {
  if (containerEl) {
    const styles = window.getComputedStyle(containerEl)
    const paddingTop = Number(styles.paddingTop.replace('px', '')) || 0
    const paddingBottom = Number(styles.paddingBottom.replace('px', '')) || 0
    let scroll = containerEl.clientHeight - offset - paddingTop - paddingBottom
    if (scroll < 0) {
      scroll = 0
    }
    return scroll
  }
  return null
}

const tableScrollX = (containerEl: HTMLElement | null, offset: number): number | null => {
  if (containerEl) {
    const styles = window.getComputedStyle(containerEl)
    const paddingLeft = Number(styles.paddingLeft.replace('px', '')) || 0
    const paddingRight = Number(styles.paddingRight.replace('px', '')) || 0
    let scroll = containerEl.clientWidth - offset - paddingLeft - paddingRight
    if (scroll < 0) {
      scroll = 0
    }
    return scroll
  }
  return null
}

const getBase64 = async (img: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.addEventListener('error', reject)
    reader.readAsDataURL(img)
  })
}
/**
 * Replace special character in query string
 * @param queryString
 */
const fixEncodedQueryString = (queryString: string): string => {
  return queryString.replaceAll(' ', '+').replaceAll('=', '%3D')
}

/**
 * Sync scrolling
 * @param containerId
 */
const SCROLL_EVENT = 'scroll'
const leftListener: Record<string, any> = {}
const rightListener: Record<string, any> = {}
const killScrollListener = (el: EventTarget, listener: EventListener) => {
  if (listener) {
    try {
      el.removeEventListener(SCROLL_EVENT, listener)
    } catch (e) {
      // pass
    }
  }
}

const syncScrolls = (containerId: string): void => {
  const container = document.getElementById(containerId)
  if (!container) {
    return
  }
  const left = container.querySelector('.ice-left')
  const right = container.querySelector('.ice-right')
  if (!left || !right) {
    return
  }
  killScrollListener(left, leftListener[containerId])
  leftListener[containerId] = (e: Event) => {
    const { scrollTop } = e.target as HTMLDivElement
    if (right) {
      right.scrollTop = scrollTop
    }
  }
  left.addEventListener(SCROLL_EVENT, leftListener[containerId])
  killScrollListener(right, rightListener[containerId])
  rightListener[containerId] = (e: Event) => {
    const { scrollTop } = e.target as HTMLDivElement
    if (left) {
      left.scrollTop = scrollTop
    }
  }
  right.addEventListener(SCROLL_EVENT, rightListener[containerId])
}

/**
 * Convert the input into the trimmed upper-underscore case (i.e., UPPER_UNDERSCORE).
 * @param {string} input
 * @returns {string|null}
 */
const toUpperUnderscore = (input: string | null | undefined): string | null => {
  const regex = new RegExp('\\s+', 'g')
  return input ? input.trim().replaceAll(regex, '_').toUpperCase() : null
}

/**
 * Generate expertise options for the selection component
 * @param {Array<string>} expertise
 * @param {string \ null \ undefined} searchText
 * @returns {Option[]}
 */
const genExpertiseOptions = (
  expertise: User['expertise'],
  searchText?: string | null
): Option[] => {
  let options: Option[] = []
  if (expertise !== null) {
    options = expertise.map((el) => ({
      key: el,
      label: el,
      value: el
    }))
  }
  const formattedExpertise = toUpperUnderscore(searchText)
  if (formattedExpertise && searchText) {
    const idx = options.findIndex((option) => option.label === formattedExpertise)
    if (idx === -1) {
      options.unshift({
        key: searchText,
        label: formattedExpertise,
        value: formattedExpertise
      })
    }
  }
  return options
}

/**
 * Filter out duplicated and unmatched options
 * @param {string} inputValue
 * @returns {boolean}
 */
const filterExpertiseOption = (inputValue: string, option: Option): boolean => {
  const formattedExpertise = toUpperUnderscore(inputValue)
  if (formattedExpertise) {
    return option.label.includes(formattedExpertise)
  }
  return true
}

/**
 * Calculate column width
 **/
const getColumnWidth = (text: string, withAntdTag = false): number => {
  const span = document.createElement('span')
  const content = document.createTextNode(text)
  span.style.fontSize = '14px'
  span.style.height = 'auto'
  span.style.width = 'auto'
  span.style.position = 'absolute'
  span.style.whiteSpace = 'no-wrap'
  span.appendChild(content)
  document.body.appendChild(span)
  const extraSpace = withAntdTag ? 70 : 20
  const width = span.clientWidth + extraSpace
  document.body.removeChild(span)
  return width
}

const getStateStyle = (polarity: string): Record<string, string> => {
  return CSS(STATE_THEME[polarity], 0)
}

type Dict = Record<string, any>

class DefaultDict {
  defaultVal: Dict
  items: Dict

  constructor(defaultVal: Record<string, any>) {
    this.defaultVal = { ...defaultVal }
    this.items = {}
  }

  get(key: string): Dict {
    if (!(key in this.items)) {
      this.items[key] = cloneDeep(this.defaultVal)
    }
    return this.items[key]
  }
}

const isInvalidRouterParamId = (id: any): boolean => !id || Array.isArray(id)

const normalizeRouterParamId = (id: any): string | null => (isInvalidRouterParamId(id) ? null : id)

export {
  isInvalidRouterParamId,
  normalizeRouterParamId,
  DefaultDict,
  filterExpertiseOption,
  fixEncodedQueryString,
  genExpertiseOptions,
  getBase64,
  getColumnWidth,
  getStateStyle,
  gradientMaker,
  objectId,
  syncScrolls,
  tableScrollX,
  tableScrollY,
  toUpperUnderscore
}
