import { QuestionnaireStateEnum } from '@/store/enums/states/questionnaire'
import type { QuestionnaireState } from '@/store/types/states/questionnaire'

/**
 * Default value
 */
const state: QuestionnaireState = {
  [QuestionnaireStateEnum.ERROR]: null,
  [QuestionnaireStateEnum.IS_FETCHING]: false,
  [QuestionnaireStateEnum.NETWORK_CPTS]: null,
  [QuestionnaireStateEnum.SURVEY_CPTS]: null,
  [QuestionnaireStateEnum.SURVEY_RESPONSES]: null,
  [QuestionnaireStateEnum.WORKSPACE_USER_ALLOCATIONS]: null,
  [QuestionnaireStateEnum.WORKSPACE_NETWORKS]: null
}

export { state }
