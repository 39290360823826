import { AllocationStateEnum } from '@/store/enums/states/allocation'
import type { AllocationState } from '@/store/types/states/allocation'

const state: AllocationState = {
  [AllocationStateEnum.IS_CREATING]: false,
  [AllocationStateEnum.IS_GETTING]: false,
  [AllocationStateEnum.IS_UPDATING]: false,
  [AllocationStateEnum.IS_DELETING]: false,
  [AllocationStateEnum.ALLOCATION_LIST]: {},
  [AllocationStateEnum.CURRENT_ALLOCATION]: {},
  [AllocationStateEnum.ERROR]: null,
  [AllocationStateEnum.LOADING_PROMPT_TEXT]: null
}

export { state }
