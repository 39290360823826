import { GetterTree } from 'vuex'

import { AllocationGetterEnum } from '@/store/enums/getters/allocation'
import { AllocationStateEnum } from '@/store/enums/states/allocation'
import { RootState } from '@/store/types'
import { AllocationState } from '@/store/types/states/allocation'

export const getters: GetterTree<AllocationState, RootState> = {
  [AllocationGetterEnum.ALLOCATIONS]: (state: AllocationState) => {
    return state[AllocationStateEnum.ALLOCATION_LIST]?.content
  }
}
