import { message } from 'ant-design-vue'
import { saveAs } from 'file-saver'
import { parse } from 'json2csv'
import { sortBy } from 'lodash-es'
import { ActionTree } from 'vuex'

import {
  createSurvey,
  deleteSurvey,
  exportSurvey,
  getSurvey,
  getSurveys,
  updateSurvey
} from '@/services/api/survey'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { SurveyMutationEnum } from '@/store/enums/mutations/survey'
import { RootState } from '@/store/types'
import { SurveyActions } from '@/store/types/actions/survey'
import { SurveyState } from '@/store/types/states/survey'
import { SurveyExportFormatEnum, SurveyList, SurveySchema } from '@/types'

/**
 * Action function
 */
export const actions: ActionTree<SurveyState, RootState> & SurveyActions = {
  // Get surveys
  async [SurveyActionEnum.GET_SURVEYS_ALL]({ commit }, workspaceId) {
    return new Promise((resolve, reject) => {
      commit(SurveyMutationEnum.GET_SURVEYS, undefined)
      getSurveys(workspaceId, { surrogate: null })
        .then((res: SurveyList) => {
          commit(SurveyMutationEnum.GET_SURVEYS_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(SurveyMutationEnum.GET_SURVEYS_REJECTED, err)
          reject(err)
        })
    })
  },
  // Get surveys
  async [SurveyActionEnum.GET_SURVEYS]({ commit }, workspaceId) {
    return new Promise((resolve, reject) => {
      commit(SurveyMutationEnum.GET_SURVEYS, undefined)
      getSurveys(workspaceId)
        .then((res: SurveyList) => {
          commit(SurveyMutationEnum.GET_SURVEYS_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(SurveyMutationEnum.GET_SURVEYS_REJECTED, err)
          reject(err)
        })
    })
  },
  // Get survey
  async [SurveyActionEnum.GET_SURVEY]({ commit }, id) {
    commit(SurveyMutationEnum.GET_SURVEY, undefined)
    getSurvey(id)
      .then((res: SurveySchema) => {
        commit(SurveyMutationEnum.GET_SURVEY_FULFILLED, res)
      })
      .catch((err: Error) => {
        commit(SurveyMutationEnum.GET_SURVEY_REJECTED, err)
      })
  },
  // Create survey
  async [SurveyActionEnum.CREATE_SURVEY]({ commit }, { workspaceId, survey }) {
    commit(SurveyMutationEnum.CREATE_SURVEY, undefined)
    try {
      const res = await createSurvey(workspaceId, survey)
      commit(SurveyMutationEnum.CREATE_SURVEY_FULFILLED, res)
      return res
    } catch (err) {
      commit(SurveyMutationEnum.CREATE_SURVEY_REJECTED, err)
      throw err
    }
  },
  // Update survey
  async [SurveyActionEnum.UPDATE_SURVEY]({ commit }, { id, survey }) {
    commit(SurveyMutationEnum.UPDATE_SURVEY, undefined)
    try {
      const res = await updateSurvey({ id, survey })
      commit(SurveyMutationEnum.UPDATE_SURVEY_FULFILLED, res)
      return res
    } catch (err) {
      commit(SurveyMutationEnum.UPDATE_SURVEY_REJECTED, err)
      throw err
    }
  },
  // Delete survey
  async [SurveyActionEnum.DELETE_SURVEY]({ commit }, id) {
    commit(SurveyMutationEnum.DELETE_SURVEY, undefined)
    deleteSurvey(id)
      .then(() => {
        commit(SurveyMutationEnum.DELETE_SURVEY_FULFILLED, id)
      })
      .catch((err: Error) => {
        commit(SurveyMutationEnum.DELETE_SURVEY_REJECTED, err)
      })
  },
  // Export survey
  async [SurveyActionEnum.EXPORT_SURVEY]({ commit }, { surveyId, format, userIds, variableIds }) {
    commit(SurveyMutationEnum.EXPORT_SURVEY, undefined)
    exportSurvey({ surveyId, format, userIds, variableIds })
      .then((res: any) => {
        if (format === SurveyExportFormatEnum.JSON) {
          saveAs(
            new Blob([JSON.stringify(res)], { type: 'application/json' }),
            'CPT responses.json'
          )
        }
        if (format === SurveyExportFormatEnum.CSV) {
          try {
            const separateHeaderAndContent = res.split('\r\n')
            const header = separateHeaderAndContent[0].split(',')
            const headerLength = header.length
            const excelData: Array<any> = []
            const opts = { fields: header }
            const existedUserName: Array<any> = [] // username is unique, use this to hide repeated username and email for each row
            const existedSelectedVar: Record<string, any> = {}
            for (let i = 1; i < separateHeaderAndContent.length; i++) {
              const row = separateHeaderAndContent[i]
              const rowArray = row.split(',')
              const currentUserName = rowArray[1]
              const currentSelectedVar = rowArray[3]
              const eachExcelRow: Record<string, any> = {}

              for (let j = 0; j < headerLength; j++) {
                if ((j === 1 || j === 2) && existedUserName.includes(currentUserName)) {
                  eachExcelRow[header[j]] = ''
                } else if (
                  (j === 3 || j === 4) &&
                  existedSelectedVar[currentUserName] &&
                  Array.isArray(existedSelectedVar[currentUserName]) &&
                  existedSelectedVar[currentUserName].includes(currentSelectedVar)
                ) {
                  eachExcelRow[header[j]] = ''
                } else {
                  eachExcelRow[header[j]] = rowArray[j] || ''
                }
              }
              if (!existedUserName.includes(currentUserName)) {
                existedUserName.push(currentUserName)
              }
              if (
                !Object.prototype.hasOwnProperty.call(existedSelectedVar, currentUserName) ||
                !Array.isArray(existedSelectedVar[currentUserName])
              ) {
                existedSelectedVar[currentUserName] = []
              }
              if (!existedSelectedVar[currentUserName].includes(currentUserName)) {
                existedSelectedVar[currentUserName].push(currentSelectedVar)
              }
              excelData.push(eachExcelRow)
            }

            const csv = parse(
              sortBy(excelData, (o) => parseInt(o.row)),
              opts
            )
            saveAs(new Blob([csv], { type: 'application/octet-stream' }), `cpt-responses.csv`)
          } catch (err) {
            commit(SurveyMutationEnum.EXPORT_SURVEY_REJECTED, err)
            message.error(err.message)
            return
          }
        }
        commit(SurveyMutationEnum.EXPORT_SURVEY_FULFILLED, undefined)
      })
      .catch((err: Error) => {
        commit(SurveyMutationEnum.EXPORT_SURVEY_REJECTED, err)
      })
  }
}
