<template>
  <a-modal
    :visible="visible"
    :title="modalTitle"
    class="sz-workspace-form-modal"
    :width="560"
    :ok-button-props="{
      loading: formDisabled
    }"
    :cancel-button-props="{
      disabled: formDisabled
    }"
    :closable="!formDisabled"
    :mask-closable="!formDisabled"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form
      ref="formRef"
      :colon="false"
      :model="formState"
      :validate-on-rule-change="false"
      class="sz-survey-form"
    >
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.NAME.LABEL"
        :name="FORM_ITEMS.NAME.NAME"
        :rules="FORM_RULES[FORM_ITEMS.NAME.NAME]"
      >
        <a-input v-model:value="formState[FORM_ITEMS.NAME.NAME]" :disabled="formDisabled" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.DESCRIPTION.LABEL"
        :name="FORM_ITEMS.DESCRIPTION.NAME"
        :rules="FORM_RULES[FORM_ITEMS.DESCRIPTION.NAME]"
      >
        <a-textarea
          v-model:value="formState[FORM_ITEMS.DESCRIPTION.NAME]"
          :placeholder="FORM_ITEMS.DESCRIPTION.PLACEHOLDER"
          :disabled="formDisabled"
          :rows="4"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.ACCESS_CONTROL.LABEL"
        :name="FORM_ITEMS.ACCESS_CONTROL.NAME"
      >
        <a-select
          v-model:value="formState[FORM_ITEMS.ACCESS_CONTROL.NAME]"
          mode="multiple"
          placeholder="Select users"
          style="width: 100%"
          :options="users"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { message } from 'ant-design-vue'
import { isEmpty } from 'lodash-es'
import { includes } from 'ramda'
import { computed, defineComponent, reactive, ref, toRaw, watch } from 'vue'

import {
  FORM_ITEMS,
  FORM_RULES,
  initFormData,
  updateFormData
} from '@/components/workspace/WorkspaceDialog.vue'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { AuthStateEnum, UserStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'
import type { User } from '@/types'

const { AUTH, USER } = ModuleNames

export default defineComponent({
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    workspace: {
      type: Object,
      default: undefined
    }
  },
  emits: [EMIT_EVENTS.WORKSPACE.TOGGLE_DIALOG_VISIBILITY],
  setup(props, { emit }) {
    const store = useStore()

    const formState = reactive(initFormData(props.workspace))
    const formRef = ref()
    const visible = computed(() => props.isVisible)
    const formDisabled = ref<boolean>(false)
    const modalTitle = computed(() => `${props.workspace ? 'Edit' : 'Create'} Workspace`)
    const currentUser = computed(() => store.state[AUTH][AuthStateEnum.USER])
    const users = computed(() => {
      const currentUserList = store.state[USER][UserStateEnum.USER_LIST]?.content
      return currentUserList
        ?.filter(
          (each: User) =>
            includes(DB_ENUM_VALUES.USER.ROLES.ADMIN, each[DB_FIELDS.USER.ROLES]) ||
            includes(DB_ENUM_VALUES.USER.ROLES.DESIGNER, each[DB_FIELDS.USER.ROLES])
        )
        .map((each: User) => ({
          label: each?.username,
          value: each?.id
        }))
    })
    watch(
      () => props.workspace,
      () => {
        const data = initFormData(props.workspace)
        updateFormData(data, formState)
      }
    )

    /**
     * Close the modal
     */
    const handleCancel = () => {
      emit(EMIT_EVENTS.WORKSPACE.TOGGLE_DIALOG_VISIBILITY)
    }

    /**
     * Handle submit workspace form
     */
    const handleSubmit = async () => {
      formDisabled.value = true
      try {
        // validate form before create/update
        try {
          await formRef.value.validate()
        } catch (err: any) {
          formDisabled.value = false
          const firstErrorFieldName =
            err.errorFields[0].name.length > 1 ? [err.errorFields[0].name] : err.errorFields[0].name
          formRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
          return
        }
        const workspaceData = { ...toRaw(formState), type: DB_ENUM_VALUES.WORKSPACE.TYPE.ANALYSIS }
        const defaultAcl = [
          {
            userId: currentUser.value?.id
          }
        ]
        if (isEmpty(formState[FORM_ITEMS.ACCESS_CONTROL.NAME])) {
          workspaceData[FORM_ITEMS.ACCESS_CONTROL.NAME] = defaultAcl
        } else {
          const aclList: any[] = defaultAcl
          formState[FORM_ITEMS.ACCESS_CONTROL.NAME].forEach((userId: string) => {
            if (userId !== currentUser.value?.id) {
              aclList.push({
                userId
              })
            }
          })
          workspaceData[FORM_ITEMS.ACCESS_CONTROL.NAME] = aclList
        }
        if (props.workspace && typeof props.workspace.id === 'string') {
          // update workspace
          await store.dispatch(
            vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.UPDATE_WORKSPACE),
            {
              id: props.workspace.id,
              workspace: workspaceData
            }
          )
          message.success(MESSAGE.WORKSPACE_UPDATE_SUCCESS)
        } else {
          // create workspace
          await store.dispatch(
            vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.CREATE_WORKSPACE),
            workspaceData
          )
          message.success(MESSAGE.WORKSPACE_CREATE_SUCCESS)
        }
        formDisabled.value = false
        emit(EMIT_EVENTS.WORKSPACE.TOGGLE_DIALOG_VISIBILITY)
      } catch (err) {
        formDisabled.value = false
        throw err
      }
    }

    return {
      users,
      FORM_ITEMS,
      FORM_RULES,
      formDisabled,
      formRef,
      formState,
      handleCancel,
      handleSubmit,
      modalTitle,
      visible
    }
  }
})
</script>

<style lang="stylus">

.sz-workspace-form-modal
  .ant-modal-body
    max-height calc(100vh - 200px - 55px - 53px) // minus ((modal top + bottom) + header + footer)
    overflow auto

  .ant-row
    margin-bottom: 5px
  .sz-survey-steps
    .ant-input-number
      margin-top: 4px
    .ant-input
      height 32px
    .ant-space
      display flex
      flex-direction row
    .ant-form-item-children
      display: block
  .form-header
    text-align left
    font-weight 700
    margin 10px 0 10px 0

  .ant-space
    width 100% !important
</style>
