
import { defineComponent, PropType } from 'vue'

import MatrixDisplay from '@/components/analysis/common/matrix-display/MatrixDisplay.vue'
import { StatType } from '@/components/analysis/composable/hybrid-types'
import { probFormatter } from '@/libs/common'

export default defineComponent({
  components: {
    MatrixDisplay
  },
  props: {
    matrix: { type: Array as PropType<Array<number[]> | undefined>, default: undefined },
    stats: { type: Object as PropType<StatType>, default: () => ({}) },
    isAggregate: { type: Boolean, default: false }
  },
  setup() {
    return {
      probFormatter
    }
  }
})
