/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum SurveyActionEnum {
  CREATE_SURVEY = 'CREATE_SURVEY',
  UPDATE_SURVEY = 'UPDATE_SURVEY',
  DELETE_SURVEY = 'DELETE_SURVEY',
  GET_SURVEY = 'GET_SURVEY',
  GET_SURVEYS = 'GET_SURVEYS',
  GET_SURVEYS_ALL = 'GET_SURVEYS_ALL',
  EXPORT_SURVEY = 'EXPORT_SURVEY'
}
