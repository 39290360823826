import { render } from "./AceTable.vue?vue&type=template&id=1b68497a"
import script from "./AceTable.vue?vue&type=script&lang=ts"
export * from "./AceTable.vue?vue&type=script&lang=ts"

import "./AceTable.vue?vue&type=style&index=0&id=1b68497a&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1b68497a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1b68497a', script)) {
    api.reload('1b68497a', script)
  }
  
  module.hot.accept("./AceTable.vue?vue&type=template&id=1b68497a", () => {
    api.rerender('1b68497a', render)
  })

}

script.__file = "src/components/method-ace/AceTable.vue"

export default script