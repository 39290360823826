<template>
  <div :class="wrapper" class="sza-workspace-info" :style="workspaceHeaderStyle">
    <div class="title" :title="workspace.name">{{ workspace.name }}</div>
    <div class="updated">Last updated {{ workspaceModified }}</div>
    <div v-if="workspace?.description?.length" class="description">{{ workspace.description }}</div>
  </div>
</template>

<script lang="ts">
import { css } from '@emotion/css'
import dayjs from 'dayjs'
import { computed, defineComponent, PropType } from 'vue'

import { gradientMaker } from '@/libs/utils'
import { cssTruncated } from '@/styles/common'
import { Workspace } from '@/types'

const wrapper = css`
  width: 100%;
  overflow: hidden;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid #cccccc;
  > * {
    padding-left: 8px;
    flex: 0 0 auto;
  }

  .description {
    overflow: auto;
    margin: 0 10px 10px 0px;
    font-size: 12px;
    line-height: 15px;
    color: #888888;
  }

  .updated {
    flex: 0 0 auto;
    margin: 10px 0 7px 0;
    font-size: 12px;
    color: #a0a0a0;
  }

  .title {
    ${cssTruncated};
    padding-top: 2px;
    font-size: 16px;
    font-weight: bold;
  }
`

export default defineComponent({
  props: {
    workspace: { type: Object as PropType<Workspace>, required: true }
  },
  setup(props) {
    const workspaceCreated = computed(() => dayjs(props.workspace?.createdDate).fromNow())
    const workspaceModified = computed(() => dayjs(props.workspace?.lastModifiedDate).fromNow())
    const workspaceHeaderStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(props.workspace?.name || 'UNTITLED')
    }))

    return {
      wrapper,
      workspaceCreated,
      workspaceModified,
      workspaceHeaderStyle
    }
  }
})
</script>
