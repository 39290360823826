import { message } from 'ant-design-vue'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { API_PAGEABLE_PARAMS, API_RESPONSE } from '@/types'

/**
 * Self defined error type, add a new "status" field
 */
interface MyError extends Error {
  status?: number
}

/**
 * Extract http response
 * @param {AxiosResponse} res
 */
const extractResponse = (res: AxiosResponse): any => res.data

const normalizeError = (err: AxiosError): MyError => {
  let error: MyError
  // Error from backend
  if (err.response) {
    const msg = err.response.data.message || err.response.data.error || err.response.data || 'error'
    const status = err.response.status

    error = new Error(msg)
    error.status = status
  } else {
    // Error from client, such as Network Error
    error = err
  }
  return error
}

/**
 * Error handler for api
 * @param err - AxiosError object
 */
const errorHandler = (err: AxiosError): void => {
  const error: MyError = normalizeError(err)
  message.error(error.message)
  throw error
}

/**
 * Error handler for api
 * @param err - AxiosError object
 */
const errorCatcher = (err: AxiosError): Promise<Error> => {
  const error: MyError = normalizeError(err)
  message.error(error.message)
  return Promise.reject(error)
}

declare function curry(
  params: API_PAGEABLE_PARAMS | { [key: string]: string | string[] | number },
  response?: API_RESPONSE
): Promise<any>

const recursivePaginationAPIRequestFn = (api: AxiosInstance, endpoint: string): typeof curry => {
  const request = async (params: API_PAGEABLE_PARAMS, response?: API_RESPONSE): Promise<any> => {
    const tmpResponse = extractResponse(await api.get(endpoint, { params }))
    const totalPages = tmpResponse.totalPages
    const currentPage = tmpResponse.number + 1
    if (!response) {
      response = tmpResponse
    } else {
      response.content = [...response.content, ...tmpResponse.content]
      response.numberOfElements = response.content.length
    }
    if (currentPage < totalPages) {
      params.page = currentPage
      return request(params, response)
    }
    return response
  }
  return request
}

/**
 * Convertt Binary Blob as text
 *
 * @param bin Binary Blob
 * @param callback Callback for the result
 */
const readAsText = (bin: Blob, callback: (text: string) => void): void => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsText(bin)
}

const readAsTextSync = async (file: File): Promise<any> => {
  return await new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.readAsText(file)
  })
}

export {
  errorCatcher,
  errorHandler,
  extractResponse,
  readAsText,
  readAsTextSync,
  recursivePaginationAPIRequestFn
}
