
import { defineComponent, Ref, ref } from 'vue'

import { multiplexWhatIfRaw } from '@/components/analysis/composable/whatif'

// import { NodeSelection } from '../libs/common'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    networkMap: { type: Object, default: undefined }
  },
  setup(props, { emit }) {
    const tasksRawValue = ref('')
    const tasksTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const tasksRawValue2 = ref('')
    const tasksTextAreaWrapper2: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (tasksTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      tasksRawValue.value = getSelectionRaw()
      emit(EVENTS.OK, tasksRawValue.value)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    const generate = () => {
      if (!props.networkMap) {
        return
      }
      const result = multiplexWhatIfRaw(
        props.networkMap,
        (tasksTextAreaWrapper2.value as HTMLTextAreaElement)?.value || ''
      )
      if (result?.length) {
        tasksRawValue.value = result
      }
    }

    return {
      generate,
      tasksRawValue2,
      tasksTextAreaWrapper2,
      ok,
      cancel,
      tasksRawValue,
      tasksTextAreaWrapper
    }
  }
})
