
import { Edit } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { computed, defineComponent, PropType, ref, watch } from 'vue'

import { Network, Variable } from '@/libs/bayes'
import { CSS, VARIABLE_THEME } from '@/libs/theme'

export const EVENTS = {
  SELECT: 'select',
  CHANGE: 'change',
  EDIT: 'edit',
  SELECT_ALLOCATION: 'selectionAllocation'
}

export default defineComponent({
  components: { Edit, Icon },
  props: {
    network: { type: Object as PropType<Network>, required: true },
    variable: { type: Object as PropType<Variable>, required: true },
    parents: { type: Object, required: true },
    selected: { type: Boolean, required: true },
    relation: { type: String, required: true },
    showKey: { type: Boolean, default: true },
    maxVariableWidth: { type: String, default: undefined }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const restyle = () => CSS(VARIABLE_THEME[props.relation], 0)
    const style = ref(restyle())
    const definitionVisible = ref(false)
    const definitionTitle = ref('Node definition')

    watch(
      () => props.relation,
      (): void => {
        style.value = restyle()
      }
    )

    const variableTitle = computed(() => (props.showKey ? props.variable.key : props.variable.name))

    const editVariable = () => {
      emit(EVENTS.EDIT, props.variable)
    }

    return {
      editVariable,
      variableTitle,
      definitionVisible,
      definitionTitle,
      style,
      variableWidth: '100%' || props.maxVariableWidth,
      selectVariable: () => {
        style.value = restyle()
        emit(EVENTS.SELECT, props.variable)
      }
    }
  }
})
