
import { defineComponent, Ref, ref, watch } from 'vue'

import PalettePicker from '@/components/common/PalettePicker.vue'

export const EVENTS = {
  COLOR_CHANGED: 'COLOR_CHANGED',
  PALETTE_TOGGLE: 'update:paletteVisible'
}

export default defineComponent({
  components: {
    PalettePicker
  },
  props: {
    paletteVisible: { type: Boolean, required: true },
    paletteName: { type: String, required: true },
    isLoading: { type: Boolean, required: true }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const visible: Ref<boolean> = ref(props.paletteVisible)
    const selectedPaletteName: Ref<string> = ref(props.paletteName)
    const selectPalette = () => {
      emit(EVENTS.PALETTE_TOGGLE, false)
      emit(EVENTS.COLOR_CHANGED, selectedPaletteName.value)
    }

    const cancel = () => {
      emit(EVENTS.PALETTE_TOGGLE, false)
    }

    watch(
      () => props.paletteName,
      (newVal) => {
        selectedPaletteName.value = newVal
      }
    )

    watch(
      () => props.paletteVisible,
      (newVal) => {
        visible.value = newVal
      }
    )

    return {
      cancel,
      visible,
      selectedPaletteName,
      selectPalette
    }
  }
})
