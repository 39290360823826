import { css } from '@emotion/css'

export const tipHeaderCls = css`
  font-weight: 700;
  color: #44444;
  margin-bottom: 2px;
`
export const tipBodyCls = css`
  margin-bottom: 2px;
`
