import { AxiosError } from 'axios'

import axios from '@/axios'
import { ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'

export const executeAhp = async (
  workspaceId: string,
  payload: Record<string, any>
): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.AHP_EXECUTE({ workspaceId }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

export const executeAhpMulti = async (
  workspaceId: string,
  payload: Record<string, any>
): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.AHP_MULTI_EXECUTE({ workspaceId }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}
