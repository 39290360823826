import { repeat } from 'lodash-es'

import { Ext } from '@/types'

import { Condition } from './Condition'
import { NodeType } from './enums/NodeType'
import { StatePolarity } from './enums/StatePolarity'
import { BEST_LIKELIHOOD, State, WORST_LIKELIHOOD } from './State'

export type StateArray = Array<{ id: string; name: string; description?: string }>

export const indexToPolarity = (index: number, stateNum: number): StatePolarity =>
  index === 0
    ? StatePolarity.NEGATIVE
    : index === stateNum - 1
    ? StatePolarity.POSITIVE
    : StatePolarity.NEUTRAL

export class Variable {
  id: string
  name: string
  type: NodeType
  states: Array<State>
  stateNum: number
  key: string
  stateMap: Record<string, State>
  nodeDefinition?: Array<number>
  description: string
  cssStyle?: string
  dependent?: boolean
  isReversed?: boolean
  ext?: Ext

  constructor(
    id: string,
    name: string,
    description: string,
    stateArray: StateArray,
    dependent: boolean,
    type: NodeType,
    isReversed: boolean,
    ext: Ext,
    cssStyle = '',
    nodeDefinition: Array<number> = [],
    key: string
  ) {
    this.id = id
    this.name = name
    this.key = key
    this.stateNum = (stateArray && stateArray.length) || 0
    this.dependent = dependent
    this.states = []
    this.stateMap = {}
    this.type = type
    this.description = description
    this.isReversed = isReversed
    this.cssStyle = cssStyle
    this.nodeDefinition = nodeDefinition
    this.ext = ext
    if (this.stateNum) {
      this.setStates(stateArray)
    }
  }

  get length(): number {
    return this.stateNum
  }

  setStates(stateArray: StateArray): void {
    this.states = []
    this.stateMap = {}
    for (let i = 0; i < this.stateNum; i++) {
      const { id, name, description } = stateArray[i]
      const polarity: StatePolarity = indexToPolarity(i, this.stateNum)
      const state = new State(id, name, description || '', polarity, i / (this.stateNum - 1))
      this.states.push(state)
      this.stateMap[id] = state
    }
  }

  setDependent(dependent: boolean): void {
    if (!this.ext) {
      this.ext = {}
    }
    this.ext.dependent = dependent
  }

  isDependent(): boolean {
    return this.ext?.dependent
  }

  getAllStates(): Array<Condition> {
    return this.states.map((state) => ({ variable: this, state }))
  }

  getState(index: number): State {
    return this.isReversed ? this.states[this.stateNum - 1 - index] : this.states[index]
  }

  isDeterministic(): boolean {
    return this.type === NodeType.DETERMINISTIC || this.type === NodeType.TRUTH_TABLE
  }

  getPositiveState(): Condition {
    return {
      variable: this,
      state: this.getState(this.stateNum - 1)
    }
  }

  getNegativeState(): Condition {
    return {
      variable: this,
      state: this.getState(0)
    }
  }

  getNegativeStates(): Array<Condition> {
    const states = this.isReversed
      ? this.states.slice(1, this.stateNum)
      : this.states.slice(0, this.stateNum - 1)
    return states.map((state) => ({ variable: this, state }))
  }
}

/**
 * These are dummy variables used to capture user input
 * They are not part of the real variables in the network
 */
export const LIKELIHOOD = new Variable(
  repeat('0', 24),
  'LIKELIHOOD',
  'Likelihood',
  [WORST_LIKELIHOOD, BEST_LIKELIHOOD],
  false,
  NodeType.DUMMY,
  false,
  {},
  '',
  [],
  'LIKELIHOOD'
)
