import { MutationTree } from 'vuex'

import { QuestionnaireMutationEnum } from '@/store/enums/mutations/questionnaire'
import { QuestionnaireStateEnum } from '@/store/enums/states/questionnaire'
import { QuestionnaireMutation } from '@/store/types/mutations/questionnaire'
import { QuestionnaireState } from '@/store/types/states/questionnaire'

export const mutations: MutationTree<QuestionnaireState> & QuestionnaireMutation = {
  [QuestionnaireMutationEnum.SET_IS_FETCHING](state: QuestionnaireState, payload) {
    state[QuestionnaireStateEnum.IS_FETCHING] = payload
  },
  [QuestionnaireMutationEnum.GET_NETWORK_CPTS_FULFILLED](state: QuestionnaireState, payload) {
    state[QuestionnaireStateEnum.NETWORK_CPTS] = payload
  },
  [QuestionnaireMutationEnum.GET_NETWORK_CPTS_REJECTED](state: QuestionnaireState, error) {
    state[QuestionnaireStateEnum.ERROR] = error
  },
  [QuestionnaireMutationEnum.GET_SURVEY_CPTS](state: QuestionnaireState, payload) {
    state[QuestionnaireStateEnum.SURVEY_CPTS] = payload
  },
  [QuestionnaireMutationEnum.GET_SURVEY_RESPONSES](state: QuestionnaireState, payload) {
    state[QuestionnaireStateEnum.SURVEY_RESPONSES] = payload
  },
  [QuestionnaireMutationEnum.GET_WORKSPACE_USER_ALLOCATIONS](state: QuestionnaireState, payload) {
    state[QuestionnaireStateEnum.WORKSPACE_USER_ALLOCATIONS] = payload
  },
  [QuestionnaireMutationEnum.GET_WORKSPACE_NETWORKS](state: QuestionnaireState, payload) {
    state[QuestionnaireStateEnum.WORKSPACE_NETWORKS] = payload
  }
}
