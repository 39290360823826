
import { defineComponent, PropType, Ref, ref } from 'vue'

import { NodeSelection, parseSelectionRaw } from '../../libs/common'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    records: { type: Array as PropType<NodeSelection[]>, default: undefined }
  },
  setup(props, { emit }) {
    const cleanExisting = ref(false)
    const selectionTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () =>
      (selectionTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      const selectionRawValue = getSelectionRaw()
      if (props.records && selectionRawValue?.length) {
        parseSelectionRaw(props.records, selectionRawValue, cleanExisting.value)
      }
      emit(EVENTS.OK)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      ok,
      cancel,
      cleanExisting,
      selectionTextAreaWrapper
    }
  }
})
