
import { css } from '@emotion/css'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'

import { InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import useAntTableScroll from '@/components/composables/antTableScroll'
import { genCellBgStyleRange } from '@/libs/theme'

export default defineComponent({
  props: {
    detRows: { type: Object, required: true },
    maxDetStates: { type: Object, required: true }
  },
  setup(props) {
    const maxRange: Ref<number> = ref(-100)
    const minRange: Ref<number> = ref(100)
    const { tableWrapper, scrollY, scrollX } = useAntTableScroll(null, null, 0, 0, false, 190)

    const cellBgStyle = (val: number) => {
      return genCellBgStyleRange(minRange.value, maxRange.value, val)
    }

    const detStateClass = (isBaseState: boolean) => {
      return css`
        display: flex;
        flex-direction: column;
        background-color: ${isBaseState ? 'white' : 'transparent'};
      `
    }

    const updateRange = () => {
      let _max = 0
      let _min = 0
      props.detRows.forEach((row: any) => {
        for (let i = 0; i < props.maxDetStates.length; i++) {
          if (row.states?.[i]?.state) {
            const val = row.results[i]
            if (val > _max) {
              _max = val
            }
            if (val < _min) {
              _min = val
            }
          }
        }
      })
      maxRange.value = _max
      minRange.value = _min
    }

    watch(() => props.detRows, updateRange)

    const detRows2 = computed(() => {
      return props.detRows.map((row: any) => {
        const row2: any = {
          name: row.name,
          key: row.name,
          baseline: row.baseline.name
        }
        for (let i = 0; i < props.maxDetStates.length; i++) {
          if (row.states?.[i]?.state) {
            row2['r' + i] = {
              state: row.states?.[i]?.state,
              value: row.results[i],
              name: row.states?.[i]?.state?.name,
              isBaseline: row.states?.[i]?.state?.name === row.baseline.name
            }
          } else {
            row2['r' + i] = {
              state: null,
              value: 0,
              name: ''
            }
          }
        }
        return row2
      })
    })

    const columns = computed(() => {
      const cols: ColumnProps[] = [
        {
          title: 'Input node',
          width: 120,
          dataIndex: 'name',
          fixed: 'left',
          key: 'name', // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
          align: 'left',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: 'Baseline',
          width: 70,
          fixed: 'left',
          dataIndex: 'baseline',
          key: 'baseline',
          align: 'left',
          slots: {
            customRender: 'baseline'
          },
          customHeaderCell: () => {
            return {
              style: {
                'text-align': 'right',
                padding: '1px 3px'
              }
            }
          }
        }
      ]
      for (let i = 0; i < props.maxDetStates.length; i++) {
        cols.push({
          title: 'State ' + (props.maxDetStates[i] + 1),
          width: 70,
          dataIndex: 'r' + i,
          key: 'r' + i,
          align: 'right',
          sorter: ((i_) => (a: any, b: any) => a['r' + i_].value - b['r' + i_].value)(i),
          customHeaderCell: () => {
            return {
              style: {
                'text-align': 'right',
                padding: '1px 3px'
              }
            }
          },
          slots: {
            customRender: 'value'
          }
        })
      }
      return cols
    })

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    onMounted(() => {
      updateRange()
    })

    return {
      tableWrapper,
      scrollX,
      scrollY,
      columns,
      detRows2,
      detStateClass,
      update,
      cellBgStyle,
      ROW_TYPE,
      InputType
    }
  }
})
