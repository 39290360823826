
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    title: { type: String, default: 'Data Import' },
    description: { type: String, default: 'Paste your data here' },
    isVisible: { type: Boolean, default: false }
  },
  setup(props_, { emit }) {
    const rawValue = ref('')
    const textAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (textAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      rawValue.value = getSelectionRaw()
      emit(EVENTS.OK, rawValue.value)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      ok,
      cancel,
      rawValue,
      textAreaWrapper
    }
  }
})
