
import 'splitpanes/dist/splitpanes.css'

import { computed, defineComponent } from 'vue'

import { percentFormatter, probFormatter } from '@/libs/common'

import PlainComment from './PlainComment.vue'

export const EVENTS = {
  CHANGE: 'CHANGE'
}

export default defineComponent({
  components: {
    PlainComment
  },
  props: {
    parentKey: { type: String, default: undefined },
    pairwiseRows: { type: Object, default: undefined }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const pairwiseColumns = computed(() => {
      return [
        {
          title: 'Criteria A',
          dataIndex: 'firstKey',
          key: 'firstKey',
          slots: {
            customRender: 'firstKey'
          }
        },
        {
          title: 'Criteria B',
          dataIndex: 'secondKey',
          key: 'secondKey',
          slots: {
            customRender: 'secondKey'
          }
        },
        {
          title: 'Intensity',
          dataIndex: 'intensity',
          width: '100px',
          key: 'intensity',
          slots: {
            customRender: 'intensity'
          }
        },
        {
          title: 'Comment',
          dataIndex: 'comment',
          key: 'comment',
          slots: {
            customRender: 'comment'
          }
        },
        {
          title: 'Inconsistency',
          dataIndex: 'inconsistency',
          key: 'inconsistency',
          width: '60px',
          align: 'right',
          slots: {
            customRender: 'inconsistency'
          }
        },
        {
          title: 'Rank',
          dataIndex: 'rank',
          width: '50px',
          key: 'rank',
          align: 'right',
          slots: {
            customRender: 'rank'
          }
        }
      ]
    })

    const cellBgStyle = (which: number, record: any) => {
      let backgroundColor
      if (which) {
        if (record.secondKey === record.priority) {
          backgroundColor = '#ffcc00'
        } else {
          backgroundColor = 'white'
        }
      } else {
        if (record.firstKey === record.priority) {
          backgroundColor = '#ffcc00'
        } else {
          backgroundColor = 'white'
        }
      }
      return { backgroundColor }
    }

    const selectPriority = (which: number, record: any) => {
      if (which) {
        record.priority = record.secondKey
      } else {
        record.priority = record.firstKey
      }
      emit(EVENTS.CHANGE, record)
    }

    const intensityChange = (record: any) => {
      emit(EVENTS.CHANGE, props.parentKey, record)
    }

    const commentChange = (record: any, newComment: string) => {
      record.comment = newComment
      emit(EVENTS.CHANGE, props.parentKey, record)
    }

    const rowClassName = (record: any) => (record.boundary ? 'boundary-row' : null)

    return {
      commentChange,
      intensityChange,
      rowClassName,
      selectPriority,
      cellBgStyle,
      probFormatter,
      percentFormatter,
      pairwiseColumns
    }
  }
})
