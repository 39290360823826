export enum AllocationStateEnum {
  IS_CREATING = 'isCreating',
  IS_GETTING = 'isGetting',
  IS_UPDATING = 'isUpdating',
  IS_DELETING = 'isDeleting',
  ALLOCATION_LIST = 'allocationList',
  CURRENT_ALLOCATION = 'currentAllocation',
  ERROR = 'error',
  LOADING_PROMPT_TEXT = 'loadingPromptText'
}
