<template>
  <svg class="wny-boxplot" :width="width" :height="height">
    <g>
      <!-- five number summary: min, q1, mean, q2, max -->
      <text
        v-for="(item, index) in items"
        :key="`item-value-${index}`"
        class="wny-value"
        text-anchor="middle"
        :x="item.x + 'px'"
        :y="valueY + 'px'"
        :width="lblWidth + 'px'"
        @click="pick(item.q)"
      >
        {{ item.value }}
      </text>
      <text
        v-for="(item, index) in items"
        :key="`item-label-${index}`"
        class="wny-label"
        text-anchor="middle"
        :x="item.x + 'px'"
        :y="labelY + 'px'"
        :width="lblWidth + 'px'"
      >
        {{ item.label }}
      </text>
      <!-- median -->
      <text
        key="item-label-median"
        class="wny-label"
        text-anchor="start"
        :x="0 + 'px'"
        :y="auxY + 'px'"
      >
        MEDIAN:
      </text>
      <text
        key="item-value-median"
        class="wny-value"
        text-anchor="start"
        :x="40 + 'px'"
        :y="auxY + 'px'"
        :width="lblWidth + 'px'"
        @click="pick(median)"
      >
        {{ median.toFixed(2) }}
      </text>
      <!-- modes -->
      <text
        key="item-label-modes"
        class="wny-label"
        text-anchor="start"
        :x="80 + 'px'"
        :y="auxY + 'px'"
        :width="lblWidth + 'px'"
      >
        MODES:
      </text>
      <text
        v-for="(mode, index) in modes"
        :key="`item-value-mode-${index}`"
        class="wny-value"
        text-anchor="start"
        :x="120 + index * lblWidth + 'px'"
        :y="auxY + 'px'"
        :width="lblWidth + 'px'"
        @click="pick(mode)"
      >
        {{ mode.toFixed(2) }}
      </text>
      <!-- line -->
      <rect
        :fill="'#aaaaaa'"
        :x="items[0].x + 'px'"
        :y="startY + barHeightHalf + 'px'"
        :width="items[4].x - items[0].x + 'px'"
        :height="'1px'"
      />
      <!-- whisker and bar -->
      <rect
        :fill="'#777777'"
        :x="items[0].x + 'px'"
        :y="startY + 'px'"
        :width="1 + 'px'"
        :height="barHeight + 'px'"
      />
      <rect
        :fill="'#cccccc'"
        :x="items[1].x + 'px'"
        :y="startY + 'px'"
        :width="items[2].x - items[1].x + 'px'"
        :height="barHeight + 'px'"
      />
      <rect
        :fill="'#333333'"
        :x="items[2].x + 'px'"
        :y="startY + 'px'"
        :width="1 + 'px'"
        :height="barHeight + 'px'"
      />
      <rect
        :fill="'#cccccc'"
        :x="items[2].x + 'px'"
        :y="startY + 'px'"
        :width="items[3].x - items[2].x + 'px'"
        :height="barHeight + 'px'"
      />
      <rect
        :fill="'#777777'"
        :x="items[4].x + 'px'"
        :y="startY + 'px'"
        :width="1 + 'px'"
        :height="barHeight + 'px'"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { boxplot } from '@sgratzl/boxplots'
import { defineComponent, PropType } from 'vue'

const findModes = (data: Array<number>) => {
  const tally = data.reduce((t: Map<number, number>, x: number) => {
    t.set(x, (t.get(x) || 0) + 1)
    return t
  }, new Map())
  const sortedTally = [...tally].sort((x1, x2) => x2[1] - x1[1])
  return sortedTally
    .filter((x) => x[1] === sortedTally[0][1])
    .slice(0, 3)
    .map((x) => x[0])
}

export const OVERRIDE = 'override'

export default defineComponent({
  props: {
    data: { type: Array as PropType<Array<number>>, default: () => [] },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 60 }
  },
  emits: [OVERRIDE],
  setup(props, { emit }) {
    const startY = 0
    const lblWidth = 30
    const barHeight = 10
    const barHeightHalf = 5
    const valueY = startY + barHeight + 15
    const labelY = valueY + 12
    const auxY = labelY + 18
    const offset = 20

    const { min, q1, mean, q3, max, median } = boxplot(props.data)
    const modes = findModes(props.data)
    const qs = [min, q1, mean, q3, max]
    const labels = ['MIN', 'Q1', 'MEAN', 'Q3', 'MAX']
    const qWidth = max - min
    const items = qs.map((q, index) => {
      const x = qWidth
        ? Math.round(((q - min) * (props.width - offset * 2)) / qWidth) + offset
        : Math.round((index * (props.width - offset * 2)) / 4) + offset
      const value = q.toFixed(2)
      const label = labels[index]
      return {
        x,
        label,
        q,
        value
      }
    })

    const pick = (value: number) => {
      emit(OVERRIDE, parseFloat(value.toFixed(2)))
    }

    return {
      barHeightHalf,
      startY,
      labelY,
      auxY,
      valueY,
      pick,
      median,
      modes,
      items,
      lblWidth,
      barHeight
    }
  }
})
</script>

<style lang="stylus">
.wny-boxplot
  .wny-label
    font-size: 8px
    fill: #aaaaaa
  .wny-value
    font-size: 9px
    font-weight: bold
    fill: #333333
    cursor: pointer
    &:hover
      fill: #ff3300
</style>
