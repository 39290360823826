export enum SurveyStateEnum {
  IS_CREATING = 'isCreating',
  IS_UPDATING = 'isUpdating',
  IS_DELETING = 'isDeleting',
  IS_GETTING = 'isGetting',
  IS_EXPORTING = 'isExporting',
  ERROR = 'error',
  CURRENT_SURVEY = 'currentSurvey',
  SURVEY_LIST = 'surveyList',
  LOADING_PROMPT_TEXT = 'loadingPromptText'
}
