import { render } from "./SurveyList.vue?vue&type=template&id=76646aa6"
import script from "./SurveyList.vue?vue&type=script&lang=ts"
export * from "./SurveyList.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "76646aa6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('76646aa6', script)) {
    api.reload('76646aa6', script)
  }
  
  module.hot.accept("./SurveyList.vue?vue&type=template&id=76646aa6", () => {
    api.rerender('76646aa6', render)
  })

}

script.__file = "src/components/survey/SurveyList.vue"

export default script