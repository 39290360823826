// import { Condition } from '..'
import { last } from 'ramda'

import { AnalyticsRecord, getResponseMapForVar } from '@/components/composables/analytics'
import { CPTSummary, CPTSummaryOutcome, CPTSummaryRow } from '@/libs/bayes'
import {
  anonymizeRationale,
  complexRow,
  Dict,
  normalizeName,
  numberCell,
  simpleRow
} from '@/libs/common'
import { DefaultDict } from '@/libs/utils'
import { User } from '@/types'

import { Condition } from '..'
import { Combination, CombinationRow, DEP_COMB_KEY } from '../Combination'
import { ResponseMap } from '../CPTResponse'
import { CPTMethod } from '../enums/CPTMethod'
import { Network } from '../Network'
import { State } from '../State'
import { Variable } from '../Variable'
import { CPT } from './CPT'
// import { ResponseMap } from '../CPTResponse'

/**
 * This key represents a key for a particular child state
 */
export type CHILD_STATE_KEY = string
export type NON_POS_STATE_ID = State['id']

/**
 * Interpolation factor for a variable when switching from a 'positive' state to the state in the variable
 */
export type IFS = Record<
  DEP_COMB_KEY,
  Record<CHILD_STATE_KEY, Record<Variable['id'], Record<NON_POS_STATE_ID, number>>>
>

export type BASE = Record<DEP_COMB_KEY, Record<CHILD_STATE_KEY, number>>
export type DENOMS = Record<DEP_COMB_KEY, Record<CHILD_STATE_KEY, number>>

export class CainCPT extends CPT {
  cainIndependentCombinations: Array<Combination>
  denoms: DENOMS
  bases: BASE
  ifs: IFS

  constructor(network: Network, variable: Variable, state: State, id?: string) {
    super(network, variable, state, id, CPTMethod.CAIN)
    this.denoms = {}
    this.ifs = {}
    this.bases = {}
    this.cainIndependentCombinations = []
  }

  generateCainIndependentCombinations(variables: Array<Variable>): Array<Combination> {
    let combinations: Array<Combination> = [[]]
    if (!variables.length) {
      return combinations
    }
    // do extreme positive
    const extremePositiveCombination: Combination = []
    for (let i = 0; i < variables.length; i++) {
      const variable = variables[i]
      const variableState = variable.getPositiveState()
      extremePositiveCombination.push(variableState)
    }
    combinations = [extremePositiveCombination]

    // do extreme negative
    const extremeNegativeCombination: Combination = []
    for (let i = 0; i < variables.length; i++) {
      const variable = variables[i]
      const variableState = variable.getNegativeState()
      extremeNegativeCombination.push(variableState)
    }
    combinations = combinations.concat([extremeNegativeCombination])

    /*
    let extremeNegativeCombinations: Array<Combination> = [[]];
    for (let i = 0; i < variables.length; i++) {
      const variable = variables[i];
      const negativeStates = variable.getNegativeStates();
      extremeNegativeCombinations = this.multiplexStates(
        extremeNegativeCombinations,
        negativeStates
      );
    }
    combinations = combinations.concat(extremeNegativeCombinations);
    */

    // do single negative
    if (variables.length > 1) {
      let negativeCombinations: Array<Combination> = []
      for (let i = 0; i < variables.length; i++) {
        const variable = variables[i]
        const negativeStates = variable.getNegativeStates()
        // const positiveCombination: Combination = [];
        let runningCombinations: Array<Combination> = [[]]
        for (let j = 0; j < variables.length; j++) {
          const posVariableState = variables[j].getPositiveState()
          if (i === j) {
            runningCombinations = this.multiplexStates(runningCombinations, negativeStates)
          } else {
            runningCombinations = this.multiplexStates(runningCombinations, [posVariableState])
          }
        }
        negativeCombinations = negativeCombinations.concat(runningCombinations)
      }
      combinations = combinations.concat(negativeCombinations)
    }
    this.cainIndependentCombinations = combinations
    return combinations
  }

  getCainBlockLength(): number {
    return this.cainIndependentCombinations.length
  }

  getCainBlockNum(): number {
    return this.cainIndependentCombinations.length
      ? (this.elicitedRows?.length || 0) / this.cainIndependentCombinations.length
      : this.elicitedRows?.length || 1
  }

  getCainBlockRows(blockIndex: number): Array<CombinationRow> {
    const blockLength = this.getCainBlockLength()
    const start = blockIndex * blockLength
    const end = (blockIndex + 1) * blockLength
    return this.elicitedRows?.slice(start, end) || []
  }

  generateElicitedRows(): void {
    const dependentCombs = this.dependentCombinations
    const cainIndependentCombs = this.generateCainIndependentCombinations(this.independents)
    const cainCombs = this.product(dependentCombs, cainIndependentCombs)
    this.tableRows = cainCombs.map((combination) => new CombinationRow(combination))
    this.elicitedCombinations = this.multiplexRightByStates(cainCombs, this.variable.getAllStates())
    this.elicitedRows = this.elicitedCombinations.map(
      (combination) => new CombinationRow(combination)
    )
  }

  calcDenom(responseMap: ResponseMap, blockIndex: number): void {
    if (!this.elicitedRows?.length) {
      return
    }
    const { denoms, bases } = this
    const depNum = this.dependents.length
    const blockRows = this.getCainBlockRows(blockIndex)
    const xPosRow = blockRows[0]
    const xNegRow = blockRows[1]
    const depRowKey = this.makeCombKey(xPosRow.combination.slice(0, depNum))
    const childState = last(xPosRow.combination)
    if (!childState) {
      return
    }
    const xPosKey = this.makeCombKey(xPosRow.combination)
    const xNegKey = this.makeCombKey(xNegRow.combination)
    const childStateKey = this.makeCondKey(childState)
    if (!(depRowKey in denoms)) {
      denoms[depRowKey] = {}
      bases[depRowKey] = {}
    }
    const xPosVal = responseMap.get(xPosKey)?.response?.value || 0
    const xNegVal = responseMap.get(xNegKey)?.response?.value || 0
    denoms[depRowKey][childStateKey] = xPosVal - xNegVal
    bases[depRowKey][childStateKey] = xNegVal
  }

  findNonPos(combination: Combination): Condition | undefined {
    const condNum = combination.length
    for (let i = 0; i < condNum; i++) {
      const condition = combination[i]
      if (condition.state.isNonPositive()) {
        return condition
      }
    }
  }

  calcIfs(responseMap: ResponseMap, blockIndex: number): void {
    if (!this.elicitedRows?.length) {
      return
    }
    const { ifs, denoms } = this
    const depNum = this.dependents.length
    const blockRows = this.getCainBlockRows(blockIndex)
    const xNegRow = blockRows[1]
    const depRowKey = this.makeCombKey(xNegRow.combination.slice(0, depNum))
    const childState = last(xNegRow.combination)
    if (!childState) {
      return
    }
    const xNegKey = this.makeCombKey(xNegRow.combination)
    const childStateKey = this.makeCondKey(childState)
    if (!ifs[depRowKey]) {
      ifs[depRowKey] = {}
    }
    const xNegVal = responseMap.get(xNegKey)?.response?.value || 0
    if (!ifs[depRowKey][childStateKey]) {
      ifs[depRowKey][childStateKey] = {}
    }
    const nonExtremeRows = blockRows.slice(2)
    nonExtremeRows.forEach((row) => {
      const key = this.makeCombKey(row.combination)
      const val = responseMap.get(key)?.response?.value || 0
      const indepComb = row.combination.slice(depNum).slice(0, -1)
      const nonPosCond = this.findNonPos(indepComb)
      if (!nonPosCond) {
        console.log('Something wrong')
        return
      }
      const { variable, state } = nonPosCond
      if (!ifs[depRowKey][childStateKey][variable.id]) {
        ifs[depRowKey][childStateKey][variable.id] = {}
      }
      const denom = denoms[depRowKey][childStateKey]
      if (denom === 0) {
        ifs[depRowKey][childStateKey][variable.id][state.id] = 0
      } else {
        ifs[depRowKey][childStateKey][variable.id][state.id] = (val - xNegVal) / denom
      }
    })
  }

  calcDenomsAndIfs(responseMap: ResponseMap): void {
    const blockNum = this.getCainBlockNum()
    for (let i = 0; i < blockNum; i++) {
      this.calcDenom(responseMap, i)
      this.calcIfs(responseMap, i)
    }
  }

  getInterpolation(
    responseMap: ResponseMap,
    depComb: Combination,
    childState: Condition,
    indepComb: Combination
  ): number {
    const { ifs } = this
    const seedComb: Combination = []
    let negativeCond: Condition
    const ifConds: Array<Condition> = []
    indepComb.forEach((condition: Condition) => {
      const { variable, state } = condition
      if (state.isPositive()) {
        seedComb.push(condition)
      } else {
        if (!negativeCond) {
          negativeCond = condition
          seedComb.push(condition)
        } else {
          seedComb.push(variable.getPositiveState())
          ifConds.push(condition)
        }
      }
    })
    const depRowKey = this.makeCombKey(depComb)
    const childStateKey = this.makeCondKey(childState)
    const seedKey =
      depRowKey +
      CPT.COMB_DELIM +
      this.makeCombKeyNoPrefix(seedComb) +
      CPT.COMB_DELIM +
      childStateKey
    const prob = responseMap.get(seedKey)?.response?.value || 0
    const base = this.bases[depRowKey][childStateKey]
    let multiplier = 1
    ifConds.forEach(({ variable, state }: Condition) => {
      multiplier *= ifs[depRowKey][childStateKey][variable.id][state.id]
    })
    return (prob - base) * multiplier + base
  }

  interpolate(responseMap: ResponseMap, isAnonymous = true): any {
    if (!this.independents.length) {
      return this.interpolateForAllDependents(responseMap)
    }

    const rows: Array<CPTSummaryRow> = []
    const rowsMap: Record<string, Record<string, any>> = {}
    let value: number

    const result: Map<string, any> = new Map()
    const { independentCombinations } = this
    this.calcDenomsAndIfs(responseMap)
    this.dependentCombinations.forEach((depComb: Combination) => {
      this.variable.getAllStates().forEach((childState: Condition) => {
        independentCombinations.forEach((indepComb: Combination) => {
          const parentKey =
            this.makeCombKey(depComb) + CPT.COMB_DELIM + this.makeCombKeyNoPrefix(indepComb)
          const childStateKey = this.makeCondKey(childState)
          const key = parentKey + CPT.COMB_DELIM + childStateKey
          if (responseMap.has(key)) {
            value = responseMap.get(key)?.response?.value || 0
          } else {
            value = this.getInterpolation(responseMap, depComb, childState, indepComb)
          }
          result.set(key, value)
          if (!rowsMap[parentKey]) {
            rowsMap[parentKey] = {}
          }
          rowsMap[parentKey][childStateKey] = value
        })
      })
    })
    this.dependentCombinations.forEach((depComb: Combination) => {
      independentCombinations.forEach((indepComb: Combination) => {
        const parentKey =
          this.makeCombKey(depComb) + CPT.COMB_DELIM + this.makeCombKeyNoPrefix(indepComb)
        const parents: Combination = depComb.concat(indepComb)
        const outcomes: Array<CPTSummaryOutcome> = []
        const allStates: Condition[] = this.variable.getAllStates()
        allStates.forEach((childState: Condition) => {
          const childStateKey = this.makeCondKey(childState)
          const value = rowsMap[parentKey][childStateKey]
          let rationale = ''
          const key = parentKey + CPT.COMB_DELIM + childStateKey
          rationale = anonymizeRationale(
            responseMap.get(key)?.response?.rationale || '',
            isAnonymous
          )
          const outcome: CPTSummaryOutcome = {
            state: childState.state,
            value: value / 100.0,
            rationale
          }
          outcomes.push(outcome)
        })
        const row: CPTSummaryRow = {
          parents,
          outcomes
        }
        rows.push(row)
      })
    })
    const cptSummary: CPTSummary = {
      variable: this.variable,
      rows
    }
    return cptSummary
  }

  interpolateForAllDependents(responseMap: ResponseMap): any {
    const rows: Array<CPTSummaryRow> = []
    const rowsMap: Record<string, Record<string, any>> = {}
    let value: number

    const result: Map<string, any> = new Map()
    this.dependentCombinations.forEach((depComb: Combination) => {
      this.variable.getAllStates().forEach((childState: Condition) => {
        const parentKey = this.makeCombKey(depComb)
        const childStateKey = this.makeCondKey(childState)
        const key = parentKey + CPT.COMB_DELIM + childStateKey
        if (responseMap.has(key)) {
          value = responseMap.get(key)?.response?.value || 0
        } else {
          value = 0
        }
        result.set(key, value)
        if (!rowsMap[parentKey]) {
          rowsMap[parentKey] = {}
        }
        rowsMap[parentKey][childStateKey] = value
      })
    })
    this.dependentCombinations.forEach((depComb: Combination) => {
      const parentKey = this.makeCombKey(depComb)
      const parents: Combination = depComb
      const outcomes: Array<CPTSummaryOutcome> = []
      this.variable.getAllStates().forEach((childState: Condition) => {
        const childStateKey = this.makeCondKey(childState)
        const value = rowsMap[parentKey][childStateKey]
        const outcome: CPTSummaryOutcome = {
          state: childState.state,
          value: value / 100.0
        }
        outcomes.push(outcome)
      })
      const row: CPTSummaryRow = {
        parents,
        outcomes
      }
      rows.push(row)
    })
    const cptSummary: CPTSummary = {
      variable: this.variable,
      rows
    }
    return cptSummary
  }

  summarize(analyticsMap: Record<string, any>, isAnonymous = true): CPTSummary | null {
    const responseMap = getResponseMapForVar(
      analyticsMap,
      this.variable,
      this.elicitedRows || [],
      this.dependentMap
    )
    if (!this.isCompleteData(responseMap)) {
      return null
    }
    return this.interpolate(responseMap, isAnonymous)
  }

  generateTabularData(responseMap: Record<string, any>): any[] {
    const { tableRows } = this
    const data: any[] = []
    const allVarStates = this.variable.getAllStates()

    const depLength = this.dependentCombinations.length
    const cainHeaders: string[] = []
    this.tableRows?.[0].combination.forEach((_, index) => {
      let headerVar
      let headerState
      if (index < depLength) {
        headerVar = `DepVar${index + 1}`
        headerState = `DepState${index + 1}`
      } else {
        headerVar = `IndepVar${index - depLength + 1}`
        headerState = `IndepState${index - depLength + 1}`
      }
      cainHeaders.push(headerVar)
      cainHeaders.push(headerState)
    })
    data.push(simpleRow(['Cain']))
    data.push(
      simpleRow(['Variable', normalizeName(this.variable.name), `(${allVarStates.length} states)`])
    )
    data.push(simpleRow(['']))
    data.push(simpleRow([...cainHeaders, 'State', 'Value', 'Rationale']))

    tableRows?.forEach((combRow) => {
      const combData: string[] = []
      combRow?.combination?.forEach((condition: Condition) => {
        const { state, variable } = condition
        combData.push(normalizeName(variable.name))
        combData.push(state.name)
      })
      allVarStates.forEach((condition: Condition) => {
        const parentKey = this.makeCombKey(combRow?.combination)
        const childStateKey = this.makeCondKey(condition)
        const key = parentKey + CPT.COMB_DELIM + childStateKey
        const value = responseMap.get(key)?.response?.value
        const rationale = responseMap.get(key)?.response?.rationale
        const dataRow = [...combData, condition.state.name, numberCell(value), rationale]
        data.push(complexRow(dataRow))
      })
    })
    return data
  }

  generateAnalyticsTabularData(
    analyticsMap: Record<string, AnalyticsRecord>,
    users: User[],
    userMap: Dict,
    isAnonymous = false
  ): any[] {
    // Use [...userIds, 'final'] for adding summary
    if (!this.elicitedRows) {
      return []
    }
    const record: AnalyticsRecord = analyticsMap[this.variable.id]
    if (!record) {
      return []
    }
    const analyticsByUserMap: DefaultDict = record.userMap
    const data: any[] = []
    const allVarStates = this.variable.getAllStates()

    const depLength = this.dependentCombinations.length
    const cainHeaders: any[] = ['User ID']
    this.tableRows?.[0].combination.forEach((_, index) => {
      let headerVar
      let headerState
      if (index < depLength) {
        headerVar = `DepVar${index + 1}`
        headerState = `DepState${index + 1}`
      } else {
        headerVar = `IndepVar${index - depLength + 1}`
        headerState = `IndepState${index - depLength + 1}`
      }
      cainHeaders.push(headerVar)
      cainHeaders.push(headerState)
    })
    data.push(simpleRow(['Cain']))
    data.push(
      simpleRow(['Variable', normalizeName(this.variable.name), `(${allVarStates.length} states)`])
    )
    data.push(simpleRow(['']))
    data.push(simpleRow([...cainHeaders, 'State', 'Value', 'Rationale']))

    users.forEach(({ id: userId }, userIndex: number) => {
      let responseMap: ResponseMap
      if (userId === 'final') {
        responseMap = this.generateResponseMapFromXAggByCombKey(record.xaggMap)
      } else {
        const userItem = analyticsByUserMap.get(userId)
        if (!userItem) {
          return
        }
        responseMap = this.generateResponseMapByCombKey(userItem.responseMap)
      }
      this.tableRows?.forEach((combRow) => {
        const combData: string[] = []
        combRow?.combination?.forEach((condition: Condition) => {
          const { state, variable } = condition
          combData.push(normalizeName(variable.name))
          combData.push(state.name)
        })
        allVarStates.forEach((condition: Condition) => {
          const parentKey = this.makeCombKey(combRow?.combination)
          const childStateKey = this.makeCondKey(condition)
          const key = parentKey + CPT.COMB_DELIM + childStateKey
          const response = responseMap.get(key)?.response
          if (!response) {
            return
          }
          const userId = response.userId || 'final'
          const userName = this.generateUserName(userId, userIndex, userMap, isAnonymous)
          const value = response?.value
          const rationale = anonymizeRationale(response?.rationale, isAnonymous)
          const dataRow = [
            userName,
            ...combData,
            condition.state.name,
            numberCell(value),
            rationale
          ]
          data.push(complexRow(dataRow))
        })
      })
    })
    return data
  }
}
