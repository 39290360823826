<template>
  <div v-if="combination" :class="cx('sz-prob-selector', probStyle)">
    <a-input
      v-model:value="probSummary"
      :disabled="disabled"
      :tabindex="tabIndex"
      class="sz-input-prob-selector"
    />
    <div :class="dotStyle" @click="showDialog"></div>
    <a-modal
      v-model:visible="visible"
      wrap-class-name="sz-prob-choices-dialog"
      width="85vw"
      :body-style="bodyStyle"
      size="small"
      title="Select Distribution"
      @ok="handleOk"
      @cancel="closeDialog"
    >
      <div class="sz-prob-choices-body">
        <a-radio-group v-model:value="probType">
          <div class="prob-choices">
            <div v-for="dist in DISTRIBUTIONS" :key="dist.id" class="prob-choice">
              <div>
                <a-radio :value="dist.id" />
              </div>
              <div>
                <img v-if="dist.id != 'none'" :src="`/images/stats/${dist.id}.svg`" />
                <div>{{ dist.label }}</div>
              </div>
              <div class="sz-prob-choices-params">
                <div v-for="param in dist.parameters" :key="param.id" class="sz-prob-choices-param">
                  <span class="label">{{ param.label }}</span>
                  <a-input-number
                    v-model:value="formData[dist.id][param.id]"
                    :precision="2"
                    :step="0.001"
                    :min="0"
                    :max="100"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
        </a-radio-group>
      </div>
    </a-modal>
    <div
      v-if="allowCritical"
      :class="criticalStyle"
      :title="critical ? 'Critical' : 'Non-critical'"
      @click="onToggleCritical"
    >
      <Pinned v-if="critical" class="critical-on" />
      <Pin v-else class="critical-off" />
    </div>
  </div>
</template>

<script lang="ts">
import { css, cx } from '@emotion/css'
import { Pin, Pinned } from '@vicons/tabler'
import { computed, defineComponent, onMounted, PropType, reactive, ref, watch } from 'vue'

import { DISTRIBUTION_FORM_DATA, DISTRIBUTIONS } from '@/constants/probs'
import { Variable } from '@/libs/bayes'

import { EVENTS, useProbSelector } from './common'
import { criticalStyle, dotStyle } from './styles'

export const probStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .ant-input {
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;

    .ant-input-input-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 20px;
      bottom: 0;

      .ant-input-input {
        text-align: center;
        margin-right: 21px;
        font-size: 18px;
        height: 100%;
      }
    }
  }
`

export default defineComponent({
  components: {
    Pin,
    Pinned
  },
  props: {
    allowCritical: { type: Boolean, default: false },
    colIndex: { type: Number, default: undefined },
    tabIndex: { type: Number, default: undefined },
    combination: { type: Object, required: true },
    combKey: { type: String, required: true },
    disabled: { type: Boolean, required: true },
    discrete: { type: Boolean, default: false },
    response: { type: Object, default: undefined },
    rowId: { type: String, default: undefined },
    rowIndex: { type: Number, default: undefined },
    variable: { type: Object as PropType<Variable>, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const probType = ref<any>(undefined)
    const visible = ref<boolean>(false)

    const formData = reactive({ ...DISTRIBUTION_FORM_DATA })

    const prob = ref<number | undefined>(undefined)
    const probT = ref<number | undefined>(undefined)
    const probSummary = ref<string>('')
    const critical = ref<boolean>(false)
    const isDeterministic = computed(() => props.variable?.isDeterministic())

    const { emitProbSelectorChange, onToggleCritical } = useProbSelector(
      props,
      emit,
      formData,
      probType,
      critical
    )

    /**
     * Sync response
     */
    const updateValuesFromProps = () => {
      if (props.response?.value !== prob.value || props.response?.value !== probT.value) {
        prob.value = props.response?.value
        probT.value = props.response?.value
      }
      if (props.response?.ext?.critical !== critical.value) {
        critical.value = props.response?.ext?.critical
      }
      if (props.response?.ext?.probabilityConfig) {
        const p = props.response?.ext?.probabilityConfig
        probType.value = p.id
        probSummary.value = p.id + ' [' + Object.values(p.params).join(',') + ']'
        Object.keys(p.params).forEach((k) => {
          formData[p.id][k] = p.params[k]
        })
      }
    }

    // When first load component, update init value
    onMounted(() => {
      updateValuesFromProps()
    })

    watch(
      () => props.response,
      () => {
        updateValuesFromProps()
      }
    )

    const handleOk = () => {
      visible.value = false
      const probConfig = emitProbSelectorChange()
      if (probConfig) {
        probSummary.value = probConfig.id + ' [' + Object.values(probConfig.params).join(',') + ']'
      } else {
        probSummary.value = ''
      }
    }

    const showDialog = () => {
      if (!props.disabled) {
        visible.value = true
      }
    }

    const closeDialog = () => {
      visible.value = false
    }

    const bodyStyle = {
      height: 'calc(100vh - 300px)',
      overflow: 'auto',
      backgroundColor: '#f5f5f5'
    }

    return {
      probSummary,
      showDialog,
      closeDialog,
      onToggleCritical,
      critical,
      criticalStyle,
      cx,
      dotStyle,
      prob,
      probStyle,
      probType,
      isDeterministic,
      probT,
      DISTRIBUTIONS,
      formData,
      visible,
      handleOk,
      bodyStyle
    }
  }
})
</script>

<style lang="stylus">


.sz-prob-choices-body {
  overflow: auto;

  > div {
    width: 100%;
  }
}

.prob-choices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  .prob-choice {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    margin-bottom: 9px;
    align-items: center;
    border-radius: 5px;
    background-color: white;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    > div:last-child {
      margin-left: 10px;
    }

    .sz-prob-choices-params {
      .sz-prob-choices-param {
        .label {
          display: inline-block;
          width: 80px;
          text-align: right;
          padding-right: 5px;
        }

        .ant-input {
          width: 100px;
          text-align: center;
        }

      }

    }
  }

  img {
    width: 120px;
  }
}
</style>
