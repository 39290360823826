import { getColumnWidth } from '@/libs/utils'

export const COLUMNS = {
  ACTION: {
    TITLE: '',
    KEY: 'action',
    DATA_INDEX: 'variable',
    SLOTS: {
      CUSTOM_RENDER: 'action'
    }
  },
  VAR_INDEX: {
    TITLE: 'No',
    KEY: 'variableIndex',
    DATA_INDEX: 'variableIndex'
  },
  VAR_NAME: {
    TITLE: 'Name',
    DATA_INDEX: 'variableName',
    KEY: 'variableName',
    SLOTS: {
      TITLE: 'variableTitle',
      CUSTOM_RENDER: 'variable-name',
      FILER_DROPDOWN: 'filterDropdown',
      FILER_ICON: 'filterIcon'
    }
  },
  ANALYTICS: {
    TITLE: '',
    DATA_INDEX: 'stats',
    KEY: 'analytics',
    SLOTS: {
      TITLE: 'analyticsTitle',
      CUSTOM_RENDER: 'analytics'
    }
  },
  DEPENDENCY: {
    TITLE: 'D',
    DATA_INDEX: 'dependency',
    KEY: 'dependency',
    SLOTS: {
      TITLE: 'dependencyTitle',
      CUSTOM_RENDER: 'dependency'
    }
  },
  COLL_METHOD: {
    TITLE: 'C',
    DATA_INDEX: 'collectionMethod',
    KEY: 'collectionMethod',
    SLOTS: {
      TITLE: 'collectionMethodTitle',
      CUSTOM_RENDER: 'collectionMethod'
    }
  },
  STATES: {
    TITLE: 'States',
    DATA_INDEX: 'states',
    KEY: 'states',
    SLOTS: {
      TITLE: 'statesTitle',
      CUSTOM_RENDER: 'states'
    }
  },
  OUTPUT: {
    TITLE: 'Out',
    DATA_INDEX: 'isOutput',
    KEY: 'isOutput',
    SLOTS: {
      CUSTOM_RENDER: 'isOutput'
    }
  },
  ALLOCATION: {
    SLOTS: {
      CUSTOM_RENDER: 'allocation'
    }
  }
}

export const TABLE_HEADER_HEIGHT = 39
export const TABLE_PAGNATION_HEIGHT = 56
export const VAR_NAME_PAD = 22 * 2 + 10 // padding for parent and dependent flag
export const VAR_NAME_MIN_WIDTH = getColumnWidth(COLUMNS.VAR_NAME.TITLE) + VAR_NAME_PAD

export const DEPENDENCY_SEARCH_VALUE = {
  TRUE: {
    TEXT: 'Dependent',
    VALUE: 'true'
  },
  FALSE: {
    TEXT: 'Independent',
    VALUE: 'false'
  }
}

export const COLL_METHOD_SEARCH_VALUE = {
  TRUE: {
    TEXT: 'Cain',
    VALUE: 'true'
  },
  FALSE: {
    TEXT: 'Default',
    VALUE: 'false'
  }
}

export interface TableConfig {
  analyticsVisible?: boolean
  actionVisible?: boolean
  allocationVisible?: boolean
  dependencyVisible?: boolean
  collectionMethodVisible?: boolean
  indexVisible?: boolean
  statesVisible?: boolean
  variableNameVisible?: boolean
}
