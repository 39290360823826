<template>
  <table class="sza-matrix" :class="cssTable">
    <caption></caption>
    <thead>
      <tr class="header">
        <th></th>
        <th v-for="(parent, c) in matrix?.[0]" :key="c">{{ c }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, r) in matrix" :key="r" class="row">
        <td>{{ r }}</td>
        <td v-for="(val, c) in row" :key="c" class="value" :style="cellStyle(r, c)">
          {{ probFormatter(val, precision) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { css } from '@emotion/css'
import { defineComponent, PropType } from 'vue'

import { probFormatter } from '@/libs/common'

export const cssTable = css`
  font-size: 12px;
  td,
  th {
    width: 32px;
    height: 32px;
    padding-right: 4px;
    text-align: right;
    border: 1px solid #f0f0f0;
  }
`
export default defineComponent({
  props: {
    matrix: { type: Array as PropType<Array<number[]> | undefined>, default: () => [[]] },
    precision: { type: Number, default: 2 }
  },
  setup() {
    const cellStyle = (r: number, c: number) => {
      const backgroundColor = r === c ? '#f0f0f0' : 'white'
      return {
        backgroundColor
      }
    }

    return {
      cellStyle,
      cssTable,
      probFormatter
    }
  }
})
</script>
