/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum NetworkActionEnum {
  CREATE_NETWORK = 'CREATE_NETWORK',
  UPDATE_NETWORK = 'UPDATE_NETWORK',
  DELETE_NETWORK = 'DELETE_NETWORK',
  GET_NETWORKS = 'GET_NETWORKS',
  GET_NETWORK = 'GET_NETWORK',
  IMPORT_NETWORK = 'IMPORT_NETWORK'
}
