import { computed, Ref, ref } from 'vue'

import { CATEGORICAL_PALETTE_MAP, ColorSchemeItem } from '@/components/common/color-scheme'

export default function usePalette(): any {
  const paletteVisible: Ref<boolean> = ref(false)
  const paletteName: Ref<string> = ref('Dark2')
  const paletteColors = computed(() =>
    CATEGORICAL_PALETTE_MAP[paletteName.value]?.items?.map(({ color }: ColorSchemeItem) => color)
  )

  const showPalettePicker = () => {
    paletteVisible.value = !paletteVisible.value
  }

  const selectPalette = (name: string) => {
    paletteName.value = name
  }

  return {
    paletteVisible,
    paletteName,
    paletteColors,
    selectPalette,
    showPalettePicker
  }
}
