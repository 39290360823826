import { ActionTree } from 'vuex'

import { getAllocations } from '@/services/api/allocation'
import { authCurrent } from '@/services/api/auth'
import { getNetworkCPTs, getSurveyCPTs } from '@/services/api/cpt'
import { getNetworks } from '@/services/api/network'
import { getSurveyResponses } from '@/services/api/response'
import { createResponse, updateResponse } from '@/services/api/response'
import { QuestionnaireActionEnum as Action } from '@/store/enums/actions/questionnaire'
import { QuestionnaireMutationEnum as Mutation } from '@/store/enums/mutations/questionnaire'
import type { RootState } from '@/store/types'
import { QuestionnaireAction } from '@/store/types/actions/questionnaire'
import { QuestionnaireState } from '@/store/types/states/questionnaire'

/**
 * Action function
 */
export const actions: ActionTree<QuestionnaireState, RootState> & QuestionnaireAction = {
  async [Action.GET_DATA]({ commit, dispatch }, payload) {
    const { surveyId, workspaceId, userId, networkId } = payload
    commit(Mutation.SET_IS_FETCHING, true)
    const promises = []
    promises.push(dispatch(Action.GET_SURVEY_CPTS, { surveyId, getAll: true }))
    promises.push(dispatch(Action.GET_SURVEY_RESPONSES, { surveyId, userId, networkId }))
    promises.push(dispatch(Action.GET_WORKSPACE_USER_ALLOCATIONS, { workspaceId, userId }))
    await Promise.all(promises)
    commit(Mutation.SET_IS_FETCHING, false)
  },
  async [Action.GET_SURVEY_CPTS]({ commit }, payload) {
    const cpts = await getSurveyCPTs(payload)
    commit(Mutation.GET_SURVEY_CPTS, cpts)
    return cpts
  },
  async [Action.GET_SURVEY_RESPONSES]({ commit }, payload) {
    const responses = await getSurveyResponses(payload)
    commit(Mutation.GET_SURVEY_RESPONSES, responses)
    return responses
  },
  async [Action.GET_NETWORK_CPTS]({ commit }, networkId) {
    commit(Mutation.SET_IS_FETCHING, true)
    getNetworkCPTs(networkId)
      .then((res) => {
        commit(Mutation.GET_NETWORK_CPTS_FULFILLED, res)
      })
      .catch((err) => {
        commit(Mutation.GET_NETWORK_CPTS_REJECTED, err)
      })
    commit(Mutation.SET_IS_FETCHING, false)
  },
  async [Action.GET_WORKSPACE_USER_ALLOCATIONS]({ commit }, { workspaceId, userId }) {
    const params = {
      userId
    }
    const allocations = await getAllocations(workspaceId, params)
    commit(Mutation.GET_WORKSPACE_USER_ALLOCATIONS, allocations)
    return allocations
  },
  async [Action.GET_WORKSPACE_NETWORKS]({ commit }, payload) {
    const networks = await getNetworks(payload)
    commit(Mutation.GET_WORKSPACE_NETWORKS, networks)
    return networks
  },
  async [Action.CREATE_RESPONSES](context, payload) {
    const promises = []
    await authCurrent() // making sure auth is fine, before a group of API call promises
    for (const response of payload) {
      const { surveyId } = response
      if (surveyId) {
        promises.push(createResponse({ id: surveyId, response }))
      }
    }
    await Promise.all(promises)
  },
  async [Action.UPDATE_RESPONSES](context, payload) {
    const promises = []
    await authCurrent() // making sure auth is fine, before a group of API call promises
    for (const response of payload) {
      promises.push(updateResponse(response))
    }
    await Promise.all(promises)
  },
  async [Action.PATCH_RESPONSES](context, payload) {
    const promises = []
    await authCurrent() // making sure auth is fine, before a group of API call promises
    for (const response of payload) {
      const { id, surveyId } = response
      if (id) {
        promises.push(updateResponse(response))
      } else if (surveyId) {
        promises.push(createResponse({ id: surveyId, response }))
      }
    }
    await Promise.all(promises)
  }
}
