
import { boxplot } from '@sgratzl/boxplots'
import { defineComponent, PropType } from 'vue'

const findModes = (data: Array<number>) => {
  const tally = data.reduce((t: Map<number, number>, x: number) => {
    t.set(x, (t.get(x) || 0) + 1)
    return t
  }, new Map())
  const sortedTally = [...tally].sort((x1, x2) => x2[1] - x1[1])
  return sortedTally
    .filter((x) => x[1] === sortedTally[0][1])
    .slice(0, 3)
    .map((x) => x[0])
}

export const OVERRIDE = 'override'

export default defineComponent({
  props: {
    data: { type: Array as PropType<Array<number>>, default: () => [] },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 60 }
  },
  emits: [OVERRIDE],
  setup(props, { emit }) {
    const startY = 0
    const lblWidth = 30
    const barHeight = 10
    const barHeightHalf = 5
    const valueY = startY + barHeight + 15
    const labelY = valueY + 12
    const auxY = labelY + 18
    const offset = 20

    const { min, q1, mean, q3, max, median } = boxplot(props.data)
    const modes = findModes(props.data)
    const qs = [min, q1, mean, q3, max]
    const labels = ['MIN', 'Q1', 'MEAN', 'Q3', 'MAX']
    const qWidth = max - min
    const items = qs.map((q, index) => {
      const x = qWidth
        ? Math.round(((q - min) * (props.width - offset * 2)) / qWidth) + offset
        : Math.round((index * (props.width - offset * 2)) / 4) + offset
      const value = q.toFixed(2)
      const label = labels[index]
      return {
        x,
        label,
        q,
        value
      }
    })

    const pick = (value: number) => {
      emit(OVERRIDE, parseFloat(value.toFixed(2)))
    }

    return {
      barHeightHalf,
      startY,
      labelY,
      auxY,
      valueY,
      pick,
      median,
      modes,
      items,
      lblWidth,
      barHeight
    }
  }
})
