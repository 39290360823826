<template>
  <div class="sza-sa-table">
    <a-tabs v-model:activeKey="activeTab" tab-position="top">
      <a-tab-pane :key="TABS.CHANCE" tab="Chance input">
        <div v-if="chanceRows.length" ref="tableWrapper" class="sza-sa-results-wrapper">
          <div class="subheading">Chance input</div>
          <a-table
            size="small"
            :scroll="{ y: scrollY, x: scrollX }"
            :data-source="chanceRows2"
            :columns="columns"
            :pagination="false"
            width="100%"
          >
            <template #name="{ text }">
              <div class="name">{{ text }}</div>
            </template>
            <template #baseline="{ text }">
              <div class="sz-sa-result-cell">
                {{ text }}
              </div>
            </template>
            <template #value="{ text }">
              <div
                class="sz-sa-result-cell"
                :style="text.isBaseline ? 'background: white' : cellBgStyle(text.value || 0)"
              >
                {{ Number(text.value).toFixed(4) }}
              </div>
            </template>
          </a-table>
          <!-- <div v-if="false">
            <div class="sza-sa-result-header">
              <div class="name">Input node</div>
              <div
                v-for="(variation, j) in allVariations"
                :key="j"
                :style="{
                  border: +j === selectedColIndex ? '1px solid red' : '1px solid transparent'
                }"
                @click="selectCol(parseInt(j))"
              >
                {{ variation }}%
              </div>
            </div>
            <div v-for="(row, index) in chanceRows" :key="index" class="sza-sa-result-row">
              <div class="name">{{ row.name }}</div>
              <div
                v-for="(variation, j) in allVariations"
                :key="j"
                :style="variation !== 0 ? cellBgStyle(row.results[j] || 0) : 'background: white'"
              >
                {{ Number(row.results[j] * 100).toFixed(4) }}
              </div>
            </div>
          </div> -->
        </div>
        <div v-else>No results for chance variable(s)</div>
      </a-tab-pane>
      <a-tab-pane :key="TABS.DETERMINISTIC" tab="Deterministic input">
        <div v-if="detRows.length" class="sza-sa-results-wrapper">
          <div class="subheading">Deterministic input</div>
          <SensitivityResultsDeterministicTable
            :det-rows="detRows"
            :max-det-states="maxDetStates"
          />
          <!-- <div class="sza-sa-result-header">
            <div class="name">Input node</div>
            <div class="baseline">Baseline</div>
            <div v-for="(n, j) in maxDetStates" :key="j" :title="'State' + (n + 1)" class="cell">
              {{ 'State' + (n + 1) }}
            </div>
          </div>
          <div v-for="(row, index) in detRows" :key="index" class="sza-sa-result-row">
            <div class="name">
              <span>{{ row.name }}</span>
            </div>
            <div class="baseline">{{ row.baseline.name }}</div>
            <div
              v-for="(_, j) in maxDetStates"
              :key="j"
              :title="row.states?.[j]?.state?.name"
              class="cell"
            >
              <div v-if="row.states?.[j]?.state">
                <a-tooltip placement="top">
                  <template #title>
                    <div class="sza-sa-det-node-name">
                      {{ row.states?.[j]?.state?.name }}
                    </div>
                  </template>
                  <div>{{ row.results[j] ? Number(row.results[j]).toFixed(4) : '0' }}</div>
                </a-tooltip>
              </div>
            </div>
          </div> -->
        </div>
        <div v-else>No results for deterministic variable(s)</div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script lang="ts">
import { css } from '@emotion/css'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'

import { InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import useAntTableScroll from '@/components/composables/antTableScroll'
import { genCellBgStyleRange } from '@/libs/theme'

import SensitivityResultsDeterministicTable from './SensitivityResultsDeterministicTable.vue'

export const TABS = {
  CHANCE: 'CHANCE',
  DETERMINISTIC: 'DETERMINISTIC'
}

export const EVENTS = {
  ON_MOVE_UP_ROW: 'onMoveUpRow',
  ON_MOVE_DOWN_ROW: 'onMoveDownRow',
  ON_UPDATE_NETWORK: 'onUpdateNetwork',
  ON_CALCULATE: 'onCalculate',
  ON_UPDATE_OBSERVED_NODE: 'onUpdateObservedNode',
  ON_SELECT_COLUMN: 'onSelectColumn',
  ON_SELECT_NODE_TYPE: 'onSelectNodeType'
}

export default defineComponent({
  components: {
    SensitivityResultsDeterministicTable
  },
  props: {
    selections: { type: Object, required: true },
    networks: { type: Object, required: true },
    chanceRows: { type: Object, required: true },
    detRows: { type: Object, required: true },
    allVariations: { type: Object, required: true },
    maxDetStates: { type: Object, required: true },
    baselineIndex: { type: Number, required: true },
    selectedColIndex: { type: Number, default: -1 }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const activeTab = ref(TABS.CHANCE)
    const moveUp = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_UP_ROW, record, rowIndex)
    }

    const moveDown = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_DOWN_ROW, record, rowIndex)
    }
    const maxRange: Ref<number> = ref(1)
    const minRange: Ref<number> = ref(-1)

    const { tableWrapper, scrollY, scrollX } = useAntTableScroll(null, null, 0, 0, false, 190)

    const cellBgStyle = (val: number) => {
      return genCellBgStyleRange(minRange.value, maxRange.value, val)
    }

    const detStateClass = (isBaseState: boolean) => {
      return css`
        display: flex;
        flex-direction: column;
        background-color: ${isBaseState ? 'white' : 'transparent'};
      `
    }

    const updateRange = () => {
      let _max = 0
      let _min = 0
      props.chanceRows.forEach((row: any) => {
        for (let i = 0; i < props.allVariations.length; i++) {
          if (i !== props.baselineIndex) {
            const val = row.results[i]
            if (val > _max) {
              _max = val
            }
            if (val < _min) {
              _min = val
            }
          }
        }
      })
      maxRange.value = _max
      minRange.value = _min
    }

    watch(() => props.chanceRows, updateRange)

    watch(activeTab, (tab: string) => {
      emit(EVENTS.ON_SELECT_NODE_TYPE, tab)
    })

    const chanceRows2 = computed(() => {
      return props.chanceRows.map((row: any) => {
        const row2: any = {
          name: row.name,
          key: row.name,
          baseline: Number(row.baseline * 100).toFixed(4)
        }
        for (let i = 0; i < props.allVariations.length; i++) {
          row2['r' + i] = {
            value: row.results[i],
            isBaseline: i === props.baselineIndex
          }
        }
        return row2
      })
    })

    const columns = computed(() => {
      const cols: ColumnProps[] = [
        {
          title: 'Input node',
          width: 120,
          dataIndex: 'name',
          fixed: 'left',
          key: 'name', // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
          align: 'left',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: 'Baseline %',
          width: 70,
          fixed: 'left',
          dataIndex: 'baseline',
          key: 'baseline',
          align: 'right',
          slots: {
            customRender: 'baseline'
          },
          customHeaderCell: () => {
            return {
              style: {
                'text-align': 'right',
                padding: '1px 3px'
              }
            }
          }
        }
      ]
      for (let i = 0; i < props.allVariations.length; i++) {
        cols.push({
          title: props.allVariations[i] + '%',
          width: 70,
          dataIndex: 'r' + i,
          key: 'r' + i,
          align: 'right',
          sorter: ((i_) => (a: any, b: any) => a['r' + i_].value - b['r' + i_].value)(i),
          customHeaderCell: () => {
            return {
              style: {
                'text-align': 'right',
                padding: '1px 3px'
              }
            }
          },
          slots: {
            customRender: 'value'
          }
        })
      }
      return cols
    })

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    const selectCol = (index: number) => {
      emit(EVENTS.ON_SELECT_COLUMN, index)
    }

    onMounted(() => {
      updateRange()
    })

    return {
      tableWrapper,
      scrollX,
      scrollY,
      activeTab,
      TABS,
      columns,
      chanceRows2,
      detStateClass,
      selectCol,
      update,
      cellBgStyle,
      ROW_TYPE,
      EVENTS,
      InputType,
      moveUp,
      moveDown
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/commons.styl"

.sza-sa-results-wrapper
  overflow auto
  display: flex
  flex-direction: column
  .ant-table
    font-size: 12px
  .ant-table-thead
    th
      padding: 2px !important
  .ant-table-row
    td
      padding: 0 !important
      text-align: center
  .sz-sa-result-cell
    width: 100%
    height: 100%
    text-align: right
    border-right: 1px solid #e0e0e0;
    padding: 1px 3px;
  .subheading
    padding: 3px 10px
    background-color: #c0d0d0;

.sza-sa-det-results
  overflow auto
  display: flex
  flex-direction: column
  .sza-sa-result-header,
  .sza-sa-result-row
    width: 100%
    display: flex
    flex-direction: row
    > div
      flex: 1 1 100px
      font-size: 14px
      background-color: #f0f0f0
      border-right: 1px solid white
      border-bottom: 1px solid white
  .sza-sa-result-header
    > div
      background-color: #e0e0e0
  .cell
    padding: 1px 3px
    text-align: right
    border-right: 1px solid #e0e0e0;
  .subheading
    padding: 3px 10px
    background-color: #c0d0d0;
  .name
    width: 150px
    flex: 0 0 auto !important
    text-align: left
    padding: 3px 5px
  .baseline
    width: 150px
    flex: 0 0 auto !important
    padding: 3px
    text-align: center
</style>
