
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import { computed, defineComponent } from 'vue'
import { Tippy } from 'vue-tippy'

import { StatePolarity } from '@/libs/bayes/enums/StatePolarity'
import { State } from '@/libs/bayes/State'
import { STATE_THEME } from '@/libs/theme'

export default defineComponent({
  components: {
    Tippy
  },
  props: {
    state: State,
    index: { type: Number, default: undefined }
  },
  setup(props) {
    const color = computed(() => STATE_THEME[props.state?.polarity || StatePolarity.NEUTRAL].color)
    return {
      color
    }
  }
})
