<template>
  <a-modal
    class="sza-hybrid-tree-import"
    title="Hierarchy import"
    :visible="isVisible"
    width="1000px"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="container">
      <div>
        <textarea
          ref="tasksTextAreaWrapper"
          style="width: 100%; height: 400px"
          :value="tasksRawValue"
          placeholder="Paste your hierarcy here"
          name="sza-hybrid-tree-import-textarea"
          spellcheck="false"
        />
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    networkMap: { type: Object, default: undefined }
  },
  setup(props, { emit }) {
    const tasksRawValue = ref('')
    const tasksTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (tasksTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      tasksRawValue.value = getSelectionRaw()
      emit(EVENTS.OK, tasksRawValue.value)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      ok,
      cancel,
      tasksRawValue,
      tasksTextAreaWrapper
    }
  }
})
</script>

<style lang="stylus">
.sza-hybrid-tree-import
  .container
    width: 100%
    display: flex
    flex-direction: row
    > div
      flex: 1 1 auto;
</style>
