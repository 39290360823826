/**
 * Mutation types
 */
export enum WorkspaceMutationEnum {
  GET_WORKSPACES = 'GET_WORKSPACES',
  GET_WORKSPACES_FULFILLED = 'GET_WORKSPACES_FULFILLED',
  GET_WORKSPACES_REJECTED = 'GET_WORKSPACES_REJECTED',

  GET_WORKSPACE = 'GET_WORKSPACE',
  GET_WORKSPACE_FULFILLED = 'GET_WORKSPACE_FULFILLED',
  GET_WORKSPACE_REJECTED = 'GET_WORKSPACE_REJECTED',

  CREATE_WORKSPACE = 'CREATE_WORKSPACE',
  CREATE_WORKSPACE_FULFILLED = 'CREATE_WORKSPACE_FULFILLED',
  CREATE_WORKSPACE_REJECTED = 'CREATE_WORKSPACE_REJECTED',

  UPDATE_WORKSPACE = 'UPDATE_WORKSPACE',
  UPDATE_WORKSPACE_FULFILLED = 'UPDATE_WORKSPACE_FULFILLED',
  UPDATE_WORKSPACE_REJECTED = 'UPDATE_WORKSPACE_REJECTED',

  DELETE_WORKSPACE = 'DELETE_WORKSPACE',
  DELETE_WORKSPACE_FULFILLED = 'DELETE_WORKSPACE_FULFILLED',
  DELETE_WORKSPACE_REJECTED = 'DELETE_WORKSPACE_REJECTED'
}
