
import {
  IconCaretDown,
  IconCaretUp,
  IconCircleFilled,
  IconCopy,
  IconEdit,
  IconRun,
  IconTrash
} from '@tabler/icons-vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, ComputedRef, defineComponent } from 'vue'

import VectorInput from '@/components/analysis/common/VectorInput.vue'
import { AnalysisTask, InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import { genCellBgStyle } from '@/libs/theme'

export const EVENTS = {
  ON_EDIT_TASK: 'onEditTask',
  ON_DUPLICATE_TASK: 'onDuplicateTask',
  ON_REMOVE_TASK: 'onRemoveTask',
  ON_RUN_TASK: 'onRunTask',
  ON_TOGGLE_TASK: 'onToggleTask',
  ON_MOVE_UP_ROW: 'onMoveUpRow',
  ON_MOVE_DOWN_ROW: 'onMoveDownRow'
}

export default defineComponent({
  components: {
    IconEdit,
    IconCopy,
    IconRun,
    IconTrash,
    IconCaretDown,
    IconCaretUp,
    IconCircleFilled,
    VectorInput
  },
  props: {
    tasks: { type: Object, required: true },
    selections: { type: Object, required: true },
    rows: { type: Object, required: true },
    multiNetwork: { type: Boolean, required: false }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const columns: ComputedRef<ColumnProps[]> = computed(() => {
      const columns: ColumnProps[] = [
        {
          title: '',
          dataIndex: 'key',
          key: 'leftActions',
          align: 'left',
          width: 30,
          slots: {
            customRender: 'leftActions'
          }
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          customCell: () => ({
            style: {
              paddingRight: '40px !important'
            }
          }),
          slots: {
            customRender: 'name'
          }
        }
      ]
      props.tasks?.forEach((task: any) => {
        columns.push({
          title: task.name,
          dataIndex: task.id,
          align: 'center',
          key: task.id,
          slots: {
            customRender: 'value'
          },
          customHeaderCell: () => {
            return {
              'data-task-name': task.name,
              'data-task-id': task.id
            }
          }
        })
      })
      return columns
    })

    const data = computed(() => {
      return props.rows
    })

    const moveUp = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_UP_ROW, record, rowIndex)
    }

    const moveDown = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_DOWN_ROW, record, rowIndex)
    }

    const cellBgStyle = (val: number) => {
      return genCellBgStyle(val)
    }

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    const duplicateTask = (taskId: string, taskIndex: number) => {
      emit(EVENTS.ON_DUPLICATE_TASK, taskId, taskIndex)
    }

    const removeTask = (taskId: string, taskIndex: number) => {
      emit(EVENTS.ON_REMOVE_TASK, taskId, taskIndex)
    }

    const runTask = (task: AnalysisTask) => {
      emit(EVENTS.ON_RUN_TASK, task)
    }

    const toggleTask = (task: AnalysisTask) => {
      emit(EVENTS.ON_TOGGLE_TASK, task)
    }

    const editTask = (task: AnalysisTask) => {
      emit(EVENTS.ON_EDIT_TASK, task)
    }

    return {
      editTask,
      toggleTask,
      update,
      runTask,
      duplicateTask,
      removeTask,
      data,
      columns,
      cellBgStyle,
      ROW_TYPE,
      EVENTS,
      InputType,
      moveUp,
      moveDown
    }
  }
})
