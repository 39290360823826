/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { XMLBuilder, XMLParser } from 'fast-xml-parser'
import { saveAs } from 'file-saver'
import { includes } from 'lodash-es'

import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { CPTSet } from '@/libs/bayes/CPTSet'
import { CPTSummary } from '@/libs/bayes/CPTSummary'
import { CPTType } from '@/libs/bayes/enums'
import { Dict } from '@/libs/common'
import { getAllocations } from '@/services/api/allocation'
import { createCPT, createCPTs, deleteCPT, deleteCPTs, getSurveyCPTs } from '@/services/api/cpt'
import { getNetworkSource } from '@/services/api/network'
import { deleteResponse, getSurveyResponses } from '@/services/api/response'
import { getSurveys } from '@/services/api/survey'
import {
  createSurveyStatus,
  deleteSurveyStatus,
  getSurveyStatuses
} from '@/services/api/surveyStatus'
import {
  Allocation,
  CPTEntrySchema,
  CPTSummarySchema,
  NetworkSchema,
  SurveySchema,
  SurveyStatus,
  SurveyStatusForm,
  User,
  Workspace
} from '@/types'

import {
  createCPTSummary,
  deleteCPTSummary,
  exportXDSL,
  getSurveyCPTSummaries,
  traceNetwork
} from '../api/cptSummary'

const {
  SURVEY_STATUS: { SURVEY_ID, USER_ID, COMPLETION_RATE, STATUS }
} = DB_FIELDS

/**
 * Clean CPTs
 * @returns Promise
 */
export const cleanCPTs = async (surveyId: SurveySchema['id']): Promise<any> => {
  const promises = []
  const cpts = (await getSurveyCPTs({ surveyId, getAll: true })).content
  for (const cpt of cpts) {
    const { id } = cpt
    promises.push(deleteCPT(id))
  }
  return Promise.all(promises)
}

export const cleanCPTsNext = async (surveyId: SurveySchema['id']): Promise<any> => {
  return deleteCPTs(surveyId)
}

export const saveCPTsNext = async (cptSet: CPTSet, surveyId: SurveySchema['id']): Promise<any> => {
  const cpts: Array<CPTEntrySchema> = cptSet.serialize(CPTType.ELICITED, surveyId)
  return createCPTs(cpts)
}

/**
 * Clean Responses
 * @returns Promise
 */
export const cleanResponses = async (surveyId: SurveySchema['id']): Promise<any> => {
  const promises = []
  const surveyResponses = (await getSurveyResponses({ surveyId })).content
  for (const res of surveyResponses) {
    const { id } = res
    promises.push(deleteResponse(id))
  }
  return Promise.all(promises)
}

/**
 * Clean Survey Status
 * @returns Promise
 */
export const cleanSurveyStatuses = async (surveyId: SurveySchema['id']): Promise<any> => {
  const promises = []
  const surveyStatuses = (await getSurveyStatuses({ surveyId, getAll: true })).content
  for (const surveyStatus of surveyStatuses) {
    const { id } = surveyStatus
    promises.push(deleteSurveyStatus(id))
  }
  return Promise.all(promises)
}

export const saveCPTs = async (cptSet: CPTSet, surveyId: SurveySchema['id']): Promise<any> => {
  const cpts: Array<CPTEntrySchema> = cptSet.serialize(CPTType.ELICITED, surveyId)
  const promises = cpts.map((cpt) => createCPT(cpt))
  return Promise.all(promises)
}

export const initSurveyStatuses = async (
  workspaceId: Workspace['id'],
  surveyId: SurveySchema['id']
): Promise<any> => {
  const promises = []
  const allocations = (await getAllocations(workspaceId)).content
  const userIds = [...new Set(allocations.map((allocation: Allocation) => allocation.userId))]
  for (const userId of userIds) {
    const surveyUserStatus = {
      [SURVEY_ID]: surveyId,
      [USER_ID]: userId,
      [STATUS]: DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING,
      [COMPLETION_RATE]: 0
    } as SurveyStatusForm
    promises.push(createSurveyStatus(surveyUserStatus))
  }
  return Promise.all(promises)
}

export const updateSurveyStatusForUser = async (
  workspaceId: Workspace['id'],
  userId: User['id'],
  initStatus: boolean
): Promise<any> => {
  if (!userId || !workspaceId) {
    return Promise.reject('Empty workspaceId or userId')
  }

  const promises = []
  const surveys = (await getSurveys(workspaceId))?.content || []
  const surveyIds = surveys.map((survey: SurveySchema) => survey.id)
  for (const surveyId of surveyIds) {
    const surveyStatuses = (await getSurveyStatuses({ surveyId, getAll: true }))?.content
    if (surveyStatuses) {
      for (const surveyStatus of surveyStatuses) {
        const { id, userId: existingUserId } = surveyStatus
        if (userId === existingUserId) {
          promises.push(deleteSurveyStatus(id))
        }
      }
      if (initStatus) {
        const surveyUserStatus = {
          [SURVEY_ID]: surveyId,
          [USER_ID]: userId,
          [STATUS]: DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING,
          [COMPLETION_RATE]: 0
        } as SurveyStatusForm
        promises.push(createSurveyStatus(surveyUserStatus))
      }
    }
  }
  return Promise.all(promises)
}

export const cleanSurveyStatusForUser = async (
  workspaceId: Workspace['id'],
  userId: User['id']
): Promise<any> => {
  if (!userId || !workspaceId) {
    return Promise.reject('Empty workspaceId or userId')
  }

  const promises = []
  const surveys = (await getSurveys(workspaceId))?.content || []
  const surveyIds = surveys.map((survey: SurveySchema) => survey.id)
  for (const surveyId of surveyIds) {
    const surveyStatuses = (await getSurveyStatuses({ surveyId, getAll: true }))?.content
    if (surveyStatuses) {
      for (const surveyStatus of surveyStatuses) {
        const { id, userId: existingUserId } = surveyStatus
        if (userId === existingUserId) {
          promises.push(deleteSurveyStatus(id))
        }
      }
    }
  }
  return Promise.all(promises)
}

export const initSurveyStatusesForUser = async (
  workspaceId: Workspace['id'],
  userId: User['id']
): Promise<any> => {
  if (!userId || !workspaceId) {
    return Promise.reject('Empty workspaceId or userId')
  }

  const promises = []
  const surveys = (await getSurveys(workspaceId))?.content || []
  const surveyIds = surveys.map((survey: SurveySchema) => survey.id)
  for (const surveyId of surveyIds) {
    const surveyStatuses = (await getSurveyStatuses({ surveyId, getAll: true }))?.content
    if (surveyStatuses) {
      const existingUserIds = surveyStatuses.map(
        (surveyStatus: SurveyStatus) => surveyStatus.userId
      )
      if (!includes(existingUserIds, userId)) {
        const surveyUserStatus = {
          [SURVEY_ID]: surveyId,
          [USER_ID]: userId,
          [STATUS]: DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING,
          [COMPLETION_RATE]: 0
        } as SurveyStatusForm
        promises.push(createSurveyStatus(surveyUserStatus))
      }
    }
  }
  return Promise.all(promises)
}

export const initSurveyStatusesForUsers = async (
  workspaceId: Workspace['id'],
  userIds: string[]
): Promise<any> => {
  if (!userIds?.length || !workspaceId) {
    return Promise.reject('Empty workspaceId or userId')
  }

  const promises: Promise<any>[] = []
  const surveys = (await getSurveys(workspaceId))?.content || []
  const surveyIds = surveys.map((survey: SurveySchema) => survey.id)
  for (const surveyId of surveyIds) {
    const surveyStatuses = (await getSurveyStatuses({ surveyId, getAll: true }))?.content
    if (surveyStatuses) {
      const existingUserIdMap = surveyStatuses.reduce((acc: Dict, surveyStatus: SurveyStatus) => {
        acc[surveyStatus.userId] = true
        return acc
      }, {})
      userIds.forEach((userId: string) => {
        if (!(userId in existingUserIdMap)) {
          const surveyUserStatus = {
            [SURVEY_ID]: surveyId,
            [USER_ID]: userId,
            [STATUS]: DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING,
            [COMPLETION_RATE]: 0
          } as SurveyStatusForm
          promises.push(createSurveyStatus(surveyUserStatus))
        }
      })
    }
  }
  return Promise.all(promises)
}

/**
 * Persist CPT
 * @returns Promise
 */
export const publishSurvey = async (
  currentNetwork?: NetworkSchema,
  cptSet?: CPTSet,
  surveyId?: SurveySchema['id'],
  workspaceId?: Workspace['id']
): Promise<any> => {
  if (!currentNetwork || !cptSet || !surveyId || !workspaceId) {
    return Promise.reject()
  }
  // remove existing survey status, cpts and response
  const promises: Array<Promise<any>> = []
  promises.push(cleanSurveyStatuses(surveyId))
  promises.push(cleanResponses(surveyId))
  promises.push(cleanCPTsNext(surveyId))
  await Promise.all(promises)
  // create CPTs
  await saveCPTsNext(cptSet, surveyId)
  // initialize survey status
  await initSurveyStatuses(workspaceId, surveyId)
}

/**
 * Clean Survey Status
 * @returns Promise
 */
export const cleanCPTSummaries = async (surveyId: SurveySchema['id']): Promise<any> => {
  const promises = []
  const cptSummaries = (await getSurveyCPTSummaries({ surveyId, getAll: true })).content
  for (const cptSummary of cptSummaries) {
    const { id } = cptSummary
    promises.push(deleteCPTSummary(id))
  }
  return Promise.all(promises)
}

export const saveCPTSummaries = async (
  surveyId: SurveySchema['id'],
  summaries: Array<CPTSummary>,
  cptSet: CPTSet
): Promise<any> => {
  // const cptSummaries: Array<CPTSummarySchema> = cptSet.serializeSummaries(summaries, surveyId)
  const cptSummaries: Array<CPTSummarySchema> = cptSet.serializeSummariesWithOrder(
    cptSet.network,
    summaries,
    surveyId
  )
  const promises = cptSummaries.map((cptSummary) => createCPTSummary(cptSummary))
  return Promise.all(promises)
}

/**
 * Persist Mock
 * @returns Promise
 */
export const persistMock = async (
  networkId?: NetworkSchema['id'],
  surveyId?: SurveySchema['id'],
  cptSet?: CPTSet
): Promise<any> => {
  if (!networkId || !cptSet || !surveyId) {
    return Promise.reject()
  }
  const summaries: Array<CPTSummary> = cptSet.mock()
  const promises = [cleanCPTSummaries(surveyId), saveCPTSummaries(surveyId, summaries, cptSet)]
  await Promise.all(promises)

  return exportXDSL({ networkId, surveyId }).then((res: any) => {
    saveAs(res, 'cpt-summary.xdsl')
  })
}

const patchXdsl = async (originalString: string, exportedString: string) => {
  const options = {
    preserveOrder: true, // use preserve ordering for Genie
    ignoreAttributes: false,
    attributeNamePrefix: '@_'
  }
  const parser1 = new XMLParser(options)
  const parser2 = new XMLParser(options)
  const oXdsl: any = parser1.parse(originalString)
  const eXdsl: any = parser2.parse(exportedString)
  try {
    rematchIds(oXdsl[1].smile[1].extensions[0].genie, eXdsl[1].smile[1].extensions[0].genie)
  } catch (e) {
    // pass
  }
  eXdsl[1].smile[1].extensions[0].genie = oXdsl[1].smile[1].extensions[0].genie
  const builder = new XMLBuilder({
    format: true,
    preserveOrder: true,
    ignoreAttributes: false,
    attributeNamePrefix: '@_'
  })
  return builder.build(eXdsl)
}

export const rematchIds = (oldExtensions: any, newExtensions: any): any => {
  // Sample pattern.
  // o[1].smile[1].extensions[0].genie[0].submodel[6].node[0].name[0]['#text']
  const nameToIdMap: Record<string, any> = {}
  const processOld = (children: any[]) => {
    children.forEach((c) => {
      if (c.node) {
        const nodeName = c.node[0].name[0]['#text']
        nameToIdMap[nodeName] = c[':@']['@_id']
      } else if (c.submodel) {
        const subModelName = c.submodel[0].name[0]['#text']
        nameToIdMap[subModelName] = c[':@']['@_id']
        processOld(c.submodel)
      }
    })
  }
  processOld(newExtensions)
  const processNew = (children: any[]) => {
    children.forEach((c) => {
      if (c.node) {
        const nodeName = c.node[0].name[0]['#text']
        if (nameToIdMap[nodeName]) {
          c[':@']['@_id'] = nameToIdMap[nodeName]
        }
      } else if (c.submodel) {
        const subModelName = c.submodel[0].name[0]['#text']
        if (nameToIdMap[subModelName]) {
          c[':@']['@_id'] = nameToIdMap[subModelName]
        }
        processNew(c.submodel)
      }
    })
  }
  processNew(oldExtensions)
  return oldExtensions
}

/**
 * Persist real summary
 * @returns Promise
 */
export const persistSummaries = async (
  analyticsMap: Record<string, any>,
  networkId?: NetworkSchema['id'],
  surveyId?: SurveySchema['id'],
  cptSet?: CPTSet,
  isSourceAvailable = true,
  isAnonymous = true
): Promise<any> => {
  if (!networkId || !cptSet || !surveyId) {
    return Promise.reject()
  }
  const summaries: Array<CPTSummary> = cptSet.summarize(analyticsMap, isAnonymous)
  await cleanCPTSummaries(surveyId)
  await saveCPTSummaries(surveyId, summaries, cptSet)
  let exportedString = await exportXDSL({ networkId, surveyId })
  exportedString = await exportedString.text()
  let res = exportedString
  if (isSourceAvailable) {
    try {
      let originalString = await getNetworkSource(networkId)
      originalString = await originalString.text()
      if (originalString || Math.abs(exportedString.length - originalString.length) < 100) {
        res = await patchXdsl(originalString, exportedString)
      }
    } catch (e) {
      console.log(e)
      // pass
    }
  }
  saveAs(new Blob([res], { type: 'application/xml;charset=utf-8' }), 'cpt-summary.xdsl')
}

/**
 * Persist real summary
 * @returns Promise
 */
export const trace = async (
  analyticsMap: Record<string, any>,
  networkId?: NetworkSchema['id'],
  surveyId?: SurveySchema['id'],
  cptSet?: CPTSet,
  nodeIds?: string[],
  isAnonymous = true
): Promise<any> => {
  if (!networkId || !cptSet || !surveyId || !nodeIds) {
    return Promise.reject()
  }
  const summaries: Array<CPTSummary> = cptSet.summarize(analyticsMap, isAnonymous)
  await cleanCPTSummaries(surveyId)
  await saveCPTSummaries(surveyId, summaries, cptSet)
  return await traceNetwork({ networkId, surveyId, nodeIds })
}
