import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { ValueOf } from '@/types'

type UserRole = ValueOf<typeof DB_ENUM_VALUES.USER.ROLES>

interface UserExt {
  [index: string]: string
}
interface User {
  [DB_FIELDS.USER.COMMENTS]: string | null
  [DB_FIELDS.USER.CREATED_DATE]?: string
  [DB_FIELDS.USER.EMAIL]?: string
  [DB_FIELDS.USER.ENABLED]?: boolean
  [DB_FIELDS.USER.EXPERTISE]: string[] | null
  [DB_FIELDS.USER.EXT]: UserExt | null
  [DB_FIELDS.USER.FIRST_NAME]: string | null
  [DB_FIELDS.USER.ID]: string
  [DB_FIELDS.USER.LAST_MODIFIED_DATE]: string
  [DB_FIELDS.USER.LAST_NAME]: string | null
  [DB_FIELDS.USER.PASSWORD]: string
  [DB_FIELDS.USER.PRIMARY_PHONE]?: string | null
  [DB_FIELDS.USER.PROFILE_PHOTO]?: string | null
  [DB_FIELDS.USER.ROLES]: UserRole[]
  [DB_FIELDS.USER.SCHEMA_VERSION]?: number
  [DB_FIELDS.USER.TITLE]?: string | null
  [DB_FIELDS.USER.USERNAME]: string
}

type UserNext = User & { [DB_FIELDS.USER.KEY]: string }

export { User, UserRole, UserExt, UserNext }
