import { css } from '@emotion/css'

export const wrapper = css`
  overflow: auto;
  background: white;

  .ant-table-body {
    td {
      height: 35px;
      padding: 5px 3px !important;
    }

    td:first-child {
      padding-left: 8px !important;
    }

    .ant-input {
      height: 22px;
    }

    .sz-variations-input {
      .ant-input {
        width: calc(100% - 40px);
      }
    }
  }

  .ant-table-column-title > span {
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: fade;
  }
`
