<template>
  <div class="sz-apex-chart-actions">
    <a-button type="link" size="small" @click="toggleMaximize">
      <template #icon>
        <Icon :size="16" color="#6e8192">
          <Minimize v-if="isMaximize" />
          <Maximize v-else />
        </Icon>
      </template>
    </a-button>
  </div>
</template>

<script lang="ts">
import { Maximize, Minimize } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { defineComponent } from 'vue'

export const EVENTS = {
  ON_TOGGLE_MAXIMIZE: 'onToggleMaximize'
}

export default defineComponent({
  name: 'ChartActions',
  components: {
    Icon,
    Maximize,
    Minimize
  },
  props: {
    chartEl: { type: Object, required: true },
    isMaximize: { type: Boolean, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const toggleMaximize = () => {
      emit(EVENTS.ON_TOGGLE_MAXIMIZE)
    }

    return {
      toggleMaximize
    }
  }
})
</script>

<style lang="stylus" scoped>
.sz-apex-chart-actions {
  position: absolute;
  right: 25px;
  top: -2px;
}
</style>
