import { render } from "./VariableContext.vue?vue&type=template&id=0f71e9ce"
import script from "./VariableContext.vue?vue&type=script&lang=ts"
export * from "./VariableContext.vue?vue&type=script&lang=ts"

import "./VariableContext.vue?vue&type=style&index=0&id=0f71e9ce&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0f71e9ce"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0f71e9ce', script)) {
    api.reload('0f71e9ce', script)
  }
  
  module.hot.accept("./VariableContext.vue?vue&type=template&id=0f71e9ce", () => {
    api.rerender('0f71e9ce', render)
  })

}

script.__file = "src/components/survey/VariableContext.vue"

export default script