import { render } from "./BatchImport.vue?vue&type=template&id=31593f12"
import script from "./BatchImport.vue?vue&type=script&lang=ts"
export * from "./BatchImport.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "31593f12"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('31593f12', script)) {
    api.reload('31593f12', script)
  }
  
  module.hot.accept("./BatchImport.vue?vue&type=template&id=31593f12", () => {
    api.rerender('31593f12', render)
  })

}

script.__file = "src/components/analysis/common/node-selection/BatchImport.vue"

export default script