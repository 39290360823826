<template>
  <div class="sz-analysis sz-wrapper">
    <workspace-info :workspace="workspace" />
    <div class="sz-command-bar">
      <a-button type="link" @click="configure">
        <template #icon>
          <Icon :class="cssIcon">
            <Adjustments />
          </Icon>
        </template>
        <span>Configure</span>
      </a-button>
      <a-button type="link" @click="saveJob">
        <template #icon>
          <Icon :class="cssIcon">
            <DeviceFloppy />
          </Icon>
        </template>
        <span>Save current analysis job</span>
      </a-button>
    </div>
    <a-tabs v-model:activeKey="activeTab" tab-position="left">
      <a-tab-pane :key="TABS.PARAMS" tab="Parameters">
        <splitpanes class="default-theme" horizontal>
          <pane size="50">
            <div class="sza-networks-wrapper">
              <div class="heading">
                <div class="title">Networks</div>
              </div>
              <NetworkList
                :workspace-id="workspaceId"
                :networks="networks"
                @[NET_EVENTS.NETWORK_IMPORTED]="loadNetworks"
                @[NET_EVENTS.NETWORK_REMOVE]="removeNetwork"
              />
            </div>
          </pane>
          <pane>
            <div class="sza-node-selections-wrapper">
              <div class="heading">Variable and Objective Nodes</div>
              <div v-if="commonNodeKeys.length == 0 && networks.length > 1" class="warning">
                No variables common in all networks
              </div>
              <option-design-node-selection
                :node-selections="nodeSelections"
                :config="selectionConfig"
                class="content"
                @[VAR_EVENTS.TOGGLE_INPUT]="toggleInput"
                @[VAR_EVENTS.TOGGLE_OUTPUT]="toggleOutput"
                @[VAR_EVENTS.VARIATION_CHANGE]="onVariationUpdated"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
      <a-tab-pane :key="TABS.RESULTS" tab="Results">
        <splitpanes class="default-theme" horizontal>
          <pane size="30">
            <div class="heading">
              <div class="title">Tasks</div>
            </div>
            <div style="min-height: 100px; overflow: auto">
              <option-design-task-list
                :tasks="tasks"
                :networks="networks"
                :network-map="networkMap"
                @[TASK_EVENTS.UPDATE_TASKS]="sync"
                @[TASK_EVENTS.RUN]="run"
                @[TASK_EVENTS.ABORT]="abort"
                @[TASK_EVENTS.REFRESH]="refresh"
                @[TASK_EVENTS.REMOVE]="remove"
                @[TASK_EVENTS.RUN_ALL]="runAll"
                @[TASK_EVENTS.VIEW_RESULT]="viewResult"
              />
            </div>
          </pane>
          <pane>
            <splitpanes class="default-theme" vertical>
              <pane min-size="10" size="50">
                <div class="heading">
                  <div class="title">Result Table</div>
                </div>
                <div class="sz-command-bar">
                  <a-button type="link" @click="exportResults">
                    <template #icon>
                      <Icon size="14">
                        <TableExport />
                      </Icon>
                    </template>
                    <span>Export Result</span>
                  </a-button>
                </div>
                <div style="min-height: 100px; overflow: auto">
                  <option-design-results-table
                    :ref="
                      (el) => {
                        if (el) {
                          table = el
                        }
                      }
                    "
                    :key="tableKey"
                    :selections="nodeSelections"
                    :networks="networks"
                    :option-count="optionCount"
                    :max="maxOutputValue"
                    :min="minOutputValue"
                    :task="selectedTask"
                    :variable-nodes="variableNodes"
                    :change-counts="changeCounts"
                    :baselines="baselines"
                    :opt-val-by-objective-node-rows="optValByObjectiveNodeRows"
                    :solution-by-variable-node-rows="solutionByVariableNodeRows"
                  />
                </div>
              </pane>
              <pane>
                <div style="overflow: auto">
                  <div class="heading">Charts</div>
                  <div class="sz-command-bar">
                    <a-button type="link" @click="showPalettePicker">
                      <template #icon>
                        <Icon size="14">
                          <Palette />
                        </Icon>
                      </template>
                      <span>Change Chart Colors</span>
                    </a-button>
                  </div>
                  <option-design-apex-chart
                    v-if="outputNodeKeys?.length && chartData?.length"
                    :objective-node-keys="outputNodeKeys"
                    :data="chartData"
                    :colors="paletteColors"
                    :max="maxOutputValue"
                    :min="minOutputValue"
                  />
                </div>
              </pane>
            </splitpanes>
          </pane>
        </splitpanes>
      </a-tab-pane>
    </a-tabs>
  </div>
  <a-modal
    v-model:visible="isConfigVisible"
    title="Configure Option Design"
    class="sz-variation-generator"
    width="600px"
    @ok="onConfigOk"
    @cancel="onConfigCancel"
  >
    <option-design-config
      ref="configForm"
      :networks="networks"
      :variable-nodes="variableNodes"
      :data="configFormData"
    />
  </a-modal>
  <common-analysis
    v-model:palette-visible="paletteVisible"
    :is-loading="isCreatingJob || isUpdatingJob || isExecuting"
    :palette-name="paletteName"
    @[COMMON_EVENTS.COLOR_CHANGED]="selectPalette"
  />
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

import { Adjustments, DeviceFloppy, Palette, TableExport } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import { Pane, Splitpanes } from 'splitpanes'
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  Ref,
  ref,
  UnwrapRef,
  watch
} from 'vue'
import { useRoute } from 'vue-router'
import writeXlsxFile from 'write-excel-file'

import CommonAnalysis, {
  EVENTS as COMMON_EVENTS
} from '@/components/analysis/common/CommonAnalysis.vue'
import NetworkList, {
  EVENTS as NET_EVENTS
} from '@/components/analysis/common/network-list/NetworkList.vue'
import OptionDesignNodeSelection, {
  EVENTS as VAR_EVENTS
} from '@/components/analysis/common/node-selection/NodeSelectionTable.vue'
import OptionDesignTaskList, {
  EVENTS as TASK_EVENTS
} from '@/components/analysis/common/task-list/TaskList.vue'
import WorkspaceInfo from '@/components/analysis/common/workspace-info/WorkspaceInfo.vue'
import useAnalysis from '@/components/analysis/composable/analysis'
import useBase, { TABS } from '@/components/analysis/composable/base'
import useJob from '@/components/analysis/composable/job'
import useOptionDesign, {
  inputNodeMapper,
  outputNodeMapper
} from '@/components/analysis/composable/option-design'
import usePalette from '@/components/analysis/composable/palette'
import { AnalysisTask, NodeSelection } from '@/components/analysis/libs/common'
import {
  DEFAULT_OPTION_DESIGN_CONFIG,
  OptionDesignConfigData
} from '@/components/analysis/libs/option-design'
import OptionDesignApexChart from '@/components/analysis/option-design/OptionDesignApexChart.vue'
import OptionDesignConfig from '@/components/analysis/option-design/OptionDesignConfig.vue'
import OptionDesignResultsTable, {
  EVENTS as TABLE_EVENTS
} from '@/components/analysis/option-design/OptionDesignResultsTable.vue'
import { ROUTE_NAME } from '@/constants/router'
import { cssIcon } from '@/styles/common'
import { JobType } from '@/types/database/job'

const REFRESH_ENABLE = false

export default defineComponent({
  components: {
    Adjustments,
    CommonAnalysis,
    DeviceFloppy,
    Icon,
    NetworkList,
    OptionDesignApexChart,
    OptionDesignConfig,
    OptionDesignNodeSelection,
    OptionDesignResultsTable,
    OptionDesignTaskList,
    Pane,
    Palette,
    Splitpanes,
    WorkspaceInfo,
    TableExport
  },
  setup() {
    const activeTab = ref(TABS.PARAMS)
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const selectionConfig = {
      inputVisible: true,
      utilityVectorVisible: true,
      stateVisible: true,
      variationVisible: true,
      variationsVisible: false,
      constraintVisible: true,
      headers: {
        input: {
          title: 'Variable',
          desc: 'Variable Node'
        },
        output: {
          title: 'Objective',
          desc: 'Objective Node'
        }
      }
    }
    const {
      networks,
      removeNetwork,
      loadWorkspace,
      loadNetworks,
      workspace,
      workspaceModified,
      networkOptions,
      selectedNetwork,
      selectedNetworkIndex,
      workspaceHeaderStyle
    } = useBase(workspaceId)

    const {
      paletteVisible,
      paletteName,
      paletteColors,
      selectPalette,
      showPalettePicker
    } = usePalette()

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }

    const {
      currentUser,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      updateNodeSelections,
      inputNodeKeys,
      outputNodeKeys,
      updateInputOutputNodes,
      commonNodeKeys
    } = useAnalysis(workspaceId, JobType.SENSITIVITY, networks)

    const {
      currentJob,
      tasks,
      taskMap,
      loadJob,
      loadTasks,
      persistJob,
      persistTasks,
      isUpdatingJob,
      isCreatingJob,
      isLoading,
      isPersisting,
      syncTasks,
      removeTask
    } = useJob(
      currentUser,
      workspaceId,
      JobType.OPTIONDESIGN,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      onSuccessSaveJob
    )

    const {
      changeCounts,
      optionCount,
      chartData,
      chartNodeIds,
      isExecuting,
      variableNodes,
      maxOutputValue,
      minOutputValue,
      executeAnalysis,
      updateOptionDesignNodes,
      abortAnalysis,
      clearResult,
      pullResult,
      prepareTasks,
      optValByObjectiveNodeRows,
      solutionByVariableNodeRows,
      baselines,
      exportTabular
    } = useOptionDesign(
      workspaceId,
      networks,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      taskMap,
      updateTable,
      onSuccessSaveJob,
      selectedNetwork,
      currentJob
    )

    const isConfigVisible: Ref<boolean> = ref(false)
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const inputs: Ref<number[][]> = ref([])
    const outputs: Ref<number[][]> = ref([])
    const configForm = ref()
    const configFormData: UnwrapRef<OptionDesignConfigData> = reactive(
      Object.assign(
        { ...DEFAULT_OPTION_DESIGN_CONFIG },
        currentJob.value?.params?.config?.optionDesignConfig
      )
    )
    const selectedTask: Ref<AnalysisTask | undefined> = ref()

    const handleLoadNetwork = () => {
      loadNetworks()
    }

    const calculate = () => {
      if (selectedNetwork.value) {
        updateOptionDesignNodes()
        updateInputOutputNodes()
        executeAnalysis()
        updateTable()
      }
    }

    const toggleInput = () => {
      updateOptionDesignNodes()
      updateInputOutputNodes()
      // updateWhatIfRows()
    }
    const toggleOutput = () => {
      updateOptionDesignNodes()
      updateInputOutputNodes()
      // updateWhatIfRows()
    }

    const configure = () => {
      isConfigVisible.value = true
    }

    const onConfigOk = () => {
      Object.assign(configFormData, configForm.value.getData())
      isConfigVisible.value = false
    }

    const onConfigCancel = () => {
      isConfigVisible.value = false
    }

    const onVariationUpdated = ({ variation, key }: any) => {
      nodeSelections.value?.forEach((node: NodeSelection) => {
        if (node.isInput && !node.isDeterministic && node.key !== key) {
          node.variation = variation
        }
      })
    }

    watch(networks, async () => {
      updateNodeSelections()
      await loadJob()
      await loadTasks()
      if (!tasks.value?.length) {
        prepareTasks()
      }
      updateOptionDesignNodes()
      selectedNetwork.value = networks.value?.[0]
    })

    watch(nodeSelections, () => {
      // updateSensitivityRows()
    })

    watch(currentJob, () => {
      Object.assign(configFormData, currentJob.value?.params?.config?.optionDesignConfig)
    })

    let refreshTimer: any

    onMounted(async () => {
      await loadWorkspace()
      await loadNetworks()
      if (REFRESH_ENABLE) {
        refreshTimer = setInterval(() => {
          if (activeTab.value === TABS.RESULTS) {
            loadTasks()
          }
        }, 30000)
      }
    })

    onBeforeUnmount(() => {
      if (refreshTimer) {
        clearInterval(refreshTimer)
      }
    })

    const exportResults = async () => {
      const { datas, names } = exportTabular()
      await writeXlsxFile(datas, {
        sheets: names,
        fileName: workspace.value?.name + '-results.xlsx'
      })
    }

    const saveJob = async () => {
      prepareTasks()
      const optionDesignConfig = configFormData
      const variableNodesCount = variableNodes.value.length
      if (
        optionDesignConfig.maxInputNodes &&
        optionDesignConfig.maxInputNodes > variableNodesCount
      ) {
        optionDesignConfig.maxInputNodes = variableNodesCount
      }
      await persistJob({
        optionDesignConfig
      })
      await persistTasks()
    }

    const run = (task: AnalysisTask) => {
      const _network = networkMap.value?.[task.networkId]
      if (_network) {
        selectedNetwork.value = _network
        updateOptionDesignNodes()
        updateInputOutputNodes()
        executeAnalysis(task)
        updateTable()
      }
    }

    const runAll = async () => {
      updateOptionDesignNodes()
      updateInputOutputNodes()
      await executeAnalysis()
      loadTasks()
      updateTable()
    }

    const abort = (task: AnalysisTask) => {
      abortAnalysis(task)
    }

    const refresh = () => {
      loadTasks()
    }

    const remove = async (taskId: string, taskIndex: number) => {
      removeTask(taskId, taskIndex)
      await saveJob()
      if (selectedTask.value?.id === taskId) {
        clearResult()
      }
    }

    const sync = async () => {
      syncTasks()
      await saveJob()
    }

    const viewResult = (task: AnalysisTask) => {
      selectedTask.value = task
      updateOptionDesignNodes()
      updateInputOutputNodes()
      pullResult(task)
    }

    watch(selectedNetworkIndex, () => {
      const _network = networks.value?.[selectedNetworkIndex.value]
      if (_network) {
        // updateRows(_network)
        selectedNetwork.value = _network
      }
    })

    return {
      exportResults,
      optValByObjectiveNodeRows,
      solutionByVariableNodeRows,
      sync,
      abort,
      activeTab,
      calculate,
      chartData,
      chartNodeIds,
      COMMON_EVENTS,
      commonNodeKeys,
      configForm,
      configFormData,
      maxOutputValue,
      minOutputValue,
      configure,
      cssIcon,
      handleLoadNetwork,
      inputNodeKeys,
      inputs,
      isConfigVisible,
      isCreatingJob,
      isExecuting,
      isLoading,
      isPersisting,
      isUpdatingJob,
      loadNetworks,
      NET_EVENTS,
      networkMap,
      networkOptions,
      networks,
      nodeSelections,
      onConfigCancel,
      onConfigOk,
      onVariationUpdated,
      optionCount,
      outputNodeKeys,
      outputs,
      paletteColors,
      paletteName,
      paletteVisible,
      refresh,
      changeCounts,
      baselines,
      remove,
      removeNetwork,
      ROUTE_NAME,
      run,
      runAll,
      saveJob,
      selectedTask,
      selectedNetwork,
      selectedNetworkIndex,
      selectionConfig,
      selectPalette,
      showPalettePicker,
      TABLE_EVENTS,
      table,
      tableKey,
      TABS,
      TASK_EVENTS,
      tasks,
      toggleInput,
      toggleOutput,
      VAR_EVENTS,
      variableNodes,
      viewResult,
      workspace,
      workspaceHeaderStyle,
      workspaceId,
      workspaceModified
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/base.styl"
@import "../../../styles/commons.styl"
@import "../styles/common.styl"

.sz-page-spinner
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  padding: 0
  margin: 0
  background-color: rgba(255, 255, 255, 0.5)
  > div
    @extend .centered
    margin auto
</style>
