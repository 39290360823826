import { render } from "./Sorter.vue?vue&type=template&id=5a2faffe"
import script from "./Sorter.vue?vue&type=script&lang=ts"
export * from "./Sorter.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5a2faffe"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5a2faffe', script)) {
    api.reload('5a2faffe', script)
  }
  
  module.hot.accept("./Sorter.vue?vue&type=template&id=5a2faffe", () => {
    api.rerender('5a2faffe', render)
  })

}

script.__file = "src/components/analysis/common/sorter/Sorter.vue"

export default script