import { computed, Ref } from 'vue'

import { CPT, Network, Variable } from '@/libs/bayes'

export default function useTable(
  network: Ref<Network | undefined>,
  selectedVariable: Ref<Variable | undefined>,
  cpt: Ref<CPT | undefined>
): any {
  const parents = computed(() => network.value?.parents[selectedVariable.value?.id || '']) || []
  const children = computed(() => network.value?.children[selectedVariable.value?.id || '']) || []
  const combinations = computed(() => cpt.value?.elicitedCombinations || [])
  const elicitedRows = computed(() => cpt.value?.elicitedRows || [])
  const dependents = computed(() => cpt.value?.dependents || [])
  const independents = computed(() => cpt.value?.independents || [])
  const dependentMap = computed(() => cpt.value?.dependentMap || new Map())
  // const independentMap = computed(() => cpt.value?.independentMap || new Map())
  const selectedState = computed(() => selectedVariable.value?.getPositiveState().state)

  return {
    parents,
    children,
    combinations,
    elicitedRows,
    dependents,
    independents,
    dependentMap,
    selectedState
  }
}
