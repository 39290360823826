import API from '@/axios'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import { API_PAGEABLE_PARAMS, User } from '@/types'
import { UserForm } from '@/types/api/user'

/**
 * Create user
 */
const createUser = async (user: UserForm): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.USERS, user))
  } catch (e) {
    errorHandler(e)
  }
}

const createUsers = async (users: UserForm[]): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.USERS_IMPORT, users))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get user from server
 */
const getUser = async (id: User['id']): Promise<any> => {
  try {
    return extractResponse(await API.get(ENDPOINTS.USER({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get users from server
 */
const getUsers = async (options?: {
  params?: (API_PAGEABLE_PARAMS & { id: Array<string> }) | Partial<User>
  getAll?: boolean
}): Promise<any> => {
  try {
    const getAll = options ? options.getAll : false
    const params = options ? options.params : {}
    if (getAll) {
      const requestParams = Object.assign({}, API_DEFAULT_PAGEABLE_PARAMS, params)
      return recursivePaginationAPIRequestFn(API, ENDPOINTS.USERS)(requestParams)
    } else {
      return extractResponse(await API.get(ENDPOINTS.USERS, { params }))
    }
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Update user
 */
const updateUser = async (id: User['id'], user: Partial<User>): Promise<any> => {
  try {
    return extractResponse(await API.put(ENDPOINTS.USER({ id }), user))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get users from server
 */
const deleteUser = async (id: User['id']): Promise<any> => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.USER({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

export { createUser, createUsers, getUsers, getUser, updateUser, deleteUser }
