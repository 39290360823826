import { render } from "./OptionDesignConfig.vue?vue&type=template&id=2c68b0e1"
import script from "./OptionDesignConfig.vue?vue&type=script&lang=ts"
export * from "./OptionDesignConfig.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2c68b0e1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2c68b0e1', script)) {
    api.reload('2c68b0e1', script)
  }
  
  module.hot.accept("./OptionDesignConfig.vue?vue&type=template&id=2c68b0e1", () => {
    api.rerender('2c68b0e1', render)
  })

}

script.__file = "src/components/analysis/option-design/OptionDesignConfig.vue"

export default script