<template>
  <div style="cursor: pointer">
    <a-upload
      v-model:fileList="fileList"
      name="file"
      :multiple="true"
      :show-upload-list="false"
      :custom-request="handleRequest"
      @change="handleChange"
    >
      <a-button type="link" size="small">
        <template #icon>
          <Icon :class="cssIcon">
            <FileUpload />
          </Icon>
        </template>
        <span>Upload XDSL</span>
      </a-button>
    </a-upload>
  </div>
</template>

<script lang="ts">
import { FileUpload } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import { any } from 'ramda'
import { defineComponent, PropType, ref } from 'vue'

import { EMIT_EVENTS } from '@/constants/emits'
import { ModuleNames } from '@/constants/vuex'
import { Network } from '@/libs/bayes'
import { parse, parseRaw } from '@/libs/exim'
import { readAsText, readAsTextSync } from '@/services/api/utils'
import { useStore } from '@/store'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { vuexActions } from '@/store/util'
import { cssIcon } from '@/styles/common'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  originFileObj: any
}

interface FileInfo {
  file: FileItem
  fileList: FileItem[]
}

/*
function readAsText(img: Blob, callback: (text: string) => void) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsText(img)
}
*/

const IMPORT_METHOD = 'api' // or 'js'

export default defineComponent({
  components: {
    Icon,
    FileUpload
  },
  props: {
    workspaceId: { type: String, required: true },
    networks: { type: Object as PropType<Network[]>, default: () => [] as Network[] }
  },
  emits: [EMIT_EVENTS.NETWORK.LOAD],
  setup(props, { emit }) {
    const store = useStore()

    let handleRequest
    let handleChange

    if (IMPORT_METHOD === 'api') {
      handleRequest = async (options: any) => {
        try {
          if (props.workspaceId) {
            const xdslRaw = await readAsTextSync(options.file)
            const xdsl = await parseRaw(xdslRaw)
            console.log(props.networks)
            console.log(xdsl)
            if (any((network: Network) => network.name === xdsl?.smile?.$?.id)(props.networks)) {
              message.error({
                content:
                  'There is another network with the same name. Please rename the smile id inside the XDSL',
                duration: 3
              })
              return
            }
            const importNetwork = store.dispatch(
              vuexActions(ModuleNames.NETWORK, NetworkActionEnum.IMPORT_NETWORK),
              {
                workspaceId: props.workspaceId,
                file: options.file as File
              }
            )
            let networkRes = await importNetwork
            if (networkRes) {
              options.onSuccess(networkRes, options.file)
              emit(EMIT_EVENTS.NETWORK.LOAD, networkRes)
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
      handleChange = (info: FileInfo) => {
        const status = info.file.status
        if (status === 'error') {
          console.log(`${info.file.name} file upload failed.`)
        } else {
          readAsText(info.file.originFileObj, async (text: string) => {
            const networkXml = await parseRaw(text)
            console.log(networkXml)
          })
        }
      }
    } else {
      // client-side import
      handleRequest = async (options: any) => {
        options.onSuccess({}, { ...options.file })
      }
      handleChange = (info: FileInfo) => {
        const status = info.file.status
        if (status === 'error') {
          console.log(`${info.file.name} file upload failed.`)
        } else {
          // if (status === 'done')
          readAsText(info.file.originFileObj, async (text: string) => {
            const network = await parse(text)
            emit(EMIT_EVENTS.NETWORK.LOAD, network)
          })
        }
      }
    }
    return {
      handleRequest,
      handleChange,
      cssIcon,
      fileList: ref([])
    }
  }
})
</script>
