/**
 * Mutation types
 */
export enum SurveyMutationEnum {
  GET_SURVEY = 'GET_SURVEY',
  GET_SURVEY_FULFILLED = 'GET_SURVEY_FULFILLED',
  GET_SURVEY_REJECTED = 'GET_SURVEY_REJECTED',

  GET_SURVEYS = 'GET_SURVEYS',
  GET_SURVEYS_FULFILLED = 'GET_SURVEYS_FULFILLED',
  GET_SURVEYS_REJECTED = 'GET_SURVEYS_REJECTED',

  CREATE_SURVEY = 'CREATE_SURVEY',
  CREATE_SURVEY_FULFILLED = 'CREATE_SURVEY_FULFILLED',
  CREATE_SURVEY_REJECTED = 'CREATE_SURVEY_REJECTED',

  UPDATE_SURVEY = 'UPDATE_SURVEY',
  UPDATE_SURVEY_FULFILLED = 'UPDATE_SURVEY_FULFILLED',
  UPDATE_SURVEY_REJECTED = 'UPDATE_SURVEY_REJECTED',

  DELETE_SURVEY = 'DELETE_SURVEY',
  DELETE_SURVEY_FULFILLED = 'DELETE_SURVEY_FULFILLED',
  DELETE_SURVEY_REJECTED = 'DELETE_SURVEY_REJECTED',

  SET_CURRENT_SURVEY = 'SET_CURRENT_SURVEY',

  EXPORT_SURVEY = 'EXPORT_SURVEY',
  EXPORT_SURVEY_FULFILLED = 'EXPORT_SURVEY_FULFILLED',
  EXPORT_SURVEY_REJECTED = 'EXPORT_SURVEY_REJECTED'
}
