import { render } from "./VariableDependencyCol.vue?vue&type=template&id=119174b6"
import script from "./VariableDependencyCol.vue?vue&type=script&lang=ts"
export * from "./VariableDependencyCol.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "119174b6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('119174b6', script)) {
    api.reload('119174b6', script)
  }
  
  module.hot.accept("./VariableDependencyCol.vue?vue&type=template&id=119174b6", () => {
    api.rerender('119174b6', render)
  })

}

script.__file = "src/components/variable/VariableDependencyCol.vue"

export default script