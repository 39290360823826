/* eslint-disable @typescript-eslint/no-unused-vars */
import { isNil } from 'ramda'

import { AnalyticsRecord } from '@/components/composables/analytics'
import { Dict } from '@/libs/common'
import { User } from '@/types'

import { Combination, CombinationRow } from '../Combination'
import { Condition } from '../Condition'
import { ResponseMap } from '../CPTResponse'
import { CPTSummary, CPTSummaryOutcome, CPTSummaryRow } from '../CPTSummary'
import { CPTMethod } from '../enums/CPTMethod'
import { CPTType } from '../enums/CPTType'
import { Network } from '../Network'
import { State } from '../State'
import { Variable } from '../Variable'

export class CPT {
  id?: string
  method: CPTMethod
  network: Network
  variable: Variable
  // `state` is not required, schedulued for removal. For now, always assign, the positive state
  state: State
  parents: Array<Variable>
  dependents: Array<Variable>
  independents: Array<Variable>
  dependentMap: Map<Variable['id'], Variable>
  independentMap: Map<Variable['id'], Variable>
  dependentCombinations: Array<Combination>
  independentCombinations: Array<Combination>
  /**
   * Combination represent more abstract representation of elicitedRows
   */
  elicitedCombinations?: Array<Combination>
  /**
   * Elicited rows represent the CPT rows to store data input
   */
  elicitedRows?: Array<CombinationRow>
  /**
   * Calculated rows represent the actual CPT rows/combination that store the actual CPT
   */
  calculatedRows?: Array<CombinationRow>
  /**
   * Table rows represent the rows in the input table
   */
  tableRows?: Array<CombinationRow>
  isMarginal?: boolean

  static PREFIX = 'v+s'
  static COND_DELIM = '+'
  static COMB_DELIM = '-'

  constructor(
    network: Network,
    variable: Variable,
    state: State,
    id?: string,
    method: CPTMethod = CPTMethod.ACE_DST
  ) {
    this.id = id
    this.network = network
    this.variable = variable
    this.state = state
    this.method = method
    this.parents = this.network.getParents(variable)
    const { dependents, independents, dependentMap, independentMap } = this.network.getDependencies(
      variable
    )
    this.isMarginal = method === CPTMethod.MARGINAL
    this.dependents = dependents
    this.independents = independents
    this.dependentMap = dependentMap
    this.independentMap = independentMap
    this.dependentCombinations = []
    this.independentCombinations = []
    this.elicitedRows = []
    this.calculatedRows = []
    this.tableRows = []
    this.generateDependentCombinations()
    this.generateIndependentCombinations()
  }

  getCombKey(row: CombinationRow): string {
    const depKey: Array<Array<string>> = []
    const indepKey: Array<Array<string>> = []

    row.combination.forEach(({ variable, state }) => {
      if (this.dependentMap.has(variable.id)) {
        depKey.push([variable.id, state.id])
      } else {
        indepKey.push([variable.id, state.id])
      }
    })
    return (
      row.prefix +
      (depKey.length + indepKey.length > 0 ? CPT.COMB_DELIM : '') +
      depKey
        .concat(indepKey)
        .map((k) => k.join(CPT.COND_DELIM))
        .join(CPT.COMB_DELIM)
    )
  }

  makeCombKey(combination: Combination): string {
    const prefix = 'v+s'

    return (
      prefix +
      // (combination.length > 0 ? CPT.COND_DELIM : '') +
      (combination.length > 0 ? CPT.COMB_DELIM : '') +
      combination
        .map(({ variable, state }) => `${variable.id}${CPT.COND_DELIM}${state.id}`)
        .join(CPT.COMB_DELIM)
    )
  }

  makeCombKeyNoPrefix(combination: Combination): string {
    return combination
      .map(({ variable, state }) => `${variable.id}${CPT.COND_DELIM}${state.id}`)
      .join(CPT.COMB_DELIM)
  }

  makeCondKey({ variable, state }: Condition): string {
    return `${variable.id}${CPT.COND_DELIM}${state.id}`
  }

  static makeCombIds(combination: Combination): any {
    return combination.map(({ variable, state }) => ({
      variableId: variable.id,
      stateId: state.id
    }))
  }

  multiplexStates(
    combinations: Array<Combination>,
    conditions: Array<Condition>
  ): Array<Combination> {
    const combs = []
    for (let i = 0; i < combinations.length; i++) {
      for (let j = 0; j < conditions.length; j++) {
        const condition = conditions[j]
        combs.push(combinations[i].concat([condition]))
      }
    }
    return combs
  }

  multiplexRightByStates(
    combinations: Array<Combination>,
    conditions: Array<Condition>
  ): Array<Combination> {
    const combs = []
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i]
      for (let j = 0; j < combinations.length; j++) {
        combs.push(combinations[j].concat([condition]))
      }
    }
    return combs
  }

  multiplex(combinations: Array<Combination>, variable: Variable): Array<Combination> {
    return this.multiplexStates(combinations, variable.getAllStates())
  }

  multiplexPositive(combination: Combination, variables: Array<Variable>): Array<Combination> {
    const combs = []

    for (let i = 0; i < variables.length; i++) {
      const variableState = variables[i].getPositiveState()
      combs.push([...combination].concat([variableState]))
    }
    return combs
  }

  product(
    combinations1: Array<Combination>,
    combinations2: Array<Combination>
  ): Array<Combination> {
    const combs = []
    for (let i = 0; i < combinations1.length; i++) {
      for (let j = 0; j < combinations2.length; j++) {
        if (combinations1[i].length || combinations2[j].length) {
          combs.push(combinations1[i].concat(combinations2[j]))
        }
      }
    }
    return combs
  }

  generateAllCombinations(variables: Array<Variable>): Array<Combination> {
    let combinations: Array<Combination> = [[]]
    for (let i = 0; i < variables.length; i++) {
      combinations = this.multiplex(combinations, variables[i])
    }
    return combinations
  }

  generateElicitedRows(): void {
    this.elicitedCombinations = this.generateAllCombinations(this.parents)
    this.elicitedRows = this.elicitedCombinations.map(
      (combination) => new CombinationRow(combination)
    )
  }

  generateCalculatedRows(): void {
    const combinations = this.generateAllCombinations(this.parents)
    this.calculatedRows = combinations.map(
      (combination) => new CombinationRow(combination, CPTType.CALCULATED)
    )
  }

  generateOrderedRows(parents: Array<string>): CombinationRow[] {
    const variables = parents.map((variableId) => this.network.variableMap[variableId])
    const combinations = this.generateAllCombinations(variables)
    return combinations.map((combination) => new CombinationRow(combination, CPTType.CALCULATED))
  }

  generateDependentCombinations(): Array<Combination> {
    this.dependentCombinations = this.generateAllCombinations(this.dependents)
    return this.dependentCombinations
  }

  generateIndependentCombinations(): Array<Combination> {
    this.independentCombinations = this.generateAllCombinations(this.independents)
    return this.independentCombinations
  }

  setElicitedRows(combinations: Array<Combination>, elicitedRows: Array<CombinationRow>): void {
    this.elicitedCombinations = combinations
    this.elicitedRows = elicitedRows
  }

  setCalculatedRows(calculatedRows: Array<CombinationRow>): void {
    this.calculatedRows = calculatedRows
  }

  displayComb(combinations: Array<Combination>): void {
    for (let i = 0; i < combinations.length; i++) {
      const combination = combinations[i]
      let combText = ''
      for (let j = 0; j < combination.length; j++) {
        const { variable, state } = combination[j]
        combText += variable.name + ':' + state.name + ' '
      }
      console.log(combText)
    }
  }

  mock(): CPTSummary | null {
    const combinations = this.generateAllCombinations(this.parents)

    if (isNil(combinations)) {
      return null
    }
    const summaryRows: Array<CPTSummaryRow> = []
    combinations.forEach((combination: Combination) => {
      for (let i = 0; i < combination.length; i++) {
        const parents = combination
        const childStateNum = this.variable.length
        const outcomes: Array<CPTSummaryOutcome> = []
        for (let j = 0; j < childStateNum; j++) {
          const state = this.variable.getState(j)
          outcomes.push({
            state,
            value: 1 / childStateNum
          })
        }
        summaryRows.push({
          parents,
          outcomes
        })
      }
    })
    return {
      variable: this.variable,
      summaryRows
    } as CPTSummary
  }

  isCompleteData(responseMap: Map<string, any>): boolean {
    for (const { valid } of responseMap.values()) {
      if (!valid) {
        return false
      }
    }
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  summarize(responseMap: Record<string, any>, _sisAnonymous = true): CPTSummary | null {
    const combinations = this.generateAllCombinations(this.parents)

    if (isNil(combinations)) {
      return null
    }
    const summaryRows: Array<CPTSummaryRow> = []
    combinations.forEach((combination: Combination) => {
      for (let i = 0; i < combination.length; i++) {
        const parents = combination
        const combKey = this.makeCombKey(combination)
        const response = responseMap[combKey]?.response
        if (response) {
          const childStateNum = this.variable.length
          const outcomes: Array<CPTSummaryOutcome> = []
          for (let j = 0; j < childStateNum; j++) {
            const state = this.variable.getState(j)
            outcomes.push({
              state,
              value: response.value
            })
          }
          summaryRows.push({
            parents,
            outcomes
          })
        }
      }
    })
    return {
      variable: this.variable,
      rows: summaryRows
    } as CPTSummary
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interpolate(_responseMap: ResponseMap): any {
    return {}
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateTabularData(_responseMap: Record<string, any>): any[] {
    return []
  }

  generateResponseMap(responses: any[]): ResponseMap {
    const map: ResponseMap = new Map()
    if (this.elicitedRows) {
      this.elicitedRows.forEach(({ rowId, combination: combination = [] }) => {
        const combKey = this.makeCombKey(combination)
        const response = responses.find((response: any) => response && response?.rowId === rowId)
        map.set(combKey, { rowId, combKey, combination, response })
      })
    }
    return map
  }

  generateResponseMapByCombKey(responseMapByRowId: Dict): ResponseMap {
    const map: ResponseMap = new Map()
    if (this.elicitedRows) {
      this.elicitedRows.forEach(({ rowId, combination: combination = [] }) => {
        if (!rowId) {
          return
        }
        const combKey = this.makeCombKey(combination)
        const response = responseMapByRowId[rowId]
        if (response) {
          map.set(combKey, { rowId, combKey, combination, response })
        }
      })
    }
    return map
  }

  generateResponseMapFromXAggByCombKey(xAggMap: Dict): ResponseMap {
    const map: ResponseMap = new Map()
    if (this.elicitedRows) {
      this.elicitedRows.forEach(({ rowId, combination: combination = [] }) => {
        if (!rowId) {
          return
        }
        const combKey = this.makeCombKey(combination)
        const response = xAggMap[rowId]
        if (response) {
          map.set(combKey, { rowId, combKey, combination, response })
        }
      })
    }
    return map
  }

  generateAnalyticsTabularData(
    analyticsMap: Record<string, AnalyticsRecord>,
    users: User[],
    userMap: Dict,
    isAnonymous = false
  ): any[] {
    return []
  }

  generateUserName(
    userId: string,
    userIndex: number,
    userMap: Dict,
    isAnonymous = false,
    finalUser = 'final'
  ): string {
    return userId === 'final'
      ? userId
      : isAnonymous
      ? `user-${userIndex}`
      : userMap[userId]?.username
  }
}
