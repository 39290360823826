
import 'splitpanes/dist/splitpanes.css'

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  CloudOutlined,
  ExportOutlined,
  FormOutlined,
  PieChartOutlined,
  RadarChartOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { isArray } from 'lodash'
import { isEmpty } from 'lodash-es'
import { clone } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

import useMethods from '@/components/composables/methods'
import useSurvey from '@/components/composables/survey'
import AceTable from '@/components/method-ace/AceTable.vue'
import CainTable from '@/components/method-cain/CainTable.vue'
import MarginalTable from '@/components/method-marginal/MarginalTable.vue'
import SurveyDialog from '@/components/survey/SurveyDialog.vue'
import VariableTable from '@/components/variable/VariableTable.vue'
import { DB_ENUM_VALUES } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { CPTMethod } from '@/libs/bayes/enums/CPTMethod'
import { Variable } from '@/libs/bayes/Variable'
import { publishSurvey } from '@/services/composition/survey'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions } from '@/store/util'
import { SurveyExportFormatEnum, User } from '@/types'

export default defineComponent({
  components: {
    Pane,
    Splitpanes,
    AceTable,
    CloudOutlined,
    PieChartOutlined,
    ExportOutlined,
    FormOutlined,
    MarginalTable,
    VariableTable,
    CainTable,
    RadarChartOutlined,
    SurveyDialog
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const routerParams = route.params
    const { workspaceId, surveyId } = routerParams
    const verboseState = ref(false)
    const panels = reactive({
      variables: true,
      survey: true,
      cpt: true
    })

    const userListContent = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)

    const {
      cpt,
      cptSet,
      currentNetwork,
      currentSurvey,
      network,
      hasDiscreteScale,
      selectedVariable,
      selectVariable
    } = useSurvey(store)

    const {
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal
    } = useMethods(cpt)

    const isShowEditModal = ref(false)

    /**
     * Export CPT response for current survey
     * @param fileType - output file type
     **/
    const exportCPTResponse = (fileType: SurveyExportFormatEnum) => {
      if (surveyId && typeof surveyId === 'string' && !isEmpty(network.value.variables)) {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.EXPORT_SURVEY), {
          surveyId,
          format: fileType,
          userIds: userListContent.value.map((each: User) => each.id) as Array<User['id']>,
          variableIds: network.value.variables.map((each: Variable) => each.id)
        })
      }
    }

    /**
     * Handle display create user model
     **/
    const handleShowEditModal = () => {
      isShowEditModal.value = !isShowEditModal.value
    }

    // http://localhost:3000/#/workspaces/608798785a1e581fa9eace8c/surveys/6095ed100d4da47b36cdceda/questionnaire
    const getLink = () => {
      window.open(`/#/workspaces/${workspaceId}/surveys/${surveyId}/questionnaire`)
    }

    const persistCPT = async () => {
      try {
        if (currentNetwork.value && cptSet.value && !isArray(surveyId) && !isArray(workspaceId)) {
          await publishSurvey(currentNetwork.value, cptSet.value, surveyId, workspaceId)
          message.success(MESSAGE.PERSIST_CPT_SUCCESS)
        }
      } catch (err) {
        message.error(MESSAGE.PERSIST_CPT_FAIL)
        throw err
      }
    }

    const toggleCain = async ({ variable, method }: { variable: Variable; method: CPTMethod }) => {
      if (!currentSurvey.value) {
        return
      }
      const surveyData = clone(currentSurvey.value)
      surveyData.ext.collectionMethodMap = surveyData.ext.collectionMethodMap || {}
      surveyData.ext.collectionMethodMap[variable.id] = method
      const surveyId = currentSurvey.value.id
      await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.UPDATE_SURVEY), {
        id: surveyId,
        survey: {
          ...surveyData,
          workspaceId,
          surveyId
        }
      })
      // updateMethodForVariable(variable: Variable, method: CPTMethod)
      message.success(MESSAGE.SURVEY_CONFIG_UPDATE_SUCCESS)
    }

    return {
      DB_ENUM_VALUES,
      EMIT_EVENTS,
      persistCPT,
      handleShowEditModal,
      isShowEditModal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isAceOriginal,
      isCain,
      isMarginal,
      cpt,
      cptSet,
      hasDiscreteScale,
      currentSurvey,
      selectedVariable,
      getLink,
      surveyId,
      workspaceId,
      toggleCain,
      selectVariable,
      exportCPTResponse,
      verboseState,
      network,
      panels,
      SurveyExportFormatEnum,
      ROUTE_NAME
    }
  }
})
