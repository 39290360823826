import { Module } from 'vuex'

import { RootState } from '@/store/types'
import type { AuthState } from '@/store/types/states/auth'

import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'

export const store: Module<AuthState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
