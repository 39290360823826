import { css } from '@emotion/css'

const dotSize = '10px'
const dotOffset = '5px'

export const dotStyle = css`
  position: absolute;
  top: ${dotOffset};
  left: ${dotOffset};
  width: ${dotSize};
  height: ${dotSize};
  border-radius: 50%;
  background-color: #aaaaaa;
  cursor: pointer;
`

export const dotAggStyle = css`
  ${dotStyle};
  top: 0;
  right: 23px;
  left: auto;
  width: auto;
  height: 15px;
  padding: 0 3px;
  border-radius: 0 0 5px 5px;
  font-size: 8px;
`

export const criticalStyle = css`
  position: absolute;
  bottom: 5px;
  left: 2px;
  width: 15px;
  height: 15px;
  background-color: transparent;

  .critical-on {
    color: #ff3300;
  }

  .critical-off {
    color: #808080;
  }
`

export const probStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .ant-input-number {
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;

    .ant-input-number-input-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 20px;
      bottom: 0;

      .ant-input-number-input {
        text-align: center;
        margin-right: 21px;
        font-size: 18px;
        height: 100%;
      }
    }
  }
`

// .sz-input-prob
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   .ant-input-number
//     border: 1px solid transparent;
//     position: absolute
//     top: 0
//     left: 0
//     right: 0
//     bottom: 0
//     width: auto !important;
//     .ant-input-number-input
//       text-align: center;
//       margin-right: 21px;

// .sz-input-prob-agg
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   .ant-input-number
//     border: 1px solid transparent;
//     .ant-input-number-input
//       text-align: center;
//       margin-right: 21px;
