import { findIndex } from 'lodash-es'
import { MutationTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { NetworkMutationEnum } from '@/store/enums/mutations/network'
import { NetworkStateEnum } from '@/store/enums/states/network'
import type { NetworkMutation } from '@/store/types/mutations/network'
import type { NetworkState } from '@/store/types/states/network'
import type { NetworkList, NetworkSchema } from '@/types'

/**
 * Mutation function
 */
const mutations: MutationTree<NetworkState> & NetworkMutation = {
  // Get networks
  [NetworkMutationEnum.GET_NETWORKS](state: NetworkState) {
    state[NetworkStateEnum.IS_GETTING] = true
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_NETWORK
  },
  [NetworkMutationEnum.GET_NETWORKS_FULFILLED](state: NetworkState, payload: NetworkList) {
    state[NetworkStateEnum.IS_GETTING] = false
    state[NetworkStateEnum.NETWORK_LIST] = payload
    state[NetworkStateEnum.CURRENT_NETWORK] = payload && payload.content[0]
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [NetworkMutationEnum.GET_NETWORKS_REJECTED](state: NetworkState, error: Error) {
    state[NetworkStateEnum.IS_GETTING] = false
    state[NetworkStateEnum.ERROR] = error
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Get network
  [NetworkMutationEnum.GET_NETWORK](state: NetworkState) {
    state[NetworkStateEnum.IS_GETTING] = true
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_NETWORK
  },
  [NetworkMutationEnum.GET_NETWORK_FULFILLED](state: NetworkState, payload: NetworkSchema) {
    state[NetworkStateEnum.IS_GETTING] = false
    state[NetworkStateEnum.CURRENT_NETWORK] = payload
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [NetworkMutationEnum.GET_NETWORK_REJECTED](state: NetworkState, error: Error) {
    state[NetworkStateEnum.IS_GETTING] = false
    state[NetworkStateEnum.ERROR] = error
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Create network
  [NetworkMutationEnum.CREATE_NETWORK](state: NetworkState) {
    state[NetworkStateEnum.IS_CREATING] = true
  },
  [NetworkMutationEnum.CREATE_NETWORK_FULFILLED](state: NetworkState, payload: NetworkSchema) {
    state[NetworkStateEnum.IS_CREATING] = false
    state[NetworkStateEnum.CURRENT_NETWORK] = payload
    state[NetworkStateEnum.NETWORK_LIST] = {
      ...state[NetworkStateEnum.NETWORK_LIST],
      content: state[NetworkStateEnum.NETWORK_LIST]?.content
        ? [...state[NetworkStateEnum.NETWORK_LIST]?.content, payload]
        : [payload]
    }
  },
  [NetworkMutationEnum.CREATE_NETWORK_REJECTED](state: NetworkState, error: Error) {
    state[NetworkStateEnum.IS_CREATING] = false
    state[NetworkStateEnum.ERROR] = error
  },
  // Import network
  [NetworkMutationEnum.IMPORT_NETWORK](state: NetworkState) {
    state[NetworkStateEnum.IS_IMPORTING] = true
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.IMPORTING_NETWORK
  },
  [NetworkMutationEnum.IMPORT_NETWORK_FULFILLED](state: NetworkState, payload: NetworkSchema) {
    state[NetworkStateEnum.IS_IMPORTING] = false
    state[NetworkStateEnum.CURRENT_NETWORK] = payload
    state[NetworkStateEnum.NETWORK_LIST] = {
      ...state[NetworkStateEnum.NETWORK_LIST],
      content: state[NetworkStateEnum.NETWORK_LIST]?.content
        ? [...state[NetworkStateEnum.NETWORK_LIST]?.content, payload]
        : [payload]
    }
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [NetworkMutationEnum.IMPORT_NETWORK_REJECTED](state: NetworkState, error: Error) {
    state[NetworkStateEnum.IS_IMPORTING] = false
    state[NetworkStateEnum.ERROR] = error
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Update network
  [NetworkMutationEnum.UPDATE_NETWORK](state: NetworkState) {
    state[NetworkStateEnum.IS_UPDATING] = true
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.UPDATING_NETWORK
  },
  [NetworkMutationEnum.UPDATE_NETWORK_FULFILLED](state: NetworkState, payload: NetworkSchema) {
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
    const updatedId = payload?.id
    const index = findIndex(state[NetworkStateEnum.NETWORK_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    state[NetworkStateEnum.IS_UPDATING] = false
    state[NetworkStateEnum.CURRENT_NETWORK] = payload
    if (index !== -1) {
      const newContent = [
        ...state[NetworkStateEnum.NETWORK_LIST]?.content.slice(0, index),
        payload,
        ...state[NetworkStateEnum.NETWORK_LIST]?.content.slice(index + 1)
      ]
      state[NetworkStateEnum.NETWORK_LIST] = {
        ...state[NetworkStateEnum.NETWORK_LIST],
        content: newContent
      }
    }
  },
  [NetworkMutationEnum.UPDATE_NETWORK_REJECTED](state: NetworkState, error: Error) {
    state[NetworkStateEnum.IS_UPDATING] = false
    state[NetworkStateEnum.ERROR] = error
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  },
  // Delete network
  [NetworkMutationEnum.DELETE_NETWORK](state: NetworkState) {
    state[NetworkStateEnum.IS_DELETING] = true
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.DELETING_NETWORK
  },
  [NetworkMutationEnum.DELETE_NETWORK_FULFILLED](state: NetworkState, id: NetworkSchema['id']) {
    const index = findIndex(state[NetworkStateEnum.NETWORK_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: id
    })
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
    state[NetworkStateEnum.IS_DELETING] = false
    state[NetworkStateEnum.CURRENT_NETWORK] = null
    if (index !== -1) {
      const newContent = [
        ...state[NetworkStateEnum.NETWORK_LIST]?.content.slice(0, index),
        ...state[NetworkStateEnum.NETWORK_LIST]?.content.slice(index + 1)
      ]
      state[NetworkStateEnum.NETWORK_LIST] = {
        ...state[NetworkStateEnum.NETWORK_LIST],
        content: newContent
      }
    }
  },
  [NetworkMutationEnum.DELETE_NETWORK_REJECTED](state: NetworkState, error: Error) {
    state[NetworkStateEnum.IS_DELETING] = false
    state[NetworkStateEnum.ERROR] = error
    state[NetworkStateEnum.LOADING_PROMPT_TEXT] = null
  }
}

export { mutations }
