/**
 * Generate string based on params
 * @param strings - base strings
 * @param keys - passed params
 */
export const ttl = (
  strings: string | TemplateStringsArray,
  ...keys: any[]
): ((collection: any) => string) => {
  return function (collection: any) {
    const result = [strings[0]]
    collection = collection || {}
    keys.forEach(function (key: string, i: number) {
      result.push(collection[key] || '', strings[i + 1])
    })
    return result.join('')
  }
}

export const API_PREFIX = '/api'

export const API_DEFAULT_PAGEABLE_PARAMS = {
  page: 0,
  size: 100
}

export const ENDPOINTS = {
  ALLOCATION: ttl`/allocations/${'id'}`,
  ALLOCATIONS: ttl`/workspaces/${'workspaceId'}/allocations`,
  AUTH_CURRENT: '/auth/current',
  AUTH_LOGIN: '/auth/login',
  AUTH_SURVEY_KEY_LOGIN: '/auth/survey-key',
  AUTH_AHP_SURVEY_KEY_LOGIN: '/auth/ahp-survey-key',
  AUTH_LOGOUT: '/auth/logout',
  AUTH_REFRESH: '/auth/refresh',
  CPT: ttl`/cpts/${'id'}`,
  CPTS: '/cpts',
  CPTS_BULK: '/cpts/import',
  CPT_SUMMARY: ttl`/cpt-summaries/${'id'}`,
  CPT_SUMMARIES: '/cpt-summaries',
  CQRS_NETWORK: ttl`/cqrs/networks/${'id'}`,
  CQRS_NODE: ttl`/cqrs/networks/${'networkId'}/nodes/${'id'}`,
  CQRS_EDGE: ttl`/cqrs/networks/${'networkId'}/edges/${'id'}`,
  INFO: '/info',
  NETWORK: ttl`/networks/${'id'}`,
  NETWORK_XDSL: ttl`/networks/${'id'}/xdsl`,
  NETWORK_SOURCE: ttl`/networks/${'id'}/source`,
  NETWORKS: ttl`/workspaces/${'workspaceId'}/networks`,
  NETWORKS_IMPORT: ttl`/workspaces/${'workspaceId'}/networks/import`,
  NETWORK_CPTS: ttl`/networks/${'id'}/cpts`,
  RESPONSE: ttl`/responses/${'id'}`,
  SURVEY: ttl`/surveys/${'id'}`,
  SURVEY_EXPORT: ttl`/surveys/${'id'}/export`,
  SURVEYS: ttl`/workspaces/${'workspaceId'}/surveys`,
  SURVEY_CLONE: ttl`/surveys/${'id'}/clone`,
  SURVEY_DISTRIBUTE: ttl`survey-user-statuses/${'id'}/distribute`,
  SURVEY_STATUS: ttl`/survey-user-statuses/${'id'}`,
  SURVEY_STATUSES: ttl`/surveys/${'id'}/survey-user-statuses`,
  SURVEY_CPTS: ttl`/surveys/${'id'}/cpts`,
  SURVEY_CPT_SUMMARIES: ttl`/surveys/${'id'}/cpt-summaries`,
  SURVEY_RESPONSES: ttl`/surveys/${'id'}/responses`,
  ANALYSIS_RESPONSES: ttl`/surveys/${'id'}/analysis-responses`,
  USER: ttl`/users/${'id'}`,
  USERS: '/users',
  USERS_IMPORT: '/users/import',
  WORKSPACE: ttl`/workspaces/${'id'}`,
  WORKSPACES: '/workspaces?sort=lastModifiedDate,desc',
  XDSL_EXPORT: ttl`/networks/${'id'}/export`,
  TRACE: ttl`/networks/${'id'}/trace`,
  JOBS: ttl`/workspaces/${'workspaceId'}/jobs`,
  JOB: ttl`/workspaces/${'workspaceId'}/jobs/${'jobId'}`,
  JOB_TASKS: ttl`/workspaces/${'workspaceId'}/jobs/${'jobId'}/tasks`,
  JOB_ALL_TASKS: ttl`/workspaces/${'workspaceId'}/jobs/${'jobId'}/tasks-all`,
  JOB_TASK: ttl`/workspaces/${'workspaceId'}/jobs/${'jobId'}/tasks/${'jobTaskId'}`,
  JOB_TASK_EXECUTE: ttl`/workspaces/${'workspaceId'}/jobs/${'jobId'}/tasks/${'jobTaskId'}/execute`,
  JOB_TASK_ABORT: ttl`/workspaces/${'workspaceId'}/jobs/${'jobId'}/tasks/${'jobTaskId'}/abort`,
  ANALYSIS: ttl`/workspaces/${'workspaceId'}/analysis`,
  ANALYSIS_EXECUTE: ttl`/workspaces/${'workspaceId'}/execute`,
  ANALYSIS_SENSITIVITY_EXECUTE: ttl`/workspaces/${'workspaceId'}/sensitivity-execute`,
  ANALYSIS_SENSITIVITY_EXECUTE_NEXT: ttl`/workspaces/${'workspaceId'}/sensitivity-execute-next`,
  SESSIONS: ttl`/workspaces/${'workspaceId'}/sessions`,
  SESSION_PARAMS: ttl`/workspaces/${'workspaceId'}/sessions/${'sessionId'}/params`,
  SESSION_RUN: ttl`/workspaces/${'workspaceId'}/sessions/${'sessionId'}/run`,
  SESSION_RESULTS: ttl`/workspaces/${'workspaceId'}/sessions/${'sessionId'}/results`,
  AHP_EXECUTE: ttl`/workspaces/${'workspaceId'}/ahp`,
  AHP_MULTI_EXECUTE: ttl`/workspaces/${'workspaceId'}/ahp-multi`,
  STOCHASTIC_RESULTS: ttl`/workspaces/${'workspaceId'}/simulation-results/survey/${'surveyId'}/all`,
  STOCHASTIC_RAW: ttl`/workspaces/${'workspaceId'}/simulation-results/survey/${'surveyId'}/raw`,
  STOCHASTIC_EXPORT: ttl`/simulation-result/job-task/${'jobTaskId'}/extract-result`
}
