<template>
  <div>
    <a-space>
      <a-tooltip v-if="isTextarea" placement="top">
        <template #title>
          <span>Bold</span>
        </template>
        <a-button @click="handleClickTools(TOOL_KEYS.BOLD)"><strong>B</strong></a-button>
      </a-tooltip>
      <a-tooltip v-if="isTextarea" placement="top">
        <template #title>
          <span>Italic</span>
        </template>
        <a-button @click="handleClickTools(TOOL_KEYS.ITALIC)"><i>I</i></a-button>
      </a-tooltip>
      <a-button @click="handleClickTools(TOOL_KEYS.SURVEY_NAME)">Survey name</a-button>
      <a-button @click="handleClickTools(TOOL_KEYS.USER_NAME)">User name</a-button>
      <a-button @click="handleClickTools(TOOL_KEYS.SURVEY_URL)">Survey url</a-button>
    </a-space>
    <a-textarea
      v-if="isTextarea"
      v-model:value="content"
      :default-value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :auto-size="true"
      @change="onHandleChange"
      @focus="(e) => onFocus(e)"
      @pressEnter="onPressEnter"
    />
    <a-input
      v-if="!isTextarea"
      v-model:value="content"
      :default-value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="onHandleChange"
      @focus="(e) => onFocus(e)"
    />
    <blockquote v-if="isTextarea" class="preview-content" v-html="content"></blockquote>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    value: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    disabled: Boolean,
    isTextarea: { type: Boolean, default: true }
  },
  emits: ['onChange', 'update:value'],
  setup(props, { emit }) {
    const content = ref<string>(props.value || '')
    const focusingElement = ref<any>()

    const TOOL_KEYS = {
      BOLD: 'BOLD',
      ITALIC: 'ITALIC',
      SURVEY_NAME: 'SURVEY_NAME',
      USER_NAME: 'USER_NAME',
      SURVEY_URL: 'SURVEY_URL'
    }

    /**
     * On focusing text area
     **/
    const onFocus = (evt: FocusEvent) => {
      focusingElement.value = evt.target as HTMLTextAreaElement
    }

    /**
     * On losing focus text area
     **/
    const onBlur = () => {
      focusingElement.value = undefined
    }

    /**
     * Handle change input value
     * @param e
     */
    const onHandleChange = (e: MouseEvent) => {
      const ele = e.target as HTMLTextAreaElement
      content.value = ele.value
      updateAntdFormValue(ele.value)
    }

    /**
     * Sync textarea value to form item
     **/
    const updateAntdFormValue = (value: any) => {
      emit('onChange', value)
      emit('update:value', value)
    }

    /**
     * Handle click tools
     * @param key
     */
    const handleClickTools = (key: string) => {
      const target = focusingElement.value
      if (target) {
        const text = target.value
        const cursorStart = target.selectionStart
        const cursorEnd = target.selectionEnd
        const selected = text.slice(cursorStart, cursorEnd)
        if (key === TOOL_KEYS.BOLD) {
          content.value =
            text.slice(0, cursorStart) + `<strong>${selected}</strong>` + text.slice(cursorEnd)
        } else if (key === TOOL_KEYS.ITALIC) {
          content.value = text.slice(0, cursorStart) + `<i>${selected}</i>` + text.slice(cursorEnd)
        } else if (key === TOOL_KEYS.SURVEY_NAME) {
          content.value = text.slice(0, cursorStart) + ' {{ surveyName }} ' + text.slice(cursorEnd)
        } else if (key === TOOL_KEYS.USER_NAME) {
          content.value = text.slice(0, cursorStart) + ' {{ userName }} ' + text.slice(cursorEnd)
        } else if (key === TOOL_KEYS.SURVEY_URL) {
          content.value = text.slice(0, cursorStart) + ' {{ surveyUrl }} ' + text.slice(cursorEnd)
        }
      }
      updateAntdFormValue(content.value)
      onBlur()
    }

    /**
     * Handle press enter
     * @param e
     */
    const onPressEnter = (e: MouseEvent) => {
      const element = e.target as HTMLTextAreaElement
      const cursorStart = element.selectionStart
      const cursorEnd = element.selectionEnd
      const text = element.value
      content.value = text.slice(0, cursorStart) + '<br />' + text.slice(cursorEnd)
    }

    return {
      TOOL_KEYS,
      content,
      onHandleChange,
      onFocus,
      onBlur,
      handleClickTools,
      onPressEnter
    }
  }
})
</script>

<style lang="stylus" scoped>
.preview-content
  color black
  border-left 3px solid bisque
  padding-left 10px
</style>
