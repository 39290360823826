<template>
  <div class="sz-survey-list sz-wrapper">
    <div class="sz-command-bar">
      <a-button type="link" @click="handleShowCreateModal">
        <template #icon><SolutionOutlined /></template>
        Create New Survey
      </a-button>
    </div>
    <a-table :data-source="surveyList" size="middle" class="sz-core-content">
      <a-table-column :key="DB_FIELDS.SURVEY.NAME" title="Name" :data-index="DB_FIELDS.SURVEY.NAME">
        <template #default="{ record }">
          <router-link
            :to="{ name: ROUTE_NAME.SURVEY, params: { workspaceId, surveyId: record.id } }"
          >
            {{ record.name }}
          </router-link>
        </template>
      </a-table-column>
      <a-table-column
        :key="DB_FIELDS.SURVEY.STATE"
        title="Status"
        :data-index="DB_FIELDS.SURVEY.STATE"
      />
      <a-table-column
        :key="DB_FIELDS.SURVEY.SURROGATE"
        title="CE"
        :data-index="DB_FIELDS.SURVEY.SURROGATE"
      >
        <template #default="{ record }">
          <a-checkbox v-model:checked="record.surrogate" />
        </template>
      </a-table-column>
      <a-table-column
        :key="DB_FIELDS.SURVEY.COLLECTION_METHOD"
        title="Collection Method"
        :data-index="DB_FIELDS.SURVEY.COLLECTION_METHOD"
      />
      <a-table-column key="scale.method" title="Input Scale" data-index="scale.method" />
      <a-table-column
        :key="DB_FIELDS.COMMON.CREATED_DATE"
        title="Created at"
        :data-index="DB_FIELDS.COMMON.CREATED_DATE"
      />
      <a-table-column key="action" class="sz-table-actions">
        <template #default="{ record }">
          <a-tooltip placement="top">
            <template #title>
              <span>Manage Survey</span>
            </template>
            <router-link
              :to="{ name: ROUTE_NAME.SURVEY, params: { workspaceId, surveyId: record.id } }"
            >
              <SettingTwoTone />
            </router-link>
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="top">
            <template #title>
              <span>Survey Status</span>
            </template>
            <router-link
              :to="{
                name: ROUTE_NAME.SURVEY_STATUSES,
                params: { surveyId: record.id, workspaceId }
              }"
            >
              <PieChartTwoTone two-tone-color="#52c41a" />
            </router-link>
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="top">
            <template #title>
              <span>Survey Analytics</span>
            </template>
            <router-link
              :to="{
                name: ROUTE_NAME.SURVEY_ANALYTICS,
                params: { surveyId: record.id, workspaceId }
              }"
            >
              <RadarChartOutlined />
            </router-link>
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="top">
            <template #title>
              <span>Export Survey</span>
            </template>
            <a-dropdown placement="bottomCenter" :trigger="['click']">
              <CloudTwoTone />
              <template #overlay>
                <a-menu @click="({ key }) => exportCPTResponse(record.id, key)">
                  <a-menu-item :key="SurveyExportFormatEnum.CSV">CSV format</a-menu-item>
                  <a-menu-item :key="SurveyExportFormatEnum.JSON">JSON format</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-tooltip>
          <a-divider type="vertical" />
          <a-popconfirm
            title="Are you sure delete this survey?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteSurveyById(record.key)"
          >
            <a-tooltip placement="top">
              <template #title>
                <span>Delete record</span>
              </template>
              <DeleteTwoTone two-tone-color="#eb2f96" />
            </a-tooltip>
          </a-popconfirm>
        </template>
      </a-table-column>
    </a-table>
  </div>
  <SurveyDialog :is-visible="isShowCreateModal" @handleSetIsVisible="handleShowCreateModal" />
</template>

<script lang="ts">
import {
  CloudTwoTone,
  DeleteTwoTone,
  PieChartTwoTone,
  RadarChartOutlined,
  SettingTwoTone,
  SolutionOutlined
} from '@ant-design/icons-vue'
import { isEmpty } from 'lodash-es'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import SurveyDialog from '@/components/survey/SurveyDialog.vue'
import { DB_FIELDS } from '@/constants/database'
import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { Network } from '@/libs/bayes/Network'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { SurveyMutationEnum } from '@/store/enums/mutations/survey'
import { NetworkStateEnum } from '@/store/enums/states/network'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions, vuexMutations } from '@/store/util'
import { SurveyExportFormatEnum } from '@/types'
import { SurveySchema } from '@/types'
import { User } from '@/types'

export default defineComponent({
  components: {
    SurveyDialog,
    CloudTwoTone,
    DeleteTwoTone,
    SettingTwoTone,
    PieChartTwoTone,
    RadarChartOutlined,
    SolutionOutlined
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    const userListContent = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)
    const currentNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.CURRENT_NETWORK]
    )
    const network = computed(() => {
      if (currentNetwork.value) {
        return new Network(currentNetwork.value)
      }
      return null
    })

    const surveyList = computed(() => {
      const surveys = store.state.survey[SurveyStateEnum.SURVEY_LIST]?.content
      return surveys?.map((each: SurveySchema) => ({
        ...each,
        key: each?.id
      }))
    })

    /**
     * Delete survey by id
     **/
    const deleteSurveyById = (id: SurveySchema['id']) => {
      store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.DELETE_SURVEY), id)
    }

    const isShowCreateModal = ref(false)

    /**
     * Handle display create user model
     **/
    const handleShowCreateModal = () => {
      isShowCreateModal.value = !isShowCreateModal.value
    }

    /**
     * Export responses for current survey
     * @param surveyId
     * @param fileType
     */
    const exportCPTResponse = (surveyId: string, fileType: SurveyExportFormatEnum) => {
      if (
        surveyId &&
        typeof surveyId === 'string' &&
        network.value &&
        !isEmpty(network?.value?.variables)
      ) {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.EXPORT_SURVEY), {
          surveyId,
          format: fileType,
          userIds: userListContent.value.map((each: User) => each.id) as Array<User['id']>,
          variableIds: network?.value?.variables.map((each) => each.id)
        })
      }
    }

    /**
     * Get new survey list when workspace id change
     */
    watch([workspaceId], (newVal, oldVal) => {
      if (newVal !== oldVal && typeof newVal === 'string') {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.GET_SURVEYS), newVal)
      }
    })

    onMounted(() => {
      if (workspaceId.value && typeof workspaceId.value === 'string') {
        store.dispatch(
          vuexActions(ModuleNames.SURVEY, SurveyActionEnum.GET_SURVEYS_ALL),
          workspaceId.value
        )
        // reset current survey for create survey modal
        store.commit(vuexMutations(ModuleNames.SURVEY, SurveyMutationEnum.SET_CURRENT_SURVEY), null)
      }
    })

    return {
      exportCPTResponse,
      isShowCreateModal,
      handleShowCreateModal,
      deleteSurveyById,
      workspaceId,
      ROUTE_NAME,
      DB_FIELDS,
      surveyList,
      SurveyExportFormatEnum
    }
  }
})
</script>
<style></style>
