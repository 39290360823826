
import 'splitpanes/dist/splitpanes.css'

import { DeviceFloppy, Palette, Run, TableExport } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import writeXlsxFile from 'write-excel-file'

import CommonAnalysis, {
  EVENTS as COMMON_EVENTS
} from '@/components/analysis/common/CommonAnalysis.vue'
import NetworkList, {
  EVENTS as NET_EVENTS
} from '@/components/analysis/common/network-list/NetworkList.vue'
import SensitivityNodeSelection, {
  EVENTS as VAR_EVENTS
} from '@/components/analysis/common/node-selection/NodeSelectionTable.vue'
import SensitivityTaskList, {
  EVENTS as TASK_EVENTS
} from '@/components/analysis/common/task-list/TaskList.vue'
import WorkspaceInfo from '@/components/analysis/common/workspace-info/WorkspaceInfo.vue'
import useAnalysis from '@/components/analysis/composable/analysis'
import useBase, { TABS } from '@/components/analysis/composable/base'
import useJob from '@/components/analysis/composable/job'
import usePalette from '@/components/analysis/composable/palette'
import useSensitivity, {
  inputNodeMapper,
  outputNodeMapper
} from '@/components/analysis/composable/sensitivity'
import { AnalysisTask, ChartData, NodeSelection } from '@/components/analysis/libs/common'
import SensitivityByInputChart from '@/components/analysis/sensitivity/charts/SensitivityByInputChart.vue'
import SensitivityByVariationChart from '@/components/analysis/sensitivity/charts/SensitivityByVariationChart.vue'
import SensitivityResultsTable, {
  EVENTS as TABLE_EVENTS,
  TABS as NODE_TYPE
} from '@/components/analysis/sensitivity/SensitivityResultsTable.vue'
import { EMIT_EVENTS } from '@/constants/emits'
import { ROUTE_NAME } from '@/constants/router'
dayjs.extend(relativeTime)
import { Network } from '@/libs/bayes'
import { gradientMaker } from '@/libs/utils'
import { cssIcon } from '@/styles/common'
import { JobType } from '@/types/database/job'

export const CHART_TABS = {
  BY_VARIATION: 'BY_VARIATION',
  BY_INPUT: 'BY_INPUT'
}

export default defineComponent({
  components: {
    CommonAnalysis,
    DeviceFloppy,
    Palette,
    Run,
    Icon,
    WorkspaceInfo,
    NetworkList,
    Pane,
    Splitpanes,
    SensitivityByInputChart,
    SensitivityByVariationChart,
    SensitivityNodeSelection,
    SensitivityResultsTable,
    SensitivityTaskList,
    TableExport
  },
  setup() {
    const activeTab = ref(TABS.PARAMS)
    const activeChartTab = ref(CHART_TABS.BY_VARIATION)
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const selectionConfig = {
      inputVisible: true,
      utilityVectorVisible: true,
      stateVisible: true,
      variationVisible: false,
      variationsVisible: true,
      constraintVisible: false,
      headers: {
        input: {
          title: 'Variation',
          desc: 'Variation Node'
        },
        output: {
          title: 'Observed',
          desc: 'Observed Node'
        }
      }
    }
    const {
      networks,
      removeNetwork,
      loadNetworks,
      loadWorkspace,
      workspace,
      workspaceModified
    } = useBase(workspaceId)

    const {
      paletteVisible,
      paletteName,
      paletteColors,
      selectPalette,
      showPalettePicker
    } = usePalette()

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }
    const selectedTaskId: Ref<string | undefined> = ref()
    const isDeterministicResultShown: Ref<boolean> = ref(false)

    const {
      currentUser,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      updateNodeSelections,
      inputNodeKeys,
      outputNodeKeys,
      updateInputOutputNodes,
      commonNodeKeys
    } = useAnalysis(workspaceId, JobType.SENSITIVITY, networks)

    const {
      currentJob,
      abortTask,
      tasks,
      taskMap,
      loadJob,
      loadTasks,
      persistJob,
      persistTasks,
      isUpdatingJob,
      isCreatingJob,
      executeTask,
      syncTasks,
      removeTask
    } = useJob(
      currentUser,
      workspaceId,
      JobType.SENSITIVITY,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      onSuccessSaveJob
    )

    const {
      maxOutputValue,
      minOutputValue,
      chanceRows,
      detRows,
      allVariations,
      commonVariations,
      maxDetStates,
      detChartData,
      chanceChartData,
      chartNodeIds,
      isExecuting,
      selectedNetwork,
      selectedObservedNodeKey,
      selectedVariationIndex,
      chanceChartByInputData,
      detChartByInputData,
      executeAnalysisSync,
      baselineIndex,
      clearResult,
      pullResult,
      exportTabular
    } = useSensitivity(
      workspaceId,
      networks,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      updateTable,
      onSuccessSaveJob,
      currentJob
    )

    const chartData: Ref<ChartData> = ref([])
    const chartByInputData: Ref<ChartData> = ref([])

    const observedNodeOptions: Ref<any[]> = ref([])
    const selectedColIndex: Ref<number> = ref(-1)
    const taskOptions = computed(
      () =>
        tasks.value?.map((task: AnalysisTask) => ({
          label: task.name + ` (${task.status})`,
          value: task.id
        })) || []
    )

    const variationNodeKeys = computed(() =>
      isDeterministicResultShown.value
        ? detRows.value?.map((row: any) => row.name)
        : chanceRows.value?.map((row: any) => row.name)
    )
    const networkColumns = computed(() => {
      return [
        {
          title: 'Network name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Nodes count',
          dataIndex: 'nodes.length',
          key: 'nodes'
        }
      ]
    })

    const networkRows = computed(
      () =>
        networks.value?.map((network: any) => ({
          key: network.id,
          nodes: network.nodes,
          name: network.name
        })) || []
    )

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(workspace.value?.name || 'UNTITLED')
    }))

    const handleLoadNetwork = () => {
      loadNetworks()
    }

    const calculate = () => {
      if (selectedNetwork.value && selectedObservedNodeKey.value) {
        executeAnalysisSync()
        updateTable()
      }
    }

    const toggleInput = () => {
      updateInputOutputNodes()
    }
    const toggleOutput = () => {
      updateInputOutputNodes()
      updatedObservedNodeOptions(selectedNetwork.value)
    }

    const onVariationsUpdated = ({ variations, variationsText, variationStep }: any) => {
      nodeSelections.value?.forEach((node: NodeSelection) => {
        if (node.isInput) {
          node.variations = variations
          node.variationsText = variationsText
          node.variation = variationStep
        }
      })
      updateTable()
    }

    watch(networks, async () => {
      updateNodeSelections()
      await loadJob()
      await loadTasks()
      selectedNetwork.value = networks.value?.[0]
      updatedObservedNodeOptions(networks.value?.[0])
    })

    watch(nodeSelections, () => {
      // updateSensitivityRows()
    })

    onMounted(() => {
      loadWorkspace()
      loadNetworks()
    })

    const saveJob = async () => {
      await persistJob()
      await persistTasks()
    }

    const selectCol = (index: number) => {
      if (index > baselineIndex.value) {
        selectedVariationIndex.value = index - 1
      } else if (index < baselineIndex.value) {
        selectedVariationIndex.value = index
      }
      executeAnalysisSync()
      updateTable()
    }

    const selectNodeType = (tab: string) => {
      if (tab === NODE_TYPE.DETERMINISTIC) {
        chartData.value = [...detChartData.value]
        chartByInputData.value = [...detChartByInputData.value]
        isDeterministicResultShown.value = true
      } else {
        chartData.value = [...chanceChartData.value]
        chartByInputData.value = [...chanceChartByInputData.value]
        isDeterministicResultShown.value = false
      }
    }

    watch(tasks, () => {
      if (!tasks.value?.length) {
        return
      }
    })

    watch(taskOptions, () => {
      if (!selectedTaskId.value && taskOptions.value.length) {
        selectedTaskId.value = taskOptions.value?.[0].value
      }
    })

    watch(selectedTaskId, () => {
      if (!selectedTaskId.value || !taskMap.value[selectedTaskId.value]?.networkId) {
        return
      }
      const _network = networkMap.value?.[taskMap.value[selectedTaskId.value]?.networkId]
      if (_network) {
        updatedObservedNodeOptions(_network)
        selectedNetwork.value = _network
      }
    })

    const updatedObservedNodeOptions = (selectedNetwork: Network) => {
      if (!selectedNetwork) {
        return
      }
      const _observedNodeOptions: any[] = []
      nodeSelections.value?.forEach((node: NodeSelection) => {
        const { isOutput, key } = node
        const variable = selectedNetwork.variableMapByKey[key]
        if (!variable) {
          return
        }
        if (isOutput) {
          _observedNodeOptions.push({
            label: key,
            value: key
          })
        }
      })
      observedNodeOptions.value = _observedNodeOptions
      selectedObservedNodeKey.value = _observedNodeOptions?.[0]?.value
    }

    const exportResults = async () => {
      const { datas, names } = exportTabular()
      await writeXlsxFile(datas, {
        sheets: names,
        fileName: workspace.value?.name + '-results.xlsx'
      })
    }

    const run = (task: AnalysisTask) => {
      executeTask(task)
    }

    const runAll = async () => {
      updateInputOutputNodes()
      executeTask()
    }

    const abort = (task: AnalysisTask) => {
      if (!abortTask(task)) {
        message.error('You need to save the job first')
      }
    }

    const refresh = () => {
      loadTasks()
    }

    const remove = async (taskId: string, taskIndex: number) => {
      removeTask(taskId, taskIndex)
      await saveJob()
      if (selectedTaskId.value === taskId) {
        clearResult()
      }
    }

    const sync = async () => {
      syncTasks()
      await saveJob()
    }

    const selectTask = (task: AnalysisTask) => {
      selectedTaskId.value = task.id
      const _network = networkMap.value?.[task.networkId]
      if (_network) {
        selectedNetwork.value = _network
      }
    }

    const showResults = async () => {
      if (selectedNetwork.value && selectedObservedNodeKey.value && selectedTaskId.value) {
        clearResult()
        await pullResult(taskMap.value[selectedTaskId.value])
        selectNodeType(NODE_TYPE.CHANCE)
        updateTable()
      }
    }

    return {
      isDeterministicResultShown,
      selectTask,
      runAll,
      abort,
      refresh,
      remove,
      run,
      sync,
      showResults,
      activeChartTab,
      activeTab,
      allVariations,
      baselineIndex,
      calculate,
      cardStyle,
      chanceRows,
      CHART_TABS,
      chartData,
      chartByInputData,
      chanceChartByInputData,
      chanceChartData,
      detChartData,
      detChartByInputData,
      chartNodeIds,
      COMMON_EVENTS,
      commonNodeKeys,
      commonVariations,
      cssIcon,
      detRows,
      EMIT_EVENTS,
      NODE_TYPE,
      exportResults,
      handleLoadNetwork,
      inputNodeKeys,
      isCreatingJob,
      isExecuting,
      isUpdatingJob,
      loadNetworks,
      maxDetStates,
      maxOutputValue,
      minOutputValue,
      NET_EVENTS,
      networkColumns,
      networkMap,
      taskOptions,
      selectedTaskId,
      networkRows,
      networks,
      nodeSelections,
      observedNodeOptions,
      onVariationsUpdated,
      outputNodeKeys,
      paletteColors,
      paletteName,
      paletteVisible,
      removeNetwork,
      ROUTE_NAME,
      saveJob,
      selectCol,
      selectedColIndex,
      selectedNetwork,
      selectedObservedNodeKey,
      selectionConfig,
      selectPalette,
      showPalettePicker,
      syncTasks,
      selectNodeType,
      TABLE_EVENTS,
      table,
      tableKey,
      TABS,
      TASK_EVENTS,
      tasks,
      toggleInput,
      toggleOutput,
      VAR_EVENTS,
      variationNodeKeys,
      workspace,
      workspaceId,
      workspaceModified
    }
  }
})
