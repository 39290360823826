import { find, keyBy, size } from 'lodash-es'
import { isEmpty, isNil } from 'ramda'

import { DB_FIELDS } from '@/constants/database'
import { ModuleNames } from '@/constants/vuex'
import { Network, Variable } from '@/libs/bayes'
import { Dict } from '@/libs/common'
import { Store } from '@/store'
import { AllocationActionEnum } from '@/store/enums/actions'
import { vuexActions } from '@/store/util'
import { Allocation, AllocationForm, Assignment, User, Workspace } from '@/types'

const {
  ASSIGNMENTS,
  ASSIGNMENTS_ASSIGNED,
  ASSIGNMENTS_EXT,
  ASSIGNMENTS_VARIABLE_ID,
  NETWORK_ID,
  USER_ID,
  WORKSPACE_ID
} = DB_FIELDS.ALLOCATION

export const parseAllocationRaw = (
  rawValue: string,
  userMapByName: Dict,
  variableMapByKey: Dict
): Dict => {
  /* 
  - variable name, e.g. fp1
  - username, e.g. user1,user2
  */
  const rows = rawValue.split(/\r?\n/)
  const mapByUser: Dict = {}
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const cols = row.split(/\s*,\s*/)
    const key = cols[0]
    const usernames = cols.slice(1)
    const variable = variableMapByKey[key]
    if (!variable) {
      continue
    }
    usernames.forEach((username) => {
      const userId = userMapByName[username]?.id
      if (!userId) {
        return
      }
      if (userId in mapByUser) {
        mapByUser[userId].push(variable.id)
      } else {
        mapByUser[userId] = [variable.id]
      }
    })
  }
  return mapByUser
}

export default function useAllocation(
  store: Store,
  workspaceId: Workspace['id'],
  networkId: Network['id']
): any {
  const allocateUserToVariable = async (
    user: User,
    variableIdMap: Dict,
    variables: Array<Variable>,
    allocations: Array<Allocation>
  ): Promise<any> => {
    const userId = user.id

    if (!userId || !size(variableIdMap) || !networkId) {
      return
    }
    const allocation: any = find(allocations, {
      [USER_ID]: user.id
    })
    // If current user already has allocation, then update allocation or create new one
    if (allocation) {
      if (allocation.networkId !== networkId) {
        console.error('Mismatched network id for allocation', {
          origin: allocation.networkId,
          passed: networkId
        })
      } else {
        const allocationId = allocation.id
        const newAssignments: any[] = []
        const assignmentMap = keyBy(allocation.assignments, 'variableId')
        allocation.assignments?.forEach((assignment: Assignment) => {
          if (assignment.variableId in variableIdMap) {
            if (variableIdMap[assignment.variableId]) {
              newAssignments.push({
                ...assignment,
                [ASSIGNMENTS_ASSIGNED]: variableIdMap[assignment.variableId]
              })
            }
          } else {
            newAssignments.push(assignment)
          }
        })
        Object.keys(variableIdMap).forEach((varId: string) => {
          if (!(varId in assignmentMap)) {
            newAssignments.push({
              [ASSIGNMENTS_ASSIGNED]: variableIdMap[varId],
              [ASSIGNMENTS_EXT]: {},
              [ASSIGNMENTS_VARIABLE_ID]: varId
            })
          }
        })
        const newAllocation: AllocationForm = {
          ...allocation,
          [ASSIGNMENTS]: newAssignments
        }
        return store.dispatch(
          vuexActions(ModuleNames.ALLOCATION, AllocationActionEnum.UPDATE_ALLOCATION),
          { id: allocationId, allocation: newAllocation }
        )
      }
    } else {
      if (!isNil(variables) && !isEmpty(variables)) {
        // If clicked var is equal to current var, then make assigned as true
        const assignments: any[] = []
        Object.keys(variableIdMap).forEach((varId: string) => {
          if (variableIdMap[varId]) {
            assignments.push({
              [ASSIGNMENTS_ASSIGNED]: true,
              [ASSIGNMENTS_EXT]: {},
              [ASSIGNMENTS_VARIABLE_ID]: varId
            })
          }
        })
        return store.dispatch(
          vuexActions(ModuleNames.ALLOCATION, AllocationActionEnum.CREATE_ALLOCATION),
          {
            allocation: {
              [ASSIGNMENTS]: assignments,
              [NETWORK_ID]: networkId,
              [USER_ID]: userId,
              [WORKSPACE_ID]: workspaceId
            } as AllocationForm,
            workspaceId
          }
        )
      }
    }
    return allocation
  }

  return {
    allocateUserToVariable
  }
}
