<template>
  <a-modal
    :visible="visible"
    :title="modalTitle"
    class="sz-survey-form-modal"
    :width="560"
    :ok-button-props="{
      loading: formDisabled
    }"
    :cancel-button-props="{
      disabled: formDisabled
    }"
    :closable="!formDisabled"
    :mask-closable="!formDisabled"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form
      ref="formRef"
      :colon="false"
      :label-col="{ span: 4 }"
      :model="formState"
      :validate-on-rule-change="false"
      :wrapper-col="{ span: 20 }"
      class="sz-survey-form"
    >
      <a-form-item
        :label="FORM_ITEMS.NAME.LABEL"
        :name="FORM_ITEMS.NAME.NAME"
        :rules="FORM_RULES[FORM_ITEMS.NAME.NAME]"
      >
        <a-input v-model:value="formState[FORM_ITEMS.NAME.NAME]" :disabled="formDisabled" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 4 }"
        :label="FORM_ITEMS.STATE.LABEL"
        :name="FORM_ITEMS.STATE.NAME"
        :wrapper-col="{ span: 10 }"
        :rules="FORM_RULES[FORM_ITEMS.STATE.NAME]"
      >
        <a-select
          v-model:value="formState[FORM_ITEMS.STATE.NAME]"
          placeholder="please select state"
          :disabled="formDisabled"
          :options="STATE_OPTIONS"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 4 }"
        :label="FORM_ITEMS.COLLECTION.LABEL"
        :name="FORM_ITEMS.COLLECTION.NAME"
        :wrapper-col="{ span: 10 }"
        :rules="FORM_RULES[FORM_ITEMS.COLLECTION.NAME]"
      >
        <a-select
          v-model:value="formState[FORM_ITEMS.COLLECTION.NAME]"
          placeholder="Please select a collection method"
          :disabled="formDisabled"
          :options="COLLECTION_METHOD_OPTIONS"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 4 }"
        :label="FORM_ITEMS.SCALE.CHILDREN.METHOD.LABEL"
        :name="[FORM_ITEMS.SCALE.NAME, FORM_ITEMS.SCALE.CHILDREN.METHOD.NAME]"
        :wrapper-col="{ span: 10 }"
        :rules="FORM_RULES[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.METHOD.NAME]"
      >
        <a-select
          v-model:value="formState[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.METHOD.NAME]"
          placeholder="Please select a scale"
          :options="SCALE_METHOD_OPTIONS"
          :disabled="formDisabled"
        />
      </a-form-item>
      <!-- <a-form-item
        v-if="false"
        :label="FORM_ITEMS.SCALE.CHILDREN.MIN.LABEL"
        :name="[FORM_ITEMS.SCALE.NAME, FORM_ITEMS.SCALE.CHILDREN.MIN.NAME]"
      >
        <a-input-number
          v-model:value="formState[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.MIN.NAME]"
          :min="0"
          :max="100"
        />
      </a-form-item> -->
      <!-- <a-form-item
        v-if="false"
        :label="FORM_ITEMS.SCALE.CHILDREN.MAX.LABEL"
        :name="[FORM_ITEMS.SCALE.NAME, FORM_ITEMS.SCALE.CHILDREN.MAX.NAME]"
      >
        <a-input-number
          v-model:value="formState[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.MAX.NAME]"
          :min="0"
          :max="100"
        />
      </a-form-item> -->
      <!-- <a-form-item
        v-if="false"
        :label="FORM_ITEMS.SCALE.CHILDREN.PRECISION.LABEL"
        :name="[FORM_ITEMS.SCALE.NAME, FORM_ITEMS.SCALE.CHILDREN.PRECISION.NAME]"
      >
        <a-input-number
          v-model:value="formState[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.PRECISION.NAME]"
          :min="0"
          :max="100"
        />
      </a-form-item> -->
      <a-form-item
        v-for="(step, index) in formState[FORM_ITEMS.SCALE.NAME][
          FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME
        ]"
        v-show="
          formState[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.METHOD.NAME] ===
          DB_ENUM_VALUES.SURVEY.SCALE_METHOD.PHRASE
        "
        :key="`step-${index}`"
        :label="index === 0 ? 'Steps' : ' '"
        :required="index === 0"
        class="sz-survey-steps"
      >
        <a-space size="small" align="start">
          <a-form-item
            :name="[
              FORM_ITEMS.SCALE.NAME,
              FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME,
              index,
              FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.NAME
            ]"
            :rules="
              FORM_RULES[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME][
                FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.NAME
              ]
            "
          >
            <a-input
              v-model:value="step[FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.NAME]"
              placeholder="please input name for step"
              :disabled="formDisabled"
            />
          </a-form-item>
          <a-form-item
            :name="[
              FORM_ITEMS.SCALE.NAME,
              FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME,
              index,
              FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.DESCRIPTION
            ]"
            :rules="
              FORM_RULES[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME][
                FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.DESCRIPTION
              ]
            "
          >
            <a-input
              v-model:value="step[FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.DESCRIPTION]"
              placeholder="please input description for step"
              :disabled="formDisabled"
            />
          </a-form-item>
          <a-form-item
            :name="[
              FORM_ITEMS.SCALE.NAME,
              FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME,
              index,
              FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.VALUE
            ]"
            :rules="
              FORM_RULES[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME][
                FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.VALUE
              ]
            "
          >
            <a-input-number
              v-model:value="step[FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.VALUE]"
              placeholder="please input value for step"
              :disabled="formDisabled"
            />
          </a-form-item>
          <MinusCircleOutlined
            v-if="formState[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME].length > 0"
            @click="removeStep(step)"
          />
        </a-space>
      </a-form-item>
      <a-form-item
        v-show="
          formState[FORM_ITEMS.SCALE.NAME][FORM_ITEMS.SCALE.CHILDREN.METHOD.NAME] ===
          DB_ENUM_VALUES.SURVEY.SCALE_METHOD.PHRASE
        "
        :wrapper-col="{ span: 20, offset: 4 }"
      >
        <a-button type="dashed" style="width: 60%" :disabled="formDisabled" @click="addStep">
          <PlusOutlined />
          Add step
        </a-button>
      </a-form-item>
      <h3 class="form-header">Template Variables</h3>
      <a-tag
        v-for="eachKey in Object.keys(SURVEY_TEMPLATE_VARIABLES)"
        :key="eachKey"
        :color="TEMPLATE_VARIABLE_COLORS[eachKey]"
        :value="SURVEY_TEMPLATE_VARIABLES[eachKey]"
        style="cursor: pointer; margin-bottom: 20px"
        @mousedown="handleClickTemplateVariable(eachKey)"
        >{{ SURVEY_TEMPLATE_VARIABLES[eachKey] }}</a-tag
      >
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME]"
      >
        <a-textarea
          v-model:value="formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME]"
          placeholder="Introduction text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME]"
      >
        <a-textarea
          v-model:value="formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME]"
          placeholder="Description text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <!-- Ace -->
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME]"
      >
        <a-textarea
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME]
          "
          placeholder="ACE Introduction text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME]"
      >
        <a-textarea
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME]
          "
          placeholder="ACE Description text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <!-- Ace new likelihood + weigts -->
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME]"
      >
        <a-textarea
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME]
          "
          placeholder="ACE Introduction text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME]"
      >
        <a-textarea
          v-model:value="formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME]"
          placeholder="ACE Description text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <!-- Marginal -->
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME]"
      >
        <a-textarea
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME]
          "
          placeholder="Marginal Introduction text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME]"
      >
        <a-textarea
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME]
          "
          placeholder="Marginal Description text"
          :disabled="formDisabled"
          auto-size
          @focus="(e) => onFocus(e, FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME)"
          @blur="onBlur"
        />
      </a-form-item>
      <h3 class="form-header">Email config</h3>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_SUBJECT.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_SUBJECT.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_SUBJECT.NAME]"
      >
        <RichTextEditor
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_SUBJECT.NAME]
          "
          placeholder="Email share subject text"
          :disabled="formDisabled"
          :is-textarea="false"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_BODY.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_BODY.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_BODY.NAME]"
      >
        <RichTextEditor
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_BODY.NAME]
          "
          placeholder="Email share body text"
          :disabled="formDisabled"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_SUBJECT.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_SUBJECT.NAME]"
        :rules="
          FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_SUBJECT.NAME]
        "
      >
        <RichTextEditor
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_SUBJECT.NAME]
          "
          placeholder="Email reminder subject text"
          :disabled="formDisabled"
          :is-textarea="false"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_BODY.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_BODY.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_BODY.NAME]"
      >
        <RichTextEditor
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_BODY.NAME]
          "
          placeholder="Email reminder body text"
          :disabled="formDisabled"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_SUBJECT.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_SUBJECT.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_SUBJECT.NAME]"
      >
        <RichTextEditor
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_SUBJECT.NAME]
          "
          placeholder="Email close subject text"
          :disabled="formDisabled"
          :is-textarea="false"
        />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_BODY.LABEL"
        :name="[FORM_ITEMS.EXT.NAME, FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_BODY.NAME]"
        :rules="FORM_RULES[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_BODY.NAME]"
      >
        <RichTextEditor
          v-model:value="
            formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_BODY.NAME]
          "
          placeholder="Email close body text"
          :disabled="formDisabled"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { clone, difference } from 'ramda'
import { computed, defineComponent, reactive, ref, toRaw, watch } from 'vue'

import RichTextEditor from '@/components/survey/RichTextEditor.vue'
import { SURVEY_TEMPLATE_VARIABLES } from '@/constants/components'
import { DB_ENUM_VALUES } from '@/constants/database'
import { LABELS } from '@/constants/labels'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { checkNumberInput } from '@/libs/formValidate'
import router from '@/router'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { vuexActions } from '@/store/util'
import type { Step, SurveyForm } from '@/types'

interface STEP {
  name: string
  description: string
  value: number
}
const STEPS: STEP[] = [
  {
    name: 'Impossible',
    description: 'Impossible',
    value: 0
  },
  {
    name: 'Very Unlikely',
    description: 'Very Unlikely',
    value: 12
  },
  {
    name: 'Unlikely',
    description: 'Unlikely',
    value: 25
  },
  {
    name: 'Fairly Unlikely',
    description: 'Fairly Unlikely',
    value: 38
  },
  {
    name: 'As Likely as Not',
    description: 'As Likely as Not',
    value: 50
  },
  {
    name: 'Fairly Likely',
    description: 'Fairly Likely',
    value: 62
  },
  {
    name: 'Likely',
    description: 'Likely',
    value: 75
  },
  {
    name: 'Very Likely',
    description: 'Very Likely',
    value: 88
  },
  {
    name: 'Certain',
    description: 'Certain',
    value: 100
  }
]

const FORM_ITEMS = {
  NAME: {
    LABEL: 'Name',
    NAME: 'name'
  },
  STATE: {
    LABEL: 'State',
    NAME: 'state'
  },
  COLLECTION: {
    LABEL: 'Collection',
    NAME: 'collectionMethod'
  },
  SCALE: {
    NAME: 'scale',
    CHILDREN: {
      METHOD: {
        LABEL: 'Scale',
        NAME: 'method'
      },
      MIN: {
        LABEL: 'Scale',
        NAME: 'min'
      },
      MAX: {
        LABEL: 'Scale',
        NAME: 'max'
      },
      PRECISION: {
        LABEL: 'Scale',
        NAME: 'precision'
      },
      STEPS: {
        NAME: 'steps',
        CHILDREN: {
          NAME: 'name',
          DESCRIPTION: 'description',
          VALUE: 'value'
        }
      }
    }
  },
  EXT: {
    NAME: 'ext',
    CHILDREN: {
      INTRODUCTION: {
        LABEL: 'Introduction',
        NAME: 'ui_introduction'
      },
      DESCRIPTION: {
        LABEL: 'Description',
        NAME: 'ui_description'
      },
      ACE_INTRODUCTION: {
        LABEL: 'ACE Method Introduction',
        NAME: 'ace_introduction'
      },
      ACE_DESCRIPTION: {
        LABEL: 'ACE Method Description',
        NAME: 'ace_description'
      },
      ACE_LIKELIHOOD: {
        LABEL: 'ACE Likelihood Intro',
        NAME: 'ace_likelihood'
      },
      ACE_WEIGHTS: {
        LABEL: 'ACE Weights Intro',
        NAME: 'ace_weights'
      },
      MARGINAL_INTRODUCTION: {
        LABEL: 'Marginal Introduction',
        NAME: 'marginal_introduction'
      },
      MARGINAL_DESCRIPTION: {
        LABEL: 'Marginal Description',
        NAME: 'marginal_description'
      },
      EMAIL_SHARE_SUBJECT: {
        LABEL: 'Email share subject',
        NAME: 'email_share_subject'
      },
      EMAIL_SHARE_BODY: {
        LABEL: 'Email share body',
        NAME: 'email_share_body'
      },
      EMAIL_REMINDER_SUBJECT: {
        LABEL: 'Email reminder subject',
        NAME: 'email_reminder_subject'
      },
      EMAIL_REMINDER_BODY: {
        LABEL: 'Email reminder body',
        NAME: 'email_reminder_body'
      },
      EMAIL_CLOSE_SUBJECT: {
        LABEL: 'Email close subject',
        NAME: 'email_close_subject'
      },
      EMAIL_CLOSE_BODY: {
        LABEL: 'Email close body',
        NAME: 'email_close_body'
      }
    }
  }
}

const FORM_RULES = {
  [FORM_ITEMS.NAME.NAME]: [
    {
      required: true,
      message: 'Please input the name',
      trigger: 'change'
    }
  ],
  [FORM_ITEMS.STATE.NAME]: [
    {
      required: true,
      message: 'Please select the state',
      trigger: 'change'
    }
  ],
  [FORM_ITEMS.COLLECTION.NAME]: [
    {
      required: true,
      message: 'Please select a collection method',
      trigger: 'change'
    }
  ],
  [FORM_ITEMS.SCALE.NAME]: {
    [FORM_ITEMS.SCALE.CHILDREN.METHOD.NAME]: [
      {
        required: true,
        message: 'Please select a scale',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.SCALE.CHILDREN.MIN.NAME]: [
      {
        validator: checkNumberInput,
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.SCALE.CHILDREN.MAX.NAME]: [
      {
        validator: checkNumberInput,
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.SCALE.CHILDREN.PRECISION.NAME]: [
      {
        validator: checkNumberInput,
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME]: {
      [FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.NAME]: [
        {
          required: true,
          message: 'Please enter the name',
          trigger: 'change'
        }
      ],
      [FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.DESCRIPTION]: [
        {
          required: true,
          message: 'Please enter the description',
          trigger: 'change'
        }
      ],
      [FORM_ITEMS.SCALE.CHILDREN.STEPS.CHILDREN.VALUE]: [
        {
          required: true,
          message: 'Please enter a number',
          trigger: 'change',
          type: 'number'
        },
        {
          max: 100,
          message: 'The maximum value is 100',
          type: 'number'
        },
        {
          min: 0,
          message: 'The minimum value is 0',
          type: 'number'
        },
        {
          message: 'Please enter an integer',
          type: 'integer'
        }
      ]
    }
  },
  [FORM_ITEMS.EXT.NAME]: {
    [FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME]: [
      {
        required: true,
        message: 'Please enter introduction text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME]: [
      {
        required: true,
        message: 'Please enter description text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME]: [
      {
        required: true,
        message: 'Please enter ACE Method introduction text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME]: [
      {
        required: true,
        message: 'Please enter ACE Method description text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME]: [
      {
        required: true,
        message: 'Please enter ACE Likelihood text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME]: [
      {
        required: true,
        message: 'Please enter ACE Weights text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME]: [
      {
        required: true,
        message: 'Please enter marginal introduction text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME]: [
      {
        required: false,
        message: 'Please enter marginal description text',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_SUBJECT.NAME]: [
      {
        required: true,
        message: 'Please enter email share subject',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_BODY.NAME]: [
      {
        required: true,
        message: 'Please enter email share body',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_SUBJECT.NAME]: [
      {
        required: true,
        message: 'Please enter email reminder subject',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_BODY.NAME]: [
      {
        required: true,
        message: 'Please enter email reminder body',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_SUBJECT.NAME]: [
      {
        required: true,
        message: 'Please enter email close subject',
        trigger: 'change'
      }
    ],
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_BODY.NAME]: [
      {
        required: true,
        message: 'Please enter email close body',
        trigger: 'change'
      }
    ]
  }
}

const DEFAULT_FORM_STATE = {
  [FORM_ITEMS.NAME.NAME]: 'Untitled',
  [FORM_ITEMS.STATE.NAME]: DB_ENUM_VALUES.SURVEY.STATE.IN_PROGRESS,
  [FORM_ITEMS.COLLECTION.NAME]: DB_ENUM_VALUES.SURVEY.COLLECTION_METHOD.MIXED,
  [FORM_ITEMS.SCALE.NAME]: {
    [FORM_ITEMS.SCALE.CHILDREN.METHOD.NAME]: DB_ENUM_VALUES.SURVEY.SCALE_METHOD.CONTINUOUS,
    [FORM_ITEMS.SCALE.CHILDREN.MIN.NAME]: 0,
    [FORM_ITEMS.SCALE.CHILDREN.MAX.NAME]: 100,
    [FORM_ITEMS.SCALE.CHILDREN.PRECISION.NAME]: 1,
    [FORM_ITEMS.SCALE.CHILDREN.STEPS.NAME]: STEPS
  },
  [FORM_ITEMS.EXT.NAME]: {
    [FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME]:
      'These questions will address {{ selected }}. It is influenced by the following parents: {{ parent }}.',
    [FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME]:
      'For each question below, assess the likelihood {{ selected }} will be in a {{ selectedState }} state given the specified state of its parents. Assign a number between 0 - 100',
    [FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME]:
      'Given {{ parent }} are all adequate. What is the likelihood that adequate {{ selected }}',
    [FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME]:
      'For each parent (contributing to the child), give a weight between 0 and 100. Set 100 for the parent with greatest contribution. Allocate weights other parent’s contribution relative to that',
    [FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME]:
      '<strong>Likelihood probabilities (0 to 100%)</strong> of {{ selected }} for the best-case scenario (all contributing effects are in favorable state) and the worst-case scenario (all contributing effects are in unfavorable state).',
    [FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME]:
      '<strong>Weights (0 to 100)</strong>:  Identify the effect (below) with the greatest importance for contributing to {{ selected }} and associate a weight of 100 to it. Allocate weights to the remaining effects in relation to the one with greatest importance.',
    [FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME]:
      '<strong>Weights (0 to 100)</strong>: Assess the likelihood that {{ selected }} will be in its states.',
    [FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME]: 'N/A',
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_SUBJECT.NAME]:
      '[SAUCE] Survey shared (name: {{ surveyName }})',
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_SHARE_BODY.NAME]:
      'Dear {{ userName }}, <br />The survey {{ surveyName }} is currently available. Please click the link {{ surveyUrl }} here.',
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_SUBJECT.NAME]: 'N/A',
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_REMINDER_BODY.NAME]: 'N/A',
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_SUBJECT.NAME]: 'N/A',
    [FORM_ITEMS.EXT.CHILDREN.EMAIL_CLOSE_BODY.NAME]: 'N/A'
  }
} as Partial<SurveyForm>

const TEMPLATE_VARIABLE_COLORS: Record<string, any> = {
  SELECTED: 'orange',
  PARENT: 'pink',
  SELECTED_STATE: 'green'
}

const STATE_OPTIONS = Object.values(DB_ENUM_VALUES.SURVEY.STATE).map((el, idx) => ({
  value: el,
  label: LABELS.SURVEY.STATE[el],
  key: idx
}))

const COLLECTION_METHOD_OPTIONS = Object.values(DB_ENUM_VALUES.SURVEY.COLLECTION_METHOD)
  .filter(
    (method) =>
      method !== DB_ENUM_VALUES.SURVEY.COLLECTION_METHOD.MARGINAL &&
      method !== DB_ENUM_VALUES.SURVEY.COLLECTION_METHOD.DEFAULT
  )
  .map((el, idx) => ({
    value: el,
    label: LABELS.SURVEY.COLLECTION_METHOD[el],
    key: idx
  }))

const SCALE_METHOD_OPTIONS = Object.values(DB_ENUM_VALUES.SURVEY.SCALE_METHOD).map((el, idx) => ({
  value: el,
  label: LABELS.SURVEY.SCALE_METHOD[el],
  key: idx
}))

/**
 * Init form data
 **/
const initFormData = (currentSurvey: any) => {
  const data = Object.assign(clone(DEFAULT_FORM_STATE), currentSurvey)
  if (data.scale?.steps && data.scale.steps.length == 0) {
    data.scale.steps = [...STEPS]
  }
  return data
}

/**
 * Update form data
 **/
const updateFormData = (newData: any, oldData: any) => {
  const cloneNewData = clone(newData)
  const newKeys = Object.keys(cloneNewData)
  const oldKeys = Object.keys(oldData)
  const removeKeys = difference(oldKeys, newKeys)
  for (const key of removeKeys) {
    delete oldData[key]
  }
  for (const key of newKeys) {
    oldData[key] = cloneNewData[key]
  }
  return oldData
}

export const EVENTS = {
  ON_TOGGLE_SHOW: 'handleSetIsVisible'
}

export default defineComponent({
  name: 'SurveyForm',
  components: {
    PlusOutlined,
    MinusCircleOutlined,
    RichTextEditor
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const store = useStore()
    const routerParams = router.currentRoute.value.params
    const { workspaceId, surveyId } = routerParams

    const currentSurvey = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.CURRENT_SURVEY]
    )

    const formState = reactive(initFormData(currentSurvey.value))
    const formRef = ref()
    const visible = computed(() => props.isVisible)
    const introductionEl = ref()
    const descriptionEl = ref()
    const focusingTextArea = ref()
    const formDisabled = ref<boolean>(false)
    const isCreate = computed(() => typeof surveyId !== 'string')
    const modalTitle = computed(() => (isCreate.value ? 'Create Survey' : 'Edit Survey'))

    watch(currentSurvey, () => {
      const data = initFormData(currentSurvey.value)
      updateFormData(data, formState)
    })

    /**
     * Handle click remove step button
     * @param {Step} item
     */
    const removeStep = (item: Step) => {
      if (formState.scale && !formDisabled.value) {
        let index = formState.scale.steps.indexOf(item)
        if (index !== -1) {
          formState.scale.steps.splice(index, 1)
        }
      }
    }

    /**
     * Handle click add step button
     */
    const addStep = () => {
      if (formState.scale) {
        formState.scale.steps.push({
          name: '',
          description: '',
          value: 0
        })
      }
    }

    /**
     * Close the modal
     */
    const handleCancel = () => {
      emit(EVENTS.ON_TOGGLE_SHOW)
    }

    /**
     * On focusing text area
     **/
    const onFocus = (evt: FocusEvent, fieldName: string) => {
      focusingTextArea.value = {
        target: evt.target as HTMLTextAreaElement,
        fieldName
      }
    }

    /**
     * On losing focus text area
     **/
    const onBlur = () => {
      focusingTextArea.value = undefined
    }

    /**
     * Handle click each template variable to insert variable into textarea
     **/
    const handleClickTemplateVariable = (variableKey: string) => {
      if (focusingTextArea.value) {
        const { target, fieldName } = focusingTextArea.value
        if (target) {
          const cursorStartPosition = target.selectionStart
          const cursorEndPosition = target.selectionEnd
          const text = target.value
          const newValue =
            text.slice(0, cursorStartPosition) +
            ` {{ ${SURVEY_TEMPLATE_VARIABLES[variableKey]} }} ` +
            text.slice(cursorEndPosition)
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.INTRODUCTION.NAME]: newValue
              }
            }
          }
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.DESCRIPTION.NAME]: newValue
              }
            }
          }
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][
                FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME
              ] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.ACE_INTRODUCTION.NAME]: newValue
              }
            }
          }
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][
                FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME
              ] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.ACE_DESCRIPTION.NAME]: newValue
              }
            }
          }
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.ACE_LIKELIHOOD.NAME]: newValue
              }
            }
          }
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.ACE_WEIGHTS.NAME]: newValue
              }
            }
          }
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][
                FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME
              ] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.MARGINAL_INTRODUCTION.NAME]: newValue
              }
            }
          }
          if (fieldName === FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME) {
            if (formState.ext) {
              formState[FORM_ITEMS.EXT.NAME][
                FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME
              ] = newValue
            } else {
              formState.ext = {
                [FORM_ITEMS.EXT.CHILDREN.MARGINAL_DESCRIPTION.NAME]: newValue
              }
            }
          }
        }
      }
    }

    /**
     * Handle submit register
     */
    const handleSubmit = async () => {
      formDisabled.value = true
      try {
        if (typeof workspaceId === 'string') {
          // validate form before create/update
          try {
            await formRef.value.validate()
          } catch (err: any) {
            formDisabled.value = false
            const firstErrorFieldName =
              err.errorFields[0].name.length > 1
                ? [err.errorFields[0].name]
                : err.errorFields[0].name
            formRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
            return
          }
          const surveyData = { ...toRaw(formState) }
          if (typeof surveyId === 'string') {
            // update survey
            await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.UPDATE_SURVEY), {
              id: surveyId,
              survey: {
                ...surveyData,
                workspaceId,
                surveyId
              }
            })
            message.success(MESSAGE.SURVEY_CONFIG_UPDATE_SUCCESS)
          } else {
            // create survey
            await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.CREATE_SURVEY), {
              workspaceId,
              survey: {
                ...surveyData,
                workspaceId
              } as SurveyForm
            })
            updateFormData(initFormData(currentSurvey.value), formState)
            message.success(MESSAGE.SURVEY_CONFIG_CREATE_SUCCESS)
          }
          emit(EVENTS.ON_TOGGLE_SHOW)
        }
        formDisabled.value = false
      } catch (err) {
        formDisabled.value = false
        throw err
      }
    }

    return {
      COLLECTION_METHOD_OPTIONS,
      DB_ENUM_VALUES,
      FORM_ITEMS,
      FORM_RULES,
      SCALE_METHOD_OPTIONS,
      STATE_OPTIONS,
      SURVEY_TEMPLATE_VARIABLES,
      TEMPLATE_VARIABLE_COLORS,
      addStep,
      descriptionEl,
      formDisabled,
      formRef,
      formState,
      handleCancel,
      handleClickTemplateVariable,
      handleSubmit,
      introductionEl,
      modalTitle,
      onBlur,
      onFocus,
      removeStep,
      visible
    }
  }
})
</script>

<style lang="stylus">

.sz-survey-form-modal
  .ant-modal-body
    max-height calc(100vh - 200px - 55px - 53px) // minus ((modal top + bottom) + header + footer)
    overflow auto

  .ant-row
    margin-bottom: 5px
  .sz-survey-steps
    .ant-input-number
      margin-top: 4px
    .ant-input
      height 32px
    .ant-space
      display flex
      flex-direction row
    .ant-form-item-children
      display: block
  .form-header
    text-align left
    font-weight 700
    margin 10px 0 10px 0

  .ant-space
    width 100% !important
</style>
