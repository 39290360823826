<template>
  <div class="sz-var-context">
    <div v-if="showParents" class="sz-var-parents">
      <div v-if="parents.length" class="label">{{ VariableRelation.PARENT }}</div>
      <VariableSymbol
        v-for="(parent, index) in parents || []"
        :key="parent.id"
        :variable="parent"
        :index="index"
        :relation="VariableRelation.PARENT"
      />
    </div>
    <div class="sz-var-selected">
      <div class="label">{{ VariableRelation.SELECTED }}</div>
      <VariableSymbol :variable="variable" :index="0" :relation="VariableRelation.SELECTED" />
    </div>
    <div v-if="showChildren" class="sz-var-children">
      <div v-if="children.length" class="label">{{ VariableRelation.CHILD }}</div>
      <VariableSymbol
        v-for="(child, index) in children || []"
        :key="child.id"
        :variable="child"
        :index="index"
        :relation="VariableRelation.CHILD"
      />
    </div>
  </div>
  <div v-if="currentSurvey && currentSurvey.ext && !isAce" class="sz-var-context-intro">
    <div v-html="uiIntroductionRender"></div>
    <div v-html="uiDescriptionRender"></div>
  </div>
</template>

<script lang="ts">
import { isEmpty } from 'lodash-es'
import { computed, defineComponent, PropType } from 'vue'

import VariableSymbol from '@/components/symbols/VariableSymbol.vue'
import { SURVEY_TEMPLATE_VARIABLES } from '@/constants/components'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { Network } from '@/libs/bayes/Network'
import { State } from '@/libs/bayes/State'
import { Variable } from '@/libs/bayes/Variable'
import { SurveySchema } from '@/types'

export default defineComponent({
  components: {
    VariableSymbol
  },
  props: {
    network: { type: Network, required: true },
    variable: { type: Variable, required: true },
    state: { type: State, required: true },
    showParents: { type: Boolean, required: true },
    showChildren: { type: Boolean, required: false },
    currentSurvey: { type: Object as PropType<SurveySchema>, required: true },
    isCain: { type: Boolean, default: false },
    isAce: { type: Boolean, default: false },
    isMarginal: { type: Boolean, default: false }
  },
  setup(props) {
    const parents = computed(
      () => (props.network && props.network.parents[props.variable.id]) || []
    )
    const children = computed(
      () => (props.network && props.network.children[props.variable.id]) || []
    )

    /**
     * Replace variables into HTML DOM in survey template
     * @param text
     */
    const replaceVariableIntoComponent = (text: string) => {
      const parentRegex = new RegExp(`{{\\s*${SURVEY_TEMPLATE_VARIABLES.PARENT}\\s*}}`, 'g')
      const selectedVariableRegex = new RegExp(
        `{{\\s*${SURVEY_TEMPLATE_VARIABLES.SELECTED}\\s*}}`,
        'g'
      )
      const selectedStateRegex = new RegExp(
        `{{\\s*${SURVEY_TEMPLATE_VARIABLES.SELECTED_STATE}\\s*}}`,
        'g'
      )

      const parentComponent = `${(parents?.value || [])
        .map((p: Variable) => `<span class="sz-var-symbol-inline">${p.name}</span>`)
        .join(', ')}`

      const selectedVariableComponent = `<span class="sz-var-symbol-inline selected">${props.variable.name}</span>`
      const selectedStateComponent = `<span class="sz-var-selected-state-${props.state.polarity}">${props.state.name}</span>`

      return `<p>${text
        .replace(parentRegex, parentComponent)
        .replace(selectedVariableRegex, selectedVariableComponent)
        .replace(selectedStateRegex, selectedStateComponent)}</p>`
    }

    /**
     * Render ui introduction text
     */
    const uiIntroductionRender = computed(() => {
      const survey = props.currentSurvey as SurveySchema
      if (survey && !isEmpty(survey.ext)) {
        let introduction = survey.ext.ui_introduction
        if (props.isAce) {
          introduction = survey.ext.ace_introduction || introduction
        } else if (props.isMarginal) {
          introduction = survey.ext.marginal_introduction || introduction
        }
        return replaceVariableIntoComponent(introduction)
      }
      return null
    })

    /**
     * Render ui description text
     */
    const uiDescriptionRender = computed(() => {
      const survey = props.currentSurvey as SurveySchema
      if (survey && !isEmpty(survey.ext)) {
        let description = survey.ext.ui_description
        if (props.isAce) {
          description = survey.ext.ace_description || description
        } else if (props.isMarginal) {
          description = survey.ext.marginal_description || description
        }

        return replaceVariableIntoComponent(description)
      }
      return null
    })

    return {
      parents,
      children,
      uiIntroductionRender,
      uiDescriptionRender,
      VariableRelation
    }
  }
})
</script>

<style lang="stylus">
@import '../styles/entities.styl';
.sz-var-context
  margin-bottom 7px
  max-width: calc(100% - 200px);
  .label
    position absolute
    right calc(100% + 10px)

.sz-var-context-intro
  max-width 800px
  padding 5px 20px
  p
    font-size 16px
    font-family 'Petrona'
    text-align left

.sz-var-parents,
.sz-var-selected,
.sz-var-children
  position relative
  display flex
  flex-direction row
  flex-wrap wrap
  justify-content flex-start
  align-items flex-start
  align-content flex-end
  .sz-var-symbol
    width 180px
    margin-bottom 4px
    overflow hidden
</style>
