import { AxiosError } from 'axios'

import axios from '@/axios'
import { ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'
import { JobTask } from '@/types/database/job'

const createJobTask = async (
  workspaceId: string,
  jobId: string,
  payload: JobTask
): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.JOB_TASKS({ workspaceId, jobId }), payload))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const getJobTasks = async (workspaceId: string, jobId: string): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.JOB_ALL_TASKS({ workspaceId, jobId }))) || []
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const getJobTask = async (workspaceId: string, jobId: string, jobTaskId: string): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.JOB_TASK({ workspaceId, jobId, jobTaskId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const updateJobTask = async (
  workspaceId: string,
  jobId: string,
  jobTaskId: string,
  payload: JobTask
): Promise<any> => {
  try {
    return extractResponse(
      await axios.put(ENDPOINTS.JOB_TASK({ workspaceId, jobId, jobTaskId }), payload)
    )
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const executeJobTask = async (
  workspaceId: string,
  jobId: string,
  jobTaskId: string
): Promise<any> => {
  try {
    return extractResponse(
      await axios.post(ENDPOINTS.JOB_TASK_EXECUTE({ workspaceId, jobId, jobTaskId }), {})
    )
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const exportJobTaskStochastic = async (jobTaskId: string): Promise<any> => {
  try {
    return axios.get(ENDPOINTS.STOCHASTIC_EXPORT({ jobTaskId }), { responseType: 'blob' })
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const abortJobTask = async (
  workspaceId: string,
  jobId: string,
  jobTaskId: string
): Promise<any> => {
  try {
    return extractResponse(
      await axios.post(ENDPOINTS.JOB_TASK_ABORT({ workspaceId, jobId, jobTaskId }), {})
    )
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const deleteJobTask = async (
  workspaceId: string,
  jobId: string,
  jobTaskId: string
): Promise<any> => {
  try {
    return extractResponse(
      await axios.delete(ENDPOINTS.JOB_TASK({ workspaceId, jobId, jobTaskId }))
    )
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

export {
  exportJobTaskStochastic,
  abortJobTask,
  createJobTask,
  getJobTasks,
  getJobTask,
  updateJobTask,
  deleteJobTask,
  executeJobTask
}
