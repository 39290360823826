import { Module } from 'vuex'

import { RootState } from '@/store/types'
import type { AllocationState } from '@/store/types/states/allocation'

import { actions } from './actions'
import { getters } from './getter'
import { mutations } from './mutations'
import { state } from './state'

const store: Module<AllocationState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

export { store }
