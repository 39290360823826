<template>
  <div class="sza-workspace-item card-container" :style="cardStyle">
    <div class="header">
      <a-space size="small">
        <a-tooltip placement="top">
          <template #title>
            <span>Edit</span>
          </template>
          <EditTwoTone @click="handleClickEditButton" />
        </a-tooltip>
        <a-popconfirm
          title="Are you sure delete this workspace?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteWorkspace"
        >
          <a-tooltip placement="top">
            <template #title>
              <span>Delete</span>
            </template>
            <DeleteTwoTone two-tone-color="#eb2f96" />
          </a-tooltip>
        </a-popconfirm>
      </a-space>
    </div>
    <div class="body-container">
      <div class="title" :title="workspace.name">{{ workspace.name }}</div>
      <div class="updated">Last updated {{ modified }}</div>
      <div class="description">{{ workspace.description }}</div>
    </div>
    <div class="action-container">
      <!-- <a-tooltip placement="top">
        <template #title>
          <span>Networks</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ANALYSIS_WORKSPACE, params: { workspaceId } }">
          <DeploymentUnitOutlined />
        </router-link>
      </a-tooltip> -->
      <a-tooltip placement="top">
        <template #title>
          <span>What-if Analysis</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ANALYSIS_WHATIF, params: { workspaceId } }">
          <Icon size="24">
            <ZoomQuestion />
          </Icon>
        </router-link>
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>
          <span>Sensitivity Analysis</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ANALYSIS_SENSITIVITY, params: { workspaceId } }">
          <Icon size="24">
            <ChartDots />
          </Icon>
        </router-link>
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>
          <span>Option Design</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ANALYSIS_OPTION_DESIGN, params: { workspaceId } }">
          <Icon size="24">
            <RelationManyToMany />
          </Icon>
        </router-link>
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>
          <span>Hybrid</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ANALYSIS_HYBRID, params: { workspaceId } }">
          <IconCirclesRelation :size="24" />
        </router-link>
      </a-tooltip>
      <!-- <a-tooltip v-show="false" placement="top">
        <template #title>
          <span>Cause Effect</span>
        </template>
        <router-link :to="{ name: ROUTE_NAME.ANALYSIS_CAUSEEFFECT, params: { workspaceId } }">
          <Icon size="24">
            <ArrowRightTail />
          </Icon>
        </router-link>
      </a-tooltip> -->
    </div>
  </div>
</template>

<script lang="ts">
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons-vue'
import { IconCirclesRelation } from '@tabler/icons-vue'
import { ChartDots, RelationManyToMany, ZoomQuestion } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed, defineComponent, toRefs } from 'vue'

import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { vuexActions } from '@/store/util'
dayjs.extend(relativeTime)
import { message } from 'ant-design-vue'

import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { gradientMaker } from '@/libs/utils'

export const EVENTS = {
  CLICK_CARD: EMIT_EVENTS.WORKSPACE.CLICK_WORKSPACE_CARD,
  DELETE_WORKSPACE: 'DELETE_WORKSPACE'
}

export default defineComponent({
  components: {
    RelationManyToMany,
    ChartDots,
    DeleteTwoTone,
    EditTwoTone,
    Icon,
    ZoomQuestion,
    IconCirclesRelation
  },
  props: {
    workspace: {
      type: Object,
      required: true
    }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const store = useStore()
    const { workspace } = toRefs(props)
    const workspaceId = workspace.value?.id
    const created = computed(() => dayjs(workspace.value.createdDate).fromNow())
    const modified = computed(() => dayjs(workspace.value.lastModifiedDate).fromNow())

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(props.workspace?.name || 'UNTITLED')
    }))

    /**
     * Delete workspace
     */
    const deleteWorkspace = async () => {
      await store.dispatch(
        vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.DELETE_WORKSPACE),
        workspaceId
      )
      emit(EVENTS.DELETE_WORKSPACE)
      message.success(MESSAGE.WORKSPACE_DELETE_SUCCESS)
    }

    /**
     * Click card
     **/
    const handleClickEditButton = () => {
      emit(EVENTS.CLICK_CARD, props.workspace)
    }

    return {
      ROUTE_NAME,
      created,
      modified,
      deleteWorkspace,
      handleClickEditButton,
      workspaceId,
      cardStyle
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/base.styl"
@import "../../../styles/commons.styl"

.sza-workspace-item
  &.card-container
    @extend .sz-window
    position relative
    background-color white
    padding 5px 15px 15px 15px
    margin 0 15px 15px 0
    display flex
    flex-direction column
    align-items stretch

  .header
    flex 0 0 auto
    height 32px
    align-items flex-start
    display flex
    flex-direction row
    > .ant-space
      flex: 0 0 auto
      display flex
      flex-direction row
      justify-content flex-end
      width 100%

  .body-container
    width 100%
    overflow hidden
    min-height 1px
    flex 1 1 auto
    display flex
    flex-direction column
    > *
      min-height 1px

  .title
    @extend .truncate
    font-size 18px
    font-weight bold
    flex 0 0 auto

  .description
    overflow auto
    padding 3px 0 4px 0
    font-size 12px
    line-height 15px
    flex 1 1 auto
    color grey

  .updated
    flex 0 0 auto
    margin 4px 0 0 0
    font-size 12px
    color #a0a0a0

  .action-container
    flex 0 0 auto
    display flex
    flex-direction row
    justify-content space-between
    align-items flex-end
    height 40px

    .anticon
      font-size 24px
</style>
