import { computed, Ref } from 'vue'

import { DB_ENUM_VALUES } from '@/constants/database'
import { CPT } from '@/libs/bayes'

const {
  SURVEY: { COLLECTION_METHOD }
} = DB_ENUM_VALUES

export default function useMethods(cpt: Ref<CPT | undefined>): any {
  const isCain = computed(() => cpt.value?.method === COLLECTION_METHOD.CAIN)
  const isAceOriginal = computed(() => cpt.value?.method === COLLECTION_METHOD.ACE)
  // or WEIGHTED for the old one
  const isAceDstSimple = computed(() => cpt.value?.method === COLLECTION_METHOD.ACE_DST_SIMPLE)
  const isAceDstFull = computed(() => cpt.value?.method === COLLECTION_METHOD.ACE_DST)
  const isAceDst = computed(
    () =>
      cpt.value?.method === COLLECTION_METHOD.ACE_DST ||
      cpt.value?.method === COLLECTION_METHOD.ACE_DST_SIMPLE
  )
  const isMarginal = computed(() => cpt.value?.isMarginal)

  return {
    isCain,
    isAceOriginal,
    isAceDst,
    isAceDstSimple,
    isAceDstFull,
    isMarginal
  }
}
