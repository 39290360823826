import { Module } from 'vuex'

import type { RootState } from '@/store/types'
import type { NetworkState } from '@/store/types/states/network'

import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

const store: Module<NetworkState, RootState> = {
  state,
  // getters,
  mutations,
  actions,
  namespaced: true
}

export { store }
