import { Module } from 'vuex'

import { RootState } from '@/store/types'
import { SurveyState } from '@/store/types/states/survey'

import { actions } from './actions'
import { getters } from './getter'
import { mutations } from './mutations'
import { state } from './state'

export const store: Module<SurveyState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
