import { rgb } from 'd3'
import * as d3 from 'd3-scale-chromatic'
import { indexBy, prop } from 'ramda'

const d3c = d3 as Record<string, any> // force typing
type NameDict = Record<string, string>

export enum SEQUENTIAL_SINGLE_HUE {
  Blues = 'Blues',
  Greens = 'Greens',
  Greys = 'Greys',
  Oranges = 'Oranges',
  Purples = 'Purples',
  Reds = 'Reds'
}

export const SEQUENTIAL_MULTI_HUE: NameDict = {
  BuGn: 'BuGn',
  BuPu: 'BuPu',
  GnBu: 'GnBu',
  OrRd: 'OrRd',
  PuBu: 'PuBu',
  PuBuGn: 'PuBuGn',
  PuRd: 'PuRd',
  RdPu: 'RdPu',
  YlGn: 'YlGn',
  YlGnBu: 'YlGnBu',
  YlOrBr: 'YlOrBr',
  YlOrRd: 'YlOrRd',
  Cividis: 'Cividis',
  Viridis: 'Viridis',
  Inferno: 'Inferno',
  Magma: 'Magma',
  Plasma: 'Plasma',
  Warm: 'Warm',
  Cool: 'Warm'
}

export const DIVERGING: NameDict = {
  BrBG: 'BrBG',
  PiYG: 'PiYG',
  PRGn: 'PRGn',
  PuOr: 'PuOr',
  RdBu: 'RdBu',
  RdGy: 'RdGy',
  RdYlBu: 'RdYlBu',
  RdYlGn: 'RdYlGn',
  Spectral: 'Spectral'
}

export const CATEGORICAL: NameDict = {
  Accent: 'Accent',
  Dark2: 'Dark2',
  Paired: 'Paired',
  Pastel1: 'Pastel1',
  Pastel2: 'Pastel2',
  Set1: 'Set1',
  Set2: 'Set2',
  Set3: 'Set3',
  Tableau10: 'Tableau10',
  Category10: 'Category10'
}

export const CYCLICAL: NameDict = {
  Rainbow: 'Rainbow',
  Sinebow: 'Sinebow'
}

export type ColorSchemeItem = {
  boundary: number
  allowEqual?: boolean
  color: string
}

export type ColorScheme = {
  name: string
  numColors?: number
  interpolator?: any
  discrete: boolean
  items?: ColorSchemeItem[]
}

export const DEFAULT_PALETTE = {
  discrete: true,
  items: [
    {
      boundary: 0.15,
      allowEqual: false,
      color: '#FA5457'
    },
    {
      boundary: 0.35,
      allowEqual: false,
      color: '#FA8924'
    },
    {
      boundary: 0.65,
      allowEqual: false,
      color: '#F5D51E'
    },
    {
      boundary: 0.85,
      allowEqual: false,
      color: '#00B4BC'
    },
    {
      boundary: 1.0,
      allowEqual: true,
      color: '#5FA45A'
    }
  ]
}

export const genPalette = (name: string, steps = -1, discrete?: boolean): any => {
  const isCategorical = !!CATEGORICAL[name]
  discrete = isCategorical || !!discrete
  let interpolator
  let items: ColorSchemeItem[] = []
  const scheme = d3c?.[`scheme${name}`]
  let colors = []
  if (isCategorical) {
    colors = scheme
    // -1 for picking the max palette number
    if (steps === -1) {
      steps = scheme?.length || 1
    }
    for (let i = 0; i < steps; ++i) {
      items.push({
        boundary: (i + 1) / steps,
        allowEqual: true,
        color: colors[i % scheme.length]
      })
    }
  } else {
    interpolator = d3c[`interpolate${name}`]
    if (steps === -1) {
      steps = 100 // default steps for discretized continuous
    }
    if (scheme?.[steps]) {
      colors = scheme[steps]
      items = colors.map((color: string, index: number) => ({
        boundary: (index + 1) / colors.length,
        allowEqual: true,
        color: color
      }))
    } else {
      colors = []
      for (let i = 0; i < steps; ++i) {
        const color = rgb(interpolator(i / (steps - 1))).formatHex()
        colors.push(color)
        items.push({
          boundary: (i + 1) / steps,
          allowEqual: true,
          color: color
        })
      }
    }
  }
  const palette: ColorScheme = {
    name,
    numColors: items.length,
    interpolator,
    discrete,
    items
  }
  return {
    colors,
    palette
  }
}

export const CONTINUOUS_PALETTES = Object.keys(SEQUENTIAL_SINGLE_HUE)
  .concat(Object.keys(SEQUENTIAL_MULTI_HUE))
  .concat(Object.keys(DIVERGING))
  .map((name) => genPalette(name).palette)

export const CATEGORICAL_PALETTES = Object.keys(CATEGORICAL).map((name) => genPalette(name).palette)
export const CATEGORICAL_PALETTE_MAP = indexBy(prop('name'), CATEGORICAL_PALETTES)

export const pickColor = (palette: ColorScheme, index: number): any => {
  if (palette.discrete && palette.items) {
    return palette.items[index % (palette?.numColors || 1)].color
  }
}

export const mapColor = (palette: ColorScheme, value: number): any => {
  value = value % 1 // map to 0 - 1
  if (palette.interpolator) {
    return palette.interpolator(value)
  }
  if (palette.items) {
    for (let i = 0; i < palette.items.length; ++i) {
      const { boundary, allowEqual, color } = palette.items[i]
      if ((allowEqual && value <= boundary) || (!allowEqual && value < boundary)) {
        return color
      }
    }
  }
}
