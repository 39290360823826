<template>
  <!-- <div class="sz-workspace-menu">
    <div class="sz-vert-item">Properties</div>
    <div class="sz-vert-item">Survey</div>
    <div class="sz-vert-item">Variables</div>
  </div> -->
  <SurveyDialog :is-visible="isShowEditModal" @handleSetIsVisible="handleShowEditModal" />
  <div class="sz-survey-page sz-wrapper">
    <div class="sz-command-bar">
      <a-space align="center" :size="0">
        <a-button type="link" @click="handleShowEditModal">
          <template #icon><FormOutlined /></template>
          Edit Survey
        </a-button>
        <a-popconfirm ok-text="Delete and publish" cancel-text="Cancel" @confirm="persistCPT">
          <template #title
            >Publish survey will delete <strong>old CPTs and responses</strong>, please
            confirm</template
          >
          <a-button type="link">
            <template #icon><ExportOutlined /></template>
            Publish Survey
          </a-button>
        </a-popconfirm>
        <!-- <a-button type="link" @click="getLink">
          <template #icon><OrderedListOutlined /></template>
          Open Survey Questionnaire
        </a-button> -->
        <router-link :to="{ name: ROUTE_NAME.SURVEY_STATUSES, params: { surveyId, workspaceId } }">
          <a-button type="link">
            <template #icon><PieChartOutlined /></template>
            Survey Status
          </a-button>
        </router-link>
        <router-link :to="{ name: ROUTE_NAME.SURVEY_ANALYTICS, params: { surveyId, workspaceId } }">
          <a-button type="link">
            <template #icon><RadarChartOutlined /></template>
            Analytics
          </a-button>
        </router-link>
        <router-link
          :to="{ name: ROUTE_NAME.SURVEY_STOCHASTICS, params: { surveyId, workspaceId } }"
        >
          <a-button type="link">
            <template #icon><RadarChartOutlined /></template>
            Stochastics
          </a-button>
        </router-link>
        <a-dropdown placement="bottomCenter" :trigger="['click']">
          <a-button type="link">
            <template #icon><CloudOutlined /></template>
            Export Survey
          </a-button>
          <template #overlay>
            <a-menu @click="({ key }) => exportCPTResponse(key)">
              <a-menu-item :key="SurveyExportFormatEnum.CSV"> CSV format </a-menu-item>
              <a-menu-item :key="SurveyExportFormatEnum.JSON"> JSON format </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-space>
    </div>
    <div class="sz-config">
      <div v-if="currentSurvey" class="sz-survey-summary">
        <div>
          <span>Survey Name</span>
          <span>{{ currentSurvey.name }}</span>
        </div>
        <div>
          <span>Status</span>
          <span>{{ currentSurvey.state }}</span>
        </div>
        <div>
          <span>Collection</span>
          <span>{{ currentSurvey.collectionMethod }}</span>
        </div>
        <div>
          <span>Scale</span>
          <span>{{ currentSurvey.scale && currentSurvey.scale.method }}</span>
        </div>
      </div>
    </div>
    <splitpanes class="default-theme">
      <pane min-size="10" size="30">
        <div class="sz-variables-container sz-survey-variables" :style="{ zIndex: 900 }">
          <VariableTable
            v-if="network && network.variables"
            :selected-variable="selectedVariable"
            :network="network"
            :current-survey="currentSurvey"
            :config="{
              allocationVisible: false,
              dependencyVisible: false,
              indexVisible: true,
              statesVisible: false,
              collectionMethodVisible: true,
              variableNameVisible: true
            }"
            :show-aux="false"
            v-on="{
              [EMIT_EVENTS.VARIABLE.SELECT]: selectVariable,
              [EMIT_EVENTS.SURVEY.VARIABLE_TOGGLE_CAIN]: toggleCain
            }"
          />
        </div>
      </pane>
      <pane>
        <div class="sz-cpt-container" :style="{ zIndex: 1000 }">
          <CainTable
            v-if="network && cpt && isCain"
            :cpt="cpt"
            :network="network"
            :current-survey="currentSurvey"
            :verbose-state="verboseState"
            :discrete="hasDiscreteScale"
            :selected-variable="selectedVariable"
          />
          <AceTable
            v-if="network && cpt && isAceDst"
            :network="network"
            :current-survey="currentSurvey"
            :cpt="cpt"
            :selected-variable="selectedVariable"
            :editable="true"
            :is-full="isAceDstFull"
          />
          <MarginalTable
            v-if="network && cpt && cpt.isMarginal"
            :network="network"
            :current-survey="currentSurvey"
            :cpt="cpt"
            :selected-variable="selectedVariable"
            :editable="true"
            :no-save="true"
          />
        </div>
      </pane>
    </splitpanes>
  </div>
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  CloudOutlined,
  ExportOutlined,
  FormOutlined,
  PieChartOutlined,
  RadarChartOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { isArray } from 'lodash'
import { isEmpty } from 'lodash-es'
import { clone } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

import useMethods from '@/components/composables/methods'
import useSurvey from '@/components/composables/survey'
import AceTable from '@/components/method-ace/AceTable.vue'
import CainTable from '@/components/method-cain/CainTable.vue'
import MarginalTable from '@/components/method-marginal/MarginalTable.vue'
import SurveyDialog from '@/components/survey/SurveyDialog.vue'
import VariableTable from '@/components/variable/VariableTable.vue'
import { DB_ENUM_VALUES } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { CPTMethod } from '@/libs/bayes/enums/CPTMethod'
import { Variable } from '@/libs/bayes/Variable'
import { publishSurvey } from '@/services/composition/survey'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions } from '@/store/util'
import { SurveyExportFormatEnum, User } from '@/types'

export default defineComponent({
  components: {
    Pane,
    Splitpanes,
    AceTable,
    CloudOutlined,
    PieChartOutlined,
    ExportOutlined,
    FormOutlined,
    MarginalTable,
    VariableTable,
    CainTable,
    RadarChartOutlined,
    SurveyDialog
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const routerParams = route.params
    const { workspaceId, surveyId } = routerParams
    const verboseState = ref(false)
    const panels = reactive({
      variables: true,
      survey: true,
      cpt: true
    })

    const userListContent = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)

    const {
      cpt,
      cptSet,
      currentNetwork,
      currentSurvey,
      network,
      hasDiscreteScale,
      selectedVariable,
      selectVariable
    } = useSurvey(store)

    const {
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal
    } = useMethods(cpt)

    const isShowEditModal = ref(false)

    /**
     * Export CPT response for current survey
     * @param fileType - output file type
     **/
    const exportCPTResponse = (fileType: SurveyExportFormatEnum) => {
      if (surveyId && typeof surveyId === 'string' && !isEmpty(network.value.variables)) {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.EXPORT_SURVEY), {
          surveyId,
          format: fileType,
          userIds: userListContent.value.map((each: User) => each.id) as Array<User['id']>,
          variableIds: network.value.variables.map((each: Variable) => each.id)
        })
      }
    }

    /**
     * Handle display create user model
     **/
    const handleShowEditModal = () => {
      isShowEditModal.value = !isShowEditModal.value
    }

    // http://localhost:3000/#/workspaces/608798785a1e581fa9eace8c/surveys/6095ed100d4da47b36cdceda/questionnaire
    const getLink = () => {
      window.open(`/#/workspaces/${workspaceId}/surveys/${surveyId}/questionnaire`)
    }

    const persistCPT = async () => {
      try {
        if (currentNetwork.value && cptSet.value && !isArray(surveyId) && !isArray(workspaceId)) {
          await publishSurvey(currentNetwork.value, cptSet.value, surveyId, workspaceId)
          message.success(MESSAGE.PERSIST_CPT_SUCCESS)
        }
      } catch (err) {
        message.error(MESSAGE.PERSIST_CPT_FAIL)
        throw err
      }
    }

    const toggleCain = async ({ variable, method }: { variable: Variable; method: CPTMethod }) => {
      if (!currentSurvey.value) {
        return
      }
      const surveyData = clone(currentSurvey.value)
      surveyData.ext.collectionMethodMap = surveyData.ext.collectionMethodMap || {}
      surveyData.ext.collectionMethodMap[variable.id] = method
      const surveyId = currentSurvey.value.id
      await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.UPDATE_SURVEY), {
        id: surveyId,
        survey: {
          ...surveyData,
          workspaceId,
          surveyId
        }
      })
      // updateMethodForVariable(variable: Variable, method: CPTMethod)
      message.success(MESSAGE.SURVEY_CONFIG_UPDATE_SUCCESS)
    }

    return {
      DB_ENUM_VALUES,
      EMIT_EVENTS,
      persistCPT,
      handleShowEditModal,
      isShowEditModal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isAceOriginal,
      isCain,
      isMarginal,
      cpt,
      cptSet,
      hasDiscreteScale,
      currentSurvey,
      selectedVariable,
      getLink,
      surveyId,
      workspaceId,
      toggleCain,
      selectVariable,
      exportCPTResponse,
      verboseState,
      network,
      panels,
      SurveyExportFormatEnum,
      ROUTE_NAME
    }
  }
})
</script>

<style lang="stylus">
@import '../styles/Page.styl';

.sz-survey-summary
  display flex
  flex-direction row
  > div
    > span
      display inline-block
    > span:first-child
      text-align right
      padding-right 5px
    > span:nth-child(2)
      font-weight 700
      width: calc(100% - 100px)
      padding-right 15px

.sz-config
  overflow: auto
  display: flex
  flex-direction: row
  padding: 5px 0 6px 12px
  background-color: white

.sz-survey-page
  display: flex
  flex-direction: column

  .sz-survey-variables
    position absolute
    top 0
    right 0
    left 0
    bottom 0
  // .sz-survey-variables
  //   ::-webkit-scrollbar
  //     width 0
</style>
