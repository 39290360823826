import { render } from "./Continuous.vue?vue&type=template&id=3796b0a4"
import script from "./Continuous.vue?vue&type=script&lang=ts"
export * from "./Continuous.vue?vue&type=script&lang=ts"

import "./Continuous.vue?vue&type=style&index=0&id=3796b0a4&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3796b0a4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3796b0a4', script)) {
    api.reload('3796b0a4', script)
  }
  
  module.hot.accept("./Continuous.vue?vue&type=template&id=3796b0a4", () => {
    api.rerender('3796b0a4', render)
  })

}

script.__file = "src/components/input/Continuous.vue"

export default script