import { createLogger, createStore } from 'vuex'

import { store as allocation } from '@/store/modules/allocation/index'
import { store as auth } from '@/store/modules/auth/index'
import { store as builder } from '@/store/modules/builder'
import { store as network } from '@/store/modules/network/index'
import { store as questionnaire } from '@/store/modules/questionnaire/index'
import { store as survey } from '@/store/modules/survey/index'
import { store as surveyStatus } from '@/store/modules/surveyStatus/index'
import { store as surveySummary } from '@/store/modules/surveySummary/index'
import { store as user } from '@/store/modules/user/index'
import { store as workspace } from '@/store/modules/workspace/index'
import type { Store } from '@/store/types'

/**
 * Vuex store
 */
const store = createStore({
  modules: {
    allocation,
    auth,
    builder,
    network,
    questionnaire,
    survey,
    surveySummary,
    surveyStatus,
    user,
    workspace
  },
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
})

/**
 * In order to dispatch actions, please import this function in components
 * @example const store = useStore(); store.dispatch(action)
 */
function useStore(): Store {
  return store as Store
}

export { Store, store, useStore }
