
import 'splitpanes/dist/splitpanes.css'

import { LayoutGridAdd, Palette, Run } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'
import { useRoute } from 'vue-router'

import useAnalysis from '@/components/analysis/composable/base-deprecated'
import { CATEGORICAL_PALETTE_MAP, CATEGORICAL_PALETTES } from '@/components/common/color-scheme'
import PalettePicker from '@/components/common/PalettePicker.vue'
import Import from '@/components/network/XdslImport.vue'
import { EMIT_EVENTS } from '@/constants/emits'
import { ROUTE_NAME } from '@/constants/router'
dayjs.extend(relativeTime)
import { ColorSchemeItem } from '@/components/common/color-scheme'
import { Network } from '@/libs/bayes'
import { gradientMaker } from '@/libs/utils'
import { getNetworks } from '@/services/api/network'
import { getWorkspace } from '@/services/api/workspace'
import { useStore } from '@/store'
import { NetworkSchema } from '@/types'

export default defineComponent({
  components: {
    LayoutGridAdd,
    Run,
    Icon,
    Import,
    Pane,
    Splitpanes,
    Palette,
    PalettePicker
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const paletteVisible: Ref<boolean> = ref(false)
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const inputs: Ref<number[][]> = ref([])
    const outputs: Ref<number[][]> = ref([])
    const { run, data } = useRequest(getWorkspace, {
      manual: true
    })
    const taskPaletteName: Ref<string> = ref('')
    const taskPaletteColors: Ref<string[]> = ref([])

    const { run: runNetworks, data: dataNetworks } = useRequest(getNetworks, {
      manual: true
    })

    const rawNetworks = computed(() => dataNetworks.value?.content || [])
    const networks = computed(() =>
      rawNetworks.value?.map((rawNetwork: NetworkSchema) => new Network(rawNetwork))
    )
    const workspace = computed(() => data.value || {})
    const created = computed(() => dayjs(workspace.value?.createdDate).fromNow())
    const modified = computed(() => dayjs(workspace.value?.lastModifiedDate).fromNow())

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const {
      chartData,
      chartNodeIds,
      whatIfRows,
      selections,
      updateSelections,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      initTasks,
      addTask,
      removeTask,
      updateInputOutputNodes,
      updateWhatIfRows,
      executeAnalysis,
      updateRowsWithUtilityVector
    } = useAnalysis(workspaceId, networks, store, updateTable)

    const networkColumns = computed(() => {
      return [
        {
          title: 'Network name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Nodes count',
          dataIndex: 'nodes.length',
          key: 'nodes'
        }
      ]
    })

    const networkRows = computed(
      () =>
        networks.value?.map((network: any) => ({
          key: network.id,
          nodes: network.nodes,
          name: network.name
        })) || []
    )

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(workspace.value?.name || 'UNTITLED')
    }))

    const handleLoadNetwork = () => {
      runNetworks(workspaceId as string)
    }

    const calculate = () => {
      executeAnalysis()
      updateTable()
    }

    const toggleInput = () => {
      updateInputOutputNodes()
      // updateWhatIfRows()
    }
    const toggleOutput = () => {
      updateInputOutputNodes()
      // updateWhatIfRows()
    }

    const init = () => {
      initTasks()
      updateWhatIfRows()
    }

    const onUtilityUpdated = (key: string) => {
      updateRowsWithUtilityVector(key)
    }

    watch(networks, () => {
      updateSelections()
    })

    watch(selections, () => {
      updateWhatIfRows()
    })

    onMounted(() => {
      run(workspaceId as string)
      runNetworks(workspaceId as string)
    })

    const showPalette = () => {
      paletteVisible.value = !paletteVisible.value
    }

    const selectTaskPalette = () => {
      taskPaletteColors.value = CATEGORICAL_PALETTE_MAP[taskPaletteName.value]?.items?.map(
        ({ color }: ColorSchemeItem) => color
      )
      paletteVisible.value = false
    }

    const onDuplicateTask = (taskId: string, taskIndex: number) => {
      addTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    const onRemoveTask = (taskId: string, taskIndex: number) => {
      removeTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    return {
      onDuplicateTask,
      onRemoveTask,
      showPalette,
      tableKey,
      initTasks,
      toggleInput,
      toggleOutput,
      inputs,
      outputs,
      networkColumns,
      networkRows,
      selections,
      EMIT_EVENTS,
      ROUTE_NAME,
      created,
      modified,
      workspace,
      workspaceId,
      networks,
      cardStyle,
      whatIfRows,
      tasks,
      handleLoadNetwork,
      calculate,
      onUtilityUpdated,
      table,
      init,
      inputNodeKeys,
      outputNodeKeys,
      chartNodeIds,
      chartData,
      paletteVisible,
      taskPaletteName,
      taskPaletteColors,
      CATEGORICAL_PALETTES,
      CATEGORICAL_PALETTE_MAP,
      selectTaskPalette
    }
  }
})
