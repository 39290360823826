/**
 * Mutation types
 */
export enum UserMutationEnum {
  GET_USERS = 'GET_USERS',
  GET_USERS_FULFILLED = 'GET_USERS_FULFILLED',
  GET_USERS_REJECTED = 'GET_USERS_REJECTED',

  GET_USER = 'GET_USER',
  GET_USER_FULFILLED = 'GET_USER_FULFILLED',
  GET_USER_REJECTED = 'GET_USER_REJECTED',

  CREATE_USER = 'CREATE_USER',
  CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED',
  CREATE_USER_REJECTED = 'CREATE_USER_REJECTED',

  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED',
  UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED',

  DELETE_USER = 'DELETE_USER',
  DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED',
  DELETE_USER_REJECTED = 'DELETE_USER_REJECTED'
}
