/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from 'ant-design-vue'
import { keyBy, max, min } from 'lodash-es'
import { isNil } from 'ramda'
import { Ref, ref } from 'vue'

import {
  AnalysisTask,
  ChartData,
  findMaxProbIndex,
  findStateIndex,
  getInputOuputNodes,
  getVariable,
  InputNode,
  NodeSelection,
  OutputNode
} from '@/components/analysis/libs/common'
import { Network, State } from '@/libs/bayes'
import { complexRow, numberCell, simpleRow } from '@/libs/common'
import { objectId } from '@/libs/utils'
import { abortJobTask, executeJobTask, getJobTask } from '@/services/api/jobTask'
import { Job, JobInputNode, JobOutputNode } from '@/types/database/job'

export const inputNodeMapper = ({
  key,
  state,
  value,
  variation,
  variations
}: InputNode): JobInputNode => {
  return {
    key,
    state,
    value,
    variation,
    variations
  }
}

export const outputNodeMapper = ({ key, constraint, utilityVector }: OutputNode): JobOutputNode => {
  return {
    key,
    constraint,
    utilityVector: utilityVector.map((x) => x)
  }
}

export default function useOptionDesign(
  workspaceId: string,
  networks: Ref<Network[]>,
  networkMap: Ref<Record<string, Network>>,
  nodeSelections: Ref<NodeSelection[]>,
  nodeSelectionMap: Ref<Record<string, NodeSelection>>,
  inputNodeKeys: Ref<string[]>,
  outputNodeKeys: Ref<string[]>,
  tasks: Ref<AnalysisTask[]>,
  taskMap: Ref<Record<string, AnalysisTask>>,
  refreshCallback: () => void,
  successCallback: () => void = () => undefined,
  selectedNetwork: Ref<Network>,
  currentJob: Ref<Job>
): any {
  const chartNodeIds: Ref<any[]> = ref([])
  const chartData: Ref<ChartData> = ref([])
  const isExecuting: Ref<boolean> = ref(false)
  const optionCount: Ref<number> = ref(0)

  const changeCounts: Ref<number[]> = ref([])
  const baselines: Ref<any[]> = ref([])

  const variableNodes: Ref<any[]> = ref([])
  const objectiveNodes: Ref<any[]> = ref([])
  const minOutputValue: Ref<number> = ref(0)
  const maxOutputValue: Ref<number> = ref(100)

  const optValByObjectiveNodeRows: Ref<any[]> = ref([])
  const solutionByVariableNodeRows: Ref<any[]> = ref([])

  const updateOptionDesignNodes = () => {
    const _variableNodes: any[] = []
    const _objectiveNodes: any[] = []
    nodeSelections.value?.forEach((node: NodeSelection) => {
      const {
        isInput,
        isOutput,
        key,
        isDeterministic,
        variation = 20,
        constraint,
        state,
        utilityVector
      } = node
      const variable = getVariable(networks.value, key)
      if (!variable) {
        return
      }
      if (isInput) {
        _variableNodes.push({
          key,
          state,
          states: variable.getAllStates(),
          variation,
          isDeterministic
        })
      } else if (isOutput) {
        _objectiveNodes.push({
          key,
          constraint,
          utilityVector,
          outcomes: [],
          value: 0
        })
      }
    })
    variableNodes.value = _variableNodes
    objectiveNodes.value = _objectiveNodes
  }

  const updateChart = () => {
    const _chartData: ChartData = []

    chartData.value = _chartData
  }

  const prepareTasks = () => {
    if (tasks.value?.length) {
      tasks.value?.forEach((_task: AnalysisTask) => {
        const network: Network = networkMap.value[_task.networkId]
        if (!network) {
          return
        }
        const { inputNodes, outputNodes } = getInputOuputNodes(network, nodeSelections.value)
        _task.inputNodes = inputNodes
        _task.outputNodes = outputNodes
      })
    }
  }

  const fillTasks = () => {
    if (!tasks.value?.length) {
      const _tasks: AnalysisTask[] = []
      networks.value?.map((network: Network, index: number) => {
        const _task: AnalysisTask = Object.assign(
          {
            id: objectId(),
            name: network.name,
            networkId: network.id,
            isPersisted: false,
            status: 'PENDING'
          },
          getInputOuputNodes(network, nodeSelections.value)
        )
        _tasks.push(_task)
      })
      tasks.value = _tasks
    }
  }

  const updateRowsWithResult = (
    optimalValues: number[][],
    _solutions: number[][],
    nodeDefinitions: number[][]
  ) => {
    if (isNil(selectedNetwork.value)) {
      return
    }
    const inputNodes: any[] = []
    const outputNodes: any[] = []
    nodeSelections.value?.forEach((node: NodeSelection) => {
      const { isInput, isOutput, key, isDeterministic, variation = 20, state, constraint } = node
      const variable = selectedNetwork.value.variableMapByKey[key]
      if (!variable) {
        return
      }
      if (isInput) {
        inputNodes.push({
          key,
          state,
          states: variable.getAllStates(),
          variation,
          isDeterministic
        })
      } else if (isOutput) {
        outputNodes.push({
          key,
          constraint,
          utilityVector: node.utilityVector,
          outcomes: [],
          value: 0
        })
      }
    })
    const _rows: any[] = []
    const options = optimalValues
    optionCount.value = options.length
    const _chartData: number[][] = new Array(options.length)
    let minValue = 100
    let maxValue = 0
    outputNodes.forEach((outputNode, outIndex) => {
      const { key, isDeterministic, state, constraint, states } = outputNode
      const row: any = {}
      row.name = key
      row.outputNode = outputNode
      row.baseline = 0
      row.constraint = constraint
      options.forEach((option, index) => {
        row['opt' + index] = option[outIndex] / 100.0
        _chartData[index] = option
        minValue = min([minValue, min(option)]) || 0
        maxValue = max([maxValue, max(option)]) || 100
      })
      _rows.push(row)
    })
    const baselines_: any[] = []
    solutionByVariableNodeRows.value = []
    inputNodes.forEach((inputNode: any, inputIndex: number) => {
      const { key, isDeterministic, state, variation, states } = inputNode
      const nodeDefinition = nodeDefinitions?.[inputIndex]
      const maxIndex = findMaxProbIndex(nodeDefinition)
      let baseline
      if (isDeterministic) {
        baseline = states[maxIndex]?.state
      } else {
        const baselineIndex = state
          ? findStateIndex(
              states.map(({ state }: { state: State }) => state),
              state
            )
          : maxIndex
        baseline = nodeDefinition[baselineIndex]
      }
      baselines_.push(baseline)
      const row: any = {}
      row.baseline = baseline
      row.inputNode = inputNode
      _solutions.forEach((solution, index) => {
        const levelIndex = solution[inputIndex]
        const nodeDefinition = nodeDefinitions?.[inputIndex]
        if (nodeDefinition) {
          if (isDeterministic) {
            const stateIndex = levelIndex
            row['opt' + index] = states[stateIndex]?.state
            row['order' + index] = stateIndex
          } else {
            let baseline = baselines_[inputIndex]
            baseline = baseline + (levelIndex * (inputNode.variation || 20)) / 100
            if (baseline > 1) {
              baseline = 1
            }
            row['opt' + index] = baseline
            row['order' + index] = baseline
          }
        }
      })
      row['variableNode'] = inputNode
      solutionByVariableNodeRows.value.push(row)
    })

    minOutputValue.value = minValue
    maxOutputValue.value = maxValue
    optValByObjectiveNodeRows.value = _rows
    baselines.value = baselines_
    chartData.value = _chartData

    return {
      inputNodes,
      outputNodes
    }
  }

  const clearResult = () => {
    chartData.value = []
    optValByObjectiveNodeRows.value = []
    solutionByVariableNodeRows.value = []
  }

  const pullResult = async (selectedTask: AnalysisTask) => {
    const jobId = currentJob.value?.id
    if (!jobId) {
      message.error('You need to save the job first')
      return
    }

    const jobTask = await getJobTask(workspaceId, jobId, selectedTask.id)
    if (!jobTask) {
      return
    }
    if (jobTask.status !== 'SUCCESS') {
      message.info('The task might still be running')
      return
    }
    const optimalValues = jobTask?.result?.optionDesignResults?.optimalValues
    const _solutions = jobTask?.result?.optionDesignResults?.solutions
    changeCounts.value = jobTask?.result?.optionDesignResults?.changeCounts
    const nodeDefinitions = jobTask?.result?.optionDesignResults?.nodeDefinitions
    if (!optimalValues) {
      message.error('No result is found')
      return
    }
    updateRowsWithResult(optimalValues, _solutions, nodeDefinitions)
  }

  const executeAnalysis = async (selectedTask: AnalysisTask) => {
    const jobId = currentJob.value?.id
    if (!jobId) {
      message.error('You need to save the job first')
      return
    }
    if (!selectedNetwork.value) {
      return
    }
    if (selectedTask) {
      selectedTask.status = 'EXECUTING'
      executeJobTask(workspaceId, jobId, selectedTask.id)
    } else {
      tasks.value?.forEach(async (task: AnalysisTask) => {
        await executeJobTask(workspaceId, jobId, task.id)
      })
    }
  }

  const abortAnalysis = (task: AnalysisTask) => {
    const jobId = currentJob.value?.id
    if (!jobId) {
      message.error('You need to save the job first')
      return
    }
    abortJobTask(workspaceId, jobId, task.id)
  }

  const exportTabular = (): any => {
    const datas: any[] = []
    const names = ['Optimal Values', 'Solutions']
    let data: any[] = []

    let header: any[] = ['Observed node', 'Type', 'Constraint', 'Utility']
    for (let i = 0; i < optionCount.value; i++) {
      header.push(`Option ${i + 1}`)
    }
    data.push(simpleRow(header))
    optValByObjectiveNodeRows.value?.forEach((objectiveNodeRow: any) => {
      const { outputNode, constraint } = objectiveNodeRow
      const { key, utilityVector } = outputNode
      const dataRow: any[] = [key, 'output', constraint, '[' + utilityVector.join(';') + ']']
      for (let i = 0; i < optionCount.value; i++) {
        dataRow.push(numberCell(objectiveNodeRow[`opt${i}`] * 100))
      }
      data.push(complexRow(dataRow))
    })
    datas.push(data)

    data = []
    const changes: any[] = ['No of changes', '', '', '', '']
    for (let i = 0; i < optionCount.value; i++) {
      changes.push({ value: changeCounts.value?.[i], type: Number })
    }
    data.push(complexRow(changes))
    header = ['Variable node', 'Type', 'State', 'Variation', 'Baseline']
    for (let i = 0; i < optionCount.value; i++) {
      header.push(`Solution ${i + 1}`)
    }
    data.push(simpleRow(header))
    solutionByVariableNodeRows.value?.forEach((variableNodeRow: any) => {
      const { inputNode, baseline } = variableNodeRow
      const { state, key, variation, isDeterministic } = inputNode
      const dataRow: any[] = [
        key,
        'input',
        state ? state : '',
        variation,
        isDeterministic ? baseline?.name : numberCell(baseline * 100)
      ]
      for (let i = 0; i < optionCount.value; i++) {
        const value = variableNodeRow[`opt${i}`]
        dataRow.push(isDeterministic ? value?.name : numberCell(value * 100))
      }
      data.push(complexRow(dataRow))
    })
    datas.push(data)
    return {
      names,
      datas
    }
  }

  return {
    chartData,
    exportTabular,
    fillTasks,
    baselines,
    variableNodes,
    minOutputValue,
    maxOutputValue,
    updateOptionDesignNodes,
    optionCount,
    optValByObjectiveNodeRows,
    solutionByVariableNodeRows,
    chartNodeIds,
    abortAnalysis,
    executeAnalysis,
    isExecuting,
    updateChart,
    prepareTasks,
    pullResult,
    clearResult,
    changeCounts
  }
}
