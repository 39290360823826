<template #comment="{ record }">
  <div class="sz-plain-comment">
    <a-popover trigger="hover">
      <template #content>
        <a-textarea
          v-model:value="comment"
          placeholder="Comment"
          :auto-size="{ minRows: 3, maxRows: 8 }"
          style="width: 300px"
          @change="onCommentChange"
        ></a-textarea>
      </template>
      <span
        :class="dotStyle"
        :style="{
          backgroundColor: comment.length > 1 ? '#aaaaaa' : 'red'
        }"
      />
      <span :class="cssTruncated">{{ comment }}</span>
    </a-popover>
  </div>
</template>

<script lang="ts">
import { css } from '@emotion/css'
import { defineComponent, ref } from 'vue'

const dotSize = '10px'

export const cssTruncated = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 10px);
  display: inline-block;
  vertical-align: middle;
`

const dotStyle = css`
  display: inline-block;
  vertical-align: middle;
  width: ${dotSize};
  height: ${dotSize};
  margin-right: 3px;
  border-radius: 50%;
  background-color: #aaaaaa;
  cursor: pointer;
`
export const EVENTS = {
  ON_CHANGE: 'change'
}

export default defineComponent({
  props: {
    initialComment: { type: String, default: '' }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const comment = ref<string>(props.initialComment)

    const onCommentChange = (event: Event) => {
      const { value } = event.target as HTMLTextAreaElement
      comment.value = value
      emit(EVENTS.ON_CHANGE, value)
    }

    return {
      cssTruncated,
      comment,
      onCommentChange,
      dotStyle
    }
  }
})
</script>
