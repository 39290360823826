import { render } from "./MiniLikelihood.vue?vue&type=template&id=2982065f"
import script from "./MiniLikelihood.vue?vue&type=script&lang=ts"
export * from "./MiniLikelihood.vue?vue&type=script&lang=ts"

import "./MiniLikelihood.vue?vue&type=style&index=0&id=2982065f&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2982065f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2982065f', script)) {
    api.reload('2982065f', script)
  }
  
  module.hot.accept("./MiniLikelihood.vue?vue&type=template&id=2982065f", () => {
    api.rerender('2982065f', render)
  })

}

script.__file = "src/components/method-ace/MiniLikelihood.vue"

export default script