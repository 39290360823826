import { ActionTree } from 'vuex'

import {
  createEdge,
  createNode,
  deleteEdge,
  deleteNode,
  getEdge,
  getEdges,
  getNode,
  getNodes,
  updateEdge,
  updateNode
} from '@/services/api/builder'
import { BuilderActionEnum as Action } from '@/store/enums/actions/builder'
import { BuilderMutationEnum as Mutation } from '@/store/enums/mutations/builder'
import type { RootState } from '@/store/types'
import { BuilderAction } from '@/store/types/actions/builder'
import type { BuilderState } from '@/store/types/states/builder'
import type { EdgeListSchema, EdgeSchema, NodeListSchema, NodeSchema } from '@/types'

/**
 * Action function
 */
export const actions: ActionTree<BuilderState, RootState> & BuilderAction = {
  // Get nodes
  async [Action.GET_NODES]({ commit }, networkId) {
    return new Promise((resolve, reject) => {
      commit(Mutation.GET_NODES, undefined)
      getNodes(networkId)
        .then((res: NodeListSchema) => {
          commit(Mutation.GET_NODES_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.GET_NODES_REJECTED, err)
          reject(err)
        })
    })
  },
  // Get node
  async [Action.GET_NODE]({ commit }, id) {
    commit(Mutation.GET_NODE, undefined)
    getNode(id)
      .then((res: NodeSchema) => {
        commit(Mutation.GET_NODE_FULFILLED, res)
      })
      .catch((err: Error) => {
        commit(Mutation.GET_NODE_REJECTED, err)
      })
  },
  // Create node
  async [Action.CREATE_NODE]({ commit }, { networkId, payload }) {
    return new Promise((resolve, reject) => {
      commit(Mutation.CREATE_NODE, undefined)
      createNode(networkId, payload)
        .then((res: NodeSchema) => {
          commit(Mutation.CREATE_NODE_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.CREATE_NODE_REJECTED, err)
          reject(err)
        })
    })
  },
  // Update node
  async [Action.UPDATE_NODE]({ commit }, { id, networkId, payload }) {
    return new Promise((resolve, reject) => {
      commit(Mutation.UPDATE_NODE, undefined)
      updateNode(networkId, id, payload)
        .then((res: NodeSchema) => {
          commit(Mutation.UPDATE_NODE_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.UPDATE_NODE_REJECTED, err)
          reject(err)
        })
    })
  },
  // Delete node
  async [Action.DELETE_NODE]({ commit }, { networkId, id }) {
    commit(Mutation.DELETE_NODE, undefined)
    deleteNode({ networkId, id })
      .then(() => {
        commit(Mutation.DELETE_NODE_FULFILLED, id)
      })
      .catch((err: Error) => {
        commit(Mutation.DELETE_NODE_REJECTED, err)
      })
  },
  // Get edges
  async [Action.GET_EDGES]({ commit }, networkId) {
    return new Promise((resolve, reject) => {
      commit(Mutation.GET_EDGES, undefined)
      getEdges(networkId)
        .then((res: EdgeListSchema) => {
          commit(Mutation.GET_EDGES_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.GET_EDGES_REJECTED, err)
          reject(err)
        })
    })
  },
  // Get edge
  async [Action.GET_EDGE]({ commit }, id) {
    commit(Mutation.GET_EDGE, undefined)
    getEdge(id)
      .then((res: EdgeSchema) => {
        commit(Mutation.GET_EDGE_FULFILLED, res)
      })
      .catch((err: Error) => {
        commit(Mutation.GET_EDGE_REJECTED, err)
      })
  },
  // Create edge
  async [Action.CREATE_EDGE]({ commit }, { networkId, payload }) {
    return new Promise((resolve, reject) => {
      commit(Mutation.CREATE_EDGE, undefined)
      createEdge({ networkId, payload })
        .then((res: EdgeSchema) => {
          commit(Mutation.CREATE_EDGE_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.CREATE_EDGE_REJECTED, err)
          reject(err)
        })
    })
  },
  // Update edge
  async [Action.UPDATE_EDGE]({ commit }, { id, networkId, payload }) {
    return new Promise((resolve, reject) => {
      commit(Mutation.UPDATE_EDGE, undefined)
      updateEdge({ id, networkId, payload })
        .then((res: EdgeSchema) => {
          commit(Mutation.UPDATE_EDGE_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.UPDATE_EDGE_REJECTED, err)
          reject(err)
        })
    })
  },
  // Delete edge
  async [Action.DELETE_EDGE]({ commit }, { networkId, id }) {
    commit(Mutation.DELETE_EDGE, undefined)
    deleteEdge({ networkId, id })
      .then(() => {
        commit(Mutation.DELETE_EDGE_FULFILLED, id)
      })
      .catch((err: Error) => {
        commit(Mutation.DELETE_EDGE_REJECTED, err)
      })
  }
}
