import { SurveyStateEnum } from '@/store/enums/states/survey'
import { SurveyState } from '@/store/types/states/survey'

/**
 * Default value
 */
const state: SurveyState = {
  [SurveyStateEnum.IS_CREATING]: false,
  [SurveyStateEnum.IS_UPDATING]: false,
  [SurveyStateEnum.IS_DELETING]: false,
  [SurveyStateEnum.IS_GETTING]: false,
  [SurveyStateEnum.IS_EXPORTING]: false,
  [SurveyStateEnum.CURRENT_SURVEY]: null,
  [SurveyStateEnum.SURVEY_LIST]: {},
  [SurveyStateEnum.ERROR]: null,
  [SurveyStateEnum.LOADING_PROMPT_TEXT]: null
}

export { state }
