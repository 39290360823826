
import { isEmpty } from 'lodash-es'
import { computed, defineComponent, onBeforeUpdate, PropType, Ref, ref, toRefs } from 'vue'

import useAntTableScroll from '@/components/composables/antTableScroll'
import useResponses from '@/components/composables/responses'
import useTable from '@/components/composables/table'
import { EVENTS as PROB_EVENTS, redistributeProbs } from '@/components/input/common'
import Prob from '@/components/input/Prob.vue'
import ProbAgg from '@/components/input/ProbAgg.vue'
import ProbSelector from '@/components/input/ProbSelector.vue'
import { RowData, TABLE_EVENTS } from '@/components/method-common/Table'
import VariableContext from '@/components/survey/VariableContext.vue'
import { Combination, CPT, Network, Variable } from '@/libs/bayes'
import { ResponseUnit } from '@/libs/bayes/CPTResponse'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { getStateStyle } from '@/libs/utils'
import { ResponseSchema, SurveySchema } from '@/types'

export default defineComponent({
  components: {
    VariableContext,
    Prob,
    ProbAgg,
    ProbSelector
  },
  props: {
    network: { type: Network, required: true },
    currentSurvey: { type: Object as PropType<SurveySchema>, required: true },
    cpt: { type: Object as PropType<CPT>, required: true },
    selectedVariable: { type: Object as PropType<Variable>, required: true },
    responses: { type: Array as PropType<Array<ResponseSchema>>, default: () => [] },
    editable: { type: Boolean, default: false },
    allowPartialSave: { type: Boolean, default: false },
    isSaveButtonSpinning: { type: Boolean },
    analytics: { type: Object, default: undefined },
    userMap: { type: Object, default: undefined },
    isAnonymous: { type: Boolean },
    isStochastics: { type: Boolean, default: false }
  },
  emits: [...Object.values(TABLE_EVENTS)],
  setup(props, { emit }) {
    const isPreviewVisible = ref(false)
    const isAnalytics = computed(() => !isEmpty(props.analytics))
    const { responses, cpt, network, selectedVariable } = toRefs(props)

    const {
      parents,
      children,
      combinations,
      elicitedRows,
      selectedState,
      dependents,
      independents,
      dependentMap
    } = useTable(network, selectedVariable, cpt)

    const cptableWrapper = ref(null)
    const depKeyMapper: Ref<Record<string, any>> = ref({})
    const cptValues: Ref<Array<Array<number>> | null> = ref([[]])
    const depPermutation: Ref<Array<any>> = ref([])
    const isTerminal = computed(() => !parents?.value.length)
    const probs = ref<Record<string, any>>({})

    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      probs.value = {}
    })

    const { COMBKEY_PREFIX, isValid, responseMap, genResponsesPayload, validMap } = useResponses(
      responses,
      combinations,
      elicitedRows,
      cpt,
      dependentMap
    )
    const { tableWrapper, scrollX, scrollY } = useAntTableScroll(dependents, independents)

    const columns = computed(() => {
      // const allStates = props.selectedVariable.getAllStates()

      const stateColumns = combinations.value.map((combination: Combination, index: number) => {
        const { variable, state } = combination[0]
        const depCombKey = `${COMBKEY_PREFIX}-${variable.id}+${state.id}`
        depKeyMapper.value[depCombKey] = combination
        return {
          title: state.name,
          key: `marginal-${index}`,
          dataIndex: depCombKey,
          align: 'center',
          combination,
          polarity: state.polarity,
          width: 120,
          slots: {
            customRender: 'response'
          },
          customHeaderCell: (col: any) => {
            return {
              style: {
                ...getStateStyle(col.polarity)
              }
            }
          }
        }
      })

      const varColumn: any = {
        title: 'Variable',
        align: 'left',
        fixed: 'left',
        key: 'name',
        dataIndex: 'name',
        slots: {
          customRender: 'first-col'
        },
        width: 400
      }
      return [varColumn].concat(stateColumns)
    })

    const genCoreData = () => {
      return combinations.value.reduce((acc: RowData, combination: Combination, index: number) => {
        const { variable, state } = combination[0]
        const depCombKey = `${COMBKEY_PREFIX}-${variable.id}+${state.id}`
        const response = responseMap.value.get(depCombKey) || {
          combKeyx: depCombKey
        }
        response.disabled = index === 0
        acc[depCombKey] = response
        return acc
      }, {})
    }

    const data: Ref<Array<RowData>> = computed(() => {
      const variable = props.selectedVariable
      const row: RowData = {}
      row.key = variable.id
      row.varName = variable.name
      row.name = variable.name
      row.isLikelihood = false
      const coreData = genCoreData()
      return [{ ...row, ...coreData }]
    })

    const onResponsesChange = () => {
      const payload = genResponsesPayload(props.isStochastics)
      if (payload) {
        emit(TABLE_EVENTS.ON_RESPONSES_CHANGE, payload)
      }
    }

    const onResponseChange = (
      {
        response,
        combKey,
        valid,
        rowIndex
      }: {
        response: ResponseSchema
        combKey: string
        valid: boolean
        rowIndex: number
      },
      noDist = false
    ) => {
      validMap.set(combKey, valid)
      const responseUnit = responseMap.value.get(combKey)
      if (!responseUnit) {
        return
      }
      const rowData = data.value[rowIndex]
      const stateLength = props.selectedVariable?.getAllStates().length
      const colLength = columns.value?.length
      const isDeterministic = props.selectedVariable?.isDeterministic()
      if (!noDist) {
        try {
          redistributeProbs(
            rowData,
            columns.value.slice(colLength - stateLength),
            probs.value,
            combKey,
            isDeterministic
          )
        } catch (e) {
          console.log(e)
          // pass
        }
      }
      const comboRow: ResponseUnit = { ...responseUnit }
      comboRow.response = Object.assign({ ...responseUnit.response }, response)
      responseMap.value.set(combKey, comboRow)
    }

    return {
      cptValues,
      PROB_EVENTS,
      VariableRelation,
      children,
      columns,
      combinations,
      elicitedRows,
      cptableWrapper,
      depPermutation,
      data,
      dependents,
      independents,
      isValid,
      onResponseChange,
      onResponsesChange,
      parents,
      probs,
      selectedState,
      responseMap,
      validMap,
      scrollX,
      scrollY,
      tableWrapper,
      isPreviewVisible,
      isAnalytics,
      isTerminal
    }
  }
})
