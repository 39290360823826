import API from '@/axios'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import { Allocation, AllocationForm, Workspace } from '@/types'

/**
 * Create allocation
 * @param workspaceId - workspace uuid
 * @param allocation - allocation form value
 */
const createAllocation = async ({
  workspaceId,
  allocation
}: {
  workspaceId: Workspace['id']
  allocation: AllocationForm
}): Promise<any> => {
  try {
    return extractResponse(await API.post(ENDPOINTS.ALLOCATIONS({ workspaceId }), allocation))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get filtered allocations
 */
const getAllocations = async (
  workspaceId: Workspace['id'],
  params: { userId?: Allocation['userId'] } | undefined = {}
): Promise<any> => {
  try {
    const requestParams = Object.assign({}, API_DEFAULT_PAGEABLE_PARAMS, params)
    return recursivePaginationAPIRequestFn(
      API,
      ENDPOINTS.ALLOCATIONS({ workspaceId })
    )(requestParams)
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get allocation from server
 * @param id - allocation uuid
 */
const getAllocation = async (id: Allocation['id']): Promise<any> => {
  try {
    return extractResponse(await API.get(ENDPOINTS.ALLOCATION({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Update allocation data to server
 */
const updateAllocation = async ({
  id,
  allocation
}: {
  id: Allocation['id']
  allocation: AllocationForm
}): Promise<any> => {
  try {
    return extractResponse(await API.put(ENDPOINTS.ALLOCATION({ id }), allocation))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Delete an allocation from server
 * @param id - survey uuid
 */
const deleteAllocation = async (id: Allocation['id']): Promise<any> => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.ALLOCATION({ id: id })))
  } catch (e) {
    errorHandler(e)
  }
}

export { createAllocation, updateAllocation, deleteAllocation, getAllocation, getAllocations }
