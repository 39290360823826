
import { IconDeviceFloppy } from '@tabler/icons-vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, defineComponent, PropType } from 'vue'

import { CriteriaWeightsType } from '@/components/analysis/composable/hybrid-types'
import { percentFormatter } from '@/libs/common'

export const EVENTS = {
  OVERWRITE: 'OVERWRITE'
}

export default defineComponent({
  components: {
    IconDeviceFloppy
  },
  props: {
    criteriaWeights: { type: Array as PropType<CriteriaWeightsType[]>, default: undefined },
    isConsolidated: { type: Boolean, default: false }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const criteriaWeightsColumns = computed(() => {
      let columns: ColumnProps[] = [
        {
          title: 'Criterion',
          dataIndex: 'criterionKey',
          key: 'criterionKey',
          slots: {
            customRender: 'criterionKey'
          }
        }
      ]
      if (props.isConsolidated) {
        columns.push({
          title: 'Override Weight',
          dataIndex: 'elicitedWeight',
          key: 'elicitedWeight',
          align: 'right',
          slots: {
            customRender: 'elicitedWeight'
          }
        })
      }
      columns = columns.concat([
        {
          title: 'Calc Weight',
          dataIndex: 'calculatedWeight',
          key: 'calculatedWeight',
          align: 'right',
          slots: {
            customRender: 'calculatedWeight'
          }
        },
        {
          title: 'Error',
          dataIndex: 'error',
          key: 'error',
          align: 'right',
          slots: {
            customRender: 'error'
          }
        }
      ])
      return columns
    })

    const overwrite = () => {
      emit(EVENTS.OVERWRITE, props.criteriaWeights)
    }

    return {
      overwrite,
      percentFormatter,
      criteriaWeightsColumns
    }
  }
})
