import { ActionTree } from 'vuex'

import {
  createNetwork,
  deleteNetwork,
  getNetwork,
  getNetworks,
  updateNetwork,
  uploadNetwork
} from '@/services/api/network'
import { NetworkActionEnum as Action } from '@/store/enums/actions/network'
import { NetworkMutationEnum as Mutation } from '@/store/enums/mutations/network'
import type { RootState } from '@/store/types'
import { NetworkAction } from '@/store/types/actions/network'
import type { NetworkState } from '@/store/types/states/network'
import type { NetworkList, NetworkSchema } from '@/types'

/**
 * Action function
 */
export const actions: ActionTree<NetworkState, RootState> & NetworkAction = {
  // Get networks
  async [Action.GET_NETWORKS]({ commit }, workspaceId) {
    return new Promise((resolve, reject) => {
      commit(Mutation.GET_NETWORKS, undefined)
      getNetworks(workspaceId)
        .then((res: NetworkList) => {
          commit(Mutation.GET_NETWORKS_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.GET_NETWORKS_REJECTED, err)
          reject(err)
        })
    })
  },
  // Get network
  async [Action.GET_NETWORK]({ commit }, id) {
    commit(Mutation.GET_NETWORK, undefined)
    getNetwork(id)
      .then((res: NetworkSchema) => {
        commit(Mutation.GET_NETWORK_FULFILLED, res)
      })
      .catch((err: Error) => {
        commit(Mutation.GET_NETWORK_REJECTED, err)
      })
  },
  // Create network
  async [Action.CREATE_NETWORK]({ commit }, { workspaceId, network }) {
    return new Promise((resolve, reject) => {
      commit(Mutation.CREATE_NETWORK, undefined)
      createNetwork({ workspaceId, payload: network })
        .then((res: NetworkSchema) => {
          commit(Mutation.CREATE_NETWORK_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.CREATE_NETWORK_REJECTED, err)
          reject(err)
        })
    })
  },
  // Update network
  async [Action.UPDATE_NETWORK]({ commit }, { id, network }) {
    return new Promise((resolve, reject) => {
      commit(Mutation.UPDATE_NETWORK, undefined)
      updateNetwork({ id, payload: network })
        .then((res: NetworkSchema) => {
          commit(Mutation.UPDATE_NETWORK_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.UPDATE_NETWORK_REJECTED, err)
          reject(err)
        })
    })
  },
  // Import network
  async [Action.IMPORT_NETWORK]({ commit }, { workspaceId, file }) {
    return new Promise((resolve, reject) => {
      commit(Mutation.IMPORT_NETWORK, undefined)
      uploadNetwork({ workspaceId, file })
        .then((res: NetworkSchema) => {
          commit(Mutation.IMPORT_NETWORK_FULFILLED, res)
          resolve(res)
        })
        .catch((err: Error) => {
          commit(Mutation.IMPORT_NETWORK_REJECTED, err)
          reject(err)
        })
    })
  },
  // Delete network
  async [Action.DELETE_NETWORK]({ commit }, id) {
    commit(Mutation.DELETE_NETWORK, undefined)
    deleteNetwork(id)
      .then(() => {
        commit(Mutation.DELETE_NETWORK_FULFILLED, id)
      })
      .catch((err: Error) => {
        commit(Mutation.DELETE_NETWORK_REJECTED, err)
      })
  }
}
