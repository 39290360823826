<template>
  <div ref="tableWrapper" class="sz-cpt-preview">
    <a-table
      v-if="data.length !== 0"
      :data-source="data"
      :columns="columns"
      :pagination="false"
      size="small"
      class="sz-cpt-preview-table"
      :scroll="{ y: scrollY }"
      :row-class-name="() => 'sz-cpt-preview-row'"
    >
      <template #indep-col="{ text: state }">
        <div :style="getStateStyle(state.polarity)" class="sz-centered-wrapper">
          <span>{{ state?.name }}</span>
        </div>
      </template>
      <template #value="{ text: value }">
        <div class="sz-cpt-value">{{ Math.abs(value).toFixed(4) }}</div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import useAntTableScroll from '@/components/composables/antTableScroll'
import { Condition } from '@/libs/bayes/Condition'
import { CPT } from '@/libs/bayes/methods/CPT'
import { Variable } from '@/libs/bayes/Variable'
import { combinationOf } from '@/libs/common'
import { getStateStyle } from '@/libs/utils'

export default defineComponent({
  props: {
    cpt: { type: Object as PropType<CPT>, required: true },
    selectedVariable: { type: Object, required: true },
    cptValues: { type: Array as PropType<Array<Array<number>>>, required: true }
  },
  setup(props) {
    const independents = computed(() => props.cpt.independents)
    const permutation = computed(() => combinationOf(independents.value))
    const childStates = computed(() => props.selectedVariable.getAllStates())
    const { tableWrapper, scrollY } = useAntTableScroll(null, null, 177)

    const columns = computed(() => {
      const cols: Array<any> = []
      if (independents.value.length) {
        independents.value.forEach((variable: Variable) => {
          cols.push({
            title: variable.name,
            key: variable.id,
            dataIndex: variable.id,
            align: 'center',
            slots: {
              customRender: 'indep-col'
            },
            width: 80,
            customCell: () => {
              return {
                class: 'sz-indep-cell'
              }
            },
            customHeaderCell: () => {
              return {
                class: 'sz-indep-col'
              }
            }
          })
        })
      }

      const leaves = childStates.value.map(({ state }: Condition) => {
        return {
          title: state.name,
          key: state.id,
          dataIndex: state.id,
          polarity: state.polarity,
          slots: {
            customRender: 'value'
          },
          width: 100,
          customHeaderCell: (col: any) => {
            return {
              style: {
                'text-align': 'center',
                ...getStateStyle(col.polarity)
              }
            }
          }
        }
      })

      return cols.concat([
        {
          title: '',
          key: 'filler',
          customHeaderCell: () => {
            return {
              style: {
                height: '100px'
              }
            }
          },
          children: [
            {
              title: props.selectedVariable.name + ' (child)',
              key: 'selectedVar',
              children: leaves
            }
          ]
        }
      ])
    })

    const data = computed(() => {
      const rows: Array<any> = []
      if (!props.cptValues) {
        return rows
      }
      permutation.value.forEach((stateIndices: Array<number>, permIndex: number) => {
        const row: Record<string, any> = {}
        stateIndices.forEach((stateIndex: number, varIndex: number) => {
          const variable = independents.value[varIndex]
          const state = variable.getState(stateIndex)
          row[variable.id] = state
        })
        childStates.value.forEach(({ state }: Condition, childStateIndex: number) => {
          row[state.id] = props.cptValues[permIndex][childStateIndex]
        })
        rows.push(row)
      })

      return rows
    })

    return {
      getStateStyle,
      columns,
      data,
      tableWrapper,
      scrollY
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/vars.styl"
@import "../../styles/commons.styl"

.sz-cpt-preview
  display: block
  position: absolute
  left: 20px
  right: 20px
  top: 20px
  bottom: 20px

  .ant-table-thead tr
    height: 40px

.sz-cpt-preview-row
  height: 40px

.sz-indep-cell
  padding: 0 !important
  position: relative;
  .sz-centered-wrapper
    @extend .centered
    overflow: hidden
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
  span
    display: block

.sz-cpt-value
  text-align center

.sz-indep-col
  .ant-table-header-column
    width: 100px
    div
      position: relative
      .ant-table-column-title
        display: block
        transform: rotate(-90deg) translateX(10%) translateY(calc(-50% - 20px))
        width: 180px
        text-align: left
</style>
