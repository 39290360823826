
import { CheckOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { find, get, isEmpty } from 'lodash-es'
import { has, includes, keys } from 'ramda'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  PropType,
  reactive,
  Ref,
  ref,
  watch
} from 'vue'

import useAllocation from '@/components/composables/allocation'
import NodeDefinitionEditor, {
  EVENTS as EDIT_EVENTS
} from '@/components/variable/NodeDefinitionEditor.vue'
import VariableCollectionMethodCol from '@/components/variable/VariableCollectionMethodCol.vue'
import VariableDependencyCol from '@/components/variable/VariableDependencyCol.vue'
import VariableNameCol, { EVENTS as VAR_EVENTS } from '@/components/variable/VariableNameCol.vue'
import VariableOutputCol from '@/components/variable/VariableOutputCol.vue'
import VariableStateCol from '@/components/variable/VariableStateCol.vue'
import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { ModuleNames } from '@/constants/vuex'
import { Network, Variable } from '@/libs/bayes'
import { CPTMethod } from '@/libs/bayes/enums/CPTMethod'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { Dict } from '@/libs/common'
import { tableScrollY } from '@/libs/utils'
import router from '@/router'
import { useStore } from '@/store'
import { UserActionEnum } from '@/store/enums/actions'
import { AllocationStateEnum, UserStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'
import { RowData, User } from '@/types'

import {
  COLL_METHOD_SEARCH_VALUE,
  COLUMNS,
  DEPENDENCY_SEARCH_VALUE,
  TABLE_HEADER_HEIGHT,
  TABLE_PAGNATION_HEIGHT,
  TableConfig
} from './variable-table'

export default defineComponent({
  components: {
    CheckOutlined,
    SearchOutlined,
    NodeDefinitionEditor,
    VariableCollectionMethodCol,
    VariableDependencyCol,
    VariableNameCol,
    VariableOutputCol,
    VariableStateCol
  },
  props: {
    network: { type: Object as PropType<Network>, required: true },
    selectedVariable: { type: Object as PropType<Variable>, default: undefined },
    currentSurvey: { type: Object, default: undefined },
    analyticsMap: { type: Object, default: undefined },
    isStochastic: { type: Boolean, default: false },
    config: {
      type: Object as PropType<TableConfig>,
      required: false,
      default: () => ({
        analyticsVisible: false,
        actionVisible: false,
        allocationVisible: true,
        collectionMethodVisible: false,
        dependencyVisible: true,
        indexVisible: true,
        statesVisible: true,
        variableNameVisible: true
      })
    },
    showAux: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false }
  },
  emits: [
    EMIT_EVENTS.VARIABLE.SELECT,
    EMIT_EVENTS.VARIABLE.CHANGE,
    EMIT_EVENTS.VARIABLE.SELECT_ALLOCATION,
    EMIT_EVENTS.SURVEY.VARIABLE_TOGGLE_CAIN
  ],
  setup(props, context) {
    const store = useStore()
    const editedVariable: Ref<Variable | undefined> = ref()
    const variableEditorVisible = ref(false)
    const routerParams = router.currentRoute.value.params
    let { workspaceId } = routerParams
    const showKey = ref(true)
    const collectionMethodMap: Dict = reactive({})
    const outputMap: Dict = reactive({})

    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const parents = computed(() =>
      props.network && props.selectedVariable
        ? props.network.getParents(props.selectedVariable)
        : []
    )
    const children = computed(() =>
      props.network && props.selectedVariable
        ? props.network.getChildren(props.selectedVariable)
        : []
    )
    const variables = computed(() => (props.network ? props.network.variables : []))

    // const networkListContent = computed(
    //   () => store.state[ModuleNames.NETWORK][NetworkStateEnum.NETWORK_LIST]?.content
    // )
    // const networkId = computed(() => {
    //   const currentNetwork = networkListContent.value[0]
    //   const netId = currentNetwork?.id
    //   if (netId && typeof netId === 'string') {
    //     return netId
    //   }
    //   return null
    // })
    const totalUsers = computed(() => {
      return store.state[ModuleNames.USER][UserStateEnum.USER_LIST]?.totalElements
    })
    const allocations = computed(
      () => store.state[ModuleNames.ALLOCATION][AllocationStateEnum.ALLOCATION_LIST]?.content
    )
    const userList = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)
    const userMap = computed(() => {
      const result: Record<string, User> = {}
      userList.value?.forEach((each: User) => (result[each.id] = each))
      return result
    })
    const userMapByName = computed(() => {
      const result: Record<string, User> = {}
      userList.value?.forEach((each: User) => (result[each.username] = each))
      return result
    })

    const currentPage = ref<number>(API_DEFAULT_PAGEABLE_PARAMS.page + 1)
    // const state = reactive({
    //   searchText: '',
    //   searchedColumn: ''
    // })
    const searchText = ref('')
    const searchedColumn = ref('')

    const userSearchInput = ref<string>('')
    const variableRegexInput = ref<string>('')
    const tableSearchInput = ref()
    const scrollY: Ref<null | number> = ref(null)
    const variableTableWrapper = ref(null)
    // const maxVariableWidth = computed(() => {
    //   const variableNameWidth = Math.max(
    //     Math.max(...data.value.map((row: RowData) => row.variableNameWidth)) + VAR_NAME_PAD,
    //     VAR_NAME_MIN_WIDTH
    //   )
    //   return `${variableNameWidth}px`
    // })

    const { allocateUserToVariable } = useAllocation(store, workspaceId, props.network.id)

    const data = computed(() => {
      let userData: Record<string, any> = {}
      if (props.config.allocationVisible) {
        userList.value?.forEach((eachUser: User) => {
          userData[eachUser.id] = eachUser.id
        })
      }
      if (props.config.collectionMethodVisible && props.currentSurvey) {
        keys(props.currentSurvey?.ext?.collectionMethodMap).forEach((key: any) => {
          if (props.currentSurvey?.ext?.collectionMethodMap[key]) {
            collectionMethodMap[key] = props.currentSurvey.ext?.collectionMethodMap[key]
          }
        })
      }
      if (props.isStochastic && props.currentSurvey) {
        keys(props.currentSurvey?.ext?.outputMap).forEach((key: any) => {
          if (props.currentSurvey?.ext?.outputMap[key]) {
            outputMap[key] = props.currentSurvey.ext?.outputMap[key]
          }
        })
      }
      return (variables as any).value.map((eachVar: Variable, idx: number) => {
        const data: RowData = {}
        data.key = idx
        data[COLUMNS.VAR_INDEX.DATA_INDEX] = idx + 1
        data[COLUMNS.VAR_NAME.DATA_INDEX] = eachVar.name
        data[COLUMNS.DEPENDENCY.DATA_INDEX] = eachVar.dependent

        if (props.config.allocationVisible) {
          userList.value?.forEach((eachUser: User) => {
            data[eachUser.id] = eachUser
          })
        }

        if (props.config.analyticsVisible) {
          data.stats = props.analyticsMap?.[eachVar.id]?.stats
        }
        data.variable = eachVar
        // data.variableNameWidth = getColumnWidth(eachVar.name)
        // data.stateNameWidth = getColumnWidth(
        //   eachVar.states.map((each) => each.name).join(' '),
        //   true
        // )
        if (props.config.collectionMethodVisible && props.currentSurvey) {
          if (has(eachVar.id, collectionMethodMap)) {
            data.collectionMethod = 'cain'
          } else {
            data.collectionMethod = 'default'
          }
        }
        if (props.isStochastic && props.currentSurvey) {
          if (outputMap[eachVar.key]) {
            data[COLUMNS.OUTPUT.DATA_INDEX] = true
          } else {
            data[COLUMNS.OUTPUT.DATA_INDEX] = false
          }
        }

        if (props.config.allocationVisible) {
          return {
            ...data,
            ...userData
          }
        } else {
          return data
        }
      })
    })

    const columns = computed(() => {
      // const variableNameWidth = Math.max(...data.value.map((row: RowData) => row.variableNameWidth))
      const stateNameWidth = Math.max(...data.value.map((row: RowData) => row.stateNameWidth))
      const result: Array<any> = []

      if (props.config.indexVisible) {
        result.push({
          key: COLUMNS.VAR_INDEX.KEY,
          dataIndex: COLUMNS.VAR_INDEX.DATA_INDEX,
          title: COLUMNS.VAR_INDEX.TITLE,
          fixed: 'left',
          width: 40,
          align: 'center'
        })
      }
      if (props.config.variableNameVisible) {
        result.push({
          key: COLUMNS.VAR_NAME.KEY,
          dataIndex: COLUMNS.VAR_NAME.DATA_INDEX,
          // title: COLUMNS.VAR_NAME.TITLE,
          fixed: 'left',
          // width: maxVariableWidtxh.value, // variableNameWidth || getColumnWidth('Name'),
          slots: {
            title: COLUMNS.VAR_NAME.SLOTS.TITLE,
            customRender: COLUMNS.VAR_NAME.SLOTS.CUSTOM_RENDER,
            filterDropdown: COLUMNS.VAR_NAME.SLOTS.FILER_DROPDOWN,
            filterIcon: COLUMNS.VAR_NAME.SLOTS.FILER_ICON
          },
          onFilter: (value: any, record: RowData) =>
            record.variable.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
              setTimeout(() => {
                tableSearchInput.value.focus()
              }, 0)
            }
          }
        })
      }
      if (props.isStochastic) {
        result.push({
          title: COLUMNS.OUTPUT.TITLE,
          dataIndex: COLUMNS.OUTPUT.DATA_INDEX,
          key: COLUMNS.OUTPUT.KEY,
          align: 'center',
          width: 50,
          slots: {
            customRender: COLUMNS.OUTPUT.SLOTS.CUSTOM_RENDER
          }
        })
      }
      if (props.config.analyticsVisible) {
        result.push({
          key: COLUMNS.ANALYTICS.KEY,
          dataIndex: COLUMNS.ANALYTICS.DATA_INDEX,
          align: 'center',
          fixed: 'left',
          width: 50,
          slots: {
            title: COLUMNS.ANALYTICS.SLOTS.TITLE,
            customRender: COLUMNS.ANALYTICS.SLOTS.CUSTOM_RENDER
          },
          filters: [
            { text: 'No responses', value: 0 },
            { text: 'Has responses', value: 1 }
          ],
          defaultFilteredValue: [0, 1],
          onFilter: (value: number, record: RowData) => {
            const userCount = get(record.stats, 'userCount', 0)
            if (value === 0) {
              return userCount === 0
            } else {
              return userCount > 0
            }
          }
        })
      }
      if (props.config.dependencyVisible) {
        result.push({
          key: COLUMNS.DEPENDENCY.KEY,
          dataIndex: COLUMNS.DEPENDENCY.DATA_INDEX,
          align: 'center',
          fixed: 'left',
          width: 50,
          slots: {
            title: COLUMNS.DEPENDENCY.SLOTS.TITLE,
            customRender: COLUMNS.DEPENDENCY.SLOTS.CUSTOM_RENDER
          },
          filters: [
            { text: DEPENDENCY_SEARCH_VALUE.TRUE.TEXT, value: DEPENDENCY_SEARCH_VALUE.TRUE.VALUE },
            { text: DEPENDENCY_SEARCH_VALUE.FALSE.TEXT, value: DEPENDENCY_SEARCH_VALUE.FALSE.VALUE }
          ],
          defaultFilteredValue: [
            DEPENDENCY_SEARCH_VALUE.TRUE.VALUE,
            DEPENDENCY_SEARCH_VALUE.FALSE.VALUE
          ],
          onFilter: (value: string, record: RowData) => {
            const dependency = get(record.variable?.ext, 'dependency', null)
            if (dependency === null) {
              return value === DEPENDENCY_SEARCH_VALUE.FALSE.VALUE
            } else {
              return dependency.toString() === value
            }
          }
        })
      }
      if (props.config.collectionMethodVisible && props.currentSurvey) {
        result.push({
          key: COLUMNS.COLL_METHOD.KEY,
          dataIndex: COLUMNS.COLL_METHOD.DATA_INDEX,
          align: 'center',
          fixed: 'left',
          width: 60,
          slots: {
            title: COLUMNS.COLL_METHOD.SLOTS.TITLE,
            customRender: COLUMNS.COLL_METHOD.SLOTS.CUSTOM_RENDER
          },
          filters: [
            {
              text: COLL_METHOD_SEARCH_VALUE.TRUE.TEXT,
              value: COLL_METHOD_SEARCH_VALUE.TRUE.VALUE
            },
            {
              text: COLL_METHOD_SEARCH_VALUE.FALSE.TEXT,
              value: COLL_METHOD_SEARCH_VALUE.FALSE.VALUE
            }
          ],
          defaultFilteredValue: [
            COLL_METHOD_SEARCH_VALUE.TRUE.VALUE,
            COLL_METHOD_SEARCH_VALUE.FALSE.VALUE
          ],
          onFilter: (value: string, record: RowData) => {
            if (!Object.prototype.hasOwnProperty.call(record.collectionMethod, 'cain')) {
              if (value === COLL_METHOD_SEARCH_VALUE.FALSE.VALUE) {
                return !Object.prototype.hasOwnProperty.call(record.collectionMethod, 'default')
              }
              return record.collectionMethod === value
            } else {
              return record.collectionMethod.toString() === value
            }
          }
        })
      }
      if (props.config.statesVisible) {
        result.push({
          key: COLUMNS.STATES.KEY,
          dataIndex: COLUMNS.STATES.DATA_INDEX,
          align: 'left',
          // fixed: 'left',
          width: 300 || stateNameWidth, // || getColumnWidth(COLUMNS.STATES.TITLE),
          slots: {
            title: COLUMNS.STATES.SLOTS.TITLE,
            customRender: COLUMNS.STATES.SLOTS.CUSTOM_RENDER,
            filterDropdown: COLUMNS.VAR_NAME.SLOTS.FILER_DROPDOWN,
            filterIcon: COLUMNS.VAR_NAME.SLOTS.FILER_ICON
          },
          onFilter: (value: any, record: RowData) =>
            record.variable.states
              .map((each: any) => each.name.toLowerCase())
              .join(' ')
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
              setTimeout(() => {
                tableSearchInput.value.focus()
              }, 0)
            }
          }
        })
      }
      if (props.config.allocationVisible) {
        userList.value.forEach((eachUser: User) => {
          // Exclude admin from allocation
          if (includes(DB_ENUM_VALUES.USER.ROLES.ADMIN, eachUser[DB_FIELDS.USER.ROLES])) {
            return
          }
          const userName = `${eachUser[DB_FIELDS.USER.USERNAME]}`
          result.push({
            title: userName,
            dataIndex: eachUser.id,
            key: eachUser.id,
            align: 'center',
            width: 100, // getColumnWidth(userName) > 100 ? getColumnWidth(userName) : 100,
            slots: {
              customRender: COLUMNS.ALLOCATION.SLOTS.CUSTOM_RENDER
            }
          })
        })
      }
      return result
    })

    /**
     * Handle table search
     **/
    const handleSearch = (selectedKeys: Array<any>, confirm: any, dataIndex: string) => {
      confirm()
      searchText.value = selectedKeys[0]
      searchedColumn.value = dataIndex
    }

    /**
     * Handle reset table search
     **/
    const handleReset = (clearFilters: any) => {
      clearFilters()
      searchText.value = ''
    }

    /**
     * Get table scroll y value
     **/
    const onResize = () => {
      const offset = props.config.allocationVisible
        ? TABLE_HEADER_HEIGHT + TABLE_PAGNATION_HEIGHT
        : TABLE_HEADER_HEIGHT
      const y = tableScrollY(variableTableWrapper.value, offset)
      if (scrollY.value !== y) {
        scrollY.value = y
      }
    }

    /**
     * Update selected variable's parent and child
     **/
    const updateRelations = () => {
      return (variables as any).value.map((variable: any) =>
        includes(variable, (parents as any).value)
          ? VariableRelation.PARENT
          : includes(variable, (children as any).value)
          ? VariableRelation.CHILD
          : props.selectedVariable?.id === variable.id
          ? VariableRelation.SELECTED
          : VariableRelation.NONE
      )
    }

    const relations = ref(updateRelations())

    watch(
      () => props.network,
      (): void => {
        relations.value = updateRelations()
      }
    )

    watch(
      () => props.selectedVariable,
      (): void => {
        relations.value = updateRelations()
      }
    )

    /**
     * Check whether current user assigned or not
     **/
    const isAssigned = (user: User, variable: Variable) => {
      if (user) {
        const allocation: any = find(allocations.value, {
          [DB_FIELDS.ALLOCATION.USER_ID]: user.id
        })
        const assignments = allocation?.assignments
        return (
          find(assignments, {
            [DB_FIELDS.ALLOCATION.ASSIGNMENTS_VARIABLE_ID]: variable?.id
          })?.assigned || false
        )
      }
    }

    /**
     * Handle click allocation
     * @param user
     * @param variable
     **/
    const handleClickAllocation = async (user: User, variable: Variable) => {
      const allocation = await allocateUserToVariable(
        user,
        variable,
        variables.value,
        allocations.value
      )
      context.emit(EMIT_EVENTS.VARIABLE.SELECT_ALLOCATION, user, variable, allocation)
    }

    const handleBulkAllocation = async () => {
      const userName = userSearchInput.value
      const regex = variableRegexInput.value
      if (isEmpty(regex) || isEmpty(userName)) {
        return
      }
      const user = userMapByName.value[userName]
      if (!user) {
        return
      }
      const candidateVariables: any[] = []
      variables.value.forEach((variable: Variable) => {
        if (variable.key.match(regex)) {
          candidateVariables.push(variable)
        }
      })
      candidateVariables.forEach(async (variable: Variable) => {
        await handleClickAllocation(user, variable)
      })
    }

    /**
     * Handle search user
     * @param input
     */
    const handleUserSearch = async (input: string) => {
      await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
        params: { [DB_FIELDS.USER.USERNAME]: input.trim() }
      })
    }

    /**
     * Handle change page size
     **/
    const onPageChange = async (page: number) => {
      if (props.config.allocationVisible) {
        currentPage.value = page
        await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
          params: { page: currentPage.value - 1, size: API_DEFAULT_PAGEABLE_PARAMS.size }
        })
      }
    }

    const toggleCain = ({ variable, method }: { variable: Variable; method: CPTMethod }) => {
      if (!props.currentSurvey) {
        return
      }
      collectionMethodMap[variable.id] = method
      context.emit(EMIT_EVENTS.SURVEY.VARIABLE_TOGGLE_CAIN, { variable, method })
    }

    const toggleOutput = ({ variable, isOutput }: { variable: Variable; isOutput: boolean }) => {
      if (!props.currentSurvey) {
        return
      }
      if (isOutput) {
        outputMap[variable.key] = true
      } else {
        delete outputMap[variable.key]
      }
      context.emit(EMIT_EVENTS.SURVEY.VARIABLE_TOGGLE_OUTPUT, {
        variable,
        isOutput
      })
    }

    const editVariable = (variable: Variable) => {
      console.log('editVariable')
      editedVariable.value = variable
      variableEditorVisible.value = true
    }

    const variableEditorOk = () => {
      variableEditorVisible.value = false
    }

    const nodeChange = (nodeDefinition: number[]) => {
      if (editedVariable.value) {
        editedVariable.value.nodeDefinition = nodeDefinition
      }
    }

    onBeforeMount(() => {
      window.addEventListener('resize', onResize)
    })

    onMounted(() => {
      onResize()
    })

    onUpdated(() => {
      // onResize()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      toggleOutput,
      collectionMethodMap,
      nodeChange,
      EMIT_EVENTS,
      COLUMNS,
      API_DEFAULT_PAGEABLE_PARAMS,
      userMap,
      relations,
      variables,
      data,
      columns,
      parents,
      scrollY,
      variableEditorOk,
      variableTableWrapper,
      searchText,
      tableSearchInput,
      searchedColumn,
      currentPage,
      userSearchInput,
      totalUsers,
      toggleCain,
      handleSearch,
      handleReset,
      VAR_EVENTS,
      isAssigned,
      editedVariable,
      variableEditorVisible,
      EDIT_EVENTS,
      editVariable,
      variableRegexInput,
      handleBulkAllocation,
      outputMap,
      handleClickAllocation,
      selectVariable: (variable: Variable) => {
        context.emit(EMIT_EVENTS.VARIABLE.SELECT, variable)
      },
      variableChange: (variable: Variable) => {
        context.emit(EMIT_EVENTS.VARIABLE.CHANGE, variable)
      },
      handleUserSearch,
      onPageChange,
      showKey
    }
  }
})
