import { last, repeat } from 'ramda'

import { Network, State, Variable } from '@/libs/bayes'
import { Dict } from '@/libs/common'

export const DEFAULT_UTILITY_VECTOR: Record<number, number[]> = {
  2: [0, 100],
  3: [0, 10, 90],
  4: [0, 10, 10, 80],
  5: [0, 7.5, 7.5, 15, 70]
}

export const ROW_TYPE = {
  LABEL: 'LABEL',
  ACTIONS: 'ACTIONS',
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT',
  CRITERIA: 'CRITERIA'
}

export const makeDefaultUtilityVector = (stateLength: number): number[] => {
  if (stateLength in DEFAULT_UTILITY_VECTOR) {
    return DEFAULT_UTILITY_VECTOR[stateLength]
  }
  const vector = repeat(0, stateLength)
  vector[stateLength - 1] = 1
  return vector
}

export const normalizeOutcomes = (outcomes: number[], utilityVector: number[]): number => {
  return outcomes.reduce((acc, outcome, index) => {
    return acc + outcome * utilityVector[index]
  }, 0)
}

export const findStateIndex = (states: State[], stateName: string): number => {
  for (let i = 0; i < states.length; i++) {
    if (states[i].name === stateName) {
      return i
    }
  }
  return states.length - 1
}

export const findMaxProbIndex = (probs: number[]): number => {
  return probs.reduce((maxIndex, currentValue, index, arr) => {
    return currentValue > arr[maxIndex] ? index : maxIndex
  }, 0)
}

export const makeStateOptions = (variable: Variable): any[] =>
  [
    {
      value: 'auto',
      label: 'AUTO'
    }
  ].concat(
    variable?.getAllStates().map(({ state: { name } }) => ({
      value: name,
      label: name
    })) || []
  )

export const stateToValues = (variable: Variable, state_: string): number[] => {
  const values: number[] = []
  variable.getAllStates().forEach(({ state }) => {
    if (state.name === state_) {
      values.push(1)
    } else {
      values.push(0)
    }
  })
  return values
}

export type ChartData = number[][] // [netIndex][variableIndex]

export type NodeSelection = {
  key: string
  name: string
  isInput: boolean
  isOutput: boolean
  variable?: Variable
  utilityVector: number[]
  isDeterministic?: boolean
  stateOptions?: any[]
  state?: string
  states?: string[]
  constraint?: number
  variation?: number
  variations?: number[]
  variationsText?: string
  networkId?: string
}

export enum InputType {
  STATE = 0,
  PROBABILITY = 1
}

export type InputNode = {
  networkKey?: string
  key: string
  state?: string
  value?: number
  values?: number[]
  variation?: number
  variations?: number[]
  type: InputType
}

export type OutputNode = {
  networkKey?: string
  key: string
  utilityVector: number[]
  constraint?: number
  outcomes: number[]
  value: number
}

export type AnalysisTask = {
  id: string
  name: string
  networkId: string
  networkKey?: string
  networkIndex?: number
  status: string
  startedAt?: number
  isPersisted?: boolean
  inputNodes: InputNode[]
  outputNodes: OutputNode[]
  result?: any
  config?: any
  isSelected?: boolean
}

export const DEFAULT_VARIATIONS = [-100, -80, -60, -40, -20, 20, 40, 60, 80, 100]
export const DEFAULT_VARIATIONS_RAW = `[${DEFAULT_VARIATIONS.join(';')}]`

export const getVariable = (networks: Network[], key: string): Variable | undefined => {
  for (let i = networks.length - 1; i >= 0; i--) {
    const variable = networks[i].variableMapByKey[key]
    if (variable) {
      return variable
    }
  }
}

export const resetNodeSelection = (nodeSelection: NodeSelection): NodeSelection => {
  const stateOptions = nodeSelection.stateOptions || []
  nodeSelection.isInput = false
  nodeSelection.isOutput = false
  nodeSelection.states = []
  nodeSelection.state = last(stateOptions)?.value || null
  nodeSelection.variationsText = DEFAULT_VARIATIONS.join(',')
  nodeSelection.variations = DEFAULT_VARIATIONS
  nodeSelection.variation = 20
  nodeSelection.utilityVector = makeDefaultUtilityVector(stateOptions?.length)
  return nodeSelection
}

export const getInputOuputNodes = (network: Network, nodeSelections: NodeSelection[]): any => {
  const inputNodes: InputNode[] = []
  const outputNodes: OutputNode[] = []
  nodeSelections.forEach((node: NodeSelection) => {
    const { isInput, isOutput, key, constraint, variation, variations } = node
    let state = node.state
    const variable = network.variableMapByKey[key]
    if (!variable) {
      return
    }
    if (!state || state === 'auto') {
      state = last(variable.getAllStates())?.state.name || ''
    }
    if (isInput) {
      inputNodes.push({
        key,
        state,
        value: constraint,
        variation,
        variations,
        type: variable.isDeterministic() ? InputType.STATE : InputType.PROBABILITY
      })
    }
    if (isOutput) {
      outputNodes.push({
        key,
        constraint,
        utilityVector: node.utilityVector,
        outcomes: [],
        value: 0
      })
    }
  })
  return {
    inputNodes,
    outputNodes
  }
}

// deprecated
export type Selection = {
  key: string
  name: string
  isInput: boolean
  isOutput: boolean
  utilityVector: number[]
  isDeterministic?: boolean
  stateOptions?: any[]
  state?: string | undefined
  states?: string[]
  stat?: string
  variationsText?: string
  variations?: number[]
  variationStep?: number
}

export const parseNumberArray = (text: string): { sanitized?: string; numbers?: number[] } => {
  try {
    const sanitized =
      text
        ?.match(/([-0-9.;\s]+)/)?.[0]
        .replaceAll(' ', '')
        .replaceAll(';', ',') || ''
    return {
      sanitized,
      numbers: sanitized.split(',')?.map((x) => parseFloat(x.trim()))
    }
  } catch (e) {
    // pass
  }
  return {}
}

export const parseArray = (text: string): { sanitized?: string; items: string[] } => {
  try {
    const sanitized =
      text
        ?.match(/\[(.+)\]/)?.[1]
        ?.replaceAll(' ', '')
        ?.replaceAll(';', ',') || ''
    return {
      sanitized,
      items: sanitized.split(',')?.map((x) => x.trim())
    }
  } catch (e) {
    // pass
  }
  return {
    items: []
  }
}

export const parseSelectionRaw = (
  records: NodeSelection[],
  rawValue: string,
  cleanExisting = false
): void => {
  /* 
  - variable name, e.g. fp1
  - input/output, e.g. 'input' or 'output'
  - state, e.g. Effective
  - variation/constraint, e.g. 20 
  - variations/utility vector [-0.2;0.2] or [0; 20; 80]
  */
  const rows = rawValue.split(/\r?\n/)
  const rowMap: Dict = {}
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const cols = row.split(/\s*,\s*/)
    const key = cols[0]
    let isInput
    let isOutput
    if (cols[1] === 'input') {
      isInput = true
      isOutput = false
    } else {
      isInput = false
      isOutput = true
    }
    const state = cols[2] || null
    const variationOrConstraint = (cols[3] && parseFloat(cols[3])) || null
    let variationsText = null
    let variationsOrUtilityVector = null

    let candidateVariations = cols[4] || ''
    if (!candidateVariations.length) {
      candidateVariations = DEFAULT_VARIATIONS_RAW
    }
    const { sanitized, numbers } = parseNumberArray(candidateVariations)
    if (!sanitized || !numbers) {
      continue
    }
    variationsText = sanitized
    variationsOrUtilityVector = numbers
    rowMap[key] = {
      isInput,
      isOutput,
      state,
      variationOrConstraint,
      variationsText,
      variationsOrUtilityVector
    }
  }
  records?.forEach((record: NodeSelection) => {
    const { key, isDeterministic } = record
    if (key in rowMap) {
      const {
        isInput,
        isOutput,
        state,
        variationOrConstraint,
        variationsText,
        variationsOrUtilityVector
      } = rowMap[key]
      record.isInput = isInput
      record.isOutput = isOutput
      if (state) {
        record.state = state
      }
      if (variationOrConstraint) {
        if (isOutput) {
          record.constraint = variationOrConstraint
        } else if (!isDeterministic) {
          record.variation = variationOrConstraint
        }
      }
      if (variationsOrUtilityVector) {
        if (isOutput) {
          record.utilityVector = variationsOrUtilityVector
        } else if (!isDeterministic) {
          record.variationsText = variationsText
          record.variations = variationsOrUtilityVector
        }
      }
    } else if (cleanExisting) {
      resetNodeSelection(record)
    }
  })
}
