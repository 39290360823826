<template>
  <div
    v-if="showAux"
    class="sz-var-states"
    style="overflow: auto hidden; max-width: calc(100% - 20px)"
  >
    <draggable
      v-model="states"
      item-key="id"
      @start="drag = true"
      @end="drag = false"
      @change="statesChange"
    >
      <template #item="{ element, index }">
        <StateSymbol :state="element" :index="index" />
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import StateSymbol from '@/components/symbols/StateSymbol.vue'
import { EMIT_EVENTS } from '@/constants/emits'

export default defineComponent({
  components: { draggable, StateSymbol },
  props: {
    variable: { type: Object, required: true },
    showAux: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false }
  },
  emits: [EMIT_EVENTS.VARIABLE.CHANGE],
  setup(props, { emit }) {
    const drag = ref(false)
    const states = ref(props.variable.states || [])

    /**
     * Handle state change
     */
    const statesChange = () => {
      if (props.readOnly) {
        return
      }
      props.variable.setStates(states.value)
      states.value = props.variable.states
      emit(EMIT_EVENTS.VARIABLE.CHANGE, props.variable)
    }

    watch(
      () => props.variable,
      () => {
        if (props.variable) {
          states.value = props.variable.states
        }
      }
    )
    return { drag, states, statesChange }
  }
})
</script>

<style scoped lang="stylus">
.sz-var-states
  &::-webkit-scrollbar
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
    // need this due to antd min-width on .ant-table-header
    min-width: 8px !important;
  &::-webkit-scrollbar-track
    background-color: transparent
  &::-webkit-scrollbar-thumb
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0);
</style>
