import { findIndex } from 'lodash-es'
import { MutationTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { SurveyMutationEnum } from '@/store/enums/mutations/survey'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { SurveyMutation } from '@/store/types/mutations/survey'
import { SurveyState } from '@/store/types/states/survey'
import { SurveySchema } from '@/types'
import { SurveyList } from '@/types'

/**
 * Mutation function
 */
export const mutations: MutationTree<SurveyState> & SurveyMutation = {
  // Get surveys
  [SurveyMutationEnum.GET_SURVEYS](state: SurveyState) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_SURVEY
    state[SurveyStateEnum.IS_GETTING] = true
  },
  [SurveyMutationEnum.GET_SURVEYS_FULFILLED](state: SurveyState, payload: SurveyList) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_GETTING] = false
    state[SurveyStateEnum.SURVEY_LIST] = payload
  },
  [SurveyMutationEnum.GET_SURVEYS_REJECTED](state: SurveyState, error: Error) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_GETTING] = false
    state[SurveyStateEnum.ERROR] = error
  },
  // Get survey
  [SurveyMutationEnum.GET_SURVEY](state: SurveyState) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.GETTING_SURVEY
    state[SurveyStateEnum.IS_GETTING] = true
  },
  [SurveyMutationEnum.GET_SURVEY_FULFILLED](state: SurveyState, payload: SurveySchema) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_GETTING] = false
    state[SurveyStateEnum.CURRENT_SURVEY] = payload
  },
  [SurveyMutationEnum.GET_SURVEY_REJECTED](state: SurveyState, error: Error) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_GETTING] = false
    state[SurveyStateEnum.ERROR] = error
  },
  // Create survey
  [SurveyMutationEnum.CREATE_SURVEY](state: SurveyState) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.CREATING_SURVEY
    state[SurveyStateEnum.IS_CREATING] = true
  },
  [SurveyMutationEnum.CREATE_SURVEY_FULFILLED](state: SurveyState, payload: SurveySchema) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_CREATING] = false
    // state[SurveyStateEnum.CURRENT_SURVEY] = payload
    const newContent = [...state[SurveyStateEnum.SURVEY_LIST]?.content, payload]
    state[SurveyStateEnum.SURVEY_LIST] = {
      ...state[SurveyStateEnum.SURVEY_LIST],
      content: newContent
    }
  },
  [SurveyMutationEnum.CREATE_SURVEY_REJECTED](state: SurveyState, error: Error) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_CREATING] = false
    state[SurveyStateEnum.ERROR] = error
  },
  // Update survey
  [SurveyMutationEnum.UPDATE_SURVEY](state: SurveyState) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.UPDATING_SURVEY
    state[SurveyStateEnum.IS_UPDATING] = true
  },
  [SurveyMutationEnum.UPDATE_SURVEY_FULFILLED](state: SurveyState, survey: SurveySchema) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    const updatedId = survey?.id
    const index = findIndex(state[SurveyStateEnum.SURVEY_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: updatedId
    })
    state[SurveyStateEnum.IS_UPDATING] = false
    state[SurveyStateEnum.CURRENT_SURVEY] = survey
    if (index !== -1) {
      const newContent = [
        ...state[SurveyStateEnum.SURVEY_LIST]?.content.slice(0, index),
        survey,
        ...state[SurveyStateEnum.SURVEY_LIST]?.content.slice(index + 1)
      ]
      state[SurveyStateEnum.SURVEY_LIST] = {
        ...state[SurveyStateEnum.SURVEY_LIST],
        content: newContent
      }
    }
  },
  [SurveyMutationEnum.UPDATE_SURVEY_REJECTED](state: SurveyState, error: Error) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_UPDATING] = false
    state[SurveyStateEnum.ERROR] = error
  },
  // Delete survey
  [SurveyMutationEnum.DELETE_SURVEY](state: SurveyState) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.DELETING_SURVEY
    state[SurveyStateEnum.IS_DELETING] = true
  },
  [SurveyMutationEnum.DELETE_SURVEY_FULFILLED](state: SurveyState, id: SurveySchema['id']) {
    const index = findIndex(state[SurveyStateEnum.SURVEY_LIST]?.content, {
      [DB_FIELDS.COMMON.ID]: id
    })
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_DELETING] = false
    state[SurveyStateEnum.CURRENT_SURVEY] = null
    if (index !== -1) {
      const newContent = [
        ...state[SurveyStateEnum.SURVEY_LIST]?.content.slice(0, index),
        ...state[SurveyStateEnum.SURVEY_LIST]?.content.slice(index + 1)
      ]
      state[SurveyStateEnum.SURVEY_LIST] = {
        ...state[SurveyStateEnum.SURVEY_LIST],
        content: newContent
      }
    }
  },
  [SurveyMutationEnum.DELETE_SURVEY_REJECTED](state: SurveyState, error: Error) {
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.IS_DELETING] = false
    state[SurveyStateEnum.ERROR] = error
  },
  [SurveyMutationEnum.SET_CURRENT_SURVEY](state: SurveyState, payload: SurveySchema | null) {
    state[SurveyStateEnum.CURRENT_SURVEY] = payload
  },
  // Export survey
  [SurveyMutationEnum.EXPORT_SURVEY](state: SurveyState) {
    state[SurveyStateEnum.IS_EXPORTING] = true
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = MESSAGE.EXPORTING_SURVEY
  },
  [SurveyMutationEnum.EXPORT_SURVEY_FULFILLED](state: SurveyState) {
    state[SurveyStateEnum.IS_EXPORTING] = false
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
  },
  [SurveyMutationEnum.EXPORT_SURVEY_REJECTED](state: SurveyState, error: Error) {
    state[SurveyStateEnum.IS_EXPORTING] = false
    state[SurveyStateEnum.LOADING_PROMPT_TEXT] = null
    state[SurveyStateEnum.ERROR] = error
  }
}
