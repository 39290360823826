<template>
  <div class="sz-var-output-cell">
    <a-checkbox v-model:checked="checked" :disabled="readOnly" @change="toggleOutput" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

import { EVENTS } from '@/constants/emits'
import { Variable } from '@/libs/bayes'

/**
 * Toggle for isOutput
 */
export default defineComponent({
  props: {
    variable: { type: Variable, required: true },
    outputMap: { type: Object, default: () => ({}) },
    readOnly: { type: Boolean, default: false }
  },
  emits: [EVENTS.SURVEY.VARIABLE_TOGGLE_OUTPUT],
  setup(props, { emit }) {
    const checked = ref(!!props.outputMap[props.variable.key])

    const toggleOutput = () => {
      emit(EVENTS.SURVEY.VARIABLE_TOGGLE_OUTPUT, {
        variable: props.variable,
        isOutput: checked.value
      })
    }

    watch(
      () => props.variable,
      () => {
        checked.value = !!props.outputMap[props.variable.key]
      }
    )

    return {
      checked,
      toggleOutput
    }
  }
})
</script>
