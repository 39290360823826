// Template variables for survey config
const SURVEY_TEMPLATE_VARIABLES: Record<string, any> = {
  SELECTED: 'selected',
  PARENT: 'parent',
  SELECTED_STATE: 'selectedState'
}

const DEFAULT_PROFILE_PHOTO_URL = '/avatars/default.png'

// Data available in current network
const NETWORK_EXISTED_RECORD_TEXT = {
  ALLOCATION: 'allocation',
  CPT: 'CPT',
  SURVEY: 'survey',
  NETWORK: 'network'
}

export { SURVEY_TEMPLATE_VARIABLES, DEFAULT_PROFILE_PHOTO_URL, NETWORK_EXISTED_RECORD_TEXT }
