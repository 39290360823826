<template>
  <div>
    <slot></slot>
    <a-modal
      :visible="paletteVisible"
      class="sza-palette-picker"
      width="60vw"
      @ok="selectPalette"
      @cancel="cancel"
    >
      <PalettePicker v-model="selectedPaletteName" />
    </a-modal>
    <div v-if="isLoading" class="sz-page-spinner">
      <a-spin :spinning="isLoading" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, watch } from 'vue'

import PalettePicker from '@/components/common/PalettePicker.vue'

export const EVENTS = {
  COLOR_CHANGED: 'COLOR_CHANGED',
  PALETTE_TOGGLE: 'update:paletteVisible'
}

export default defineComponent({
  components: {
    PalettePicker
  },
  props: {
    paletteVisible: { type: Boolean, required: true },
    paletteName: { type: String, required: true },
    isLoading: { type: Boolean, required: true }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const visible: Ref<boolean> = ref(props.paletteVisible)
    const selectedPaletteName: Ref<string> = ref(props.paletteName)
    const selectPalette = () => {
      emit(EVENTS.PALETTE_TOGGLE, false)
      emit(EVENTS.COLOR_CHANGED, selectedPaletteName.value)
    }

    const cancel = () => {
      emit(EVENTS.PALETTE_TOGGLE, false)
    }

    watch(
      () => props.paletteName,
      (newVal) => {
        selectedPaletteName.value = newVal
      }
    )

    watch(
      () => props.paletteVisible,
      (newVal) => {
        visible.value = newVal
      }
    )

    return {
      cancel,
      visible,
      selectedPaletteName,
      selectPalette
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/base.styl"
@import "../../../styles/commons.styl"
.sz-page-spinner
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  padding: 0
  margin: 0
  background-color: rgba(255, 255, 255, 0.5)
  > div
    @extend .centered
    margin auto
</style>
