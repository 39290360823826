export enum WorkspaceStateEnum {
  IS_CREATING = 'isCreating',
  IS_UPDATING = 'isUpdating',
  IS_DELETING = 'isDeleting',
  IS_GETTING = 'isGetting',
  WORKSPACES = 'workspaces',
  CURRENT_WORKSPACE = 'currentWorkspace',
  ERROR = 'error',
  LOADING_PROMPT_TEXT = 'loadingPromptText'
}
