import { render } from "./OptionDesignWorkspace.vue?vue&type=template&id=ff63e174"
import script from "./OptionDesignWorkspace.vue?vue&type=script&lang=ts"
export * from "./OptionDesignWorkspace.vue?vue&type=script&lang=ts"

import "./OptionDesignWorkspace.vue?vue&type=style&index=0&id=ff63e174&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "ff63e174"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ff63e174', script)) {
    api.reload('ff63e174', script)
  }
  
  module.hot.accept("./OptionDesignWorkspace.vue?vue&type=template&id=ff63e174", () => {
    api.rerender('ff63e174', render)
  })

}

script.__file = "src/components/analysis/option-design/OptionDesignWorkspace.vue"

export default script