import { GetterTree } from 'vuex'

import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { AuthGetterEnum } from '@/store/enums/getters/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { RootState } from '@/store/types'
import { AuthState } from '@/store/types/states/auth'

export const getters: GetterTree<AuthState, RootState> = {
  [AuthGetterEnum.IS_ADMIN]: (state: AuthState) => {
    const currentUser = state[AuthStateEnum.USER]
    const roles = currentUser?.[DB_FIELDS.USER.ROLES]
    return roles && roles.includes(DB_ENUM_VALUES.USER.ROLES.ADMIN)
  },
  [AuthGetterEnum.HAS_PRIVILEGE]: (state: AuthState) => {
    const currentUser = state[AuthStateEnum.USER]
    const roles = currentUser?.[DB_FIELDS.USER.ROLES]
    return (
      roles &&
      (roles.includes(DB_ENUM_VALUES.USER.ROLES.ADMIN) ||
        roles.includes(DB_ENUM_VALUES.USER.ROLES.DESIGNER))
    )
  }
}
