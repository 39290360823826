import { message } from 'ant-design-vue'
import {
  createRouter,
  createWebHashHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router'

import HybridWorkspace from '@/components/analysis/hybrid/HybridWorkspaceNext.vue'
// import CauseEffectWorkspace from '@/components/analysis/cause-effect/CauseEffectWorkspace.vue'
import OptionDesignWorkspace from '@/components/analysis/option-design/OptionDesignWorkspace.vue'
import SensitivityWorkspace from '@/components/analysis/sensitivity/SensitivityWorkspace.vue'
import WhatIfWorkspace from '@/components/analysis/whatif/WhatIfWorkspace.vue'
import AnalysisWorkspace from '@/components/analysis/workspace/AnalysisWorkspace.vue'
import AnalysisWorkspaceList from '@/components/analysis/workspace/AnalysisWorkspaceList.vue'
import SurveyDialog from '@/components/survey/SurveyDialog.vue'
import SurveyList from '@/components/survey/SurveyList.vue'
// import PageView from '@/views/Page.vue'
import SurveyPage from '@/components/survey/SurveyPage.vue'
import WorkSpaceList from '@/components/workspace/WorkspaceList.vue'
import { MESSAGE } from '@/constants/message'
import { PATH, ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { AuthGetterEnum } from '@/store/enums/getters/auth'
import { vuexActions, vuexGetters } from '@/store/util'

const AccountInfoView = () => import('@/views/AccountInfoView.vue')
const LoginView = () => import('@/views/LoginView.vue')
const NetworkView = () => import('@/views/NetworkView.vue')
const AllocationView = () => import('@/views/AllocationView.vue')
const AllocationNewView = () => import('@/views/AllocationNewView.vue')
const BuilderView = () => import('@/views/BuilderView.vue')
const GoQuestionnaireView = () => import('@/views/GoQuestionnaireView.vue')
const QuestionnaireView = () => import('@/views/QuestionnaireView.vue')
const SurveyViews = () => import('@/views/SurveyViews.vue')
const NetworkVariablesView = () => import('@/views/NetworkVariablesView.vue')
const SurveyStatusView = () => import('@/views/SurveyStatusListView.vue')
const SurveyAnalyticsView = () => import('@/views/SurveyAnalyticsView.vue')
const SurveyStochasticsView = () => import('@/views/SurveyStochasticsView.vue')
const UsersView = () => import('@/views/UserViews.vue')
const WorkspaceViews = () => import('@/views/WorkspaceViews.vue')
const AnalysisViews = () => import('@/views/AnalysisViews.vue')
const CauseEffectAnalysisView = () => import('@/views/CauseEffectAnalysisView.vue')
const ManualView = () => import('@/views/ManualView.vue')
const GoAhpView = () => import('@/views/GoAhpView.vue')
const AhpView = () => import('@/views/AhpView.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.HOME,
    name: ROUTE_NAME.HOME,
    redirect: PATH.WORKSPACES,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: PATH.LOGIN,
    name: ROUTE_NAME.LOGIN,
    component: LoginView,
    beforeEnter: async (to, from, next) => {
      const store = useStore()
      const isAuthorized = await store.dispatch(
        vuexActions(ModuleNames.AUTH, AuthActionEnum.CHECK_AUTH_STATE),
        undefined
      )
      if (!isAuthorized) {
        next()
      } else {
        next(PATH.HOME)
      }
    }
  },
  {
    path: PATH.USERS,
    name: ROUTE_NAME.USERS,
    component: UsersView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: PATH.ACCOUNT,
    name: ROUTE_NAME.ACCOUNT,
    component: AccountInfoView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: PATH.MANUAL,
    name: ROUTE_NAME.MANUAL,
    component: ManualView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: PATH.ANALYSIS,
    component: AnalysisViews,
    children: [
      {
        path: PATH.ANALYSIS_WORKSPACES,
        name: ROUTE_NAME.ANALYSIS_WORKSPACES,
        component: AnalysisWorkspaceList
      },
      {
        path: PATH.ANALYSIS_WORKSPACE,
        name: ROUTE_NAME.ANALYSIS_WORKSPACE,
        component: AnalysisWorkspace
      },
      {
        path: PATH.ANALYSIS_CAUSEEFFECT,
        name: ROUTE_NAME.ANALYSIS_CAUSEEFFECT,
        component: CauseEffectAnalysisView
      },
      {
        path: PATH.ANALYSIS_HYBRID,
        name: ROUTE_NAME.ANALYSIS_HYBRID,
        component: HybridWorkspace
      },
      {
        path: PATH.ANALYSIS_WHATIF,
        name: ROUTE_NAME.ANALYSIS_WHATIF,
        component: WhatIfWorkspace
      },
      {
        path: PATH.ANALYSIS_SENSITIVITY,
        name: ROUTE_NAME.ANALYSIS_SENSITIVITY,
        component: SensitivityWorkspace
      },
      {
        path: PATH.ANALYSIS_OPTION_DESIGN,
        name: ROUTE_NAME.ANALYSIS_OPTION_DESIGN,
        component: OptionDesignWorkspace
      }
    ],
    meta: {
      requiresAuth: true,
      requiresPrivilege: true
    }
  },
  {
    path: PATH.WORKSPACES,
    component: WorkspaceViews,
    children: [
      { path: PATH.WORKSPACES, name: ROUTE_NAME.WORKSPACES, component: WorkSpaceList },
      { path: PATH.WORKSPACE, name: ROUTE_NAME.WORKSPACE, component: WorkSpaceList },
      {
        path: PATH.BUILDER,
        name: ROUTE_NAME.BUILDER,
        component: BuilderView,
        meta: {
          requiresAuth: true,
          requiresPrivilege: true
        }
      },
      {
        path: PATH.NETWORK,
        name: ROUTE_NAME.NETWORK,
        component: NetworkView
      },
      {
        path: PATH.NETWORK_VARIABLES,
        name: ROUTE_NAME.NETWORK_VARIABLES,
        component: NetworkVariablesView
      },
      {
        path: PATH.ALLOCATION_DEPRECATED,
        name: ROUTE_NAME.ALLOCATION_DEPRECATED,
        component: AllocationView
      },
      {
        path: PATH.ALLOCATION,
        name: ROUTE_NAME.ALLOCATION,
        component: AllocationNewView
      },
      // {
      //   path: PATH.PAGE,
      //   name: ROUTE_NAME.PAGE,
      //   component: PageView
      // },
      {
        path: PATH.SURVEY_STATUSES,
        name: ROUTE_NAME.SURVEY_STATUSES,
        component: SurveyStatusView
      },
      {
        path: PATH.SURVEY_ANALYTICS,
        name: ROUTE_NAME.SURVEY_ANALYTICS,
        component: SurveyAnalyticsView
      },
      {
        path: PATH.SURVEY_STOCHASTICS,
        name: ROUTE_NAME.SURVEY_STOCHASTICS,
        component: SurveyStochasticsView
      },
      {
        path: PATH.SURVEYS,
        component: SurveyViews,
        children: [
          { path: PATH.SURVEYS, name: ROUTE_NAME.SURVEYS, component: SurveyList },
          {
            path: PATH.SURVEYS_NEW,
            name: ROUTE_NAME.SURVEYS_NEW,
            component: SurveyDialog
          },
          {
            path: PATH.SURVEY,
            name: ROUTE_NAME.SURVEY,
            component: SurveyPage
          }
        ]
      }
    ],
    meta: {
      requiresAuth: true,
      requiresPrivilege: true
    }
  },
  {
    path: PATH.GO_QUESTIONNAIRE,
    name: ROUTE_NAME.GO_QUESTIONNAIRE,
    component: GoQuestionnaireView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: PATH.QUESTIONNAIRE,
    name: ROUTE_NAME.QUESTIONNAIRE,
    component: QuestionnaireView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: PATH.GO_AHP,
    name: ROUTE_NAME.GO_AHP,
    component: GoAhpView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: PATH.AHP,
    name: ROUTE_NAME.AHP,
    component: AhpView,
    meta: {
      requiresAuth: true
    }
  },
  // Otherwise redirect to home
  { path: '/:catchAll(.*)', redirect: PATH.HOME }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/**
 * Router navigation guard beforeEach
 * @param {RouteLocationNormalized} to
 * @param {RouteLocationNormalized} from
 * @param {NavigationGuardNext} next
 */
router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const store = useStore()
    const { requiresAuth, requiresAdmin, requiresPrivilege } = to.meta
    if (!requiresAuth) {
      next()
      return
    }

    if (to.name === ROUTE_NAME.SURVEY) {
      const { surveyKey } = to.query
      if (surveyKey && typeof surveyKey === 'string') {
        next()
        return
      }
    }

    const isAuthenticated = await store.dispatch(
      vuexActions(ModuleNames.AUTH, AuthActionEnum.CHECK_AUTH_STATE),
      undefined
    )
    // redirect to login page if current user is not authenticated
    if (!isAuthenticated) {
      next({
        name: ROUTE_NAME.LOGIN,
        query: {
          redirectTo: to.fullPath
        }
      })
      return
    }
    const isAdmin = store.getters[vuexGetters(ModuleNames.AUTH, AuthGetterEnum.IS_ADMIN)]
    const hasPrivilege = store.getters[vuexGetters(ModuleNames.AUTH, AuthGetterEnum.HAS_PRIVILEGE)]
    // pop out error message if current user is not admin
    if (requiresAdmin) {
      if (!isAdmin) {
        message.error(MESSAGE.ROUTE_ACCESS_NOT_ALLOWED)
        await store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.LOG_OUT), undefined)
        next({
          name: ROUTE_NAME.LOGIN,
          query: {
            redirectTo: to.fullPath
          }
        })
        return
      }
    }
    if (requiresPrivilege) {
      if (!hasPrivilege) {
        message.error(MESSAGE.ROUTE_ACCESS_NOT_ALLOWED)
        next({
          name: ROUTE_NAME.ACCOUNT
        })
        return
      }
    }
    // Currently only admin can go to home (redirected to workspaces)
    // Users can go to account info
    // At this point user is authenticated
    if (to.name === ROUTE_NAME.HOME && !hasPrivilege) {
      next({
        name: ROUTE_NAME.ACCOUNT
      })
    }
    // go to target page
    next()
  }
)

export default router
