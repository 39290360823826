/**
 * Mutation types
 */
export enum NetworkMutationEnum {
  IMPORT_NETWORK = 'IMPORT_NETWORK',
  IMPORT_NETWORK_FULFILLED = 'IMPORT_NETWORK_FULFILLED',
  IMPORT_NETWORK_REJECTED = 'IMPORT_NETWORK_REJECTED',

  GET_NETWORK = 'GET_NETWORK',
  GET_NETWORK_FULFILLED = 'GET_NETWORK_FULFILLED',
  GET_NETWORK_REJECTED = 'GET_NETWORK_REJECTED',

  GET_NETWORKS = 'GET_NETWORKS',
  GET_NETWORKS_FULFILLED = 'GET_NETWORKS_FULFILLED',
  GET_NETWORKS_REJECTED = 'GET_NETWORKS_REJECTED',

  CREATE_NETWORK = 'CREATE_NETWORK',
  CREATE_NETWORK_FULFILLED = 'CREATE_NETWORK_FULFILLED',
  CREATE_NETWORK_REJECTED = 'CREATE_NETWORK_REJECTED',

  UPDATE_NETWORK = 'UPDATE_NETWORK',
  UPDATE_NETWORK_FULFILLED = 'UPDATE_NETWORK_FULFILLED',
  UPDATE_NETWORK_REJECTED = 'UPDATE_NETWORK_REJECTED',

  DELETE_NETWORK = 'DELETE_NETWORK',
  DELETE_NETWORK_FULFILLED = 'DELETE_NETWORK_FULFILLED',
  DELETE_NETWORK_REJECTED = 'DELETE_NETWORK_REJECTED'
}
