import { GetterTree } from 'vuex'

import { QuestionnaireGetterEnum } from '@/store/enums/getters/questionnaire'
import { QuestionnaireStateEnum } from '@/store/enums/states/questionnaire'
import { RootState } from '@/store/types'
import { QuestionnaireState } from '@/store/types/states/questionnaire'

export const getters: GetterTree<QuestionnaireState, RootState> = {
  [QuestionnaireGetterEnum.CPTS]: (state: QuestionnaireState) => {
    return (
      state[QuestionnaireStateEnum.SURVEY_CPTS]?.content ||
      state[QuestionnaireStateEnum.NETWORK_CPTS]?.content
    )
  },
  [QuestionnaireGetterEnum.RESPONSES]: (state: QuestionnaireState) => {
    return state[QuestionnaireStateEnum.SURVEY_RESPONSES]?.content
  },
  [QuestionnaireGetterEnum.NETWORK]: (state: QuestionnaireState) => {
    return state[QuestionnaireStateEnum.WORKSPACE_NETWORKS]?.content[0] // assume one network per workspace
  },
  [QuestionnaireGetterEnum.ALLOCATION]: (state: QuestionnaireState) => {
    return state[QuestionnaireStateEnum.WORKSPACE_USER_ALLOCATIONS]?.content[0] // assume one allocation per user per workspace
  }
}
