export enum VariableRelation {
  PARENT = 'parent',
  CHILD = 'child',
  SELECTED = 'selected',
  NONE = 'none'
}

export enum StatePolarity {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative'
}

export enum CPTType {
  ELICITED = 'ELICITED',
  CALCULATED = 'CALCULATED'
}
