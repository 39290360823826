/**
 * Mutation types
 */
export enum SurveyStatusMutationEnum {
  GET_SURVEY_STATUS = 'GET_SURVEY_STATUS',
  GET_SURVEY_STATUS_FULFILLED = 'GET_SURVEY_STATUS_FULFILLED',
  GET_SURVEY_STATUS_REJECTED = 'GET_SURVEY_STATUS_REJECTED',

  GET_SURVEY_STATUSES = 'GET_SURVEY_STATUSES',
  GET_SURVEY_STATUSES_FULFILLED = 'GET_SURVEY_STATUSES_FULFILLED',
  GET_SURVEY_STATUSES_REJECTED = 'GET_SURVEY_STATUSES_REJECTED',

  CREATE_SURVEY_STATUS = 'CREATE_SURVEY_STATUS',
  CREATE_SURVEY_STATUS_FULFILLED = 'CREATE_SURVEY_STATUS_FULFILLED',
  CREATE_SURVEY_STATUS_REJECTED = 'CREATE_SURVEY_STATUS_REJECTED',

  UPDATE_SURVEY_STATUS = 'UPDATE_SURVEY_STATUS',
  UPDATE_SURVEY_STATUS_FULFILLED = 'UPDATE_SURVEY_STATUS_FULFILLED',
  UPDATE_SURVEY_STATUS_REJECTED = 'UPDATE_SURVEY_STATUS_REJECTED',

  DELETE_SURVEY_STATUS = 'DELETE_SURVEY_STATUS',
  DELETE_SURVEY_STATUS_FULFILLED = 'DELETE_SURVEY_STATUS_FULFILLED',
  DELETE_SURVEY_STATUS_REJECTED = 'DELETE_SURVEY_STATUS_REJECTED',

  DISTRIBUTE_SURVEY = 'DISTRIBUTE_SURVEY',
  DISTRIBUTE_SURVEY_FULFILLED = 'DISTRIBUTE_SURVEY_FULFILLED',
  DISTRIBUTE_SURVEY_REJECTED = 'DISTRIBUTE_SURVEY_REJECTED'
}
