
import { isNil, max, min } from 'lodash-es'
import { defineComponent, PropType, Ref, ref, watch } from 'vue'

import { NodeSelection } from '../libs/common'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    record: { type: Object as PropType<NodeSelection | undefined>, default: undefined },
    variations: { type: Array as PropType<number[]>, default: () => [-100, -50, 50, 100] }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const start = ref<number>(-100)
    const end = ref<number>(100)
    const step = ref<number>(20)

    const updateRangeStep = () => {
      if (!props.variations) {
        return
      }
      let _start = min(props.variations)
      let _end = max(props.variations)
      if (isNil(_start)) {
        _start = -100
      }
      if (isNil(_end)) {
        _end = 100
      }
      start.value = _start
      end.value = _end
      if (props.variations.length > 1) {
        step.value = props.variations[1] - props.variations[0]
      }
    }

    watch(
      () => props.variations,
      (): void => {
        updateRangeStep()
      }
    )
    updateRangeStep()
    const variationRange = ref<number[]>([start.value, end.value])
    const variationStep: Ref<number> = ref(step.value)
    const variationMarks = ref<Record<number, any>>({
      '-100': '-100',
      100: '100'
    })

    const ok = () => {
      const range = variationRange.value
      const step = variationStep.value
      const variations = []
      // create an array of variation
      let variation = range[0]
      while (variation <= range[1]) {
        if (variation !== 0) {
          variations.push(variation)
        }
        variation += step
      }
      emit(EVENTS.OK, props.record, {
        variations,
        variation: step
      })
    }
    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      variationMarks,
      ok,
      cancel,
      variationRange,
      variationStep
    }
  }
})
