
import { defineComponent, PropType } from 'vue'

export const OVERRIDE = 'override'

export default defineComponent({
  props: {
    data: { type: Array as PropType<Array<number>>, default: () => [] },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 60 }
  },
  emits: [OVERRIDE],
  setup(props, { emit }) {
    const startY = 0
    const lblWidth = 30
    const barHeight = 10
    const barHeightHalf = 5
    const valueY = startY + barHeight + 15
    const labelY = valueY + 12
    const auxY = labelY + 18
    const offset = 20

    let sorted = [...props.data].sort()
    const max = sorted[sorted.length - 1]
    const min = sorted[0]
    const qWidth = sorted.length ? max - min : 0
    let sum = 0
    const items = sorted.map((q, index) => {
      sum += q
      const x = qWidth
        ? Math.round(((q - min) * (props.width - offset * 2)) / qWidth) + offset
        : Math.round((index * (props.width - offset * 2)) / 2) + offset
      const value = q.toFixed(2)
      return {
        x,
        q,
        value
      }
    })
    const mean = sum / sorted.length

    const pick = (value: number) => {
      emit(OVERRIDE, parseFloat(value.toFixed(2)))
    }

    return {
      barHeightHalf,
      startY,
      labelY,
      auxY,
      valueY,
      mean,
      pick,
      items,
      lblWidth,
      barHeight
    }
  }
})
