import API from '@/axios'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorCatcher,
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import { API_PAGEABLE_PARAMS, SurveySchema, SurveyStatus, SurveyStatusForm } from '@/types'

/**
 * Get survey from server
 * FIXME: return type should be Promise<SurveyStatus> and Promise<Error>?
 * @param id - survey uuid
 */
const getSurveyStatus = async (id: SurveyStatus['id']): Promise<any> => {
  return API.get(ENDPOINTS.SURVEY_STATUS({ id }))
    .then((data) => extractResponse(data))
    .catch((error) => errorCatcher(error))
}

/**
 * Get all surveys from server
 * FIXME: return type should be Promise<SurveyStatus[]> and Promise<Error>?
 */
const getSurveyStatuses = async (options: {
  surveyId: SurveySchema['id']
  params?: API_PAGEABLE_PARAMS & {
    id?: Array<SurveyStatus['id']>
    userId?: Array<SurveyStatus['userId']>
  }
  getAll?: boolean
}): Promise<any> => {
  try {
    const { surveyId } = options
    const getAll = options ? options.getAll : false
    const params = options ? options.params : {}
    if (getAll) {
      const requestParams = Object.assign({}, API_DEFAULT_PAGEABLE_PARAMS, params)
      return recursivePaginationAPIRequestFn(
        API,
        ENDPOINTS.SURVEY_STATUSES({ id: surveyId })
      )(requestParams)
    } else {
      return extractResponse(await API.get(ENDPOINTS.SURVEY_STATUSES({ id: surveyId }), { params }))
    }
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Submit survey data to server
 * FIXME: return type should be Promise<SurveyStatus> and Promise<Error>?
 * @param payload - survey form value
 */
const createSurveyStatus = async (payload: SurveyStatusForm): Promise<any> => {
  try {
    const { surveyId } = payload
    return extractResponse(await API.post(ENDPOINTS.SURVEY_STATUSES({ id: surveyId }), payload))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Update survey data to server
 * FIXME: return type should be Promise<SurveyStatus> and Promise<Error>?
 */
const updateSurveyStatus = async ({
  id,
  surveyStatus
}: {
  id: SurveyStatus['id']
  surveyStatus: Partial<SurveyStatus>
}): Promise<any> => {
  try {
    return extractResponse(await API.put(ENDPOINTS.SURVEY_STATUS({ id }), surveyStatus))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Distribute survey and send email
 */
const distributeSurvey = async ({
  id,
  surveyUrl,
  surveyUrlVariableName,
  userNameVariableName,
  surveyNameVariableName
}: {
  id: SurveyStatus['id']
  surveyUrl: string
  surveyUrlVariableName: string
  userNameVariableName: string
  surveyNameVariableName: string
}): Promise<any> => {
  try {
    return extractResponse(
      await API.post(ENDPOINTS.SURVEY_DISTRIBUTE({ id }), {
        surveyUrl,
        surveyUrlVariableName,
        userNameVariableName,
        surveyNameVariableName
      })
    )
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Delete a survey from server
 * @param id - survey uuid
 */
const deleteSurveyStatus = async (id: SurveyStatus['id']): Promise<any> => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.SURVEY_STATUS({ id: id })))
  } catch (e) {
    errorHandler(e)
  }
}

export {
  createSurveyStatus,
  updateSurveyStatus,
  deleteSurveyStatus,
  getSurveyStatus,
  getSurveyStatuses,
  distributeSurvey
}
