<template>
  <a-modal
    class="sza-raw-import"
    :title="title"
    :visible="isVisible"
    width="1000px"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="container">
      <div>
        <textarea
          ref="textAreaWrapper"
          style="width: 100%; height: 400px"
          :value="rawValue"
          :placeholder="description"
          spellcheck="false"
        />
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    title: { type: String, default: 'Data Import' },
    description: { type: String, default: 'Paste your data here' },
    isVisible: { type: Boolean, default: false }
  },
  setup(props_, { emit }) {
    const rawValue = ref('')
    const textAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (textAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      rawValue.value = getSelectionRaw()
      emit(EVENTS.OK, rawValue.value)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      ok,
      cancel,
      rawValue,
      textAreaWrapper
    }
  }
})
</script>

<style lang="stylus" scoped>
.sza-raw-import
  .container
    width: 100%
    display: flex
    flex-direction: row
    > div
      flex: 1 1 auto;
</style>
