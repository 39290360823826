
import { computed, defineComponent, PropType, Ref, ref, toRefs } from 'vue'

import ApexChartActions, {
  EVENTS as CHART_EVENTS
} from '@/components/analysis/common/chart-actions/ApexChartActions.vue'
import useChartRange from '@/components/analysis/composable/chart-range'
import useMaximizer from '@/components/composables/maximizer-apex'
import { Dict, rounder } from '@/libs/common'

export default defineComponent({
  name: 'WhatIfApexChart',
  components: {
    ApexChartActions
  },
  props: {
    nodeIds: {
      type: Array,
      default: () => ['Level1A', 'Level1B', 'Root']
    },
    data: {
      type: Array,
      default: () => [
        [65, 59, 90],
        [35, 89, 10]
      ]
    },
    chartId: {
      type: String,
      default: 'radar-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    title: {
      default: undefined,
      type: String
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => undefined
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const chartEl: Ref<HTMLElement | null> = ref(null)
    const { min, max } = toRefs(props)
    const { maxBound } = useChartRange(min, max)

    const series = computed(() =>
      props.data.map((r: any, index: number): any => {
        const dataset: Dict = {}
        dataset.data = r.map((x: any) => rounder(parseFloat(x), 4))
        dataset.name = 'task ' + index
        return dataset
      })
    )

    const chartOptions = computed(() => ({
      chart: {
        height: 350,
        type: 'radar',
        zoom: {
          enabled: false
        }
      },
      yaxis: {
        min: 0,
        max: maxBound.value,
        decimalsInFloat: 2,
        forceNiceScale: false
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      markers: {
        size: 0
      },
      tooltip: {
        y: {
          formatter: (val: number) => val.toFixed(4)
        }
      },
      xaxis: {
        categories: props.nodeIds
      },
      legend: {
        position: 'bottom'
      },
      title: {
        text: props.title
      }
    }))

    const { isMaximize, toggle, chartStyle, maxStyle } = useMaximizer()

    return {
      chartEl,
      CHART_EVENTS,
      chartStyle,
      maxStyle,
      series,
      isMaximize,
      toggle,
      chartOptions
    }
  }
})
