/* eslint-disable @typescript-eslint/no-unused-vars */
import { first, keyBy, size } from 'lodash-es'
import { clone, indexBy, prop } from 'ramda'
import { computed, Ref, ref } from 'vue'
import { useRequest } from 'vue-request'

import {
  AnalysisTask,
  getInputOuputNodes,
  InputNode,
  InputType,
  NodeSelection,
  OutputNode
} from '@/components/analysis/libs/common'
import { ModuleNames } from '@/constants/vuex'
import { Network } from '@/libs/bayes'
import { logger } from '@/libs/logger'
import { objectId } from '@/libs/utils'
import { createJob, getJobs, updateJob } from '@/services/api/job'
import {
  abortJobTask,
  createJobTask,
  deleteJobTask,
  executeJobTask,
  exportJobTaskStochastic,
  // getJobTask,
  getJobTasks,
  updateJobTask
} from '@/services/api/jobTask'
import {
  Job,
  JobConfig,
  JobInput,
  JobInputNode,
  JobOutput,
  JobOutputNode,
  JobParams,
  JobTask,
  JobType
} from '@/types/database/job'
import { User } from '@/types/database/user'

const { AUTH } = ModuleNames

export default function useJob(
  currentUser: Ref<User>,
  workspaceId: string,
  jobType: JobType,
  networks: Ref<Network[]>,
  nodeSelections: Ref<NodeSelection[]>,
  nodeSelectionMap: Ref<Record<string, NodeSelection>>,
  inputNodeMapper: (inputNode: InputNode) => JobInputNode = (i) => i,
  outputNodeMapper: (outputNode: OutputNode) => JobOutputNode = (i) => i,
  successCallback: () => void = () => undefined
): any {
  const isLoading: Ref<boolean> = ref(false)
  const tasks: Ref<AnalysisTask[]> = ref([])
  const taskMap = computed(() => indexBy(prop('id'), tasks.value))
  const removedTaskIds: Ref<string[]> = ref([])
  // const jobTasks: Ref<JobTask[]> = ref([])
  const currentJob: Ref<Job> = ref({
    type: jobType,
    workspaceId,
    userId: currentUser.value?.id
  })
  const networkMap = computed(() => indexBy(prop('id'), networks.value))
  const networkByName = computed(() => indexBy(prop('name'), networks.value))
  const onJobChangeSuccess = (data: any) => {
    currentJob.value = data
    if (successCallback) {
      successCallback()
    }
  }
  const uvFactor = jobType === JobType.SENSITIVITY ? 100.0 : 1.0

  const onJobTaskChangeSuccess = (data: any) => {
    const savedTask = taskMap.value[data.id]
    if (savedTask) {
      savedTask.isPersisted = true
    }
  }
  const { runAsync: runAsyncUpdatingJob, loading: isUpdatingJob } = useRequest(updateJob, {
    manual: true,
    onSuccess: onJobChangeSuccess
  })

  const { runAsync: runExportJobTaskStochastic } = useRequest(exportJobTaskStochastic, {
    manual: true
  })

  const { runAsync: runAsyncCreatingJob, run: runCreatingJob, loading: isCreatingJob } = useRequest(
    createJob,
    {
      manual: true,
      onSuccess: onJobChangeSuccess
    }
  )

  const { run: runUpdatingJobTask, loading: isUpdatingJobTask } = useRequest(updateJobTask, {
    manual: true,
    onSuccess: onJobTaskChangeSuccess
  })

  const { run: runCreatingJobTask, loading: isCreatingJobTask } = useRequest(createJobTask, {
    manual: true,
    onSuccess: onJobTaskChangeSuccess
  })

  const isPersisting = computed(
    () =>
      isUpdatingJob.value ||
      isCreatingJob.value ||
      isUpdatingJobTask.value ||
      isCreatingJobTask.value
  )

  const loadJob = async () => {
    logger.info('currentUser', currentUser.value?.username)
    if (!currentUser.value) {
      return
    }

    isLoading.value = true
    const jobs = await getJobs(workspaceId)
    isLoading.value = false
    let jobs_
    if (jobs.length === 1) {
      jobs_ = jobs
    } else {
      jobs_ = jobs.filter(
        (job: Job) => job.type === jobType && job.userId === currentUser.value?.id
      )
      if (!jobs_ || jobs_.length === 0) {
        jobs_ = jobs
      }
    }
    const job: Job | undefined = first(jobs_)
    if (!job) {
      const params: JobParams = {
        networkMap: {},
        networkIds: [],
        inputs: [],
        outputs: []
      }
      currentJob.value = {
        type: jobType,
        workspaceId: workspaceId,
        userId: currentUser.value?.id,
        params
      }
    } else {
      currentJob.value = job
    }
    fillNodeSelections()
  }

  const fillNodeSelections = () => {
    if (!currentJob.value || size(nodeSelectionMap.value) == 0) {
      return
    }
    const job: Job = currentJob.value
    job.params?.inputs?.forEach((input: JobInput) => {
      nodeSelectionMap.value[input.key].isInput = true
      const variation = input.value || 20
      nodeSelectionMap.value[input.key].variation = variation
      const variations = input.values || [-100, -80, -60, -40, -20, 20, 40, 60, 80, 100]
      nodeSelectionMap.value[input.key].variations = variations
      nodeSelectionMap.value[input.key].variationsText = variations.join(',')
      nodeSelectionMap.value[input.key].isInput = true
    })
    job.params?.outputs?.forEach((output: JobOutput) => {
      nodeSelectionMap.value[output.key].isOutput = true
      nodeSelectionMap.value[output.key].constraint = output.constraint
      nodeSelectionMap.value[output.key].utilityVector = output.utilityVector?.map(
        (x) => x * uvFactor
      )
    })
  }

  const loadTasks = async () => {
    if (!currentJob.value) {
      return
    }
    const jobId = currentJob.value?.id
    if (!jobId) {
      return
    }
    isLoading.value = true
    const jobTasks = await getJobTasks(workspaceId, jobId)
    isLoading.value = false
    if (!jobTasks?.length) {
      return
    }
    fillTasks(
      jobTasks.sort((task1: JobTask, task2: JobTask) => task1.name?.localeCompare(task2.name || ''))
    )
  }

  const fillTasks = (persistedJobTasks: JobTask[]) => {
    const _tasks: AnalysisTask[] = []
    persistedJobTasks.forEach((jobTask: JobTask) => {
      const networkId = jobTask.networkId
      const network = networkMap.value[networkId]
      const inputNodes: InputNode[] = []
      const outputNodes: OutputNode[] = []
      jobTask.inputNodes?.forEach(({ key, state, values, networkKey }: JobInputNode) => {
        inputNodes.push({
          networkKey,
          key,
          state,
          values,
          type: state ? InputType.STATE : InputType.PROBABILITY
        })
      })
      jobTask.outputNodes?.forEach(
        ({ key, utilityVector, outcomes = [], value, constraint, networkKey }: JobOutputNode) => {
          outputNodes.push({
            networkKey,
            key,
            utilityVector: utilityVector.map((x) => x * uvFactor),
            constraint,
            outcomes,
            value: value || 0
          })
        }
      )
      const _task: AnalysisTask = {
        id: jobTask.id,
        name: jobTask.name || network.name,
        config: jobTask.config,
        networkId,
        status: jobTask.status,
        isPersisted: true,
        outputNodes,
        inputNodes,
        result: jobTask.result
      }
      _tasks.push(_task)
    })
    tasks.value = _tasks
  }

  const persistJob = async (config: JobConfig) => {
    if (!currentJob.value) {
      return
    }
    const networkIds: string[] = []
    const netMap: Record<string, string> = {}
    networks.value?.forEach((network: Network) => {
      const { id, name } = network
      networkIds.push(id)
      netMap[name] = id
    })
    // tasks.value?.forEach(({ networkId, inputNodes }) => {
    //   if (!networkIds.includes(networkId)) {
    //     networkIds.push(networkId)
    //   }
    //   inputNodes.forEach(({ networkKey }) => {
    //     if (networkKey) {
    //       netMap[networkKey] = networkByName.value[networkKey].id || networkId
    //     }
    //   })
    // })
    const job: Job = { ...currentJob.value, type: jobType }
    const inputs: JobInput[] = []
    const outputs: JobOutput[] = []
    nodeSelections.value?.forEach((node: NodeSelection) => {
      const { isInput, isOutput, key, constraint, variation, variations, utilityVector } = node
      const state = node.state === 'auto' ? undefined : node.state
      if (isInput) {
        inputs.push({
          key,
          state,
          values: variations, // deprecated
          value: constraint,
          variation,
          variations
        })
      }
      if (isOutput) {
        outputs.push({
          key,
          constraint,
          utilityVector: utilityVector.map((x) => x / uvFactor)
        })
      }
    })
    const params = {
      config: Object.assign({ ...job.params?.config }, config),
      networkMap: netMap,
      networkIds,
      inputs,
      outputs
    }
    job.params = Object.assign({ ...job.params }, params)
    delete job.stats
    if (job.id) {
      currentJob.value = await runAsyncUpdatingJob(workspaceId, job.id, job)
    } else {
      job.id = objectId()
      currentJob.value = await runAsyncCreatingJob(workspaceId, job)
    }
  }

  const persistTasks = async (extraConfig = {}) => {
    if (!currentJob.value) {
      return
    }
    const jobId = currentJob.value?.id
    if (!jobId) {
      return
    }
    tasks.value?.forEach(async (task: AnalysisTask) => {
      const { id, name, networkId, inputNodes = [], outputNodes = [], status, isPersisted } = task
      let config = task.config ? task.config : currentJob.value?.params?.config || {}
      config = { ...config, ...extraConfig }
      const _jobTask: JobTask = {
        id,
        name,
        jobId,
        status,
        networkId,
        config,
        inputNodes: inputNodes.map(inputNodeMapper),
        outputNodes: outputNodes.map(outputNodeMapper),
        type: jobType
      }
      if (isPersisted) {
        runUpdatingJobTask(workspaceId, jobId, _jobTask.id, _jobTask)
      } else {
        runCreatingJobTask(workspaceId, jobId, _jobTask)
      }
    })
    removedTaskIds.value.forEach(async (taskId: string) => {
      await deleteJobTask(workspaceId, jobId, taskId)
      const index = removedTaskIds.value.indexOf(taskId)
      if (index > -1) {
        removedTaskIds.value.splice(index, 1)
      }
    })
  }

  const initTasks = () => {
    const _tasks: AnalysisTask[] = []
    networks.value?.map((network: Network, index: number) => {
      const _task: AnalysisTask = Object.assign(
        {
          id: objectId(),
          name: network.name,
          networkId: network.id,
          isPersisted: false,
          status: 'PENDING'
        },
        getInputOuputNodes(network, nodeSelections.value)
      )
      _tasks.push(_task)
    })
    tasks.value = _tasks
  }

  const addTask = (taskId: string, taskIndex: number) => {
    const task = taskMap.value?.[taskId]
    const network = networkMap.value?.[task.networkId]
    const inputNodes = clone(task.inputNodes)
    const outputNodes = clone(task.outputNodes)
    const _task: AnalysisTask = Object.assign(
      {
        name: network.name,
        id: objectId(),
        networkId: network.id,
        isPersisted: false,
        status: 'PENDING'
      },
      {
        inputNodes,
        outputNodes
      }
    )
    tasks.value?.splice(taskIndex, 0, _task)
  }

  const addTaskBaseline = (inputNodes: any[]) => {
    const network = networks.value?.[0]
    const outputNodes: any[] = []
    const _task: AnalysisTask = Object.assign(
      {
        name: 'Baseline',
        id: objectId(),
        networkId: network.id,
        isPersisted: false,
        status: 'PENDING'
      },
      {
        inputNodes,
        outputNodes
      }
    )
    tasks.value?.splice(0, 0, _task)
  }

  const removeTask = (_taskId: string, taskIndex: number) => {
    tasks.value?.splice(taskIndex, 1)
    removedTaskIds.value.push(_taskId)
  }

  const clearTasks = () => {
    tasks.value?.forEach((task: AnalysisTask) => {
      const { id: taskId } = task
      removedTaskIds.value.push(taskId)
    })
    tasks.value = []
  }

  const syncTasks = () => {
    const taskMapByNetwork = keyBy(tasks.value || {}, 'networkId')
    const _tasks: AnalysisTask[] = []
    networks.value?.map((network: Network, index: number) => {
      let _task: AnalysisTask
      if (network.id in taskMapByNetwork) {
        _task = taskMapByNetwork[network.id]
        _task = Object.assign(_task, getInputOuputNodes(network, nodeSelections.value))
      } else {
        _task = Object.assign(
          {
            id: objectId(),
            name: network.name,
            networkId: network.id,
            isPersisted: false,
            status: 'PENDING'
          },
          getInputOuputNodes(network, nodeSelections.value)
        )
      }
      _tasks.push(_task)
    })
    tasks.value = _tasks
  }

  const executeTask = async (selectedTask: AnalysisTask) => {
    const jobId = currentJob.value?.id
    if (!jobId) {
      return false
    }
    if (selectedTask) {
      selectedTask.status = 'EXECUTING'
      executeJobTask(workspaceId, jobId, selectedTask.id)
    } else {
      tasks.value?.forEach(async (task: AnalysisTask) => {
        await executeJobTask(workspaceId, jobId, task.id)
      })
    }
  }

  const abortTask = (task: AnalysisTask): boolean => {
    const jobId = currentJob.value?.id
    if (!jobId) {
      return false
    }
    abortJobTask(workspaceId, jobId, task.id)
    return true
  }

  const exportTaskStochastic = (task: AnalysisTask) => {
    return runExportJobTaskStochastic(task.id)
  }

  return {
    addTaskBaseline,
    exportTaskStochastic,
    abortTask,
    syncTasks,
    clearTasks,
    currentJob,
    tasks,
    taskMap,
    isLoading,
    isPersisting,
    loadJob,
    loadTasks,
    persistJob,
    persistTasks,
    executeTask,
    fillTasks,
    fillNodeSelections,
    addTask,
    initTasks,
    removeTask,
    isUpdatingJob,
    isCreatingJob
  }
}
