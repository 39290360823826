import { ActionTree } from 'vuex'

import { getSurveyCPTSummaries } from '@/services/api/cptSummary'
import { SurveySummaryActionEnum } from '@/store/enums/actions/surveySummary'
import { SurveySummaryMutationEnum } from '@/store/enums/mutations/surveySummary'
import { RootState } from '@/store/types'
import { SurveySummaryActions } from '@/store/types/actions/surveySummary'
import { SurveySummaryState } from '@/store/types/states/surveySummary'
import { CPTSummarySchema } from '@/types'

/**
 * Action function
 */
export const actions: ActionTree<SurveySummaryState, RootState> & SurveySummaryActions = {
  // Get surveyStatuss
  async [SurveySummaryActionEnum.GET_SURVEY_SUMMARIES]({ commit }, payload) {
    commit(SurveySummaryMutationEnum.GET_SURVEY_SUMMARIES, undefined)
    try {
      const res: Array<CPTSummarySchema> = await getSurveyCPTSummaries(payload)
      commit(SurveySummaryMutationEnum.GET_SURVEY_SUMMARIES_FULFILLED, res)
      return res
    } catch (err) {
      commit(SurveySummaryMutationEnum.GET_SURVEY_SUMMARIES_REJECTED, err)
      throw err as Error
    }
  }
}
