import { AxiosError } from 'axios'

import axios from '@/axios'
import API from '@/axios'
import { API_DEFAULT_PAGEABLE_PARAMS, ENDPOINTS } from '@/constants/api'
import {
  errorHandler,
  extractResponse,
  recursivePaginationAPIRequestFn
} from '@/services/api/utils'
import {
  API_PAGEABLE_PARAMS,
  SurveyExportReqBodyJSON,
  SurveyForm,
  SurveySchema,
  Workspace
} from '@/types'

/**
 * Get survey from server
 * FIXME: return type should be Promise<Survey> and Promise<Error>?
 * @param id - survey uuid
 */
const getSurvey = async (id: SurveySchema['id']): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.SURVEY({ id })))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Get all surveys from server
 */
const getSurveys = async (
  workspaceId: Workspace['id'],
  params?: API_PAGEABLE_PARAMS & { surrogate?: boolean | null },
  getAll = true
): Promise<any> => {
  try {
    params = params ? params : {}
    if (typeof params.surrogate == 'undefined') {
      params.surrogate = false
    }
    if (params.surrogate === null) {
      delete params.surrogate
    }
    if (getAll) {
      const requestParams = Object.assign({}, API_DEFAULT_PAGEABLE_PARAMS, params)
      return recursivePaginationAPIRequestFn(API, ENDPOINTS.SURVEYS({ workspaceId }))(requestParams)
    } else {
      return extractResponse(
        await axios.get(ENDPOINTS.SURVEYS({ workspaceId }), {
          params
        })
      )
    }
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Submit survey data to server
 * FIXME: return type should be Promise<Survey> and Promise<Error>?
 * @param workspaceId - workspace id
 * @param payload - survey form value
 */
const createSurvey = async (workspaceId: Workspace['id'], payload: SurveyForm): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.SURVEYS({ workspaceId }), payload))
  } catch (e) {
    errorHandler(e)
  }
}

const cloneSurvey = async (id: SurveySchema['id'], newName = ''): Promise<any> => {
  try {
    return extractResponse(
      await axios.post(ENDPOINTS.SURVEY_CLONE({ id }), null, { params: { newName } })
    )
  } catch (e) {
    errorHandler(e as AxiosError)
  }
}

/**
 * Update survey data to server
 * FIXME: return type should be Promise<Survey> and Promise<Error>?
 */
const updateSurvey = async ({
  id,
  survey
}: {
  id: SurveySchema['id']
  survey: Partial<SurveyForm>
}): Promise<any> => {
  try {
    return extractResponse(await axios.put(ENDPOINTS.SURVEY({ id }), survey))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Export survey from server
 * @param surveyId
 * @param pageable
 * @param format
 * @param userIds
 * @param variableIds
 */
const exportSurvey = async ({
  surveyId,
  format,
  userIds,
  variableIds
}: {
  surveyId: SurveySchema['id']
  format: string
  userIds: Array<string>
  variableIds: Array<string>
}): Promise<any> => {
  try {
    const body = {
      format,
      userIds,
      variableIds
    } as SurveyExportReqBodyJSON
    return extractResponse(await axios.post(ENDPOINTS.SURVEY_EXPORT({ id: surveyId }), body))
  } catch (e) {
    errorHandler(e)
  }
}

/**
 * Delete a survey from server
 * @param id - survey uuid
 */
const deleteSurvey = async (id: SurveySchema['id']): Promise<any> => {
  try {
    return extractResponse(await axios.delete(ENDPOINTS.SURVEY({ id: id })))
  } catch (e) {
    errorHandler(e)
  }
}

export {
  cloneSurvey,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  getSurvey,
  getSurveys,
  exportSurvey
}
