import { css } from '@emotion/css'

export const cssTruncated = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const cssCentered = css`
  display: inline-flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
`

export const cssIcon = css`
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  vertical-align: middle;
  margin-right: 4px;
  &.xicon {
    ${cssCentered};
    height: 16px;
  }
  > svg {
    ${cssCentered};
    height: 16px;
  }
`
