import { LocationQueryValue } from 'vue-router'
import { ActionTree } from 'vuex'

import { DB_FIELDS } from '@/constants/database'
import LOCAL_STORAGE from '@/constants/localStorage'
import { PATH } from '@/constants/router'
import router from '@/router'
import {
  ahpSurveyKeyLogin,
  authCurrent,
  authRefresh,
  login,
  surveyKeyLogin
} from '@/services/api/auth'
import { updateUser } from '@/services/api/user'
import localStorage from '@/services/localStorage'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { AuthMutationEnum } from '@/store/enums/mutations/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { RootState } from '@/store/types'
import { AuthAction } from '@/store/types/actions/auth'
import type { AuthState } from '@/store/types/states/auth'
import { SurveyKeyResponseJSON, UserPreference, UserToken } from '@/types/api/auth'

/**
 * Actions
 */
export const actions: ActionTree<AuthState, RootState> & AuthAction = {
  async [AuthActionEnum.SET_USER_PREFERENCE]({ commit }, payload) {
    await localStorage.setItem(LOCAL_STORAGE.KEYS.USER_PREFERENCE.KEY_NAME, payload)
    commit(AuthMutationEnum.SET_USER_PREFERENCE, payload)
  },
  async [AuthActionEnum.CHECK_AUTH_STATE]({ dispatch }) {
    try {
      const isAuthenticated = await dispatch(AuthActionEnum.CHECK_PERSISTED_AUTH_STATE, undefined)
      if (isAuthenticated) {
        await dispatch(AuthActionEnum.AUTH_CURRENT)
        return true
      }
      return false
    } catch (err) {
      return false
    }
  },
  async [AuthActionEnum.CHECK_PERSISTED_AUTH_STATE]({ commit }) {
    const token: UserToken | null = await localStorage.getItem(LOCAL_STORAGE.KEYS.TOKEN.KEY_NAME)
    if (token) {
      commit(AuthMutationEnum.AUTH_FULFILLED, token)
      return true
    }
    return false
  },
  async [AuthActionEnum.SYNC_USER_PREFERENCE]({ commit }) {
    const userPreference: UserPreference | null = await localStorage.getItem(
      LOCAL_STORAGE.KEYS.USER_PREFERENCE.KEY_NAME
    )
    commit(AuthMutationEnum.SET_USER_PREFERENCE, userPreference)
  },
  // Login
  async [AuthActionEnum.AUTH_LOGIN]({ commit, dispatch }, payload) {
    commit(AuthMutationEnum.AUTH)
    try {
      const { username, password, rememberMe } = payload
      const res: UserToken = await login({ username, password })
      const userPreference = {
        rememberMe
      }
      dispatch(AuthActionEnum.SET_USER_PREFERENCE, userPreference)
      dispatch(AuthActionEnum.AUTH_FULFILLED, res)
      const redirectUrl: LocationQueryValue | LocationQueryValue[] =
        router.currentRoute.value.query?.redirectTo
      if (redirectUrl && typeof redirectUrl === 'string') {
        if (typeof redirectUrl === 'string') {
          router.push(redirectUrl)
        } else if (Array.isArray(redirectUrl)) {
          router.push(redirectUrl[0])
        }
      } else {
        router.push(PATH.HOME)
      }
    } catch (err) {
      dispatch(AuthActionEnum.AUTH_REJECTED, err as Error)
      throw err
    }
  },
  // Auth refresh, used to refresh Bearer token
  async [AuthActionEnum.AUTH_REFRESH]({ commit, state, dispatch }) {
    try {
      commit(AuthMutationEnum.AUTH_REFRESH)
      const options = {
        headers: {
          Authorization: 'Bearer ' + state[AuthStateEnum.TOKEN]?.refreshToken
        }
      }
      const res = await authRefresh(options)
      await dispatch(AuthActionEnum.AUTH_FULFILLED, res)
      return res
    } catch (err) {
      dispatch(AuthActionEnum.AUTH_REJECTED, err)
      throw err
    }
  },
  // Auth fulfilled
  async [AuthActionEnum.AUTH_FULFILLED]({ commit }, payload) {
    await localStorage.setItem(LOCAL_STORAGE.KEYS.TOKEN.KEY_NAME, payload)
    commit(AuthMutationEnum.AUTH_FULFILLED, payload)
  },
  // Auth rejected
  async [AuthActionEnum.AUTH_REJECTED]({ commit }, payload) {
    await localStorage.clear()
    commit(AuthMutationEnum.AUTH_REJECTED, payload)
  },
  // Auth survey key
  async [AuthActionEnum.AUTH_SURVEY_KEY_LOGIN]({ commit, dispatch }, payload) {
    commit(AuthMutationEnum.AUTH)
    try {
      const res: SurveyKeyResponseJSON = await surveyKeyLogin(payload)
      const token: UserToken = {
        refreshToken: res?.refreshToken,
        token: res?.token,
        tokenType: res?.tokenType
      }
      await dispatch(AuthActionEnum.AUTH_FULFILLED, token)
      await dispatch(AuthActionEnum.AUTH_REFRESH)
      await dispatch(AuthActionEnum.AUTH_CURRENT)
      // const redirectUrl: LocationQueryValue | LocationQueryValue[] =
      //   router.currentRoute.value.query?.redirectTo
      // if (redirectUrl && typeof redirectUrl === 'string') {
      //   if (typeof redirectUrl === 'string') {
      //     router.push(redirectUrl)
      //   } else if (Array.isArray(redirectUrl)) {
      //     router.push(redirectUrl[0])
      //   }
      // } else {
      //   router.push(PATH.HOME)
      // }
    } catch (err) {
      dispatch(AuthActionEnum.AUTH_REJECTED, err)
    }
  },
  async [AuthActionEnum.AUTH_AHP_SURVEY_KEY_LOGIN]({ commit, dispatch }, payload) {
    commit(AuthMutationEnum.AUTH)
    try {
      await dispatch(AuthActionEnum.LOG_OUT)
      const res: SurveyKeyResponseJSON = await ahpSurveyKeyLogin(payload)
      const token: UserToken = {
        refreshToken: res?.refreshToken,
        token: res?.token,
        tokenType: res?.tokenType
      }
      await dispatch(AuthActionEnum.AUTH_FULFILLED, token)
      await dispatch(AuthActionEnum.AUTH_REFRESH)
      await dispatch(AuthActionEnum.AUTH_CURRENT)
      // const redirectUrl: LocationQueryValue | LocationQueryValue[] =
      //   router.currentRoute.value.query?.redirectTo
      // if (redirectUrl && typeof redirectUrl === 'string') {
      //   if (typeof redirectUrl === 'string') {
      //     router.push(redirectUrl)
      //   } else if (Array.isArray(redirectUrl)) {
      //     router.push(redirectUrl[0])
      //   }
      // } else {
      //   router.push(PATH.HOME)
      // }
    } catch (err) {
      console.error(err)
      dispatch(AuthActionEnum.AUTH_REJECTED, err)
    }
  },
  // Login, used to get current logged in user
  async [AuthActionEnum.AUTH_CURRENT]({ commit, dispatch }) {
    commit(AuthMutationEnum.AUTH_CURRENT)
    try {
      const res = await authCurrent()
      commit(AuthMutationEnum.AUTH_CURRENT_FULFILLED, res)
      return res
    } catch (err) {
      commit(AuthMutationEnum.AUTH_CURRENT_REJECTED, err)
      dispatch(AuthActionEnum.AUTH_REJECTED, err)
      return Promise.reject(err)
    }
  },
  // Logout
  async [AuthActionEnum.LOG_OUT]({ commit }) {
    commit(AuthMutationEnum.LOG_OUT, undefined)
    await localStorage.clear()
    await router.push(PATH.LOGIN)
    commit(AuthMutationEnum.LOG_OUT_FULFILLED, undefined)
  },
  // update profile
  async [AuthActionEnum.UPDATE_PROFILE]({ commit, state }, payload) {
    const id = state[AuthStateEnum.USER]?.[DB_FIELDS.USER.ID]
    if (id) {
      commit(AuthMutationEnum.UPDATE_CURRENT_USER)
      try {
        const res = await updateUser(id, payload)
        commit(AuthMutationEnum.UPDATE_CURRENT_USER_FULFILLED, res)
      } catch (err) {
        commit(AuthMutationEnum.UPDATE_CURRENT_USER_REJECTED, err)
        throw err
      }
    } else {
      const error = new Error(`Unexpected userId ${id}`)
      commit(AuthMutationEnum.UPDATE_CURRENT_USER_REJECTED, error)
      throw error
    }
  },
  // update password
  async [AuthActionEnum.UPDATE_PASSWORD]({ commit, state }, payload) {
    const id = state[AuthStateEnum.USER]?.[DB_FIELDS.USER.ID]
    const username = state[AuthStateEnum.USER]?.[DB_FIELDS.USER.USERNAME]
    const { oldPassword, newPassword } = payload
    if (id && username) {
      commit(AuthMutationEnum.UPDATE_CURRENT_USER)
      try {
        await login({ username, password: oldPassword })
        const res = await updateUser(id, { password: newPassword })
        commit(AuthMutationEnum.UPDATE_CURRENT_USER_FULFILLED, res)
      } catch (err) {
        commit(AuthMutationEnum.UPDATE_CURRENT_USER_REJECTED, err)
        throw err
      }
    } else {
      const error = new Error(`Unexpected userId ${id}`)
      commit(AuthMutationEnum.UPDATE_CURRENT_USER_REJECTED, error)
      throw error
    }
  }
}
