
import { is, range, reduce } from 'ramda'
import { defineComponent, PropType, ref, watch } from 'vue'

import { Variable } from '@/libs/bayes/Variable'

export const EVENTS = {
  CHANGE: 'update:modelValue',
  USER_CHANGE: 'onUserChange'
}

const LABELS: Record<number, string> = {
  0: 'Impossible',
  5: 'Very Unlikely',
  15: 'Unlikely',
  30: 'Fairly Unlikely',
  50: 'As likely as Not',
  70: 'Fairly Likely',
  85: 'Likely',
  95: 'Very Likely',
  100: 'Certain'
}

export default defineComponent({
  components: {},
  props: {
    modelValue: { type: Number, default: undefined },
    variable: { type: Object as PropType<Variable>, default: undefined },
    config: { type: Object, default: undefined },
    disabled: { type: Boolean, default: undefined },
    isDeterministic: { type: Boolean, default: false }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const min = ref(0)
    const max = ref(100)
    const step = ref(5)
    const val = ref<number | undefined>(props.modelValue)
    const marks = ref(
      reduce(
        (acc: Record<number, any>, v) => {
          let value = v * 5
          acc[value] = {
            style: {
              fontSize: 10
            },
            label: LABELS[value] || ''
          }
          return acc
        },
        {},
        range(0, 21)
      )
    )
    watch(
      () => props.modelValue,
      (v) => {
        val.value = is(Number, v) ? v : undefined
      }
    )

    watch(val, (v) => {
      emit(EVENTS.CHANGE, is(Number, v) ? v : undefined)
    })

    const change = () => {
      emit(EVENTS.USER_CHANGE, val.value)
    }

    return {
      change,
      marks,
      max,
      min,
      step,
      val
    }
  }
})
