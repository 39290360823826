
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, defineComponent, PropType, ref, unref } from 'vue'

export const EVENTS = {
  CHANGE: 'CHANGE'
}

export const OutputType = {
  CRITERION: 'CRITERION',
  CRITERION_NODE: 'CRITERION_NODE',
  NODE: 'NODE'
}

type Key = ColumnProps['key']

export interface OutputSelectionRow {
  key: Key
  nodeKey?: string
  networkKey?: string
  type: string
  childrenKeys?: string[]
}

export default defineComponent({
  props: {
    workspaceId: { type: String, default: undefined },
    outputRows: { type: Array as PropType<OutputSelectionRow[]>, default: undefined },
    networkMap: { type: Object, default: undefined },
    criteriaMap: { type: Object, default: undefined }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const columns = computed(() => {
      let columns = [
        {
          title: 'Output',
          dataIndex: 'key',
          key: 'name',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type'
        },
        {
          title: 'Sub Criteria',
          dataIndex: 'childrenKeys',
          key: 'childrenKeys',
          slots: {
            customRender: 'childrenKeys'
          }
        }
      ]
      return columns
    })

    const selectedRowKeys = ref<Key[]>([])

    const onSelectChange = (changableRowKeys: Key[]) => {
      selectedRowKeys.value = changableRowKeys
      emit(EVENTS.CHANGE, changableRowKeys)
    }

    const dataSource = computed(() => {
      return props.outputRows
    })

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        type: 'checkbox',
        onChange: onSelectChange
      }
    })

    return {
      dataSource,
      columns,
      rowSelection
    }
  }
})
