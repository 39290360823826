
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    networkMap: { type: Object, default: undefined }
  },
  setup(props, { emit }) {
    const tasksRawValue = ref('')
    const tasksTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (tasksTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      tasksRawValue.value = getSelectionRaw()
      emit(EVENTS.OK, tasksRawValue.value)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      ok,
      cancel,
      tasksRawValue,
      tasksTextAreaWrapper
    }
  }
})
