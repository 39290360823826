export const TABLE_EVENTS = {
  ON_RESPONSES_CHANGE: 'onResponsesChange'
}

export type RowData = { [key: string]: any }

// const getColumnWidth = (text: string) => {
//   const span = document.createElement('span')
//   const content = document.createTextNode(text)
//   span.style.fontSize = '14px'
//   span.style.height = 'auto'
//   span.style.width = 'auto'
//   span.style.position = 'absolute'
//   span.style.whiteSpace = 'no-wrap'
//   span.appendChild(content)
//   document.body.appendChild(span)
//   const width = span.clientWidth + 20
//   document.body.removeChild(span)
//   return width
// }

// const onRowHeightChange = ({ index, height }: { index: number; height: number }) => {
//   rowsHeight[index] = height
// }

// const resetRowsHeight = () => {
//   Object.keys(rowsHeight).forEach((key) => {
//     delete rowsHeight[key]
//   })
// }

// watch(
//   () => props.cpt,
//   () => {
//     resetRowsHeight()
//   }
// )

// watch(
//   () => props.selectedVariable,
//   () => {
//     resetRowsHeight()
//   }
// )
