/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { is } from 'ramda'
import { computed, Ref, watch } from 'vue'

import { genCellBgStyle } from '@/libs/theme'
import { ResponseSchema } from '@/types'

export const EVENTS = {
  ON_PROB_CHANGE: 'onProbChange'
}

export const redistributeProbs = (
  rowData: any,
  columns: any,
  probComps: any,
  currentCombKey: string,
  isDeterministic: boolean
) => {
  console.log('redistributeProbs')
  let total = 0
  const restCombKeys: string[] = []
  const firstCombKey = rowData[columns[0].dataIndex]?.combKey
  let currentValue = 0
  const valueMap: Record<string, number> = {}
  if (firstCombKey === currentCombKey) {
    return
  }
  try {
    for (let index = 0; index < columns.length; index++) {
      const column = columns[index]
      const combKey = rowData[column.dataIndex]?.combKey
      const value = probComps[combKey]?.getProb() || 0
      if (index > 0) {
        valueMap[combKey] = value
        total += value
        if (currentCombKey !== combKey) {
          restCombKeys.push(combKey)
        }
      }
      if (currentCombKey === combKey) {
        currentValue = value
      }
    }
  } catch (e) {
    // pass
  }

  if (!firstCombKey) {
    return
  }

  const setValPropComb = (cKey: string, value: number) => {
    const comp = probComps[cKey]
    if (comp) {
      comp.setProb(value)
    }
  }
  if (isDeterministic) {
    if (currentValue === 100) {
      setValPropComb(firstCombKey, 0)
    } else {
      setValPropComb(firstCombKey, 100)
    }
    restCombKeys.forEach((key) => {
      setValPropComb(key, 0)
    })
    return
  }
  if (total <= 100) {
    setValPropComb(firstCombKey, 100 - total)
    // This to handle uninitialized values
    Object.keys(valueMap).forEach((key) => {
      setValPropComb(key, valueMap[key])
    })
  } else if (currentValue <= 100) {
    setValPropComb(firstCombKey, 100 - currentValue)
    restCombKeys.forEach((key) => {
      setValPropComb(key, 0)
    })
  }
}

export function useProb(
  props: any,
  emit: any,
  prob: Ref<any>,
  critical: Ref<boolean>,
  rationale: Ref<string> | null,
  isAgg = false
): any {
  const cellBgStyle = computed(() => genCellBgStyle(prob.value))

  const rowItem = isAgg
    ? computed(() => {
        let rowItem = {}
        if (props.rowId) {
          rowItem = props.analytics?.rowMap.get(props.rowId)
        }
        return rowItem
      })
    : null

  const responses = isAgg
    ? computed(() => {
        let responses = []
        if (props.rowId) {
          const rowItem = props.analytics?.rowMap.get(props.rowId)
          responses = rowItem?.responses || []
          responses = responses.map((response: ResponseSchema) => {
            const { value, userId, rationale } = response
            const critical = !!response.ext?.critical
            const name = userId && props.userMap ? props.userMap[userId]?.username : ''
            return {
              value,
              name,
              critical,
              rationale
            }
          })
        }
        return responses
      })
    : null

  const onRationaleChange = (event: Event) => {
    if (rationale) {
      const { value } = event.target as HTMLTextAreaElement
      rationale.value = value
      emitProbChange()
    }
  }

  const onToggleCritical = () => {
    critical.value = !critical.value
    emitProbChange()
  }

  watch(prob, (v) => {
    prob.value = is(Number, v) ? v : undefined
    // Don't emit for early side-effect
    // emitProbChange()
  })

  const PERSISTED_CHECK = false

  const emitProbChange = (noDist = false) => {
    if (
      !props.combination ||
      (PERSISTED_CHECK &&
        props.response?.value === prob.value &&
        props.response?.rationale === rationale?.value &&
        props.response?.ext?.critical === critical.value)
    ) {
      return
    }
    const eventData = {
      response: {
        value: is(Number, prob.value) ? prob.value : null, // set null for cancel value
        rowId: props.rowId,
        ext: {
          isAgg,
          critical: critical.value
        },
        rationale: rationale?.value,
        variableId: props.variable.id
      },
      combKey: props.combKey,
      rowIndex: props.rowIndex,
      colIndex: props.colIndex,
      valid: !isNaN(prob.value)
    }
    emit(EVENTS.ON_PROB_CHANGE, eventData, noDist)
  }

  return {
    cellBgStyle,
    emitProbChange,
    onRationaleChange,
    onToggleCritical,
    responses,
    rowItem
  }
}

export interface ProbabilityConfig {
  id: string
  params: Record<string, number>
}

export function useProbSelector(
  props: any,
  emit: any,
  formData: any,
  probType: Ref<any>,
  critical: Ref<boolean>
): any {
  const onToggleCritical = () => {
    critical.value = !critical.value
    emitProbSelectorChange()
  }

  const noDist = true

  const emitProbSelectorChange = () => {
    if (!props.combination || !probType.value) {
      return
    }
    let probabilityConfig: ProbabilityConfig | undefined = {
      id: probType.value,
      params: { ...formData[probType.value] }
    }

    if (probType.value == 'none') {
      probabilityConfig = undefined
    }

    const eventData = {
      response: {
        value: null,
        rowId: props.rowId,
        ext: {
          probabilityConfig: probabilityConfig,
          critical: critical.value
        },
        variableId: props.variable.id
      },
      combKey: props.combKey,
      rowIndex: props.rowIndex,
      colIndex: props.colIndex,
      valid: true
    }
    if (probType.value) {
      emit(EVENTS.ON_PROB_CHANGE, eventData, noDist)
    }
    return probabilityConfig
  }

  return {
    emitProbSelectorChange,
    onToggleCritical
  }
}
