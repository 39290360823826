
import { message } from 'ant-design-vue'
import { isEmpty } from 'lodash-es'
import { clone, difference, includes, pick } from 'ramda'
import { computed, defineComponent, reactive, ref, toRaw, watch } from 'vue'

import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { Dict } from '@/libs/common'
import { useStore } from '@/store'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { AuthStateEnum, UserStateEnum, WorkspaceStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'
import type { User, WorkspaceFormJSON } from '@/types'

const { AUTH, USER, WORKSPACE } = ModuleNames
export const FORM_ITEMS = {
  NAME: {
    LABEL: 'Name',
    NAME: 'name'
  },
  DESCRIPTION: {
    LABEL: 'Description',
    NAME: 'description',
    PLACEHOLDER: 'Description'
  },
  ACCESS_CONTROL: {
    LABEL: 'Designers',
    NAME: 'accessControlList'
  }
}

export const FORM_RULES = {
  [FORM_ITEMS.NAME.NAME]: [
    {
      required: true,
      message: 'Please input the name',
      trigger: 'change'
    }
  ],
  [FORM_ITEMS.DESCRIPTION.NAME]: [
    {
      required: false,
      message: 'Please input the description',
      trigger: 'change'
    }
  ]
}

const DEFAULT_FORM_STATE = {
  [FORM_ITEMS.NAME.NAME]: undefined,
  [FORM_ITEMS.DESCRIPTION.NAME]: undefined,
  [FORM_ITEMS.ACCESS_CONTROL.NAME]: []
} as Partial<WorkspaceFormJSON>

/**
 * Init form data
 **/
export const initFormData = (workspace: Dict | undefined): any => {
  if (!workspace) {
    return {}
  }
  const data = Object.assign(
    clone(DEFAULT_FORM_STATE),
    workspace ? pick(Object.keys(DEFAULT_FORM_STATE), workspace) : workspace
  )
  if (workspace) {
    data[FORM_ITEMS.ACCESS_CONTROL.NAME] = isEmpty(workspace[FORM_ITEMS.ACCESS_CONTROL.NAME])
      ? []
      : workspace[FORM_ITEMS.ACCESS_CONTROL.NAME].map((acl: any) => acl.userId)
  }
  return data
}

/**
 * Update form data
 **/
export const updateFormData = (newData: Dict, oldData: Dict): any => {
  const cloneNewData = clone(newData)
  const newKeys = Object.keys(cloneNewData)
  const oldKeys = Object.keys(oldData)
  const removeKeys = difference(oldKeys, newKeys)
  for (const key of removeKeys) {
    delete oldData[key]
  }
  for (const key of newKeys) {
    oldData[key] = cloneNewData[key]
  }
  return oldData
}

export default defineComponent({
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    workspace: {
      type: Object,
      default: undefined
    }
  },
  emits: [EMIT_EVENTS.WORKSPACE.TOGGLE_DIALOG_VISIBILITY],
  setup(props, { emit }) {
    const store = useStore()

    const formState = reactive(initFormData(props.workspace))
    const formRef = ref()
    const visible = computed(() => props.isVisible)
    const formDisabled = ref<boolean>(false)
    const modalTitle = computed(() => `${props.workspace ? 'Edit' : 'Create'} Workspace`)
    const currentUser = computed(() => store.state[AUTH][AuthStateEnum.USER])
    const workspaceError = computed(() => store.state[WORKSPACE][WorkspaceStateEnum.ERROR])
    const users = computed(() => {
      const currentUserList = store.state[USER][UserStateEnum.USER_LIST]?.content
      return currentUserList
        ?.filter(
          (each: User) =>
            includes(DB_ENUM_VALUES.USER.ROLES.ADMIN, each[DB_FIELDS.USER.ROLES]) ||
            includes(DB_ENUM_VALUES.USER.ROLES.DESIGNER, each[DB_FIELDS.USER.ROLES])
        )
        .map((each: User) => ({
          label: each?.username,
          value: each?.id
        }))
    })

    watch(
      () => props.workspace,
      () => {
        const data = initFormData(props.workspace)
        updateFormData(data, formState)
      }
    )

    /**
     * Close the modal
     */
    const handleCancel = () => {
      emit(EMIT_EVENTS.WORKSPACE.TOGGLE_DIALOG_VISIBILITY)
    }

    /**
     * Handle submit workspace form
     */
    const handleSubmit = async () => {
      formDisabled.value = true
      try {
        // validate form before create/update
        try {
          await formRef.value.validate()
        } catch (err: any) {
          formDisabled.value = false
          const firstErrorFieldName =
            err.errorFields[0].name.length > 1 ? [err.errorFields[0].name] : err.errorFields[0].name
          formRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
          return
        }
        const workspaceData: any = { ...toRaw(formState), type: 'ELICITATION' }
        const defaultAcl = [
          {
            userId: currentUser.value?.id
          }
        ]
        if (isEmpty(formState[FORM_ITEMS.ACCESS_CONTROL.NAME])) {
          workspaceData[FORM_ITEMS.ACCESS_CONTROL.NAME] = defaultAcl
        } else {
          const aclList: any[] = defaultAcl
          formState[FORM_ITEMS.ACCESS_CONTROL.NAME].forEach((userId: string) => {
            if (userId !== currentUser.value?.id) {
              aclList.push({
                userId
              })
            }
          })
          workspaceData[FORM_ITEMS.ACCESS_CONTROL.NAME] = aclList
        }
        if (props.workspace && typeof props.workspace.id === 'string') {
          // update workspace
          await store.dispatch(
            vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.UPDATE_WORKSPACE),
            {
              id: props.workspace.id,
              workspace: workspaceData
            }
          )
          message.success(MESSAGE.WORKSPACE_UPDATE_SUCCESS)
        } else {
          // create workspace
          workspaceData[DB_FIELDS.WORKSPACE.CREATOR_ID] = currentUser.value?.id
          await store.dispatch(
            vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.CREATE_WORKSPACE),
            workspaceData
          )
          if (workspaceError.value) {
            message.error(MESSAGE.WORKSPACE_CREATE_FAIL)
          } else {
            message.success(MESSAGE.WORKSPACE_CREATE_SUCCESS)
          }
        }
        formDisabled.value = false
        emit(EMIT_EVENTS.WORKSPACE.TOGGLE_DIALOG_VISIBILITY)
      } catch (err) {
        formDisabled.value = false
        throw err
      }
    }

    return {
      users,
      FORM_ITEMS,
      FORM_RULES,
      formDisabled,
      formRef,
      formState,
      handleCancel,
      handleSubmit,
      modalTitle,
      visible
    }
  }
})
