
import { css } from '@emotion/css'
import { computed, defineComponent, Ref, ref } from 'vue'

import { CATEGORICAL_PALETTES, CONTINUOUS_PALETTES, DEFAULT_PALETTE } from './color-scheme'

const wrapper = css`
  > * {
    margin-left: 4px;
  }

  .item {
    border: 1px solid transparent;
    background-color: transparent;
    padding: 6px;
    display: flex;
    flex-direction: row;

    .name {
      flex: 0 0 auto;
      width: 100px;
    }

    svg {
      flex: 0 0 auto;
      margin-right: 6px;
    }

    &.selected {
      background-color: #f0f0f0;
      border: 1px solid #dddddd;
    }
  }
`

export default defineComponent({
  name: 'PalettePicker',
  props: {
    modelValue: { type: String, required: true },
    width: {
      default: undefined,
      type: [Number, String]
    },
    categorical: {
      default: true,
      type: Boolean
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    // const palettes: Ref<any[]> = ref([])
    const n = 100
    const selected: Ref<string> = ref('')

    const palettes = computed(() => {
      if (props.categorical) {
        return CATEGORICAL_PALETTES
      } else {
        return [DEFAULT_PALETTE].concat(CONTINUOUS_PALETTES)
      }
    })

    const select = (paletteName: string, colors: string[]) => {
      emit('update:modelValue', paletteName)
      emit('change', paletteName, colors)
    }

    return {
      wrapper,
      selected,
      select,
      n,
      palettes
    }
  }
})
