import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed, Ref, ref } from 'vue'
import { useRequest } from 'vue-request'

import { Network } from '@/libs/bayes'
import { gradientMaker } from '@/libs/utils'
import { deleteNetwork, getNetworks } from '@/services/api/network'
import { getWorkspace } from '@/services/api/workspace'
import { NetworkSchema } from '@/types'

dayjs.extend(relativeTime)

export const TABS = {
  PARAMS: 'PARAMS',
  MODEL: 'MODEL',
  RESULTS: 'RESULTS',
  TASKS: 'TASKS'
}

export default function useBase(workspaceId: string): any {
  const { run: runWorkspace, data: dataWorkspace } = useRequest(getWorkspace, {
    manual: true
  })

  const { run: runNetworks, data: dataNetworks } = useRequest(getNetworks, {
    manual: true
  })

  const rawNetworks = computed(() => dataNetworks.value?.content || [])
  const networks = computed(() =>
    rawNetworks.value?.map((rawNetwork: NetworkSchema) => new Network(rawNetwork))
  )
  const workspace = computed(() => dataWorkspace.value || {})
  const workspaceCreated = computed(() => dayjs(workspace.value?.createdDate).fromNow())
  const workspaceModified = computed(() => dayjs(workspace.value?.lastModifiedDate).fromNow())

  const workspaceHeaderStyle = computed(() => ({
    backgroundSize: '100% 32px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: gradientMaker(workspace.value?.name || 'UNTITLED')
  }))

  const networkOptions = computed(
    () =>
      networks.value?.map((network: Network, index: number) => ({
        label: network.name,
        value: index
      })) || []
  )
  const selectedNetwork: Ref<Network | undefined> = ref()
  const selectedNetworkIndex: Ref<number> = ref(0)

  const loadWorkspace = () => {
    runWorkspace(workspaceId)
  }

  const loadNetworks = () => {
    runNetworks(workspaceId)
  }

  const removeNetwork = async (networkId: string) => {
    await deleteNetwork(networkId)
    loadNetworks()
  }

  return {
    workspaceHeaderStyle,
    networks,
    networkOptions,
    selectedNetwork,
    selectedNetworkIndex,
    loadWorkspace,
    removeNetwork,
    loadNetworks,
    workspace,
    workspaceCreated,
    workspaceModified
  }
}
