import { render } from "./WorkspaceCard.vue?vue&type=template&id=20ed6dcb"
import script from "./WorkspaceCard.vue?vue&type=script&lang=ts"
export * from "./WorkspaceCard.vue?vue&type=script&lang=ts"

import "./WorkspaceCard.vue?vue&type=style&index=0&id=20ed6dcb&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "20ed6dcb"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('20ed6dcb', script)) {
    api.reload('20ed6dcb', script)
  }
  
  module.hot.accept("./WorkspaceCard.vue?vue&type=template&id=20ed6dcb", () => {
    api.rerender('20ed6dcb', render)
  })

}

script.__file = "src/components/workspace/WorkspaceCard.vue"

export default script