
import {
  CloudTwoTone,
  DeleteTwoTone,
  PieChartTwoTone,
  RadarChartOutlined,
  SettingTwoTone,
  SolutionOutlined
} from '@ant-design/icons-vue'
import { isEmpty } from 'lodash-es'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import SurveyDialog from '@/components/survey/SurveyDialog.vue'
import { DB_FIELDS } from '@/constants/database'
import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { Network } from '@/libs/bayes/Network'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { SurveyMutationEnum } from '@/store/enums/mutations/survey'
import { NetworkStateEnum } from '@/store/enums/states/network'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions, vuexMutations } from '@/store/util'
import { SurveyExportFormatEnum } from '@/types'
import { SurveySchema } from '@/types'
import { User } from '@/types'

export default defineComponent({
  components: {
    SurveyDialog,
    CloudTwoTone,
    DeleteTwoTone,
    SettingTwoTone,
    PieChartTwoTone,
    RadarChartOutlined,
    SolutionOutlined
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    const userListContent = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)
    const currentNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.CURRENT_NETWORK]
    )
    const network = computed(() => {
      if (currentNetwork.value) {
        return new Network(currentNetwork.value)
      }
      return null
    })

    const surveyList = computed(() => {
      const surveys = store.state.survey[SurveyStateEnum.SURVEY_LIST]?.content
      return surveys?.map((each: SurveySchema) => ({
        ...each,
        key: each?.id
      }))
    })

    /**
     * Delete survey by id
     **/
    const deleteSurveyById = (id: SurveySchema['id']) => {
      store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.DELETE_SURVEY), id)
    }

    const isShowCreateModal = ref(false)

    /**
     * Handle display create user model
     **/
    const handleShowCreateModal = () => {
      isShowCreateModal.value = !isShowCreateModal.value
    }

    /**
     * Export responses for current survey
     * @param surveyId
     * @param fileType
     */
    const exportCPTResponse = (surveyId: string, fileType: SurveyExportFormatEnum) => {
      if (
        surveyId &&
        typeof surveyId === 'string' &&
        network.value &&
        !isEmpty(network?.value?.variables)
      ) {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.EXPORT_SURVEY), {
          surveyId,
          format: fileType,
          userIds: userListContent.value.map((each: User) => each.id) as Array<User['id']>,
          variableIds: network?.value?.variables.map((each) => each.id)
        })
      }
    }

    /**
     * Get new survey list when workspace id change
     */
    watch([workspaceId], (newVal, oldVal) => {
      if (newVal !== oldVal && typeof newVal === 'string') {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.GET_SURVEYS), newVal)
      }
    })

    onMounted(() => {
      if (workspaceId.value && typeof workspaceId.value === 'string') {
        store.dispatch(
          vuexActions(ModuleNames.SURVEY, SurveyActionEnum.GET_SURVEYS_ALL),
          workspaceId.value
        )
        // reset current survey for create survey modal
        store.commit(vuexMutations(ModuleNames.SURVEY, SurveyMutationEnum.SET_CURRENT_SURVEY), null)
      }
    })

    return {
      exportCPTResponse,
      isShowCreateModal,
      handleShowCreateModal,
      deleteSurveyById,
      workspaceId,
      ROUTE_NAME,
      DB_FIELDS,
      surveyList,
      SurveyExportFormatEnum
    }
  }
})
