import { NetworkStateEnum } from '@/store/enums/states/network'
import { NetworkState } from '@/store/types/states/network'

/**
 * Default value
 */
const state: NetworkState = {
  [NetworkStateEnum.IS_CREATING]: false,
  [NetworkStateEnum.IS_UPDATING]: false,
  [NetworkStateEnum.IS_DELETING]: false,
  [NetworkStateEnum.IS_GETTING]: false,
  [NetworkStateEnum.IS_IMPORTING]: false,
  [NetworkStateEnum.CURRENT_NETWORK]: null,
  [NetworkStateEnum.NETWORK_LIST]: {},
  [NetworkStateEnum.ERROR]: null,
  [NetworkStateEnum.LOADING_PROMPT_TEXT]: null
}

export { state }
