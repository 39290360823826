<template>
  <svg class="wny-lessplot" :width="width" :height="height">
    <g>
      <!-- five number summary: min, q1, mean, q2, max -->
      <text
        v-for="(item, index) in items"
        :key="`item-value-${index}`"
        class="wny-value"
        text-anchor="middle"
        :x="item.x + 'px'"
        :y="valueY + 'px'"
        :width="lblWidth + 'px'"
        @click="pick(item.q)"
      >
        {{ item.value }}
      </text>
      <circle
        v-for="(item, index) in items"
        :key="`item-dot-${index}`"
        :cx="item.x + 'px'"
        :cy="startY + barHeightHalf + 'px'"
        :r="3"
        :fill="'#aaaaaa'"
      >
        {{ item.label }}
      </circle>
      <!-- median -->
      <text
        key="item-label-median"
        class="wny-label"
        text-anchor="start"
        :x="0 + 'px'"
        :y="auxY + 'px'"
      >
        MEAN:
      </text>
      <text
        key="item-value-median"
        class="wny-value"
        text-anchor="start"
        :x="40 + 'px'"
        :y="auxY + 'px'"
        :width="lblWidth + 'px'"
        @click="pick(mean)"
      >
        {{ mean.toFixed(2) }}
      </text>
      <!-- line -->
      <rect
        :fill="'#aaaaaa'"
        :x="items[0].x + 'px'"
        :y="startY + barHeightHalf + 'px'"
        :width="items[items.length - 1].x - items[0].x + 'px'"
        :height="'1px'"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export const OVERRIDE = 'override'

export default defineComponent({
  props: {
    data: { type: Array as PropType<Array<number>>, default: () => [] },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 60 }
  },
  emits: [OVERRIDE],
  setup(props, { emit }) {
    const startY = 0
    const lblWidth = 30
    const barHeight = 10
    const barHeightHalf = 5
    const valueY = startY + barHeight + 15
    const labelY = valueY + 12
    const auxY = labelY + 18
    const offset = 20

    let sorted = [...props.data].sort()
    const max = sorted[sorted.length - 1]
    const min = sorted[0]
    const qWidth = sorted.length ? max - min : 0
    let sum = 0
    const items = sorted.map((q, index) => {
      sum += q
      const x = qWidth
        ? Math.round(((q - min) * (props.width - offset * 2)) / qWidth) + offset
        : Math.round((index * (props.width - offset * 2)) / 2) + offset
      const value = q.toFixed(2)
      return {
        x,
        q,
        value
      }
    })
    const mean = sum / sorted.length

    const pick = (value: number) => {
      emit(OVERRIDE, parseFloat(value.toFixed(2)))
    }

    return {
      barHeightHalf,
      startY,
      labelY,
      auxY,
      valueY,
      mean,
      pick,
      items,
      lblWidth,
      barHeight
    }
  }
})
</script>

<style lang="stylus">
.wny-lessplot
  .wny-label
    font-size: 8px
    fill: #aaaaaa
  .wny-value
    font-size: 9px
    font-weight: bold
    fill: #333333
    cursor: pointer
    &:hover
      fill: #ff3300
</style>
