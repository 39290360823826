<template>
  <div v-if="columns && rows" class="sz-mini-likelihood">
    <div class="sz-mini-likelihood-cols">
      <div
        v-for="(col, index) in columns.slice(1)"
        :key="index"
        class="sz-mini-likelihood-cell"
        :style="col.customHeaderCell(col).style"
        :title="col.title"
      >
        {{ col.title }}
      </div>
    </div>
    <div v-for="(row, rindex) in rows" :key="rindex" class="sz-mini-likelihood-row">
      <div v-for="(col, index) in columns.slice(1)" :key="index" class="sz-mini-likelihood-cell">
        {{ row[col.dataIndex]?.response?.mean || row[col.dataIndex]?.response?.value || '0' }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    columns: { type: Object, required: true },
    rows: { type: Object, required: true }
  }
})
</script>

<style lang="stylus">
@import '../../styles/commons.styl';

.sz-mini-likelihood
  @extend .fullcover
  font-size: 11px
  display: flex
  flex-direction: column
  align-items: stretch
  .sz-mini-likelihood-cols, .sz-mini-likelihood-row
    flex: 1 1 auto
    display: flex
    flex-direction: row
    align-items: stretch
    max-height: 33%
  .sz-mini-likelihood-cell
    @extend .truncate
    flex: 1 1 auto
    padding: 4px
</style>
