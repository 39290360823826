/**
 * Action enum value, this value also used as method name,
 * please make sure no repeated name across different Vuex modules
 */
export enum AuthActionEnum {
  AUTH_CURRENT = 'AUTH_CURRENT',
  AUTH_FULFILLED = 'AUTH_FULFILLED',
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_SURVEY_KEY_LOGIN = 'AUTH_SURVEY_KEY_LOGIN',
  AUTH_AHP_SURVEY_KEY_LOGIN = 'AUTH_AHP_SURVEY_KEY_LOGIN',
  AUTH_REFRESH = 'AUTH_REFRESH',
  AUTH_REJECTED = 'AUTH_REJECTED',
  CHECK_AUTH_STATE = 'CHECK_AUTH_STATE',
  CHECK_PERSISTED_AUTH_STATE = 'CHECK_PERSISTED_AUTH_STATE',
  LOG_OUT = 'LOG_OUT',
  SET_USER_PREFERENCE = 'SET_USER_PREFERENCE',
  SYNC_USER_PREFERENCE = 'SYNC_USER_PREFERENCE',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  UPDATE_PROFILE = 'UPDATE_PROFILE'
}
