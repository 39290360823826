import Color from 'color'
import { forEachObjIndexed } from 'ramda'
import { CSSProperties } from 'vue'

import { StatePolarity, VariableRelation } from '@/libs/enums'

// based on:
//
// 12-color palette
// http://mkweb.bcgsc.ca/colorblind/palettes.mhtml
// https://color.adobe.com/create/color-accessibility

const varyColor = (colorString: string, diff: number): string => {
  const kolor = Color(colorString)
  if (diff > 0) {
    return kolor.lighten(0.5).desaturate(0.3).hex()
  } else if (diff < 0) {
    return kolor.darken(0.2).desaturate(0.6).hex()
  }
  return colorString
}

export interface Theme {
  title: string
  titlePlural?: string
  color: string
  colorFade?: string
  colorLight?: string
  colorDark?: string
  textColor?: string
}

export const CSS = (theme: Theme, mode: number): any => {
  return mode === 0
    ? {
        backgroundColor: theme.color,
        color: theme.textColor
      }
    : {
        backgroundColor: theme.color,
        color: 'black'
        // border: `1px solid #909090`
      }
}

export const VARIABLE_THEME: Record<string, Theme> = {
  [VariableRelation.PARENT]: {
    title: 'Parent',
    titlePlural: 'Parents',
    color: '#7AFAC5',
    textColor: '#000000'
  },
  [VariableRelation.CHILD]: {
    title: 'Child',
    titlePlural: 'Children',
    color: '#EBF0A9',
    textColor: '#000000'
  },
  [VariableRelation.SELECTED]: {
    title: 'Selected',
    titlePlural: 'Selected',
    color: '#00F051',
    textColor: '#000000'
  },
  [VariableRelation.NONE]: {
    title: 'None',
    titlePlural: 'None',
    color: 'transparent',
    textColor: '#000000'
  }
}

export const STATE_THEME: Record<string, Theme> = {
  [StatePolarity.POSITIVE]: {
    title: 'Desirable',
    color: '#0088ff',
    textColor: '#ffffff'
  },
  [StatePolarity.NEUTRAL]: {
    title: 'Neutral',
    color: '#999999',
    textColor: '#ffffff'
  },
  [StatePolarity.NEGATIVE]: {
    title: 'Least Desirable',
    color: '#cc6633',
    textColor: '#ffffff'
  }
}

const themeExpander = (theme: Theme) => {
  const color: string = theme.color
  if (color) {
    theme.colorFade = Color(color).alpha(0.1).hex()
    if (!theme.colorLight) {
      theme.colorLight = varyColor(color, 1)
    }
    if (!theme.colorDark) {
      theme.colorDark = varyColor(color, -1)
    }
  }
  return theme
}

export const colorInterpolate = (color1: string, color2: string, ratio: number): string =>
  Color(color1).mix(Color(color2), ratio).string()

export const PROB_COLOR_LOW = '#cc6633'
export const PROB_COLOR_MID = '#999999'
export const PROB_COLOR_HIGH = '#0088ff'

export const probColor = (prob: number): string => {
  let ratio = prob / 100
  if (ratio > 1) {
    ratio = 1
  }
  if (ratio < 0) {
    ratio = 0
  }
  if (ratio === 0.5) {
    return PROB_COLOR_MID
  } else if (ratio > 0.5) {
    return colorInterpolate(PROB_COLOR_MID, PROB_COLOR_HIGH, (ratio - 0.5) * 2)
  }
  return colorInterpolate(PROB_COLOR_MID, PROB_COLOR_LOW, (0.5 - ratio) * 2)
}

export const genCellBgStyle = (
  value: number,
  backgroundColor = 'rgb(250, 250, 250, 1)',
  textColor = 'white'
): CSSProperties => {
  if (!isNaN(value)) {
    backgroundColor = probColor(value)
  }
  return { backgroundColor, color: textColor }
}

export const genCellBgStyleRange = (start: number, end: number, value: number): CSSProperties => {
  const range = end - start
  return genCellBgStyle(((value - start) * 100) / range)
}

forEachObjIndexed(themeExpander, VARIABLE_THEME)
forEachObjIndexed(themeExpander, STATE_THEME)
