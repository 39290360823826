/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import xml2js from 'xml2js'
// import short from "short-uuid";

const parser = new xml2js.Parser()

const normalizeName = (name: string) => {
  return name.split('_').join(' ')
}

export const parseRaw = async (source: any): Promise<any> => {
  return await new Promise((resolve, reject) =>
    parser.parseString(source, (err: any, result: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  )
}

export const parse = async (source: any): Promise<any> => {
  const nodes: any = []
  const edges: any = []
  const nodeMap: any = {}

  const result: any = await new Promise((resolve, reject) =>
    parser.parseString(source, (err: any, result: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  )

  result.smile.nodes[0].cpt.forEach((cpt: any, nodeIndex: any) => {
    const id = nodeIndex + '' // short.generate();
    const node: any = {
      id
    }
    node.name = normalizeName(cpt.$.id)
    node.states = []
    nodeMap[cpt.$.id] = id
    const states = cpt.state
    states.forEach((state: any, stateIndex: any) => {
      node.states.push({
        id: stateIndex + '', //short.generate(),
        name: state.$.id,
        desirable: false
      })
    })
    nodes.push(node)
    if (cpt.parents) {
      const parents = cpt.parents[0].split(' ')
      parents.forEach((parent: any) => {
        edges.push({
          source: nodeMap[parent] + '',
          target: id + ''
        })
      })
    }
  })
  return {
    name: 'Sample',
    nodes,
    edges
  }
}
