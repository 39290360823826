import { isObject, repeat } from 'lodash-es'
import { isNil, range as rangeInt, repeat as repeatR, sum } from 'ramda'
export type Combination = Array<Array<number>>
export type Contributions = Array<Array<number>>

/**
 * Return combination list of a set of variables across all possible states
 *
 * @param variables Varibles
 * @returns combination list
 *
 * Example:
 * variables: [
 *  [v1-s1, v1-s2],
 *  [v2-s1, v2-s2, v2-s3],
 *  [v3-s1, v3-s2]
 * ]
 * output: [
 *  [0, 0, 0],
 *  [0, 0, 1],
 *  [0, 1, 0],
 *  [0, 1, 1],
 *  [0, 2, 0],
 *  [0, 2, 1],
 *  [1, 0, 0],
 *  [1, 0, 1],
 *  [1, 1, 0],
 *  [1, 1, 1],
 *  [1, 2, 0],
 *  [1, 2, 1]
 * ]
 */
export const combinationOf = (variables: Array<any>): Combination => {
  const combine = (comb: Combination, stateN: number) => {
    const p = []
    for (let i = 0; i < comb.length; i++) {
      for (let j = 0; j < stateN; j++) {
        p.push(comb[i].concat(j))
      }
    }
    return p
  }

  let comb: Combination = [[]]
  for (let i = 0; i < variables.length; i++) {
    comb = combine(comb, variables[i].length)
  }
  return comb
}

type PairwiseItem<T> = {
  i: number
  j: number
  pair: T[]
}

export const pairwiseOf: <T>(items: T[]) => PairwiseItem<T>[] = (items) => {
  const pairs = []
  for (let i = 0; i < items.length; i++) {
    for (let j = 0; j < items.length; j++) {
      if (i < j) {
        pairs.push({
          i,
          j,
          pair: [items[i], items[j]]
        })
      }
    }
  }
  return pairs
}

/**
 * Return contribution list of a set of variables
 * Each contribution is a uniform steps from 0 to 1 for all possible states
 *
 * @param variables Varibles
 * @returns contribution list
 *
 * Example:
 * variables: [
 *  [v1-s1, v1-s2],
 *  [v2-s1, v2-s2, v2-s3]
 * ]
 * output: [
 *  [0, 1],
 *  [0, 0.5, 1]
 * ]
 */
export const contribsOf = (variables: Array<any>): Contributions => {
  const contribs: Contributions = variables.map((variable) => {
    const len = variable.length // this is the number of the states of the variable
    return rangeInt(0, len).map((i) => i / (len - 1))
  })
  return contribs
}

/**
 * Normalize the rating for parents (independent variables)
 * - First normalize the weights to 1
 * - Then, rescale to a range (which comes form best likelihood - worst likelihood)
 *
 * @param weights Original/raw ratings (0-1)
 * @param range weights would be scaled to this range
 * @returns Normalized weights
 */
export const normalizeWeights = (weights: Array<number>, range: number): Array<number> => {
  const total = sum(weights)
  return weights.map((weight) => (weight * range) / total)
}

export const anonymizeRationale = (rationale: string | undefined, isAnonymous = true): string => {
  rationale = rationale || ''
  return isAnonymous
    ? rationale.replaceAll(/\((user\d+):[a-zA-Z\d_ ]+ \[(\d+)\]\)/g, '($1 [$2])')
    : rationale.replaceAll(/\(user\d+:([a-zA-Z\d_ ]+) \[(\d+)\]\)/g, '($1 [$2])')
}

export const indexOfMax = (arr: number[]): number =>
  arr.reduce((indexMax, el, index, _arr) => (el > _arr[indexMax] ? index : indexMax), 0)

export const completeProbs = (arr: number[], total = 1.0, targetIndex = 0): number[] => {
  let sum = 0
  const newArr: number[] = []
  arr.forEach((val, index) => {
    if (index !== targetIndex) {
      sum += val
      if (sum > total) {
        newArr[index] = sum - total
        sum = total
      } else {
        newArr[index] = val
      }
    }
  })
  if (sum < total) {
    newArr[targetIndex] = total - sum
  }
  return newArr
}

export const defaultProbs = (stateNum: number, total = 100.0): number[] => {
  const probs = repeatR(0, stateNum)
  probs[stateNum - 1] = total
  return probs
}

export const getProbsOfNodeDef = (
  nodeDefinition: number[],
  combIndex: number,
  stateLength = 2
): number[] => nodeDefinition.slice(combIndex * stateLength, stateLength)

export const setProbsOfNodeDef = (
  nodeDefinition: number[],
  probs: number[],
  combIndex: number,
  stateLength = 2
): void => {
  nodeDefinition.splice(combIndex * stateLength, stateLength, ...probs)
}

export const rounder = (p: number, precision = 2): number => {
  const mult = Math.pow(10, precision)
  return Math.round((p + Number.EPSILON) * mult) / mult
}

export const snapper = (p: number, step: number): number => {
  let rounded = (p + Number.EPSILON) / step
  rounded = p < 0 ? Math.floor(rounded) : Math.ceil(rounded)
  return rounded * step
}

export const NUMBER_PRECISION = 4
export const PROB_PRECISION = 4
export const PROB_PRECISION_MULT = Math.pow(10, PROB_PRECISION)
export const probRounder = (p: number): number =>
  Math.round((p + Number.EPSILON) * PROB_PRECISION_MULT) / PROB_PRECISION_MULT
export const probStep = 1 / PROB_PRECISION_MULT
export const probFormatter = (p: number, precision = PROB_PRECISION): string =>
  Number(p).toFixed(precision)

export const percentFormatter = (p: number, precision = PROB_PRECISION): string =>
  Number(p * 100).toFixed(precision)

export const parentsCombinationTotal = (parentStates: any[]): number =>
  parentStates.reduce((acc, p) => acc * p.length, 1)

export const parentsCombinationDivisions = (parentStates: any[]): number[] => {
  const divisions: number[] = []
  const len = parentStates.length
  let mult = parentStates[len - 1].length
  divisions[len - 1] = mult
  for (let i = len - 2; i >= 0; i--) {
    mult = mult * parentStates[i].length
    divisions[i] = mult
  }
  return divisions
}

export const combIndexToStateIndex = (
  parentStates: any[],
  divisions: number[],
  combIndex: number,
  parentIndex: number
): number => {
  const divIdx =
    parentIndex === parentStates.length - 1
      ? combIndex
      : (combIndex / divisions[parentIndex + 1]) >> 0
  return divIdx % parentStates[parentIndex].length
}

export type Dict = Record<string, any>
export type NumberArray = number[]

export const simpleRow = (values: string[]): any[] => values.map((value) => ({ value }))

export const complexRow = (values: any[]): any[] => {
  return values.map((value) => (isObject(value) ? value : { value }))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const numberCell = (value: any, precision = NUMBER_PRECISION, rounded = false): any =>
  isNil(value) || isNaN(value)
    ? ''
    : {
        value: rounded ? rounder(value, precision) : Number(value),
        format: '#,##0.' + repeat('0', precision),
        type: Number
      }

export const rescale = (values: NumberArray, scale: number): NumberArray =>
  values.map((v) => v * scale)

export const normalizeName = (name: string): string =>
  name
    ?.replaceAll(/[.:\s/\-,()]/g, '_')
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')

export const normalizeXlsTabName = (name: string): string =>
  normalizeName(name || '').replaceAll('?', '')

export const sleep = (ms: number): Promise<any> => new Promise((r) => setTimeout(r, ms))
