<template>
  <div class="sz-ace-table-container">
    <div class="sz-title">
      <h1>Ace Table</h1>
    </div>
    <template v-if="!isTerminal">
      <VariableContext
        v-if="network"
        :network="network"
        :variable="selectedVariable"
        :state="selectedState"
        :is-ace="true"
        :show-parents="true"
        :show-children="true"
        :current-survey="currentSurvey"
      />
      <div ref="tableWrapper" class="sz-table-wrapper">
        <a-table
          v-if="data.length !== 0"
          :data-source="data"
          :columns="columns"
          :pagination="false"
          size="small"
          class="sz-ace-table"
          :class="isFull ? 'dst' : ''"
          :scroll="{ y: scrollY, x: scrollX }"
          :row-class-name="
            (record) => (record.isLikelihood ? 'ace-best-row' : record.isHtml ? 'ace-html-row' : '')
          "
        >
          <template #first-col="{ record, text }">
            <div class="sz-table-first-col-body">
              <span v-if="record.isHtml" v-html="text" />
              <span v-if="record.isLabel"> {{ text }} </span>
              <span v-if="!record.isLabel && !record.isHtml">
                {{ text }}
              </span>
            </div>
          </template>
          <template #response="{ text, record, index, column }">
            <div v-if="record.key === 'preview'">
              <a @click="preview(text)">Preview</a>
            </div>
            <div v-if="record.isLabel" />
            <AceLikelihood
              v-if="isFull && record.isLikelihood"
              :network="network"
              :cpt="cpt"
              :selected-variable="selectedVariable"
              :responses="responses"
              :analytics="analytics"
              :dep-comb-key="text?.combKeyx"
              :response-map="responseMap"
              :is-analytics="isAnalytics"
              :valid-map="validMap"
              :user-map="userMap"
              :editable="editable"
              :is-anonymous="isAnonymous"
              :is-stochastics="isStochastics"
            />
            <ProbSelector
              v-if="
                !record.isLabel &&
                text?.combination &&
                !isAnalytics &&
                isStochastics &&
                ((isFull && !record.isLikelihood) || !isFull)
              "
              :key="index"
              :comb-key="text?.combKey"
              :combination="text?.combination"
              :variable="selectedVariable"
              :row-index="index"
              :tab-index="column.colIndex * 100 + index"
              :row-id="text?.rowId"
              :discrete="record.isLikelihood"
              :response="text?.response"
              :allow-critical="!record.isLikelihood"
              :disabled="!editable || isSaveButtonSpinning"
              v-on="{
                [PROB_EVENTS.ON_PROB_CHANGE]: onResponseChange
              }"
            />
            <Prob
              v-if="
                !record.isLabel &&
                text?.combination &&
                !isAnalytics &&
                !isStochastics &&
                ((isFull && !record.isLikelihood) || !isFull)
              "
              :key="index"
              :comb-key="text?.combKey"
              :combination="text?.combination"
              :variable="selectedVariable"
              :row-index="index"
              :tab-index="column.colIndex * 100 + index"
              :row-id="text?.rowId"
              :discrete="record.isLikelihood"
              :response="text?.response"
              :allow-critical="!record.isLikelihood"
              :disabled="!editable || isSaveButtonSpinning"
              v-on="{
                [PROB_EVENTS.ON_PROB_CHANGE]: onResponseChange
              }"
            />
            <ProbAgg
              v-if="
                !record.isLabel &&
                text?.combination &&
                isAnalytics &&
                !isStochastics &&
                ((isFull && !record.isLikelihood) || !isFull)
              "
              :key="index"
              :comb-key="text?.combKey"
              :combination="text?.combination"
              :variable="selectedVariable"
              :row-index="index"
              :tab-index="column.colIndex * 100 + index"
              :row-id="text?.rowId"
              :discrete="record.isLikelihood"
              :allow-critical="!record.isLikelihood"
              :response="text?.response"
              :analytics="analytics"
              :user-map="userMap"
              :anonymous="isAnonymous"
              :disabled="!editable || isSaveButtonSpinning"
              v-on="{
                [PROB_EVENTS.ON_PROB_CHANGE]: onResponseChange
              }"
            />
          </template>
        </a-table>
      </div>
      <div v-if="editable && (allowPartialSave || combinations.length > 0)" class="sz-table-footer">
        <a-button
          type="primary"
          size="small"
          :disabled="!allowPartialSave && !isValid"
          :loading="isSaveButtonSpinning"
          @click="onResponsesChange"
        >
          Save
        </a-button>
      </div>
    </template>
    <template v-if="isTerminal">Terminal node is ignored</template>
  </div>
  <a-modal
    title="CPT Preview"
    :visible="isPreviewVisible"
    class="sz-cpt-preview-modal"
    width="80vw"
    :centered="true"
    :body-style="{ height: '80vh', position: 'relative' }"
    @cancel="isPreviewVisible = false"
  >
    <template #footer>
      <a-button key="cancel" @click="isPreviewVisible = false">OK</a-button>
    </template>
    <CPTPreview
      v-if="isPreviewVisible"
      :cpt="cpt"
      :cpt-values="cptValues"
      :selected-variable="selectedVariable"
    />
  </a-modal>
</template>

<script lang="ts">
import { isEmpty } from 'lodash-es'
import { computed, defineComponent, PropType, Ref, ref, toRefs } from 'vue'

import useAceTemplate from '@/components/composables/aceTemplate'
import useAntTableScroll from '@/components/composables/antTableScroll'
import useResponses from '@/components/composables/responses'
import useTable from '@/components/composables/table'
import CPTPreview from '@/components/cpt/CPTPreview.vue'
import { EVENTS as PROB_EVENTS } from '@/components/input/common'
import Prob from '@/components/input/Prob.vue'
import ProbAgg from '@/components/input/ProbAgg.vue'
import ProbSelector from '@/components/input/ProbSelector.vue'
import { RowData, TABLE_EVENTS } from '@/components/method-common/Table'
import VariableContext from '@/components/survey/VariableContext.vue'
import { Condition } from '@/libs/bayes/Condition'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { AceDstSimpleCPT } from '@/libs/bayes/methods/AceDstSimpleCPT'
import { CPT } from '@/libs/bayes/methods/CPT'
import { Network } from '@/libs/bayes/Network'
import { State } from '@/libs/bayes/State'
import { Variable } from '@/libs/bayes/Variable'
import { getStateStyle } from '@/libs/utils'
import { ResponseSchema, SurveySchema } from '@/types'

import AceLikelihood from './AceLikehood.vue'

export default defineComponent({
  components: {
    VariableContext,
    AceLikelihood,
    Prob,
    ProbAgg,
    ProbSelector,
    CPTPreview
  },
  props: {
    network: { type: Network, required: true },
    currentSurvey: { type: Object as PropType<SurveySchema>, required: true },
    cpt: { type: Object as PropType<CPT>, required: true },
    selectedVariable: { type: Object as PropType<Variable>, required: true },
    responses: { type: Array as PropType<Array<ResponseSchema>>, default: () => [] },
    editable: { type: Boolean, default: false },
    allowPartialSave: { type: Boolean, default: false },
    isSaveButtonSpinning: { type: Boolean },
    analytics: { type: Object, default: undefined },
    userMap: { type: Object, default: undefined },
    isFull: { type: Boolean },
    isAnonymous: { type: Boolean },
    isStochastics: { type: Boolean, default: false }
  },
  emits: [...Object.values(TABLE_EVENTS)],
  setup(props, { emit }) {
    const isPreviewVisible = ref(false)
    const isAnalytics = computed(() => !isEmpty(props.analytics))
    const { responses, cpt, network, selectedVariable } = toRefs(props)

    const {
      parents,
      children,
      combinations,
      elicitedRows,
      dependents,
      independents,
      dependentMap,
      selectedState
    } = useTable(network, selectedVariable, cpt)

    const cptableWrapper = ref(null)
    const depKeyMapper: Ref<Record<string, any>> = ref({})
    const cptValues: Ref<Array<Array<number>> | null> = ref([[]])
    const depPermutation: Ref<Array<any>> = ref([])
    const isTerminal = computed(() => !parents?.value.length)
    const cSurvey = computed(() => props.currentSurvey)

    const {
      COMBKEY_PREFIX,
      isValid,
      responseMap,
      onResponseChange,
      genResponsesPayload,
      validMap
    } = useResponses(responses, combinations, elicitedRows, cpt, dependentMap)
    const { tableWrapper, scrollX, scrollY } = useAntTableScroll(dependents, independents)
    const { aceWeights, aceLikelihood } = useAceTemplate(
      cSurvey,
      selectedVariable,
      selectedState,
      parents
    )

    const columns = computed(() => {
      const reset = () => {
        depPermutation.value = []
      }

      const multiplexStates = (leaves: Array<any>, allStates: Array<Condition>): Array<any> => {
        let newLeaves: Array<any> = []
        leaves.forEach((node) => {
          const children = allStates.map(({ variable, state }: Condition, index) => {
            let prefix = node.key
            const prevKey = node.dataIndex + '-'
            const depCombKey = `${prevKey}${variable.id}+${state.id}`
            const combination = node.combination
              ? [...node.combination].concat([[variable.id, state.id]])
              : []
            return {
              title: state.name,
              key: `${prefix}-${index}`,
              dataIndex: depCombKey,
              align: 'center',
              combination,
              polarity: state.polarity,
              customHeaderCell: (col: any) => {
                return {
                  style: {
                    ...getStateStyle(col.polarity)
                  }
                }
              }
            }
          })
          node.children = children
          newLeaves = newLeaves.concat(children)
        })
        depPermutation.value = newLeaves
        return newLeaves
      }

      const root = {
        title: 'States',
        align: 'center',
        key: 'dep',
        dataIndex: COMBKEY_PREFIX,
        combination: []
      }
      let leaves = [root]
      const depVars: any = {
        title: 'Dependents',
        align: 'left',
        width: 400
      }
      let v = depVars
      if (dependents.value.length) {
        dependents.value.forEach((variable: Variable) => {
          const allStates = variable.getAllStates()
          leaves = multiplexStates(leaves, allStates)
          // first column
          v.children = [
            {
              title: variable.name,
              width: 400
            }
          ]
          v = v.children[0]
        })
      } else {
        reset()
      }
      leaves.forEach((leaf: any, index) => {
        leaf.width = 120
        leaf.colIndex = index + 1
        leaf.slots = {
          customRender: 'response'
        }
        depKeyMapper.value[leaf.dataIndex] = leaf.combination
      })
      v.key = 'name'
      v.dataIndex = 'name'
      v.slots = {
        customRender: 'first-col'
      }
      v.fixed = 'left'
      v.width = 400
      v.align = 'left'
      v.onCell = (record: any) => {
        if (!record.isHtml) {
          return {
            ellipsis: true
          }
        }
        return {}
      }
      // const firstCol = {
      //   key: 'name2',
      //   dataIndex: 'name',
      //   title: 'FirstCol',
      //   align: 'center',
      //   fixed: 'left',
      //   width: 400,
      //   slots: {
      //     customRender: 'first-col'
      //   }
      // }
      // return [firstCol, root]
      return [depVars, root]
    })

    const genCoreData = (variable?: Variable, state?: State) => {
      const indepPair = variable && state ? `-${variable.id}+${state.id}` : ''
      if (depPermutation.value.length) {
        return depPermutation.value.reduce((acc, leaf) => {
          const key = `${leaf.dataIndex}${indepPair}`
          acc[leaf.dataIndex] = responseMap.value.get(key) || {
            combKeyx: leaf.dataIndex
          }
          return acc
        }, {})
      }
      const key = `${COMBKEY_PREFIX}${indepPair}`
      return {
        [COMBKEY_PREFIX]: responseMap.value.get(key) || {
          combKeyx: key
        }
      }
    }

    const data = computed(() => {
      const rows: Array<RowData> = [
        {
          isLabel: true,
          name: '',
          key: 'preview',
          ...depPermutation.value.reduce(
            (acc, leaf) => {
              acc[leaf.dataIndex] = leaf.dataIndex
              return acc
            },
            { 'v+s': 'v+s' }
          )
        },
        {
          isHtml: true,
          name: aceLikelihood.value,
          key: 'ace-likehood'
        },
        {
          isLikelihood: true,
          isHtml: true,
          name: props.isFull ? 'Best-case and<br />Worst-case scenario' : 'Best-case scenario',
          key: 'best',
          ...genCoreData()
        },
        {
          isHtml: true,
          name: aceWeights.value,
          key: 'ace-weights'
        },
        {
          isLabel: true,
          name: 'Independents/Contributors',
          key: 'label'
        }
      ]

      const indepRows = independents.value.map((variable: Variable) => {
        const data: RowData = {}
        let { state } = variable.getPositiveState()
        data.key = variable.id
        data.varName = variable.name
        data.stateName = state.name
        data.name = variable.name // + ' (' + state.name + ')'
        data.isLikelihood = false
        const coreData = genCoreData(variable, state)
        return Object.assign(data, coreData)
      })
      // onResize()
      return rows.concat(indepRows)
    })

    const onResponsesChange = () => {
      const payload = genResponsesPayload(props.allowPartialSave)
      if (payload) {
        emit(TABLE_EVENTS.ON_RESPONSES_CHANGE, payload)
      }
    }

    const preview = (depCombKey: string) => {
      const ace = props.cpt as AceDstSimpleCPT
      const values = ace.calculateCPT(depCombKey, responseMap.value)
      if (values) {
        cptValues.value = values
        isPreviewVisible.value = true
      }
    }

    return {
      cptValues,
      PROB_EVENTS,
      VariableRelation,
      children,
      columns,
      combinations,
      elicitedRows,
      cptableWrapper,
      depPermutation,
      data,
      dependents,
      independents,
      isValid,
      onResponsesChange,
      onResponseChange,
      parents,
      selectedState,
      responseMap,
      validMap,
      scrollX,
      scrollY,
      tableWrapper,
      preview,
      isPreviewVisible,
      isAnalytics,
      isTerminal
    }
  }
})
</script>

<style lang="stylus">
@import './styles/index.styl'
</style>
