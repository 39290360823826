<template>
  <div class="sza-output-selector">
    <div class="heading">Output Selector</div>
    <a-table
      size="small"
      :data-source="dataSource"
      :columns="columns"
      :pagination="false"
      :row-selection="rowSelection"
    >
      <template #name="{ record }">
        <div v-if="record.networkKey">{{ record.networkKey }}:{{ record.nodeKey }}</div>
        <div v-else>
          {{ record.key }}
        </div>
      </template>
      <template #childrenKeys="{ text: children }">
        <div v-if="children">
          <a-tag v-for="child in children" :key="child">
            {{ child }}
          </a-tag>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, defineComponent, PropType, ref, unref } from 'vue'

export const EVENTS = {
  CHANGE: 'CHANGE'
}

export const OutputType = {
  CRITERION: 'CRITERION',
  CRITERION_NODE: 'CRITERION_NODE',
  NODE: 'NODE'
}

type Key = ColumnProps['key']

export interface OutputSelectionRow {
  key: Key
  nodeKey?: string
  networkKey?: string
  type: string
  childrenKeys?: string[]
}

export default defineComponent({
  props: {
    workspaceId: { type: String, default: undefined },
    outputRows: { type: Array as PropType<OutputSelectionRow[]>, default: undefined },
    networkMap: { type: Object, default: undefined },
    criteriaMap: { type: Object, default: undefined }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const columns = computed(() => {
      let columns = [
        {
          title: 'Output',
          dataIndex: 'key',
          key: 'name',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type'
        },
        {
          title: 'Sub Criteria',
          dataIndex: 'childrenKeys',
          key: 'childrenKeys',
          slots: {
            customRender: 'childrenKeys'
          }
        }
      ]
      return columns
    })

    const selectedRowKeys = ref<Key[]>([])

    const onSelectChange = (changableRowKeys: Key[]) => {
      selectedRowKeys.value = changableRowKeys
      emit(EVENTS.CHANGE, changableRowKeys)
    }

    const dataSource = computed(() => {
      return props.outputRows
    })

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        type: 'checkbox',
        onChange: onSelectChange
      }
    })

    return {
      dataSource,
      columns,
      rowSelection
    }
  }
})
</script>

<style lang="stylus">
.sza-output-selector {
  /*overflow: auto !important;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;*/
}
</style>
