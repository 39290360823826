import { message } from 'ant-design-vue'
import { computed, Ref, ref } from 'vue'
import { useRequest } from 'vue-request'

import { AHP_RESULTS, APISETS } from '@/services/api/ahp/set'
import { AhpResults } from '@/types/database/ahp'

import { CriteriaWeightsType } from './hybrid-types'

export type MapOfResults = Record<string, Record<string, AhpResults>>

export default function useHybridResults(workspaceId: string): any {
  const consolidatedResults: Ref<Record<string, AhpResults>> = ref({})

  const currentResultsList: Ref<AhpResults[] | undefined> = ref([])
  const currentResultsMap: Ref<MapOfResults> = computed(() => {
    if (!currentResultsList.value) {
      return {}
    }
    const map: MapOfResults = {}
    currentResultsList.value?.forEach((results: AhpResults) => {
      const { userId, parentKey, isConsolidated } = results
      if (!map[userId]) {
        map[userId] = {}
      }
      map[userId][parentKey] = results
      if (isConsolidated) {
        consolidatedResults.value[parentKey] = results
      }
    })
    return map
  })

  const { gets, create, update } = APISETS[AHP_RESULTS].method

  const onResultsGetSuccess = (data: any) => {
    currentResultsList.value = data
  }

  const { runAsync: runGetResults } = useRequest(gets, {
    manual: true,
    onSuccess: onResultsGetSuccess
  })

  const { runAsync: runUpdateResults, loading: isUpdatingResults } = useRequest(update, {
    manual: true
  })

  const { runAsync: runCreateResults, loading: isCreatingResults } = useRequest(create, {
    manual: true
  })
  const isPersistingResults = computed(() => isUpdatingResults.value || isCreatingResults.value)

  const loadResults = async (userId: string | undefined = undefined) => {
    if (!userId) {
      await runGetResults(workspaceId, { params: { page: 0, size: 1000 } })
    } else {
      await runGetResults(workspaceId, { params: { userId, page: 0, size: 1000 } })
    }
  }

  const saveResults = async (
    userId: string,
    selectedParentKey: string,
    criteriaWeights: CriteriaWeightsType[]
  ) => {
    const currentUserResultsMap = currentResultsMap.value[userId] || {}
    const results: AhpResults | undefined = currentUserResultsMap[selectedParentKey]
    if (!results || !results.id) {
      message.error('You can not overwrite results that have not been calculated')
      return
    }
    const weightMap: Record<string, any> = {}
    results.weights.forEach((w: any) => {
      weightMap[w.key] = w
    })
    criteriaWeights.forEach(async ({ key, elicitedWeight = 0.0 }: CriteriaWeightsType) => {
      if (!key) {
        return
      }
      weightMap[key].elicitedValue = elicitedWeight / 100
    })
    await runUpdateResults(workspaceId, results.id, results)
    await loadResults()
    setTimeout(() => message.success('Results saved'), 100)
  }

  return {
    saveResults,
    runUpdateResults,
    runCreateResults,
    isPersistingResults,
    currentResultsMap,
    currentResultsList,
    consolidatedResults,
    loadResults
  }
}
