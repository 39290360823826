import { isEmpty } from 'lodash-es'
import { computed, Ref } from 'vue'

import { SURVEY_TEMPLATE_VARIABLES } from '@/constants/components'
import { State, Variable } from '@/libs/bayes'
import { SurveySchema } from '@/types'

export default function useAceTemplate(
  currentSurvey: Ref<SurveySchema | undefined>,
  selectedVariable: Ref<Variable | undefined>,
  selectedState: Ref<State | undefined>,
  parents: Ref<Array<Variable> | undefined>
): any {
  /**
   * Replace variables into HTML DOM in survey template
   * @param text
   */
  const replaceVariableIntoComponent = (text: string) => {
    const parentRegex = new RegExp(`{{\\s*${SURVEY_TEMPLATE_VARIABLES.PARENT}\\s*}}`, 'g')
    const selectedVariableRegex = new RegExp(
      `{{\\s*${SURVEY_TEMPLATE_VARIABLES.SELECTED}\\s*}}`,
      'g'
    )
    const selectedStateRegex = new RegExp(
      `{{\\s*${SURVEY_TEMPLATE_VARIABLES.SELECTED_STATE}\\s*}}`,
      'g'
    )

    const parentComponent = `${(parents?.value || [])
      .map((p: Variable) => `<span class="sz-var-symbol-inline">${p.name}</span>`)
      .join(', ')}`

    const selectedVariableComponent = `<span class="sz-var-symbol-inline selected">${selectedVariable?.value?.name}</span>`
    const selectedStateComponent = `<span class="sz-var-selected-state-${selectedState?.value?.polarity}">${selectedState?.value?.name}</span>`

    return `<p>${text
      .replace(parentRegex, parentComponent)
      .replace(selectedVariableRegex, selectedVariableComponent)
      .replace(selectedStateRegex, selectedStateComponent)}</p>`
  }

  const aceLikelihood = computed(() => {
    const survey = currentSurvey.value
    if (survey && !isEmpty(survey.ext)) {
      let text = survey?.ext?.ace_likelihood
      if (!text || !text.length) {
        text =
          '<strong>Likelihood probabilities (0 to 100%)</strong> of {{ selected }}for the best-case scenario (all contributing effects are in favorable state) and the worst-case scenario (all contributing effects are in unfavorable state).'
      }
      return replaceVariableIntoComponent(text)
    }
    return ''
  })

  const aceWeights = computed(() => {
    const survey = currentSurvey.value
    if (survey && !isEmpty(survey.ext)) {
      let text = survey?.ext?.ace_weights
      if (!text || !text.length) {
        text =
          '<strong>Weights (0 to 100)</strong>:  Identify the effect (below) with the greatest importance for contributing to {{ selected }} and associate a weight of 100 to it. Allocate weights to the remaining effects in relation to the one with greatest importance.'
      }
      return replaceVariableIntoComponent(text)
    }
    return ''
  })

  return {
    aceWeights,
    aceLikelihood
  }
}
