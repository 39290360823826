import { ActionTree } from 'vuex'

import {
  createSurveyStatus,
  deleteSurveyStatus,
  distributeSurvey,
  getSurveyStatus,
  getSurveyStatuses,
  updateSurveyStatus
} from '@/services/api/surveyStatus'
import { SurveyStatusActionEnum } from '@/store/enums/actions/surveyStatus'
import { SurveyStatusMutationEnum } from '@/store/enums/mutations/surveyStatus'
import { RootState } from '@/store/types'
import { SurveyStatusActions } from '@/store/types/actions/surveyStatus'
import { SurveyStatusState } from '@/store/types/states/surveyStatus'
import { SurveyStatus, SurveyStatusList } from '@/types'

/**
 * Action function
 */
export const actions: ActionTree<SurveyStatusState, RootState> & SurveyStatusActions = {
  // Get surveyStatuss
  async [SurveyStatusActionEnum.GET_SURVEY_STATUSES]({ commit }, payload) {
    commit(SurveyStatusMutationEnum.GET_SURVEY_STATUSES, undefined)
    try {
      const res: SurveyStatusList = await getSurveyStatuses(payload)
      commit(SurveyStatusMutationEnum.GET_SURVEY_STATUSES_FULFILLED, res)
      return res
    } catch (err) {
      commit(SurveyStatusMutationEnum.GET_SURVEY_STATUSES_REJECTED, err)
      throw err as Error
    }
  },
  // Get surveyStatus
  async [SurveyStatusActionEnum.GET_SURVEY_STATUS]({ commit }, id) {
    commit(SurveyStatusMutationEnum.GET_SURVEY_STATUS, undefined)
    getSurveyStatus(id)
      .then((res: SurveyStatus) => {
        commit(SurveyStatusMutationEnum.GET_SURVEY_STATUS_FULFILLED, res)
      })
      .catch((err: Error) => {
        commit(SurveyStatusMutationEnum.GET_SURVEY_STATUS_REJECTED, err)
      })
  },
  // Create surveyStatus
  async [SurveyStatusActionEnum.CREATE_SURVEY_STATUS]({ commit }, payload) {
    commit(SurveyStatusMutationEnum.CREATE_SURVEY_STATUS, undefined)
    try {
      const res = await createSurveyStatus(payload)
      commit(SurveyStatusMutationEnum.CREATE_SURVEY_STATUS_FULFILLED, res)
    } catch (err) {
      commit(SurveyStatusMutationEnum.CREATE_SURVEY_STATUS_REJECTED, err)
    }
  },
  // Distribute survey
  async [SurveyStatusActionEnum.DISTRIBUTE_SURVEY](
    { commit },
    { id, surveyUrl, surveyUrlVariableName, userNameVariableName, surveyNameVariableName }
  ) {
    return new Promise((resolve, reject) => {
      commit(SurveyStatusMutationEnum.DISTRIBUTE_SURVEY, undefined)
      distributeSurvey({
        id,
        surveyUrl,
        surveyUrlVariableName,
        userNameVariableName,
        surveyNameVariableName
      })
        .then((res: SurveyStatus) => {
          commit(SurveyStatusMutationEnum.DISTRIBUTE_SURVEY_FULFILLED, res)
          resolve(res)
        })
        .catch((err) => {
          commit(SurveyStatusMutationEnum.DISTRIBUTE_SURVEY_REJECTED, err)
          reject(err)
        })
    })
  },
  // Update surveyStatus
  async [SurveyStatusActionEnum.UPDATE_SURVEY_STATUS]({ commit }, { id, surveyStatus }) {
    commit(SurveyStatusMutationEnum.UPDATE_SURVEY_STATUS, undefined)
    updateSurveyStatus({ id, surveyStatus })
      .then((res: SurveyStatus) => {
        commit(SurveyStatusMutationEnum.UPDATE_SURVEY_STATUS_FULFILLED, res)
      })
      .catch((err: Error) => {
        commit(SurveyStatusMutationEnum.UPDATE_SURVEY_STATUS_REJECTED, err)
      })
  },
  // Delete surveyStatus
  async [SurveyStatusActionEnum.DELETE_SURVEY_STATUS]({ commit }, id) {
    commit(SurveyStatusMutationEnum.DELETE_SURVEY_STATUS, undefined)
    deleteSurveyStatus(id)
      .then(() => {
        commit(SurveyStatusMutationEnum.DELETE_SURVEY_STATUS_FULFILLED, id)
      })
      .catch((err: Error) => {
        commit(SurveyStatusMutationEnum.DELETE_SURVEY_STATUS_REJECTED, err)
      })
  }
}
