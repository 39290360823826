import { render } from "./WhatIfWorkspace.vue?vue&type=template&id=00d0c979"
import script from "./WhatIfWorkspace.vue?vue&type=script&lang=ts"
export * from "./WhatIfWorkspace.vue?vue&type=script&lang=ts"

import "./WhatIfWorkspace.vue?vue&type=style&index=0&id=00d0c979&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "00d0c979"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('00d0c979', script)) {
    api.reload('00d0c979', script)
  }
  
  module.hot.accept("./WhatIfWorkspace.vue?vue&type=template&id=00d0c979", () => {
    api.rerender('00d0c979', render)
  })

}

script.__file = "src/components/analysis/whatif/WhatIfWorkspace.vue"

export default script