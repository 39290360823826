<template>
  <div class="sz-prob-continuous">
    <div class="sz-prob-number">
      <a-input-number
        v-model:value="val"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        @change="change"
      />
    </div>
    <div class="sz-prob-slider">
      <a-slider
        v-model:value="val"
        :step="step"
        :min="min"
        :max="max"
        :marks="marks"
        :disabled="disabled"
        @change="change"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { message } from 'ant-design-vue'
import { is, reduce } from 'ramda'
import { defineComponent, ref, watch } from 'vue'

import { Variable } from '@/libs/bayes/Variable'

export const EVENTS = {
  CHANGE: 'update:modelValue',
  USER_CHANGE: 'onUserChange'
}

export default defineComponent({
  components: {},
  props: {
    modelValue: { type: Number, default: undefined },
    variable: Variable,
    config: { type: Object, default: undefined },
    isDeterministic: { type: Boolean, default: false },
    disabled: { type: Boolean }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const min = ref(props.config?.min)
    const max = ref(props.config?.max)
    const step = ref(props.isDeterministic ? 100 : 1)
    const val = ref<number | undefined>(props.modelValue)
    const marks = ref(
      reduce(
        (acc: Record<string, number>, val) => {
          acc[`${val}`] = val
          return acc
        },
        {},
        props.isDeterministic ? [0, 100] : [0, 25, 50, 75, 100]
      )
    )
    watch(
      () => props.modelValue,
      (v) => {
        val.value = is(Number, v) ? v : undefined
      }
    )
    watch(val, (v) => {
      if (props.isDeterministic) {
        if (v !== 0 && v !== 100) {
          message.info('This is a deterministic node that requires either 0 or 100 value')
          if (typeof v != 'undefined') {
            if (v < 50) {
              v = 0
            } else {
              v = 100
            }
          }
        }
      }
      emit(EVENTS.CHANGE, is(Number, v) ? v : undefined)
    })

    const change = () => {
      emit(EVENTS.USER_CHANGE, val.value)
    }

    return {
      change,
      step,
      marks,
      max,
      min,
      val
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/vars.styl"
@import "../../styles/commons.styl"

.sz-prob-continuous
  display flex
  flex-box-direction row

  .sz-prob-slider
    @extend .centered
    flex: 1 1 auto
    margin: 0 7px 0 10px
    padding: 2px 0
    width 200px

  .sz-prob-number
    @extend .centered

  .ant-slider-rail
    background-color: #f0ffff

  .ant-slider-with-marks
    margin: 8px 6px 24px 6px

  .ant-slider-mark
    font-size: 12px

  .ant-slider-mark-text
    color: rgba(0, 0, 0, 0.30)

  .ant-slider-mark-text-active
      color: rgba(0, 0, 0, 0.30)

  .ant-input-number-input
    font-size 18px

  .ant-input-number
    width: 72px
</style>
