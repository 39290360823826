import { isNil } from 'ramda'

import { AnalyticsRecord } from '@/components/composables/analytics'
import { Condition, Network, State, Variable } from '@/libs/bayes'
import {
  anonymizeRationale,
  complexRow,
  Dict,
  normalizeName,
  normalizeWeights,
  numberCell,
  rounder,
  simpleRow
} from '@/libs/common'
import { DefaultDict } from '@/libs/utils'
import { User } from '@/types'

import { Combination, CombinationRow } from '../Combination'
import { ResponseMap } from '../CPTResponse'
import { CPTMethod } from '../enums/CPTMethod'
import { BEST_LIKELIHOOD, WORST_LIKELIHOOD } from '../State'
import { LIKELIHOOD } from '../Variable'
import { AceDstSimpleCPT } from './AceDstSimpleCPT'

export class AceDstFullCPT extends AceDstSimpleCPT {
  constructor(network: Network, variable: Variable, state: State, id?: string) {
    super(network, variable, state, id, CPTMethod.ACE_DST)
    this.prepCalc()
  }

  generateElicitedRows(): void {
    const { dependents, independents } = this
    const dependentCombs = this.generateDependentCombinations()
    let combinations: Array<Combination> = []
    const likelihoodCombinations: Array<Combination> = []

    const variableStates = this.variable.getAllStates()
    for (let j = 0; j < variableStates.length; j++) {
      const { variable, state } = variableStates[j]
      likelihoodCombinations.push([
        { variable, state },
        { variable: LIKELIHOOD, state: WORST_LIKELIHOOD }
      ])
      likelihoodCombinations.push([
        { variable, state },
        { variable: LIKELIHOOD, state: BEST_LIKELIHOOD }
      ])
    }

    if (dependents.length === 0) {
      combinations = likelihoodCombinations
      for (let i = 0; i < dependentCombs.length; i++) {
        const dependentComb = dependentCombs[i]
        const posCombs = this.multiplexPositive(dependentComb, independents)
        combinations = combinations.concat(posCombs)
      }
    } else {
      combinations = this.product(dependentCombs, likelihoodCombinations)
      for (let i = 0; i < dependentCombs.length; i++) {
        const dependentComb = dependentCombs[i]
        const posCombs = this.multiplexPositive(dependentComb, independents)
        combinations = combinations.concat(posCombs)
      }
    }

    this.elicitedCombinations = combinations
    this.elicitedRows = combinations.map((combination) => new CombinationRow(combination))
  }

  calculateCPT(depCombKey: string, responseMap: ResponseMap): Array<Array<number>> | null {
    if (isNil(this.indepComb) || isNil(this.contributions)) {
      return null
    }
    const criticals: Array<boolean> = []
    const ratings: Array<number> = []
    const bestLikelihoods: Array<number> = []
    const worstLikelihoods: Array<number> = []
    const likelihoodRanges: Array<number> = []
    const weights: Array<Array<number>> = []
    for (let i = 0; i < this.independents.length; i++) {
      const variable: Variable = this.independents[i]
      const { state } = variable.getPositiveState()
      const indepPair = variable && state ? `-${variable.id}+${state.id}` : ''
      const key = `${depCombKey}${indepPair}`
      const rating = responseMap.get(key)?.response?.value
      if (isNil(rating)) {
        return null
      }
      criticals.push(responseMap.get(key)?.response?.ext?.critical || false)
      ratings.push(rating / 100.0)
    }
    const allStates = this.variable.getAllStates()
    for (let j = 0; j < allStates.length; j++) {
      const { variable, state } = allStates[j]
      const indepPair = variable && state ? `-${variable.id}+${state.id}` : ''
      const key = `${depCombKey}${indepPair}`
      const worstKey = `${key}-${LIKELIHOOD.id}+${WORST_LIKELIHOOD.id}`
      const bestKey = `${key}-${LIKELIHOOD.id}+${BEST_LIKELIHOOD.id}`
      let bestLikelihood = responseMap.get(bestKey)?.response?.value
      if (isNil(bestLikelihood)) {
        return null
      }
      bestLikelihood /= 100.0
      let worstLikelihood = responseMap.get(worstKey)?.response?.value
      if (isNil(worstLikelihood)) {
        return null
      }
      worstLikelihood /= 100.0
      const likelihoodRange = bestLikelihood - worstLikelihood
      worstLikelihoods.push(worstLikelihood)
      bestLikelihoods.push(bestLikelihood)
      likelihoodRanges.push(likelihoodRange)
    }
    for (let i = 0; i < this.variable.getAllStates().length; i++) {
      weights.push(normalizeWeights(ratings, likelihoodRanges[i]))
    }
    const indepComb = this.indepComb
    const contributions = this.contributions
    const parentNum = this.independents.length
    const cptRowNum = indepComb.length
    const childStateNum = this.variable.length
    const cpt: Array<Array<number>> = new Array(cptRowNum)
    let temp
    for (let l = 0; l < cptRowNum; l++) {
      cpt[l] = new Array(childStateNum)
      const combRow = indepComb[l]
      for (let k = 0; k < childStateNum; k++) {
        temp = 0
        for (let j = 0; j < parentNum; j++) {
          const contrib = contributions[j][combRow[j]]
          temp = temp + weights[k][j] * contrib
        }
        temp = worstLikelihoods[k] + temp
        cpt[l][k] = temp
      }
    }
    // modify critical nodes
    for (let l = 0; l < cptRowNum; l++) {
      const combRow = indepComb[l]
      for (let j = 0; j < parentNum; j++) {
        if (criticals[j] && combRow[j] === 0) {
          for (let k = 0; k < childStateNum; k++) {
            cpt[l][k] = cpt[0][k]
          }
        }
      }
    }
    return cpt
  }

  generateTabularData(responseMap: Record<string, any>): any[] {
    const { dependentCombinations, dependents, independents } = this
    const data: any[] = []
    // const depCombLength = dependentCombinations.length
    const allVarStates = this.variable.getAllStates()

    const depHeaders: any[] = []
    dependents.forEach((_, index) => {
      depHeaders.push(`DepVar${index}`)
      depHeaders.push(`DepState${index}`)
    })
    data.push(simpleRow(['ACE']))
    data.push(
      simpleRow(['Variable', normalizeName(this.variable.name), `(${allVarStates.length} states)`])
    )
    data.push(simpleRow(['']))
    data.push(simpleRow([...depHeaders, 'Variable/Likelihood', 'Value', 'Rationale']))
    dependentCombinations.forEach((combination: Combination) => {
      const depCombKey = this.makeCombKey(combination)
      const combData: string[] = []
      combination.forEach(({ state, variable }: Condition) => {
        combData.push(normalizeName(variable.name))
        combData.push(state.name)
      })
      const worstValues: string[] = []
      const bestValues: string[] = []
      let worstRationales = ''
      let bestRationales = ''
      allVarStates.forEach(({ variable, state }) => {
        const key = `${depCombKey}-${variable.id}+${state.id}`
        const keyWorst = `${key}-${LIKELIHOOD.id}+${WORST_LIKELIHOOD.id}`
        const keyBest = `${key}-${LIKELIHOOD.id}+${BEST_LIKELIHOOD.id}`
        const worstResponse = responseMap.get(keyWorst)?.response
        const bestResponse = responseMap.get(keyBest)?.response
        if (worstResponse) {
          worstValues.push(`${rounder(worstResponse.value, 2)}`)
          worstRationales += worstResponse.rationale || ''
        }
        if (bestResponse) {
          bestValues.push(`${rounder(bestResponse.value, 2)}`)
          bestRationales += bestResponse.rationale || ''
        }
      })
      const worstRow: string[] = [...combData, 'WORST_LH', worstValues.join(','), worstRationales]
      const bestRow: string[] = [...combData, 'BEST_LH', bestValues.join(','), bestRationales]
      data.push(simpleRow(worstRow))
      data.push(simpleRow(bestRow))
      independents?.forEach((indepVariable: Variable) => {
        const { state: indepState } = indepVariable.getPositiveState()
        const key = depCombKey + `-${indepVariable.id}+${indepState.id}`
        const value = responseMap.get(key)?.response?.value
        const rationale = responseMap.get(key)?.response?.rationale || ''
        const critical = responseMap.get(key)?.response?.ext?.critical || false
        const indepCombRow = [
          ...combData,
          normalizeName(indepVariable.name),
          numberCell(value),
          `${critical ? '(C)' : ''} ${rationale}`
        ]
        data.push(complexRow(indepCombRow))
      })
    })

    return data
  }

  generateAnalyticsTabularData(
    analyticsMap: Record<string, AnalyticsRecord>,
    users: User[],
    userMap: Dict,
    isAnonymous = false
  ): any[] {
    // Use [...userIds, 'final'] for adding summary
    if (!this.elicitedRows) {
      return []
    }
    const record: AnalyticsRecord = analyticsMap[this.variable.id]
    if (!record) {
      return []
    }
    const analyticsByUserMap: DefaultDict = record.userMap
    const data: any[] = []

    const { dependentCombinations, dependents, independents } = this
    const allVarStates = this.variable.getAllStates()

    const depHeaders: any[] = ['User ID']
    dependents.forEach((_, index) => {
      depHeaders.push(`DepVar${index}`)
      depHeaders.push(`DepState${index}`)
    })
    data.push(simpleRow(['ACE']))
    data.push(
      simpleRow(['Variable', normalizeName(this.variable.name), `(${allVarStates.length} states)`])
    )
    data.push(simpleRow(['']))

    data.push(simpleRow([...depHeaders, 'Variable/Likelihood', 'Value', 'Rationale']))
    users.forEach(({ id: userId }, userIndex: number) => {
      let responseMap: ResponseMap
      if (userId === 'final') {
        responseMap = this.generateResponseMapFromXAggByCombKey(record.xaggMap)
      } else {
        const userItem = analyticsByUserMap.get(userId)
        if (!userItem) {
          return
        }
        responseMap = this.generateResponseMapByCombKey(userItem.responseMap)
      }
      dependentCombinations.forEach((combination: Combination) => {
        const depCombKey = this.makeCombKey(combination)
        const combData: string[] = []
        combination.forEach(({ state, variable }: Condition) => {
          combData.push(normalizeName(variable.name))
          combData.push(state.name)
        })
        const worstValues: string[] = []
        const bestValues: string[] = []
        let worstRationales = ''
        let bestRationales = ''
        let worstUserName = 'final'
        let bestUserName = 'final'
        allVarStates.forEach(({ variable, state }) => {
          const key = `${depCombKey}-${variable.id}+${state.id}`
          const keyWorst = `${key}-${LIKELIHOOD.id}+${WORST_LIKELIHOOD.id}`
          const keyBest = `${key}-${LIKELIHOOD.id}+${BEST_LIKELIHOOD.id}`
          const worstResponse = responseMap.get(keyWorst)?.response
          const bestResponse = responseMap.get(keyBest)?.response
          let userId = worstResponse?.userId || 'final'
          worstUserName = this.generateUserName(userId, userIndex, userMap, isAnonymous)
          userId = bestResponse?.userId || 'final'
          bestUserName = this.generateUserName(userId, userIndex, userMap, isAnonymous)
          if (worstResponse) {
            worstValues.push(`${rounder(worstResponse.value, 2)}`)
            worstRationales += anonymizeRationale(worstResponse.rationale, isAnonymous)
          }
          if (bestResponse) {
            bestValues.push(`${rounder(bestResponse.value, 2)}`)
            bestRationales += anonymizeRationale(bestResponse.rationale, isAnonymous)
          }
        })
        const worstRow: string[] = [
          worstUserName,
          ...combData,
          'WORST_LH',
          worstValues.join(','),
          worstRationales
        ]
        const bestRow: string[] = [
          bestUserName,
          ...combData,
          'BEST_LH',
          bestValues.join(','),
          bestRationales
        ]
        data.push(simpleRow(worstRow))
        data.push(simpleRow(bestRow))
        independents?.forEach((indepVariable: Variable) => {
          const { state: indepState } = indepVariable.getPositiveState()
          const key = depCombKey + `-${indepVariable.id}+${indepState.id}`
          const response = responseMap.get(key)?.response
          if (!response) {
            return
          }
          const value = response.value
          const userId = response.userId || 'final'
          const userName = this.generateUserName(userId, userIndex, userMap, isAnonymous)
          const rationale = anonymizeRationale(response.rationale, isAnonymous)
          const critical = response.ext?.critical || false
          const indepCombRow = [
            userName,
            ...combData,
            normalizeName(indepVariable.name),
            numberCell(value),
            `${critical ? '(C)' : ''} ${rationale}`
          ]
          data.push(complexRow(indepCombRow))
        })
      })
    })
    return data
  }
}
