<template>
  <div class="sz-command-bar">
    <a-button type="link" @click="importCriteria">
      <template #icon>
        <IconFileImport :size="14" />
      </template>
      <span>Import</span>
    </a-button>
    <span>Scaling </span>
    <a-select
      v-model:value="selectedScalingMethod"
      :options="scalingMethodOptions"
      size="small"
      style="width: 200px; max-width: 400px"
    />
    <span>Calc. </span>
    <a-select
      v-model:value="eigenCalculationMethod"
      :options="eigenCalculationMethodOptions"
      size="small"
      style="width: 200px; max-width: 400px"
    />
    <a-button type="link" @click="saveCriteria">
      <template #icon>
        <IconDeviceFloppy :size="14" />
      </template>
      <span>Save</span>
    </a-button>
  </div>
  <div class="sza-taks">
    <a-table
      size="small"
      :data-source="criteriaRows"
      :columns="criteriaColumns"
      :pagination="false"
    >
      <template #children="{ text: childrenKeys }">
        <a-tag v-for="childrenKey in childrenKeys" :key="childrenKey">
          {{ childrenKey }}
        </a-tag>
      </template>
      <template #users="{ record }">
        <div v-if="record.userIdToWeightMap">
          <a-tag v-for="(username, idx) in Object.keys(record.userIdToWeightMap)" :key="idx">
            {{ username }} / {{ record.userIdToWeightMap[username] }}
          </a-tag>
        </div>
      </template>
      <template #utilityVector="{ record }">
        <utility-vector
          v-if="record.utilityVector"
          v-model="record.utilityVector"
          size="small"
          :constrained="false"
        />
      </template>
    </a-table>
  </div>
  <hybrid-hierarchy-import
    :is-visible="isImportVisible"
    :network-map="networkMap"
    @[IMPORT_EVENTS.OK]="importOk"
    @[IMPORT_EVENTS.CANCEL]="isImportVisible = false"
  />
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IconDeviceFloppy, IconFileImport } from '@tabler/icons-vue'
import { computed, defineComponent, Ref, ref } from 'vue'

import UtilityVector from '@/components/analysis/common/UtilityVector.vue'
import {
  eigenCalculationMethodOptions,
  scalingMethodOptions
} from '@/components/analysis/composable/hybrid-criteria'
import { cssIcon } from '@/styles/common'

import HybridHierarchyImport, { EVENTS as IMPORT_EVENTS } from './HybridHierarchyImport.vue'

export type CriteriaRowType = {
  parentKey?: string
  type?: string
  level?: number
  childrenKeys: string[]
  utilityVector?: number[]
  userIdToWeightMap?: Record<string, number>
}

export const EVENTS = {
  IMPORT: 'IMPORT',
  SAVE: 'SAVE'
}

export default defineComponent({
  components: {
    HybridHierarchyImport,
    IconFileImport,
    IconDeviceFloppy,
    UtilityVector
  },
  props: {
    criteriaRows: { type: Object, default: undefined },
    networkMap: { type: Object, default: undefined }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const isImportVisible: Ref<boolean> = ref(false)
    const selectedScalingMethod: Ref<string> = ref('LINEAR')
    const eigenCalculationMethod: Ref<string> = ref('EVM')

    const importCriteria = () => {
      isImportVisible.value = true
    }

    const criteriaColumns = computed(() => {
      return [
        {
          title: 'Parent',
          dataIndex: 'parentKey',
          key: 'parentKey'
        },
        {
          title: 'Children',
          dataIndex: 'childrenKeys',
          key: 'childrenKeys',
          slots: {
            customRender: 'children'
          }
        },
        {
          title: 'Util vector',
          dataIndex: 'utilityVector',
          key: 'utilityVector',
          slots: {
            customRender: 'utilityVector'
          }
        },
        {
          title: 'Usernames',
          dataIndex: 'usernames',
          key: 'usernames',
          slots: {
            customRender: 'users'
          }
        },
        {
          title: '',
          key: 'actions',
          slots: {
            customRender: 'actions'
          }
        }
      ]
    })

    // const criteriaRows: Ref<CriteriaRowType[]> = computed(() => [])
    //props.criteria?.map(({ key, parentKey, childrenKey, userIds }) => ({ key, parentKey, childrenKey, userIds }))

    const importOk = async (rawText: string) => {
      isImportVisible.value = false
      emit(EVENTS.IMPORT, rawText)
    }

    const saveCriteria = () => {
      emit(EVENTS.SAVE, {
        eigenCalculationMethod: eigenCalculationMethod.value,
        selectedScalingMethod: selectedScalingMethod.value
      })
    }

    return {
      scalingMethodOptions,
      selectedScalingMethod,
      eigenCalculationMethod,
      eigenCalculationMethodOptions,
      saveCriteria,
      criteriaColumns,
      isImportVisible,
      importCriteria,
      importOk,
      IMPORT_EVENTS,
      cssIcon
    }
  }
})
</script>
