
import {
  BarsOutlined,
  BuildOutlined,
  DeleteTwoTone,
  DeploymentUnitOutlined,
  EditTwoTone,
  SnippetsOutlined,
  UserSwitchOutlined
} from '@ant-design/icons-vue'
import { ArrowRightTail } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed, defineComponent, ref, toRefs } from 'vue'

import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import router from '@/router'
import { useStore } from '@/store'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { vuexActions } from '@/store/util'
dayjs.extend(relativeTime)
import { message } from 'ant-design-vue'

import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { gradientMaker } from '@/libs/utils'

export default defineComponent({
  components: {
    Icon,
    ArrowRightTail,
    BarsOutlined,
    BuildOutlined,
    DeleteTwoTone,
    DeploymentUnitOutlined,
    EditTwoTone,
    SnippetsOutlined,
    UserSwitchOutlined
  },
  props: {
    workspace: {
      type: Object,
      required: true
    },
    focus: {
      type: Boolean,
      required: false
    }
  },
  emits: [EMIT_EVENTS.WORKSPACE.CLICK_WORKSPACE_CARD],
  setup(props, { emit }) {
    const store = useStore()
    const isShowEditForm = ref(false)
    const { workspace } = toRefs(props)
    const workspaceId = workspace.value?.id
    const created = computed(() => dayjs(workspace.value.createdDate).fromNow())
    // FIXME: time incorrect
    const modified = computed(() => dayjs(workspace.value.lastModifiedDate).fromNow())

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(props.workspace?.name || 'UNTITLED'),
      boxShadow: props.focus
        ? '0 0 8px rgb(0 0 0 / 20%), 0 0 20px rgb(0 0 0 / 20%)'
        : '0 0 2px rgb(0 0 0 / 10%), 0 0 8px rgb(0 0 0 / 10%)'
    }))
    /**
     * Handle set whether display edit form
     **/
    const handleShowEditForm = (value: boolean) => {
      isShowEditForm.value = value
    }

    /**
     * Delete workspace
     */
    const deleteWorkspace = () => {
      store.dispatch(
        vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.DELETE_WORKSPACE),
        workspaceId
      )
      message.success(MESSAGE.WORKSPACE_DELETE_SUCCESS)
    }

    /**
     * Handle click each workspace, to enter specific workspace
     */
    const handleClickWorkspace = () => {
      router.push({ name: ROUTE_NAME.SURVEYS, params: { workspaceId } })
    }

    /**
     * Click card
     **/
    const handleClickEditButton = () => {
      emit(EMIT_EVENTS.WORKSPACE.CLICK_WORKSPACE_CARD, props.workspace)
    }

    return {
      ROUTE_NAME,
      created,
      modified,
      isShowEditForm,
      deleteWorkspace,
      handleShowEditForm,
      handleClickWorkspace,
      handleClickEditButton,
      workspaceId,
      cardStyle
    }
  }
})
