import { AuthStateEnum } from '@/store/enums/states/auth'
import { AuthState } from '@/store/types/states/auth'

/**
 * Default value
 */
const state: AuthState = {
  [AuthStateEnum.ERROR]: null,
  [AuthStateEnum.IS_AUTHORIZED]: false,
  [AuthStateEnum.IS_GETTING_CURRENT_USER]: false,
  [AuthStateEnum.IS_LOGGING_OUT]: false,
  [AuthStateEnum.IS_UPDATING_CURRENT_USER]: false,
  [AuthStateEnum.IS_VERIFYING]: false,
  [AuthStateEnum.TOKEN]: null,
  [AuthStateEnum.USER]: null,
  [AuthStateEnum.USER_PREFERENCE]: null
}

export { state }
