import { message } from 'ant-design-vue'
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import Qs from 'qs'

import { API_PREFIX, ENDPOINTS } from '@/constants/api'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { vuexActions } from '@/store/util'
interface paramsSerializerParams {
  [prop: string]: string // but this defeats the purpose of providing good typing.
}
declare module 'axios' {
  export interface AxiosRequestConfig {
    __isRetryRequest?: boolean
  }
}

const paramsSerializer = (params: paramsSerializerParams) =>
  Qs.stringify(params, { arrayFormat: 'repeat' })

// Bearer
const API = axios.create({
  baseURL: API_PREFIX,
  paramsSerializer
})

// Set auth head before each request
API.interceptors.request.use(
  (config) => {
    const store = useStore()
    const token = store.state[ModuleNames.AUTH][AuthStateEnum.TOKEN]
    if (token) {
      const authKey = token.token
      if (authKey) {
        config.headers['Authorization'] = 'Bearer ' + authKey
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const unauthorizedInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    async (error: AxiosError) => {
      // Vuex store
      const store = useStore()
      const originalRequest = error.config
      const errorCode = error?.response?.status
      const isRetryRequest = originalRequest.__isRetryRequest
      // For now, always allow the use of refresh token
      // const rememberMe = store.state[ModuleNames.AUTH][AuthStateEnum.USER_PREFERENCE]?.rememberMe
      if (errorCode === 401 && originalRequest && !isRetryRequest) {
        originalRequest.__isRetryRequest = true
        if (originalRequest.url === ENDPOINTS.AUTH_LOGIN) {
          return Promise.reject(error)
        }
        try {
          const token = await store.dispatch(
            vuexActions(ModuleNames.AUTH, AuthActionEnum.AUTH_REFRESH),
            undefined
          )
          originalRequest.headers.Authorization = 'Bearer ' + token?.token
          return axios(originalRequest)
        } catch (err) {
          if (err?.response?.status === 401) {
            message.error({
              content:
                'The session has expired. You will be redirected to the login page in 3 seconds.',
              duration: 3
            })
            await new Promise((resolve) => setTimeout(resolve, 3000))
            await store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.LOG_OUT))
          }
          return Promise.reject(err)
        }
      }
      return Promise.reject(error)
    }
  )

unauthorizedInterceptor(API)

export default API
