
import 'splitpanes/dist/splitpanes.css'

import { IconDeviceFloppy, IconTableExport } from '@tabler/icons-vue'
import { ChartBar, LayoutGridAdd, Refresh, Run } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { indexBy, prop } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import writeXlsxFile from 'write-excel-file'

import CommonAnalysis, {
  EVENTS as COMMON_EVENTS
} from '@/components/analysis/common/CommonAnalysis.vue'
import NetworkList, {
  EVENTS as NET_EVENTS
} from '@/components/analysis/common/network-list/NetworkList.vue'
import HybridTaskList, {
  EVENTS as TASK_EVENTS
} from '@/components/analysis/common/task-list/TaskList.vue'
import WorkspaceInfo from '@/components/analysis/common/workspace-info/WorkspaceInfo.vue'
import useAnalysis from '@/components/analysis/composable/analysis'
import useBase from '@/components/analysis/composable/base'
import useHybridCriteria from '@/components/analysis/composable/hybrid-criteria'
import useHybridSurveyStatus from '@/components/analysis/composable/hybrid-survey-status'
import { CriteriaRowType, ParentRowType } from '@/components/analysis/composable/hybrid-types'
import useJob from '@/components/analysis/composable/job'
import usePalette from '@/components/analysis/composable/palette'
import useWhatIf, {
  inputNodeMapper,
  outputNodeMapper
} from '@/components/analysis/composable/whatif-next'
import HybridApexChart from '@/components/analysis/hybrid/HybridApexChart.vue'
import HybridOutputSelector, {
  EVENTS as OUTPUT_EVENTS,
  OutputSelectionRow,
  OutputType
} from '@/components/analysis/hybrid/HybridOutputSelector.vue'
import { AnalysisTask } from '@/components/analysis/libs/common'
// // import WhatIfChart from '@/components/analysis/whatif/WhatIfChart.vue'
import WhatIfTable, { EVENTS as TABLE_EVENTS } from '@/components/analysis/whatif/WhatIfTable.vue'
import { CATEGORICAL_PALETTES, ColorSchemeItem } from '@/components/common/color-scheme'
import { EMIT_EVENTS } from '@/constants/emits'
import { ROUTE_NAME } from '@/constants/router'
import { User } from '@/types'
dayjs.extend(relativeTime)
import useHybridCharts from '@/components/analysis/composable/hybrid-charts'
import useHybridResults from '@/components/analysis/composable/hybrid-results'
import useHybridSelector from '@/components/analysis/composable/hybrid-selector'
import { CriteriaWeightsType } from '@/components/analysis/composable/hybrid-types'
import WhatIfBatchImport, {
  EVENTS as IMPORT_EVENTS
} from '@/components/analysis/whatif/WhatIfBatchImport.vue'
import { logger } from '@/libs/logger'
import { gradientMaker } from '@/libs/utils'
import { useStore } from '@/store'
import { UserStateEnum } from '@/store/enums/states'
import { cssIcon } from '@/styles/common'
// import { AhpResults } from '@/types/database/ahp'
import { JobType } from '@/types/database/job'

// import HybridAnalysis from './HybridAnalysis.vue'
import HybridCriteriaSetSelector, { EVENTS as SELECT_EVENTS } from './HybridCriteriaSetSelector.vue'
// import HybridPairwise, { EVENTS as PAIRWISE_EVENTS } from './HybridPairwise.vue'
import HybridFramework, { EVENTS as RES_EVENTS } from './HybridFramework.vue'
import HybridHierarchy, { EVENTS as MODEL_EVENTS } from './HybridHierarchy.vue'
import HybridUserStatusList from './HybridUserStatusList.vue'

const CATEGORICAL_PALETTE_MAP = indexBy(prop('name'), CATEGORICAL_PALETTES)

export const TABS = {
  PARAMS: 'PARAMS',
  MODEL: 'MODEL',
  SURVEY: 'SURVEY',
  PAIRWISE: 'PAIRWISE',
  ANALYSIS: 'ANALYSIS',
  RESULTS: 'RESULTS',
  TASKS: 'TASKS'
}

// type ResultMap = Record<string, AhpResults>
const CONSOLIDATE_USER_ID = '888888888888888888888888'
const ADD_INPUT_NODES = false

export default defineComponent({
  components: {
    IconTableExport,
    IconDeviceFloppy,
    HybridCriteriaSetSelector,
    ChartBar,
    CommonAnalysis,
    LayoutGridAdd,
    Run,
    NetworkList,
    Icon,
    Pane,
    Refresh,
    Splitpanes,
    HybridUserStatusList,
    HybridHierarchy,
    WhatIfBatchImport,
    HybridOutputSelector,
    HybridApexChart,
    WhatIfTable,
    WorkspaceInfo,
    HybridTaskList,
    HybridFramework
  },
  props: {
    unused: { type: String, default: undefined }
  },
  setup() {
    const activeTab = ref(TABS.PARAMS)
    const route = useRoute()
    const store = useStore()
    const userList = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content || [])
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const selectedTasks = ref(new Set<AnalysisTask>())
    const isBatchImportVisible: Ref<boolean> = ref(false)
    const isParsing: Ref<boolean> = ref(false)

    const chartTitle: Ref<string | undefined> = ref(undefined)
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const selectionConfig = {
      inputVisible: true,
      utilityVectorVisible: true,
      stateVisible: false,
      variationVisible: false,
      variationsVisible: false,
      constraintVisible: false,
      allowBothInputOutput: true,
      headers: {
        input: {
          title: 'Config',
          desc: 'Config Node'
        },
        output: {
          title: 'Output',
          desc: 'Output Node'
        }
      }
    }
    const {
      networks,
      loadWorkspace,
      loadNetworks,
      removeNetwork,
      workspace,
      workspaceCreated,
      workspaceModified
    } = useBase(workspaceId)

    const {
      paletteVisible,
      paletteName,
      paletteColors,
      selectPalette,
      showPalettePicker
    } = usePalette()

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }

    const toggleTask = (task: AnalysisTask) => {
      // task.isSelected = !task.isSelected
      if (task.isSelected) {
        selectedTasks.value.add(task)
      } else {
        selectedTasks.value.delete(task)
      }
    }

    const {
      networkMap,
      currentUser,
      nodeSelections,
      nodeSelectionMap,
      updateNodeSelections,
      inputNodeKeys,
      outputNodeKeys,
      updateInputOutputNodes,
      commonNodeKeys
    } = useAnalysis(workspaceId, JobType.WHATIF, networks)

    const currentUserId = computed(() => currentUser.value?.id)

    const { loadSurvey, dataSource, loadSurveyStatus } = useHybridSurveyStatus(
      workspaceId,
      workspace,
      userList
    )
    const completedMap = computed(() =>
      dataSource.value.reduce((acc: any, s: any) => {
        if (s.status === 'COMPLETED') {
          acc[s.userId] = 1
        }
        return acc
      }, {})
    )

    const {
      addTaskBaseline,
      isPersisting,
      abortTask,
      tasks,
      taskMap,
      clearTasks,
      loadJob,
      loadTasks,
      persistJob,
      persistTasks,
      addTask,
      initTasks,
      executeTask,
      removeTask,
      isUpdatingJob,
      isCreatingJob
    } = useJob(
      currentUser,
      workspaceId,
      JobType.HYBRID,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      onSuccessSaveJob
    )

    const criteriaRows: Ref<CriteriaRowType[]> = ref([])
    const criteriaMap: Ref<Record<string, any>> = ref([])
    const outputRows: Ref<OutputSelectionRow[]> = ref([])

    const {
      clearCharts,
      chartData,
      chartNodeIds,
      exportTabular,
      whatIfRows,
      onMoveDownRow,
      onMoveUpRow,
      updateWhatIfRows,
      executeAnalysis,
      preExecuteAnalysis,
      updateTasksFromWhatIfRows,
      parseWhatIfTasksRaw,
      isExecuting,
      maxOutputValue,
      minOutputValue,
      updateDeterministicValues
    } = useWhatIf(
      workspaceId,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      taskMap,
      updateTable,
      onSuccessSaveJob,
      criteriaRows
    )

    const updateOutputRows = () => {
      const outputRows_: OutputSelectionRow[] = []
      criteriaRows.value.forEach(({ parentKey = '', childrenKeys = [] }: CriteriaRowType) => {
        outputRows_.push({
          key: parentKey,
          type: childrenKeys.length > 1 ? OutputType.CRITERION : OutputType.CRITERION_NODE,
          childrenKeys
        })
      })
      const outputNodeMap: Record<string, number> = {}
      tasks.value.forEach((task: AnalysisTask) => {
        task.outputNodes.forEach(({ key, networkKey }) => {
          const eKey = networkKey ? networkKey + ':' + key : key
          if (!outputNodeMap[eKey]) {
            outputNodeMap[eKey] = 0
          }
          outputNodeMap[eKey]++
        })
      })
      Object.keys(outputNodeMap).forEach((key) => {
        let networkKey
        let nodeKey
        if (key.indexOf(':') > -1) {
          networkKey = key.substring(0, key.indexOf(':'))
          nodeKey = key.substring(key.indexOf(':') + 1)
        }
        outputRows_.push({
          key,
          nodeKey,
          networkKey,
          type: OutputType.NODE
        })
      })
      outputRows.value = outputRows_
    }

    watch(tasks, () => {
      updateOutputRows()
    })

    watch(criteriaRows, () => {
      const map: Record<string, any> = {}
      criteriaRows.value.forEach(
        ({
          parentKey = '',
          childrenKeys = [],
          utilityVector = [],
          userIdToWeightMap = {}
        }: CriteriaRowType) => {
          map[parentKey] = {
            parentKey,
            result: {},
            utilityVector,
            childrenKeys,
            userIdToWeightMap
          }
        }
      )
      updateOutputRows()
      criteriaMap.value = map
    })

    const {
      getInputNodesFromCriteria,
      parseRaw,
      currentCriteria,
      loadCriteria,
      persistCriteria,
      denormalizeCriteria
    } = useHybridCriteria(
      workspaceId,
      currentUser,
      networkMap,
      criteriaRows,
      updateTable,
      onSuccessSaveJob
    )

    const networkColumns = computed(() => {
      return [
        {
          title: 'Network name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Nodes count',
          dataIndex: 'nodes.length',
          key: 'nodes'
        }
      ]
    })

    const networkRows = computed(
      () =>
        networks.value?.map((network: any) => ({
          key: network.id,
          nodes: network.nodes,
          name: network.name
        })) || []
    )

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(workspace.value?.name || 'UNTITLED')
    }))

    const handleLoadNetwork = () => {
      loadNetworks()
    }

    const calculate = () => {
      executeAnalysis()
      updateTable()
    }

    const preCalculate = () => {
      preExecuteAnalysis(selectedTasks.value)
      updateTable()
    }

    const init = async () => {
      message.info('Initializing ...')
      initTasks()
      updateInputOutputNodes()
      updateWhatIfRows()
      await preExecuteAnalysis()
      message.success('Initialization completed')
    }

    watch(networks, async () => {
      logger.info('watch - networks')
      updateNodeSelections()
      await loadJob()
      await loadTasks()
      updateInputOutputNodes()
      updateWhatIfRows()
    })

    const refresh = async () => {
      await loadTasks()
      await loadSurvey()
      await loadSurveyStatus()
      updateInputOutputNodes()
      updateWhatIfRows()
      clearCharts()
    }

    watch(nodeSelections, () => {
      updateWhatIfRows()
    })

    onMounted(async () => {
      await loadWorkspace()
      await loadNetworks()
      await loadCriteria()
      await loadResults()
      await loadSurvey()
      await loadSurveyStatus()
    })

    const saveJob = async () => {
      updateTasksFromWhatIfRows()
      updateDeterministicValues()
      await persistJob({
        hybridConfig: {
          userId: currentUser.value.id
        }
      })
      await persistTasks({
        hybridConfig: {
          userId: currentUser.value.id
        }
      })
    }

    const selectTaskPalette = () => {
      paletteColors.value = CATEGORICAL_PALETTE_MAP[paletteName.value]?.items?.map(
        ({ color }: ColorSchemeItem) => color
      )
      paletteVisible.value = false
    }

    const onDuplicateTask = (taskId: string, taskIndex: number) => {
      updateTasksFromWhatIfRows()
      addTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    const onRemoveTask = (taskId: string, taskIndex: number) => {
      updateTasksFromWhatIfRows()
      removeTask(taskId, taskIndex)
      updateWhatIfRows()
    }

    const importBatch = () => {
      isBatchImportVisible.value = true
    }

    const parsingComplete = () => {
      setTimeout(() => {
        isParsing.value = false
      })
    }

    const importBatchOk = async (rawText: string) => {
      isBatchImportVisible.value = false
      isParsing.value = true
      const { criteriaList } = parseRaw(rawText)
      if (criteriaList) {
        if (currentCriteria.value) {
          currentCriteria.value.criteria = criteriaList
        }
        denormalizeCriteria()
        childrenKeys.value = criteriaList[0].childrenKeys
      }
      parsingComplete()
    }

    const {
      saveResults,
      runUpdateResults,
      runCreateResults,
      isPersistingResults,
      currentResultsMap,
      loadResults
    } = useHybridResults(workspaceId)

    const isLoadingResults = computed(
      () => runUpdateResults.value || runCreateResults.value || isPersistingResults.value
    )

    const selectedScalingMethod: Ref<string> = ref('LINEAR')

    const parentRows: ComputedRef<ParentRowType[]> = computed(() => {
      if (!criteriaRows.value) {
        return []
      }
      return criteriaRows.value
        .filter(({ childrenKeys }) => childrenKeys?.length > 0)
        .map(({ parentKey, childrenKeys, userIdToWeightMap }, index) => {
          return {
            key: index,
            parentKey,
            childrenKeys,
            selected: false,
            userIdToWeightMap
          }
        })
    })

    const { selectedUserId, childrenKeys, refreshResults } = useHybridSelector(
      workspaceId,
      currentUserId,
      currentResultsMap,
      true,
      selectedScalingMethod,
      () => {}
    )

    const showIndividualCharts: Ref<boolean> = ref(true)

    const selectedParentKeys: Ref<string[]> = ref([])
    const selectedOutputKeys: Ref<string[]> = ref([])

    const onSelectKeysForChart = (parentKeys: string[]) => {
      selectedParentKeys.value = parentKeys || []
    }

    const onSelectOuputForChart = (keys: string[]) => {
      selectedOutputKeys.value = keys || []
    }

    const updateResult = (parentKey: string, result: any) => {
      if (criteriaMap.value[parentKey]) {
        criteriaMap.value[parentKey].result = result
      }
    }

    const exportResults = async () => {
      const { datas, names } = exportTabular()
      await writeXlsxFile(datas, {
        sheets: names,
        fileName: workspace.value?.name + '-results.xlsx'
      })
    }

    const run = (task: AnalysisTask) => {
      executeTask(task)
    }

    const runAll = async () => {
      await persistJob({
        hybridConfig: {
          userId: selectedUserId.value || currentUser.value.id
        }
      })
      await persistTasks({
        hybridConfig: {
          userId: selectedUserId.value || currentUser.value.id
        }
      })
      message.info('All tasks are submitted to the batch execution')
      await executeTask()
      loadTasks()
    }

    const abort = (task: AnalysisTask) => {
      if (!abortTask(task)) {
        message.error('You need to save the job first')
      }
    }

    const remove = async (taskId: string, taskIndex: number) => {
      removeTask(taskId, taskIndex)
      await saveJob()
      updateWhatIfRows()
    }

    const importBatchWhatifOk = async (rawText: string) => {
      isBatchImportVisible.value = false
      isParsing.value = true
      const {
        newTasks,
        error,
        outputNodeKeys: outKeys,
        inputNodeKeys: inKeys
      } = parseWhatIfTasksRaw(rawText)
      if (error) {
        message.error(error)
        parsingComplete()
        return
      }
      clearTasks()
      tasks.value = newTasks
      outputNodeKeys.value = outKeys
      inputNodeKeys.value = inKeys
      updateWhatIfRows()
      parsingComplete()
    }

    const saveCriteria = (params: any) => {
      persistCriteria(params)
    }

    const userOptions = computed(() => {
      let options = userList.value.map(({ id, username }: User) => ({
        label: username,
        value: id
      }))
      options = [
        ...options.filter((o: any) => completedMap.value[o.value]),
        {
          label: 'consolidate',
          value: CONSOLIDATE_USER_ID
        }
      ]
      return options
    })

    watch(userOptions, () => {
      const isFound = userOptions.value.find((o: any) => o.value == selectedUserId.value)
      if (!isFound) {
        if (userOptions.value.length) {
          selectedUserId.value = userOptions.value[0].id
        } else {
          selectedUserId.value = undefined
        }
      }
    })

    const loadResultsHandler = () => {
      loadResults()
    }

    const refreshResultsHandler = () => {
      refreshResults()
    }

    const addBaseline = () => {
      const inputNodes = ADD_INPUT_NODES ? getInputNodesFromCriteria() : []
      addTaskBaseline(inputNodes)
      updateWhatIfRows()
    }

    const {
      chartNodeIdList,
      chartDataList,
      chartTitleList,
      legends,
      aggChartNodeIds,
      aggChartData,
      aggChartTitle,
      updateAhpChartFromTasks
    } = useHybridCharts(tasks, criteriaMap, selectedOutputKeys)

    const hybridTaskListConfig = {
      updateTasks: false,
      showResults: false,
      exportResults: false,
      importTasks: true,
      saveTasks: true,
      run: false,
      hideNetwork: true
    }

    const overwriteWeights = (
      userId: string,
      selectedParentKey: string,
      criteriaWeights: CriteriaWeightsType[]
    ) => {
      saveResults(userId, selectedParentKey, criteriaWeights)
    }

    return {
      currentUser,
      overwriteWeights,
      userOptions,
      hybridTaskListConfig,
      onSelectOuputForChart,
      outputRows,
      addBaseline,
      showIndividualCharts,
      aggChartTitle,
      aggChartNodeIds,
      aggChartData,
      legends,
      chartTitleList,
      chartNodeIdList,
      chartDataList,
      updateAhpChartFromTasks,
      loadResultsHandler,
      refreshResultsHandler,
      selectedScalingMethod,
      chartTitle,
      parentRows,
      currentUserId,
      saveCriteria,
      importBatchWhatifOk,
      abort,
      remove,
      toggleTask,
      run,
      runAll,
      isLoadingResults,
      exportResults,
      importBatchOk,
      activeTab,
      importBatch,
      isPersisting,
      isBatchImportVisible,
      calculate,
      cardStyle,
      CATEGORICAL_PALETTE_MAP,
      CATEGORICAL_PALETTES,
      OUTPUT_EVENTS,
      chartData,
      childrenKeys,
      chartNodeIds,
      COMMON_EVENTS,
      commonNodeKeys,
      cssIcon,
      EMIT_EVENTS,
      MODEL_EVENTS,
      handleLoadNetwork,
      selectedUserId,
      init,
      refresh,
      RES_EVENTS,
      isParsing,
      criteriaRows,
      inputNodeKeys,
      isCreatingJob,
      isExecuting,
      isUpdatingJob,
      loadNetworks,
      maxOutputValue,
      minOutputValue,
      NET_EVENTS,
      TASK_EVENTS,
      networkColumns,
      networkRows,
      networkMap,
      networks,
      nodeSelections,
      onDuplicateTask,
      currentResultsMap,
      onMoveDownRow,
      // plotResults,
      onMoveUpRow,
      onRemoveTask,
      outputNodeKeys,
      paletteColors,
      paletteName,
      paletteVisible,
      preCalculate,
      removeNetwork,
      ROUTE_NAME,
      saveJob,
      selectionConfig,
      selectPalette,
      selectTaskPalette,
      showPalettePicker,
      IMPORT_EVENTS,
      TABLE_EVENTS,
      table,
      tableKey,
      TABS,
      tasks,
      userList,
      whatIfRows,
      workspace,
      workspaceCreated,
      workspaceId,
      workspaceModified,
      criteriaMap,
      updateResult,
      SELECT_EVENTS,
      onSelectKeysForChart,
      displayCommonLegend: false
    }
  }
})
