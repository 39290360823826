export enum NetworkStateEnum {
  IS_CREATING = 'isCreating',
  IS_UPDATING = 'isUpdating',
  IS_DELETING = 'isDeleting',
  IS_GETTING = 'isGetting',
  IS_IMPORTING = 'isImporting',
  ERROR = 'error',
  LOADING_PROMPT_TEXT = 'loadingPromptText',
  CURRENT_NETWORK = 'currentNetwork',
  NETWORK_LIST = 'networkList'
}
