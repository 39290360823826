export enum CPTMethod {
  ACE = 'ACE', // Reserved for original Hassall's ACE method
  ACE_DST = 'ACE_DST', // DST-modified ACE method with complete likelihood matrix
  ACE_DST_SIMPLE = 'ACE_DST_SIMPLE', // DST-modified ACE method with best likelihood only
  CAIN = 'CAIN',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
  MARGINAL = 'MARGINAL',
  MIXED = 'MIXED', // DST mixed ACE, Cain and marginal method
  WEIGHTED = 'WEIGHTED'
}
