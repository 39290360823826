<template>
  <div class="sz-var-coll-method-cell">
    <a-checkbox v-model:checked="checked" :disabled="readOnly" @change="toggleCain()" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

import { EVENTS } from '@/constants/emits'
import { Variable } from '@/libs/bayes'
import { CPTMethod } from '@/libs/bayes/enums/CPTMethod'

/**
 * Toggle for Cain method
 */
export default defineComponent({
  props: {
    variable: { type: Variable, required: true },
    collectionMethodMap: { type: Object, default: () => ({}) },
    readOnly: { type: Boolean, default: false }
  },
  emits: [EVENTS.SURVEY.VARIABLE_TOGGLE_CAIN],
  setup(props, { emit }) {
    const checked = ref(!!props.collectionMethodMap[props.variable.id])

    const toggleCain = () => {
      emit(EVENTS.SURVEY.VARIABLE_TOGGLE_CAIN, {
        variable: props.variable,
        method: checked.value ? CPTMethod.CAIN : undefined
      })
    }

    watch(
      () => props.variable,
      () => {
        checked.value = !!props.collectionMethodMap[props.variable.id]
      }
    )

    return {
      checked,
      toggleCain
    }
  }
})
</script>
